import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../linkFile';

const TopView = (props) => {
    const { data } = props;
    console.log('data',data)
    return (
        <Fragment>
            <Row className="top-views">
                <div className="top-col">
                    {/* Dashboard block */}
                    <div className="left-side dash-block">
                        <span className="view-head">Total Providers</span>
                        <span className="round-fig blue">{data ?.totalProviders}</span>
                        <Link className="view-all" to={`${AdminLinks.admin_provider_management}`}>View All</Link>
                    </div>
                    <div className="right-side dash-block">
                        <img src="/assets/images/total-providers.svg" alt="providers" />
                    </div>
                    {/* Revenue block */}
                    <div className="left-side revenue-block">
                        <span className="view-head">Total Revenue</span>
                        <span className="round-fig blue">${data ? data.totalRevenue ? parseInt(data.totalRevenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "--" : "--"}</span>
                    </div>
                    <div className="right-side revenue-block">
                        <img src="/assets/images/total-revenue.svg" alt="providers" />
                    </div>
                </div>
                <div className="top-col">
                    {/* Dashboard block */}
                    <div className="left-side dash-block">
                        <span className="view-head">Total Endorsements</span>
                        <span className="round-fig light-blue">{data ?.totalEndorsement}</span>
                    </div>
                    <div className="right-side dash-block">
                        <img src="/assets/images/endorsements.svg" alt="endorsements" />
                    </div>
                    {/* Revenue block */}
                    <div className="left-side revenue-block" >
                        <span className="view-head">{data ? data.planList ? data.planList[2].planName : "" : ""} Revenue</span>
                        <span className="round-fig light-blue">${data ? data.totalPremiumPlusRevenue ? parseInt(data.totalPremiumPlusRevenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "" : ""}</span>
                    </div>
                    <div className="right-side revenue-block">
                        <img src="/assets/images/pre-plus-revenue.svg" alt="endorsements" />
                    </div>
                </div>
                <div className="top-col">
                    {/* Dashboard block */}
                    <div className="left-side dash-block">
                        <span className="view-head">Total Patients</span>
                        <span className="round-fig red">{data ?.totalPatients}</span>
                        <Link className="view-all" to={`${AdminLinks.admin_patient_management}`}>View All</Link>
                    </div>
                    <div className="right-side dash-block">
                        <img src="/assets/images/total-patients.svg" alt="patients" />
                    </div>
                    {/* Revenue block */}
                    <div className="left-side revenue-block">
                        <span className="view-head">{data ? data.planList ? data.planList[1].planName : "" : ""} Revenue</span>
                        <span className="round-fig red">${data ? data.totalPremiumRevenue ? parseInt(data.totalPremiumRevenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "--" : "--"}</span>
                    </div>
                    <div className="right-side revenue-block">
                        <img src="/assets/images/premium-revenue.svg" alt="patients" />
                    </div>
                </div>
                <div className="top-col">
                    {/* Dashboard block */}
                    <div className="left-side dash-block">
                        <span className="view-head">Total Appointments</span>
                        <span className="round-fig light-green">{data ?.totalAppointments}</span>
                        <Link className="view-all" to={`${AdminLinks.admin_appointments}`}>View All</Link>
                    </div>
                    <div className="right-side dash-block">
                        <img src="/assets/images/total-appointments.svg" alt="appointments" />
                    </div>
                    {/* Revenue block */}
                    <div className="left-side revenue-block">
                        <span className="view-head">{data ? data.planList ? data.planList[0].planName : "" : ""} Revenue</span>
                        <span className="round-fig light-green">${data ? data.totalBasicRevenue ? parseInt(data.totalBasicRevenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "--" : "--"}</span>
                    </div>
                    <div className="right-side revenue-block">
                        <img src="/assets/images/basic-revenue.svg" alt="appointments" />
                    </div>
                </div>
            </Row>

        </Fragment>
    );
}

export default TopView;