import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';
import ProviderStep from './provider-steps';
import PaymentPlans from '../payment-plan/payment-plan';
import PaymentDetails from '../payment-plan/payment-details';
import NetworkLayer from '../../reactLayer/Network/Network';


const ProviderStep5 = (props) => {

  const [ plans, setplans] = useState({});
  const [ selectedplan, setselectedplan] = useState(2);
  const getSelectedPlan = async (id) => {
     let result = await NetworkLayer.getRequest({url: 'providers/plan/details/get/'+id});
     if( result.status === true ) {
         setplans(result.result.planDetails);
     }
  }
  useEffect(() => {
    if( props.match.params.id && parseInt(props.match.params.id)) {
      getSelectedPlan(props.match.params.id)
    } 
    
  }, [])

  
  return (
    <Fragment>
        <Container fluid className="provider-steps-wrap">
            <ProviderStep step="5" />  
        </Container>        
        { Object.keys(plans).length ? (
          <>
              <PaymentPlans {...{plan : plans, ...props, setselectedplan: setselectedplan}}/>
              <PaymentDetails {...{plan : plans, ...props, selectedplan: selectedplan}}/>
          </>
        ) : (
          <></>
        )
        }
       
        
    </Fragment>
    );
  }
  
  export default ProviderStep5;