import {
  LOGIN,
  LOGOUT,
  SETLOADER,
  UNSETLOADER,
  SET_TIMMER,
  SET_FILTER,
  SET_HEADERSEARCHTEXT,
  SET_ADVANCESEARCHMODAL,
  REMOVE_FILTER,
  SET_BOOKINGLIST,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_RESPONSE_MODAL,
  HIDE_RESPONSE_MODAL,
  SET_TOKEN,
  UNSET_TOKEN,
  SHOW_HIDE_MENU,
  SET_OTPVERIFY,
  SET_COVIDOPTION,
  LOGIN_MODAL,
  LAT_LNG,
  SET_ADMIN_TOKEN,
  ADMIN_LOGIN,
  SET_APPOINTMENT_HISTORY,
  SET_PROVIDER_DETAILS,
  SET_HEALTH_GRADE,
  SET_COOKIE_CONCENT

} from './AuthTypes';

export default (state, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        modal: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modal: false,
      };
    case SHOW_RESPONSE_MODAL:
      return {
        ...state,
        responseModal: true,
      };
    case LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.payload,
      };
    case HIDE_RESPONSE_MODAL:
      return {
        ...state,
        responseModal: false,
      };
    case SETLOADER:
      return {
        ...state,
        loading: true,
      };
    case UNSETLOADER:
      return {
        ...state,
        loading: false,
      };
    case LOGIN:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case UNSET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_OTPVERIFY:
      return {
        ...state,
        otpVerifiy: action.payload,
      };
    case SET_TIMMER:
      return {
        ...state,
        timmer: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filterData: action.payload,
      };
    case SET_HEADERSEARCHTEXT:
      return {
        ...state,
        headerSearchText: action.payload,
      };
    case SET_ADVANCESEARCHMODAL:
      return {
        ...state,
        advance_search_modal: action.payload,
      };
    case SET_BOOKINGLIST:
      return {
        ...state,
        bookingList: action.payload,
      };
    case SHOW_HIDE_MENU:
      return {
        ...state,
        adminMenuActive: action.payload,
      };
    case SET_COVIDOPTION:
      return {
        ...state,
        covidOption: action.payload,
      };
    case LAT_LNG:
      return {
        ...state,
        browserLatLng: action.payload,
      };
    case SET_ADMIN_TOKEN:
      return {
        ...state,
        adminToken: action.payload,
      };
    case ADMIN_LOGIN:
      return {
        ...state,
        admin: action.payload,
      };
    case SET_APPOINTMENT_HISTORY:
      return {
        ...state,
        appointmentHistory: action.payload,
      };
    case SET_PROVIDER_DETAILS:
      return {
        ...state,
        providerDetails: action.payload,
      };
    case SET_COOKIE_CONCENT:
      return {
        ...state,
        cookie_concent: action.payload,
      };
    case SET_HEALTH_GRADE:
      return {
        ...state,
        healthGradeResult: action.payload,
      };
      return state;
  }
};
