import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'react-bootstrap';
import HomeHeader from '../../global/header/header';
import Receipt from '../../components/receipt/receipt';

const PaymentReceipt = (props) => {

  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <HomeHeader/>
      </Container>

      <Container fluid className="">
        <Receipt {...props}/>
      </Container>
    </Fragment>
    );
  }
  
  export default PaymentReceipt;