import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';

let selectedUsersTemp = []
const SendNotification = () => {
    const initialValue = {
        title: {
            value: '',
            validation: ['blank'],
            errorMsg: "This field is required",
            errorKey: 'errtitle',
            totalChar: 30
        },
        description: {
            value: '',
            validation: ['blank'],
            errorMsg: "This field is required",
            errorKey: 'errdescription',
            totalChar:2000
        },
        notificationType: {
            value: 1,
            validation: ['blank'],
            errorMsg: "This field is required",
            errorKey: 'errdescription'
        },
        userType: {
            value: 1,
            validation: ['blank'],
            errorMsg: "This field is required",
            errorKey: 'errdescription'
        }
    }

    const authContext = useContext(AuthContext);
    const [userdata, setUserdata] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    const [totalrecords, setTotalRecords] = useState(0);

    const [data, setData] = useState(initialValue)

    const changeData = (value, type) => {
        let input_value = value.target.value;

        let temp = {}
        let tempOtherData = {}

        if (type == 'title') {
            setTitleError('')
            let charLeft = 30 - input_value.length;
            temp = {
                title: {
                    ...data.title, ...{
                        value: input_value,
                        totalChar: charLeft
                    }
                }
            };
            tempOtherData = { ...data, ...temp };

            if (charLeft >= 0) {
                setData(tempOtherData);
            }
        }

        if (type == 'description') {
            setDescriptionError('')
            let charLeft = 2000 - input_value.length;
            temp = {
                description: {
                    ...data.description, ...{
                        value: input_value,
                        totalChar: charLeft
                    }
                }
            };
            tempOtherData = { ...data, ...temp };
            setData(tempOtherData);
        }

        if (type == 'notificationType') {
            temp = {
                notificationType: {
                    ...data.notificationType, ...{
                        value: input_value
                    }
                }
            };
            tempOtherData = { ...data, ...temp };
            setData(tempOtherData);
        }

        if (type == 'userType') {

            temp = {
                userType: {
                    ...data.userType, ...{
                        value: input_value
                    }
                }
            };
            tempOtherData = { ...data, ...temp };

            setData(tempOtherData);
        }

    }

    const getUsersData = async () => {
        authContext.setLoader();
        // FORM DATA 
        const formData = {
            "searchValue": ""
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/notifications/users/get',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);

        setUserdata(result ?.body ?.result.rows);
        setSelectedUsers(result ?.body ?.result.rows)
        setTotalRecords(result ?.body ?.result.count);
        let array = result ?.body ?.result.rows;

        for (let index = 0; index < array.length; index++) {
            array[index].checked = false;
        }
        authContext.unsetLoader();
    }


    const sendNotification = async () => {
        let errors = []
        
        if(data.title.value.trim() === ''){
            errors.push('title')
            setTitleError("Please add notification title")
        }
        if(data.description.value.trim() === ''){
            errors.push('description')
            setDescriptionError("Please add notification description")
        }
        
        if(errors.length){
            document.getElementById(errors[0]).focus()
            //console.log('errors',errors)
        }
        else{ 
        const users_list = []
        if(data.userType.value === '2'){
            const selectedUsersArray = selectedUsers.filter(x=>x.checked === true)
            if(selectedUsersArray.length === 0){
                setmodalProps({
                    type: 'error',
                    msg: "Select at least one user",
                });
                authContext.showResponseModal();
                return;
            }else{
                selectedUsersArray.map((user)=>{
                    users_list.push(user.id)
                    return null
                })
            }
            
        }
        authContext.setLoader();
        // FORM DATA 
        const formData = {
            "title": data.title.value,
            "message": data.description.value,
            "notification_type": parseInt(data.notificationType.value),
            "users_options": parseInt(data.userType.value),
            "users_lists": users_list
        }
        // STRUCTURE LOGIN REQUEST DATA
        
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/notifications/addNotifications',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 


        try {
            let result = await NetworkLayer.postData(submitData);
            if (result.body.status) {
                
                selectAllUser('','unselect')
                unselectAllUsers()
                resetData()
                setmodalProps({
                    type: 'success',
                    msg: "Notification sent successfully",
                });
                let e = {target:{value:''}}
                findArrayElementByTitle(e)
            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
            }

        } catch (err) {
            console.log(err);

        }

        authContext.showResponseModal();
        authContext.unsetLoader();
        }
    }

    const selectUser = (e) => {
        let is_checked = e.target.checked;
        let id = e.target.value;
        let temp = [...userdata];
        var index = temp.findIndex(obj => obj.id == id);
        temp[index].checked = is_checked;
        setUserdata(temp)
        setSelectedUsers(temp)
    }

    const unselectAllUsers = () => {
        let temp = [...userdata];
        for (let index = 0; index < temp.length; index++) {
            temp[index].checked = false;
        }
        setUserdata(temp)
        setSelectedUsers(temp)
    }

    const selectAllUser = (e,type) => {
        let is_checked = false
        if(e){
            is_checked = e.target.checked;
        }
        if(type === 'unselect'){
            is_checked = false
        }
        let temp = [...userdata];
        for (let index = 0; index < temp.length; index++) {
            temp[index].checked = is_checked;
        }
        setUserdata(temp)
        setSelectedUsers(temp)
    }

    function findArrayElementByTitle(e) {
        let title = e.target.value;
        console.log('titletitle',title)
        if(selectedUsersTemp.length === 0){
            selectedUsersTemp = selectedUsers
        }else{
            setSelectedUsers(selectedUsersTemp)
        }
        console.log('selectedUsers',selectedUsers,selectedUsersTemp)
        let findArr = selectedUsersTemp.filter(x => x.name.toLowerCase() ? x.name.toLowerCase().indexOf(title) > -1 : '')
        setUserdata(findArr)
        setSelectedUsers(findArr)
        console.log('findArr',findArr);
    }

    function resetData() {
        let tempOtherData = {}
        let temp = initialValue
        tempOtherData = { ...data, ...temp };
        console.log(tempOtherData);
        setData(tempOtherData);
    }

    useEffect(() => {
        getUsersData();
        console.log(data);
    }, [])

    return (
        <Fragment>
            {
                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            <div className="notification-wrap-admin">
                <Row className="mt-2">
                    <div className="col-12 mb-3">
                        <label className="pro-label">Notification Title</label>
                        <input type="text" id="title" className="input-pro" placeholder="Enter notification title" value={data.title.value} onChange={(e) => { changeData(e, 'title') }} />
                        <div>
                            <div>
                                <small>{data.title.totalChar} characters remaining</small>
                            </div>
                            <div>
                                <small class="error-input">{titleError ? titleError : ''}</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <label className="pro-label">Notification Description</label>
                        <textarea maxLength={2000} id="description" name="notiDesc" className="input-pro input-textarea" placeholder="Enter notification description" value={data.description.value} onChange={(e) => { changeData(e, 'description') }}></textarea>
                        <div>
                            <div><small>{data.description.totalChar} characters remaining</small></div>
                            <div><small class="error-input">{descriptionError ? descriptionError : ''}</small></div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="col-12">
                        <label className="pro-label">Notification Type:</label>
                    </div>
                    <div className="col-12 mb-2 mb-md-4">
                        <div className="noti-type">
                            <div className="radio-tick">
                                <input type="radio" name="notification_type" id="notification_type_1" value="1" checked={data.notificationType.value == 1} onChange={(e) => { changeData(e, 'notificationType') }} />
                                <label className="checkbox" htmlFor="notification_type_1">
                                    <span></span>
                                    Push Notifications
                                </label>
                            </div>
                            <div className="radio-tick">
                                <input type="radio" name="notification_type" id="notification_type_2" value="2" checked={data.notificationType.value == 2} onChange={(e) => { changeData(e, 'notificationType') }} />
                                <label className="checkbox" htmlFor="notification_type_2">
                                    <span></span>
                                    Email Notifications
                                </label>
                            </div>
                            <div className="radio-tick">
                                <input type="radio" name="notification_type" id="notification_type_3" value="3" checked={data.notificationType.value == 3} onChange={(e) => { changeData(e, 'notificationType') }} />
                                <label className="checkbox" htmlFor="notification_type_3">
                                    <span></span>
                                    Push &amp; Email Notifications
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <label className="pro-label">Choose Option:</label>
                    </div>
                    <div className="col-12 mb-2 mb-md-4">
                        <div className="noti-type">
                            <div className="radio-tick">
                                <input type="radio" name="userType" id="userType_1" value="1" checked={data.userType.value == 1} onChange={(e) => { changeData(e, 'userType') }} />
                                <label className="checkbox" htmlFor="userType_1">
                                    <span></span>
                                    Send to all Users
                                </label>
                            </div>
                            <div className="radio-tick">
                                <input type="radio" name="userType" id="userType_2" value="2" checked={data.userType.value == 2} onChange={(e) => { changeData(e, 'userType') }} />
                                <label className="checkbox" htmlFor="userType_2">
                                    <span></span>
                                    Send to Selected
                                </label>
                            </div>
                            <div className="radio-tick">
                                <input type="radio" name="userType" id="userType_3" value="3" checked={data.userType.value == 3} onChange={(e) => { changeData(e, 'userType') }} />
                                <label className="checkbox" htmlFor="userType_3">
                                    <span></span>
                                    Provider Only
                                </label>
                            </div>
                            <div className="radio-tick">
                                <input type="radio" name="userType" id="userType_4" value="4" checked={data.userType.value == 4} onChange={(e) => { changeData(e, 'userType') }} />
                                <label className="checkbox" htmlFor="userType_4">
                                    <span></span>
                                    Patients Only
                                </label>
                            </div>
                        </div>
                    </div>
                </Row>
                {/* Bottom Table section start here */}
                {
                    data.userType.value == 2 &&
                    <div className="notification-users">
                        <div className="send-noti-table-top">
                            <div className="search-bar-sendnoti">
                                <div className="table-sort-filter">
                                    <span className="filter-input">
                                        <input type="text" class="input-pro" name="search" id="search" placeholder="Search by provider name, patient name" autoComplete="off" onChange={findArrayElementByTitle} />
                                        <i className="search-icon"><SearchIcon /></i>
                                    </span>
                                </div>
                            </div>
                            <label className="pro-label">Select Users</label>
                        </div>
                        <div className="table-report-scroll table-noti mt-0" style={{height:'500px'}}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="check-tick">
                                                <input type="checkbox" name="co" id="checkall" onChange={selectAllUser} />
                                                <label className="checkbox" htmlFor="checkall">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedUsers &&
                                        selectedUsers.map((value, index) =>
                                            <tr key={value.id}>
                                                <td>
                                                    <div className="check-tick">
                                                        <input type="checkbox" name="user" id={`user${value.id}`} onChange={selectUser} value={value.id} checked={value.checked} />
                                                        <label className="checkbox" htmlFor={`user${value.id}`}>
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>{value.name}</td>
                                                <td>{value.email}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                <div className="col-12 mb-4 all-fltrbtn" style={{ width: '30%', margin: '0 auto' }}>
                    <input type="button" className="next-btn-pro" value="Submit" onClick={sendNotification} />
                    <input type="button" className="next-btn-pro" value="Reset" onClick={resetData} />
                </div>
            </div>
        </Fragment>
    );
}

export default SendNotification;