import React, { Fragment, useState, useEffect, useContext } from 'react';
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import DashboardHead from '../../components/dashboard-head-tab/dashboard-head';
import SchedulePage from './scheduling_page';
import DigitalPage from './digital_page';
import Appointments from './appointment';
import ProfilePage from './dashboard-profile-page';
import DashboardProfile from '../../components/dashboard-profile/dashboard-profile';
import Error404 from '../../global/errorpages/error-404';
import Error403 from '../../global/errorpages/error-403';
import NetworkLayer from '../../reactLayer/Network/Network';
import Global from '../../reactLayer/global';
import InputValidation from '../../reactLayer/validation';
// import Custommodal from '../../global/modal/modal';

const Dashboard = (props) => {
  const authContext = useContext(AuthContext);
  const [visibleComponent, setvisibleComponent] = useState(authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.subRoleId && authContext.user.accountInfo.subRoleId === 5 ? 'appointmentPage' : 'providerProfile');
  const tabName = [{ id: 'providerProfile', name: 'Provider Profile' }, { id: 'schedulePage', name: 'Scheduling Manager' }, { id: 'appointmentPage', name: 'Appointment Requests' }, { id: 'digitalPage', name: 'Digital Reputation' }]
  const [userprofile, setuserProfile] = useState({});
  const [scheduleTab, setscheduleTab] = useState(authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.subRoleId && authContext.user.accountInfo.subRoleId === 5 ? '2' : '1');

  const getUserProfile = async () => {
    authContext.setLoader();
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access_token': authContext.token
      },
      url: 'providers/profile-details/get'
    }
    let result = await NetworkLayer.getRequest(submitData);
    console.log('result in get user ===>profile', result);
    if (result.status) {
      setuserProfile(result.result);
    }
    authContext.unsetLoader();
  }

  const getProfile = async () => {

    let userId = authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.providerId ? authContext.user.accountInfo.providerId : null;
    if (userId) {
      authContext.setLoader();

      const formData = {

      }

      // STRUCTURE LOGIN REQUEST DATA
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access_token': authContext.token
        },
        url: 'providers/get/' + userId,
        body: JSON.stringify(formData),
      }

      // SEND REQUEST 
      let result = await NetworkLayer.postData(submitData);

      // console.log('result in get profile by id', result);
      if (result.body.status) {
        // console.log("Child Provider ", result.body.result);
        setuserProfile(result.body.result);
      }
      authContext.unsetLoader();
    }
  }
  const redirectToSchedule = () => {
    setscheduleTab('2');
    setvisibleComponent('schedulePage');

    
  }
  useEffect(() => {
    console.log('on provider dashboard ', authContext.user);
    
    if (authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.subRoleId && authContext.user.accountInfo.subRoleId === 5) {
      getProfile();
    } else {
      getUserProfile();
    }
    if( props && props.location && props.location.state && props.location.state.response && props.location.state.response == 'appointment-page') {
      setvisibleComponent('appointmentPage');
    }

  }, [])

  return (
    <Fragment>
      <HeaderMain />

      <DashboardHead visibleComponent={visibleComponent} setvisibleComponent={setvisibleComponent} tabName={tabName} title={'Provider Dashboard'} />

      {
        visibleComponent === 'providerProfile' ? <>{authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.subRoleId && authContext.user.accountInfo.subRoleId === 5 ? (<Error403 />) : (<DashboardProfile redirectToSchedule={redirectToSchedule} visibleComponent={visibleComponent} setvisibleComponent={setvisibleComponent} />)}</>
          :
          visibleComponent === 'digitalPage' ? <>{authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.subRoleId && authContext.user.accountInfo.subRoleId === 5 ? (<Error403 />) : (<DigitalPage userprofile={userprofile} />)}</>
            :
            visibleComponent === 'schedulePage' && userprofile.providerCurrentPlan ? <SchedulePage setscheduleTab={setscheduleTab} userprofile={userprofile} tab={scheduleTab} />
              :
              visibleComponent === 'appointmentPage' ? <Appointments userprofile={userprofile} /> : <></>
      }



      <Footer />
    </Fragment>
  );
}

export default Dashboard;