import React, { Fragment, useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import InputValidation from '../../reactLayer/validation';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { useHistory } from "react-router-dom";
import { ProviderLinks, PatientLinks } from '../../linkFile';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import * as moment from 'moment';

const TwoStep = (props) => {
    console.log('two step props', props);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const emailErrorMsg = ['Otp required', 'Invalid Otp'];
    const loginType = props.ltype ? props.ltype : 'provider';
    const [errors, setErrors] = useState({
        eemail: false,
        epassword: false,
    });
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: ''

    });
    const [loginData, setLoginData] = useState({
        email: {
            value: '',
            validation: ['blank', 'email'],
            errorMsg: emailErrorMsg,
            errorKey: 'eemail',
        },
        remember: {
            value: '',
            validation: [],
        },

    });
    const resentOtp = async (e) => {
        e.preventDefault()
        let url = 'otp/resent';
        let data = {};
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: url,
            body: JSON.stringify(data),
        }
        authContext.setLoader();
        let result = await NetworkLayer.postData(submitData);
        authContext.setTimmer(180);
        authContext.unsetLoader();
        if (result.body.status) {
            setmodalProps({
                type: 'success',
                msg: result.body.message,
            });
            authContext.showResponseModal();
        } else {
            setmodalProps({
                type: 'error',
                msg: result.body.message,
            });
            authContext.showResponseModal();
        }

    }
    const formSubmit = async () => {
        const validationResult = InputValidation(loginData);
        console.log('validationResult-->', validationResult)
        if (Object.keys(validationResult).length > 0) {
            setErrors({ ...errors, ...validationResult });
        } else {
            authContext.setLoader();
            let data = {
                otpCode: loginData.email.value,
            }
            let url = '';
            if (loginType === 'provider') {
                url = 'otp/verification';
            } else {
                url = 'otp/verification';
            }
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: url,
                body: JSON.stringify(data),
            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            console.log('result .body', result);
            if (result.body.status) {
                let user = authContext.user;
                user = { ...user, ...result.body.result.data, ...{email_verified: true} }
                authContext.setUser(user);
                if(loginData.remember.value) {
                    authContext.setotpVerifiy(user.accountInfo.email, true);
                } else {
                    authContext.setotpVerifiy(user.accountInfo.email, false);
                }
                
                if (loginType === 'provider') {
                    if (result.body.result.data.isProfileCompleted) {
                        history.push({ pathname: ProviderLinks.home });
                    } else {
                        if (result.body.result.data) {
                            history.push({ pathname: ProviderLinks['register_setp' + result.body.result.step] });
                        }
                    }

                } else {
                    if (result.body.result.data.isProfileCompleted) {
                        if (props.location && props.location.pathname && props.location.pathname === PatientLinks.doctor_search_result) {
                            // props.settabvisibleComponent && props.settabvisibleComponent('');
                        } else {
                            history.push({ pathname: PatientLinks.home });
                        }
                    } else {
                        if (result.body.result.data) {
                            history.push({ pathname: PatientLinks['register_setp' + result.body.result.step] });
                        }
                    }
                }
            } else {
                setErrors({ ...errors, ...{ eemail: result.body.message } });
            }
        }
    }

    const keepLogin = (e) => {
        if (e.currentTarget.checked === true) {
            setLoginData({
                ...loginData,
                ...{
                    remember: {
                        value: true,
                        validation: []
                    }
                }
            })
            // authContext.setotpVerifiy(false);
        } else {
            setLoginData({
                ...loginData,
                ...{
                    remember: {
                        value: false,
                        validation: [],
                    }
                }
            })
            // authContext.setotpVerifiy(true);
        }
    }
    const dosubmit = (e) => {
        if (e.key === 'Enter') {
            formSubmit(e);
        }
    }


    // const [counter, setCounter] = useState(180);
    // useEffect(() => {
    //     console.log('authContext', authContext.user);
    //   }, []);

    return (
        <Fragment>
            {/* Form start step 1 */}
            <Container className="">
                <Row className={loginType === 'provider' ? 'provider-login-wrap twostep' : 'provider-login-wrap twostep patient'}>
                    {
                        authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                    }
                    <div className="col-md-12 mb-3">
                        {loginType === 'provider' ? (<>
                            <h1 className="mb-3">Two-Step Verification</h1>
                            <div className="col-md-12 mb-3 provider-steps-wrap">
                                <div className="medical-info body"></div>
                            </div>
                            <p className="small col-md-8 offset-md-2 ml-auto mr-auto pt-2">Please enter the code that has been sent to your registered email </p></>
                        ) : (
                                <><h1 className="mb-3">Two-Step Verification</h1>

                                    {/* <p className="small col-md-8 offset-md-2 ml-auto mr-auto pt-2">Please enter the code that has been sent to phone number <strong>***-****-0344</strong> </p> */}
                                    <p className="small col-md-8 offset-md-2 ml-auto mr-auto pt-2">Please enter the code that has been sent to your registered email </p>
                                </>
                            )}

                    </div>


                    <div className="col-md-12 mb-3">
                        <input type="text" name="email" className={errors.eemail ? "input-pro  error-border" : "input-pro"} placeholder="Enter Code" autoComplete="off"
                            onClick={() => setErrors({ ...errors, ...{ eemail: false } })}
                            onFocus={() => setErrors({ ...errors, ...{ eemail: false } })}
                            onKeyPress={(e) => dosubmit(e)}
                            onChange={(e) => setLoginData({
                                ...loginData,
                                ...{
                                    email: {
                                        value: e.currentTarget.value.trim(),
                                        validation: ['blank', 'number'],
                                        errorMsg: emailErrorMsg,
                                        errorKey: 'eemail',
                                    }
                                }
                            })
                            }
                            value={loginData.email.value}
                        />
                        <small className="error-input">{errors.eemail}</small>
                    </div>


                    <div className="col-md-12 mb-2 keep-log">
                        <div className="cus-check">
                            <input type="checkbox" id="loggedIn" name="loggedIn" onChange={(e) => { keepLogin(e) }} />
                            <label htmlFor="loggedIn">Don't ask for codes on this device</label>
                        </div>
                    </div>


                    <div className="col-md-12 text-center submit-btn">
                        <div className="next-prev-btn-pro">
                            <input type="button" onClick={() => { formSubmit() }} className="next-btn-pro full-width" value="Continue" />
                        </div>
                    </div>
                    <div className="col-12 mt0-3 text-center link-text">
                        <div className="bday-use">
                            <strong>
                                {
                                    authContext.timmer > 0 ? (<>
                                        {moment.utc(authContext.timmer * 1000).format('mm:ss')}
                                    </>) : (
                                            <>
                                                <a href="/#" onClick={(e) => resentOtp(e)}>Resend Code</a>
                                            </>)
                                }
                                {/* <div>{counter}</div> */}
                            </strong>
                        </div>
                    </div>

                </Row>
            </Container>
        </Fragment>
    );
}

export default TwoStep;