import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Spinner } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';
import InputValidation from '../../reactLayer/validation';
import ProviderStep4 from '../../components/provider-steps/provider-step4';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import { CSVLink, CSVDownload } from "react-csv";

const Appointments = (props) => {
 
    const [allappointment, setallappointment] = useState([]);
    const profile = props.userprofile ? props.userprofile : '';
    const authContext = useContext(AuthContext);
    const [prevAppointments, setprevAppointments] = useState(false);
    const getAllappointments = async() => {
        authContext.setLoader();
        let fData = { days: 30}
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'providers/appointment/request/get',
            body: JSON.stringify(fData),
        }
        let result = await NetworkLayer.postData(submitData);
        console.log('appointment result ===>', result);
        if( result.body.status ) {
            setallappointment(result.body.result);
            authContext.unsetLoader();
        } else {
            setallappointment([]);
            authContext.unsetLoader();
        }
    }

    const getPrevMonthappointments = async() => {
        // authContext.setLoader();
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'providers/appointment/request/previous-month/get'
        }
        let result = await NetworkLayer.getRequest(submitData);
        console.log('getPrevMonthappointments result ===>', result);
        if( result.status ) {
            try {
                let appointmentdata = []
                let header = [
                    { label: "Requested Date", key: "requestedDate" },
                    { label: "Name", key: "name" },
                    { label: "Gender", key: "gender" },
                    { label: "Preferred Pronouns", key: "preferredPronoun" },
                    { label: "New Patient", key: "newwPatient" },
                    { label: "Phone", key: "phone" },
                    { label: "Preferred Appt Time", key: "appt_time" },
                    { label: "Preferred Appt Date", key: "appt_date" },
                    { label: "Appointment Status", key: "appt_status" },
                ];
                result.result && result.result.map((ud,i) => {
                    let data = [];
                    ud.data && ud.data.map((d,di) =>{
                        data.push({
                            requestedDate: d.createdAt ? d.createdAt : '',
                            name : d.users.patients && d.users.patients.firstName ? d.users.patients.firstName+' ' : '' + d.users.patients && d.users.patients.middleName ? d.users.patients.middleName+' ' : '' + d.users.patients && d.users.patients.lastName ? d.users.patients.lastName+' ' : '',
                            gender: d.users.patients && d.users.patients.gender ? d.users.patients.gender : '',
                            preferredPronoun: d.users.patients && d.users.patients.preferredPronoun ? d.users.patients.preferredPronoun : '',
                            newwPatient: d.isNewPatient ?  'Yes' : 'No',
                            reason: d.visitType ? d.visitType : '',
                            phone: d.users.patients && d.users.patients.phoneType === 'cell' ? d.users.patients.homeNumber ? Global.phoneFormator(d.users.patients.homeNumber) : '' : d.users.patients.phoneNumber && Global.phoneFormator(d.users.patients.phoneNumber) ,
                            appt_time: d.preferAppointmentTime ? d.preferAppointmentTime : '',
                            appt_date: d.appointmentDayType ? d.appointmentDayType : d.appointmentDate ? d.appointmentDate : '',
                            appt_status: d.bookingStatus ? 'Schedule' : 'Unschedule'
                        })
                    })
                    appointmentdata.push({
                        header: header,
                        data:data,
                        practiceName: {monthName: ud.monthName, practiceName: ud.practiceName, year: ud.year}
                    })
                })
                setprevAppointments([...appointmentdata]);
            } catch(e) {
                setprevAppointments([]);
            }
            
        } else {
            // authContext.unsetLoader();
            setprevAppointments([]);
        }
    }

    const changeSchedue = async(e, id, status) => {
        e && e.preventDefault();
        authContext.setLoader();
        let fData = { id: id, bookingStatus: status === 1 ? 2 : 1}
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'appointment/book/status/update',
            body: JSON.stringify(fData),
        }
        let result = await NetworkLayer.postData(submitData);
        console.log('appointment result ===>', result);
        if( result.body.status ) {
            getAllappointments();
            authContext.unsetLoader();
        } else {
            authContext.unsetLoader();
        }
    }

    
    useEffect(() => {
        getAllappointments();
        getPrevMonthappointments();
    }, [])
  return (
    <Fragment>
    {   
        profile &&  profile.providerCurrentPlan && profile.providerCurrentPlan.providerCurrentServices && profile.providerCurrentPlan.providerCurrentServices.indexOf(3) > '-1' ? (<>
            <Container className="appointment-wrap">
                <Row>
                    <div className="col-12">
                        <div className="appoint-sub-head-wrap">
                            <h2>Appointment Requests</h2>
                            <div className="filter-tab">
                                {/* <a href="/#">All Filters</a> */}
                            </div>
                        </div>
                    </div>
                </Row>
                
                <Row>
                    <div className="col-md-6">
                        <div className="appoint-req-record">
                            <span><div className="svgtime-icon"></div>Last 30 days</span>
                            <span>{allappointment ? allappointment.length : 0} Appointment Requests</span>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3 mt-md-0">
                        <div className="schedule-icons">
                            <span>Scheduled</span>
                            <span>Unscheduled</span>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="col-12 mb-5">
                        <div className="appoint-box">
                            {/* heading start */}
                            <div className="table-heading-wrap">
                                <div>Request Date</div>
                                <div>Patient Information</div>
                                <div>Insurance Information</div>
                                <div>Appointment Status</div>
                            </div>
                            {/* Content start */}
                            <div className="appoint-box-content">
                                {/* heading start */}
                                <div className="table-heading-wrap-device">
                                    <div>Request Date</div>
                                    <div>Patient Information</div>
                                    <div>Insurance Information</div>
                                    <div>Appointment Status</div>
                                </div>
                                {/* row 1 */}
                                {console.log('ad',allappointment)}
                                {
                                    allappointment.map((ad, ai) =>
                                        <div className="table-content-appointment">
                                            <div>
                                                <p><span>{ad.createdAt} {ad.providerPracticeLocations && ad.providerPracticeLocations.timeZone ?  ' ' + ad.providerPracticeLocations.timeZone : '' }</span></p>
                                            </div>
                                            <div>
                                                {ad.users.patients ? (<>
                                                    <p><span>Name:</span><span>
                                                            {
                                                            ad.childId ? 
                                                                ad.users.childInsurance.find(x=>x.id === ad.childId).firstName
                                                            :
                                                                ad.users.patients.firstName ? ad.users.patients.firstName + ' ' : ''
                                                            } 
                                                            {
                                                            ad.childId ? 
                                                                ad.users.childInsurance.find(x=>x.id === ad.childId).middleName ? " "+ad.users.childInsurance.find(x=>x.id === ad.childId).middleName : ""
                                                            :
                                                                ad.users.patients.middleName ? ad.users.patients.middleName + ' ' : ''
                                                            } 
                                                            {
                                                            ad.childId ? 
                                                                ad.users.childInsurance.find(x=>x.id === ad.childId).lastName ? " "+ad.users.childInsurance.find(x=>x.id === ad.childId).lastName : ""
                                                            :
                                                                ad.users.patients.lastName ? ad.users.patients.lastName + ' ' : ''
                                                            }
                                                            </span></p>
                                                </>) : (<>
                                                
                                                </>)}
                                                
                                                <p><span>Gender:</span><span>{ad.users.patients && ad.users.patients.gender ? ad.users.patients.gender : '' }</span></p>
                                                <p><span>Preferred Pronouns:</span><span>{ad.users.patients && ad.users.patients.preferredPronoun ? ad.users.patients.preferredPronoun : '' }</span></p>
                                                <p><span>New Patient:</span><span>{ad.isNewPatient ? 'Yes' : 'No'}</span></p>
                                                <p><span>Visit Reason:</span><span>{ad.appointmentDescription ? ad.appointmentDescription : 'No'}</span></p>
                                                <p><span>Phone:</span><span>{ad.users.patients && ad.users.patients.phoneType === 'cell' && ad.users.patients.homeNumber ? Global.phoneFormator(ad.users.patients.homeNumber) : ad.users.patients && ad.users.patients.phoneNumber ? Global.phoneFormator(ad.users.patients.phoneNumber) : '' }</span></p>
                                                <p><span>Preferred Appt Time:</span><span>{ad.preferAppointmentTime ? ad.preferAppointmentTime : ''}</span></p>
                                                <p><span>Preferred Appt Date:</span><span>{ad.appointmentDayType ? ad.appointmentDayType : ad.appointmentDate ? ad.appointmentDate : ''}</span></p>
                                            </div>
                                            <div>
                                                {

                                                    ad.users.patientInsurances && ad.users.patientInsurances.map((ins,insIndex) =>
                                                        <ul className="mb-2">
                                                            <li style={{width:"100%"}}>
                                                                <p><span>Insurance Name:</span><span>{ins.insurances && ins.insurances.name ? ins.insurances.name : ''}</span></p>
                                                                <p><span>Plan Name:</span><span>{ins.planName ? ins.planName : ''}</span></p>
                                                                <p><span>Group #:</span><span>{ins.group ? ins.group : ''}</span></p>
                                                                <p><span>Membership ID:</span><span>{ins.membershipId ? ins.membershipId : ''}</span></p>
                                                            </li>
                                                        </ul>
                                                    
                                                    )
                                                }
                                                
                                            </div>
                                            <div>
                                                <ul className="schedule-status">
                                                    {
                                                        ad.bookingStatus && ad.bookingStatus === 1 ? (<><li className="scheduled">Scheduled</li><span className="switch-link"><a href="/#" onClick={(e) => changeSchedue(e,ad.id, ad.bookingStatus)} >Switch to “Unscheduled”</a></span></>) : (<><li className="blank" onClick={(e) => changeSchedue(e, ad.id, ad.bookingStatus)}>Scheduled</li><li className="unscheduled">Unscheduled</li></>)       
                                                    }
                                                </ul>
                                            </div>
                                        </div>    
                                    )
                                }
                                {/* row 2 */}
                                {/* <div className="table-content-appointment">
                                    <div>
                                        <p><span>Friday, January 11, 2020<br/>4:00 PM EST</span></p>
                                    </div>
                                    <div>
                                        <p><span>Name:</span><span>Goku Dragon</span></p>
                                        <p><span>Gender:</span><span>Male</span></p>
                                        <p><span>Preferred Pronouns:</span><span>He/Him</span></p>
                                        <p><span>New Patient:</span><span>Yes</span></p>
                                        <p><span>Visit Reason:</span><span>Annual Physical</span></p>
                                        <p><span>Phone #:</span><span>(813) 987=6554</span></p>
                                        <p><span>Preferred Appt Time:</span><span>Morning</span></p>
                                        <p><span>Preferred Appt Date:</span><span>Next 7 Day</span></p>
                                    </div>
                                    <div style={{width:"100%"}}>
                                        <p><span>Insurance Name:</span><span>Blue Cross</span></p>
                                        <p><span>Plan Name:</span><span>HMO</span></p>
                                        <p><span>Group #:</span><span>123456</span></p>
                                        <p><span>Membership ID:</span><span>8123DJDH84</span></p>
                                    </div>
                                    <div>
                                        <ul className="schedule-status">
                                            <li className="scheduled">Scheduled</li>
                                            <span className="switch-link"><a href="/#">Switch to “Unscheduled”</a></span>
                                        </ul>
                                    </div>
                                </div> */}
                                {/* row 3 */}
                                {/* <div className="table-content-appointment">
                                    <div>
                                        <p><span>Friday, January 11, 2020<br/>4:00 PM EST</span></p>
                                    </div>
                                    <div>
                                        <p><span>Name:</span><span>Goku Dragon</span></p>
                                        <p><span>Gender:</span><span>Male</span></p>
                                        <p><span>Preferred Pronouns:</span><span>He/Him</span></p>
                                        <p><span>New Patient:</span><span>Yes</span></p>
                                        <p><span>Visit Reason:</span><span>Annual Physical</span></p>
                                        <p><span>Phone #:</span><span>(813) 987=6554</span></p>
                                        <p><span>Preferred Appt Time:</span><span>Morning</span></p>
                                        <p><span>Preferred Appt Date:</span><span>Next 7 Day</span></p>
                                    </div>
                                    <div>
                                        <p><span>Insurance Name:</span><span>Blue Cross</span></p>
                                        <p><span>Plan Name:</span><span>HMO</span></p>
                                        <p><span>Group #:</span><span>123456</span></p>
                                        <p><span>Membership ID:</span><span>8123DJDH84</span></p>
                                    </div>
                                    <div>
                                        <ul className="schedule-status">
                                            <li className="scheduled">Scheduled</li>
                                            <span className="switch-link"><a href="/#">Switch to “Unscheduled”</a></span>
                                        </ul>
                                    </div>
                                </div> */}
                                {/* row 4 */}
                                {/* <div className="table-content-appointment">
                                    <div>
                                        <p><span>Friday, January 11, 2020<br/>4:00 PM EST</span></p>
                                    </div>
                                    <div>
                                        <p><span>Name:</span><span>Goku Dragon</span></p>
                                        <p><span>Gender:</span><span>Male</span></p>
                                        <p><span>Preferred Pronouns:</span><span>He/Him</span></p>
                                        <p><span>New Patient:</span><span>Yes</span></p>
                                        <p><span>Visit Reason:</span><span>Annual Physical</span></p>
                                        <p><span>Phone #:</span><span>(813) 987=6554</span></p>
                                        <p><span>Preferred Appt Time:</span><span>Morning</span></p>
                                        <p><span>Preferred Appt Date:</span><span>Next 7 Day</span></p>
                                    </div>
                                    <div>
                                        <p><span>Insurance Name:</span><span>Blue Cross</span></p>
                                        <p><span>Plan Name:</span><span>HMO</span></p>
                                        <p><span>Group #:</span><span>123456</span></p>
                                        <p><span>Membership ID:</span><span>8123DJDH84</span></p>
                                    </div>
                                    <div>
                                        <ul className="schedule-status">
                                            <li className="blank">Scheduled</li>
                                            <li className="unscheduled">Unscheduled</li>
                                        </ul>
                                    </div>
                                </div> */}
                                {/* row 5 */}
                                {/* <div className="table-content-appointment">
                                    <div>
                                        <p><span>Friday, January 11, 2020<br/>4:00 PM EST</span></p>
                                    </div>
                                    <div>
                                        <p><span>Name:</span><span>Goku Dragon</span></p>
                                        <p><span>Gender:</span><span>Male</span></p>
                                        <p><span>Preferred Pronouns:</span><span>He/Him</span></p>
                                        <p><span>New Patient:</span><span>Yes</span></p>
                                        <p><span>Visit Reason:</span><span>Annual Physical</span></p>
                                        <p><span>Phone #:</span><span>(813) 987=6554</span></p>
                                        <p><span>Preferred Appt Time:</span><span>Morning</span></p>
                                        <p><span>Preferred Appt Date:</span><span>Next 7 Day</span></p>
                                    </div>
                                    <div>
                                        <p><span>Insurance Name:</span><span>Blue Cross</span></p>
                                        <p><span>Plan Name:</span><span>HMO</span></p>
                                        <p><span>Group #:</span><span>123456</span></p>
                                        <p><span>Membership ID:</span><span>8123DJDH84</span></p>
                                    </div>
                                    <div>
                                        <ul className="schedule-status">
                                            <li className="scheduled">Scheduled</li>
                                            <span className="switch-link"><a href="/#">Switch to “Unscheduled”</a></span>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="col-12">
                        <div className="appoint-sub-head-wrap prev-head">
                            <h2>Previous Month Appointment Request File(s)</h2>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="col-12">
                        <div className="prev-file-wrap">
                            {
                               !prevAppointments ? (<>
                                     <div className="loadingContainer">
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                               </>) : (<>
                                    {
                                        prevAppointments && prevAppointments.map((d,i) => 
                                            <Row className="prev-file-box">
                                                <div className="col-lg-4 col-md-6 text-center text-lg-left">Provider/{`${d.practiceName.practiceName}  ${d.practiceName.monthName} ${d.practiceName.year}`} </div>
                                                <div className="col-lg-4 col-md-6 text-center text-lg-left"></div>
                                                <div className="col-lg-4 col-md-12 text-center text-lg-left mt-4 mt-lg-0">
                                                    <CSVLink data={d.data} headers={d.header} filename={`${d.practiceName.practiceName}-${d.practiceName.monthName}-${d.practiceName.year}.csv`}>
                                                    <img src="/assets/images/xls-icon.png" className="xls-icon" alt="Download CSV"/> 
                                                        Download File
                                                    </CSVLink>
                                                </div>
                                            </Row>
                                        )
                                    }
                               </>) 
                            }
                            
                                
                            {/* <Row className="prev-file-box">
                                <div className="col-lg-4 col-md-6 text-center text-lg-left">Provider/Practice Name_Month</div>
                                <div className="col-lg-4 col-md-6 text-center text-lg-left">10/12/2019 12:25 PM</div>
                                <div className="col-lg-4 col-md-12 text-center text-lg-left mt-4 mt-lg-0"><a href="/#"><img src="/assets/images/xls-icon.png" className="xls-icon" alt="Download XLS"/>Download File</a></div>
                            </Row> */}
                        </div>
                    </div>
                </Row>
            </Container>
        
        </>) : (<>
            <Container className="appointment-wrap modal-wrapper">
                <Row>
                    <div className="col-12 profile-plans">
                        <div className="provider-step4-wrap">
                            <ProviderStep4 title={'Manage Subscriptions'}/>                                
                        </div>
                    </div>
                </Row>
            </Container>
        </>)
    }
    

    </Fragment>
    );
  }
  
  export default Appointments;