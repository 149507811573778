import React, { useReducer, useEffect, useCallback } from "react";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import StorageAdaptation from "../storage/storage";
import Global from "../global";

import {
  LOGIN,
  LOGOUT,
  SETLOADER,
  UNSETLOADER,
  SET_TIMMER,
  SET_FILTER,
  SET_HEADERSEARCHTEXT,
  SET_ADVANCESEARCHMODAL,
  SET_BOOKINGLIST,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_RESPONSE_MODAL,
  HIDE_RESPONSE_MODAL,
  SET_TOKEN,
  UNSET_TOKEN,
  SHOW_HIDE_MENU,
  SET_OTPVERIFY,
  SET_COVIDOPTION,
  LOGIN_MODAL,
  LAT_LNG,
  SET_ADMIN_TOKEN,
  ADMIN_LOGIN,
  SET_APPOINTMENT_HISTORY,
  SET_PROVIDER_DETAILS,
  SET_HEALTH_GRADE,
  SET_COOKIE_CONCENT

} from "./AuthTypes";

const AuthState = (props) => {
  const initialState = {
    user: StorageAdaptation.getLocalStorage("user", false, "local"),
    loading: false,
    token: StorageAdaptation.getLocalStorage("token", false, "local"),
    otpVerifiy: StorageAdaptation.getLocalStorage("otpVerifiy", false, "local"),
    covidOption: StorageAdaptation.getLocalStorage(
      "covidOption",
      false,
      "local"
    ),
    refreshToken: null,
    error: null,
    timmer: 0,
    filterData: null,
    headerSearchText: null,
    advance_search_modal: false,
    showLoader: false,
    responseModal: false,
    modal: false,
    loginModal: false,
    browserLatLng: false,
    adminMenuActive: "activedash",
    bookingList: StorageAdaptation.getLocalStorage(
      "bookingList",
      false,
      "local"
    ),
    cookie_concent: StorageAdaptation.getLocalStorage("cookie_concent", false, "local"),
    adminToken: StorageAdaptation.getLocalStorage("adminToken", false, "local"),
    admin: StorageAdaptation.getLocalStorage("admin", false, "local"),
    appointmentHistory: StorageAdaptation.getLocalStorage("appointmentHistory", false, "local"),
    providerDetails: StorageAdaptation.getLocalStorage("providerDetails", false, "local"),
    healthGradeResult: StorageAdaptation.getLocalStorage("healthGradeResult", false, "local"),
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // register (it call network to for register and set data accordingly )
  let timeCountId;
  const browserLatLng = (templatlng) => {
    templatlng.coords.latitude && templatlng.coords.longitude && dispatch({
      type: LAT_LNG,
      payload: { latitude: templatlng.coords.latitude, longitude: templatlng.coords.longitude },
    });
  }

  useEffect(() => {
    Global.getGeoLocation(browserLatLng);
    return () => {
      clearInterval(timeCountId);
    };
  }, []);

  const register = () => {
    // state.totalCartCount++;
    // dispatch({
    //     type: REGISTER,
    //     payload: state.totalCartCount
    // });
  };
  // login (it call network to for login and set data accordingly )

  const setLoader = () => {
    dispatch({
      type: SETLOADER,
    });
  };

  const unsetLoader = () => {
    dispatch({
      type: UNSETLOADER,
    });
  };

  const showModal = () => {
    dispatch({
      type: SHOW_MODAL,
    });
  };

  const hideModal = () => {
    dispatch({
      type: HIDE_MODAL,
    });
  };

  const showResponseModal = () => {
    dispatch({
      type: SHOW_RESPONSE_MODAL,
    });
  };

  const showLoginModal = (status) => {
    dispatch({
      type: LOGIN_MODAL,
      payload: status,
    });
  };

  const hideResponseModal = () => {
    dispatch({
      type: HIDE_RESPONSE_MODAL,
    });
  };

  const setUser = (userData) => {
    console.log("userData====>AuthContext", userData);
    StorageAdaptation.setLocalStorage("user", userData, false, "local");
    dispatch({
      type: LOGIN,
      payload: userData,
    });
  };

  const setCookieConent = () => {
    StorageAdaptation.setLocalStorage("cookie_concent", true, false, "local");
    dispatch({
      type: SET_COOKIE_CONCENT,
      payload: true,
    });
  };

  const setToken = (token) => {
    StorageAdaptation.setLocalStorage("token", token, false, "local");
    dispatch({
      type: SET_TOKEN,
      payload: token,
    });
  };

  const setCovidOption = (covidOption) => {
    StorageAdaptation.setLocalStorage(
      "covidOption",
      covidOption,
      false,
      "local"
    );
    dispatch({
      type: SET_COVIDOPTION,
      payload: covidOption,
    });
  };

  const setotpVerifiy = (email, status) => {
    
    let otpVerifiy = StorageAdaptation.getLocalStorage("otpVerifiy", false, "local");
    if(otpVerifiy && otpVerifiy.length) {
      let emailIndex = otpVerifiy.indexOf(email);
      if( status ) {
        if(emailIndex == '-1') {
          otpVerifiy.push(email);
        }
      } else {
        if(emailIndex > '-1') {
          otpVerifiy.splice(emailIndex, 1);
        }
      }
    } else {
      if( status ) {
        otpVerifiy = new Array(email);
      }
    }
    StorageAdaptation.setLocalStorage("otpVerifiy", otpVerifiy, false, "local");
    dispatch({
      type: SET_OTPVERIFY,
      payload: otpVerifiy,
    });
  };

  const unsetToken = (token) => {
    StorageAdaptation.removeLocalStorage("token", "local");
    dispatch({
      type: UNSET_TOKEN,
      payload: null,
    });
  };

  const addBookingList = (bookingList) => {
    console.log("Bookinglist====>AuthContext", bookingList);
    StorageAdaptation.setLocalStorage(
      "bookingList",
      bookingList,
      false,
      "local"
    );
    dispatch({
      type: SET_BOOKINGLIST,
      payload: bookingList,
    });
  };

  const setFilter = (filterData) => {
    dispatch({
      type: SET_FILTER,
      payload: filterData,
    });
  };

  const setHeaderSearchText = (payload) => {
    dispatch({
      type: SET_HEADERSEARCHTEXT,
      payload: payload,
    });
  };

  const setAdvDoctorSearchModal = (payload) => {
    dispatch({
      type: SET_ADVANCESEARCHMODAL,
      payload: payload,
    });
  };

  const removeFilter = () => {
    dispatch({
      type: SET_FILTER,
      payload: null,
    });
  };

  const toggleAdminMenu = (activeClassName) => {
    dispatch({
      type: SHOW_HIDE_MENU,
      payload: activeClassName,
    });
  };

  const networkError = () => {
    // alert("error");
  };

  const setTimmer = (time = 60, intervalTime = 1000) => {
    let timeCount = time;
    timeCountId = setInterval(() => {
      timeCount = timeCount - 1;
      dispatch({
        type: SET_TIMMER,
        payload: timeCount,
      });
      if (timeCount <= 0) {
        clearInterval(timeCountId);
      }
    }, intervalTime);
  };

  const logout = async () => {
    StorageAdaptation.removeLocalStorage("user", "local");
    dispatch({
      type: LOGOUT,
      payload: null,
    });
    StorageAdaptation.removeLocalStorage("token", "local");
    dispatch({
      type: UNSET_TOKEN,
      payload: null,
    });
  };

  const setAdminUser = (adminData) => {
    console.log("userData====>AuthContext", adminData);
    StorageAdaptation.setLocalStorage("admin", adminData, false, "local");
    dispatch({
      type: ADMIN_LOGIN,
      payload: adminData,
    });
  };

  const setAdminToken = (token) => {
    StorageAdaptation.setLocalStorage("adminToken", token, false, "local");
    dispatch({
      type: SET_ADMIN_TOKEN,
      payload: token,
    });
  };

  const setAppointmentHistory = (data) => {
    StorageAdaptation.setLocalStorage("appointmentHistory", data, false, "local");
    dispatch({
      type: SET_APPOINTMENT_HISTORY,
      payload: data,
    });
  };

  const setProviderDetails = (data) => {
    StorageAdaptation.setLocalStorage("providerDetails", data, false, "local");
    dispatch({
      type: SET_PROVIDER_DETAILS,
      payload: data,
    });
  };

  const sethealthGradeResult = (data) => {
    StorageAdaptation.setLocalStorage("healthGradeResult", data, false, "local");
    dispatch({
      type: SET_HEALTH_GRADE,
      payload: data,
    });
  };



  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        modal: state.modal,
        showModal,
        hideModal,
        register,
        setUser,
        setToken,
        unsetToken,
        setLoader,
        unsetLoader,
        responseModal: state.responseModal,
        showResponseModal,
        hideResponseModal,
        timmer: state.timmer,
        setTimmer,
        networkError,
        logout,
        filterData: state.filterData,
        setFilter,
        removeFilter,
        bookingList: state.bookingList,
        addBookingList,
        adminMenuActive: state.adminMenuActive,
        toggleAdminMenu,
        setotpVerifiy,
        otpVerifiy: state.otpVerifiy,
        setCovidOption,
        covidOption: state.covidOption,
        headerSearchText: state.headerSearchText,
        setHeaderSearchText,
        advance_search_modal: state.advance_search_modal,
        setAdvDoctorSearchModal,
        showLoginModal,
        loginModal: state.loginModal,
        browserLatLng: state.browserLatLng,
        setAdminToken,
        setAdminUser,
        adminToken: state.adminToken,
        admin: state.admin,
        setAppointmentHistory,
        appointmentHistory: state.appointmentHistory,
        setProviderDetails,
        providerDetails: state.providerDetails,
        sethealthGradeResult,
        healthGradeResult: state.healthGradeResult,
        cookie_concent: state.cookie_concent,
        setCookieConent
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
