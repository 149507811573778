import React, { Fragment, useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import Scheduling from '../../components/scheduling/scheduling';

import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';
import InputValidation from '../../reactLayer/validation';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import ProviderStep4 from '../../components/provider-steps/provider-step4';
import Error403 from '../../global/errorpages/error-403';
import moment from 'moment'

const Schedulingpage = (props) => {

    // console.log('props on scheduling page==>', props);
    const authContext = useContext(AuthContext);
    const timeList = ['12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM', '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM']
    const [calenderTimeList,setCalenderTimeList] = useState([])
    const [key, setKey] = useState(props.tab ? props.tab : '1');
    const profile = props.userprofile ? props.userprofile : '';
    const mailtoEmail = 'g.docscheduler@gmail.com';//authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.email ? authContext.user.accountInfo.email : 'info@docscheduler.com';
    const mailtoFname = authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.firstName ? authContext.user.accountInfo.firstName + ' ' : '';
    const mailtoMname = authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.middleName ? authContext.user.accountInfo.middleName + ' ' : '';
    const mailtoLname = authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.lastName ? authContext.user.accountInfo.lastName + ' ' : '';
    const changeTab = (e) => {
        e.preventDefault()
        setKey(e.currentTarget.dataset.rbeventkey);
    }
    const [slots, setslots] = useState([]);
    const practiceDataStart = {

        visitDuration: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'visitDuration',
        },
        padding: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'padding',
        },
        locationId: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'locationId',
        },
        tZone: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'tZone',
        },
        openTime: {
            value: [],
            validation: ['arrayempty'],
            errorMsg: ['This field is required'],
            errorKey: 'openTime',
        },
        breakStart: {
            value: '12:00 PM',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'breakStart',
            breakStart: false

        },
        breakEnd: {
            value: '1:00 PM',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'breakEnd',
            breakEnd: false
        },
        practiceHours: {
            value: [],
        }
    };
    const [practiceData, setpracticeData] = useState([practiceDataStart]);
    const [alllocation, setalllocations] = useState([]);
    const [practiceHours, setpracticeHours] = useState([])
    const [selectedlocation, setselectedlocation] = useState(null);
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    const [passwordInputType, setpasswordInputType] = useState('password');
    const changepasswordInputType = () => {
        setpasswordInputType(passwordInputType === 'text' ? 'password' : 'text');
    }
    const userConstData = [
        {
            fname: {
                value: '',
                validation: ['name'],
                errorMsg: ['Invalid frist name'],
                errorKey: 'fname',
                fname: false
            },
            lname: {
                value: '',
                validation: ['name'],
                errorMsg: ['Invalid last name'],
                errorKey: 'lname',
                lname: false
            },
            email: {
                value: '',
                validation: ['email'],
                errorMsg: ['Invalid email'],
                errorKey: 'email',
                email: false
            }
        },
        {
            fname: {
                value: '',
                validation: ['name'],
                errorMsg: ['Invalid frist name'],
                errorKey: 'fname',
                fname: false
            },
            lname: {
                value: '',
                validation: ['name'],
                errorMsg: ['Invalid last name'],
                errorKey: 'lname',
                lname: false
            },
            email: {
                value: '',
                validation: ['email'],
                errorMsg: ['Invalid email'],
                errorKey: 'email',
                email: false
            }
        },
        {
            fname: {
                value: '',
                validation: ['name'],
                errorMsg: ['Invalid frist name'],
                errorKey: 'fname',
                fname: false
            },
            lname: {
                value: '',
                validation: ['name'],
                errorMsg: ['Invalid last name'],
                errorKey: 'lname',
                lname: false
            },
            email: {
                value: '',
                validation: ['email'],
                errorMsg: ['Invalid email'],
                errorKey: 'email',
                email: false
            }
        }
    ]
    const [userData, setuserData] = useState({
        password: {
            value: '',
            validation: ['password'],
            errorMsg: ['Invalid password'],
            errorKey: 'password',
            password: false
        },
        userList: []
    });
    const [openTime, setOpenTime] = useState([]);
    const openTimehandle = (e) => {
        let index, name, value,
            tempData = [];
        //  return false;
        index = e.currentTarget.getAttribute('data-index');
        name = e.currentTarget.getAttribute('data-name');
        value = e.currentTarget.value;
        tempData = practiceData;
        let temppracticehours = tempData[index]['practiceHours']['value'];

        // console.log('check array', index,name);
        let arrayIndex = tempData[index][name]['value'].indexOf(value);
        if (arrayIndex > '-1') {
            tempData[index][name]['value'].splice(arrayIndex, 1);
            tempData[index][name][name] = '';
            delete temppracticehours[index][value];
        } else {
            tempData[index][name]['value'].push(value);
            tempData[index][name][name] = '';
            temppracticehours[index] = { ...temppracticehours[index], ...{ [value]: { day: value, start: '8:00 AM', end: '5:00 PM', error: false } } };

        }
        tempData[index]['practiceHours']['value'] = temppracticehours;
        // console.log('testing of pdata', tempData);
        // setpracticeHours([...temppracticehours]);
        setpracticeData([...tempData]);
        // console.log('practiceData===on modification==>', practiceData);
    }

    const changeOpenTime = (e) => {
        let name = e.currentTarget.getAttribute('data-name');
        let day = e.currentTarget.getAttribute('data-day');
        let index = e.currentTarget.getAttribute('data-index');
        let value = e.currentTarget.value;
        let practHours = practiceData[index]['practiceHours']['value']; //practiceHours;
        let tempPH = practHours[index][day];
        tempPH = { ...tempPH, ...{ [name]: value, 'error': false } };
        practHours[index][day] = tempPH;
        // setpracticeHours([...practHours]);
        let tempPD = practiceData;
        tempPD[index]['practiceHours']['value'] = practHours;
        setpracticeData([...tempPD]);
        // console.log('practice HOurs', practHours);
    }
    // console.log('Test user data', userData);  
    const modifyDataST = (e) => {
        let value = '', name = '', index = '';
        name = e.currentTarget.getAttribute('name');
        index = e.currentTarget.getAttribute('data-index');
        value = e.currentTarget.value;
        let tempUserData = userData;
        // console.log('tempUserData===>before', tempUserData);
        if (name === 'password') {
            tempUserData.password['value'] = value;
        } else {
            // console.log('index-->', index, 'name-->', name);
            // console.log('fasfasf', tempUserData.userList[index]);
            tempUserData.userList[index][name][name] = false;
            tempUserData.userList[index][name]['value'] = value;
        }
        // console.log('tempUserData===>after', tempUserData);
        setuserData({ ...userData, ...tempUserData });
    }
    const formDataSubmit = async () => {

        let userList = userData.userList;
        let password = userData.password;
        let tempUserList = [];
        let flag = true;
        //    console.log('onSubmit data===>', userList, password);
        userList.map((ul) => {
            if (ul.fname.value.trim() !== '' && ul.lname.value.trim() !== '' && ul.email.value.trim() !== '') {
                tempUserList.push({
                    firstName: ul.fname.value.trim(),
                    lastName: ul.lname.value.trim(),
                    email: ul.email.value.trim()
                })
            }
            if (ul.fname.value.trim() !== '' || ul.lname.value.trim() || '' && ul.email.value.trim() !== '') {
                if( ul.fname.value.trim() == '' )  {
                    flag = false;
                    ul.fname.fname = 'This field is required'; 
                } 
                if( ul.lname.value.trim() == '' )  {
                    flag = false;
                    ul.lname.lname = 'This field is required'; 
                } 
                if( ul.email.value.trim() == '' )  {
                    flag = false;
                    ul.email.email = 'This field is required'; 
                } 
            }   
        })
        if( !flag ) {
            setuserData({ ...userData, ...{ userList: userList } });
            
            return false;
        }
        if (tempUserList.length && password.value.trim() == "") {
            password.password = 'This field is required';
            //    console.log('check password', password);
            setuserData({ ...userData, ...{ password: password } });
            flag = false;
            return false;
        } else {
            const data = {
                "schedulingTeam": tempUserList,
                "teamPassword": password.value ? password.value.trim() : ''
            }
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'appointment/schedule/teams',
                body: JSON.stringify(data),
            }
            authContext.setLoader();
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            // console.log('result ===>', result);
            if (result.body.status) {

                setmodalProps({
                    type: 'success',
                    msg: 'Updated Successfully'
                });
                authContext.showResponseModal();
            } else {
                if (result.body.alreadyRegister) {
                    setmodalProps({
                        type: 'error',
                        msg: result.body.message
                    });
                    authContext.showResponseModal();
                } else {
                    setmodalProps({
                        type: 'error',
                        msg: result.body.message,
                    });
                    authContext.showResponseModal();
                }
            }
        }
    }
    const formSubmit = () => {

        let tempUserList = userData.userList;
        let password = { password: userData.password };
        // console.log('password test--password-->', password);
        let flag = true;

        tempUserList.map((ul, ind) => {
            const validationResult = InputValidation(ul);
            if (Object.keys(validationResult).length > 0) {
                flag = false;
                for (const prop in validationResult) {
                    // console.log('prop', tempUserList[ind][prop][prop]);
                    tempUserList[ind][prop][prop] = `${validationResult[prop]}`;
                }
            }
        })
        const validationResult = InputValidation(password);
        // console.log('password test---->', validationResult);
        if (Object.keys(validationResult).length > 0) {
            flag = false;
            for (const prop in validationResult) {
                password[prop][prop] = `${validationResult[prop]}`;
            }
        }

        setuserData({ ...password, ...{ userList: tempUserList } });
        if (flag) {
            formDataSubmit()
        }
    }

    const getUserList = async () => {
        authContext.setLoader();
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'appointment/schedule/getTeams'
        }
        let result = await NetworkLayer.getRequest(submitData);
        // console.log('get result ===>', result);
        if (result.status) {
            let tempUsersList = [...userConstData];
            let userpassword = userData.password;
            result.result && result.result.schedulingTeam && result.result.schedulingTeam.map((ul, index) => {
                tempUsersList[index]['fname']['value'] = ul.firstName;
                tempUsersList[index]['lname']['value'] = ul.lastName;
                tempUsersList[index]['email']['value'] = ul.email;
            })
            userpassword.value = result.result.password;
            setuserData({ ...{ password: userpassword }, ...{ userList: tempUsersList } });
            authContext.unsetLoader();
        } else {
            let tempUsersList = [...userConstData];
            setuserData({ ...userData, ...{ userList: tempUsersList } });
            authContext.unsetLoader();
        }
    }
    const afterSetCountFinished = () => {
        // console.log('afterSetCountFinished', practiceHours);
    }
    const chPracHours = async (pInfo) => {
        let openT = [];
        let pHours = []
        pInfo.practiceHours.map((ph, i) => {
            openT.push(ph.day);
            pHours[0] = { ...pHours[0], ...{ [ph['day']]: ph } };
        });
        // console.log('pHours===>',pHours);


        return { openT: openT, pHours: pHours };
    }
    const setPracticeInfo = async (pInfoData) => {
        debugger;
        let practData = [];
        let pInfo = pInfoData;
        let openT = [];
        let pHours = [];

        pInfo.practiceHours && pInfo.practiceHours.length && pInfo.practiceHours.map((ph, i) => {
            openT.push(ph.day);
            pHours[0] = { ...pHours[0], ...{ [ph['day']]: ph } };
        });
        // console.log('pHours===>',pHours);

        // setpracticeHours([...pHours]);


        practData.push({

            visitDuration: {
                value: pInfo.visitDuration ? pInfo.visitDuration : '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'visitDuration',
            },
            padding: {
                value: pInfo.padding ? pInfo.padding : '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'padding',
            },
            locationId: {
                value: pInfo.locationId ? pInfo.locationId : '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'locationId',
            },
            tZone: {
                value: pInfo.timezone ? pInfo.timezone : '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'tZone',
            },
            openTime: {
                value: openT ? openT : [],
                validation: ['arrayempty'],
                errorMsg: ['This field is required'],
                errorKey: 'openTime',
            },
            breakStart: {
                value: pInfo.breakTime && pInfo.breakTime.start ? pInfo.breakTime.start : '12:00 PM',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'breakStart',
                breakStart: false

            },
            breakEnd: {
                value: pInfo.breakTime && pInfo.breakTime.end ? pInfo.breakTime.end : '1:00 PM',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'breakEnd',
                breakEnd: false
            },
            practiceHours: {
                value: pHours
            }

        })
        
        let beginningTime = ""
        let beginningTime1 = ""
        let minTime = ""
        let maxTime = ""
        if(practData[0].practiceHours){
            if(practData[0].practiceHours.value[0]){
                const dateData = practData[0].practiceHours.value[0];
                for(let k in dateData){
                    
                    let endTime = moment(dateData[k].start,'h:mm a')
                    if(beginningTime === ""){
                        beginningTime = endTime;
                        minTime = dateData[k].start
                    }
                    if(beginningTime.isBefore(endTime)){
                        beginningTime = endTime;
                        minTime = dateData[k].start
                    } 

                    let endTime1 = moment(dateData[k].end,'h:mm a')
                    if(beginningTime1 === ""){
                        beginningTime1 = endTime1;
                        maxTime = dateData[k].end
                    }
                    if(!beginningTime1.isBefore(endTime1)){
                        beginningTime1 = endTime1;
                        maxTime = dateData[k].end
                    }

                }
            }
        }
        let calenderTimeListTemp = []
      //  debugger;
        for(let k = 0; k < timeList.length; k++ ){
            if(timeList[k] === minTime || timeList[k] === maxTime || (moment(timeList[k],'h:mm a').isAfter(moment(minTime,'h:mm a')) && moment(timeList[k],'h:mm a').isBefore(moment(maxTime,'h:mm a')))){
                calenderTimeListTemp.push(timeList[k])
            }
        }
        setCalenderTimeList(calenderTimeListTemp)

        setpracticeData([...practData]);
    }
    const setSelectedLocation = (locationId, alllocation) => {
        let location = {};
        alllocation.filter((d, i) => {
            if (parseInt(locationId) === parseInt(d.locationid)) {
                location = d;
            }
        });
        // setPracticeInfo(location);

    }
    const getLocationBasedDetails = async (locationId, alllocation = [], date = '',updateField = true) => {
        authContext.setLoader();
        // setSelectedLocation(locationId,alllocation);
        let startFrom, cDate;
        if (date) {
            cDate = Global.getYearMonthDate(date);
        } else {
            cDate = Global.getYearMonthDate();
        }
        startFrom = cDate.year + '-' + cDate.month + '-' + cDate.date;
        let fData = { startFrom: startFrom, locationId: locationId };

        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'appointment/schedule',
            body: JSON.stringify(fData),
        }
        let result = await NetworkLayer.postData(submitData);
        // console.log('getLocationBasedDetails result ===>', result);
        if (result.body.status) {
            // console.log('Test data==>', result.body.result.location);
            if (result.body.result.location ) {
                setPracticeInfo(result.body.result.location);
            } else {
                debugger
                console.log('updateField',updateField)
                if(updateField === true){
                    setpracticeData([practiceDataStart]);
                }
            }
            if (result.body.result.slots) {
                setslots([...result.body.result.slots]);
            } else {
                setslots([]);
            }
            result.body.result.slots && setslots([...result.body.result.slots]);
            authContext.unsetLoader();
        } else {

            setpracticeData([practiceDataStart]);
            authContext.unsetLoader();
        }
    }
    const changeSelectedLocation = (e) => {
        if (e && e.currentTarget.value) {
            let value = e.currentTarget.value;
            getLocationBasedDetails(value);
            let tempData = practiceData;
            tempData[0]['locationId']['value'] = value;
            tempData[0]['locationId']['locationId'] = '';
            setpracticeData([...tempData]);
        }
    }
    const getAllLocations = async () => {
        authContext.setLoader();
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'appointment/getLocations'
        }
        let result = await NetworkLayer.getRequest(submitData);
        // console.log('getAllLocations result ===>', result);
        if (result.status) {
            setalllocations(result.result);
            let pd = practiceData;
            pd[0]['locationId']['value'] = result.result[0]['locationid'];
            
            setpracticeData([...pd])
            getLocationBasedDetails(result.result[0]['locationid'], result.result,'',false);
            authContext.unsetLoader();
        } else {
            setalllocations([]);
            authContext.unsetLoader();
        }
    }
    const modifyPracticeData = (e) => {

        let index, name, value,
            tempData = [];
        index = e.currentTarget.getAttribute('data-index');
        name = e.currentTarget.getAttribute('data-name');
        value = e.currentTarget.value;
        //  return false;
        tempData = practiceData;
        tempData[index][name]['value'] = value;
        tempData[index][name][name] = '';
        setpracticeData([...tempData]);
        if (name === 'locationId') {
            getLocationBasedDetails(value);
        }
        // formSubmit();
    }
    const practiceDataSubmit = async () => {
        // console.log('practive Data', practiceData);
        let temppracticeData = practiceData;
        let flag = true;
        let setFocus = false;
        let PractHours = practiceHours;
        let practData = [];

        temppracticeData.forEach((currentValue, index) => {

            let validationResult = InputValidation(currentValue);
            let tempPractHours = currentValue.practiceHours.value[index];// PractHours[index];
            let practHoursIndex = [];
            for (const prop in tempPractHours) {
                practHoursIndex.push({ day: tempPractHours[prop]['day'], start: tempPractHours[prop]['start'], end: tempPractHours[prop]['end'] });
                let start = Global.convertTime12to24(tempPractHours[prop]['start']);
                let end = Global.convertTime12to24(tempPractHours[prop]['end']);
                // console.log('start==>',start,'end==>',end);
                if (start >= end) {
                    tempPractHours[prop]['error'] = 'Start time should be less than close time';
                    // PractHours[index] = tempPractHours;
                    if (!flag && !setFocus) {
                        setFocus = true;
                        flag = false;
                        // console.log('check how may times');
                        // let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({block: "start", behavior: "smooth"});

                    }
                }

            }

            let ms = {
                "visitDuration": currentValue.visitDuration.value,
                "padding": currentValue.padding.value,
                "locationId": parseInt(currentValue.locationId.value),
                "timeZone": currentValue.tZone.value,
                "practiceHours": practHoursIndex,//[{"day":"Monday","start":"8 AM","end":"8 PM"},{"day":"Tuesday","start":"8 AM","end":"8 PM"},{"day":"Wednesday","start":"8 AM","end":"8 PM"}],
                "breakTime": { "start": currentValue.breakStart.value, "end": currentValue.breakEnd.value },
                // "sortOrder": index+1
            };
            practData.push(ms);
            if (Object.keys(validationResult).length > 0) {
                flag = false;
                for (const prop in validationResult) {
                    temppracticeData[index][prop][prop] = `${validationResult[prop]}`;
                    if (!flag && !setFocus) {
                        setFocus = true;
                        flag = false;
                        // console.log('index==>props', index, prop);
                        let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
                    }
                }
                setpracticeData([...temppracticeData]);
            } else {
                let start = Global.convertTime12to24(currentValue.breakStart.value.trim());
                let end = Global.convertTime12to24(currentValue.breakEnd.value.trim());
                // console.log('start==>',start,'end==>',end);
                if (start >= end) {
                    temppracticeData[index]['breakStart']['breakStart'] = 'Start time should be less than close time';
                    if (!flag && !setFocus) {
                        setFocus = true;
                        // console.log('check how may times');
                        let element = document.querySelectorAll(`[data-index="${index}"][data-name="breakStart"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });

                    }
                    setpracticeData([...temppracticeData]);
                    flag = false;
                }
            }
            // console.log('practiceData current value ', practiceData);
        });
        // return false;
        if (flag) {
            authContext.setLoader();
            const fData = {
                "scheduleInformation": practData[0],
            }

            // console.log('fData', fData);
            // console.log(fData,JSON.stringify(fData));
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'appointment/schedule/practiceSettings',
                body: JSON.stringify(fData),

            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            // console.log('result-on submit final data', result);
            if (result.body.status) {
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                getLocationBasedDetails(practData[0].locationId)

            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }

    }

    const changeDate = (date) => {
        let chDate = date;

        let sdate = new Date(chDate.setHours(0, 0, 0, 0)).getTime();
        let cdate = new Date().getTime();
        if (sdate < cdate) {
            chDate = new Date();
        }
        getLocationBasedDetails(practiceData[0].locationId.value, '', chDate);
    }

    const enableDisbleSlots = async (sl, dt, stdate) => {
        if (sl && dt) {
            authContext.setLoader();
            const fData = {
                slotTime: sl,
                locationId: practiceData[0].locationId.value,
                appointmentDate: dt
            }
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'appointment/update/slots',
                body: JSON.stringify(fData),

            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            // console.log('result-on submit final data', result);
            if (result.body.status) {
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
                getLocationBasedDetails(practiceData[0].locationId.value, '', new Date(stdate));
                authContext.showResponseModal();
            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }
    }

    useEffect(() => {
        getUserList();
        getAllLocations();
        return () => {
            props.setscheduleTab && props.setscheduleTab(1)
        }
    }, [])

    // useEffect(() => {
    //     let pd = practiceData;
    //     pd[0]['openTime']['value'] = openTime;

    //     console.log('pdpdpdpdpdpdppdpdpdpdpd', pd);
    //     setpracticeData([...pd]);
    // },openTime)

    return (

        profile && profile.providerCurrentPlan && profile.providerCurrentPlan.providerCurrentServices && profile.providerCurrentPlan.providerCurrentServices.indexOf(4) > '-1' ? (<>
            <Container>
                <Row className="mb-4">
                    {
                        authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                    }
                    <div className="col-12 mb-4">
                        <div className="scheduling-page">

                            <div className="profile-tabs">
                                <nav className="nav nav-tabs" role="tablist">
                                    <a onClick={(e) => changeTab(e)} href="#" role="tab" data-rbeventkey="1" aria-controls="controlled-tab-example-tabpane-profile" aria-selected="true" tabIndex={key == '1' ? '' : '-1'} className={key == '1' ? 'nav-item nav-link active' : 'nav-item nav-link'}>
                                        <div className="tabTitle">
                                            <h3>Scheduling Team Members</h3>
                                        </div>
                                    </a>
                                    <a onClick={(e) => changeTab(e)} id="controlled-tab-template" href="#" role="tab" data-rbeventkey="2" aria-controls="controlled-tab-example-tabpane-home" tabIndex={key == '2' ? '' : '-1'} aria-selected="false" className={key == '2' ? 'nav-item nav-link active' : 'nav-item nav-link'}>
                                        <div className="tabTitle">
                                            <h3>Scheduling Template</h3>
                                        </div>
                                    </a>

                                </nav>

                                <div className="tab-content">
                                    <div id="controlled-tab-template" aria-labelledby="controlled-tab-example-tab-home" role="tabpanel" aria-hidden={key == '21' ? "true" : "false"} className={key == '2' ? "fade tab-pane active show" : "fade tab-pane"}>
                                        <Row >
                                            <div className="col-12 heading">
                                                <h4>Update/Edit your scheduling availability for the next five (5) weeks</h4>
                                            </div>
                                            {/* section 1 */}
                                            <div className="col-lg-6 provider-form-inner">
                                                <div className="col-12 col-md-10 mb-3">
                                                    <label className="pro-label">Location</label>
                                                    <div className="myform  ">
                                                        <select
                                                            onChange={(e) => modifyPracticeData(e)}
                                                            data-index={0}
                                                            value={practiceData[0]['locationId']['value'] ? practiceData[0]['locationId']['value'] : ''}
                                                            data-name={'locationId'}
                                                            className={practiceData[0]['locationId']['locationId'] ? "select  error-border" : "select"}
                                                        >
                                                            {
                                                                alllocation.map((location, locationIndex) =>
                                                                    <option value={location.locationid}>{location.practiceName ? location.practiceName + ' ' : ''} {location.practiceAddress ? location.practiceAddress + ' ' : ''} {location.city ? location.city + ' ' : ''} {location.state ? location.state + ' ' : ''} {location.zipCode ? location.zipCode : ''}</option>
                                                                )
                                                            }
                                                        </select>
                                                        <small className="error-input">{practiceData[0]['locationId']['locationId']}</small>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-10 mb-3">
                                                    <label className="pro-label">Practice Office Hours</label>
                                                </div>
                                                {practiceData.map((data, index) =>
                                                    <>
                                                        <div className="provider-steps-wrap">
                                                            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((d, windex) =>
                                                                <Row className="practice-hour-box" key={d + '-main-' + index}>
                                                                    <span>
                                                                        <div className="cus-check-rev">
                                                                            <input type="checkbox" id={d + '-' + index} name="lang"
                                                                                data-index={index}
                                                                                data-name='openTime'
                                                                                value={d}
                                                                                onChange={(e) => openTimehandle(e)}
                                                                                checked={data.openTime.value.indexOf(d) > '-1' ? true : false}
                                                                            />
                                                                            <label htmlFor={d + '-' + index}>{d}</label>
                                                                        </div>
                                                                    </span>
                                                                    {
                                                                        data.openTime.value.indexOf(d) > '-1' ? (
                                                                            <>
                                                                                <span>
                                                                                    <div className='myform'>
                                                                                        <select className='select' value={data.practiceHours.value[index][d]['start'] ? data.practiceHours.value[index][d]['start'] : ''} data-day={d} data-name="start" data-index={index} onChange={(e) => { changeOpenTime(e) }} >
                                                                                            {timeList.map((t, ti) =>
                                                                                                <option key={'start-' + index + '-' + t} value={t}>{t}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </span>
                                                                                <span>to</span>
                                                                                <span>
                                                                                    <div className='myform'>
                                                                                        <select className='select' value={data.practiceHours.value[index][d]['end'] ? data.practiceHours.value[index][d]['end'] : ''} data-day={d} data-name="end" data-index={index} onChange={(e) => { changeOpenTime(e) }}  >
                                                                                            {timeList.map((t, ti) =>
                                                                                                <option key={'end-' + index + '-' + t} value={t}>{t}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </span>
                                                                                <small className="error-input time-error">{data.practiceHours.value[index][d]['error']}</small>
                                                                            </>
                                                                        ) : (<><span>Not Working Day</span> </>)
                                                                    }
                                                                </Row>
                                                            )}
                                                            <small className="error-input">{data.openTime.openTime}</small>
                                                        </div>
                                                        <div className="col-12 col-md-10 mb-3 margin-30">
                                                            <label className="pro-label">Visit Duration</label>
                                                            <div className="myform ">
                                                                <select
                                                                    className={data.visitDuration.visitDuration ? "select  error-border" : "select"}
                                                                    value={data.visitDuration.value === '00:00' ? 30 : data.visitDuration.value}
                                                                    data-index={index}
                                                                    data-name="visitDuration"
                                                                    onChange={(e) => { modifyPracticeData(e) }}
                                                                >
                                                                    <option key={'visit'} value="">Select</option>
                                                                    <option key={'15-visit'} value="15">15 Mins</option>
                                                                    <option key={'30-visit'} value="30">30 Mins</option>
                                                                    <option key={'45-visit'} value="45">45 Mins</option>
                                                                    <option key={'60-visit'} value="60">60 Mins</option>
                                                                </select>
                                                                <small className="error-input">{data.visitDuration.visitDuration}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-10 mb-3 margin-30 provider-steps-wrap">
                                                            <label className="pro-label info-hg">Padding 
                                                                <div className="info-icon"></div>
                                                                <div class="info-callout left-right"> Please Provide Text </div>
                                                            </label>
                                                            <div className="myform practice-hour-box row custom-padding">

                                                                <label>add</label>
                                                                
                                                                <div className='myform '>
                                                                    <select
                                                                        className={data.padding.padding ? "select  error-border" : "select"}
                                                                        value={data.padding.value === '00:00' ? 0 : data.padding.value}
                                                                        data-index={index}
                                                                        data-name="padding"
                                                                        onChange={(e) => { modifyPracticeData(e) }}
                                                                    >
                                                                        <option key={'padding'} value="">Select</option>
                                                                        <option key={'0-padding'} value="0">0 Mins</option>
                                                                        <option key={'5-padding'} value="5">5 Mins</option>
                                                                        <option key={'10-padding'} value="10">10 Mins</option>
                                                                        <option key={'15-padding'} value="15">15 Mins</option>

                                                                    </select>
                                                                </div>
                                                                <label>after each visit</label>
                                                                <small className="error-input">{data.padding.padding}</small>

                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-10 mb-3 margin-30 provider-steps-wrap">
                                                            <label className="pro-label info-hg">Break 
                                                                <div className="info-icon"></div>
                                                                <div class="info-callout left-right"> Please Provide Text </div>
                                                            </label>
                                                            <div className="myform practice-hour-box row">
                                                                <span>
                                                                    <label>Block calendar from</label>
                                                                </span>
                                                                <span>

                                                                    <div className='myform'>
                                                                        <select
                                                                            className={data.breakStart.breakStart ? "select  error-border" : "select"}
                                                                            value={data.breakStart.value}
                                                                            data-index={index}
                                                                            data-name="breakStart"
                                                                            onChange={(e) => { modifyPracticeData(e) }}
                                                                        >
                                                                            {calenderTimeList.map((t, ti) =>
                                                                                <option key={'start-' + 'index' + '-' + t} value={t}>{t}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                    <small className="error-input error-block-cal">{data.breakStart.breakStart}</small>
                                                                </span>
                                                                <span>to</span>
                                                                <span>
                                                                    <div className='myform'>
                                                                        <select
                                                                            className={data.breakEnd.breakEnd ? "select  error-border" : "select"}
                                                                            value={data.breakEnd.value}
                                                                            data-index={index}
                                                                            data-name="breakEnd"
                                                                            onChange={(e) => { modifyPracticeData(e) }}
                                                                        >
                                                                            {calenderTimeList.map((t, ti) =>
                                                                                <option key={'end-' + 'index' + '-' + t} value={t}>{t}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                    <small className="error-input">{data.breakEnd.breakEnd}</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-12">
                                                        <div className="button">
                                                            <a href="/#">Cancel</a>
                                                        </div>
                                                        <div className="button disabled">
                                                            <a href="/#">All Changes Saved</a>
                                                        </div>
                                                    </div> */}
                                                        {/* <div className="button disabled">
                                                        <a href="/#">Cancel</a>
                                                        <a href="/#">All Changes Saved</a>
                                                    </div> */}
                                                    </>
                                                )}



                                                <div className="col-12 mb-5 mt-5 text-center provider-steps-wrap">
                                                    <div className="next-prev-btn-pro">
                                                        {/* <a href="/#" data-prev-page="account-information" className="prev-btn-pro mb-4">Previous</a> */}
                                                        <input type="button" className="next-btn-pro mb-4" onClick={practiceDataSubmit} value="Save Changes" />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* section 2 */}
                                            <div className="col-lg-6 provider-form-inner">
                                                <div className="col-12 col-md-10 mb-3">
                                                    <label className="pro-label">USA/Eastern Standard Time (EST)</label>
                                                    <div className="myform ">
                                                        <select
                                                            className={practiceData[0]['tZone']['tZone'] ? "select  error-border" : "select"}
                                                            value={practiceData[0]['tZone']['value'] ? practiceData[0]['tZone']['value'] : ''}
                                                            data-index={0}
                                                            data-name="tZone"
                                                            onChange={(e) => { modifyPracticeData(e) }}
                                                        >
                                                            <option value="">Time Zone</option>
                                                            <option value="HST">Hawaii Standard Time (HST){practiceData[0]['tZone']['value'] ? practiceData[0]['tZone']['value'] : ''}</option>
                                                            <option value="AKST">Alaska Standard Time (AKST)</option>
                                                            <option value="PST">Pacific Standard Time (PST)</option>
                                                            <option value="MST">Mountain Standard Time (MST)</option>
                                                            <option value="CST">Central Standard Time (CST)</option>
                                                            <option value="EST">Eastern Standard Time (EST)</option>
                                                        </select>
                                                        <small className="error-input">{practiceData[0]['tZone']['tZone'] ? practiceData[0]['tZone']['tZone'] : ''}</small>
                                                    </div>
                                                </div>
                                                <p>
                                                    Click on individual time slots below to identify available (blue) and unavailable (grey) appointment slots. Alternatively, you can have our customer service team set up your scheduling template by uploading screen shots of your scheduling book <a href={`mailto:${mailtoEmail}?subject=Scheduling Template Request || (${mailtoFname}${mailtoMname}${mailtoLname})`}>here</a>!
                                            </p>
                                                <Scheduling enableDisbleSlots={enableDisbleSlots} upgrade={false} selectedDate={''} changeDate={changeDate} slots={slots} />
                                            </div>
                                        </Row>
                                    </div>


                                    <div id="controlled-tab-team-member" aria-labelledby="controlled-tab-example-tab-home" role="tabpanel" aria-hidden={key == '1' ? "true" : "false"} className={key == '1' ? "fade tab-pane active show" : "fade tab-pane"}>
                                        {authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.subRoleId && authContext.user.accountInfo.subRoleId === 5 ? (<>
                                            <Error403 />

                                        </>) : (<>
                                            <Row>

                                                <div className="col-12 heading">
                                                    <h4>Add scheduling team members that you want to have access to view your DocScheduler scheduling templates and appointment request</h4>
                                                </div>
                                                {
                                                    userData.userList.map((ud, udIndex) =>
                                                        <Row className="provider-form-inner member" key={'userList-' + udIndex}>
                                                            <label className="pro-label number ">{udIndex + 1}.</label>
                                                            <div className="col-md-4 mb-3 section">
                                                                <label htmlFor={'fname-' + udIndex} className="pro-label">First Name</label>
                                                                <input type="text" id={'fname-' + udIndex} name="fname" placeholder="First Name" autoComplete="off"
                                                                    className={ud.fname.fname ? 'input-pro  error-border' : 'input-pro'}
                                                                    onChange={(e) => modifyDataST(e)}
                                                                    data-index={udIndex}
                                                                    value={ud.fname.value}

                                                                />
                                                                <small className="error-input">{ud.fname.fname}</small>
                                                            </div>
                                                            <div className="col-md-4 mb-3 section">
                                                                <label htmlFor={'lname-' + udIndex} className="pro-label">Last Name</label>
                                                                <input type="text" id={'lname-' + udIndex} name="lname" placeholder="Last Name" autoComplete="off"
                                                                    className={ud.lname.lname ? 'input-pro  error-border' : 'input-pro'}
                                                                    onChange={(e) => modifyDataST(e)}
                                                                    data-index={udIndex}
                                                                    value={ud.lname.value}

                                                                />
                                                                <small className="error-input">{ud.lname.lname}</small>
                                                            </div>
                                                            <div className="col-md-4 mb-3 section">
                                                                <label htmlFor={'email-' + udIndex} className="pro-label">Email</label>
                                                                <input type="text" id={'email-' + udIndex} name="email" placeholder="Email" autoComplete="off"
                                                                    className={ud.email.email ? 'input-pro  error-border' : 'input-pro'}
                                                                    onChange={(e) => modifyDataST(e)}
                                                                    data-index={udIndex}
                                                                    value={ud.email.value}

                                                                />
                                                                <small className="error-input">{ud.email.email}</small>
                                                            </div>
                                                        </Row>
                                                    )
                                                }
                                                <Row className="provider-form-inner member provider-steps-wrap" key={'password'}>
                                                    <div className="col-12 heading">
                                                        <h4>Team Password for Appointment Request Data Sharing</h4>
                                                    </div>

                                                    <div className="col-md-8 mb-3 no-padding">
                                                        <label htmlFor="password" className="pro-label">Create a Password</label>
                                                        <input type={passwordInputType} data-index="0" 
                                                            name="password" 
                                                            placeholder="Password" 
                                                            autoComplete="new-password"
                                                            className={userData.password.password ? 'input-pro  error-border' : 'input-pro'}
                                                            onChange={(e) => modifyDataST(e)}
                                                            value={userData.password.value}
                                                        />
                                                        <div className="eye-icon" onClick={() => { changepasswordInputType() }}><div className={passwordInputType === 'text' ? "pass-show-eye-icon" : "pass-hide-eye-icon"}></div></div>
                                                        <small className="error-input">{userData.password.password}</small>
                                                    </div>
                                                    <div className="col-md-8 px-0 mb-3">
                                                        <div className="pass-rules">
                                                            <div className="head-rules">Password Rules:</div>
                                                            <ul>
                                                                <li>Has at least 8 characters</li>
                                                                <li>Must contain letters, at least 1 number, and at least 1 special character</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-0 mt-3 mb-md-5 mt-md-5 text-center provider-steps-wrap">
                                                        <div className="next-prev-btn-pro">
                                                            <input type="button" className="next-btn-pro mb-md-4" onClick={formSubmit} value="Save" />
                                                        </div>
                                                    </div>


                                                </Row>
                                            </Row>
                                        </>)}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>

        </>) : (<>
            <Container className="appointment-wrap modal-wrapper">
                <Row>
                    <div className="col-12 profile-plans">
                        <div className="provider-step4-wrap">
                            <ProviderStep4 title={'Manage Subscriptions'} />
                        </div>
                    </div>
                </Row>
            </Container>
        </>)
    )

}

export default Schedulingpage;