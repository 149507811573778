import React, { Fragment, useEffect, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import ManagementFilter from '../patient/filter';
import Paging from '../patient/paging';
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';

let searchText = ""
const ManagementAppointment = (props) => {

    const authContext = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [viewdetails, setViewdetails] = useState([]);
    const [sort, setsort] = useState('DESC');
    const [sortName, setSortName] = useState('createdAt');

    const getList = async () => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        console.log('searchText',searchText)
        if (searchText) {
            formData = {
                userId: props.providerDetails ?.accountInfo ?.id,
                searchValue: searchText,
                sortOrder: sort,
                sortBy: sortName
            }
        } else {
            formData = {
                userId: props.providerDetails ?.accountInfo ?.id,
                sortOrder: sort,
                sortBy: sortName
            }
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/appointment-history/get',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        // console.log("result==>", result.body.result);
        setData(result ?.body ?.result ?.rows);
        authContext.unsetLoader();
    }

    useEffect(() => {
        getList();
    }, [])

    const searchFilter = (value) => {
        console.log(value);
        searchText = value
        getList();
    }

    const changeSorting = (e) => {
        let name = e.currentTarget.getAttribute('name');
        setSortName(name);
        setsort(sort == "ASC" ? "DESC" : "ASC")
        getList()
    };

    return (
        <Fragment>
            <ManagementFilter search={searchFilter} />
            <div className="table-report-scroll mt-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="data-sort" style={{ minWidth: '150px' }}>
                                    Patient Name
                            </th>
                            <th>
                            <span className="sort-outer">
                                Request Date & Time
                                <span className="data-sort-icon" name="createdAt" onClick={changeSorting}><DataSortIcon /></span>
                            </span>
                            </th>
                            <th>
                                <span className="sort-outer">
                                    Appointment <br /> Date & Time
                                    <span className="data-sort-icon" name="appointmentDate" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Contact No.</th>
                            <th>Location</th>
                            <th>
                                <span className="sort-outer">
                                    Appointment Type
                                    <span className="data-sort-icon" name="visitType" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length == 0 && <tr>
                                <td>No Results Found</td>
                            </tr>
                        }
                        {
                            data && data.map((value, index) =>
                                <tr key={index}>
                                    <td><Link>{`${value.users.patients.firstName} ${value.users.patients.lastName}`}</Link></td>
                                    <td>{value.createdAt}</td>
                                    <td>{value.appointmentDate}</td>
                                    <td>{value.providerPracticeLocations.phoneNumber}</td>
                                    <td>{value.providerPracticeLocations.practiceAddress}</td>
                                    <td>{value.visitType}</td>
                                    <td>{value.bookingStatus == 1 ? "Scheduled" : "Unscheduled"}</td>
                                </tr>
                            )
                        }


                    </tbody>
                </table>
            </div>
            {/* <Paging/> */}
        </Fragment>
    );
}

export default ManagementAppointment;