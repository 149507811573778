import React, { Fragment, useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { Link } from 'react-router-dom';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import Paging from '../../components/admin/patient/paging';
import AppointmentFilter from '../../components/admin/appointments-admin/appointment-filter';
import AppointmentsTable from '../../components/admin/appointments-admin/manage-appointments-table';
import CallBackTable from '../../components/admin/appointments-admin/manage-callback-table';

const AdminAppointments = () => {
    const authContext = useContext(AuthContext);
    const [ visibletab, setvisibletab] = useState('appointment');

    const changeTab = (name) => {
        setvisibletab(name);
    }
    return (
        <Fragment>
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section"}>

                    <Row className="bg-white-report">
                        <div className="table-report">
                            <ul className="edit-tab-list row mt-3 pl-3 pr-3">
                                <li className={ visibletab === 'appointment' ? 'active': ''} onClick={() => { setvisibletab('appointment')} }>Appointments</li>
                                <li className={ visibletab === 'callback' ? 'active': ''} onClick={() => { setvisibletab('callback')} }>Callback Requests</li>
                            </ul>

                            {/* Bottom container content */}
                            {/* <AppointmentFilter/> */}
                            {
                                visibletab === 'appointment' ? <AppointmentsTable /> : <CallBackTable/>
                            }
                            
                            {/* <Paging/> */}
                        </div>
                    </Row>



                </Container>
            </div>
        </Fragment>
    );
}

export default AdminAppointments;