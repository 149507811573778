import React, { Fragment, useState , useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from "../../reactLayer/Network/Network";
import AuthContext from "../../reactLayer/auth/AuthContext";
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';

const NoResultsModal = (props) => {
    const authContext = useContext(AuthContext);
    const [emailId,setEmailId] = useState('')
    const [description,setDescription] = useState('')
    const [emailError,setEmailError] = useState('')
    const [descriptionError,setDescriptionError] = useState('')
    const [inviteOption,setInviteOption] = useState(1);
    const maxChar = 500;
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
      
    });
    const [ charLimit, setcharLimit] = useState(0);
    const closeinvitemodal = () => {
        props.closeModal && props.closeModal('', "invitemodal");
    }
    const sendInvite = async () => {
        let errors = []
        if(emailId === ''){
            let errorMsg = "Please enter email address"
            errors.push({key:'emailId',msg:errorMsg})
            setEmailError(errorMsg)
        }
        if(emailId !== ''){
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            console.log(re.test(emailId))
            if(re.test(emailId) === false){
                let errorMsg = "Please enter valid email address"
                errors.push({key:'emailId',msg:errorMsg})
                setEmailError(errorMsg)
            }
        }
        if(description === '' && inviteOption === 2){
            let errorMsg = "Please your message"
            errors.push({key:'description',msg:errorMsg})
            setDescriptionError(errorMsg)
        }
        if(errors.length){
            document.getElementById(errors[0].key).focus()

        }else{
            authContext.setLoader();
            let headers = { 'Content-Type': 'application/json' };
            if (authContext.token) {
            headers['access_token'] = authContext.token
            }
            const dataToSend = {
                mail_to:emailId,
                comment:description
            }

            const submitData = {
            headers: headers,
            url: "send-invite",
            body: JSON.stringify(dataToSend),
            };
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if (result.body.status) {
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                    closeaction: closeinvitemodal
                });
                authContext.showResponseModal();
            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                    closeaction: closeinvitemodal
                });
                authContext.showResponseModal();
            }
            console.log('result',result)
        }
    }

    const onChangeHandler = (e,type) => {
        if(type === 'emailId'){
            setEmailError('')
            setEmailId(e.target.value)
        }
        if(type === 'description'){
            setDescriptionError('')
            if( inviteOption == 2) {
                setDescription(e.target.value)
                setcharLimit(e.target.value.length);
            }
        }
    }


return (
    <Fragment>
        <Container>
            <div className="search-modal-wrap no-result-modal-wrap">
                <div className="edit-all-filter">
                    <Row>
                        {
                            authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                        }
                    </Row>
                    <Row>
                        <div className="col-12 px-0">  
                            <div className="modal-head">No Search Results Found</div>
                        </div>
                    </Row> 
                    <div className="allfilter-innerbox">
                        <Row>
                            <div className="col-12 mb-3 noresult-topline">
                                Looking for someone not on DocScheduler yet? Invite them.
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12 mb-3">
                                <label className="pro-label">Enter Email Address</label>
                                <input maxLength="100" type="text" className="input-pro" id="emailId" value={emailId} onChange={(e)=>onChangeHandler(e,'emailId')} placeholder="Enter Email Address" />
                                <small className="error-input">{emailError}</small>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12 mb-1">
                                <label className="pro-label">How do you want to invite your colleague?</label>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12 mb-4">
                                <div className="cus-radio">
                                    <input type="radio" id="noresult" onClick={()=>{ setInviteOption(1); setDescriptionError('');  setDescription(''); setcharLimit(0);}} checked={inviteOption === 1 ? true : false} name="noresult" value="noresult"/>
                                    <label for="noresult">
                                        I'd like you to join DocScheduler to help grow you clinic's digital brand and increase patient volume and revenue. They offer a 60 day free trial. if you are interested, please follow the link below or Google DocScheduler.com to learn more.
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="cus-radio">
                                    <input type="radio" id="noresult1" onClick={()=>setInviteOption(2)} checked={inviteOption === 2 ? true : false} name="noresult" value="noresult1"/>
                                    <label for="noresult1">
                                    <textarea maxLength={maxChar} disabled = {inviteOption == 1 ? true : false } value={description} onChange={(e)=>onChangeHandler(e,'description')} id="description" name="address" className="input-pro" placeholder="Enter your own personal message"></textarea>
                                    <div class="limit"><strong>Note:</strong> {maxChar-charLimit} character limit</div>
                                    <small className="error-input">{descriptionError}</small>
                                    </label>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12 mb-4 all-fltrbtn">
                                <input type="button" className="next-btn-pro" onClick={()=>sendInvite()} value="Invite"/>
                            </div>
                        </Row> 
                    </div>
                </div>
            </div>              
        </Container>

    </Fragment>
)
    
}

export default NoResultsModal;