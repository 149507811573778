import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Row } from 'react-bootstrap';
import Global from '../../reactLayer/global';


const Freeprofile = (props) => {
    // console.log('props on free profile', props);
    const date = new Date();
    const today = (parseInt(date.getMonth()) +1 ).toString().padStart(2, "0") + '/' + date.getDate().toString().padStart(2, "0") + '/'+ date.getFullYear();
    
    const addDays = Global.addDays( null, 60);
    const firstBill = (parseInt(addDays.getMonth()) +1 ).toString().padStart(2, "0") + '/' + addDays.getDate().toString().padStart(2, "0") + '/' + addDays.getFullYear();

    const billSecond = addDays.getFullYear() +'/' + (parseInt(addDays.getMonth()) +1 ).toString().padStart(2, "0") + '/' + addDays.getDate().toString().padStart(2, "0");
    const addSecondBillDays = Global.addDays( billSecond, 30);
    const secondBill =  (parseInt(addSecondBillDays.getMonth()) +1 ).toString().padStart(2, "0") + '/' + addSecondBillDays.getDate().toString().padStart(2, "0") + '/'+ addSecondBillDays.getFullYear();

    return (
        <Row className="free-profile-component">
            <div className="col-12">
                <h3> All of DocScheduler, Free for 60 Days </h3>
            </div>
            <div className="offset-1 col-10 date-container"> 
                <div className="heading-txt">
                    <span>Today</span>
                    <span>{today}</span>
                </div>
                <div className="heading-txt">
                    <span>First Bill</span>
                    <span>{firstBill}</span>
                </div>
                <div className="heading-txt">
                    <span>Second Bill</span>
                    <span>{secondBill}</span>
                </div>
            </div>
            <div className="offset-1 col-10 bar-container"> 
                <div className="remaining-days">
                    <div className="fill-color"></div>
                </div>
                <div className="billing-days"></div>
                <div className="billing-circle"></div>
                <div className="billing-circle last"></div>
                <div className="msg-container">
                    <div className="timeline"></div>
                    <div className="msg"><img src="/assets/images/grey-mail-icon.png"/>7 Day Reminder</div>
                </div>
            </div>
            <div className="offset-2 col-lg-8 description"> 
                We’ll email you a reminder seven (7) days before your trial ends. Cancel anytime before the first bill date and you won’t be charged.
            </div>
            <div className="offset-2 col-lg-8 call-msg"> 
                Would you like to talk to a Customer Success Representative? <a href={`mailto:support@docscheduler.com?subject=Provider Call Request || DocScheduler`}>Schedule a Call </a>
            </div>
            { props.redirectToCreatAccount ? (<>
                <div onClick={(e) => props.redirectToCreatAccount(e)} className="col-sm-8 col-md-6 mb-5 submission next-btn-pro">
                    <a href='/#' className="" onClick={(e) => props.redirectToCreatAccount(e)}>
                        Try 60 Days Free
                    </a>
                </div>
            </>) : (<></>)}
            
           
        </Row>
    )
    
}

export default Freeprofile;