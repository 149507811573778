import React, { useState, useContext, useEffect, useCallback, lazy, Suspense  } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Global from '../../reactLayer/global';
import { PatientLinks } from '../../linkFile';
import NetworkLayer from "../../reactLayer/Network/Network";
import AuthContext from "../../reactLayer/auth/AuthContext";
import SchedulingDate from '../scheduling/scheduling-date';
import SchedulingTitle from '../scheduling/scheduling-title';
import SchedulingTime from '../scheduling/scheduling-time';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import Custommodal from '../../global/modal/modal';
import RequestAppointmentModal from '../doctor-search-result/req-appontment-modal';

const ProviderRightProfile = (props) => {

    const authContext = useContext(AuthContext);
    const [startDate, setStartDate] = useState("");
    const browserAddress = props.addressInfo && props.addressInfo.formattedAddress ? props.addressInfo.formattedAddress : false;
    const [ bookSlot, setbookSlot] = useState(false)
    const [selectedItem, setselectedItem] = useState();
    const [tabvisibleComponent, settabvisibleComponent] = useState("");
    const modalList = ["reqAppointment"];

    const [slots, setslots] = useState([]);
    const [locationId, setlocationId] = useState('');
    
    const [modalProps, setmodalProps] = useState({
        type:'', 
        msg:'',
        btnname: '',
        redirection: '',
        rightSection:false
    });
    const openLoginModal = (e) => {
        e && e.preventDefault();
        authContext.showLoginModal({
        modalName: 'login',
        redirectUrl: PatientLinks.doctor_search_result
        });
    }
    const openRequestModal = (modalName, slot = false, item=null) => {
        // authContext.hideModal()
        setselectedItem(item);
        setbookSlot(slot);
        settabvisibleComponent(modalName);
        authContext.showModal();
    };
    const bookAppointment = (slot,item=null) => {
        if( authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.isProfileCompleted && authContext.user.accountInfo.roleId === 4 ) {
        openRequestModal('reqAppointment', slot,item);
        } else {
        openLoginModal();
        }
    }
    const doaskenDorseDoctor = async (drId, type='ask') => {
        authContext.setLoader();
        let data =  {toUser: drId}
        let url = 'endorsement/ask';
        if( type === 'add') {
            url = 'endorsement/add';
        }
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: url,
            body: JSON.stringify(data),
        };
        let result = await NetworkLayer.postData(submitData);
        // authContext.unsetLoader();
        if (result.body.status) {
            setmodalProps({
                type:'success', 
                msg: result.body.message,
                rightSection:true
            });
            authContext.showResponseModal();
            props.getProfile && props.getProfile();
        } else {
            setmodalProps({
                type:'error', 
                msg: result.body.message,
                rightSection:true
            });
            authContext.showResponseModal();
            props.getProfile && props.getProfile();
        }
        
    };
    const askenDorseDoctor = (e, drId) => {
        e && e.preventDefault();
        doaskenDorseDoctor(drId, 'ask');
    }

    const enDorseDoctor = (e,drId) => {
        e && e.preventDefault();
        doaskenDorseDoctor(drId, 'add');
    }
    const requstCallback = async(e,userId) => {
        e.preventDefault();
        authContext.setLoader();
        let data = { userId: userId };
        let url = "patients/request-callback/submit";
        
        const submitData = {
        headers: {
            "Content-Type": "application/json",
            access_token: authContext.token,
        },
        url: url,
        body: JSON.stringify(data),
        };
        let result = await NetworkLayer.postData(submitData);
        console.log('result on ', result);
        // authContext.unsetLoader();
        authContext.unsetLoader();
        if (result.body.status) {
        setmodalProps({
            type: "success",
            msg: result.body.message,
            rightSection:true
        });
        authContext.showResponseModal();
        props.getProfile && props.getProfile();
        } else {
        setmodalProps({
            type: "error",
            msg: result.body.message,
            rightSection:true
        });
        authContext.showResponseModal();
        props.getProfile && props.getProfile();
        }
    }
    const dateSelect = (value = false, event, type = false) => {
        let  cdate = value;
        if( type ) {
            if( type == 'prev') {
                cdate = Global.addDays(startDate, -5)
            } else {
                cdate = Global.addDays(startDate, 5)
                // console.log('cdate=====>', cdate);
            }
        }
        setStartDate(cdate);
    }
     const getLocationBasedDetails = useCallback(async() => {
        authContext.setLoader();
        let startFrom, cDate;
        if( startDate ) {
            cDate = Global.getYearMonthDate(startDate);
        } else {
            cDate = Global.getYearMonthDate();
        }
        startFrom = cDate.year +'-'+ cDate.month +'-'+ cDate.startDate;
        if( locationId) {
            let fData = { startFrom:startFrom, locationId:locationId };
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'appointment/schedule',
                body: JSON.stringify(fData),
            }
            let result = await NetworkLayer.postData(submitData);
            // console.log('getLocationBasedDetails result ===>', result);
            if( result.body.status ) {
                if(result.body.result.slots) {
                    setslots([...result.body.result.slots]);
                } else {
                    setslots([]);
                } 
                authContext.unsetLoader();
            } else {
                setslots([]);
                authContext.unsetLoader();
            }
        }
    },[locationId,startDate])

    useEffect(() => {
        console.log('props on prd-doc-profile-right', props);
        props.practiceInfo && props.practiceInfo.providerPracticeLocations && props.practiceInfo.providerPracticeLocations.length && setlocationId(props.practiceInfo.providerPracticeLocations[0].id);
        props && setselectedItem({...props});
    },[props])
    const suspenseLoader = () => <p></p>;
    return (
        <Suspense fallback={suspenseLoader()}>
            <div className="pro-profile-box-style provider-detail-right-top">
                {modalList.indexOf(tabvisibleComponent) > "-1" ? (
                    <Custommodal
                    unsetComponent={settabvisibleComponent}
                    componentName={
                        tabvisibleComponent === "reqAppointment" && selectedItem ? (
                        <RequestAppointmentModal {...props} bookSlot={bookSlot} browserAddress={browserAddress} type={'profile'}/>
                        ) : (
                        <></>
                        )
                    }
                    />
                ) : (
                    <></>
                )}
                {
                    authContext.responseModal && modalProps.rightSection ? <Msgmodal {...modalProps} /> : <></>
                }
                {
                    props.accountInfo && props.accountInfo.id && authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId === 3 ? (<>
                        <div className="patient-features">
                            <em>Patient features unavailable.<br/>Please sign in as a patient if you would like to book an appointment.</em>
                        </div>
                    </>) : (<>
                        {
                            props?.practiceInfo &&
                            props?.practiceInfo?.allowOnline === 1 &&
                            props?.providerCurrentPlan?.planId &&
                            ( props?.providerCurrentPlan?.planId === 3  ||
                                props?.providerCurrentPlan?.planId === 4 ) &&
                                props?.practiceInfo?.providerPracticeLocations.length > 0 &&
                                <>
                                    <div className="book-appointment-wrap">
                                        <h2>Book an appointment</h2>
                                        <div className="subhead-profile capitalize">
                                            with {' '}
                                            {props.accountInfo && props.accountInfo.firstName ? props.accountInfo.firstName + ' ' : ''} 
                                            {props.accountInfo && props.accountInfo.middleName ? props.accountInfo.middleName + ' ' : ''} 
                                            {props.accountInfo && props.accountInfo.lastName ? props.accountInfo.lastName : ''}
                                            {props.accountInfo && props.medicalSpeciality.providerMedicalCredientialsValue ? ', '+props.medicalSpeciality.providerMedicalCredientialsValue : ''} 
                                        </div>
                                        <div className="appointment-location">Appointment Location</div>
                                        <div className="location-select">
                                            <div className="myform">
                                                <select className="select" onChange={(e)=>setlocationId(e.currentTarget.value)}>
                                                    {
                                                        props.practiceInfo && props.practiceInfo.providerPracticeLocations && props.practiceInfo.providerPracticeLocations.map((location, locationIndex) =>
                                                            <option  value={location.id}>{location.practiceName ? location.practiceName + ' ': ''} {location.practiceAddress ? location.practiceAddress + ' ' : ''} {location.city ? location.city + ' ': ''} {location.state ? location.state + ' ' : ''} {location.zipCode ? location.zipCode : ''}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="calender-wrap">
                                        <SchedulingTitle
                                            startDate={startDate}
                                            setStartDate={setStartDate}
                                            dateSelect={dateSelect}
                                        />
                                        <SchedulingDate startDate={startDate} setStartDate={setStartDate}/>
                                        <SchedulingTime
                                            key={'locationId'} 
                                            locationId={locationId} 
                                            item={slots} 
                                            startDate={startDate} 
                                            bookAppointment={bookAppointment}
                                        />
                                    </div>
                                </>
                        }
                        {
                                props?.practiceInfo &&
                                props?.practiceInfo?.allowOnline === 0 &&
                                props?.providerCurrentPlan?.planId &&
                                ( props?.providerCurrentPlan?.planId === 1 ) && (
                                    <>
                                        <div className="scheduling-features">
                                            <em>
                                                The real-time scheduling feature is not available for this
                                                doctor. Please call the number an appointment
                                                below to book with this doctor.
                                            </em>
                                        </div>
                                    </>
                                )
                        }
                        {
                            props?.practiceInfo &&
                            props?.practiceInfo?.allowOnline === 1 &&
                            props?.providerCurrentPlan?.planId &&
                            ( props?.providerCurrentPlan?.planId === 2 ) && (
                                <>
                                    <div className="scheduling-features">
                                        <em>
                                            The real-time scheduling feature is not available for this
                                            doctor. Please call the number or request an appointment
                                            below to book with this doctor.
                                        </em>
                                    </div>
                                </>
                            )
                        }
                        {
                                props?.practiceInfo &&
                                props?.practiceInfo?.allowOnline === 0 &&
                                props?.providerCurrentPlan?.planId &&
                                ( props?.providerCurrentPlan?.planId === 3 ||
                                props?.providerCurrentPlan?.planId === 4) && (
                                    <>
                                        <div className="scheduling-features">
                                            <em>
                                                The real-time scheduling feature is not available for this
                                                doctor. Please call the number or request an appointment
                                                below to book with this doctor.
                                            </em>
                                        </div>
                                        <div className="request-appoint show">
                                            <input
                                            type="button"
                                            onClick={() =>  authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.isProfileCompleted && authContext.user.accountInfo.roleId === 4 ? 
                                                openRequestModal("reqAppointment",false, props) : openLoginModal()
                                            }
                                            className="next-btn-pro"
                                            value="Request Appointment"
                                            />
                                        </div>
                                    </>
                                )
                        }
                    </>)
                }
                
                {
                    props.accountInfo && props.accountInfo.id && authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId === 3 ? (<>
                        <div className="endorse-link">
                            {
                                props.isEndorsed ? (<>
                                    <a style={{'cursor': 'default', 'background-color': '#e4eef3'}} href="/#" onClick={(e) => e.preventDefault()}><span>Endorsed</span></a>
                                </>) : (<>
                                    <a href="/#" onClick={(e) => enDorseDoctor(e, props.accountInfo.id)}><span>Endorse Doctor</span></a>
                                </>)
                            }
                            <a href="/#" onClick={(e) => askenDorseDoctor(e, props.accountInfo.id)}>Ask Doctor for Endorsement</a>
                        </div>
                    </>) : (<>
                        <div className="endorse-link endorse-link-patient">
                            {
                                props.accountInfo && props.accountInfo.phoneType  && ((props.accountInfo.phoneType && props.accountInfo.phoneType === 'home' && props.accountInfo.homeNumber) || (props.accountInfo.phoneType === 'cell' && props.accountInfo.phoneNumber)) ? (<>
                                    <a href={`tel:${props.accountInfo.phoneType === 'cell' ? props.accountInfo.phoneNumber : props.accountInfo.homeNumber}`}>
                                    {
                                        Global.phoneFormator(props.accountInfo.phoneType === 'cell' ? props.accountInfo.phoneNumber : props.accountInfo.homeNumber)
                                    }
                                    </a>
                                </> ) : (<></>)
                                // props.accountInfo && props.accountInfo.phoneType ? (<>Test</>) : (<>Test1</>)
                                
                            }
                            {
                                props?.practiceInfo &&
                                props?.practiceInfo?.allowedPatient === 1 &&
                                props?.providerCurrentPlan?.planId &&
                            ( props?.providerCurrentPlan?.planId === 2 ||
                                props?.providerCurrentPlan?.planId === 3 ||
                                props?.providerCurrentPlan?.planId === 4 ) && (
                                <a href="/#" className={!props.isCallbackRequested ? '' : 'cursor-default'} onClick={(e) =>  authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.isProfileCompleted && authContext.user.accountInfo.roleId === 4 ? 
                                        !props.isCallbackRequested ? requstCallback(e, props.accountInfo.id) : e.preventDefault() : openLoginModal(e)
                                    }
                                >{!props.isCallbackRequested ? 'Request Callback' : 'Request Sent'}</a>
                                )
                            }

                            
                        </div>
                    </> )
                }
                
                
            </div>
        </Suspense>
    )
    
}

export default ProviderRightProfile;