import React, { Fragment, useState, useEffect, memo, useCallback, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { v4 as uuidv4 } from "uuid";
import { Container, Row } from "react-bootstrap";
import SearchFilter from "./searchBy_filter";
import Global from "../../reactLayer/global";
import { PatientLinks, ProviderLinks } from "../../linkFile";
import { useHistory } from "react-router-dom";
import useLocationMod from "../../hooks/useLocationMod";
import AuthContext from "../../reactLayer/auth/AuthContext";

const SearchBy = (props) => {
  const { type, pageHeading, setfilter, filter } = props;
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const locationMod = useLocationMod();
  const [insuranceList, setinsuranceList] = useState([]);
  const [mainInsuranceList, setmainInsuranceList] = useState([]);
  const [specialtyList, setSpecialtyList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [otherData, setotherData] = useState({
    language: {
      value: [],
    },
    Insurances: {
      value: [],
    },
    specialties: {
      value: [],
    },
  });
  const addLangData = (e) => {
    let data,
      val,
      arrayIndex,
      name = e.currentTarget.getAttribute("data-name");
    val = e.currentTarget.value;
    data = otherData[name];
    arrayIndex = data.value.indexOf(val);
    if (arrayIndex > "-1") {
      data.value.splice(arrayIndex, 1);
    } else {
      data.value.push(val);
    }
    setotherData({ ...otherData, ...{ [name]: data } });
  };

  const addOptionChangeData = (e, id) => {
    let data,
      val,
      arrayIndex,
      name = e.currentTarget.getAttribute("data-name");
    val = id;
    data = otherData[name];
    arrayIndex = data.value.indexOf(val);
    if (arrayIndex > "-1") {
      data.value.splice(arrayIndex, 1);
    } else {
      data.value.push(val);
    }
    setotherData({ ...otherData, ...{ [name]: data } });
  };

  const resetHandleInsurance = useCallback(
    (item) => {
      setotherData((preState) => ({
        ...preState,
        ...{
          Insurances: {
            value: [],
          },
        },
      }));
      let newInsurance = [];
      mainInsuranceList.map((node, index) => {
        if (node?.category) {
          if (node?.category.toLowerCase() === item.toLowerCase()) {
            newInsurance.push({ ...node });
          }
        }
        return true;
      });
      setinsuranceList(newInsurance)
    },
    [mainInsuranceList]
  );

  const getInsurance = async () => {
    let insResponse = await Global.getInsurance();
    setinsuranceList(insResponse);
    setmainInsuranceList(insResponse);
  };

  const getMedicalSpeciality = async () => {
    let result = await Global.getMedicalSpeciality();
    let formatResult = result.map(({ id, name }) => {
      return {
        id,
        name,
      };
    });
    if (formatResult.length > 0) {
      setSpecialtyList(formatResult);
    }
  };

  const getLangListData = async () => {
    let result = await Global.getLangList();
    setLangList(result);
  };

  const onSumbitSearch = useCallback(() => {
    const path = authContext.user && authContext?.user?.isProfileCompleted && authContext.user.accountInfo.roleId === 3 ? ProviderLinks.search_result : PatientLinks.doctor_search_result;
    history.push({
      pathname: path,
      state: {
        find: filter?.searchValue,
        zipCity: filter?.cityOrZip,
        insurance_Name: filter?.insurances,
        speciality: filter?.speciality,
        languages: filter?.languages,
      },
    });
    locationMod.location.state = {
      find: filter?.searchValue,
      zipCity: filter?.cityOrZip,
      insurance_Name: filter?.insurances,
      speciality: filter?.speciality,
      languages: filter?.languages,
    };
  }, [filter, history, locationMod]);

  useEffect(() => {
    if (type) {
      type === "specialty" && getMedicalSpeciality();
      type === "insurance" && getInsurance();
      type === "language" && getLangListData();
    }
  }, [type, setfilter]);

  const arrayFindObjectByProp = (arr, prop, val, returnKey) => {
    return returnKey
      ? arr.find((obj) => obj[prop] === val)[returnKey]
      : arr.find((obj) => obj[prop] === val);
  };

  useEffect(() => {
    setfilter((preState) => ({
      ...preState,
      callApi: false,
      languages:
        otherData.language.value.length > 0 && type === "language"
          ? [].concat(otherData.language.value)
          : [],
      speciality:
        otherData.specialties.value.length > 0 && type === "specialty"
          ? [].concat(otherData.specialties.value)
          : [],
      insurances:
        otherData.Insurances.value.length > 0 && type === "insurance"
          ? [].concat(
              otherData.Insurances.value.map((val) =>
                arrayFindObjectByProp(insuranceList, "id", val, "name")
              )
            )
          : [],
    }));
  }, [type, otherData, setfilter, insuranceList]);

  useEffect(() => {
    if (filter?.callApi) {
      setotherData({
        language: {
          value: [],
        },
        Insurances: {
          value: [],
        },
        specialties: {
          value: [],
        },
      });
    }
  }, [filter]);

  return (
    <Fragment>
      <Container className="free-profile searchby-container">
        <Container>
          <Row>
            <div className="col-12">
              <h2>{pageHeading?.title}</h2>
            </div>
            <div className="col-12">
              <div className="searchBy provider-steps-wrap">
              <SearchFilter
                type={type}
                setfilter={setfilter}
                filter={filter}
                resetInsurance={resetHandleInsurance}
              />
              <div className="col-12 mb-4 mt-3 mt-md-0">
                <div className="sub-head-pro-step2 head-title">
                  {pageHeading?.subTitle}
                </div>
              </div>

              <div className="col-12 mb-3 mb-md-4 provider-steps-wrap">
                {type === "specialty" && (
                  <div className="language-wrap-step2">
                    {specialtyList.map((d, ind) => (
                      <div
                        className="cus-check-rev search-insurance"
                        key={d.name + "specialties" + ind}
                      >
                        <input
                          type="checkbox"
                          id={d.name}
                          name="specialties"
                          data-name="specialties"
                          value={d.name}
                          onChange={(e) => addOptionChangeData(e, d.id)}
                          checked={
                            otherData.specialties.value.indexOf(d.id) > "-1"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor={d.name}>{d.name}</label>
                      </div>
                    ))}
                  </div>
                )}

                {type === "language" && (
                  <div className="language-wrap-step2">
                    {langList.map((d, ind) => (
                      <div className="cus-check-rev search-page" key={uuidv4()}>
                        <input
                          type="checkbox"
                          id={d.toLowerCase() + "-" + ind}
                          name="lang"
                          data-name="language"
                          key={d.toLowerCase() + "-" + ind}
                          value={d.toLowerCase()}
                          onChange={(e) => addLangData(e)}
                          checked={
                            otherData.language.value.indexOf(d.toLowerCase()) >
                            "-1"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor={d.toLowerCase() + "-" + ind}>{d}</label>
                      </div>
                    ))}
                  </div>
                )}

                {type === "insurance" && (
                  <div className="language-wrap-step2 language-wrap-step3">
                    {insuranceList.map((d, ind) => (
                      <div
                        className="cus-check-rev search-insurance"
                        key={d.name + "insList" + ind}
                      >
                        <input
                          type="checkbox"
                          id={d.name}
                          name="insurance"
                          data-name="Insurances"
                          value={d.name}
                          onChange={(e) => addOptionChangeData(e, d.id)}
                          checked={
                            otherData.Insurances.value.indexOf(d.id) > "-1"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor={d.name}>{d.name}</label>
                      </div>
                    ))}
                  </div>
                )}

                <div className="col-12 mb-5 mt-5 text-center">
                  <div className="next-prev-btn-pro">
                    <input
                      type="button"
                      className="next-btn-pro"
                      value="Search"
                      onClick={onSumbitSearch}
                    />
                  </div>
                </div>
              </div>
            </div>
            </div>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
};

SearchBy.defaultProps = {
  type: "",
  pageHeading: null,
  setfilter: null,
  filter: null,
};

export default memo(SearchBy);
