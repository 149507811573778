import React, { Fragment, useState, useEffect, useRef, useLayoutEffect } from 'react';
import Calendar from 'react-calendar';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-calendar/dist/Calendar.css';
import { Container, Row } from 'react-bootstrap';
import Global from '../../reactLayer/global';



const Scheduling = (props) => {

    const [showCal, setshowCal] = useState(false);
    const [ prevSel, setprevSel] = useState(false);
    const [ nextSel, setnextSel] = useState(true);
    const [ showDate, setshowDate] = useState(new Date());
    const [ showallslots, setshowallslots] = useState('');
    const [ enableSM, setenableSM] = useState(false);
    
    const slots = props.slots ? props.slots : [];
    const dateWrapper = useRef(null);
    const dateSelect = (value = false, event, type = false) => {
        setshowCal(false);
        // console.log('value ', value);
        let  cdate = value;
        if( type ) {
            if( type == 'prev') {
                cdate = Global.addDays(slots[0]['SDate'], -5)
            } else {
                cdate = Global.addDays(slots[0]['SDate'], 5)
                // console.log('cdate=====>', cdate);
            }
        }
        if( props.changeDate && typeof props.changeDate === 'function') {
            props.changeDate(cdate);
        }
    }

    const enableDisbleSlots = (sl, dt, status) => {
        if( props.enableDisbleSlots && typeof props.enableDisbleSlots === 'function') {
            props.enableDisbleSlots(sl, dt, status);
        }
    }
    // console.log('scheduling slots', props);

    const enableShowMore = () => {
        !enableSM && setenableSM(true);
    }
    useEffect(() => {
    
    if( slots.length && slots[0]['SDate'] ) {
        let sdate = new Date(slots[0]['SDate'] +' 00:00').getTime();
        let ldate =Global.addDays(null,45).setHours(0,0,0,0);
        let cdate = new Date().getTime();
        console.log('slot date', new Date(slots[0]['SDate']), 'current date', new Date() );
        if( sdate > cdate ) {
            setprevSel(true);
        } else {
            setprevSel(false);
        }

        if( sdate < ldate) {
            setnextSel(true);
        } else {
            setnextSel(false);
        }
        setshowDate(new Date(slots[0]['SDate']));
    }   
    setenableSM(false);
    setshowallslots('');
    }, [slots])

    // useLayoutEffect(() => {

    //     if( dateWrapper && dateWrapper.current && dateWrapper.current.clientHeight && dateWrapper.current.scrollHeight ) {
    //         console.log('dateWrapper.current.clientHeight ==>', dateWrapper.current.clientHeight, 'dateWrapper.current.scrollHeight ==>', dateWrapper.current.scrollHeight ); 
    //     }
    //     if( dateWrapper && dateWrapper.current && dateWrapper.current.clientHeight && dateWrapper.current.scrollHeight && dateWrapper.current.clientHeight >= dateWrapper.current.scrollHeight ) {
    //         // console.log('dateWrapper.current.clientHeight ==>', dateWrapper.current.clientHeight, 'dateWrapper.current.scrollHeight ==>', dateWrapper.current.scrollHeight ); 
    //         setshowallslots('show-height');
    //     }
    //     return () => {
    //         setshowallslots('');
    //     }
    // },[props])

    return (
        <Container>
        <Row className="scheduling-component">
            <div className="col-12 scheduling-component-main"> 
                {
                    slots && slots.length ? (<>
                        <Row className="title-area">
                            <div className="col-9 col-md-6 title">Select a date & time</div>
                            <div className="col-3 col-md-6 cal text-right position-relative">                        
                                {
                                    showCal ? (<>
                                        <Calendar
                                            onClick={dateSelect}
                                            onClickDay={dateSelect}
                                            value={showDate}
                                            minDate= {new Date()}
                                            maxDate= {Global.addDays(null,45)}
                                            defaultActiveStartDate= {new Date()}
                                            calendarType={'US'}
                                        />
                                        <span onClick={()=> {setshowCal(false)}} className="close-cal"></span>
                                    </>) : 
                                        (<><a onClick={()=> {setshowCal(true)}} className="cal-link"><div className="calender-icon"></div><span className="view-cal-title">View Calendar</span></a></>)
                                }
                            </div>
                        </Row>   
                        <Row className={showallslots + ' date-area'} ref={dateWrapper}>
                            {
                                prevSel ? <><img onClick={() => dateSelect('', '','prev')} className="left-arrow" src="/assets/images/left-arrow-icon.png" /></> : <></>
                            }
                            {
                                nextSel ? <><img onClick={() => dateSelect('', '','next')} className="right-arrow" src="/assets/images/right-arrow-icon.png" /></> : <></>
                            }
                            { slots.map((slot , s_index) => 
                                <div  className='date-column' >
                                    <div className="date-heading">
                                        <span>{slot.scheduleName.substring(0,3)}</span>
                                        <span> {slot.date} </span>
                                    </div>
                                    {  slot.slots.map((sl , sl_index) => 
                                        <div className={sl.isDisabled || sl.isBooked ? 'date-list disabled' :  sl.isBooked ? 'date-list booked' : 'date-list'} onClick={()=>enableDisbleSlots(sl.startTime, slot.SDate, slots[0]['SDate'])}>
                                            {!enableSM && sl_index > 7 && enableShowMore()}
                                            {sl.startTime}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Row>
                        <Row>
                            {
                                showallslots === '' && enableSM ? (<>
                                    <div className="col-12">
                                        <div className="down-arrow-date" onClick={()=>setshowallslots('show-height')} ><div className="down-arrow-lg" alt="Down arrow"></div></div>
                                    </div>
                                </>) : (<></>)
                            }
                            
                        </Row>
                    
                    </>) : (<>
                        <div>Slots not available</div>
                    </>)
                }
            </div>
        </Row>
        </Container>
    )
    
}

export default Scheduling;