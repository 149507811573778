import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col, Tabs } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import SearchFilter from "./searchBy_filter";

import Header from "../../global/header/header";
import Footer from "../../global/footer/footer";
import SearchByLanguage from "../../components/searchBy/searchBy";
import SearchByCity from "../../components/searchBy/searchByCity";

const SearchBy = (props) => {
  const history = useHistory();
  const { type } = useParams();
  const [pageHeading, setPageHeading] = useState(null);
  const field = {
    callApi: false,
    cityOrZip: "",
    speciality: [],
    languages: [],
    insurances: [],
    focusAreas: "",
    searchValue: "",
    insurancesType: "",
  };
  const [filter, setfilter] = useState(field);

  const renderDom = useCallback(() => {
    switch (type) {
      case "specialty":
        return (
          pageHeading && (
            <SearchByLanguage
              filter={filter}
              setfilter={setfilter}
              type={type}
              pageHeading={pageHeading}
            />
          )
        );
      case "insurance":
        return (
          pageHeading && (
            <SearchByLanguage
              filter={filter}
              setfilter={setfilter}
              type={type}
              pageHeading={pageHeading}
            />
          )
        );
      case "language":
        return (
          pageHeading && (
            <SearchByLanguage
              filter={filter}
              setfilter={setfilter}
              type={type}
              pageHeading={pageHeading}
            />
          )
        );
      case "location":
        return (
          pageHeading && (
            <SearchByCity
              filter={filter}
              setfilter={setfilter}
              pageHeading={pageHeading}
            />
          )
        );
      default:
        return null;
    }
  }, [filter, type, pageHeading]);

  useEffect(() => {
    history.listen(() => {
      setfilter((preState) => ({
        ...preState,
        callApi: true,
        cityOrZip: "",
        speciality: [],
        languages: [],
        insurances: [],
        focusAreas: "",
        searchValue: "",
        insurancesType: "",
      }));
    });
  }, [history]);

  useEffect(() => {
    switch (type) {
      case "specialty":
        setPageHeading({
          title: "Search Doctor by Specialty",
          subTitle: "Doctor Specialties",
        });
        break;
      case "insurance":
        setPageHeading({
          title: "Search Doctor by Insurance",
          subTitle: "Insurances",
        });
        break;
      case "language":
        setPageHeading({
          title: "Search Doctor by Language",
          subTitle: "Doctor's Languages Spoken",
        });
        break;
      case "location":
        setPageHeading({
          title: "Search Local Doctor by City",
          subTitle: "",
        });
        break;
      default:
        history.push("/");
    }
  }, [type, history]);

  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <Header/>
      </Container>
      <Container className="home-wrap">
        {renderDom()}
      </Container>
      <Footer />
    </Fragment>
  );
};

export default memo(SearchBy);
