import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useCallback,
  memo,
} from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row } from "react-bootstrap";
import Global from '../../reactLayer/global';
import NetworkLayer from "../../reactLayer/Network/Network";
import AuthContext from "../../reactLayer/auth/AuthContext";
import Custommodal from "../../global/modal/modal";
import SearchFilter from "../../components/search-filter-modal/search-modal";
import Pagination from "../../components/pagination/pagination";
import SponsoredDoc from "./sponsored-doctor";
import useLocationMod from "../../hooks/useLocationMod";
import { Link } from "react-router-dom";
import HomeSearch from "../homepage/home_search";
import AllFilters from "../doctor-search-result/all-filter";
import SchedulingDate from "../scheduling/scheduling-date";
import SchedulingTitle from "../scheduling/scheduling-title";
import ReactGA from 'react-ga';
import NoResultsModal from '../../components/provider-login/service-area-coming-soon';


const SearchResults = (props) => {
  const { userCateType } = props;
  const locationMod = useLocationMod();
  const authContext = useContext(AuthContext);
  const [sresults, setsresults] = useState([]);
  const [browserAddress, setbrowserAddress] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [openNoResultsModal,setOpenNoResultsModal] = useState(false)
  const [openNoResultsZipModal,setOpenNoResultsZipModal] = useState(false)
  const [modalStatus, setModalStatus] = useState(
    authContext ?.advance_search_modal
  );
  const [latlng, setlatlng] = useState({
    latitude: null,
    longitude: null
  })
  const [totalCount, settotalCount] = useState(0);
  let sponsored = []
  let top = []
  let normal = []


  const searchText = (findHeaderTextFind, find) => {
    let data = "";
    if (find) {
      data = find;
    }
    if (findHeaderTextFind) {
      data = findHeaderTextFind;
    }
    return data;
  };
  const [filter, setfilter] = useState({
    callApi: false,
    firstName: "",
    lastName: "",
    cityOrZip: locationMod.location.state && locationMod.location.state.zipCity ? locationMod.location.state.zipCity : "",
    speciality: locationMod.location.state && locationMod.location.state.speciality ? [].concat(locationMod.location.state.speciality) : [],
    languages: locationMod.location.state && locationMod.location.state.languages ? [].concat(locationMod.location.state.languages) : ['english'],
    insurances: locationMod.location.state && locationMod.location.state.insurance_Name ? [].concat(locationMod.location.state.insurance_Name) : [],
    focusAreas: locationMod.location.state && locationMod.location.state.focusAreas ? locationMod.location.state.focusAreas : '',
    searchValue: searchText(locationMod.location.state && locationMod.location.state.findHeaderTextFind ? locationMod.location.state.findHeaderTextFind : "", locationMod.location.state && locationMod.location.state.find ? locationMod.location.state.find : ""),
    hasVirtualVisit: 0,
    isAcceptingNewPatient: 0,
    available72hours: 0,
    isTopShow: 0,
    isSponsoredShow: 1,
    isGender: "",
    isChildren: locationMod.location.state && locationMod.location.state.isChildren ? locationMod.location.state.isChildren : false,
    offset: 0,
    limit: 5,
    latitude: authContext.browserLatLng && authContext.browserLatLng.latitude ? authContext.browserLatLng.latitude : '',
    longitude: authContext.browserLatLng && authContext.browserLatLng.longitude ? authContext.browserLatLng.longitude : ''
  });

  const changeLimit = (page) => {
    setfilter({ ...filter, callApi: true, ...{ offset: parseInt(page) } });
  };

  const browserLatLng = (templatlng) => {
    templatlng.coords.latitude && templatlng.coords.longitude && setlatlng({ ...latlng, latitude: templatlng.coords.latitude, longitude: templatlng.coords.longitude })
  }
  const showModal = (e) => {
    e && e.preventDefault();
    setModalStatus(true);
  };

  const hideModal = () => {
    authContext.hideModal();
  };

  const getSearchResult = useCallback(async () => {
    // if (filter.callApi) {
    if (authContext ?.headerSearchText ?.actionSubmit) {
      authContext.setHeaderSearchText({
        text: authContext ?.headerSearchText ?.text,
        actionSubmit: false,
      });
    }
    authContext.setLoader();
    let tempFilterData = filter;
    if (authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId === 3) {
      delete tempFilterData.isChildren;
      delete tempFilterData.isAcceptingNewPatient;
      delete tempFilterData.hasVirtualVisit;
    }
    if (latlng.latitude && latlng.longitude) {
      tempFilterData.latitude = latlng.latitude
      tempFilterData.longitude = latlng.longitude
    }
    
    // if( )
    let headers = { 'Content-Type': 'application/json' };
    if (authContext.token) {
      headers['access_token'] = authContext.token
    }

    //"type":"sponsored"
    //"type":"top"
    //"type":"normal"

    let filter1 = JSON.parse(JSON.stringify(filter))
    let filter2 = JSON.parse(JSON.stringify(filter))
    let filter3 = JSON.parse(JSON.stringify(filter))
    const currentPage = (filter.offset / filter.limit)

    if(filter.sponsoredTotal >= currentPage+1 || !filter.sponsoredTotal)
    {
        filter1.type = 'sponsored'
        filter1.offset = currentPage
        filter1.limit = 1
        const submitData1 = {
          headers: headers,
          url: "providers/search/get/all",
          body: JSON.stringify(filter1),
        };
        var sponsoredDoctors = await NetworkLayer.postData(submitData1);
        var sponsoredDoctorsResult = sponsoredDoctors.body.result
        
        filter.sponsoredTotal = sponsoredDoctorsResult.totalCount

        if(sponsoredDoctorsResult.data.length){
          filter.prevOffset = currentPage*4
          filter.prevLimit = 4
          filter2.offset = currentPage*4
          filter2.limit = 4
        }

        if((filter.topDoctorsTotal >= currentPage+1 || !filter.topDoctorsTotal) && filter.isTopShow)
        {
          filter3.type = 'top'
          filter3.offset = currentPage
          filter3.limit = 1
          const submitData3 = {
            headers: headers,
            url: "providers/search/get/all",
            body: JSON.stringify(filter3),
          };
          var topDoctors = await NetworkLayer.postData(submitData3);
          var topDoctorsResult = topDoctors.body.result
          filter.topDoctorsTotal = topDoctorsResult.totalCount

          if(topDoctorsResult.data.length){
            filter.prevOffset = currentPage*3
            filter.prevLimit = 3
            filter2.offset = currentPage*3
            filter2.limit = 3
          }
        }
    }else{
      if((filter.topDoctorsTotal >= currentPage+1 || !filter.topDoctorsTotal) && filter.isTopShow)
        {
          filter3.type = 'top'
          filter3.offset = currentPage
          filter3.limit = 1
          const submitData3 = {
            headers: headers,
            url: "providers/search/get/all",
            body: JSON.stringify(filter3),
          };
          var topDoctors = await NetworkLayer.postData(submitData3);
          var topDoctorsResult = topDoctors.body.result
          filter.topDoctorsTotal = topDoctorsResult.totalCount

          if(topDoctorsResult.data.length){
            filter.prevOffset = currentPage*4
            filter.prevLimit = 4
            filter2.offset = currentPage*4
            filter2.limit = 4
          }
        }
      
      filter2.offset = filter2.offset - (filter.sponsoredTotal ? filter.sponsoredTotal : 0)
    }

    filter2.type = 'normal'
    const submitData2 = {
      headers: headers,
      url: "providers/search/get/all",
      body: JSON.stringify(filter2),
    };

    let normalDoctors = await NetworkLayer.postData(submitData2);
    let normalDoctorsResult = normalDoctors.body.result
    
    
    authContext.unsetLoader();
    let totalCount = normalDoctorsResult.totalCount
    let allData = []
    if(filter.sponsoredTotal >= currentPage+1)
    {
       totalCount = sponsoredDoctorsResult.totalCount + normalDoctorsResult.totalCount
       let sponsoredDoctors = []
       sponsoredDoctorsResult.data.map((doc)=>{
          doc.userType = 'sponsored'
          sponsoredDoctors.push(doc)
          return null
        })

       
       if((filter.topDoctorsTotal >= currentPage+1 || filter.topDoctorsTotal) && filter.isTopShow)
       {
        let topDoctors = []
        topDoctorsResult.data.map((doc)=>{
          doc.userType = 'top'
          topDoctors.push(doc)
          return null
        })
        allData = [...sponsoredDoctors,...topDoctors,...normalDoctorsResult.data]
       }
       else{
          allData = [...sponsoredDoctors,...normalDoctorsResult.data]
       }
    }else{
      if((filter.topDoctorsTotal >= currentPage+1 || filter.topDoctorsTotal) && filter.isTopShow)
      {
        let topDoctors = []
        topDoctorsResult.data.map((doc)=>{
          doc.userType = 'top'
          topDoctors.push(doc)
          return null
        })
        allData = [...topDoctors,...normalDoctorsResult.data]
      }else{
        allData = normalDoctorsResult.data
        ReactGA.initialize('G-V0BN382S55');
        ReactGA.pageview(window.location.pathname + window.location.search+'?q='+filter.searchValue);
      }
    }
    setsresults(allData);
    settotalCount(totalCount);

    
   /* if (result.body.status) {
      if(result.body.result.totalCount === 0 && filter.firstName){
        if(authContext ?.user ?.accountInfo ?.id){
          setOpenNoResultsModal(true)
          authContext.showModal();
        }
      }
      if(result.body.result.totalCount === 0 && filter.cityOrZip!==''){
        setOpenNoResultsZipModal(true)
        authContext.showModal();
      }
      result.body.result.address && result.body.result.address.formattedAddress && setbrowserAddress(result.body.result.address.formattedAddress)
      
      
      //setsresults(result.body.result.data);
      //settotalCount(result.body.result.totalCount);
    } else {
      
      setsresults([]);
    }*/
    // setfilter((preState) => ({
    //   ...preState,
    //   callApi: false,
    // }));
    // }
  }, [filter, latlng]);



  useEffect(() => {
    getSearchResult();
  }, [filter, latlng]);

  useEffect(() => {
    if (authContext.headerSearchText ?.actionSubmit) {
      setfilter((preState) => ({
        ...preState,
        callApi: true,
        searchValue: authContext ?.headerSearchText ?.text,
      }));
    }
  }, [authContext.headerSearchText]);




  const dateSelect = (value = false, event, type = false) => {
    let cdate = value;
    if (type) {
      if (type == 'prev') {
        cdate = Global.addDays(startDate, -5)
      } else {
        cdate = Global.addDays(startDate, 5)
        // console.log('cdate=====>', cdate);
      }
    }
    setStartDate(cdate);
  }

  useEffect(() => {
    return () => {
      if (authContext ?.headerSearchText ?.text) {
        authContext.setHeaderSearchText({
          text: "",
          actionSubmit: false,
        });
      }
    };
  }, [authContext]);
  // useEffect(() => {
  //   console.log('testing purpose--> check header reload--> search-resultpage-->', props);
  //   Global.getGeoLocation(browserLatLng)

  // },[])

  const unsetNoResultsComponent = () => {
    setOpenNoResultsModal(false)
    setOpenNoResultsZipModal(false)
  }

  useEffect(() => {
    if (modalStatus) {
      authContext.showModal();
    } else {
      authContext.hideModal();
    }
  }, [modalStatus]);


  return (
    <Fragment>
       {
        sresults?.length === 0 && openNoResultsZipModal === true? 
          <Custommodal componentName={<NoResultsModal zipCode={filter.cityOrZip} unsetComponent={unsetNoResultsComponent} />} />
        :
        ""
      } 
      <Container
        className={
          userCateType === "patient" ? "doctor-search-wrap" : "search-wrap"
        }
      >
        {userCateType === "patient" ? (
          <>

            <Row>
              <div className="col-12">
                <h1>Doctor Search Results</h1>
              </div>
            </Row>
            <HomeSearch filter={filter} setfilter={setfilter} />
            <AllFilters filter={filter} setfilter={setfilter} resetInviteModal={setOpenNoResultsModal} invitemodal={openNoResultsModal} />
          </>
        ) : (
            <div className="sticky-provider">
              <Row>
                <div className="col-12">
                  <div className="search-result-heading-wrap">
                    <h1>Provider Search Results</h1>
                    <div className="filter-tab">
                      <Link onClick={(e) => showModal(e)} to="#">
                        All Filters
                  </Link>
                    
                      <Custommodal
                        componentName={
                          <SearchFilter
                            filter={filter}
                            setfilter={setfilter}
                            setModalStatus={setModalStatus}
                          />
                        }
                      />
                      
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          )}
        <div className="sticky-patient">
          <Row className="justify-content-end">
            {userCateType === "patient" && sresults.length > 0 && (
              <div className="col-lg-6 col-xl-6">
                <SchedulingTitle
                  startDate={startDate}
                  setStartDate={setStartDate}
                  dateSelect={dateSelect}
                  key={'schedulingTitle'}
                />
                <SchedulingDate startDate={startDate} setStartDate={setStartDate} key={'schedulingDate'}/>
              </div>
            )}
          </Row>
        </div>
        <Row>
          <div className="col-12 px-0">
            <div className="patient-doc-search-list">
              <Row
                style={{
                  justifyContent: sresults.length > 0 ? null : "center",
                  alignItems: sresults.length > 0 ? null : "center",
                }}
              >
                {sresults.length > 0 ? (
                  // sresults.map((each, index) => (

                  // )
                  <SponsoredDoc
                    items={sresults}
                    userCateType={userCateType}
                    startDate={startDate}
                    getProfile={getSearchResult} 
                    componentName={'searchResult'}
                    getSearchResult={getSearchResult}
                    browserAddress={browserAddress}
                    key={'sponDoc'}
                  />
                  // <></>
                ) : (
                    <h2 className="no-service"><a href="/#" onClick={(e) => e.preventDefault()}>Service Area Coming Soon</a></h2>
                  )}
              </Row>
            </div>
          </div>
          <div className="col-12 px-0 paging-doctor-search">
            <Pagination
              total={totalCount}
              limit={filter.limit}
              range={4}
              offset={filter.offset}
              callBack={changeLimit}
              key={'pagination'}
            />
          </div>
        </Row>
      </Container>
    </Fragment>
  );
};

SearchResults.defaultProps = {
  userCateType: "",
};

export default memo(SearchResults);
