import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';

import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { PatientLinks } from '../../linkFile';







const CovidSelfAssessment2 = (props) => {
  const authContext = useContext(AuthContext);
  const step2 = authContext['covidOption'] && authContext['covidOption']['step-2'] ? authContext['covidOption']['step-2'] : false

  return (
    <Fragment>
      <Container fluid className="covid-screens-wrap">
        <Row>
          <Container>
            <Row>
                <div className="col-12">
                    <h1>Self Assessment</h1>
                    <div className="progress-outer">
                        <div className="progress-inner" style={{width: "26%"}}></div>
                    </div>
                </div>                
            </Row>
            <Row>
              <div className="col-12">
                <div className="covid-self-outerbox">
                    <div className="covid-question">
                      <span>2.</span>
                      Do you have any of the following life-threatening symptoms?
                    </div>
                    <div className="covid-question-options">
                      <div className="cus-radio">
                          <input type="radio" id="opt1" checked={step2 === 'opt1' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt1"/>
                          <label htmlFor="opt1">Not experiencing any life-threatening symptoms</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt2" checked={step2 === 'opt2' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt2"/>
                          <label htmlFor="opt2">Gasping for air or cannot talk without catching your breath</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt3" checked={step2 === 'opt3' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt3"/>
                          <label htmlFor="opt3">Severe and constant pain or pressure in the chest</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt4" checked={step2 === 'opt4' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt4"/>
                          <label htmlFor="opt4">Severe and constant dizziness or light headedness</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt5" checked={step2 === 'opt5' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt5"/>
                          <label htmlFor="opt5">Confused (new or worsening)</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt6" name="options" checked={step2 === 'opt6' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} value="opt6"/>
                          <label htmlFor="opt6">Slurred speech (new or worsening)</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt7" name="options" checked={step2 === 'opt7' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} value="opt7"/>
                          <label htmlFor="opt7">Unconscious or very difficult to wake up</label>
                      </div>
                      <small className="error-input">{ props.error.step2 }</small>
                    </div>
                    <div className="covid-buttons">
                      <span className="covid-bkbtn" data-prev-page={PatientLinks.covid_self_1} onClick={(e)=>{ props.goPrevious(e) }}>Back</span>
                      <span className="nextbtn">
                        <input type="button" className="next-btn-pro" onClick={(e)=>{ props.onNext(e) }} value="Next"/>
                      </span>
                    </div>
                </div>                
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
    );
  }
  
  export default CovidSelfAssessment2;