import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'react-bootstrap';

import HomeHeader from '../../global/header/header';

import Footer from '../../global/footer/footer';

import Errorpage from '../../patient/contactus/appointmentRequestSubmit';

const Test = () => {

  return (
    <Fragment>
      {/* header */}
      {/* <Container fluid className="header px-0">
        <HomeHeader/>
      </Container> */}
      


      {/* <Container fluid className=""> */}
        <Errorpage/>
      {/* </Container> */}



      {/* footer */}
      {/* <Container fluid className="footer">
        <Container>
          <Footer/>
        </Container>
      </Container> */}
      
     
      
    </Fragment>
    );
  }
  
  export default Test;