import React, { Fragment, useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { AdminLinks } from '../../../linkFile';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Loadermodal from "../../../global/loader-modal/loader-modal";
import AuthContext from '../../../reactLayer/auth/AuthContext';
import { Link } from 'react-router-dom';
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';

const RatingReviewManage = (props) => {
    let ratingID = props.ratingID;
    const history = useHistory();
    const [data, setdata] = useState();
    const [message, setMessage] = useState("");
    const authContext = useContext(AuthContext);
    const [visibleComponent, setvisibleComponent] = useState('personalInfo');
    const tabName = [{ id: 'personalInfo', name: 'Personal Information' }, { id: 'medSpe', name: 'Medical Specialty' }, { id: 'practiceInfo', name: 'Practice Information' }, { id: 'appointHistory', name: 'Appointment History' }, { id: 'digitalRepo', name: 'Digital Reputation' }, { id: 'endorse', name: 'Endorsement' }]
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    const [userRating, setuserRating] = useState({
        rating: {
            value: 0,
            tempvalue: '',
            validation: ['blank'],
            errorMsg: ['Please rate'],
            errorKey: 'rating',
            rating: false
        },
        review: {
            value: '',
            validation: [''],
            errorMsg: ['Please rate'],
            errorKey: 'review',
            remaining: 300,
            review: false
        },
    })


    const getDetails = async (id) => {
        authContext.setLoader();

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/rating/get/' + id,
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        console.log("ratings result", result ?.result)
        setdata(result ?.result);
        setMessage(result ?.result.message);

        let urating = userRating;
        urating['rating']['value'] = result ?.result.rating;
        urating['rating']['tempvalue'] = 0;
        urating['rating']['rating'] = false;
        setuserRating({ ...urating });

        authContext.unsetLoader();
    }

    const goBack = () => {
        history.push({
            pathname: AdminLinks.admin_rating_review,
        });
    }
    const changeValue = (e) => {
        if (e.target.value.length <= 500) {
            setMessage(e.target.value);
        }
    }

    useEffect(() => {
        getDetails(ratingID);
    }, [])

    const submitForm = async () => {
        authContext.setLoader();
        // FORM DATA 
        const formData = {
            id: data.id,
            comment: message,
            rating: userRating.rating.value
        }
        // console.log(formData); return false;

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/rating/update/' + data.id,
            body: JSON.stringify(formData),
        }

        try {
            let result = await NetworkLayer.postData(submitData);
            if (result ?.body ?.status) {
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
            }
            authContext.showResponseModal();
            setTimeout(() => {
                goBack()
            }, 2000);
        } catch (err) {

        }


        authContext.unsetLoader();
    }

    const settemprating = (r) => {
        let urating = userRating;
        urating['rating']['tempvalue'] = r;
        urating['rating']['rating'] = false;
        setuserRating({ ...urating });
    }

    const setrating = (r) => {
        let urating = userRating;
        urating['rating']['value'] = r;
        urating['rating']['tempvalue'] = 0;
        urating['rating']['rating'] = false;
        setuserRating({ ...urating });
    }

    return (
        <Fragment>
            {
                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            <Loadermodal />
            {
                data && <div className="rating-review-wrap">
                    <div className="ratrev-top">
                        <div className="ratrev-left">
                            <span>Provider</span>
                            <span>{`${data.providerInfo ?.providerName ?.firstName} ${data.providerInfo ?.providerName ?.lastName}`}</span>
                        </div>
                        <div className="ratrev-right">
                            <span>Patient</span>
                            <span>{`${data.patientsInfo ?.patientName ?.firstName} ${data.patientsInfo ?.patientName ?.lastName}`}</span>
                        </div>
                    </div>

                    <div className="ratrev-rating">
                        <span className="ratrev-rating-head">Rating</span>
                        <span className="rating">
                            <div className="stars-outer">
                                {
                                    [1, 2, 3, 4, 5].map((d, i) =>
                                        <span key={i} className={userRating.rating.value >= d ? 'star full' : userRating.rating.tempvalue >= d ? 'star full' : 'star'} onMouseLeave={() => settemprating(0)} onMouseEnter={() => settemprating(d)} onClick={() => setrating(d)}></span>
                                    )
                                }
                                <small className="error-input">{userRating.rating.rating}</small>
                            </div>
                        </span>
                    </div>

                    <div className="ratrev-rating">
                        <div className="comment-area-rating">
                            <label className="pro-label">Comment</label>
                            <textarea id="address" name="address" className="input-pro" value={message} onChange={(e) => { changeValue(e) }}></textarea>
                        </div>
                        <small style={{ color: '#979797' }}>{"500 Characters Maximum"}</small>
                    </div>
                    <div className="bottom-btns mb-4">
                        <div className="next-prev-btn-pro">
                            <input type="button" className="prev-btn-pro" value="Cancel" onClick={goBack} />
                            <input type="button" className="next-btn-pro" value="Save" onClick={submitForm} />
                        </div>
                    </div>
                </div>
            }

        </Fragment>
    )

}

export default RatingReviewManage;