import React, { Fragment, useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';
import { ProviderLinks } from '../../linkFile';

import ProviderLeftProfile from '../pro-doc-profile-left/pro-doc-profile-left';
import Scheduling from '../scheduling/scheduling';
import ProfileTabs from '../profile-tabs/profile-tabs';
import Ratingandreview from '../rating-review/rating-review';


const DashboardProfile = (props) => {
    const authContext = useContext(AuthContext);
    const [profile, setProfile] = useState({});
    const [slots, setslots] = useState([]);
    const [upgrade, setupgrade] = useState(false);
    


    const getLocationBasedDetails = async(locationId, date = '') => {
        authContext.setLoader();
        // setSelectedLocation(locationId,alllocation);
        let startFrom, cDate;
        if( date ) {
            cDate = Global.getYearMonthDate(date);
        } else {
            cDate = Global.getYearMonthDate();
        }
        startFrom = cDate.year +'-'+ cDate.month +'-'+ cDate.date;
        let fData = { startFrom:startFrom, locationId:locationId };
                
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'appointment/schedule',
            body: JSON.stringify(fData),
        }
        let result = await NetworkLayer.postData(submitData);
        // console.log('getLocationBasedDetails result ===>', result);
        if( result.body.status ) {
            // console.log('Test data==>', result.body.result.location);
            if(result.body.result.slots) {
                setslots([...result.body.result.slots]);
            } else {
                setslots([]);
            } 
            authContext.unsetLoader();
        } else {
            setslots([]);
            authContext.unsetLoader();
        }
    }
    const getProfile = async () => {
        authContext.setLoader();
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'providers/profile-details/get'
        }
        let result = await NetworkLayer.getRequest(submitData);
        // console.log('result in get profile', result);
        if(result.status) {
            setProfile(result.result);
            result.result.practiceInfo &&
                result.result.practiceInfo.providerPracticeLocations && 
                    result.result.practiceInfo.providerPracticeLocations.length &&
                    getLocationBasedDetails(result.result.practiceInfo.providerPracticeLocations[0]['id']);
        }
        authContext.unsetLoader();
    }
    const redirectToSchedule = (e) => {
        e.preventDefault();
        props.redirectToSchedule && props.redirectToSchedule();
    }
    const changeDate = (date) => {
        let chDate = date;
        // getLocationBasedDetails(practiceData[0].locationId.value, '', chDate);
    }
    useEffect(() => {
        getProfile()
    },[])
  return (
    <Fragment>    
        <Container>
            {/* Profile section start Scheduling */}
            <Row className="mb-4">
                <div className="col-12">    
                    <div className="provider-profile-inner provider-dash-wrap">
                        <ProviderLeftProfile {...profile} getProfile={getProfile} editable={true}/>
                        <div className="pro-profile-box-style provider-detail-right-top provider-dash-right position-relative">
                            {
                                profile &&  profile.providerCurrentPlan && profile.providerCurrentPlan.providerCurrentServices && profile.providerCurrentPlan.providerCurrentServices.indexOf(4) > '-1' ? (<>
                                    <h3>
                                        Scheduling Template
                                        <span>Scheduling availability for the next five (5) weeks</span>
                                        <div  onClick={(e)=>redirectToSchedule(e)} className="edit-icon-position-top"></div>
                                    </h3>
                                    <a href="/#" onClick={(e)=>redirectToSchedule(e)}>
                                        <Scheduling upgrade={upgrade} selectedDate={''} changeDate={changeDate} slots={slots}/> 
                                    </a>
                                    </>) : (<>
                                    <div className="modal-appointment">
                                        <div className="modal-content-appointment">
                                            <div className="modal-heading-appointment">
                                                <h1>Premium Feature</h1>
                                                <div className="modal-text-appointment">
                                                You are on a {profile &&  profile.providerCurrentPlan && profile.providerCurrentPlan.planName ? profile.providerCurrentPlan.planName : 'free'} plan.<br/>
                                                Please upgrade your plan to activate this premium feature
                                                </div>
                                                <div className="upgrade-btn-appointment">
                                                    <Link className="next-btn-pro" to={ProviderLinks.accountprofile+'/manage-subscription'}>Upgrade Plan</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div role="document" className="upgrade-modal-dialog">
                                        <div className="modal-content">
                                            <div className="container">
                                                <div className="row"></div>
                                                <div className="dashboard-modal-wrap">
                                                    <div className="edit-intro">
                                                        <h3>Premium Feature</h3>
                                                        You are on a free plan. 
                                                        Please upgrade your plan to activate this premium feature
                                                    </div>
                                                    <div className="col-md-12 text-center"> */}
                                                        {/* <input type="button"  className="next-btn-pro pl-4 pr-4" value='Upgrade Plan' /> */}
                                                        {/* <Link className="next-btn-pro pl-4 pr-4" to={ProviderLinks.accountprofile+'/manage-subscription'}>Upgrade Plan</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </>)
                            }
                            
                            
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
        <div className="pro-dash-tabs">
            <ProfileTabs {...profile} getProfile={getProfile} editable={true} />
        </div>
        <div className="pro-dash-review">
            <Ratingandreview profile={profile} getProfile={getProfile} ratingReviews = {profile.ratingReviews ? profile.ratingReviews : []}/>
        </div>
    </Fragment>
    );
  }
  
  export default DashboardProfile;