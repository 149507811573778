import React, { useRef, useEffect,Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Loader } from "google-maps";
import 'promise-polyfill/src/polyfill';
const loader = new Loader("AIzaSyAUcaKOD9Ly0w_NfphqfhzcT7KAebWZEp0", {});

const Gmaps = (props) => {
  const { width, height, zoomLabel, gmapData } = props;
  
  const mapWrapper = useRef(null);
  let map;

  const initMap = async () => {
    
    const google = await loader.load();
    const myLatLng = { lat: -31.56391, lng: 147.154312 };
    map = new google.maps.Map(mapWrapper.current, {
      center: { lat: gmapData[0]["lat"], lng: gmapData[0]["lng"] },
      zoom: zoomLabel,
      mapTypeControl: false,
      streetViewControl: false

    });
    gmapData.length &&
    gmapData.map((location, i) => {
        new google.maps.Marker({
          position: location,
          map,
          // mapTypeControlOptions: {      
          //   mapTypeIds: [
          //     google.maps.MapTypeId.ROADMAP,
          //   ]
          // }
          // label: labels[i % labels.length],
        });
      });
    // new google.maps.Marker({
    //     position: myLatLng,
    //     map,
    //     title: "Hello World!",
    // });
    // plotMarkers();
  };

  useEffect(() => {
    gmapData.length && initMap();
  }, []);
  const suspenseLoader = () => <p></p>;
  return (
    <Suspense fallback={suspenseLoader()}>
      <div
        id="map"
        style={{ height: width + "px", maxWidth: height + "px" ,marginTop:'20px'}}
        ref={mapWrapper}
      ></div>
    </Suspense>
  );
};

Gmaps.defaultProps = {
  width: 500,
  height: 500,
  zoomLabel: 17,
  gmapData: [{ lat: 20.5937, lng: 78.9629 }],
};

export default Gmaps;
