import React, { Fragment, useContext, useState, useEffect } from 'react';
import AuthContext from '../../reactLayer/auth/AuthContext';
import StorageAdaptation from '../../reactLayer/storage/storage';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch, Link, useHistory, useLocation  } from 'react-router-dom';
import { ProviderLinks, PatientLinks } from '../../linkFile';
import Custommodal from '../modal/modal';
import Login from '../../components/provider-login/provider-login';
import Twostep from '../../components/provider-login/twostep';
import ServiceComingSoon from '../../components/provider-login/service-area-coming-soon';
import Notifyregister from '../../components/provider-login/Notifyregistersuccess';


const HomeHeader = (props) => {
    const [showStrip, setshowStrip] = useState(true);
    const [showCookieConcent, setshowCookieConcent] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [tokenExpire, settokenExpire] = useState(null);
    const modalList = ['login','twostep','service-comming-soon','notify-register'];
    const path = location.pathname !== "/";

    const openLoginModal = (e,modalName)=>{
        // authContext.hideModal()
        e && e.preventDefault();
        settabvisibleComponent(modalName)
        authContext.showModal()
    }
    const closeModals = () => {
        authContext.showLoginModal(false);
        settabvisibleComponent('');
    }
    
    useEffect(() => {
        if(showCookieConcent === false){
            setTimeout(()=>{
                setshowCookieConcent(true)
            },1000)
        }

        if( props && props.location && props.location.state && props.location.state.modalName ) {
            openLoginModal('', props.location.state.modalName);
            history.replace({ prevPath: '' });
        } else if(authContext.loginModal && authContext.loginModal.modalName) {
            openLoginModal('', authContext.loginModal.modalName);
        }

        if( StorageAdaptation.getLocalStorage("tokenExpire", false, "session") && StorageAdaptation.getLocalStorage("tokenExpire", false, "session") === 'patient' && !StorageAdaptation.getLocalStorage("token", false, "local")) {
            settokenExpire(true);
            openLoginModal('', 'login');
            StorageAdaptation.removeLocalStorage("tokenExpire", "session");
        }
        
        return () => {
            props = {};
            settabvisibleComponent('');
            history.replace({ prevPath: '' });
        }
    },[authContext.loginModal])

  return (
    <Fragment>
    { modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal unsetComponent={settabvisibleComponent} componentName={
            tabvisibleComponent === 'login' ? <Login ltype={'patient'} closeModals={closeModals} settabvisibleComponent = {settabvisibleComponent} tokenExpire={tokenExpire}/> :
            tabvisibleComponent === 'twostep' ? <Twostep ltype={'patient'} {...props} settabvisibleComponent = {settabvisibleComponent}/> :
            tabvisibleComponent === 'service-comming-soon' ? <ServiceComingSoon ltype={'patient'} /> :
            tabvisibleComponent === 'notify-register' ? <Notifyregister ltype={'patient'} /> 
            :
            <></>
        } />
    ) : (
        <></>
    )}
    <header> 
        {/* Home page header     */}
        {
            showStrip ?(<>
            <div className="covid-strip">
                <img src="/assets/images/info-icon.png" className="info-covid-icon" alt="Info" /><Link to={PatientLinks.covid_self_1}>Click here to view</Link> <span>COVID-19 Symptoms and Self Screening Test</span>
                <div onClick={()=> {setshowStrip(false)}} className="covid-close-icon"><img src="/assets/images/cross.svg" className="scale" /></div>
            </div>
            </>):(<></>)
        }
        {
            !authContext.cookie_concent ?(<>
            <div className={showCookieConcent ? "cookie-concent-wrapper" : "cookie-concent-wrapper hidden"}>
                <p>By continuing to use this website, you are agreeing to DocScheduler's <strong>Privacy Policy and Terms of use.</strong></p>
                <p><strong>Important notice regarding use of cookies:</strong> We have updated our Privacy Policy to reflect our use of cookies to collect and process data. By continuing to use this website, you agree to our use of cookies and similar technologies as described in our Privacy Policy. <strong>Privacy Policy.</strong> More about our use of cookies >> </p>
                <div onClick={()=> {authContext.setCookieConent(true)}} className="next-prev-btn-pro"><a className="next-btn-pro full-width" href="/#" onClick={(e) => e.preventDefault()}>Accept and hide this message</a></div>
            </div>
            </>):(<></>)
        }
        <Container>
            <Row>
                <div className="col-12">
                    <div className="header-inner-before-login">
                    {
                        path ?(<>
                        <Link className="header-logo" to={PatientLinks.login}>
                            <div className="logo-main">
                            <img
                                src="/assets/images/logo-icon.svg"
                                className="logo-icon"
                                alt="DocScheduler"
                            />
                            <span>DocScheduler</span>
                            </div>
                        </Link>
                        </>):(<></>)
                    }
                        <div className="header-practice-link">
                            <Link to={ProviderLinks.freeprofile} className="under-line">List your practice on DocScheduler</Link>
                        </div>                          
                        <div className="create-acc-block">
                            <a href="/#" onClick={(e)=>e.preventDefault()}>Create Account</a>
                            <div className="home-menu">
                                <div className="home-menu-inner">
                                    <Link to={PatientLinks.register_setp1}>
                                        <div className="home-menu-links">
                                            <span>Patients</span>
                                            <span><a href="/#">Create Account</a></span>
                                        </div>
                                    </Link>
                                    <Link to={ProviderLinks.freeprofile}>
                                    <div className="home-menu-links no-border">
                                        <span>Doctors</span>
                                        <span><a href="/#">List Your Practice</a></span>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="sign-in-block">
                            <a href="/#" onClick={(e)=>e.preventDefault()}>Sign In</a>
                            <div className="home-menu">
                                <div className="home-menu-inner">
                                    <Link to="/">
                                        <div className="home-menu-links">
                                            <span>Patients</span>
                                            <span><a href="/#" onClick={(e)=>openLoginModal(e, 'login')}>Sign In</a></span>
                                        </div>
                                    </Link>
                                    <Link to={ProviderLinks.login}>
                                        <div className="home-menu-links">
                                            <span>Doctors</span>
                                            <span><a href="/#">Sign In</a></span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>        
    </header>
</Fragment>
    );
  }
  
  export default HomeHeader;