import React, { Fragment, useState, useContext, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import StorageAdaptation from '../../reactLayer/storage/storage';
import AuthContext from '../../reactLayer/auth/AuthContext';
import InputValidation from '../../reactLayer/validation';
import { useHistory } from "react-router-dom";
import { OldSocialLogin as SocialLogin } from 'react-social-login';
import { ProviderLinks, PatientLinks } from '../../linkFile';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';

const ProviderLogin = (props) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [tokenExpire, settokenExpire] = useState(null);
    const [passwordExpired,setPasswordExpired] = useState(false)

    const [resetPassword,setResetPassword] = useState('')
    const [resetPasswordAgain,setResetPasswordAgain] = useState('')
    const [resetPasswordError,setResetPasswordError] = useState('')
    const [resetPasswordAgainError,setResetPasswordAgainError] = useState('')
    const [verifyToken,setVerifyToken] = useState('')
    const [modalProps,setmodalProps] = useState({})

    const emailErrorMsg = ['Email is required', 'Invalid Email'];
    const passwordErrorMsg = ['Password is required', 'Invalid Password'];
    const loginType = props.ltype ? props.ltype : 'provider';
    const [errors, setErrors] = useState({
        eemail: false,
        epassword: false,
        eresponse: false,
    });
    const Validatepages = () => {
        let user = authContext.user ? authContext.user : null

        if (loginType === 'provider') {
            if (user && user.accountInfo && user.accountInfo.isProfileCompleted && authContext.token) {
                history.push({
                    pathname: ProviderLinks.home,
                });
            } 
            // else {
            //     history.push({
            //         pathname: ProviderLinks.register_setp1,
            //     });
            // }
        } else {
            if (user && user.accountInfo && user.accountInfo.isProfileCompleted && authContext.token) {
                history.push({
                    pathname: PatientLinks.home,
                });
            }
            // if (user && !user.step) {
            //     history.push({
            //         pathname: PatientLinks['register_setp' + user.step],
            //     });
            // } else {
            //     history.push({
            //         pathname: PatientLinks.register_setp1,
            //     });
            // }
        }
    }
    const [loginData, setLoginData] = useState({
        email: {
            value: '',
            validation: ['blank', 'email'],
            errorMsg: emailErrorMsg,
            errorKey: 'eemail',
        },
        password: {
            value: '',
            validation: ['blank', 'password'],
            errorMsg: passwordErrorMsg,
            errorKey: 'epassword',
        },
        keep_me: {
            value: 0,
            validation: [],
        },

    });
    const openPatientLogin = (e) => {
        e.preventDefault();
        props.settabvisibleComponent('login');
        authContext.showModal();
    }
    const formSubmit = async (e) => {
        e.preventDefault();
        const validationResult = InputValidation(loginData);
        console.log('validationResult-->', validationResult)
        let otpVerifiy = authContext.otpVerifiy && authContext.otpVerifiy.length && authContext.otpVerifiy.indexOf(loginData.email.value) >'-1' ? false : true;
        if (Object.keys(validationResult).length > 0) {
            setErrors({ ...errors, ...validationResult });
        } else {
            authContext.setLoader();

            let data = {
                email: loginData.email.value,
                password: loginData.password.value,
                keep_me: loginData.keep_me.value,
                otpVerifiy: otpVerifiy
            }
            let url = '';
            if (loginType === 'provider') {
                url = 'providers/login';
            } else {
                url = 'patients/login';
            }
            const submitData = {
                url: url,
                body: JSON.stringify(data),
            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            console.log('result .body', result, authContext.otpVerifiy);
            
            if (result.body.status) {
                let user = result.body.result;
                user.token = result.body.result.token;
                authContext.setToken(user.token);
                if (loginType === 'provider') {
                    if (result.body.result.data && result.body.result.data.accountInfo && result.body.result.data.accountInfo.isVerifyAuth === 0) {
                        authContext.setUser(result.body.result.data);
                        if( !result.body.result.data.isProfileCompleted && result.body.result.data.step ) {
                            history.push({ pathname: ProviderLinks.ProviderLinks['register_setp'+result.body.result.data.step]});
                        } else {
                            history.push({ pathname: ProviderLinks.dashboard });
                        }
                    } else if (result.body.result.data && result.body.result.data.accountInfo && result.body.result.data.accountInfo.isVerifyAuth === 1 && !otpVerifiy) {
                        authContext.setUser(result.body.result.data);
                        if( !result.body.result.data.isProfileCompleted && result.body.result.data.step ) {
                            history.push({ pathname: ProviderLinks['register_setp'+result.body.result.data.step]});
                        } else {
                            history.push({ pathname: ProviderLinks.home });
                        }
                    } else {
                        history.push({ pathname: ProviderLinks.twofactauth });
                    }
                } else {
                    console.log('result.body.result',result.body.result)
                    if (result.body.result.data && result.body.result.data.accountInfo && result.body.result.data.accountInfo.isVerifyAuth === 0) {
                        result.body.result.data['isProfileCompleted'] = 1
                        authContext.setUser(result.body.result.data);
                        if (authContext.loginModal && authContext.loginModal.redirectUrl) {

                        } else {

                            history.push({ pathname: PatientLinks.home });
                        }
                    } else if (result.body.result.data && result.body.result.data.accountInfo && result.body.result.data.accountInfo.isVerifyAuth === 1 && !otpVerifiy) {
                        authContext.setUser(result.body.result.data);
                        if (authContext.loginModal && authContext.loginModal.redirectUrl) {

                        } else {
                            history.push({ pathname: PatientLinks.home });
                        }
                    } else {
                        props.settabvisibleComponent('twostep');
                    }
                }
            } else {
                if(result?.body?.result?.error_type){
                    if(result.body.result.error_type === 'password_expired'){
                        setVerifyToken(result.body.result.verify_token)
                        setPasswordExpired(true)
                    }else{
                        setErrors({ ...errors, ...{ eresponse: result.body.message } });
                    }
                }else{
                    setErrors({ ...errors, ...{ eresponse: result.body.message } });
                }
            }
        }
    }
    const dosubmit = (e) => {
        // console.log('fsaffsaf',e.key);
        if (e.key === 'Enter') {
            formSubmit(e);
        }
    }
    const keepLogin = (e) => {
        if (e.currentTarget.checked === true) {
            setLoginData({
                ...loginData,
                ...{
                    keep_me: {
                        value: 1,
                        validation: []
                    }
                }
            })
        } else {
            setLoginData({
                ...loginData,
                ...{
                    keep_me: {
                        value: 0,
                        validation: [],
                    }
                }
            })
        }
    }
    const handleSocialLogin = async (user = null, err = null) => {
        if (user && user._profile && user._profile.email) {
            let data = {
                email: user._profile.email ? user._profile.email : '',
                oauth_provider: 'google',
                oauth_uid: user._profile.id,
                firstName: user._profile.firstName ? user._profile.firstName : '',
                lastName: user._profile.lastName ? user._profile.lastName : '',
                // image: user._profile.profilePicURL ? user._profile.profilePicURL : '',            
            }
            authContext.setLoader();

            let url = 'patients/google/login';
            const submitData = {
                url: url,
                body: JSON.stringify(data),
            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if (result.body.status) {
                if (result.body.result.data) {
                    console.log('result.body.result', JSON.stringify(result.body.result));
                    let user = authContext.user ? authContext.user : {};
                    user['token'] = result.body.result.token;
                    user = { ...user, ...result.body.result.data }
                    authContext.setToken(user.token);
                    authContext.setUser(user);
                    if (result.body.result.step) {
                        history.push({ pathname: PatientLinks['register_setp' + result.body.result.step] });
                    } else if (result.body.result.data.accountInfo && result.body.result.data.accountInfo.isProfileCompleted) {
                        history.push({ pathname: PatientLinks.dashboard });
                    } else {
                        history.push({ pathname: PatientLinks['register_setp2'] });
                    }
                }
            }
        }
    }

    const handleSocialLoginFailure = (err) => {
        console.error(err)
    }

    const resetPasswordSubmit = async () => {
        let checkBlank = false
        if(resetPassword === ''){
            checkBlank = true
            setResetPasswordError('Please enter password')
        }
        if(resetPasswordAgain === ''){
            checkBlank = true
            setResetPasswordAgainError('Please enter password again')
        }
        if(checkBlank){ return; }

        const regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
        if(regularExpression.test(resetPassword) === false){
            setResetPasswordError('Password must contain 8 letters, at least 1 number, and at least 1 special character')
            return;
        }
        if(resetPassword !== resetPasswordAgain){
            setResetPasswordAgainError('Passwords does not matched')
            return;
        }
        const data = {password:resetPassword,confirmPassword:resetPasswordAgain,resetPasswordToken:verifyToken};
        const url = 'reset-password';
        const submitData = {
            url: url,
            body: JSON.stringify(data),
        }
        authContext.setLoader();
        let result = await NetworkLayer.postData(submitData);
        authContext.unsetLoader();
        if(result.body.status === false){
            setResetPasswordAgainError(result.body.message)
        }else{
            setmodalProps({
                type:'success', 
                msg: result.body.message,
            });
            authContext.showResponseModal();
            setPasswordExpired(false)
        }
    }

    useEffect(() =>{
        authContext.hideResponseModal();
        //console.log('ccc',StorageAdaptation.getLocalStorage("tokenExpire", false, "session"))
        if( StorageAdaptation.getLocalStorage("tokenExpire", false, "session") && StorageAdaptation.getLocalStorage("tokenExpire", false, "session") == 'provider' && !StorageAdaptation.getLocalStorage("token", false, "local")) {
            settokenExpire(true);
        }
        // return ()=>{
        //     StorageAdaptation.removeLocalStorage("tokenExpire", "session");
        // }
    },[tokenExpire])
    const suspenseLoader = () => <p></p>;
    return (
        <Suspense fallback={suspenseLoader()}>
            <Fragment>
                {/* Form start step 1 */}
                <Row>
                    {
                        authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                    }
                </Row>
                {authContext.user === null || (authContext.user && !authContext.user.isProfileCompleted ) ? <>
                    <Container className="">
                        <Row className={loginType === 'provider' ? 'provider-login-wrap' : 'provider-login-wrap patient'}>

                            <div className="col-md-12 mb-3">
                                {loginType === 'provider' ? (<>
                                    <h1>Provider Sign In</h1>
                                    {
                                        tokenExpire ? (<>
                                            <div className="loggedout-msg">
                                                <div className="l-msg"><div className="right-mark"></div></div>                    
                                                <div className="r-msg">For your safety, we logged you out automatically due to inactivity. Please log in again.</div>
                                            </div>
                                        
                                        </>) : (<></>)
                                    }
                                    <p>By continuing, I agree that I am a licensed medical professional and have read and agree to DocScheduler's <a href={PatientLinks.term_of_user}>Terms of Use</a> and <a href={PatientLinks.privacy_policy}>Privacy Policy.</a></p></>
                                ) : (
                                    passwordExpired ?
                                        <>
                                            <h1>Reset your password</h1>    
                                            <p>Your need to reset your password for security reasons as it's been 90 days since you updated your password.</p>
                                        </>
                                    :
                                        <>
                                        <h1>Patient Sign In</h1>
                                            {
                                                props.tokenExpire ? (<>
                                                    <div className="loggedout-msg">
                                                        <div className="l-msg"><div className="right-mark"></div></div>                    
                                                        <div className="r-msg">For your safety, we logged you out automatically due to inactivity. Please log in again.</div>
                                                    </div>
                                                
                                                </>) : (<></>)
                                            }
                                            
                                            <p>By continuing I agree that I am at least 18 years old and have read and agree to DocScheduler's <a href={PatientLinks.term_of_user}>Terms of Use</a> and <a href={PatientLinks.privacy_policy}>Privacy Policy.</a></p></>
                                    )}

                            </div>
                            {loginType === 'patient' ? (
                            
                            passwordExpired ?
                                <></>
                            :
                            <>
                                <div className="col-md-12 mb-3 provider-steps-wrap text-center">
                                    <SocialLogin
                                        provider='google'
                                        appId='1054905927811-rdht4vdkpg8a10rd4ki1meeu1cok99k6.apps.googleusercontent.com'
                                        callback={handleSocialLogin}
                                    // onLoginSuccess={handleSocialLogin}
                                    // onLoginFailure={handleSocialLoginFailure}
                                    >
                                        <div className="google-box">
                                            <div className="google-icon" alt="Google"></div>
                                            <span>Connect with Google</span>
                                        </div>
                                    </SocialLogin>

                                </div>
                                <div className="col-md-12 mb-3 provider-steps-wrap">
                                    <div className="medical-info body"><span>Or</span></div>
                                </div>
                            </>) : (<></>)}
                            {
                                passwordExpired ?
                                    <>
                                        <div className="col-md-12 mb-3">
                                            <label className="pro-label">Password</label>
                                            <input type="password" name="resetPassword" onChange={(e)=>{setResetPassword(e.target.value);setResetPasswordError('');}} className={resetPasswordError ? "input-pro  error-border" : "input-pro"} placeholder="Enter Password" autoComplete="off"
                                                value={resetPassword}
                                            />
                                            <small className="error-input">{resetPasswordError}</small>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label className="pro-label">Confirm Password</label>
                                            <input type="password" name="resetPasswordAgain" onChange={(e)=>{ setResetPasswordAgain(e.target.value);setResetPasswordAgainError(''); } } className={resetPasswordAgainError ? "input-pro  error-border" : "input-pro"} placeholder="Enter Password Again" autoComplete="off"
                                                value={resetPasswordAgain}
                                            />
                                            <small className="error-input">{resetPasswordAgainError}</small>
                                            {
                                            /* resetPasswordAgainError &&
                                                <div className="pass-rules validation-rules">
                                                    <div className="head-rules">Password Rules:</div>
                                                    <ul>
                                                        <li>Has at least 8 characters</li>
                                                        <li>Must contain letters, at least 1 number, and at least 1 special character</li>
                                                    </ul>
                                                </div>*/
                                            }
                                        </div>
                                        <div className="col-md-12 text-center submit-btn">
                                            <div className="next-prev-btn-pro">
                                                <input type="button" onClick={(e) => { resetPasswordSubmit(e) }} className="next-btn-pro full-width" value="Reset" />
                                            </div>
                                        </div>
                                    </>
                                :
                            <>
                                <div className="col-md-12 mb-3">
                                    <label className="pro-label">Email</label>
                                    <input type="text" name="email" className={errors.eemail ? "input-pro  error-border" : "input-pro"} placeholder="Email" autoComplete="off"
                                        onClick={() => setErrors({ ...errors, ...{ eemail: false, eresponse: false } })}
                                        onFocus={() => setErrors({ ...errors, ...{ eemail: false, eresponse: false } })}
                                        onChange={(e) => setLoginData({
                                            ...loginData,
                                            ...{
                                                email: {
                                                    value: e.currentTarget.value.trim(),
                                                    validation: ['blank', 'email'],
                                                    errorMsg: emailErrorMsg,
                                                    errorKey: 'eemail',
                                                }
                                            }
                                        })
                                        }
                                        value={loginData.email.value}
                                    />
                                    <small className="error-input">{errors.eemail}</small>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className="pro-label">Password</label>
                                    <input type="password" name="password" className={errors.epassword ? "input-pro  error-border" : "input-pro"} placeholder="Password" autoComplete="off"
                                        onClick={() => setErrors({ ...errors, ...{ epassword: false, eresponse: false } })}
                                        onFocus={() => setErrors({ ...errors, ...{ epassword: false, eresponse: false } })}
                                        onKeyPress={(e) => dosubmit(e)}
                                        onChange={(e) => setLoginData({
                                            ...loginData,
                                            ...{
                                                password: {
                                                    value: e.currentTarget.value.trim(),
                                                    validation: ['blank', 'password'],
                                                    errorMsg: passwordErrorMsg,
                                                    errorKey: 'epassword',
                                                }
                                            }
                                        })
                                        }
                                        value={loginData.password.value}
                                    />
                                    <small className="error-input">{errors.eresponse}</small>
                                    <small className="error-input">{errors.epassword}</small>
                                    {
                                        errors.epassword &&
                                        <div className="pass-rules validation-rules">
                                            <div className="head-rules">Password Rules:</div>
                                            <ul>
                                                <li>Has at least 8 characters</li>
                                                <li>Must contain letters, at least 1 number, and at least 1 special character</li>
                                            </ul>
                                        </div>
                                    }
                                </div>

                                <div className="col-md-6 mb-3 keep-log">
                                    <div className="cus-check">
                                        <input type="checkbox" id="loggedIn" name="loggedIn" onChange={(e) => { keepLogin(e) }} />
                                        <label htmlFor="loggedIn">Keep me logged in</label>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3 forgot-login">
                                    <div className="text-md-right"><Link onClick={authContext.hideModal} to={ProviderLinks.forgot_pass}>Forgot your password?</Link></div>
                                </div>

                                <div className="col-md-12 text-center submit-btn">
                                    <div className="next-prev-btn-pro">
                                        <input type="button" onClick={(e) => { formSubmit(e) }} className="next-btn-pro full-width" value="Sign In" />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3 text-center link-text">
                                    {loginType === 'provider' ? (<>
                                        <div className="bday-use">New to DocScheduler? <strong><Link onClick={authContext.hideModal} to={ProviderLinks.freeprofile}>List your Practice on DocScheduler</Link></strong></div>
                                    </>) : (<>
                                        <div className="bday-use">New to DocScheduler? <strong><Link onClick={authContext.hideModal} to={PatientLinks.register_setp1}>Create Account</Link></strong></div>
                                    </>)}
                                </div>
                                <div className="col-md-12 mb-3 text-center link-text">
                                    {loginType === 'provider' ? (
                                        <>
                                            <div className="bday-use">Looking to sign in as a patient? <strong><a href="/#" onClick={(e) => openPatientLogin(e)} >Sign in here</a></strong></div>
                                        </>) : (
                                            <>
                                                <div className="bday-use">Are you a Doctor or Dentist? <strong><Link onClick={authContext.hideModal} to={ProviderLinks.login}>Sign in here</Link></strong></div>
                                            </>
                                        )}
                                </div>
                            </>
                            }
                        </Row>
                    </Container>
                </> : <>
                        {
                            Validatepages()
                        }

                    </>}

            </Fragment>
        </Suspense>
    );
}

export default ProviderLogin;