import React, { Fragment, useContext, useState, useEffect } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import Custommodal from '../../../global/modal/modal';
import { ProviderLinks, PatientLinks, AdminLinks } from '../../../linkFile';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import DetailModal from './detail-modal';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Pagination from "../../../components/admin/pagination/pagination";
import Loadermodal from "../../../global/loader-modal/loader-modal";
import * as moment from 'moment';
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';

const SubAdminTable = (props) => {
    const authContext = useContext(AuthContext);
    const { refresh, editRecord } = props;
    const [totalCount, settotalCount] = useState(0);
    const [filter, setfilter] = useState({
        offset: 0,
        limit: 10
    });
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    const [data, setData] = useState([]);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [viewdetails, setViewdetails] = useState([]);
    const [search, setSearch] = useState("");
    const [sort, setsort] = useState('asc');
    const [sortName, setSortName] = useState('email');
    const [showPopupResponse, setShowPopupResponse] = useState(false);

    // FOR POPUP MODAL
    const modalList = ['subAdminDetails'];
    const openProfileTabModal = (modalName, value) => {
        setViewdetails(value);
        settabvisibleComponent(modalName)
        authContext.showModal()
    }


    const getList = async (value) => {
        console.log(value);
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (value) {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                searchValue: value,
                sortOrder: sort,
                sortBy: sortName
            }
        } else {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                sortOrder: sort,
                sortBy: sortName
            }
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getAllAdminDetails',
            body: JSON.stringify(formData)
        }

        // SEND REQUEST 
        try {
            let result = await NetworkLayer.postData(submitData);
            
            // let result = await NetworkLayer.getRequest(submitData);
            console.log(result);
            let resultData = result.body.result.result
            let results = []
            resultData.map((res)=>{
                let permissionlist = res.userPermissions
                console.log('permissionlist',permissionlist)
                let listIndexRating = permissionlist.findIndex(x=>x.name === 'RatingAndComments')
                let listIndexWebsite = permissionlist.findIndex(x=>x.name === "WebsiteContents")

                if(listIndexRating !== -1){
                    permissionlist[listIndexRating]['name'] = 'Ratings And Comments'
                }
                if(listIndexWebsite !== -1){
                    permissionlist[listIndexWebsite]['name'] = 'Website Contents'
                }
                res['userPermissions'] = permissionlist
                results.push(res)
                return null
            })

            setData(results);
            settotalCount(result.body ?.result ?.recordPerPage);
        } catch (err) {
            console.log("ERROR :", err)
        }

        authContext.unsetLoader();

    }

    const getDetails = async (id) => {
        authContext.setLoader();

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getAdmin/' + id,
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        setViewdetails(result ?.result);
        authContext.unsetLoader();
    }

    const updateStatus = async (id, status) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id,
            // status: status
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/updateAdminStatus',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        try {
            let result = await NetworkLayer.postData(submitData);
            getList();
        } catch (err) {

        }

        authContext.unsetLoader();

    }

    const successMsg = (type, message) => {
        setShowPopupResponse(true)
        setmodalProps({
            type: type,
            msg: message
        });
        authContext.showResponseModal();
    }

    const deleteProvider = async (id) => {
        

        // FORM DATA 
        const formData = {
            id: id
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/deleteAdmin',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        if (window.confirm('Are you sure you want to delete this sub-admin?')) {
            authContext.setLoader();
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if (result.body.status) {
                successMsg("success", result.body.message);
            } else {
                successMsg("error", result.body.message);
            }
           setTimeout(() => {
            getList();
           }, 3000);

        }

        
    }

    const editRecordFunc = (value) => {
        authContext.setLoader();
        editRecord(value);
        authContext.unsetLoader();
    }


    useEffect(() => {
        getList();
    }, [filter.offset, refresh, sort])

    // PAGINATION CALLBACK
    const changeLimit = (page) => {
        setfilter({ ...filter, ...{ offset: parseInt(page) } });
    };


    const toggleStatus = (values) => {
        console.log(values);
        let status = values.status == 1 ? 0 : 1;
        let id = values.id;
        updateStatus(id, status);
    }

    const changeSorting = (e) => {
        let name = e.currentTarget.getAttribute('name');
        setSortName(name);
        setsort(sort == "asc" ? "desc" : "asc")
    };

    const searchFilter = (value) => {
        console.log(value);
        getList(value);
    }
    return (
        <Fragment>
            {
                showPopupResponse && authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            <Loadermodal />
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'subAdminDetails' ? <DetailModal data={viewdetails} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <div className="table-report-scroll mt-0">
                <table className="table" style={{ border: '1px solid #f5f6f9' }}>
                    <thead>
                        <tr>
                        
                            <th>
                                <span className="sort-outer">Name
                                <span className="data-sort-icon" name="Name" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>
                                <span className="sort-outer">Date Created 
                                <span className="data-sort-icon" name="createdAt" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Permissions</th>
                            <th>
                                <span className="sort-outer">Active
                                <span className="data-sort-icon" name="status" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data ?.length == 0 &&
                                <tr>
                                    <td>No Records Found</td>
                                </tr>
                        }
                        {
                            data && data.map((value, index) =>
                                <tr key={index}>
                                    <td>{`${value.firstName} ${value.lastName}`}</td>
                                    <td>{moment(value ?.createdAt).format('MM/DD/YYYY')}</td>
                                    <td>
                                        {
                                            value ?.userPermissions.map((val, ind) =>
                                                <>
                                                    {ind < 3 ?
                                                        <>{val.name} {ind != 2 ? ', ' : '...'}</> : ''
                                                    }

                                                </>)
                                    }
                                    </td>
                                    <td>
                                        <label class="active-toggle">
                                            <input type="checkbox" checked={value.status == 1 ? 'checked' : ''} onClick={() => toggleStatus(value)} />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <Link onClick={(e) => { e.preventDefault(); openProfileTabModal("subAdminDetails", value) }} className="table-action view-all">Detail</Link>
                                        <Link onClick={() => { editRecordFunc(value) }} className="table-action view-all">Edit</Link>
                                        <Link className="table-action view-all" onClick={() => { deleteProvider(value.id) }}>Delete</Link>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>

        </Fragment>
    );
}

export default SubAdminTable;