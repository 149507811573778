import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {  Modal } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';


const Custommodal = (props) => {

 console.log('modaltabvisibleComponent props', props);
  const authContext = useContext(AuthContext);
  const handleClose = () => {
    if( props.unsetComponent && typeof(props.unsetComponent) == 'function') {
      props.unsetComponent('');
    }
    authContext.showLoginModal(false);
    authContext.hideModal()
  };
  const handleShow = () => authContext.showModal();

  const ComponentName = props.componentName ? props.componentName : <></>;  

  return (
    <Fragment>

        <Modal show={authContext.modal} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
             {ComponentName}
            
           {/* <button onClick={authContext.setLoader}>Check loader</button> */}
        </Modal>
    </Fragment>
    );
  }
  
  export default Custommodal;