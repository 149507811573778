import React, { memo, useEffect, useState, useCallback, useRef, useLayoutEffect, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Spinner } from "react-bootstrap";
import NetworkLayer from "../../reactLayer/Network/Network";
import * as moment from 'moment';
import Global from "../../reactLayer/global";

const SchedulingTime = (props) => {
  const { isLoading, locationId, startDate, item } = props;
  const [isLoadingState, setIsLoadingState] = useState(isLoading);
  const [slots, setslots] = useState([]);
  const [showallslots, setshowallslots] = useState('');
  const [showMore, setshowMore] = useState(false);
  const [maxSlots,setMaxSlots] = useState(0)

  const dateWrapper = useRef(null);
  const bookSlot = (sl, index) => {
    if (!sl.isDisabled && !sl.isBooked) {
      let selectedDate, slotInfo = {};
      selectedDate = Global.getYearMonthDate(Global.addDays(startDate, index));
      slotInfo = sl;
      slotInfo.locationId = locationId;
      slotInfo.date = selectedDate.year + '-' + selectedDate.month + '-' + selectedDate.date;
      props.bookAppointment && props.bookAppointment(slotInfo, item);
    }
  }

  const sm = useCallback(async (smv = false) => {
    console.log('tstign of call back in time');
    setshowMore(smv)
  }, [])

  useEffect(() => {
    setIsLoadingState(true);
    console.log('casjkaskcjkasjkcsjkcs')
    const doctorSchedulingTime = async (locationId, startDate) => {
      let data = { startFrom: moment(startDate).format("YYYY-MM-DD"), locationId: locationId };
      let url = "appointment/schedule";
      const submitData = {
        url: url,
        body: JSON.stringify(data),
      };
      let result = await NetworkLayer.postData(submitData);
      // console.log('on schedule page results', result);
      if (result.body.status) {
        let totalSlots = 0
        if(result.body.result.slots){
        result.body.result.slots.map((slot)=>{
          if(slot.slots.length > 0 && totalSlots < slot.slots.length){
            totalSlots = slot.slots.length
          }
          return null
        })
        }
        setMaxSlots(totalSlots)
        setslots(result.body.result.slots);
        setIsLoadingState(false);
      } else {
        setslots([]);
        setIsLoadingState(false);
      }
    };
    locationId && startDate && doctorSchedulingTime(locationId, startDate);
  }, [locationId, startDate]);

  useMemo(() => {

    if (dateWrapper && dateWrapper.current && dateWrapper.current.clientHeight && dateWrapper.current.scrollHeight && dateWrapper.current.clientHeight >= dateWrapper.current.scrollHeight) {
      console.log('dateWrapper.current.clientHeight ==>', dateWrapper.current.clientHeight, 'dateWrapper.current.scrollHeight ==>', dateWrapper.current.scrollHeight);
      // setshowallslots('show-height1');
    }

    return () => {
      setshowallslots('');
    }
  }, [props])
  return (
    <div key={locationId + '-Wrapper'} className="col-12 px-0 scheduling-component-main">
      {isLoadingState ? (
        <div className="calenderLoading">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
          <div key={locationId + 'lid'} className={showallslots + ' date-area date-area-patient'} ref={dateWrapper}>
            {slots && slots.length && slots.map((slot, m_index) => (
              <div className="date-column" key={m_index}>
                {slot.slots.length ? (<>
                  {

                    slot.slots.map((sl, sl_index) =>
                      <div className={sl.isDisabled || sl.isBooked ? 'date-list disabled' : sl.isBooked ? 'date-list booked' : 'date-list'}
                        onClick={() => bookSlot(sl, m_index)}
                      >
                        {!sl_index && !showMore && sm(true)}
                        {sl.startTime}
                      </div>
                    )
                  }
                </>) : (
                <>
                {[...Array(maxSlots)].map((x, i) =>
                  <div key={i} className="date-list disabled"><strong>---</strong></div>
                )}
                </>)
                }
              </div>
            ))}
          </div>
        )}
      {
        slots && slots.length ? (<>
          <div className={showallslots === '' ? 'date-area-more' : 'date-area-more date-area-less'} >
            {slots && slots.length && slots.map((slot, m_index) =>
              <div className="date-column" onClick={(e) => slot.slots.length > 2 ? setshowallslots(showallslots === '' ? 'show-height1' : '') : e.preventDefault()}>
                <div className={slot.slots.length > 2 ? 'down-arrow-time' : 'down-arrow-time1'}>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </>) : (<></>)
      }


    </div>
  );
};

SchedulingTime.defaultProps = {
  isLoading: false,
  locationId: "",
  startDate: "",
};

export default memo(SchedulingTime);
