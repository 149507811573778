import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg';
import { useHistory } from "react-router-dom";
import { ProviderLinks, PatientLinks, AdminLinks } from '../../../linkFile';
import Global from '../../../reactLayer/global';
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import InputValidation from '../../../reactLayer/validation';
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';
import * as moment from 'moment';

const InsuranceInfo = (props) => {

    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [insuranceList, setinsuranceList] = useState([]);
    const monthList = Global.getCurrentYear();
    const yearList = Global.getYearListChild();
    const getInsurance = async () => {
        let insResponse = await Global.getInsurance();
        setinsuranceList(insResponse);

    }

    // console.log('insuranceList', insuranceList);
    const [patientInsCategory, setpatientInsCategory] = useState({
        medical: [],
        dental: [],
        vision: [],
        child: []
    })


    const getFields = (e, category = 'medical') => {
        e && e.preventDefault();
        let fields = {
            insuranceId: {
                value: '',
                validation: [],
                errorMsg: [],
                errorKey: 'insuranceId',
                insuranceId: false
            },
            membershipId: {
                value: '',
                validation: ['alphanumeric'],
                errorMsg: ['Invalid Membership Id'],
                errorKey: 'membershipId',
                membershipId: false
            },
            planName: {
                value: '',
                validation: [],
                errorMsg: [],
                errorKey: 'planName',
                planName: false
            },
            group: {
                value: '',
                validation: [],
                errorMsg: [],
                errorKey: 'group',
                group: false
            }
        }
        if (category === 'child') {
            let childFields = {
                fname: {
                    value: '',
                    validation: ['blank', 'name'],
                    errorMsg: ['This field is required', 'Invalid First Name'],
                    errorKey: 'fname',
                    fname: false
                },
                mname: {
                    value: '',
                    validation: [],
                    errorMsg: ['This field is required', 'Invalid Middle Name'],
                    errorKey: 'mname',
                    mname: false
                },
                lname: {
                    value: '',
                    validation: ['blank', 'name'],
                    errorMsg: ['This field is required', 'Invalid Last Name'],
                    errorKey: 'lname',
                    lname: false
                },
                day: {
                    value: '',
                    validation: ['blank', 'number'],
                    errorMsg: ['This field is required'],
                    errorKey: 'day',
                    dob: false
                },
                month: {
                    value: '',
                    validation: ['blank', 'number'],
                    errorMsg: ['This field is required'],
                    errorKey: 'month',
                    dob: false
                },
                year: {
                    value: '',
                    validation: ['blank', 'number'],
                    errorMsg: ['This field is required'],
                    errorKey: 'year',
                    dob: false
                }
            }
            fields = { ...fields, ...childFields };
        }
        let temp = patientInsCategory;
        temp[category].push(fields);
        setpatientInsCategory({ ...temp });

    }
    const DeleteElement = (elementName, index) => {

        let tempData = {};
        tempData = patientInsCategory;
        tempData[elementName][index] && tempData[elementName].splice(index, 1);
        setpatientInsCategory({ ...tempData });

    }
    const DeleteSection = (props) => {
        return (
            <>
                <div onClick={() => DeleteElement(props.name, props.index)} className="delete-icon" alt="Delete" ></div>
            </>
        )
    }
    const modifyData = (e, varName) => {

        let index, name, value,
            tempData = [];
        index = e.currentTarget.getAttribute('data-index');
        name = e.currentTarget.getAttribute('data-name');

        value = e.currentTarget.value;
        tempData = patientInsCategory;
        tempData[varName][index][name]['value'] = value;
        tempData[varName][index][name][name] = '';

        setpatientInsCategory({ ...tempData });

    }
    const formSubmit = async () => {

        let tempData = patientInsCategory;
        let cdate = new Date();
        let todayDate = cdate.getFullYear() + '-' + (parseInt(cdate.getMonth()) + 1).toString().padStart(2, "0") + '-' + cdate.getDate().toString().padStart(2, "0") + ' 00:00';
        let currentDate = new Date(todayDate).getTime();
        let userDob = '';
        let formDataIns = {
            medical: [],
            dental: [],
            vision: [],
            child: []
        }
        let flag = true;
        let setFocus = false;
        for (const data in tempData) {

            tempData[data].forEach((currentValue, index) => {

                let validationResult = InputValidation(currentValue);
                let checkDate = ''
                let tempFormData = {}
                if (data == 'child') {
                    console.log('child dob', currentValue);
                    tempFormData.firstName = currentValue.fname.value;
                    tempFormData.middleName = currentValue.mname.value;
                    tempFormData.lastName = currentValue.lname.value;
                    tempFormData.dob = currentValue.month.value.toString().padStart(2, "0") + '-' + currentValue.day.value.toString().padStart(2, "0") + '-' + currentValue.year.value;
                    userDob = currentValue.year.value + '-' + currentValue.month.value.toString().padStart(2, "0") + '-' + currentValue.day.value.toString().padStart(2, "0") + ' 00:00';
                    userDob = new Date(userDob).getTime();
                }
                tempFormData.insuranceId = currentValue.insuranceId.value;
                tempFormData.membershipId = currentValue.membershipId.value;
                tempFormData.planName = currentValue.planName.value;
                tempFormData.group = currentValue.group.value;
                formDataIns[data].push(tempFormData);
                if (Object.keys(validationResult).length > 0) {
                    flag = false;
                    for (const prop in validationResult) {
                        tempData[data][index][prop][prop] = `${validationResult[prop]}`;
                        if (!flag && !setFocus) {
                            setFocus = true;
                            // let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({block: "start", behavior: "smooth"});
                        }
                    }
                } else {
                    if (data == 'child') {
                        console.log('check ', userDob);
                        console.log('check2 ', currentDate);
                        if (userDob > currentDate) {
                            flag = false;
                            tempData[data][index]['year']['year'] = `Invalid Date of Birth`;
                        }

                    }
                }
                console.log('tempData',tempData)

                //setpatientInsCategory({ ...tempData });
            })
        };


        if (flag) {
            authContext.setLoader();

            console.log(formDataIns, JSON.stringify(formDataIns));
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.adminToken
                },
                url: 'admin/patient/addInsuranceInformation',
                body: JSON.stringify({ patient_insurances: formDataIns, userId: props.data ?.patientInfo ?.accountInfo ?.id }),

            }
            console.log('submitData', submitData);
            try {
                let result = await NetworkLayer.postData(submitData);

                if (result.body.status) {
                    setmodalProps({
                        type: 'success',
                        msg: result.body.message
                    });
                    props.callBack && props.callBack();
                    authContext.showResponseModal();
                } else {
                    setmodalProps({
                        type: 'error',
                        msg: result.body.message,
                    });
                    authContext.showResponseModal();
                }
            } catch (err) {
                setmodalProps({
                    type: 'error',
                    msg: 'Something Went Wrong',
                });
                authContext.showResponseModal();
            }

            authContext.unsetLoader();

        } else {

            setTimeout(() => {
                let errorFilds = document.querySelectorAll('.error-border');
                if (errorFilds.length) {
                    errorFilds[0].scrollIntoView({ block: "start", behavior: "smooth" });
                }
            }, 100)

        }
    }
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });

    const setFields = (category = 'medical', data) => {
        let fields = {
            insuranceId: {
                value: data.insuranceId,
                validation: [],
                errorMsg: [],
                errorKey: 'insuranceId',
                insuranceId: false
            },
            membershipId: {
                value: data.membershipId,
                validation: ['alphanumeric'],
                errorMsg: ['Invalid Membership Id'],
                errorKey: 'membershipId',
                membershipId: false
            },
            planName: {
                value: data.planName,
                validation: [],
                errorMsg: [],
                errorKey: 'planName',
                planName: false
            },
            group: {
                value: data.group,
                validation: [],
                errorMsg: [],
                errorKey: 'group',
                group: false
            }
        }

        if (category === 'child') {

            let dob = moment(data.dob).format('d/M/Y').split('/');
            console.log("DOB :::: ", dob)

            let childFields = {
                fname: {
                    value: data.firstName,
                    validation: ['blank', 'name'],
                    errorMsg: ['This field is required', 'Invalid First Name'],
                    errorKey: 'fname',
                    fname: false
                },
                mname: {
                    value: data.middleName,
                    validation: [],
                    errorMsg: ['This field is required', 'Invalid Middle Name'],
                    errorKey: 'mname',
                    mname: false
                },
                lname: {
                    value: data.lastName,
                    validation: ['blank', 'name'],
                    errorMsg: ['This field is required', 'Invalid Last Name'],
                    errorKey: 'lname',
                    lname: false
                },
                day: {
                    value: dob[0],
                    validation: ['blank', 'number'],
                    errorMsg: ['This field is required'],
                    errorKey: 'day',
                    dob: false
                },
                month: {
                    value: dob[1],
                    validation: ['blank', 'number'],
                    errorMsg: ['This field is required'],
                    errorKey: 'month',
                    dob: false
                },
                year: {
                    value: dob[2],
                    validation: ['blank', 'number'],
                    errorMsg: ['This field is required'],
                    errorKey: 'year',
                    dob: false
                }
            }
            fields = { ...fields, ...childFields };
        }

        console.log("patientInsCategory===>", patientInsCategory)
        let temp = patientInsCategory;
        temp[category].push(fields);
        setpatientInsCategory({ ...temp });
    }
    useEffect(() => {
        getInsurance();

        if (props.data ?.patientInfo ?.patientInsurances) {
            if (props.data ?.patientInfo ?.patientInsurances ?.medical ?.length > 0) {
                setFields('medical', props.data ?.patientInfo ?.patientInsurances ?.medical[0]);
            } else {
                getFields();
            }
            if (props.data ?.patientInfo ?.patientInsurances ?.dental ?.length > 0) {
                setFields('dental', props.data ?.patientInfo ?.patientInsurances ?.dental[0]);
            }
            if (props.data ?.patientInfo ?.patientInsurances ?.vision ?.length > 0) {
                setFields('vision', props.data ?.patientInfo ?.patientInsurances ?.vision[0]);
            }
            if (props.data ?.patientInfo ?.patientInsurances ?.child ?.length > 0) {
                setFields('child', props.data ?.patientInfo ?.patientInsurances ?.child[0]);
            }
        } else {
            getFields();
        }

    }, [])


    return (
        <Fragment>
            {
                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            <Row className="table-sort-filter">
                <div className="col-12 col-lg-9">
                    <form className="provider-form-edit">

                        {
                            patientInsCategory.medical.length ? (<>
                                {patientInsCategory.medical.map((data, index) =>
                                    <Row key={'medical-' + index}>
                                        <div className="col-md-6 no-padding-for-small mb-3">
                                            <label className="pro-label">Medical Insurance Name</label>
                                            <div className="myform">
                                                <select required className={data.insuranceId.insuranceId ? "select  error-border" : "select"} value={data.insuranceId.value} data-index={index} data-name="insuranceId" onChange={(e) => modifyData(e, 'medical')}>
                                                    <option value="">Choose</option>
                                                    {insuranceList.map((mc, mci) =>
                                                        <>
                                                            {
                                                                mc.category == "medical" ? (<>
                                                                    <option key={'medical-' + mc.id + '-' + index} value={mc.id}>{mc.name}</option>
                                                                </>) : (<></>)
                                                            }
                                                        </>
                                                    )}
                                                </select>
                                                <small className="error-input">{data.insuranceId.insuranceId}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6 no-padding-for-small mb-3">
                                            <label className="pro-label">Plan Name</label>
                                            <input type="text" name="pName" data-name={'planName'} data-index={index} placeholder="Plan Name" autoComplete="off"
                                                className={data.planName.planName ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'medical')}
                                                value={data.planName.value}

                                            />
                                            <small className="error-input">{data.planName.planName}</small>
                                        </div>

                                        <div className="col-md-6 no-padding-for-small mb-3 mb-md-0">
                                            <label className="pro-label">Group (GRP) #</label>
                                            <input type="text" name="mId" data-name={'group'} data-index={index} placeholder="Group#" autoComplete="off"
                                                className={data.group.group ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'medical')}
                                                value={data.group.value}
                                            />
                                            <small className="error-input">{data.group.group}</small>

                                        </div>
                                        <div className="col-md-6 no-padding-for-small">
                                            <label className="pro-label">Membership ID#</label>
                                            <input type="text" name="grp" data-name={'membershipId'} data-index={index} placeholder="Membership ID#" autoComplete="off"
                                                className={data.membershipId.membershipId ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'medical')}
                                                value={data.membershipId.value}
                                            />
                                            <small className="error-input">{data.membershipId.membershipId}</small>
                                        </div>
                                    </Row>
                                )}
                            </>
                            ) : (
                                    <><span className="col-12 no-padding-for-small"><a href="/#" onClick={(e) => getFields(e, 'medical')} className="under-line">+ Add Medical Insurance</a></span></>
                                )
                        }
                        {
                            patientInsCategory.dental.length ? (<>
                                {patientInsCategory.dental.map((data, index) =>

                                    <Row className='position-relative' key={'dental-' + index} style={{ marginTop: '30px' }}>
                                        <>
                                            <div className="col-md-10 mb-3"><a href="/#" onClick={(e) => e.preventDefault()} className="">Dental Insurance</a></div>
                                            <div className="col-md-2 mb-3">
                                                {
                                                    index >= 0 ? <div><DeleteSection name='dental' index={index} /></div> : <></>
                                                }
                                            </div>
                                        </>

                                        <div className="col-md-6 no-padding-for-small mb-3">
                                            <label className="pro-label">Dental Insurance Name</label>
                                            <div className="myform">
                                                <select required className={data.insuranceId.insuranceId ? "select  error-border" : "select"} value={data.insuranceId.value} data-index={index} data-name="insuranceId" onChange={(e) => modifyData(e, 'dental')}>
                                                    <option value="">Choose</option>
                                                    {insuranceList.map((mc, mci) =>
                                                        <>
                                                            {
                                                                mc.category == "dental" ? (<>
                                                                    <option key={'dental-' + mc.id + '-' + index} value={mc.id}>{mc.name}</option>
                                                                </>) : (<></>)
                                                            }
                                                        </>

                                                    )}
                                                </select>
                                                <small className="error-input">{data.insuranceId.insuranceId}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6 no-padding-for-small mb-3">
                                            <label className="pro-label">Plan Name</label>
                                            <input type="text" name="pName" data-name={'planName'} data-index={index} placeholder="Plan Name" autoComplete="off"
                                                className={data.planName.planName ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'dental')}
                                                value={data.planName.value}

                                            />
                                            <small className="error-input">{data.planName.planName}</small>
                                        </div>

                                        <div className="col-md-6 no-padding-for-small mb-3 mb-md-0">
                                            <label className="pro-label">Group (GRP) #</label>
                                            <input type="text" name="mId" data-name={'group'} data-index={index} placeholder="Group#" autoComplete="off"
                                                className={data.group.group ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'dental')}
                                                value={data.group.value}
                                            />
                                            <small className="error-input">{data.group.group}</small>

                                        </div>
                                        <div className="col-md-6 no-padding-for-small">
                                            <label className="pro-label">Membership ID#</label>
                                            <input type="text" name="grp" data-name={'membershipId'} data-index={index} placeholder="Membership ID#" autoComplete="off"
                                                className={data.membershipId.membershipId ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'dental')}
                                                value={data.membershipId.value}
                                            />
                                            <small className="error-input">{data.membershipId.membershipId}</small>
                                        </div>
                                    </Row>
                                )}
                            </>
                            ) : (
                                    <div className="col-12 mb-2" style={{ padding: '0px', marginTop: '30px' }}>
                                        <Link className="add-link" onClick={(e) => getFields(e, 'dental')}>+ Add Dental Insurance</Link>
                                    </div>
                                )
                        }

                        {
                            patientInsCategory.vision.length ? (<>
                                {patientInsCategory.vision.map((data, index) =>
                                    <Row className='position-relative' key={'vision-' + index} style={{ marginTop: '30px' }}>
                                        <>
                                            <div className="col-md-10 mb-3"><a href="/#" onClick={(e) => e.preventDefault()} className="">Vision Insurance</a></div>
                                            <div className="col-md-2 mb-3">
                                                {
                                                    index >= 0 ? <div><DeleteSection name='vision' index={index} /></div> : <></>
                                                }
                                            </div>
                                        </>

                                        <div className="col-md-6 no-padding-for-small mb-3">
                                            <label className="pro-label">Vision Insurance Name</label>
                                            <div className="myform">
                                                <select required className={data.insuranceId.insuranceId ? "select  error-border" : "select"} value={data.insuranceId.value} data-index={index} data-name="insuranceId" onChange={(e) => modifyData(e, 'vision')}>
                                                    <option value="">Choose</option>
                                                    {insuranceList.map((mc, mci) =>
                                                        <option key={'vision-' + mc.id + '-' + index} value={mc.id}>{mc.name}</option>
                                                    )}
                                                </select>
                                                <small className="error-input">{data.insuranceId.insuranceId}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6 no-padding-for-small mb-3">
                                            <label className="pro-label">Plan Name</label>
                                            <input type="text" name="pName" data-name={'planName'} data-index={index} placeholder="Plan Name" autoComplete="off"
                                                className={data.planName.planName ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'vision')}
                                                value={data.planName.value}

                                            />
                                            <small className="error-input">{data.planName.planName}</small>
                                        </div>

                                        <div className="col-md-6 no-padding-for-small mb-3 mb-md-0">
                                            <label className="pro-label">Group (GRP) #</label>
                                            <input type="text" name="mId" data-name={'group'} data-index={index} placeholder="Group#" autoComplete="off"
                                                className={data.group.group ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'vision')}
                                                value={data.group.value}
                                            />
                                            <small className="error-input">{data.group.group}</small>

                                        </div>
                                        <div className="col-md-6 no-padding-for-small">
                                            <label className="pro-label">Membership ID#</label>
                                            <input type="text" name="grp" data-name={'membershipId'} data-index={index} placeholder="Membership ID#" autoComplete="off"
                                                className={data.membershipId.membershipId ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'vision')}
                                                value={data.membershipId.value}
                                            />
                                            <small className="error-input">{data.membershipId.membershipId}</small>
                                        </div>
                                    </Row>
                                )}
                            </>
                            ) : (
                                    <div className="col-12 mb-2" style={{ padding: '0px' }}>
                                        <Link className="add-link" onClick={(e) => getFields(e, 'vision')}>+ Add Vision Insurance</Link>
                                    </div>
                                )
                        }
                        {
                            patientInsCategory.child.length ? (<>
                                {patientInsCategory.child.map((data, index) =>
                                    <Row className='position-relative' key={'child-' + index} style={{ marginTop: '30px' }}>
                                        <>
                                            <div className="col-md-10 mb-3"><a href="/#" onClick={(e) => e.preventDefault()} className="">Child and Child's Insurance</a></div>
                                            <div className="col-md-2 mb-3">
                                                {
                                                    index >= 0 ? <div><DeleteSection name='child' index={index} /></div> : <></>
                                                }
                                            </div>
                                        </>
                                        <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                                            <label className="pro-label">First Name</label>
                                            <input type="text" name="fname" data-name={'fname'} data-index={index} placeholder="First Name" autoComplete="off"
                                                className={data.fname.fname ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'child')}
                                                value={data.fname.value}

                                            />
                                            <small className="error-input">{data.fname.fname}</small>
                                        </div>
                                        <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                                            <label className="pro-label">Middle Name</label>
                                            <input type="text" name="mname" data-name={'mname'} data-index={index} placeholder="Middle Name" autoComplete="off"
                                                className={data.mname.mname ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'child')}
                                                value={data.mname.value}

                                            />
                                            <small className="error-input">{data.mname.mname}</small>
                                        </div>
                                        <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                                            <label className="pro-label">Last Name</label>
                                            <input type="text" name="lname" data-name={'lname'} data-index={index} placeholder="Last Name" autoComplete="off"
                                                className={data.lname.lname ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'child')}
                                                value={data.lname.value}

                                            />
                                            <small className="error-input">{data.lname.lname}</small>
                                        </div>

                                        <div className="col-12">
                                            <label className="pro-label">Date of Birth</label>
                                        </div>
                                        <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                                            <div className="myform">
                                                <select required className={data.month.month ? "select  error-border" : "select"}
                                                    data-index={index} data-name="month"
                                                    onChange={(e) => modifyData(e, 'child')}
                                                    value={data.month.value}
                                                >
                                                    <option value="">Month</option>
                                                    {monthList.map((month, index_index) =>
                                                        <option key={month.id + '-month'} value={month.id}>{month.name}</option>
                                                    )}

                                                </select>
                                            </div>
                                            <small className="error-input">{data.month.month}</small>
                                        </div>
                                        <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                                            <div className="myform">
                                                <select required className={data.day.day ? "select  error-border" : "select"}
                                                    data-index={index} data-name="day"
                                                    onChange={(e) => modifyData(e, 'child')}
                                                    value={data.day.value}
                                                >
                                                    <option value="">Day</option>
                                                    {[...new Array(31)].map((day, day_index) =>
                                                        <option key={1 + day_index + '-days'} value={day_index + 1}>{day_index + 1}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <small className="error-input">{data.day.day}</small>
                                        </div>
                                        <div className="col-sm-4 no-padding-for-small">
                                            <div className="myform">
                                                <select required className={data.year.year ? "select  error-border" : "select"}
                                                    data-index={index} data-name="year"
                                                    onChange={(e) => modifyData(e, 'child')}
                                                    value={data.year.value}
                                                >
                                                    <option value="">Year</option>
                                                    {yearList.map((year) =>
                                                        <option key={year + '-year'} value={year}>{year}</option>
                                                    )}

                                                </select>
                                            </div>
                                            <small className="error-input">{data.year.year}</small>
                                        </div>
                                        <div className="col-md-6 no-padding-for-small mb-3">
                                            <label className="pro-label">Medical Insurance Name</label>
                                            <div className="myform">
                                                <select required className={data.insuranceId.insuranceId ? "select  error-border" : "select"} value={data.insuranceId.value} data-index={index} data-name="insuranceId" onChange={(e) => modifyData(e, 'child')}>
                                                    <option value="">Choose</option>
                                                    {insuranceList.map((mc, mci) =>
                                                        <option key={'child-' + mc.id + '-' + index} value={mc.id}>{mc.name}</option>
                                                    )}
                                                </select>
                                                <small className="error-input">{data.insuranceId.insuranceId}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6 no-padding-for-small mb-3">
                                            <label className="pro-label">Plan Name</label>
                                            <input type="text" name="pName" data-name={'planName'} data-index={index} placeholder="Plan Name" autoComplete="off"
                                                className={data.planName.planName ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'child')}
                                                value={data.planName.value}

                                            />
                                            <small className="error-input">{data.planName.planName}</small>
                                        </div>

                                        <div className="col-md-6 no-padding-for-small mb-3 mb-md-0">
                                            <label className="pro-label">Group (GRP) #</label>
                                            <input type="text" name="mId" data-name={'group'} data-index={index} placeholder="Group#" autoComplete="off"
                                                className={data.group.group ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'child')}
                                                value={data.group.value}
                                            />
                                            <small className="error-input">{data.group.group}</small>

                                        </div>
                                        <div className="col-md-6 no-padding-for-small">
                                            <label className="pro-label">Membership ID#</label>
                                            <input type="text" name="grp" data-name={'membershipId'} data-index={index} placeholder="Membership ID#" autoComplete="off"
                                                className={data.membershipId.membershipId ? "input-pro  error-border" : "input-pro"}
                                                onChange={(e) => modifyData(e, 'child')}
                                                value={data.membershipId.value}
                                            />
                                            <small className="error-input">{data.membershipId.membershipId}</small>
                                        </div>
                                    </Row>
                                )}
                            </>
                            ) : (
                                    <div className="col-12 mb-2" style={{ padding: '0px' }}>
                                        <Link className="add-link" onClick={(e) => getFields(e, 'child')}>+ Add Child and Child's Insurance</Link>
                                    </div>
                                )
                        }



                        <div className="bottom-btns mt-4 mb-3">
                            <div className="next-prev-btn-pro">
                                <input type="button" className="prev-btn-pro" value="Cancel" onClick={() => (history.push({ pathname: AdminLinks.admin_patient_management }))} />
                                <input type="button" className="next-btn-pro" value="Save" onClick={() => formSubmit()} />
                            </div>
                        </div>
                    </form>
                </div>
            </Row>
        </Fragment>
    );
}

export default InsuranceInfo;