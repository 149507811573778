
export const REGISTER = 'REGISTER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SETLOADER = 'SETLOADER'
export const UNSETLOADER = 'UNSETLOADER'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const SET_TIMMER = 'SET_TIMMER'
export const REMOVE_TIMMER = 'REMOVE_TIMMER'
export const SET_FILTER = 'SET_FILTER'
export const SET_HEADERSEARCHTEXT = 'SET_HEADERSEARCHTEXT'
export const SET_ADVANCESEARCHMODAL = 'SET_ADVANCESEARCHMODAL'
export const SET_BOOKINGLIST = 'SET_BOOKINGLIST'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_RESPONSE_MODAL = 'SHOW_RESPONSE_MODAL'
export const HIDE_RESPONSE_MODAL = 'HIDE_RESPONSE_MODAL'
export const SET_TOKEN = 'SET_TOKEN'
export const UNSET_TOKEN = 'UNSET_TOKEN'
export const SHOW_HIDE_MENU = 'SHOW_HIDE_MENU'
export const SET_OTPVERIFY = 'SET_OTPVERIFY'
export const SET_COVIDOPTION = 'SET_COVIDOPTION'
export const LOGIN_MODAL = 'LOGIN_MODAL'
export const LAT_LNG = 'LAT_LNG'
export const SET_ADMIN_TOKEN = 'SET_ADMIN_TOKEN'
export const ADMIN_LOGIN = 'ADMIN_LOGIN'
export const SET_APPOINTMENT_HISTORY = 'SET_APPOINTMENT_HISTORY'
export const SET_PROVIDER_DETAILS = 'SET_PROVIDER_DETAILS'
export const SET_HEALTH_GRADE = 'SET_HEALTH_GRADE'
export const SET_COOKIE_CONCENT = 'SET_COOKIE_CONCENT'







