import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { PatientLinks } from '../../linkFile';






const CovidSelfAssessment6 = (props) => {
  const authContext = useContext(AuthContext);
  const step6 = authContext['covidOption'] && authContext['covidOption']['step-6'] ? authContext['covidOption']['step-6'] : []
  return (
    <Fragment>
      <Container fluid className="covid-screens-wrap">
        <Row>
          <Container>
            <Row>
                <div className="col-12">
                    <h1>Self Assessment</h1>
                    <div className="progress-outer">
                        <div className="progress-inner" style={{width: "76%"}}></div>
                    </div>
                </div>                
            </Row>
            <Row>
              <div className="col-12">
                <div className="covid-self-outerbox">
                    <div className="covid-question">
                      <span>6.</span>
                      Do you have any of the following symptoms? 
                    </div>
                    <div className="covid-question-options">
                        <div className="cus-check-rev">
                            <input type="checkbox" id="opt1" checked={step6.indexOf('opt1')  > '-1' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="covid" value="opt1"/>
                            <label htmlFor="opt1">Fever or feeling feverish (chills, sweating)</label>
                        </div>
                        <div className="cus-check-rev">
                            <input type="checkbox" id="opt2" checked={step6.indexOf('opt2')  > '-1' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="covid" value="opt2"/>
                            <label htmlFor="opt2">Shortness of breath (not severe)</label>
                        </div>
                        <div className="cus-check-rev">
                            <input type="checkbox" id="opt3" checked={step6.indexOf('opt3')  > '-1' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="covid" value="opt3"/>
                            <label htmlFor="opt3">Dry Cough</label>
                        </div>
                        <div className="cus-check-rev">
                            <input type="checkbox" id="opt4" checked={step6.indexOf('opt4')  > '-1' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="covid" value="opt4"/>
                            <label htmlFor="opt4">Extreme fatigue</label>
                        </div>
                        <div className="cus-check-rev">
                            <input type="checkbox" id="opt5" checked={step6.indexOf('opt5')  > '-1' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="covid" value="opt5"/>
                            <label htmlFor="opt5">Other symptoms not listed above</label>
                        </div>
                        <small className="error-input">{ props.error.step6 }</small>
                    </div>
                    <div className="covid-buttons">
                      <span className="covid-bkbtn" data-prev-page={PatientLinks.covid_self_4} onClick={(e)=>{ props.goPrevious(e) }}>Back</span>
                      <span className="nextbtn">
                        <input type="button" className="next-btn-pro" onClick={(e)=>{ props.onNext(e) }}  value="Next"/>
                      </span>
                    </div>
                </div>                
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
    );
  }
  
  export default CovidSelfAssessment6;