import React, { Fragment } from 'react';
import { useHistory } from "react-router-dom";
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row} from 'react-bootstrap';
import { ProviderLinks} from '../../linkFile';

const SubscriptionCancel = (props) => {
  const history = useHistory();
  const gotoplans = () => {
    history.push( {
        pathname: `${ProviderLinks.accountprofile}/manage-subscription`,
    });
  }

  return ( 
    <Fragment>
    {/* header */}
    <HeaderMain/>
    <Container className="my-account-wrap reactive-wrap">
        <Row>
            <div className="col-12">
                <h1>Subscription Cancelled</h1>
            </div>
        </Row>
        <Row className="position-relative pb-4 pb-md-5">
            <div className="col-12">  
                <div className="my-acc-info-box">                                         
                    <div className="reactive-acc-txt subs-canceled">
                        <p>
                        This is confirmation that your subscription has been cancelled at your request.
                        <br/><br/>
                        To start connecting your practice with patients digitally again, you can reactivate your account at any time. We hope you decide to come back soon.
                        </p>
                        <input style={{width:'250px'}} onClick={()=>gotoplans()} type="button" class="next-btn-pro" value="Reactivate Subscription" />
                    </div>
                </div> 
            </div>
        </Row>
        
    </Container>
    <Footer/>
   
    </Fragment>
    );
  }
  
  export default SubscriptionCancel;