import React, { Fragment,useEffect,useContext,useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import { useHistory } from "react-router";
import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
let currentPageNew = 1
let currentPageOld = 1

const Notification = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [newNotifications , setNewNotifications] = useState([])
  const [oldNotifications , setOldNotifications] = useState([])
  let noNewNotification = 0
  let noOldNotification = 0
  let stopScroll = 0

  const getNotifications = async (type = '') => {
    if(type === ''){ authContext.setLoader(); }
    let page = 1
    stopScroll = 1
    if(type === 'new'){ page = currentPageNew;  }
    if(type === 'old'){ page = currentPageOld; }
    const submitData = {
      headers: {
          'Content-Type': 'application/json',
          'access_token': authContext.token
      },
      url: 'patients/notifications?page='+page+'&view='+(type ? type : 'both'),
  }

    let response = await NetworkLayer.getRequest(submitData);
    if(type === ''){ authContext.unsetLoader(); }
    stopScroll = 0
    if(response.status === true){
      //console.log('response.result.rows',response.result.newNotificationData.rows)
      if(type === 'new'){
        let notifications = JSON.parse(JSON.stringify(newNotifications))
        if(response.result.newNotificationData.rows.length){
          response.result.newNotificationData.rows.map((notification)=>{
            notifications.push(notification)
            return null
          })
          setNewNotifications(notifications)
        }else{
          noNewNotification = 1
        }
      }
      if(type === 'old'){
        let notifications = oldNotifications
        if(response.result.oldNotificationData.rows.length){
          response.result.oldNotificationData.rows.map((notification)=>{
            notifications.push(notification)
          })
          setOldNotifications(notifications)
        }else{
          noOldNotification = 1
        }
      }
      if(type === 'both'){
        setNewNotifications(response.result.newNotificationData.rows)
        setOldNotifications(response.result.oldNotificationData.rows)
      }
      
    }
  }

  const checkScroll = (e,type) => {
    if(stopScroll){ return; }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if(type === 'new'){
        if(noNewNotification){
          getNotifications(type)
        }else{
          currentPageNew++; 
          getNotifications(type)
        }
      }
      if(type === 'old'){
        if(noOldNotification){
          getNotifications(type)
        }else{
          currentPageOld++; 
          getNotifications(type)
        }
      }
    }
  };

  const readNotification = async (notification,type) => {
    const id = notification.id
    console.log('notification',notification)
    if(notification.notificationsMasters.url){
      history.push({
        pathname:  notification.notificationsMasters.url,
        state: {
          response: notification.notificationsMasters.url == '/provider/dashboard' ?  'appointment-page' : '' 
        } 
      });
    }
    if(type)
    {
      const submitData = {
        headers: {
            'Content-Type': 'application/json',
            'access_token': authContext.token
        },
        url: 'patients/notifications/updateStatus',
        body: JSON.stringify({ id: id, status: 'read' }),

      }
      let response = await NetworkLayer.postData(submitData);
      
      let allnewNotifications = JSON.parse(JSON.stringify(newNotifications))
      const notificationClicked = allnewNotifications.findIndex(x=>x.id === id)
      allnewNotifications[notificationClicked].is_read = 1

      setNewNotifications(allnewNotifications)
    }
  }


  useEffect(() => {
    getNotifications('both');
  }, [])
  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <HomeHeader/>
      </Container>
      


      <Container fluid className="free-profile">
          <Container>
            <Row>
                <div className="col-12 no-padding">
                    <h2>Notifications</h2>
                </div>

                <div className="col-12 notifications recent mb-4">
                  <Row>
                    <h3 className="mb-4">Recent Notifications</h3>
                  </Row>
                  <Row className="notifications_wrapper" onScroll={(e) => checkScroll(e,'new')}>
                    {
                      newNotifications.length ?
                        newNotifications.map((notification,key)=>{
                          return(
                            <div key={key} style={{cursor:'pointer'}} onClick={()=>readNotification(notification,true)} className={notification.is_read === 0 ? "col-md-12 pt-3 pb-3 section unread" : "col-md-12 pt-3 pb-3 section"}>
                              <h4 className="mb-2">{notification.notificationsMasters.title}</h4>
                              <div>{notification.notificationsMasters.message}</div>
                            </div>
                          )
                        })
                      :
                      "Notifications not available"
                    }
                  </Row>
                </div>


                <div className="col-12 notifications earlier">
                  <Row>
                    <h3 className="mb-4">Earlier</h3>
                  </Row>
                  <Row className="notifications_wrapper" onScroll={(e) => checkScroll(e,'old')}>
                  {
                      oldNotifications.length ?
                        oldNotifications.map((notification,key)=>{
                          return(
                            <div key={key} onClick={()=>readNotification(notification,false)} className={notification.status === 'unread' ? "col-md-12 pt-3 pb-3 section unread" : "col-md-12 pt-3 pb-3 section"}>
                              <h4 className="mb-2">{notification.notificationsMasters.title}</h4>
                              <div>{notification.notificationsMasters.message}</div>
                            </div>
                          )
                        })
                      :
                      "Notifications not available"
                    }
                     
                  </Row>
                </div>
                
            </Row>
          </Container>
      </Container>



      {/* footer */}
      <Container fluid className="footer">
        <Container>
          <Footer/>
        </Container>
      </Container>
      
     
      
    </Fragment>
    );
  }
  
  export default Notification;