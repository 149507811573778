import React, { Fragment, useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { Link } from 'react-router-dom';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import Management from '../../components/admin/provider/revenue-mngt';
import AdminViewManagement from '../../components/admin/provider/admin-view-management';
import EditProvider from '../../components/admin/provider/edit-provider';

const RevenueManagement = (props) => {
    console.log('props on admin provider-->', props)

    const authContext = useContext(AuthContext);
    useEffect(() => {

    },[])
    return (
        <Fragment>
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section patient-management-outer"}>
                   
                    <Management />

                </Container>
            </div>
        </Fragment>
    );
}

export default RevenueManagement;