import React, { Fragment, useState, useContext, useRef, useCallback, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';

import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import InputValidation from '../../reactLayer/validation';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import Cropper from 'react-easy-crop';
import getCroppedImg  from '../../reactLayer/cropImage';
import { Link } from "react-router-dom";
import {PatientLinks} from '../../linkFile';

const EditIntro = (props) => {

    const authContext = useContext(AuthContext);
    const accountInfo = props.accountInfo ? props.accountInfo : {};
    const medicalInfo = props.medicalInfo ? props.medicalInfo : {};
    console.log('medical info in edit-into page', medicalInfo);
    const profileImageSetting = accountInfo.profileImageSetting ? JSON.parse(accountInfo.profileImageSetting) : null;
    const [userImage, setuserImage] = useState(accountInfo.profileImage ? accountInfo.profileImage : '/assets/images/top-doc-img.png' );
    const [base64Image, setbase64Image] = useState(accountInfo.profileImageOriginal ? accountInfo.profileImageOriginal : null );
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(profileImageSetting && profileImageSetting.croppedAreaPixels ? profileImageSetting.croppedAreaPixels : null);
    const [imageChange, setimageChange] = useState(null);
    const [imageError, setimageError] = useState(false);
    // console.log('medical on edit profile', medicalInfo);
    // For image cropping 
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(profileImageSetting && profileImageSetting.zoom ? profileImageSetting.zoom : 1);
    const [cropFile, setcropFile] = useState(null);
    
    const [croppedImage, setCroppedImage] = useState(null)
    
    const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] = useState(undefined);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(' testing data on croppedArea---->', croppedArea)
        console.log(' testing data on croppedAreaPixels---->', croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const renderBase64Image = (e) => {
        e.preventDefault();
        setcropFile(base64Image);
    }
    const createFile = async(url) => {
        let response = await fetch(url);
        let data = await response.blob();
        let metadata = {
            type: 'image/jpeg'
        };
        let file = new File([data], "test.jpg", metadata);
        return file;
    // ... do something with the file or return it
    }
    const showCroppedImage = useCallback(async () => {
        try {
        const croppedImage = await getCroppedImg(
            cropFile,
            croppedAreaPixels
        )
        console.log('donee', { croppedImage })
        let blob = await createFile(croppedImage);
        setuserImage(croppedImage);
        setimageChange(blob);
        setbase64Image(cropFile);
        setCroppedImage(null);
        setcropFile(null);
        } catch (e) {
        console.error(e)
        }
    }, [croppedAreaPixels, rotation])
    
    useEffect(() => {
        setInitialCroppedAreaPixels(profileImageSetting && profileImageSetting.croppedAreaPixels ? profileImageSetting.croppedAreaPixels : null)
    }, [])

     // For image cropping end
    const hiddenFileInput = React.useRef(null);
    const imageAllowed = ['image/jpeg','image/jpg'];
    const [modalProps, setmodalProps] = useState({
        type:'', 
        msg:'',
        btnname: '',
        redirection: ''
    });
    const [userData, setuserData] = useState({
        firstName: {
            value: accountInfo.firstName ? accountInfo.firstName : '',
            validation: ['blank', 'name'],
            errorMsg: ['First Name is required', 'Invalid First Name'],
            errorKey: 'firstName',
            firstName: false
        },
        middleName: {
            value: accountInfo.middleName ? accountInfo.middleName : '',
            validation: ['name'],
            errorMsg: ['Invalid Middle Name'],
            errorKey: 'middleName',
            middleName: false
        },
        lastName: {
            value: accountInfo.lastName ? accountInfo.lastName : '',
            validation: ['blank', 'name'],
            errorMsg: ['Last Name is required', 'Invalid Last Name'],
            errorKey: 'lastName',
            lastName: false
        },
    })
    
    const handleClick = event => {
        event.preventDefault();
        setimageError(false);
        if( base64Image) {
            setcropFile(base64Image);
        } else {
            hiddenFileInput.current.click();
        }
        
    };

    const handleChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            let fileEvent  = event;
            console.log('fasfsfsafsaf-254364', fileEvent.target.files[0]);
            if( imageAllowed.indexOf(event.target.files[0].type) > '-1') {
                reader.onload = (e) => {
                    if (e.target.result.match('image.*')) {
                        setuserImage(e.target.result);
                        setcropFile(e.target.result);
                        console.log('base-64 image-->', e.target.result);
                    }
                };
                reader.readAsDataURL(event.target.files[0]);
                // setimageChange(event.target.files[0])
                // console.log('fasfsfsafsaf-254364', event.target.files[0]);
            } else {
                setimageError('Invalid file type:- jpg/jpeg files are allowed');
            }
            
        }
    }

    
    const formSubmit = async() => {
        let flag = true;
        let setFocus = false;
        let tempuserData = {};
        tempuserData = userData;
        let validationResult = InputValidation(tempuserData);
        if (Object.keys(validationResult).length > 0) {
            flag = false;
            for (const prop in validationResult) {
                
                tempuserData[prop][prop] = `${validationResult[prop]}`;
                if(!flag && !setFocus) {
                    setFocus = true;
                    let element = document.querySelectorAll(`[name="${prop}"]`)[0].scrollIntoView({block: "start", behavior: "smooth"});
                }
            }
            setuserData({...tempuserData});
        }
        if( flag ) {
            authContext.setLoader();

            const formData = new FormData();
            
            formData.append('firstName', userData.firstName.value.trim())
            formData.append('middleName', userData.middleName.value.trim())
            formData.append('lastName', userData.lastName.value.trim())
            if(imageChange && base64Image) {
                console.log('imageChange', imageChange);
                formData.append('profileImage', imageChange);
                formData.append('profileImageOriginal', base64Image);
                formData.append('profileImageSetting', JSON.stringify({zoom: zoom, croppedAreaPixels: croppedAreaPixels}));
                
            } else {
                formData.append('profileImage', '');
                formData.append('profileImageOriginal', '');
                formData.append('profileImageSetting', '');
            }
            
            const submitData = {
                headers: {
                    // 'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/profile-details/update',
                body: formData
                
            }
            console.log('post- result', submitData);
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            // props.setVisibleComponent('');
            console.log('result', result);
            if( result.body.status ) {
                props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
                setmodalProps({
                    type:'success', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                setTimeout(() => {
                    props.setVisibleComponent && props.setVisibleComponent('');
                },3000);
            } else {
                setmodalProps({
                    type:'error', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }
    }
    const modifyUserData = (e) => {
       let index,name,value,
            tempData = {};

        tempData = userData;
        name = e.currentTarget.getAttribute('name');
        value = e.currentTarget.value;
        tempData[name][name] = '';
        tempData[name]['value'] = value;
        setuserData({...tempData});
    }
    const deleteImageProfile = (e) => {
        e.preventDefault();
        setbase64Image(null);
        setcropFile(null);
        setuserImage(null);
    }
    const setZoomOnSlide = (e,zoom) => {
       // debugger;

    }
    return (
        <Fragment>
            <Container>
                <Row>
                {
                    authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                }
                </Row>
                <div className="dashboard-modal-wrap">
                    <div className="edit-intro">
                        <Row>
                            <div className="col-12 px-0">  
                                <div className="modal-head">Edit Intro</div>
                                
                                {
                                    cropFile ? (<>
                                        <div className="mb-5 croppper">
                                            <Cropper
                                                image={cropFile}
                                                crop={crop}
                                                rotation={rotation}
                                                zoom={zoom}
                                                aspect={1}
                                                cropShape={'round'}
                                                cropSize={{width: 180, height: 180}}
                                                onCropChange={setCrop}
                                                onRotationChange={setRotation}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                                zoomWithScroll={1}
                                                initialCroppedAreaPixels={initialCroppedAreaPixels}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 value-box ">
                                            <input 
                                                className="next-btn-pro mt-0"
                                                type="range"
                                                value={zoom}
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                aria-labelledby="Zoom"
                                                onChange={(e) => setZoom(e.currentTarget.value)}                                            
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 value-box mb-4">
                                            <input type="button" className="next-btn-pro mt-2" onClick={showCroppedImage} value="Crop"/>
                                        </div>

                                    </>) : (<>
                                        
                                        <a href="\#" onClick={(e)=> e.preventDefault()}>
                                            { 
                                                userImage ? (
                                                    <div className="edit-profile-pic" onClick={(e)=> handleClick(e)}>
                                                        <img src={userImage} alt="Doctor" />                  
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            
                                            <div style={{ display: 'inline-flex', 'justify-content':'center' }}>
                                            {
                                                base64Image ? (
                                                    <span htmlFor="" onClick={(e) => renderBase64Image(e)} ><div className="edit-icon"></div></span>
                                                ) : (<></>)
                                            }

                                            
                                        
                                            <span htmlFor="myFile" onClick={(e)=> hiddenFileInput.current.click()}><div className="plus-icon"></div></span>
                                            <span htmlFor="" onClick={(e) => deleteImageProfile(e)} ><div className="delete-icon"></div></span>
                                            </div>
                                            <small className="error-input">{imageError}</small>
                                        </a>
                                    </>)
                                    
                                }
                                
                                <input ref={hiddenFileInput}
                                        onChange={handleChange} 
                                        type="file" id="myFile" 
                                        accept="image/jpeg" 
                                        name="file" value="" 
                                        style={{display: 'none'}} 
                                />
                            </div>
                        </Row> 
                        <Row>
                            <div className="col-12 value-box mb-3">
                                <label htmlFor="firstName">First Name</label>
                                <input type="text" name="firstName"  id="firstName"
                                    className={ userData.firstName.firstName ? "input-pro  error-border" : "input-pro"}
                                    onChange={(e)=>modifyUserData(e)}
                                    value={userData.firstName.value}
                                />
                                <small className="error-input">{userData.firstName.firstName}</small>
                            </div>
                            <div className="col-12 value-box mb-3">
                                <label htmlFor="middleName">Middle Name</label>
                                <input type="text" name="middleName"  id="middleName"
                                    className={ userData.middleName.middleName ? "input-pro  error-border" : "input-pro"}
                                    onChange={(e)=>modifyUserData(e)}
                                    value={userData.middleName.value}
                                />
                                <small className="error-input">{userData.middleName.middleName}</small>
                            </div>
                            <div className="col-12 value-box mb-3">
                                <label htmlFor="lastName">Last Name</label>
                                <input type="text" name="lastName"  id="lastName"
                                    className={ userData.lastName.lastName ? "input-pro  error-border" : "input-pro"}
                                    onChange={(e)=>modifyUserData(e)}
                                    value={userData.lastName.value}
                                />
                                <small className="error-input">{userData.lastName.lastName}</small>
                            </div>
                            {
                                
                                medicalInfo.providerMedicalSpecialities.map((m,i)=>
                                    <>
                                    
                                    <div className="col-12 value-box mb-3">
                                        <label htmlFor="mCred" className="pro-label">Medical Credential</label>
                                        <input type="text" name="mCred" className="input-pro" defaultValue={m.degrees.name} disabled/>
                                        <small className="error-input"></small>
                                    </div>
                                    <div className="col-12 value-box mb-1">
                                        <label htmlFor="mSpec" className="pro-label">Medical Specialty</label>
                                        <input type="text" name="mSpec" className="input-pro" defaultValue={m.specialties.name} disabled/>
                                        <small className="error-input"></small>
                                    </div>
                                    </>
                                
                                )
                            }
                            
                            <div className="col-12 value-box">
                               <Link to={{
                                    pathname: PatientLinks.contactus,
                                    state: { selectedFeild: 'Technical Issue' }
                                }}>Need to change specialty?</Link>
                            </div>
                            <div className="col-12 value-box mb-4">
                                {
                                    cropFile ? (<>
                                        <input type="button" className="next-btn-pro" style={{opacity:'0.5'}}  value="Save"/>
                                    </>) : 
                                    (<> 
                                        <input type="button" className="next-btn-pro" onClick={formSubmit} value="Save"/>
                                    </>)
                                }
                                
                            </div>
                        </Row> 
                    </div>
                </div>              
            </Container>
        </Fragment>
    )
    
}

export default EditIntro;