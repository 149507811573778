import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';







const Doctorprofile = (props) => {

  return (
    <Fragment>
        <div className="provider-detail-box">            
            <img src="/assets/images/edit-icon.svg" className="edit-doc-profile" alt="Edit Profile"/>
            <div className="provider-doc-img">
                {
                    props.profileImage ? (<>
                        <img src={props.profileImage} alt="Doctor" />
                    </>) : (<>
                        <img src="/assets/images/top-doc-img.png" alt="Doctor" />
                    </>)
                }
                {
                    props.accountInfo && props.accountInfo.profileImage ? (<>
                        <img src={props.accountInfo.profileImage} alt="Doctor" />
                    </>) : (<>
                        <img src="/assets/images/top-doc-img.png" alt="Doctor" />
                    </>)
                }
                
            </div>
            {
                props.accountInfo ? (<>
                    <h5 style={{textTransform: 'capitalize'}}>
                        {props?.accountInfo?.firstName ? props.accountInfo.firstName + ' ' : ''} 
                        {props?.accountInfo?.middleName ? props.accountInfo.middleName + ' ' : ''} 
                        {props?.accountInfo?.lastName ? props.accountInfo.lastName : ''}
                        {props?.medicalSpeciality?.providerMedicalCredientialsValue ? ', '+props.medicalSpeciality.providerMedicalCredientialsValue : ''} 
                        <span>Verified</span>
                    </h5>
                    <div className="provider-designation 1">{props?.medicalSpeciality?.providerMedicalSpecialitiesValue ? props.medicalSpeciality.providerMedicalSpecialitiesValue : '' }</div>
                    <div className="endorsements"><span>{props?.endorsementCount ? props.endorsementCount : 0 } Doctor endorsements</span></div>
                    <div className="rating-result">
                        <span className="rating view"><span className={ props?.ratingReviewsAvg ? `rating${parseFloat(props.ratingReviewsAvg).toFixed(1)}`: 'rating0' }></span></span>
                        <span>({props?.ratingReviewsAvg ? parseFloat(props?.ratingReviewsAvg).toFixed(1) : 0}) {props.ratingReviewsCount ? props.ratingReviewsCount : 0 } Reviews</span>
                    </div>
                </>) : (<>
                    <h5 style={{textTransform: 'capitalize'}}>
                        {props?.providers?.firstName ? props.providers.firstName + ' ' : ''} 
                        {props?.providers?.middleName ? props.providers.middleName + ' ' : ''} 
                        {props?.providers?.lastName ? props.providers.lastName : ''}
                        {props?.providerMedicalCredientialsValue ? ', '+props.providerMedicalCredientialsValue : ''} 
                        <span>Verified</span>
                    </h5>
                    <div className="provider-designation 2">{props?.providerMedicalSpecialitiesValue ? props.providerMedicalSpecialitiesValue : '' }</div>
                    <div className="endorsements"><span>{props?.totalEndorsementCount ? props.totalEndorsementCount : 0 } Doctor endorsements</span></div>
                    <div className="rating-result">
                    <span class="stars-outer" style={{height:'25px'}}>
                                    {
                                        [1,2,3,4,5].map((starNumber,key)=>{
                                            return(
                                                <span key={key} class={props?.totalReviewAvg ? parseFloat(props.totalReviewAvg).toFixed(1) >= starNumber ? "full star" : "star" : "start"} name="rating"></span>
                                            )
                                        })
                                    }
                                </span>
                        <span>({props?.totalReviewAvg ? parseFloat(props?.totalReviewAvg).toFixed(1) : 0}) {props?.totalReviewCount ? props.totalReviewCount : 0 } Reviews</span>
                    </div>
                </>)
            }
            
        </div>
    </Fragment>
    );
  }
  
  export default Doctorprofile;