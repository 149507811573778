import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

const CircleAnimationGrade = (props) => {
    const {grade,score} = props;
    
  return (
    
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" className="circular-chart blue">
            <defs>    
                <linearGradient id="Gradient1" x1="2" x2="1" y1="1" y2="2">
                <stop offset="0%" stop-color="#49c4fe"/>
                <stop offset="50%" stop-color="#077db4"/>
                <stop offset="100%" stop-color="#077db4"/>
                </linearGradient>
            </defs>
            <path className="circle-bg"
            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path className={score ? 'circle animate' : 'circle'} stroke="url(#Gradient1)" stroke-dasharray={`${score}, 100`}
            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text x="18" y="20.35" dy="2" className="percentage">{grade}</text>
        </svg>
  );
}

export default CircleAnimationGrade;