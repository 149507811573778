import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { ReactComponent as SelectIcon } from '../../../admin/assets/images/list-triangle.svg';
import SubAdminTable from './sub-admin-table';
import NetworkLayer from '../../../reactLayer/Network/Network';
import { Multiselect } from 'react-widgets'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';
import InputValidation from '../../../reactLayer/validation';
import { right } from '@popperjs/core';

const randompassword = (length) => {
    var result = [];

    var charactersAlpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersSymbol = '@#';
    var charactersNumber = '123456789';
    var characters = 'abcdefghijklmnopqrstuvwxyz';
    var charactersAlphaLength = charactersAlpha.length;
    var charactersSymbolLength = charactersSymbol.length;
    var charactersNumberLength = charactersNumber.length;
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
        if (i == 0) {
            result.push(charactersAlpha.charAt(Math.floor(Math.random() *
                charactersAlphaLength)));
        } else if (i == 3) {
            result.push(charactersSymbol.charAt(Math.floor(Math.random() *
                charactersSymbolLength)));
        } else if (i == 4 || i == 6) {
            result.push(charactersNumber.charAt(Math.floor(Math.random() *
                charactersNumberLength)));
        } else {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
        }
    }
    return result.join('');
}

const SubAdmin = () => {
    const cancelClick = React.useRef(null)
    const authContext = useContext(AuthContext);
    const hiddenFileInput = React.useRef(null);
    const [refresh, setRefresh] = useState(0);
    const [isedit, setIsedit] = useState(0);
    const [profileImage, setProfileImage] = useState();
    const [userImage, setuserImage] = useState("");
    const initialValue = {
        userid: {
            value: 0
        }, email: {
            value: "",
            validation: ['blank', 'email'],
            errorMsg: ['Email is required', 'Invalid Email'],
            errorKey: 'email',
        },
        password: {
            value: ""
        },
        firstName: {
            value: "",
            validation: ['blank', 'name'],
            errorMsg: ['First Name is required', 'Invalid First Name'],
            errorKey: 'firstName',
        },
        lastName: {
            value: "",
            validation: ['blank', 'name'],
            errorMsg: ['Last Name is required', 'Invalid Last Name'],
            errorKey: 'lastName',
        },
        dateOfBirth: {
            value: ""
        },
        phoneNumber: {
            value: ""
        },
        role: {
            value: ""
        },
        modules: {
            value: [],
            validation: ['blank'],
            errorMsg: ['Permissions are required'],
            errorKey: 'modules',
        }
    }

    const [permission, setPermission] = useState({
        permission: {
            value: []
        },
    })
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    const [permissionlist, setPermissionlist] = useState([])
    const [formdata, setFormdata] = useState(initialValue);
    const imageAllowed = ['image/jpeg', 'image/jpg', 'image/png'];


    const getpermissions = async () => {

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/permissions/get',
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        let totalPermissions = result ?.result ?.rows;
        let finalPermissions = []
        for (let index = 0; index < totalPermissions.length; index++) {
            let temp = {}
            const element = totalPermissions[index];
            temp.id = element.id
            temp.name = element.name
            finalPermissions.push(temp);
        }
        return finalPermissions;
    }

    const getSubAdminDetails = async (id) => {

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getAdmin/' + id,
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        return result ?.result;
    }

    const submitForm = async (addEdit) => {
        const formDataNew = new FormData();

        let tempCustIns = formdata;
        let flag = true;
        let validationResult = InputValidation(formdata);

        // return false;
        if (Object.keys(validationResult).length > 0) {
            flag = false;
            for (const prop in validationResult) {
                tempCustIns[prop][prop] = `${validationResult[prop]}`;
            }
            setFormdata({ ...tempCustIns });
        }

        // return false;
        if (!flag) {
            return false;
        }

        
        // FORM DATA 

        let url = 'admin/createAdmin';

        if (addEdit == 1) {
            url = 'admin/updateAdmin';
            formDataNew.append('email', formdata.email.value)
            formDataNew.append('firstName', formdata.firstName.value)
            formDataNew.append('lastName', formdata.lastName.value)
            formDataNew.append('role', 2)
            formDataNew.append('modules', "[" + permission.permission.value.toString() + "]")
            formDataNew.append('id', formdata.userid.value)
        } else {

            formDataNew.append('email', formdata.email.value)
            formDataNew.append('password', randompassword(8))
            formDataNew.append('firstName', formdata.firstName.value)
            formDataNew.append('lastName', formdata.lastName.value)
            formDataNew.append('dateOfBirth', "")
            formDataNew.append('phoneNumber', "")
            formDataNew.append('role', 2)
            formDataNew.append('modules', "[" + permission.permission.value.toString() + "]")
        }
        if (profileImage) {
            formDataNew.append('profileImage', profileImage)
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'access_token': authContext.adminToken
            },
            url: url,
            body: formDataNew
        }

        // SEND REQUEST 
        try {
            authContext.setLoader();
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();

            if (result ?.body ?.status) {
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
                
                // cancelRecord()
                setTimeout(() => {
                    setRefresh(refresh + 1)
                    cancelClick.current.click()
                }, 3000);

            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
            }

            console.log(result);
        } catch (err) {

            console.log("ERROR :", err)
        }
        authContext.showResponseModal();

    }

    const changeData = (value, type) => {
        let input_value = value ?.target ?.value;

        let temp = {}
        let tempOtherData = {}

        if (type == 'firstName') {
            temp = {
                firstName: {
                    ...formdata.firstName, ...{
                        value: input_value,
                        firstName: ''
                    }
                }
            };
            tempOtherData = { ...formdata, ...temp };
            setFormdata(tempOtherData)
        }

        if (type == 'lastName') {
            temp = {
                lastName: {
                    ...formdata.lastName, ...{
                        value: input_value,
                        lastName: ''
                    }
                }
            };
            tempOtherData = { ...formdata, ...temp };
            setFormdata(tempOtherData)
        }

        if (type == 'email') {
            temp = {
                email: {
                    ...formdata.email, ...{
                        value: input_value,
                        email: ''
                    }
                }
            };
            tempOtherData = { ...formdata, ...temp };
            setFormdata(tempOtherData)
        }

        if (type == 'permissions') {
            temp = {
                modules: {
                    ...formdata.modules, ...{
                        value: value,
                        modules: ''
                    }
                }
            };
            tempOtherData = { ...formdata, ...temp };
            setFormdata(tempOtherData);
            changeFocusArea(value);
        }
    }

    const changeFocusArea = (favalue) => {

        let temparea
        let testarr = []
        temparea = permission;
        for (let index = 0; index < favalue.length; index++) {
            testarr.push(favalue[index].id);
        }
        temparea.permission.value = testarr;
        let tempOtherData = { ...permission, ...temparea };

        setPermission(tempOtherData);
    }

    const handleClick = event => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };

    const handleChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            let fileEvent = event;
            console.log(fileEvent.target.files[0]);
            if (imageAllowed.indexOf(event.target.files[0].type) > '-1') {
                reader.onload = (e) => {
                    if (e.target.result.match('image.*')) {
                        setuserImage(e.target.result);
                        console.log(e.target);
                    }
                };
                reader.readAsDataURL(event.target.files[0]);
                setProfileImage(event.target.files[0]);
                console.log('imageAllowed', event.target.files[0]);
            } else {
                // setimageError('Invalid file type:- jpg/jpeg files are allowed');
            }

        }
    }

    const editRecord = async (data) => {

        let temp = {}
        let tempOtherData = {}

        temp = {
            userid: {
                ...formdata.userid, ...{
                    value: data.id
                }
            },
            firstName: {
                ...formdata.firstName, ...{
                    value: data.firstName,
                    firstName: ''
                }
            },
            lastName: {
                ...formdata.lastName, ...{
                    value: data.lastName,
                    lastName: ''
                }
            },
            email: {
                ...formdata.email, ...{
                    value: data.email,
                    email: ''
                }
            },
            modules: {
                ...formdata.modules, ...{
                    value: data.userPermissions,
                    modules: ''
                }
            }
        };
        tempOtherData = { ...formdata, ...temp };
        setFormdata(tempOtherData);
        setuserImage(data.profileImage);
        setIsedit(3);
        changeFocusArea(data.userPermissions);
    }

    const cancelRecord = () => {
        setIsedit(4);
        setFormdata(initialValue);
        setuserImage("");
        setPermission({
            permission: {
                value: []
            },
        })
    }

    useEffect(async () => {
        if (isedit == 3) {
            setIsedit(1);
        }
        if (isedit == 4) {
            setIsedit(0);
        }
    }, [isedit])

    useEffect(async () => {
        let permissionlist = await getpermissions();
        let listIndexRating = permissionlist.findIndex(x=>x.name === 'RatingAndComments')
        let listIndexWebsite = permissionlist.findIndex(x=>x.name === "WebsiteContents")

        permissionlist[listIndexRating]['name'] = 'Ratings And Comments'
        permissionlist[listIndexWebsite]['name'] = 'Website Contents'
        setPermissionlist(permissionlist);
    }, [])

    return (
        <Fragment>
            {
                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            <Row className="bg-white-report">
                <div className="table-report">
                    <div className="heading-wrap">
                        <div className="table-heading">{isedit == 1 ? 'Edit' : 'New'} Sub-Admin Details</div>
                    </div>

                    {/* Bottom container content */}
                    <div className="sub-admin-wrap">
                        <div className="subadmin-top">
                            <div className="subadmin-left">
                                <div className="subadmin-profileimg">
                                    <img src={userImage ? userImage : "/assets/images/profile-dummy-img.jpg"} style={{ width: '100%', height: '100%' }} />
                                </div>
                                <div className="button-anchor">
                                    <a className="white-btn" onClick={(e) => handleClick(e)}>Select Image</a>
                                </div>
                                <input ref={hiddenFileInput}
                                    onChange={handleChange}
                                    type="file" id="myFile"
                                    accept="image/*"
                                    name="file" value=""
                                    style={{ display: 'none' }}
                                />
                            </div>

                            <div className="subadmin-right">
                                <div className="subadmin-right-inner">
                                    <div className="right-fields">
                                        <input type="text" className="input-pro" name="fName" id="fName" placeholder="First Name" autocomplete="off" value={formdata.firstName.value} onChange={(e) => { changeData(e, 'firstName') }} />
                                        <small className="error-input">{formdata.firstName.firstName}</small>
                                    </div>
                                    <div className="right-fields">
                                        <input type="text" className="input-pro" name="lName" id="lName" placeholder="Last Name" autocomplete="off" value={formdata.lastName.value} onChange={(e) => { changeData(e, 'lastName') }} />
                                        <small className="error-input">{formdata.lastName.lastName}</small>
                                    </div>
                                    <div className="right-fields">
                                        <input type="text" className="input-pro" name="eMail" id="eMail" placeholder="Email" autocomplete="off" value={formdata.email.value} onChange={(e) => { changeData(e, 'email') }} />
                                        <small className="error-input">{formdata.email.email}</small>
                                    </div>
                                    <input type="hidden" className="input-pro" name="userid" id="userid" value={formdata.userid.value} />
                                    <div className="right-fields">

                                        {isedit == 1 &&
                                            <>
                                                < Multiselect
                                                    dataKey="id"
                                                    textField="name"
                                                    value={permission.permission.value.id}
                                                    placeholder={"Roles & Permission"}
                                                    className={!permission ?.permission ?.value ? "input-pro  error-border" : "input-pro"}
                                                    onChange={(e) => { changeData(e, 'permissions') }}
                                                    data={permissionlist}
                                                    defaultValue={formdata.modules.value}
                                                    selectIcon
                                                />
                                            </>
                                        }
                                        {isedit == 0 &&
                                            <>
                                                < Multiselect
                                                    dataKey="id"
                                                    textField="name"
                                                    value={permission.permission.value.id}
                                                    placeholder={"Roles & Permission"}
                                                    className={!permission ?.permission ?.value ? "input-pro  error-border" : "input-pro"}
                                                    onChange={(e) => { changeData(e, 'permissions') }}
                                                    data={permissionlist}
                                                    defaultValue={formdata.modules.value}
                                                />
                                            </>
                                        }
                                        <small className="error-input">{formdata.modules.modules}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* bottom buttons */}
                        <div className="bottom-btns">
                            <div className="next-prev-btn-pro">
                                <input type="button" className="next-btn-pro" value={isedit == 1 ? 'Update Sub-Admin' : 'Add Sub-Admin'} onClick={() => submitForm(isedit)} style={{ 'margin-right': '12px' }} />
                                <input ref={cancelClick} type="button" className="prev-btn-pro" value="Cancel" onClick={cancelRecord} />
                            </div>
                        </div>
                    </div>
                    {/* End top section */}
                    <SubAdminTable refresh={refresh} editRecord={editRecord} />
                </div>
            </Row>
        </Fragment >
    );
}

export default SubAdmin;