import React, { Fragment, useEffect, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { AdminLinks } from '../../../linkFile';
import { Link,useLocation } from 'react-router-dom';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import ManagementFilter from '../provider/filter';
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import * as moment from 'moment';
import Pagination from "../../../components/admin/pagination/pagination";
import Loadermodal from "../../../global/loader-modal/loader-modal";
import ProviderManagementInfoMadal from './provider-manage-info-modal';
import Custommodal from '../../../global/modal/modal';
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';


const AdminViewManagement = (props) => {
    const authContext = useContext(AuthContext);
    const [totalCount, settotalCount] = useState(0);
    const [filter, setfilter] = useState({
        offset: 0,
        limit: 10
    });
    const [data, setData] = useState([]);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [viewdetails, setViewdetails] = useState([]);
    const [search, setSearch] = useState("");
    const [sort, setsort] = useState('ASC');
    const [sortName, setSortName] = useState('Name');
    const [pageHeading,setPageHeading] = useState("Provider Management")
    const currentUrl = window.location.href.split('/').slice(-1).pop();
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    // FOR POPUP MODAL
    const modalList = ['manageInfo'];
    const openProfileTabModal = (modalName, value) => {
        getDetails(value ?.id).then(() => {
            settabvisibleComponent(modalName)
            authContext.showModal()
        })
    }

    const getList = async (value) => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (value) {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                searchValue: value,
                sortOrder: sort,
                sortBy: sortName
            }
        } else {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                sortOrder: sort,
                sortBy: sortName
            }
        }

        if(currentUrl === 'incomplete-registered-providers'){
            formData.actionType = 'incompleted'
            setPageHeading('Incomplete Registered Providers')
        }

        if(currentUrl === 'latest-providers'){
            formData.actionType = 'latest'
            setPageHeading('Latest Registered Provider (Past 30 days)')
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/get',
            body: JSON.stringify(formData),
        }

        console.log('submitData',submitData)

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        // console.log("result==>", result.body.result);
        setData(result ?.body ?.result ?.data);
        settotalCount(result ?.body ?.result ?.totalRecords);
        authContext.unsetLoader();
    }

    const getDetails = async (id) => {
        authContext.setLoader();

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/view/' + id,
        }



        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        setViewdetails(result ?.result);
        authContext.unsetLoader();
    }

    const updateStatus = async (id, status) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id,
            status: status
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/status/update',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        authContext.unsetLoader();
        getList();
    }

    const deleteProvider = async (id) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/delete',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        if (window.confirm('Are you sure you want to delete this provider?')) {
            authContext.setLoader();
            let result = await NetworkLayer.postData(submitData);
            console.log('deleteSuccess',result)
            authContext.unsetLoader();
            setmodalProps({
                type: 'success',
                msg: "Provider deleted successfully",
            });
            authContext.showResponseModal();
        }

    }

    useEffect(() => {
        getList();
    }, [filter.offset, sort])

    // PAGINATION CALLBACK
    const changeLimit = (page) => {
        setfilter({ ...filter, ...{ offset: parseInt(page) } });
    };

    // const getAccountType = (val) => {
    //     switch (val) {
    //         case 1:
    //             return 'free';
    //         case 2:
    //             return 'Basic';
    //         case 3:
    //             return 'Premium';
    //         case 4:
    //             return 'Premium+';
    //         default:
    //             return 'free';
    //     }
    // };

    const toggleStatus = (values) => {
        let status = values.status == 1 ? 0 : 1;
        let id = values.id;
        updateStatus(id, status);
    }

    const changeSorting = (e) => {
        let name = e.currentTarget.getAttribute('name');
        setSortName(name);
        setsort(sort == "ASC" ? "DESC" : "ASC")
    };

    const searchFilter = (value) => {
        console.log(value);
        getList(value);
    }
    return (
        <Fragment>
            <Loadermodal />
            {
                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'manageInfo' ? <ProviderManagementInfoMadal data={viewdetails} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <Row className="bg-white-report">
                <div className="table-report provider-management-outer">
                    <div className="heading-wrap">
                        <div className="table-heading">
                            {pageHeading}
                        </div>
                    </div>
                    <ManagementFilter search={searchFilter} />
                    <div className="table-report-scroll mt-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="data-sort">
                                        <span className="sort-outer">
                                            Provider Name
                                            <span className="data-sort-icon" name="Name" onClick={changeSorting}><DataSortIcon /></span>
                                        </span>
                                    </th>
                                    <th>Years Exp.</th>
                                    <th style={{ 'min-width': '130px' }}>
                                        <span className="sort-outer">
                                            Joined Date
                                            <span className="data-sort-icon" name="createdAt" onClick={changeSorting}><DataSortIcon /></span>
                                        </span>
                                    </th>
                                    <th>Email</th>
                                    <th>Location</th>
                                    <th style={{ 'min-width': '150px' }}>Account Type</th>
                                    
                                    <th>
                                        <span className="sort-outer">
                                            Active
                                            <span className="data-sort-icon" name="status" onClick={changeSorting}><DataSortIcon /></span>
                                        </span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((value, index) =>
                                        <tr key={index}>
                                            <td>{`${value.providers.firstName} ${value.providers.lastName}`}</td>
                                            <td>{value.provider_exp}</td>
                                            <td>{moment(value.createdAt).format('MM/DD/YYYY')}</td>
                                            <td>{value.email}</td>
                                            <td>{value.practiceLocation}{value.providerPracticeLocations && value.providerPracticeLocations[0] && value.providerPracticeLocations[0].cities ? ', '+value.providerPracticeLocations[0].cities.name :''}{value.providerPracticeLocations && value.providerPracticeLocations[0] && value.providerPracticeLocations[0].states ? ', '+ value.providerPracticeLocations[0].states.name:''}</td>
                                            <td>{value.userType}</td>
                                            <td>
                                                <label class="active-toggle">
                                                    <input type="checkbox" checked={value.status == 1 ? "checked" : ""} onChange={() => { toggleStatus(value) }} />
                                                    <span class="slider round"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <Link onClick={() => { openProfileTabModal("manageInfo", value) }} className="table-action view-all">View</Link>
                                                <Link to={`${AdminLinks.admin_provider_detail}/${value.id}`} className="table-action view-all">Edit</Link>
                                                <Link className="table-action view-all" onClick={() => { deleteProvider(value.id) }}>Delete</Link>
                                            </td>
                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="report-page mt-4">
                        <Pagination
                            total={totalCount}
                            limit={filter.limit}
                            range={4}
                            offset={filter.offset}
                            callBack={changeLimit}
                        />
                    </div>

                </div>
            </Row>
        </Fragment>
    );
}

export default AdminViewManagement;