import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import Error403 from '../../global/errorpages/error-403';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { PatientLinks } from '../../linkFile';






const CovidSelfAssessment8 = (props) => {
  const authContext = useContext(AuthContext);
  const step8 = authContext['covidOption'] && authContext['covidOption']['step-8'] ? authContext['covidOption']['step-8'] : false
  const validLinks = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
  const Validatepages = () => {
      if (step8 && validLinks.indexOf(step8) > '-1') {
        if( step8 === 'first' ) {
            return <>
                <Row>
                    <Container>
                        <Row>
                            <div className="col-12">
                                <h1>Self Assessment</h1>
                                <div className="progress-outer">
                                    <div className="progress-inner" style={{width: "100%"}}></div>
                                </div>
                            </div>                
                        </Row>
                        <Row>
                            <div className="col-12">
                                <div className="covid-screen8-outer">
                                    <div className="covid-greenbox">
                                        <div className="left-icon"><img src="/assets/images/green-covid.svg" alt=""/></div>
                                        <div className="right-text">
                                            <span>Sounds like you are feeling ok.</span>
                                            <span>This Coronavirus Self-Checker system is for those who may be sick.`</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Row>
            </>
        } else  if( step8 === 'second' ) {
            return <>
                <Row>
                    <Container>
                        <Row>
                            <div className="col-12">
                                <h1>Self Assessment</h1>
                                <div className="progress-outer">
                                    <div className="progress-inner" style={{width: "100%"}}></div>
                                </div>
                            </div>                
                        </Row>
                        <Row>
                            <div className="col-12">
                                <div className="covid-screen8-outer">
                                    <div className="covid-redbox">
                                        <div className="left-icon"><img src="/assets/images/red-covid.svg" alt=""/></div>
                                        <div className="right-text">
                                            <span>Call 911 now, you may be having a medical emergency. Immediate medical attention is needed.</span>
                                            <span>Tell the 911 operator if you have been in contact with someone with COVID-19 or if you have recently been to an area where COVID-19 is spreading.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Row>
            </>
        } else  if( step8 === 'third' ) {
            return <>
                <Row>
                    <Container>
                        <Row>
                            <div className="col-12">
                                <h1>Self Assessment</h1>
                                <div className="progress-outer">
                                    <div className="progress-inner" style={{width: "100%"}}></div>
                                </div>
                            </div>                
                        </Row>
                        <Row>
                            <div className="col-12">
                                <div className="covid-screen8-outer">
                                    <div className="covid-yellowbox">
                                        <div className="left-icon"><img src="/assets/images/orange-covid.svg" alt=""/></div>
                                        <div className="right-text">
                                            <span>Go to the nearest emergency department. Urgent medical attention may be needed.</span>
                                            <span>Tell the emergency staff if you have had contact with someone with COVID-19 or if you have recently been to an area where COVID-19 is spreading.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Row>
            </>
        } else  if( step8 === 'fourth' ) {
            return <>
                <Row>
                    <Container>
                        <Row>
                            <div className="col-12">
                                <h1>Self Assessment</h1>
                                <div className="progress-outer">
                                    <div className="progress-inner" style={{width: "100%"}}></div>
                                </div>
                            </div>                
                        </Row>
                        <Row>
                            <div className="col-12">
                                <div className="covid-screen8-outer">
                                    <div className="covid-yellowbox">
                                        <div className="left-icon"><img src="/assets/images/orange-covid.svg" alt=""/></div>
                                        <div className="right-text">
                                            <span>Stay home and take care of yourself. Call your provider if you get worse. You have one or more symptom(s) that may be related to COVID-19. Stay home and take care of yourself.</span>
                                            <span>Stay home and take care of yourself. Get tested if possible and call your provider if you get worse. You have one or more symptom(s) that may be related to COVID-19. Stay home and take care of yourself.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Row>
            </>
        } else  if( step8 === 'fifth' ) {
            return <>
                <Row>
                    <Container>
                        <Row>
                            <div className="col-12">
                                <h1>Self Assessment</h1>
                                <div className="progress-outer">
                                    <div className="progress-inner" style={{width: "100%"}}></div>
                                </div>
                            </div>                
                        </Row>
                        <Row>
                            <div className="col-12">
                                <div className="covid-screen8-outer">
                                    <div className="covid-yellowbox">
                                        <div className="left-icon"><img src="/assets/images/orange-covid.svg" alt=""/></div>
                                        <div className="right-text">
                                            <span>You may want to consider asking your provider about testing and treatment for influenza.</span>
                                            <span>Because some of the symptoms of flu and COVID-19 are similar, it may be hard to tell the difference between them based on symptoms alone, and testing may be needed to help confirm a diagnosis.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Row>
            </>
        } else  if( step8 === 'sixth' ) {
            return <>
                <Row>
                    <Container>
                        <Row>
                            <div className="col-12">
                                <h1>Self Assessment</h1>
                                <div className="progress-outer">
                                    <div className="progress-inner" style={{width: "100%"}}></div>
                                </div>
                            </div>                
                        </Row>
                        <Row>
                            <div className="col-12">
                                <div className="covid-screen8-outer">
                                    <div className="covid-yellowbox">
                                        <div className="left-icon"><img src="/assets/images/orange-covid.svg" alt=""/></div>
                                        <div className="right-text">
                                            <span>Call a medical provider within 24 hours You have some symptoms that may be related to COVID-19. You also have medical conditions that could put you at greater risk for complications from COVID-19.</span>
                                            <span>Tell the staff if you have had contact with someone with COVID-19 or if you have recently been to an area where COVID-19 is spreading. Start home isolation (e.g., no work, no school, avoid public areas and transportation) except to get medical care.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Row>
            </>
        }

      } else {
        return <Error403/>
      }
    }
  return (
    <Fragment>
      <Container fluid className="covid-screens-wrap">
        
        {
            Validatepages()
        }

      </Container>
    </Fragment>
    );
  }
  
  export default CovidSelfAssessment8;