import React, { Fragment, useState, useContext, Suspense } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import InputValidation from '../../reactLayer/validation';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import { PatientLinks } from '../../linkFile';


import CreateAccountStep from './create-acc-step';

const CreateAccountStep1 = (props) => {

    const history = useHistory();
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const [passwordInputType, setpasswordInputType] = useState('password');
    const changepasswordInputType = () => {
        setpasswordInputType(passwordInputType === 'text' ? 'password' : 'text');
    }

    const fnameErrorMsg = ['First Name is required', 'Invalid First Name'];

    const emailErrorMsg = ['Email is required', 'Invalid Email'];
    const cemailErrorMsg = ['Confirm email is required', 'Invalid Confirm Email', 'Confirm Email Not Matched'];
    const passwordErrorMsg = ['Password is required', 'Invalid Password'];


    const [errors, setErrors] = useState({
        ecemail: false,
        eemail: false,
        epassword: false,
    });

    const [userData, setUserData] = useState({

        email: {
            value: '',
            validation: ['blank', 'email'],
            errorMsg: emailErrorMsg,
            errorKey: 'eemail',
        },
        // cemail: {
        //     value: '',
        //     validation: ['blank', 'email', {'confirmEmail': {email: ''}}],
        //     errorMsg: cemailErrorMsg,
        //     errorKey: 'ecemail',
        // },
        password: {
            value: '',
            validation: ['blank', 'password'],
            errorMsg: passwordErrorMsg,
            errorKey: 'epassword',
        }

    });
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    const formDataSubmit = async () => {

        const data = {
            "email": userData.email.value,
            "password": userData.password.value,
            // "confirmEmail" :userData.cemail.value,
        }

        const submitData = {
            url: 'patients/account-information',
            body: JSON.stringify(data),

        }
        authContext.setLoader();
        let result = await NetworkLayer.postData(submitData);
        authContext.unsetLoader();
        console.log('crate acc--->', result);
        // return false;
        if (result.body.status) {
            let user = result.body.result.data;
            let token = result.body.result.token;
            authContext.setToken(token);
            authContext.setUser(user);
            setTimeout(() => {
                history.push({ pathname: PatientLinks.register_setp2 })
            }, 500);
            // setmodalProps({
            //     type:'success', 
            //     msg:'Registered Successfully',
            //     redirection: PatientLinks.register_setp2,
            //     closeredirection: PatientLinks.register_setp2
            // });
            // authContext.showResponseModal();
            // setTimeout(() => {
            //     authContext.hideResponseModal();
            //     history.push({ pathname: PatientLinks.register_setp2})
            // }, 5000);
            //  history.push({ pathname: PatientLinks.register_setp2, state: {modalName: 'twostep'}})
        } else {
            setmodalProps({
                type: 'error',
                msg: result.body.message,
                closeredirection: PatientLinks.login,
                modalName: 'login'
            });
            authContext.showResponseModal();
            // setTimeout(() => {
            //     authContext.hideResponseModal();
            //     history.push({ pathname: PatientLinks.login, state: {modalName: 'login'}})
            // }, 5000);
        }
        // console.log('result', result.status);
    }
    const formSubmit = () => {
        // console.log('userData', userData);
        const validationResult = InputValidation(userData);
        // console.log( 'validationResult-->',validationResult )
        if (Object.keys(validationResult).length > 0) {
            setErrors({ ...errors, ...validationResult });
        } else {
            formDataSubmit();
        }
    }

    return (
        <Fragment>
            {/* Form start step 1 */}
            <Suspense>
                <CreateAccountStep step="1" />
                <Container className="create-acc-steps-wrap">

                <div className="create-acc-inner-step1">
                    <Row>
                        {
                            authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                        }

                    </Row>
                    <Row >
                        <div className="col-12">
                            <div className="seaprator-title"><span><img src="/assets/images/lock-icon.png" className="scale lock" alt="lock" /> Encrypted Account Data</span></div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 mb-3 mb-3">
                            <label className="pro-label">Email Address</label>
                            <input type="text" name="eMail" className={errors.eemail ? "input-pro  error-border" : "input-pro"} placeholder="Enter Your Email" autoComplete="off"
                                onClick={() => setErrors({ ...errors, ...{ eemail: false } })}
                                onFocus={() => setErrors({ ...errors, ...{ eemail: false } })}
                                onChange={(e) => setUserData({
                                    ...userData,
                                    ...{
                                        email: {
                                            value: e.currentTarget.value.trim(),
                                            validation: ['blank', 'email'],
                                            errorMsg: emailErrorMsg,
                                            errorKey: 'eemail',
                                        },
                                        // cemail: {
                                        //     value: userData.cemail.value.trim(),
                                        //     validation: ['blank', 'email', {'confirmEmail': {email: userData.email.value}}],
                                        //     errorMsg: cemailErrorMsg,
                                        //     errorKey: 'ecemail',
                                        //     }

                                    }
                                })
                                }
                                value={userData.email.value}
                            />
                            <small className="error-input">{errors.eemail}</small>
                        </div>
                        {/* <div className="col-12 mb-3 mb-3">
                <label className="pro-label">Confirm Email Address</label>
                <input type="text" name="cMail" className={ errors.ecemail ? "input-pro  error-border" : "input-pro"} placeholder="Re-enter Your Email" autoComplete="off" 
                    onClick={()=> setErrors({...errors, ...{ecemail: false}})}
                    onFocus={()=> setErrors({...errors, ...{ecemail: false}})}
                    onChange={(e)=>setUserData({
                        ...userData,
                        ...{
                            cemail: {
                                value: e.currentTarget.value.trim(),
                                validation: ['blank', 'email', {'confirmEmail': {email: userData.email.value}}],
                                errorMsg: cemailErrorMsg,
                                errorKey: 'ecemail',
                                }
                            }
                        })
                    }
                    value={userData.cemail.value}
                />
                <small className="error-input">{errors.ecemail}</small>
            </div> */}
                        <div className="col-12 mb-2">
                            <label htmlFor="password" className="pro-label">Password</label>
                            <input type={passwordInputType} name="password" className={errors.epassword ? "input-pro  error-border" : "input-pro"} placeholder="Create Password" autoComplete="off"
                                onClick={() => setErrors({ ...errors, ...{ epassword: false } })}
                                onFocus={() => setErrors({ ...errors, ...{ epassword: false } })}
                                onChange={(e) => setUserData({
                                    ...userData,
                                    ...{
                                        password: {
                                            value: e.currentTarget.value.trim(),
                                            validation: ['blank', 'password'],
                                            errorMsg: passwordErrorMsg,
                                            errorKey: 'epassword',
                                        }
                                    }
                                })
                                }
                                onKeyUp={(e)=>{
                                    if(e.keyCode === 13){
                                        formSubmit()
                                    }
                                }}
                                value={userData.password.value}
                            />
                            <small className="error-input">{errors.epassword}</small>
                            <div className="eye-icon" onClick={() => { changepasswordInputType() }}><div className={passwordInputType === 'text' ? "pass-show-eye-icon" : "pass-hide-eye-icon"}></div></div>
                        </div>
                        <div className="col-12 mb-3 mb-3">
                            <div className="pass-rules">
                                <div className="head-rules">Password Rules:</div>
                                <ul>
                                    <li>Has at least 8 characters</li>
                                    <li>Must contain letters, at least 1 number, and at least 1 special character</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 mb-5 text-center">
                            <input type="button" className="next-btn-pro" onClick={() => { formSubmit() }} value="Next" />
                        </div>
                    </Row>
                </div>
            </Container>
            </Suspense>
        </Fragment>
    );
}

export default CreateAccountStep1;