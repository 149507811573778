import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#000',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function FileUpload(props) {
    const [file, setFile] = useState("");
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: '.xlsx, .csv',
        onDrop: (acceptedFiles) => {
            console.log("onDrop", acceptedFiles);
            props.handleChange(acceptedFiles[0]);
            setFile(acceptedFiles[0].name);
        }
    });



    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
            isDragActive,
            isDragReject,
            isDragAccept
        ]);

    const onSelect = (e) => {
        console.log("onSelect");
        props.handleChange(e);
        setFile(e.target.files[0].name);
    }


    return (

        <div {...getRootProps({ style })}>
            <input {...getInputProps()} onChange={onSelect} />
            {
                file ?
                    <p>{file}</p> :
                    <p>Drag 'n' drop file here, or click to select file</p>
            }

        </div>

    );
}

export default FileUpload;