import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import * as moment from 'moment';

const DetailModal = (props) => {

    const { data } = props;
    console.log(data);
    return (
        <Fragment>
            <Container>
                <div className="search-modal-wrap patient-info-modal sub-admin-modal">
                    <div className="edit-all-filter">
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12 mb-3 mb-md-4">
                                    <div className="rating-detail-wrap">
                                        <div className="rating-detail-head">Sub Admin Details</div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left"></div>
                                            <div className="rating-comment-right"><img className="profile-img" src={data.profileImage} /></div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">First Name:</div>
                                            <div className="rating-comment-right">{`${data.firstName}`}</div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Last Name:</div>
                                            <div className="rating-comment-right">{`${data.lastName}`}</div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Email:</div>
                                            <div className="rating-comment-right">{`${data.email}`}</div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Permissions :</div>
                                            <div className="rating-comment-right">
                                                {
                                                    data ?.userPermissions.map((val, ind) =>
                                                        <>
                                                            <p>{val.name}</p>
                                                        </>)
                                                }
                                            </div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Date Created :</div>
                                            <div className="rating-comment-right">{moment(data ?.createdAt).format('D MMM Y')}</div>
                                        </div>
                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Last Login :</div>
                                            <div className="rating-comment-right">{data ?.lastLogin ? moment(data ?.lastLogin).format('D MMM Y h:mm:ss a') : ""}</div>
                                        </div>

                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </Fragment>
    )

}

export default DetailModal;