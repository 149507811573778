import React, { Fragment, useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';
import ProviderStep from './provider-steps';
import { useHistory } from "react-router-dom";
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { ProviderLinks} from '../../linkFile';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';

 
const ProviderStep4 = (props) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const currentRunningPlan = authContext.user.providerCurrentPlan ? authContext.user.providerCurrentPlan : null;
    const [ plans, setplans] = useState([]);
    const [ services, setservices ] = useState([]);
    const [modalProps, setmodalProps] = useState({
        type:'', 
        msg:'',
        btnname: '',
        redirection: ''
    });
    const title = props.title ? props.title : 'Select a Plan';
    const getServicesPlan = async () => {
        let result = await NetworkLayer.getRequest({url: 'providers/plans/get'});
        if( result.status === true ) {
            setplans(result.result.plans);
            setservices(result.result.allservices);
        }
        return result;
    }
    const selectplan = async (e,planid) => {
        e.preventDefault();
        let selectedplan = [];
        if( planid ) {
            selectedplan = plans.filter( (plan) => {
                                if( planid == plan.id) {
                                    return true;
                                }
                            });
            
        }

        if( selectedplan.length ) {
            selectedplan = selectedplan[0];
            if( selectedplan.subPlans && Object.keys(selectedplan.subPlans).length ) {
                let redirecturl = ProviderLinks.register_setp5+planid; 
                if( authContext.user.isProfileCompleted) {
                    redirecturl = ProviderLinks.accountprofile+'/upgrade-subscription/'+planid; 
                }
                
                // let redirecturl = ProviderLinks.accountprofile+'/upgrade-subscription/' +planid; 
                history.push( {
                    pathname: redirecturl,
                });
            } else {
                const fData = {
                    "planId": planid,
                }

                authContext.setLoader();
                console.log(fData,JSON.stringify(fData));
                let url = 'providers/signup/plan/save';
                if( authContext.user.isProfileCompleted) {
                    url = 'providers/plan/update';
                }
                const submitData = {
                    headers: {
                        'Content-Type': 'application/json',
                        'access_token': authContext.token
                    },
                    url: url,
                    body: JSON.stringify(fData),
                    
                }
                let result = await NetworkLayer.postData(submitData);
                authContext.unsetLoader();
                console.log('result---> step--->4', result);
                if( result.body.status ) {
                    let user = result.body.result;
                    authContext.setUser(user);
                    if( authContext.user.isProfileCompleted) {
                         setmodalProps({
                            type:'success', 
                            msg: result.body.message,
                            closeredirection: ProviderLinks.accountprofile
                        });
                    } else {
                        setmodalProps({
                            type:'success', 
                            msg: result.body.message,
                            redirection: ProviderLinks.twofactauth,
                            closeredirection: ProviderLinks.twofactauth
                        });
                    }
                    authContext.showResponseModal();
                } else {
                    setmodalProps({
                        type:'error', 
                        msg: result.body.message,
                    });
                    authContext.showResponseModal();
                }
                
            }
        }
    }
    const confirmCancelSub = async () => {
        authContext.setLoader();
        const fData = {}
        let url = 'providers/plan/cancel';
        
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: url,
            body: JSON.stringify(fData),
            
        }
        let result = await NetworkLayer.postData(submitData);
        
        console.log('result', result);
        if( result.body.status ) {
            authContext.setUser(result.body.result);
            let redirecturl = ProviderLinks.subscription_canceled; 
             //let redirecturl = ProviderLinks.accountprofile+'/upgrade-subscription/' +planid; 
             history.push( {
                 pathname: redirecturl,
             });
            
            authContext.unsetLoader();
            authContext.showResponseModal();
            setTimeout(()=>{
                history.push({
                    pathname: ProviderLinks.subscription_canceled,
                });
            },1000)
            
        } else {
            setmodalProps({
                type:'error', 
                msg: result.body.message,
            });
            authContext.unsetLoader();
            authContext.showResponseModal();
        }
    }
    const cancelSubscription = (e) => {
        e.preventDefault();
        setmodalProps({
            autoclose:'false',
            type:'question', 
            btncancel:"Keep Subscription",
            msg:'Are you sure you want to cancel your subscription?',
            btnname: 'Cancel Subscription',
            action: confirmCancelSub
        })
        authContext.showResponseModal();
        
    }

    
    useEffect(() => {
        getServicesPlan();
        //  let result = await NetworkLayer.getData({url: 'plans'});
        //  console.log('result', result);
        return () => {
            authContext.hideResponseModal();
        }
        console.log('currentRunningPlan', currentRunningPlan);
    }, [])


  return (
    <Fragment>
    <Container fluid className="provider-steps-wrap">
        <ProviderStep step="4" />  
    </Container>
    {/* Start step 4 */}
    
        <Container className="provider-step4-inner">
            
            <Row className="no-gutters">  
                
                <div className="col-12">
                    {
                        console.log('authContext.responseModal',authContext.responseModal)
                    }
                    {
                        authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                    }
                    <div className="plan-outer">
                        <div className="plan-left">
                            <div className="plan-left-top">
                                <h2>{title}</h2>
                                <div className="pro-service hide-pro-serve">Products &amp; Services</div>
                            </div>
                            {
                                services.map((service, service_i) => 
                                    <div className="plan-left-bottom info-hg" key={service.name+'-wrapper'}>
                                        <div className="info-icon" /> 
                                        {service.name}
                                        {
                                            service.info ? (<>
                                                <div className="info-callout left-right"> {service.info} </div>
                                            </>) : (<></>)
                                        }
                                        
                                    </div>
                                )
                            }
                        </div>
                        <div className="plan-right">
                            <div className="plan-right-inner">
                            {
                                plans.map((plan, plan_i) => 
                                <div  key={plan.planName+'-container-'+plan_i} >

                                    <a href="/#"  onClick={( e )=>selectplan(e, plan.id) }
                                    className={currentRunningPlan && currentRunningPlan.planId == plan.id ? 'active upgrade' : ''}
                                    
                                    >

                                        <div className="plan-right-top">
                                            <h2>
                                                {plan.planName}
                                                <span>${plan.subPlans && plan.subPlans.Monthly ? plan.subPlans.Monthly.price : 0}/{'Month'}</span>
                                            
                                            </h2>
                                            
                                            { currentRunningPlan && currentRunningPlan.planId ? (<>
                                                
                                                    

                                                        {
                                                            currentRunningPlan.planId > plan.id  ? (
                                                                <>
                                                                    <div className="try-box active">Change Plan</div>
                                                                    <div className="purchase-now">&nbsp;</div>
                                                                </>
                                                            ) : (
                                                                currentRunningPlan.planId == plan.id ? (<>
                                                                    <div className="try-box">Keep Current Plan</div>
                                                                    <div className="purchase-now">&nbsp;</div>
                                                                </>) : (<>
                                                                    <div className="try-box active">Upgrade Plan</div>
                                                                    <div className="purchase-now">&nbsp;</div>
                                                                </>)
                                                            )
                                                        
                                                        
                                                        }
                                                    
                                                
                                            </>) : (<>
                                            
                                                { 
                                                    Object.keys(plan.subPlans).length ? (<>
                                                        <div className="try-box1">Try free for 60 days</div>
                                                        {/* <div className="purchase-now">or <span>purchase now</span></div> */}
                                                    </>) : (<>
                                                        <div className="try-box mb-3">Get started</div>
                                                        <div className="purchase-now">&nbsp;</div>
                                                    </>)
                                                }
                                            
                                            </>)
                                                
                                                
                                            }
                                            {/* <div className="pro-service-right pro-service-get-start">Products &amp; Services</div> */}
                                        </div>
                                        {
                                            services.map((service, service_i) => 
                                                <div className="plan-right-bottom" key={plan.planName+'-'+service.name}>
                                                    <div className="left-text-device info-hg">
                                                        <div className="info-icon" /> 
                                                        {service.name}
                                                        {
                                                            service.info ? (<>
                                                                <div className="info-callout left-right"> {service.info} </div>
                                                            </>) : (<></>)
                                                        }
                                                    </div>
                                                    {
                                                        plan.services.indexOf(service.id) > '-1' ?  (
                                                            <div className="right-icon"></div>
                                                        ): (<></>)
                                                    }
                                                </div>
                                            )
                                        }
                                    </a>
                                    {
                                        currentRunningPlan && currentRunningPlan.planId === plan.id && Object.keys(plan.subPlans).length > 0 ? (<>
                                            <div className="cancel-link"><a onClick={(e)=>cancelSubscription(e)} href="/#">Cancel Subscription</a></div>
                                        </>) : (<>
                                        </>)
                                    }
                                    
                                </div>
                                )
                            }
                            </div>
                        </div>                    
                    </div>
                </div>
            </Row>    
        </Container>
        
    </Fragment>
    );
  }
  
  export default ProviderStep4;