import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import PolicyTwo from '../../components/privacy-terms/policy2';
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';


const AcceptablePolicy = (props) => {

  return (
    <Fragment>
      <HeaderMain {...props}/>     
      <PolicyTwo/>
      <Footer/>
    </Fragment>
    );
  }
  
  export default AcceptablePolicy;