import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';

import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';
import TermsOne from '../../components/privacy-terms/terms1';

const TermsOfUse = (props) => {

  return (
    <Fragment>
      <HeaderMain {...props}/>
      <TermsOne/>
      <Footer/>
    </Fragment>
    );
  }
  
  export default TermsOfUse;