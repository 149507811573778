import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import * as moment from 'moment';

const EndorsementInfoModal = (props) => {
    const { data } = props;

    return (
        <Fragment>
            <Container>
                <div className="search-modal-wrap patient-info-modal">
                    <div className="edit-all-filter">
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12 mb-3 mb-md-4">
                                    <div className="provider-wrap">
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col" colspan="2">Endorsement Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" width="150">Provider Name :</th>
                                                    <td>{data.providerName}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Date & Time</th>
                                                    <td>{data.dateEndoresed}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Comment</th>
                                                    <td>{data.message}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </Fragment>
    )

}

export default EndorsementInfoModal;