import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';

import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';
import TermsTwo from '../../components/privacy-terms/terms2';

const AdditionalTerms = (props) => {

  return (
    <Fragment>
      <HeaderMain {...props}/>  
      <TermsTwo/>
      <Footer/>
    </Fragment>
    );
  }
  
  export default AdditionalTerms;