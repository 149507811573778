import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PatientLinks } from '../../linkFile';
import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';


const Errorpage = (props) => {
  const goBack = (e) => {
    e && e.preventDefault();
    props.history.goBack();
  }
  return (
    <Fragment>
      {/* header */}


      <Container fluid className="free-profile">
        <Row>
          <Container>
            <Row>
              <div className="col-12 contactus no-border no-shadow">
                <Row>
                  <div className="error-404 suc-msg">
                    <h3 className="italic error-msg-404">
                      You are not authorized for this page.
                      </h3>
                    <h3 className="error-msg-404">
                      Error Code: 403
                      </h3>
                    <ul>
                      <li><a onClick={goBack} > Previous Page</a></li>
                      <li><Link to={PatientLinks.home} > Home Page</Link></li>
                      <li><Link to={PatientLinks.contactus} > Contact Us</Link></li>
                    </ul>
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>



      {/* footer */}




    </Fragment>
  );
}

export default Errorpage;