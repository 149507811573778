import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import * as moment from 'moment';

const RatingCommnent = (props) => {

    const { data } = props;

    return (
        <Fragment>
            <Container>
                <div className="search-modal-wrap patient-info-modal">
                    <div className="edit-all-filter">
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12 mb-3 mb-md-4">
                                    <div className="rating-detail-wrap">
                                        <div className="rating-detail-head">Comment detail</div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Name:</div>
                                            <div className="rating-comment-right">{`${data.providerInfo ?.providerName ?.firstName} ${data.providerInfo ?.providerName ?.lastName}`}</div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Patient:</div>
                                            <div className="rating-comment-right">{`${data.patientsInfo ?.patientName ?.firstName} ${data.patientsInfo ?.patientName ?.lastName}`}</div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Ratings:</div>
                                            <div className="rating-comment-right">
                                                <span className="rating"><span className={`rating${parseFloat(data.rating.replace('.', ''))}`}></span></span>
                                            </div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Comment:</div>
                                            <div className="rating-comment-right">
                                                {data.message}
                                            </div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Submission Date:</div>
                                            <div className="rating-comment-right">{moment(data ?.createdAt).format('MM/DD/YYYY')}</div>
                                        </div>

                                        <div className="rating-comments-box">
                                            <div className="rating-comment-left">Reviewed:</div>
                                            <div className="rating-comment-right">{data.status == 1 ? "Yes" : "No"}</div>
                                        </div>

                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </Fragment>
    )

}

export default RatingCommnent;