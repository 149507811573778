import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';

import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';
import Referfriend from '../../components/refer-friend/refer-friend';





const ReferFriendPage = () => {

  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <HomeHeader/>
      </Container>
      


      <Container fluid className="">
        <Referfriend/>
      </Container>



      {/* footer */}
      <Container fluid className="footer">
        <Container>
          <Footer/>
        </Container>
      </Container>
      
     
      
    </Fragment>
    );
  }
  
  export default ReferFriendPage;