import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import AuthContext from '../../reactLayer/auth/AuthContext';
import Custommodal from '../modal/modal';
import DoctorReviewModal from '../../components/doctor-review-modal/doctor-review-modal';


const Starts = (props) => {
    const authContext = useContext(AuthContext)
    
    const getData = () => {
        let accountDetails = {};
        if( props.componentName &&  props.componentName == 'patient_appointment' ) {
            accountDetails = {
                accountInfo: {
                    id: props.providerPracticeLocations.users.id,
                    firstName: props.providerPracticeLocations.users.providers.firstName,
                    middleName: props.providerPracticeLocations.users.providers.middleName,
                    lastName: props.providerPracticeLocations.users.providers.lastName,
                    profileImage: props.providerPracticeLocations.users.profileImage,
                },
                medicalSpeciality: {
                    providerMedicalSpecialitiesValue: props.providerMedicalSpecialitiesValue
                }
            }
        } else if ( props.componentName && ( props.componentName == 'sponsored_doctor' || props.componentName == 'searchResult' )) {
            accountDetails = {
                accountInfo: {
                    id: props.id,
                    firstName: props.providers.firstName,
                    middleName: props.providers.middleName,
                    lastName: props.providers.lastName,
                    profileImage: props.profileImage,
                },
                medicalSpeciality: {
                    providerMedicalSpecialitiesValue: props.providerMedicalSpecialitiesValue
                }
            }
        }  else if ( props.componentName && props.componentName == 'profile' ) {
            accountDetails = {
                accountInfo: {
                    id: props.accountInfo.id,
                    firstName: props.accountInfo.firstName,
                    middleName: props.accountInfo.middleName,
                    lastName: props.accountInfo.lastName,
                    profileImage: props.accountInfo.profileImage,
                },
                medicalSpeciality: {
                    providerMedicalSpecialitiesValue: props.medicalSpeciality.providerMedicalSpecialitiesValue
                }
            }
        }else {

        }
        
        return accountDetails;
    }
    const accountData = getData();
    const modalList = ['reviewModal'];
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [ userRating, setuserRating ] = useState({rating:{value:'0'}})

    useEffect(() => {
        setuserRating({
            rating: {  
                        value: props.rating ? props.rating : '',
                        tempvalue: '',
                        validation: ['blank'],
                        errorMsg: ['Please rate'],
                        errorKey: 'rating',
                        rating: false
                    }
        })
    },[props])

    const setrating = (r) => {
         let urating = userRating; 
         urating['rating']['value'] = r;
         urating['rating']['tempvalue'] = 0;
         urating['rating']['rating'] = false;
         setuserRating({...urating});
    }
    const settemprating = (r) => {
         let urating = userRating; 
         urating['rating']['tempvalue'] = r;
         urating['rating']['rating'] = false;
         setuserRating({...urating});
    }

    const openModal = (e) => {
        if( authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId == '4' ) {
            openProfileTabModal('reviewModal');
        } else if ( authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId == '3' ) {
            e.preventDefault();
        } else {
            // openLoginModal();
            authContext.showLoginModal({
                modalName: 'login',
                redirectUrl: window.location.href 
            });
        }
    }

    const openProfileTabModal = (modalName) => {
        settabvisibleComponent(modalName)
        authContext.showModal()
    }
    return (
        <Fragment>
            { modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'reviewModal' ? <DoctorReviewModal {...accountData} getProfile={props.getProfile} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <div className="stars-outer"> 
                {
                    [1,2,3,4,5].map((d,i) =>
                        <span 
                            style={{ cursor : authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId == '3' ? 'default' : 'pointer'}}
                            className={userRating.rating.value >= d ? 'star full' :  userRating.rating.tempvalue >= d ? 'star full' : 'star'} 
                            onMouseLeave={(e) => authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId == '3' ? e.preventDefault() :  settemprating(0)} 
                            onMouseEnter={(e) => authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId == '3' ? e.preventDefault() : settemprating(d)}
                            onClick={(e)=> openModal(e) }>
                        </span>
                    )
                }
            </div>
        </Fragment>
    )
    
}

export default Starts;