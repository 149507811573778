import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { PatientLinks } from '../../linkFile';






const CovidSelfAssessment5 = (props) => {
  const authContext = useContext(AuthContext);
  const step5 = authContext['covidOption'] && authContext['covidOption']['step-5'] ? authContext['covidOption']['step-5'] : false
  return (
    <Fragment>
      <Container fluid className="covid-screens-wrap">
        <Row>
          <Container>
            <Row>
                <div className="col-12">
                    <h1>Self Assessment</h1>
                    <div className="progress-outer">
                        <div className="progress-inner" style={{width: "64%"}}></div>
                    </div>
                </div>                
            </Row>
            <Row>
              <div className="col-12">
                <div className="covid-self-outerbox">
                    <div className="covid-question">
                      <span>5.</span>
                      In the last two weeks have you worked or volunteered in a hospital, emergency room, clinic, medical office, long-term care facility or nursing home, ambulance service, first responder services, or any health care setting or taken care of patients as part of your work?
                    </div>
                    <div className="covid-question-options">
                      <div className="cus-radio">
                          <input type="radio" id="opt1" checked={step5 === 'opt1' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt1"/>
                          <label htmlFor="opt1">Yes</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt2" checked={step5 === 'opt2' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt2"/>
                          <label htmlFor="opt2">No</label>
                      </div>
                      <small className="error-input">{ props.error.step5 }</small>
                    </div>
                    <div className="covid-buttons">
                      <span className="covid-bkbtn" data-prev-page={PatientLinks.covid_self_4} onClick={(e)=>{ props.goPrevious(e) }}>Back</span>
                      <span className="nextbtn">
                        <input type="button" className="next-btn-pro" onClick={(e)=>{ props.onNext(e) }}  value="Next"/>
                      </span>
                    </div>
                </div>                
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
    );
  }
  
  export default CovidSelfAssessment5;