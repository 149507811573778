import React, { Fragment, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Modal } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { useHistory, useLocation } from "react-router-dom";
import Msgmodal from './Msgmodal';


const MsgResponseModal = (props) => {

  // console.log('modal props', props);
  const history = useHistory();
  const location = useLocation();
  // console.log('location.pathname==>',location.pathname);
  const authContext = useContext(AuthContext);

  const btnName = props.btnname ? props.btnname : false;
  const btnCancel = props.btncancel ? props.btncancel : false;
  const showMsg = props.msg ? props.msg : false;
  const type = props.type;

  const handleClose = () => {
    if (props.unsetComponent && typeof (props.unsetComponent) == 'function') {
      props.unsetComponent('');
    }

    if (props.closeredirection) {
      let modalName;
      if (props.modalName) {
        modalName = props.modalName;
      }
      history.replace({ pathname: props.closeredirection, state: { prevPath: location.pathname, modalName: modalName } })
    }
    if (props.closeaction && typeof (props.closeaction === 'function')) {
      props.closeaction()
    }
    authContext.hideResponseModal();
  };

  const handleButton = () => {
    if (props.unsetComponent && typeof (props.unsetComponent) == 'function') {
      props.unsetComponent('');
    }
    if (props.redirection) {
      history.push({ pathname: props.redirection, state: { prevPath: location.pathname, modalName: 'twostep' } })
    }
    if (props.action && typeof (props.action === 'function')) {
      props.action()
    }
    authContext.hideResponseModal();
  };
  const data = { btnname: btnName, msg: showMsg, type: type, btnclick: handleButton, btncancel: btnCancel, close: handleClose };

  useEffect(() => {
    setTimeout(() => {
      props.autoclose || props.autoclose !== 'false' && handleClose();
    }, 3500);
  }, [])


  return (
    <Fragment>

      <Modal dialogClassName="loader-modal" show={authContext.responseModal} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header>
        </Modal.Header>
        <Msgmodal {...data} />

        {/* <button onClick={authContext.setLoader}>Check loader</button> */}
      </Modal>
    </Fragment>
  );
}

export default MsgResponseModal;