import React, { useState, useEffect, memo, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row } from "react-bootstrap";
import Global from "../../reactLayer/global";
import AuthContext from "../../reactLayer/auth/AuthContext";

const SearchFilter = (props) => {
  const { setModalStatus } = props;
  const authContext = useContext(AuthContext);
  const [medicalCredentials, setmedicalCredentials] = useState([]);
  // let medicalSpeciality = [];
  const [medicalSpeciality, setmedicalSpeciality] = useState([]);
  const [filtermedicalSpeciality, setfiltermedicalSpeciality] = useState([]);
  const [selectedMC, setselectedMC] = useState("medical");
  const [searchfilter, setsearchfilter] = useState({
    firstName: "",
    lastName: "",
    cityOrZip: "",
    speciality: [],
    languages: [],
    insurances: [],
    focusAreas: "",
    offset: 0,
    limit: 5,
  });
  const modifyFilter = (e) => {
    let name = e.currentTarget.getAttribute("name"),
      value = e.currentTarget.value,
      sfilters = searchfilter;
    if (name === "speciality") {
      let arrayIndex = sfilters.speciality.indexOf(value);
      if (e.currentTarget.checked) {
        if (arrayIndex == "-1") {
          sfilters.speciality.push(value);
        }
      } else {
        if (arrayIndex > "-1") {
          sfilters.speciality.splice(arrayIndex, 1);
        }
      }
      setsearchfilter({ ...sfilters });
    } else {
      sfilters[name] = value;
      setsearchfilter({ ...sfilters });
    }
  };

  
  const applyFilter = () => {
    setModalStatus(false);
    props.setfilter && props.setfilter({ ...searchfilter, callApi: true });
  };

  const onPressEnter = (e) => {
    if( e && e.keyCode == 13 ) {
      applyFilter()
    }
  }
  const setFilterSpl = () => {
    let fs = [];

    medicalSpeciality.map((ms, msi) => {
      if (ms.category === selectedMC) {
        fs.push(ms);
      }
    });
    setfiltermedicalSpeciality([...fs]);
  };

  const changeMedicalCred = (e) => {
    let value = e.currentTarget.value;
    setselectedMC(value);
    setFilterSpl();
  };

  const getApiData = async () => {
    let medicalCred = await Global.getMedicalCredentials();
    // console.log('medical cred', medicalCred);
    setmedicalCredentials(medicalCred);
    if (medicalCred.length) {
      let tempdata = medicalCred[0]["category"];
      setselectedMC(tempdata);
    }

    let medicalSpec = await Global.getMedicalSpeciality();
    // console.log("medical speciality", medicalSpec);
    // setmedicalSpeciality(medicalSpec);
    // medicalSpeciality = medicalSpec;
    setmedicalSpeciality([...medicalSpec]);
    
  };
  
  

  useEffect(() => {
    getApiData();
    props.filter && setsearchfilter(props.filter);
  }, []);

  useEffect(() => {
    setFilterSpl();
  },[medicalSpeciality])

  return (
    <Container>
      <Row>
        <div className="col-12 search-filter">
          <div className="search-filter-box">
            <h4>All Filters</h4>
            <div className="modal-fields">
              <Row>
                <div className="col-12 px-0">
                  <div className="modal-subhead">Provider Name</div>
                </div>
              </Row>
              <Row className="modal-border">
                <div className="col-md-6 pl-0">
                  <label htmlFor="fName" className="pro-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    onChange={(e) => modifyFilter(e)}
                    onKeyDown={onPressEnter}
                    value={searchfilter.firstName}
                    className="input-pro"
                    placeholder="First Name"
                    autoComplete="off"
                  />
                  <small className="error-input"></small>
                </div>
                <div className="col-md-6 pr-md-0 pr-3 pl-md-2 pl-0">
                  <label htmlFor="lName" className="pro-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    onChange={(e) => modifyFilter(e)}
                    onKeyDown={onPressEnter}
                    value={searchfilter.lastName}
                    className="input-pro"
                    placeholder="Last Name"
                    autoComplete="off"
                  />
                  <small className="error-input"></small>
                </div>
              </Row>
              <Row>
                <div className="col-12 px-0">
                  <div className="modal-subhead">Location</div>
                </div>
              </Row>
              <Row className="modal-border">
                <div className="col-md-6 pl-0">
                  <label htmlFor="zCode" className="pro-label">
                    City or Zip Code
                  </label>
                  <input
                    type="text"
                    name="cityOrZip"
                    onChange={(e) => modifyFilter(e)}
                    value={searchfilter.cityOrZip}
                    className="input-pro"
                    placeholder="12245"
                    autoComplete="off"
                  />
                  <small className="error-input"></small>
                </div>
              </Row>
              <Row>
                <div className="col-12 px-0">
                  <div className="modal-subhead">
                    Health Care Professional Type
                  </div>
                </div>
              </Row>
              <Row className="modal-border">
                <div className="col-md-6 mt-2 pl-0">
                  <div className="myform">
                    <select
                      className="select-service"
                      onChange={(e) => changeMedicalCred(e)}
                      value = {selectedMC}
                    >
                      <option key={"mc-" + 0} value="medical">
                        Medical
                      </option>
                      <option key={"mc-" + 1} value="dental">
                        Dental
                      </option>
                    </select>
                  </div>
                  <small className="error-input"></small>
                </div>
              </Row>
              <Row>
                <div className="col-12 px-0">
                  <div className="modal-subhead">Filter by Specialty</div>
                </div>
              </Row>
              <Row>
                <div className="col-12 mt-2 pl-0">
                  <div className="speciality-filter">
                    {filtermedicalSpeciality.map((ms, msi) => (
                      <div key={"ms-" + msi} className="cus-check-rev">
                        <input
                          type="checkbox"
                          id={"ms-" + ms.id}
                          name="speciality"
                          value={ms.name}
                          checked={
                            searchfilter.speciality.indexOf(ms.name) > "-1"
                              ? true
                              : false
                          }
                          onChange={(e) => modifyFilter(e)}
                        />
                        <label htmlFor={"ms-" + ms.id}>{ms.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-12 text-center mt-3">
                  <input
                    type="button"
                    className="next-btn-pro"
                    onClick={applyFilter}
                    value="Apply Filters"
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

SearchFilter.defaultProps = {
  setModalStatus: false,
};

export default memo(SearchFilter);
