import React, { Fragment,useEffect,useContext,useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { ProviderLinks } from '../../linkFile';
import { useHistory } from "react-router-dom";
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import moment from 'moment'

const Receipt = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [checkLoggedIn,setLoggedIn] = useState(false)
  const [receiptData,setReceiptData] = useState({
      paid_amount_currency:"",
      top_address:""
    })
  useEffect(()=>{
      console.log('authContext.isProfileCompleted',authContext.user)
    if(authContext.user.isProfileCompleted === true){
        setLoggedIn(true)
    }
    getReceiptData()
  },[])

  const getReceiptData = async () => {
    authContext.setLoader();
    
    let url = 'providers/getProviderInvoiceDetails';
    const formData = {
        planId:props.match.params.planId
    }
    const submitData = {
        headers: {
            'Content-Type': 'application/json',
            'access_token': authContext.token
        },
        url: url,
        body: JSON.stringify(formData),
    }
    let result = await NetworkLayer.postData(submitData);
    if(result.body.result){
        setReceiptData(result.body.result)
    }
  }

 const printPdf = (elementId, uniqueIframeId) => {
    const content = document.getElementById(elementId)
    let pri
    if (document.getElementById(uniqueIframeId)) {
        pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
        const iframe = document.createElement('iframe')
        iframe.setAttribute('title', uniqueIframeId)
        iframe.setAttribute('id', uniqueIframeId)
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        
        document.body.appendChild(iframe)
        pri = iframe.contentWindow
    }
    pri.document.open()
    let contentHtml = `<html><head>
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <style>
      * {
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
        color-adjust: exact !important;  /*Firefox*/
        }
        .next-btn-pro{
            display:none;
        }
        .receipt-box {
            border: 1px solid #dcdcdc;
            position: relative;
            box-shadow: 0 4px 14px 0 #e4eef3;
            background-color: #ffffff;
            position: relative;
            border-radius: 10px;
            padding: 45px 45px 30px;
        }
        .receipt-box-outer {
            max-width: 100%;
        }
        .receipt-greybox {
            background-color: #f4f4f4;
            color: #606060;
            padding: 25px;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            letter-spacing: 0.7px;
            margin-top: 30px;
        }
        .receipt-top-head {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            justify-content: space-between;
            font-size: 20px;
            letter-spacing: 0.8px;
            line-height: 1.5;
            color: #000000;
            font-weight: 500;
        }
        .receipt-info-table {
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            margin: 30px 0 15px;
            padding: 30px 0;
        }
        .receipt-top-left {
            width: 270px;
        }
        .receipt-top-right {
            text-align: right;
        }
        .receipt-top-right button {
            display:none;
        }
        .right-text {
            margin-top: 52px;
        }
        .logo-receipt {
            margin-bottom: 15px;
        }
        .receipt-head-address {
            padding: 0;
        }
        .info-table-inner:first-child {
            margin-bottom: 10px;
        }
        .info-table-inner {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            letter-spacing: 0.7px;
            justify-content: space-between;
            line-height: 1.7;
        }
        .info-table-inner:first-child {
            margin-bottom: 10px;
        }
        .info-table-inner:first-child span {
            font-size: 20px;
            font-weight: 500;
        }
        .info-table-inner span:first-child {
            width: 24%;
            text-align: left;
        }
        .info-table-inner span {
            width: 14%;
            text-align: left;
        }
        .recept-des-inner {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            letter-spacing: 0.7px;
            justify-content: space-between;
            line-height: 1.7;
            margin-bottom: 20px;
        }
        .recept-des-inner:first-child {
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 15px;
            margin-bottom: 30px;
        }
        .recept-des-inner:first-child span {
            font-size: 20px;
            font-weight: 500;
        }
        .recept-des-inner span:nth-child(1) {
            width: 15%;
            text-align: left;
        }
        .recept-des-inner span:nth-child(2) {
            width: 25%;
            text-align: left;
        }
        .receipt-total-inner {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            justify-content: flex-end;
            font-size: 20px;
            margin-bottom: 10px;
        }
        .receipt-total-inner span {
            width: 20%;
            text-align: right;
        }
        .receipt-total-inner span:first-child {
            font-weight: 500;
        }
        .receipt-greybox {
            background-color: #f4f4f4;
            color: #606060;
            padding: 25px;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            letter-spacing: 0.7px;
            margin-top: 30px;
        }

    </style></head><body>`;
    contentHtml+=content.innerHTML+"</body></html>";
    pri.document.write(contentHtml)
    pri.document.body.style.fontFamily="'Roboto', sans-serif";  
    pri.document.close()
    pri.focus()
    pri.print()
}

  return (
    <Fragment>
        <Container>
            <Row>
                <div className="col-12 px-0 px-xl-3">
                    <div className="payment-receipt-wrap">
                        <h1>Payment Confirmed</h1>
                        <div className="receipt-subtext">
                            Thank you for choosing DocScheduler to help grow your business! You will receive an email confirmation with your order details at {authContext.user.accountInfo.email}
                        </div>
                        <div className="receipt-box" id="receipt-box">
                            <div className="receipt-box-outer">
                                <div className="receipt-inner">
                                    {/* Receipt head */}
                                    <div className="receipt-top-head">
                                        <div className="receipt-top-left">
                                            <div className="logo-receipt">
                                                <img src="/assets/images/logo-receipt.png"/>
                                            </div>
                                            <div className="receipt-head-address">
                                                {receiptData.top_address ? receiptData.top_address : "--"}
                                            </div>
                                        </div>
                                        <div className="receipt-top-right">
                                            <input type="button" className="next-btn-pro" onClick={()=>printPdf('receipt-box',1)} value="Print"/>
                                            <div className="right-text">Federal Tax ID: {receiptData.federal_tax_id ? receiptData.federal_tax_id : 'XX-XXXXXXX'}</div>
                                        </div>
                                    </div>
                                    {/* Receipt info */}
                                    <div className="receipt-info-table">
                                        <div className="info-table-inner">
                                            <span className="receipt-billed">Billed To:</span>
                                            <span className="receipt-date">Date:</span>
                                            <span className="receipt-method">Method:</span>
                                            <span className="receipt-recpt">Receipt #:</span>
                                            <span className="receipt-invoice">Invoice #:</span>
                                        </div>
                                        <div className="info-table-inner">
                                            <span className="receipt-billed">
                                            {receiptData.billed_to}
                                            </span>
                                            <span className="receipt-date">{moment(receiptData.billed_date).format('DD/MM/YYYY')}</span>
                                            <span className="receipt-method">{receiptData.cardType} *****{receiptData.cardNumber ? receiptData.cardNumber.substring(receiptData.cardNumber.length-4) : "--"}</span>
                                            <span className="receipt-recpt">{receiptData.receipt_id ? receiptData.receipt_id : '--'}</span>
                                            <span className="receipt-invoice">{receiptData.invoice_id ? receiptData.invoice_id : '--'}</span>
                                        </div>
                                    </div>
                                    {/* Receipt description */}
                                    <div className="receipt-description-table">
                                        <div className="recept-des-inner">
                                            <span>Item</span>
                                            <span>Description</span>
                                            <span style={{textAlign:'center'}}>Rate</span>
                                            <span style={{textAlign:'center'}}>Quantity</span>
                                            <span>Price</span>
                                        </div>
                                        <div className="recept-des-inner">
                                            <span>1</span>
                                            <span>{receiptData.planName} From {moment(receiptData.plan_start_date).format("MMM DD, YYYY")} to {moment(receiptData.plan_end_date).format("MMM DD, YYYY")}</span>
                                            <span style={{textAlign:'center'}}>{receiptData.paid_amount ? receiptData.paid_amount_currency.toUpperCase()+' '+receiptData.paid_amount.toLocaleString("en-US") : '--'}</span>
                                            <span style={{textAlign:'center'}}>1</span>
                                            <span>{receiptData.paid_amount ? receiptData.paid_amount_currency.toUpperCase()+' '+receiptData.paid_amount.toLocaleString("en-US") : '--'}</span>
                                        </div>
                                    </div>
                                    {/* Receipt total */}
                                    <div className="receipt-total-wrap">
                                        <div className="receipt-total-inner">
                                            <span>Subtotal:</span>
                                            <span>{receiptData.paid_amount ? receiptData.paid_amount_currency.toUpperCase()+' '+receiptData.paid_amount.toLocaleString("en-US") : '--'}</span>
                                        </div>
                                        <div className="receipt-total-inner">
                                            <span>Sales Tax: 0%:</span>
                                            <span>$0.00</span>
                                        </div>
                                        <div className="receipt-total-inner">
                                            <span>Invoice:</span>
                                            <span>$0.00</span>
                                        </div>
                                        <div className="receipt-total-inner">
                                            <span>Payment:</span>
                                            <span>{receiptData.paid_amount ? receiptData.paid_amount_currency.toUpperCase()+' '+receiptData.paid_amount.toLocaleString("en-US") : '--'}</span>
                                        </div>
                                        <div className="receipt-total-inner">
                                            <span>Balance:</span>
                                            <span>$0.00</span>
                                        </div>
                                    </div>                                    
                                </div>                            
                            </div>
                            {/* Receipt grey box */}
                            {
                                receiptData.message ? 
                                <div className="receipt-greybox">
                                    {receiptData.message}
                                </div>
                                :
                                ""
                            }
                        </div>
                        <div className="receipt-bott-button">
                            <input type="button" className="next-btn-pro" onClick={()=>history.push({pathname: checkLoggedIn ? ProviderLinks.dashboard : '/provider/two-step-verification'})} value={checkLoggedIn ? "My Provider Dashboard" : "Click here to login"}/>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    </Fragment>
    );
  }
  
  export default Receipt;