import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import Custommodal from '../../../global/modal/modal';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg';
import { ReactComponent as SelectIcon } from '../../../admin/assets/images/list-triangle.svg';
import RatingExport from './rating-export-modal';
import NetworkLayer from '../../../reactLayer/Network/Network';
import * as moment from 'moment';
import Calendar from 'react-calendar';

export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};



const RatingFilter = (props) => {
    const { search } = props;
    const authContext = useContext(AuthContext);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const modalList = ['ratingExport'];
    const [filtervalue, setFiltervalue] = useState();
    const [planData, setPlanData] = useState("");

    const [showCal, setshowCal] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [formstartDate, setFormStartDate] = useState("");
    const [formendDate, setFormEndDate] = useState("");
    const calendarRef = useRef(null);

    const openProfileTabModal = (modalName) => {
        settabvisibleComponent(modalName)
        authContext.showModal()
    }

    const getPlansDetails = async () => {

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/plan/list',
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        let finalResult = [];
        if (result.status) {

            result.result.map(item => {
                let temp = {
                    id: item.id,
                    name: item.planName
                }
                finalResult.push(temp);
            });

            setPlanData(finalResult);
        }

    }

    const onInputChange = (e) => {
        e.preventDefault();
        setFiltervalue(e.target.value);
    }

    const debounceGet = useRef(
        debounce((nextValue) => {
            search(nextValue)
        }
            , 1000)
    ).current;

    useEffect(() => {
        if (filtervalue == "" && search) {
            debounceGet(filtervalue);
            // search(filtervalue);
        } else {
            if (filtervalue && filtervalue.length > 2) {
                debounceGet(filtervalue);
                // search(filtervalue);
            }
        }
    }, [filtervalue])

    useEffect(() => {
        getPlansDetails();
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setshowCal(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [calendarRef])

    const onChangeDate = (value, event) => {
        setStartDate(moment(value[0]).format('M/D/Y'));
        setFormStartDate(moment(value[0]).format('Y-M-D'))
        setEndDate(moment(value[1]).format('M/D/Y'));
        setFormEndDate(moment(value[1]).format('Y-M-D'))
        setshowCal(!showCal)
        search("",moment(value[0]).format('YYYY-MM-DD')+' - '+moment(value[1]).format('YYYY-MM-DD'));
    }

    return (
        <Fragment>
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'ratingExport' ? <RatingExport plans={planData} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <div className="rating-filter-box">
                <div className="rating-search">
                    <div className="table-sort-filter">
                        <span className="filter-input">
                            <input type="text" class="input-pro" name="search" id="search" placeholder="Search by provider name, patient name" autocomplete="off" onChange={(e) => onInputChange(e)} />
                            <i className="search-icon"><SearchIcon onClick={() => search(filtervalue)} /></i>
                        </span>
                    </div>
                </div>
                <div className="rating-filter">
                    <div className="table-sort-filter">
                        <span className="filter-input" onClick={() => { setshowCal(!showCal) }}>
                            <input type="text" class="input-pro" name="date" id="date" placeholder="Filter By Date" value={startDate ? `${startDate} to ${endDate}` : ''} />
                            <div className="search-icon"><div className="calender-icon"></div></div>
                        </span>
                    </div>
                    {
                        showCal && (
                            <div ref={calendarRef} class="ratings-calendar" style={{ 'zIndex': '10', position: 'relative', bottom: '-1em' }}>
                                <Calendar
                                    onChange={onChangeDate}
                                    maxDate={new Date()}
                                    calendarType={'US'}
                                    selectRange={true}
                                    returnValue={"range"}
                                />
                            </div>
                        )
                    }
                </div>
                {/* <div className="rating-sort">
                    <div className="table-sort-filter">
                        <span className="sort-select">
                            <select class="input-pro sort-select">
                                <option value="">Sort By</option>
                                <option value="">Sort By</option>
                                <option value="">Sort By</option>
                                <option value="">Sort By</option>
                            </select>
                            <span className="select-icon"><SelectIcon /></span>
                        </span>
                    </div>
                </div> */}
                <div className="rating-export">
                    <div className="table-sort-filter">
                        <Link onClick={(e) => { e.preventDefault(); openProfileTabModal("ratingExport") }} className="link-btn">Export</Link>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default RatingFilter;