import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';





const ProviderHeading = (props) => {

    const redirectToback= (e) => {
        e.preventDefault();
        console.log('props- on provider heading-->', props);
        debugger;
        props && props.history && props.history.goBack();
    }
    
    const changeStatus = () => {
        props && props.setClaim();
    }

    return (
        <Container>
            <Row>
                <div className="col-12">
                    <div className="provider-profile-heading">
                        <h1>Provider Profile</h1>
                        <div className="back-btn">
                            <a href="/#" onClick={(e)=> redirectToback(e)} ><img src="/assets/images/bk-arrow.svg" className="bk-arrow" alt="Doctor" /> Back to search results</a>
                            {
                                props.claimProfile?.accountInfo?.claimProfile === true ?
                                    <span>Is this your profile? <a href="/#" onClick={(e)=>{ e.preventDefault(); changeStatus()}}>Claim it here</a></span>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    )
    
}

export default ProviderHeading;