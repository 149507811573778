import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as SelectIcon } from '../../../admin/assets/images/list-triangle.svg';
import { ReactComponent as FilterIcon } from '../../../admin/assets/images/filter.svg';
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg';
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import { CSVLink } from "react-csv";
import { ProviderLinks, PatientLinks, AdminLinks } from '../../../linkFile';

export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};

const ManagementFilter = (props) => {
    const authContext = useContext(AuthContext);
    const { search } = props;
    const [filtervalue, setFiltervalue] = useState();
    const [data, setData] = useState([]);
    const csvLinkEl = useRef();
    const [header, setHeader] = useState([{ label: "", key: "name" }]);

    const onInputChange = (e) => {
        e.preventDefault();
        setFiltervalue(e.target.value);
    }

    const debounceGet = useRef(
        debounce((nextValue) => search(nextValue), 1000)
    ).current;

    const getExportList = async () => {
        setHeader([
            { label: "id", key: "id" },
            { label: "Patient Name", key: "Name" },
            { label: "Age", key: "age" },
            { label: "Gender", key: "gender" },
            { label: "Joined Date", key: "createdAt" },
            { label: "Email", key: "email" },
            { label: "Medical Ins. Name", key: "medicalIns" },
            { label: "Dental Ins. Name", key: "dentalIns" },
            { label: "Location", key: "location" },
            { label: "Last Appt. Request Date", key: "lastApptDate" }
        ]);

        let url = 'admin/exportPatientCSV';

        const formData = {
            searchValue: filtervalue
        }
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: url,
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 


        authContext.setLoader();
        try {
            let result = await NetworkLayer.postData(submitData);
            console.log(result ?.result);
            let datares = result ?.body ?.result ?.map(item => ({
                id: item.id,
                Name: `${item ?.patients ?.firstName} ${item ?.patients ?.lastName}`,
                email: item.email,
                gender: item ?.patients ?.gender,
                createdAt: item ?.patients ?.createdAt,
                age: item ?.patients ?.age,
                medicalIns: item ?.patientMedicalInsurances[0] ?.insurances ?.name,
                dentalIns: item ?.patientDentalInsurances[0] ?.insurances ?.name,
                lastApptDate: item ?.patients ?.appointmentDate,
                location: `${item ?.patients ?.cities ?.name}, ${item ?.patients ?.cities ?.states ?.name}`,
            }));

            setData(datares);

        } catch (err) {

        }
        authContext.unsetLoader();
    }

    const downloadReport = async () => {
        let result = await getExportList();
        console.log(csvLinkEl.current.link);
        csvLinkEl.current.link.click();
    }

    useEffect(() => {
        if (filtervalue == "" && search) {
            debounceGet("");
        } else {
            if (filtervalue && filtervalue.length > 2) {
                debounceGet(filtervalue);
            }

        }

    }, [filtervalue])

    return (
        <Fragment>
            <Row className="table-sort-filter">
                <div className="col-xl-9 filter-left-wrap">
                    <form>
                        <div className="sort-filterwrap">
                            <span className="filter-input mb-3 mr-md-2">
                                <input type="text" class="input-pro" name="usernae" id="username" placeholder="Search ..." autocomplete="off" value={filtervalue} onChange={(e) => onInputChange(e)} />
                                <i className="search-icon" style={{ cursor: 'pointer' }}><SearchIcon onClick={() => search(filtervalue)} /></i>
                            </span>
                            {/* <span className="sort-select mb-3 mr-2">
                                <select class="input-pro sort-select-inner">
                                    <option value="">Sort By</option>
                                    <option value="">Sort By</option>
                                    <option value="">Sort By</option>
                                    <option value="">Sort By</option>
                                </select>
                                <span className="select-icon"><SelectIcon /></span>
                            </span> */}
                            <span className="sort-select mb-3">
                                <button className="filter-btn" type="button">
                                    <span className="filer-icon"><FilterIcon /></span><span>All Filters</span>
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
                <div className="col-xl-3 text-right filter-right-wrap">
                    <Link to={AdminLinks.admin_patient_add_detail} className="add-patient-btn">+Add Patient</Link>
                    <Link to={"\#"} className="link-btn report-btn mb-3">Report</Link>
                    <Link to={"\#"} className="link-btn export-btn ml-2 mb-3" onClick={downloadReport}>Export</Link>
                </div>
                <CSVLink
                    headers={header}
                    filename={`Patients-list.csv`}
                    data={data}
                    ref={csvLinkEl}
                />
            </Row>
        </Fragment>
    );
}

export default ManagementFilter;