import React, { Fragment, useState, useContext, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ProviderLinks, PatientLinks } from "../../linkFile";
import AuthContext from "../../reactLayer/auth/AuthContext";
import Loadermodal from "../loader-modal/loader-modal";

const Footer = () => {
  const authContext = useContext(AuthContext);
  const user = authContext.user ? authContext.user : {};
  const [activeKey, setActiveKey] = useState("");
  const history = useHistory();

  const onFooterAccordionToggle = (e) => {
    // console.log('e', window.innerWidth);
    e.preventDefault();
    if (window.innerWidth < 1024) {
      let key = e.currentTarget.getAttribute("data-controls");
      if (key == activeKey) {
        setActiveKey("");
      } else {
        setActiveKey(key);
      }
    } else {
      return false;
    }
  };

  const navigatePage = useCallback(
    (e, type) => {
      e.preventDefault();
      const path = `/patient/searchby/${type}`;
      history.push({
        pathname: path,
      });
    },
    [history]
  );

  return (
    <Fragment>
      <Container fluid className="footer">
        <Container>
          {authContext.loading ? <Loadermodal /> : <></>}
          <div className="row">
            <div className="col-lg-3 footer-inner accordion-group">
              <div className="nav1 ">
                <h4
                  className={
                    activeKey == "panel-" + 0
                      ? "accord panel-title active p-0"
                      : " accord panel-title p-0"
                  }
                  data-controls={"panel-0"}
                  onClick={(e) => {
                    onFooterAccordionToggle(e);
                  }}
                >
                  About DocScheduler
                </h4>
                <ul
                  id={"panel-0"}
                  aria-labelledby={"control-panel-" + 0}
                  role="tablist"
                  aria-hidden={activeKey == "panel-" + 0 ? "false " : "true"}
                  className={
                    activeKey == "panel-" + 0 ? "" : " hide-for-medium"
                  }
                >
                  <li>
                    <Link to={PatientLinks.aboutus}> About Us</Link>
                  </li>
                  <li>
                    <Link to={{pathname: `${ProviderLinks.freeprofile}`, query: 'commentSection'}}>
                      {" "}
                      Frequently Asked Questions
                    </Link>
                  </li>
                  {/* <li>                  
                    <Link to={{pathname: `${ProviderLinks.freeprofile}`, query: 'howWorks'}}>
                      {" "}
                      How DocScheduler Works
                    </Link>
                  </li> */}
                  <li>                  
                    <Link to={PatientLinks.how_works}>
                      {" "}
                      How DocScheduler Works
                    </Link>
                  </li>
                  <li>
                    <Link to={PatientLinks.contactus}> Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 footer-inner accordion-group">
              <div className="nav1 ">
                <h4
                  className={
                    activeKey == "panel-" + 1
                      ? "accord panel-title active p-0"
                      : " accord panel-title p-0"
                  }
                  data-controls={"panel-1"}
                  onClick={(e) => {
                    onFooterAccordionToggle(e);
                  }}
                >
                  Explore DocScheduler
                </h4>
                <ul
                  id={"panel-1"}
                  aria-labelledby={"control-panel-" + 1}
                  role="tablist"
                  aria-hidden={activeKey == "panel-" + 1 ? "false " : "true"}
                  className={
                    activeKey == "panel-" + 1 ? "" : " hide-for-medium"
                  }
                >
                  <li>
                    <Link
                      className="footerLink"
                      onClick={(e) => navigatePage(e, "specialty")}
                      to=""
                    >
                      Search Doctors by Specialty
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footerLink"
                      to=""
                      onClick={(e) => navigatePage(e, "insurance")}
                    >
                      Search Doctors by Insurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footerLink"
                      to=""
                      onClick={(e) => navigatePage(e, "language")}
                    >
                      Search Doctors by Language
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footerLink"
                      to=""
                      onClick={(e) => navigatePage(e, "location")}
                    >
                      Search Doctors by City
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 footer-inner accordion-group">
              <div className="nav1 ">
                <h4
                  className={
                    activeKey == "panel-" + 2
                      ? "accord panel-title active p-0"
                      : " accord panel-title p-0"
                  }
                  data-controls={"panel-2"}
                  onClick={(e) => {
                    onFooterAccordionToggle(e);
                  }}
                >
                  DocScheduler Terms
                </h4>
                <ul
                  id={"panel-2"}
                  aria-labelledby={"control-panel-" + 2}
                  role="tablist"
                  aria-hidden={activeKey == "panel-" + 2 ? "false " : "true"}
                  className={
                    activeKey == "panel-" + 2 ? "" : " hide-for-medium"
                  }
                >
                  <li>
                    <Link to={PatientLinks.term_of_user}>Terms of Use</Link>
                  </li>
                  <li>
                    <Link to={PatientLinks.privacy_policy}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={PatientLinks.acceptable_policy}>Acceptable Use Policy</Link>
                  </li>
                  <li>
                    <Link to={PatientLinks.additional_terms}>Additional Terms</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 footer-inner">
              <h4>Are you a Doctor or Dentist?</h4>
              <div className="list-your-doc">
                {user.accountInfo && user.accountInfo.roleId === 3 ? (
                  <>
                    <a href="/#" onClick={(e) => e.preventDefault()}>
                      List Your Practice
                      <br />
                      on DocScheduler
                    </a>
                  </>
                ) : (
                  <>
                    <Link to={ProviderLinks.freeprofile}>
                      List Your Practice
                      <br />
                      on DocScheduler
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="footer-bott-txtwrap">
              <div className="footer-logo">
                {/* <Link to={PatientLinks.login}><img src="/assets/images/footer-logo.png" alt="logo" className="scale" /></Link> */}
                <Link to={PatientLinks.login}>
                  <div className="logo-main">
                    <img
                      src="/assets/images/logo-icon-white.svg"
                      className="logo-icon"
                      alt="DocScheduler"
                    />
                    <span>DocScheduler</span>
                  </div>
                </Link>
              </div>
              <p>
                Copyright &copy; {new Date().getFullYear()} DocScheduler, Inc.,
                DocScheduler, and related marks are registered trademarks of
                DocScheduler.
              </p>
              <p>
              Use of this website and any information contained herein is governed by the DocScheduler Policies and Terms. The content on DocScheduler does not provide medical advice. Always consult a medical provider for diagnosis and treatment.
              </p>
            </div>
          </div>
        </Container>
      </Container>
    </Fragment>
  );
};

export default Footer;
