import React, { Fragment, useContext, useState } from 'react';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { ProviderLinks, PatientLinks } from "../../linkFile";
import NetworkLayer from '../../reactLayer/Network/Network';
import Global from '../../reactLayer/global';
import AuthContext from '../../reactLayer/auth/AuthContext';
import InputValidation from '../../reactLayer/validation';
import HeaderMain from '../../global/header/header';
import Footer from '../../global/footer/footer';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import { ReactComponent as Checkmark } from '../../Icons/checkmark.svg';
import ReCAPTCHA from "react-google-recaptcha";

const AboutUs = (props) => {
  const recaptchaRef = React.useRef();
  const authContext = useContext(AuthContext);
  console.log('contact us props==>', props);
  const issueName = props.location.state && props.location.state.selectedFeild ? props.location.state.selectedFeild : '';
  const [contactus, setcontactus] = useState({
      firstName: {  
                  value: '',
                  validation: ['blank','name'],
                  errorMsg: ['This field is required','Invalid first name'],
                  errorKey: 'firstName',
                  firstName: false
              },
      lastName: {  
                  value: '',
                  validation: ['blank','name'],
                  errorMsg: ['This field is required','Invalid first name'],
                  errorKey: 'lastName',
                  lastName: false
              },
      email: {  
                  value: '',
                  validation: ['blank','email'],
                  errorMsg: ['This field is required','Invalid email'],
                  errorKey: 'email',
                  email: false
              },
      subject: {  
                  value: issueName,
                  validation: ['blank'],
                  errorMsg: ['This field is required'],
                  errorKey: 'subject',
                  subject: false
              },
      message: {  
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'message',
                    message: false
                }
  })
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: ''
  });
  const [response, setresponse] = useState(false);
  const modifyPracticeData = (e) => {
    let name, value,
      tempData = [];
    name = e.currentTarget.getAttribute('name');
    value = e.currentTarget.value;
    tempData = contactus;
    tempData[name]['value'] = value;
    tempData[name][name] = false;
    if (name == 'message' && value.length > 500) {
      return false;
    }
    setcontactus({ ...tempData });
  }

  const onChangeCaptcha = (value) => {
    console.log('onChangeCaptcha',value)
  }

  const formSubmit = async (e) => {
    e && e.preventDefault();
    let tempcontactus = contactus;
    let flag = true;

    let validationResult = InputValidation(tempcontactus);

    if (Object.keys(validationResult).length > 0) {
      flag = false;
      for (const prop in validationResult) {
        tempcontactus[prop][prop] = `${validationResult[prop]}`;
        let element = document.querySelectorAll(`.error-input`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
      }
      setcontactus({ ...tempcontactus });
    }
    // console.log('medical current value ', practiceData);
    if (flag) {
      authContext.setLoader();
      const fData = {
        firstName: contactus.firstName.value,
        lastName: contactus.lastName.value,
        email: contactus.email.value,
        subject: contactus.subject.value,
        message: contactus.message.value
      }

      // console.log('fData', fData);
      // console.log(fData,JSON.stringify(fData));
      let headers = { 'Content-Type': 'application/json' };
      if (authContext.token) {
        headers['access_token'] = authContext.token
      }
      const submitData = {
        headers: headers,
        url: 'contact-form/submit',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData);
      authContext.unsetLoader();
      console.log('result', result);
      if (result.body.status) {
        setresponse(true);
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        });
        authContext.showResponseModal();
      }
    }

  }
  return (
    <Fragment>
      <Container fluid className="header px-0">
        <HeaderMain {...props}/>
      </Container>
      <Container fluid className="free-profile">
        <Container>
          <Row>
            {
              authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
          </Row>
          <Row>

            <div className="col-12 no-padding">
              <h2>Contact Us</h2>
            </div>

            <div className="col-12 contactus">
              {
                !response ? (<>
                  <Row>
                    <h3>What's on your mind?</h3>
                  </Row>
                  <Row>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="fname" className="pro-label">First Name</label>
                      <input type='text' id="fname" placeholder="First Name" autoComplete="off"
                        name="firstName"
                        className={contactus.firstName.firstName ? 'input-pro  error-border' : 'input-pro'}
                        onChange={modifyPracticeData}
                        value={contactus.firstName.value}
                      />
                      <small className="error-input">{contactus.firstName.firstName}</small>
                    </div>

                    <div className="col-md-6 mb-4">
                      <label htmlFor="lname" className="pro-label">Last Name</label>
                      <input type='text' id="fname" placeholder="Last Name" autoComplete="off"
                        name="lastName"
                        className={contactus.lastName.lastName ? 'input-pro  error-border' : 'input-pro'}
                        onChange={modifyPracticeData}
                        value={contactus.lastName.value}
                      />
                      <small className="error-input">{contactus.lastName.lastName}</small>
                    </div>

                    <div className="col-12 mb-4">
                      <label htmlFor="email" className="pro-label">Email Address</label>
                      <input type='text' id="email" placeholder="Emailaddress@domainname.com" autoComplete="off"
                        name="email"
                        className={contactus.email.email ? 'input-pro  error-border' : 'input-pro'}
                        onChange={modifyPracticeData}
                        value={contactus.email.value}
                      />
                      <small className="error-input">{contactus.email.email}</small>
                    </div>

                    <div className="col-12 mb-4">
                      <label htmlFor="subject" className="pro-label">Subject</label>
                      {/* <input type='text' 
                              id="subject"  placeholder="(e.g., Feature Request, Payment/Charges, Technical Issue, General Questions)" autoComplete="off" 
                              name="subject"
                              className={ contactus.subject.subject ? 'input-pro  error-border' : 'input-pro'} 
                              onChange={modifyPracticeData}
                              value={contactus.subject.value}
                            /> */}
                      <div className="myform">
                        <select
                          id="subject" placeholder="(e.g., Feature Request, Payment/Charges, Technical Issue, General Questions)" autoComplete="off"
                          name="subject"
                          className={contactus.subject.subject ? 'select  error-border' : 'select'}
                          onChange={modifyPracticeData}
                          value={contactus.subject.value}
                        >
                          <option value="">(e.g., Feature Request, Payment/Charges, Technical Issue, General Questions)</option>
                          <option value="Feature Request">Feature Request</option>
                          <option value="Payment/Charges">Payment/Charges</option>
                          <option value="Technical Issue">Technical Issue</option>
                          <option value="General Questions">General Questions</option>
                        </select>
                        <small className="error-input">{contactus.subject.subject}</small>
                      </div>
                    </div>

                    <div className="col-12 mb-4">
                      <label htmlFor="message" className="pro-label">Message</label>
                      <textarea autoComplete="off"
                        name="message"
                        className={contactus.message.message ? 'input-pro  error-border' : 'input-pro textarea-contact'}
                        onChange={modifyPracticeData}
                        value={contactus.message.value}
                      >
                      </textarea>
                      <small className="error-input">{contactus.message.message}</small>
                      <small style={{ color: '#6c757d' }}> 500 characters maximum</small>
                    </div>
                    <div className="col-12 mb-4">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey="6LfRIOAaAAAAAMn7lVcibeG6TufIxurPN-15nttY"
                        onChange={onChangeCaptcha}
                      />
                    </div>
                    
                    <div onClick={(e) => formSubmit(e)} className="col-10 col-md-5 mb-4 mt-2 text-center next-btn-pro">
                      <a href="/#" onClick={(e) => formSubmit(e)}>Send Message</a>
                    </div>
                  </Row>
                </>) : (<>
                  <Row>
                    <div className="suc-msg">
                      <div className="checkmar-outer mb-3">
                        <Checkmark />
                      </div>

                      {/* <div className="cus-check mb-4">
                              <input type="checkbox" checked="true"  disabled="true" id="children (pediatrics)-1" name="patientTreated" data-name="patientTreated" value="" />
                              <label htmlFor="children (pediatrics)-1"></label>
                            </div> */}
                      <h3 className="">
                        Message sent! Thanks for reaching out to DocScheduler!
                            </h3>
                      <h3>
                        One of our customer service team members will respond to your message within 2 business days.
                            </h3>
                      <ul>
                        <li><Link to={PatientLinks.home}> Homepage</Link></li>
                        <li>
                          <Link to={{ pathname: `${ProviderLinks.freeprofile}`, query: 'commentSection' }}>
                            {" "}
                            Frequently Asked Questions
                              </Link>
                        </li>
                        <li>
                          <Link to={{ pathname: `${PatientLinks.how_works}`, query: 'howWorks' }}>
                            {" "}
                            How DocScheduler Works
                                </Link>
                        </li>
                      </ul>
                    </div>

                  </Row>
                </>)
              }
              {/* <Row>
                      <div className="error-msg">
                        <h3 className="italic">
                          An error occurred while loading this page.
                        </h3>
                        <h3 className="italic">
                          Try refreshing your browser.
                        </h3>
                        <ul>
                          <li><a >Refresh Page</a></li>
                          <li><a> Contact Us</a></li>
                        </ul>  
                      </div>
                    </Row> */}
            </div>
          </Row>
        </Container>
      </Container>
      <Footer />
    </Fragment>
  );
}

export default AboutUs;