import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { ProviderLinks, PatientLinks, AdminLinks } from '../../linkFile';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../admin/assets/images/back_icon.svg';
import PatientTabs from '../../components/admin/patient/patient-tabs';
import AccountInfo from '../../components/admin/patient/account-information';
import InsuranceInfo from '../../components/admin/patient/insurance-information';
import SavedDoctor from '../../components/admin/patient/saved-doctor';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import PatientAppointmentHistory from '../../components/admin/patient/patient-appointment-history';
import PatientHealthGrade from '../../components/admin/patient/patient-health-grade';
import NetworkLayer from '../../reactLayer/Network/Network';
import Loadermodal from "../../global/loader-modal/loader-modal";

const PatientDetail = (props) => {

    const authContext = useContext(AuthContext);
    const [visibleComponent, setvisibleComponent] = useState('accInfo');
    const [viewdetails, setViewdetails] = useState();
    const tabName = [{ id: 'accInfo', name: 'Account information' }, { id: 'insInfo', name: 'Insurance Information' }, { id: 'savDoc', name: 'Saved Doctors' }, { id: 'appointHistory', name: 'Appointment History' }, { id: 'healthGrade', name: 'Health Grade' }]
    let patientId = props.match.params.userID;

    const getDetails = async () => {
        authContext.setLoader();
        // FORM DATA 
        let id = props.match.params.userID;
        let formData = {};
        formData = {
            id: id
        }
        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getPatient',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);


        setViewdetails(result ?.body ?.result);
        authContext.unsetLoader();
    }

    useEffect(() => {
        getDetails();
    }, [])

    return (
        <Fragment>
            <Loadermodal />
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section patient-management-outer"}>
                    <Row className="bg-white-report patient-details-outer">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Edit Patient Detail</div>
                            </div>
                            <div className="col-12 mt-3">
                                <Link to={AdminLinks.admin_patient_management} className="back-link">
                                    <span className="back-icon"><BackIcon /></span>
                                    Back
                            </Link>
                            </div>
                            <div className="patient-tabs-outer">
                                <PatientTabs visibleComponent={visibleComponent} setvisibleComponent={setvisibleComponent} tabName={tabName} />
                            </div>
                            {
                                visibleComponent === 'accInfo' && viewdetails ? <><AccountInfo data={viewdetails} callBack={getDetails} patientId={patientId} /> </>
                                    :
                                    visibleComponent === 'insInfo' ? <InsuranceInfo data={viewdetails} callBack={getDetails} patientId={patientId} />
                                        :
                                        visibleComponent === 'savDoc' ? <SavedDoctor data={viewdetails} callBack={getDetails} patientId={patientId} />
                                            :
                                            visibleComponent === 'appointHistory' ? <PatientAppointmentHistory data={viewdetails} callBack={getDetails} patientId={patientId} />
                                                :
                                                visibleComponent === 'healthGrade' ? <PatientHealthGrade data={viewdetails} callBack={getDetails} patientId={patientId} />
                                                    : <></>
                            }

                        </div>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default PatientDetail;