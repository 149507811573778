import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';
import { PatientLinks } from '../../linkFile';






const CovidSelfAssessment1 = (props) => {
  const authContext = useContext(AuthContext);
  const step1 = authContext['covidOption'] && authContext['covidOption']['step-1'] ? authContext['covidOption']['step-1'] : false

  console.log('step------1', step1);
    
  return (
    <Fragment>      
      <Container fluid className="covid-screens-wrap">
        <Row>
          <Container>
            <Row>
                <div className="col-12">
                    <h1>Self Assessment</h1>
                    <div className="progress-outer">
                        <div className="progress-inner" style={{width: "13%"}}></div>
                    </div>
                </div>                
            </Row>
            <Row>
              <div className="col-12">
                <div className="covid-self-outerbox">
                    <div className="covid-question">
                      <span>1.</span>
                      Are you ill, or caring for someone who is ill?
                    </div>
                    <div className="covid-question-options">
                      <div className="cus-radio">
                          <input type="radio" id="yes" name="yn" checked={step1 === 'yes' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} value="yes"/>
                          <label htmlFor="yes">Yes</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="No" name="yn" checked={step1 === 'no' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} value="no"/>
                          <label htmlFor="No">No</label>
                      </div>
                      <small className="error-input">{ props.error.step1 }</small>
                    </div>
                    <div className="covid-buttons">
                      <span className="covid-bkbtn" data-prev-page={PatientLinks.home} onClick={(e)=>{ props.goPrevious(e) }} >Back</span>
                      <span className="nextbtn">
                        <input type="button" className="next-btn-pro" onClick={(e)=>{ props.onNext(e) }} value="Next"/>
                      </span>
                    </div>
                </div>                
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
    );
  }
  
  export default CovidSelfAssessment1;