import React, { Fragment, useContext, useState, useEffect } from 'react';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Custommodal from '../../global/modal/modal';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';
import Youtubevideo from '../../components/youtube-video/youtube-video';
import NetworkLayer from '../../reactLayer/Network/Network';
import Loadermodal from "../../global/loader-modal/loader-modal";


const HowWorks = () => {
    const authContext = useContext(AuthContext);
    const [data, setData] = useState([]);

    const getDetails = async (id) => {
        authContext.setLoader();

        let formData = {
            id: 7
        }
        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json'
            },
            url: 'content-management/get',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        setData(result.body ?.result);
        authContext.unsetLoader();
    }

    useEffect(() => {
        getDetails();
    }, [])
    return (
        <Fragment>
            {/* header */}
            <Container fluid className="header px-0">
                <HomeHeader />
            </Container>

            <Container>
                <Row>
                    <div className="col-12">
                        <div className="how-works-wrap">
                            <h1>{data.page_title}</h1>
                            <div dangerouslySetInnerHTML={{
                                __html: data.description
                            }}>
                            </div>
                            <Youtubevideo />
                        </div>
                    </div>
                </Row>
            </Container>

            <Footer />
        </Fragment>
    );
}

export default HowWorks;