import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'react-bootstrap';
import HomePage from '../../components/homepage/homepage';
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';




const Home = (props) => {

  return (
    <Fragment>
      <HeaderMain {...props}/>
      <Container fluid className="home-wrap">
        <HomePage/>
      </Container>
      <Footer/>
      
    </Fragment>
    );
  }
  
  export default Home;