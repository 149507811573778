import React, { Fragment, useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from "react-router-dom";
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Custommodal from '../../global/modal/modal';
import Stars from '../../global/ratingreview/stars';
import DoctorReviewModal from '../../components/doctor-review-modal/doctor-review-modal';
import {ProviderLinks, PatientLinks, AdminLinks} from '../../linkFile';

const AppointmentHistory = (props) => {
    const [data, setData] = useState([]);
    const authContext = useContext(AuthContext);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const modalList = ['reviewModal'];
    const [profile, setProfile] = useState({});


    const getAppointmentHistory = async () => {
        authContext.setLoader();
        // FORM DATA
        const formData = {
            "offset": 0,
            "limit": 5
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                "access_token": authContext.token
            },
            url: 'appointment/history/list',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST
        let result = await NetworkLayer.postData(submitData);
        authContext.setAppointmentHistory(result.body.result);
        console.log('Response', result.body.result);
        setData(result.body.result);
        authContext.unsetLoader();
    }

    const saveUnsaveFavDoc = async (value, type) => {
        console.log(value);

        authContext.setLoader();

        let url = '';
        let isSavedDoctor = 1;
        if (type == 'save') {
            url = 'patients/favourite/doctor/add';
            isSavedDoctor = 1;
        } else {
            url = 'patients/favourite/doctor/remove';
            isSavedDoctor = 0;
        }
        // FORM DATA
        const formData = {
            "toUser": value ?.providerPracticeLocations ?.userId
        }
        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                "access_token": authContext.token
            },
            url: url,
            body: JSON.stringify(formData),
        }
        // SEND REQUEST
        let result = await NetworkLayer.postData(submitData);

        console.log('Response', result);
        if (result.body.code == 200) {
            var index = data.findIndex(obj => obj.id === value.id);
            data[index].providerPracticeLocations.users.isSavedDoctor = isSavedDoctor;
            setData(data)
        }

        authContext.unsetLoader();
    }

    useEffect(() => {
        getAppointmentHistory();
    }, []);

    const openreviewModal = (e, values) => {
        e.preventDefault(e);

        // CREATE DATA RELEVANT TO REVIEW POPUP
        let accountDetails = {
            accountInfo: {
                id: values.providerPracticeLocations.users.id,
                firstName: values.providerPracticeLocations.users.providers.firstName,
                middleName: values.providerPracticeLocations.users.providers.middleName,
                lastName: values.providerPracticeLocations.users.providers.lastName,
                profileImage: values.providerPracticeLocations.users.profileImage,
            },
            medicalSpeciality: {
                providerMedicalSpecialitiesValue: values.providerMedicalSpecialitiesValue
            }
        }
        setProfile(accountDetails);
        openProfileTabModal('reviewModal');
    }
    const openProfileTabModal = (modalName) => {
        settabvisibleComponent(modalName)
        authContext.showModal()
    }
    const getProfile = () => {
        getAppointmentHistory();
    }

    return (
        <Fragment>
            {profile.accountInfo && profile.accountInfo.id && modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'reviewModal' ? <DoctorReviewModal {...profile} getProfile={getProfile} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <Container className="patient-appointment-wrap">
                <Row>
                    <div className="col-12">
                        <div className="appoint-sub-head-wrap">
                            <h2>Past Appointment Requests</h2>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="col-12 mb-5">
                        <div className="appoint-box">
                            {/* heading start */}
                            <div className="table-heading-wrap">
                                <div></div>
                                <div>Appointment Request</div>
                                <div>Location</div>
                                <div>Leave Feedback</div>

                            </div>
                            {/* Content start */}
                            <div className="appoint-box-content">
                                {/* heading start */}
                                <div className="table-heading-wrap-device">
                                    <div></div>
                                    <div>Appointment Request</div>
                                    <div>Location</div>
                                    <div>Leave Feedback</div>
                                </div>
                                {/* row 1 */}

                                {data.length == 0 ? "No past appointment requests available right now" : data.map((values, key) =>
                                    <div className="table-content-appointment" key={key}>
                                        <div>
                                            <Link to={ProviderLinks.profile+'/'+values.providerPracticeLocations.users.id}><p><img className="profile-pic" src={values.providerPracticeLocations.users.profileImage} /></p></Link>
                                        </div>
                                        <div>
                                            <Link to={ProviderLinks.profile+'/'+values.providerPracticeLocations.users.id}><span className="dr-name">{`${values.providerPracticeLocations.users.providers.firstName} ${values.providerPracticeLocations.users.providers.lastName}`}</span></Link>
                                            <p className="mb-2"><span >{values.providerMedicalSpecialitiesValue}</span></p>

                                            <p><span className="req-by">Requested:</span></p>
                                            <p><span >{values.createdAt}</span></p>
                                        </div>
                                        <div>
                                            <p><span>{values.providerPracticeLocations.practiceName}</span></p>
                                            <p><span>{values.providerPracticeLocations.practiceAddress}</span></p>
                                            <p><span>{values.providerPracticeLocations.phoneNumber}</span></p>
                                            <ul className="doctor-status mt-3">
                                                {values.providerPracticeLocations.users.isSavedDoctor == 0 ?
                                                    <li className="blank" onClick={() => saveUnsaveFavDoc(values, 'save')}><img src="../../assets/images/unsave-icon.svg" alt="" className="scale icon-docsave" />Save Doctor</li>
                                                    :
                                                    <li className="blank" onClick={() => saveUnsaveFavDoc(values, 'unsave')}><img src="../../assets/images/save-icon.png" alt="" className="scale icon-docsave" />Unsave Doctor</li>
                                                }
                                            </ul>
                                        </div>
                                        <div className="profile-review-box">
                                            <div className="rating" style={{"width": "100%"}}>
                                                <Stars 
                                                    rating={`${values.providerPracticeLocations.users.totalReviewAvg ? parseFloat(values.providerPracticeLocations.users.totalReviewAvg) : 0}`}
                                                    {...values} 
                                                    getProfile={getProfile}
                                                    componentName={'patient_appointment'}
                                                />
                                            </div>
                                            
                                            <div className="rating-result mb-4">
                                                <span className={`rating${values.providerPracticeLocations.users.totalReviewAvg ? parseFloat(values.providerPracticeLocations.users.totalReviewAvg) : 0}`}></span>
                                                <span>({values.providerPracticeLocations.users.totalReviewAvg ? parseFloat(values.providerPracticeLocations.users.totalReviewAvg) : 0}) {values.providerPracticeLocations.users.totalReviewCount} Reviews</span>
                                            </div>
                                            <div className="write-review">
                                                <a href="/#" onClick={(e) => openreviewModal(e, values)}><span>Write a Review</span></a>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }

                            </div>
                        </div>
                    </div>
                </Row>

            </Container>

        </Fragment>
    );
}

export default AppointmentHistory;