import React, { Fragment, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Tabs  } from 'react-bootstrap';
// Component Improt From Here 
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';
import ProviderLogin from '../../components/provider-login/provider-login';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Custommodal from '../../global/modal/modal';
import Twostep from '../../components/provider-login/twostep';



const ProviderRegister = (props) => {
  const authContext = useContext(AuthContext);
  const [tabvisibleComponent, settabvisibleComponent] = useState('');
  const modalList = ['login','twostep'];

  const openLoginModal = (e,modalName)=>{
      // authContext.hideModal()
      e.preventDefault();
      settabvisibleComponent(modalName)
      authContext.showModal()
  }
  return (
    <Fragment>
        <HeaderMain />
        { modalList.indexOf(tabvisibleComponent) > '-1' ? (
            <Custommodal unsetComponent={settabvisibleComponent} componentName={
                tabvisibleComponent === 'login' ? <ProviderLogin ltype={'patient'} settabvisibleComponent = {settabvisibleComponent}/> :
                tabvisibleComponent === 'twostep' ? <Twostep ltype={'patient'} /> :
                <></>
            } />
        ) : (
            <></>
        )}
        <ProviderLogin ltype={'provider'} settabvisibleComponent = {settabvisibleComponent}/>
        <Footer/>
    </Fragment>
    );
  }
  
export default ProviderRegister
