import React, { Fragment, useEffect, useState, useContext } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { ProviderLinks, PatientLinks, AdminLinks } from '../../../linkFile';
import { Link } from 'react-router-dom';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import PatientAppointmentModal from './patient-appointment-modal';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Pagination from "../../../components/admin/pagination/pagination";
import Loadermodal from "../../../global/loader-modal/loader-modal";
import * as moment from 'moment';

const PatientHealthGrade = (props) => {
    const authContext = useContext(AuthContext);
    const [filter, setfilter] = useState({
        offset: 0,
        limit: 10
    });
    const [data, setData] = useState([]);
    const [totalCount, settotalCount] = useState(0);

    const getList = async (value) => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (value) {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                searchValue: value,
                id: props.data ?.patientInfo ?.accountInfo ?.id
            }
        } else {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                id: props.data ?.patientInfo ?.accountInfo ?.id
            }
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getPatientHealthGrades',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        // console.log("result==>", result.body.result);
        setData(result ?.body ?.result ?.rows);
        settotalCount(result ?.body ?.result ?.count);
        authContext.unsetLoader();
    }

    const deleteRecord = async (id) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id,
            userId:props.data ?.patientInfo ?.accountInfo ?.id
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getPatientHealthGrades/delete',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        if (window.confirm('Are you sure you want to delete this provider?')) {
            try{
                let result = await NetworkLayer.postData(submitData);
                if(result.body?.status){
                    getList();
                }
            }catch(err){

            }
            
            
        }

        authContext.unsetLoader();
    }

    const changeLimit = (page) => {
        setfilter({ ...filter, ...{ offset: parseInt(page) } });
    };

    useEffect(() => {
        getList();
    }, [filter.offset])

    return (
        <Fragment>
            <div className="table-report-scroll mt-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="data-sort">Date</th>
                            <th>Health Grade</th>
                            <th>BMI</th>
                            <th>BPM</th>
                            <th>Sleep Per Night</th>
                            <th>Tobacco Use</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data ?.length == 0 &&
                                <tr>
                                    <td>No Such Health Grade Found</td>
                                </tr>
                        }
                        {
                            data && data.map((value, index) =>
                                <tr key={index}>
                                    <td>{moment(value.updatedAt).format('DD MMM, YYYY')} </td>
                                    <td><p className={`grade-${value.healthGrade.replace(/\W/g, '').toLowerCase()}`}>{value.healthGrade}</p></td>
                                    <td>{value.BMI}</td>
                                    <td>{value.BPM}</td>
                                    <td>{value.sleep_per_night} hours</td>
                                    <td>{value.tobacco_use == 1 ? 'Yes' : 'No'}</td>
                                    <td>
                                        <Link className="table-action view-all" onClick={() => { deleteRecord(value.id) }}>Delete</Link>
                                    </td>
                                </tr>
                            )
                        }


                    </tbody>
                </table>
            </div>
            <div className="report-page mt-4">
                <Pagination
                    total={totalCount}
                    limit={filter.limit}
                    range={4}
                    offset={filter.offset}
                    callBack={changeLimit}
                />
            </div>
        </Fragment>
    );
}

export default PatientHealthGrade;