import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';


const DashboardHead = (props) => {
    // console.log('Dashboard tap props', props);
    const tabName = props.tabName;
    const title = props.title
    const changeTab = (e,tabId) => {
        e.preventDefault();
        props.setvisibleComponent(tabId);
    }

   return (
        <Container>
            <Row>
                <div className="col-12">
                    <div className="dashboard-heading">
                        <h1>{title}</h1>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-12">
                    <div className="dashboard-top-tabs-outer">
                        <div className="dashboard-top-tabs">
                            {
                                tabName.map((data, index) =>
                                    <div key={data.id} className={props.visibleComponent == data.id ? 'active' : ''}><a href="/#" onClick={(e)=> {changeTab(e,data.id)}} className="under-line">{data.name}</a></div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    )
    
}

export default DashboardHead;