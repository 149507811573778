import React, { Fragment, useState, useEffect, useContext, lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';

import InputValidation from '../../reactLayer/validation';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import arrayMove from 'array-move';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const AddEducation = (props) => {

    const authContext = useContext(AuthContext);
    const startyear = (new Date()).getFullYear() - 80;
    const allyears = Global.getYearList(); //Array.from(new Array(81), (val, index) => index + startyear);
    let id = null;
    const [degreeData, setdegreeData] = useState([{
        id: {
            value: 0,
            validation: ['']
        },
        pType: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'pType',
        },
        sName: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'sName',
        },
        yStarted: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'yStarted',
        },
        yCompleted: {
            value: '',
            validation: ['blank', { compareDate: { startDate: '' } }],
            errorMsg: ['This field is required', 'Completed year should not be less than started year'],
            errorKey: 'yCompleted',
        },
    }]);

    const [trainingData, settrainingData] = useState([{
        id: {
            value: 0,
            validation: ['']
        },
        pType: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'pType',
        },
        pName: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'pName',
            pName: false
        },
        sName: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'sName',
        },
        yStarted: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'yStarted',
        },
        yCompleted: {
            value: '',
            validation: ['blank', { compareDate: { startDate: '' } }],
            errorMsg: ['This field is required', 'Completed year should not be less than started year'],
            errorKey: 'yCompleted',
        }
    }]);
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    const [medicalprogrametypes, setmedicalprogrametypes] = useState([]);
    const [medInstitue, setmedInstitue] = useState([]);
    const [trainingList, settrainingList] = useState([]);
    const [degreeType, setdegreeType] = useState(props.modalName === 'addEdu' ? 'degree' : props.modalName === 'editDegree' ? 'degree' : 'training');

    const modifyMedicalSpeciality = (e) => {
        let index, name, value,
            tempData = [];
        index = e.currentTarget.getAttribute('data-index');
        name = e.currentTarget.getAttribute('data-name');
        value = e.currentTarget.value;

        if (degreeType === 'degree') {
            tempData = degreeData;
            tempData[index][name]['value'] = value;
            tempData[index][name][name] = '';
            // console.log('varName', tempData);
            // return false;
            if (name == 'yCompleted' || name == 'yStarted') {
                tempData[index]['yCompleted']['validation'] = ['blank', { compareDate: { startDate: tempData[index]['yStarted']['value'] } }];
            }
            setdegreeData([...tempData]);
            // console.log('degree data', degreeData);
        } else if (degreeType === 'training') {
            tempData = trainingData;
            tempData[index][name]['value'] = value;
            tempData[index][name][name] = '';
            if (name == 'yCompleted' || name == 'yStarted') {
                tempData[index]['yCompleted']['validation'] = ['blank', { compareDate: { startDate: tempData[index]['yStarted']['value'] } }];
            }
            settrainingData([...tempData]);
        }
    }
    const getApiData = async () => {

        let progTp = await Global.getProgrametypes();
        setmedicalprogrametypes(progTp);

        let instList = await Global.getInstitues();
        setmedInstitue(instList);

        let trainingList = await Global.getTraining();
        settrainingList(trainingList);

    }
    const formSubmit = async () => {
        let flag = true;
        let setFocus = false;
        let Details = []



        let tempdegreeData = degreeData;
        let temptrainingData = trainingData;
        if (degreeType === 'degree') {
            degreeData.forEach((currentValue, index) => {
                let degData = {
                    "degreeType": "degree",
                    "id": currentValue.id.value,
                    "educationType": currentValue.pType.value,
                    "schoolName": currentValue.sName.value,
                    "yearStarted": currentValue.yStarted.value,
                    "yearCompleted": currentValue.yCompleted.value
                };
                Details.push(degData);
                let validationResult = InputValidation(currentValue);
                if (Object.keys(validationResult).length > 0) {
                    flag = false;
                    for (const prop in validationResult) {
                        tempdegreeData[index][prop][prop] = `${validationResult[prop]}`;
                        if (!flag && !setFocus) {
                            setFocus = true;
                            let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
                        }
                    }
                    setdegreeData([...tempdegreeData]);
                } else {
                    if (currentValue.yStarted.value > currentValue.yCompleted.value) {
                        tempdegreeData[index]['yCompleted']['yCompleted'] = 'Completed year should not be less then started year';
                        setdegreeData([...tempdegreeData]);
                    }

                }
            });
        } else {
            temptrainingData.forEach((currentValue, index) => {
                let tData = {

                    "degreeType": "training",
                    "id": currentValue.id.value,
                    "educationType": currentValue.pType.value,
                    "otherSchoolName": currentValue.sName.value,
                    "programName": currentValue.pName.value,
                    "yearStarted": currentValue.yStarted.value,
                    "yearCompleted": currentValue.yCompleted.value
                };
                Details.push(tData);
                let validationResult = InputValidation(currentValue);
                if (Object.keys(validationResult).length > 0) {
                    flag = false;
                    for (const prop in validationResult) {
                        temptrainingData[index][prop][prop] = `${validationResult[prop]}`;
                        if (!flag && !setFocus) {
                            setFocus = true;
                            let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
                        }
                    }
                    settrainingData([...temptrainingData]);
                } else {
                    if (currentValue.yStarted.value > currentValue.yCompleted.value) {
                        temptrainingData[index]['yCompleted']['yCompleted'] = 'Completed year should not be less then started year';
                        settrainingData([...temptrainingData]);
                    }
                }
            });
        }


        if (flag) {
            authContext.setLoader();


            const fData = {
                "degreeDetails": Details,
            }



            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/degree/addOrUpdate',
                body: JSON.stringify(fData),

            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if (result.body.status) {
                props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                setTimeout(() => {
                    props.setVisibleComponent && props.setVisibleComponent('');
                }, 3000)
            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }

    }

    useEffect(() => {
        getApiData();
    }, [])

    useEffect(() => {
        if (props.medicalData && props.medicalData.length) {
            authContext.setLoader();
            let medicalDataUpdate = [];
            props.medicalData.map((md) => {
                medicalDataUpdate.push({
                    id: {
                        value: md.id,
                        validation: ['']
                    },
                    pType: {
                        value: md.educationType,
                        validation: ['blank'],
                        errorMsg: ['This field is required'],
                        errorKey: 'pType',
                    },
                    sName: {
                        value: md.schoolName,
                        validation: ['blank'],
                        errorMsg: ['This field is required'],
                        errorKey: 'sName',
                    },
                    yStarted: {
                        value: md.yearStarted,
                        validation: ['blank'],
                        errorMsg: ['This field is required'],
                        errorKey: 'yStarted',
                    },
                    yCompleted: {
                        value: md.yearCompleted,
                        validation: ['blank', { compareDate: { startDate: '' } }],
                        errorMsg: ['This field is required', 'Completed year should not be less than started year'],
                        errorKey: 'yCompleted',
                    },
                })
            })
            setdegreeData(medicalDataUpdate);
            authContext.unsetLoader();
        }
        if (props.practiceData && props.practiceData.length) {
            authContext.setLoader();
            let trainingDataUpdate = [];
            props.practiceData.map((pd) => {
                trainingDataUpdate.push({
                    id: {
                        value: pd.id,
                        validation: ['']
                    },
                    pType: {
                        value: pd.educationType,
                        validation: ['blank'],
                        errorMsg: ['This field is required'],
                        errorKey: 'pType',
                        pType: false
                    },
                    pName: {
                        value: pd.programName,
                        validation: ['blank'],
                        errorMsg: ['This field is required'],
                        errorKey: 'pName',
                        pName: false
                    },
                    sName: {
                        value: pd.otherSchoolName,
                        validation: ['blank'],
                        errorMsg: ['This field is required'],
                        errorKey: 'sName',
                        sName: false
                    },
                    yStarted: {
                        value: pd.yearStarted,
                        validation: ['blank'],
                        errorMsg: ['This field is required'],
                        errorKey: 'yStarted',
                        yStarted: false
                    },
                    yCompleted: {
                        value: pd.yearCompleted,
                        validation: ['blank', { compareDate: { startDate: '' } }],
                        errorMsg: ['This field is required', 'Completed year should not be less than started year'],
                        errorKey: 'yCompleted',
                        yCompleted: false
                    }
                })
            })
            settrainingData(trainingDataUpdate);
            authContext.unsetLoader();
        } else {
            settrainingData([{
                id: {
                    value: 0,
                    validation: ['']
                },
                pType: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'pType',
                },
                pName: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'pName',
                },
                sName: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'sName',
                },
                yStarted: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'yStarted',
                },
                yCompleted: {
                    value: '',
                    validation: ['blank', { compareDate: { startDate: '' } }],
                    errorMsg: ['This field is required', 'Completed year should not be less than started year'],
                    errorKey: 'yCompleted',
                }
            }]);
        }

    }, [degreeType])

    const swapItem = (result) => {

        let tempData = [];
        if (result.destination && result.source.index !== result.destination.index) {
            if (degreeType === 'degree') {
                tempData = degreeData;
                tempData = arrayMove(tempData, result.source.index, result.destination.index);
                setdegreeData(tempData);
            } else {
                tempData = trainingData;
                tempData = arrayMove(tempData, result.source.index, result.destination.index);
                settrainingData(tempData);
            }
        }
    }

    const deleteData = async () => {
        if (id) {
            authContext.setLoader();

            const fData = {
                "id": id,
                "degreeType": degreeType
            }
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/degree/delete',
                body: JSON.stringify(fData),

            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if (result.body.status) {
                props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                setTimeout(() => {
                    props.setVisibleComponent && props.setVisibleComponent('');
                }, 3000)

            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }
    }
    const askDelete = (deleteId) => {
        id = deleteId;
        setmodalProps({
            type: 'success',
            msg: 'Are you sure to delete ' + degreeType + '?',
            btnname: 'Yes',
            action: deleteData,
            btncancel: 'No',
            autoclose: 'false'
        });
        authContext.showResponseModal();
    }

    return (

        <Fragment>
            <Suspense>
                <Container>
                {
                    props.modalName === 'addEdu' ? (<>
                        <div className="dashboard-modal-wrap">
                            <Row>
                                {
                                    authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                                }
                            </Row>
                            <Row>
                                <div className="col-12 px-0">
                                    <div className="modal-head">Add Education</div>
                                </div>
                            </Row>
                            <div className="add-edu-box">
                                <Row>
                                    <div className="col-12">
                                        <div className="sub-head mb-3 mb-md-4">Add Education, Residency, Fellowship or Internship</div>
                                        <div className="border-bottom">
                                            <div className="myform">
                                                <div className="desig-icon desig-icon-select"></div>
                                                {/* <div className="resi-icon desig-icon-select"></div>    */}
                                                <select className="select-service selecticon" onChange={() => setdegreeType(degreeType === 'degree' ? 'training' : 'degree')}>
                                                    <option>Degree</option>
                                                    <option>Training</option>
                                                </select>
                                            </div>
                                            <small className="error-input"></small>
                                        </div>
                                    </div>
                                    {
                                        degreeType === 'degree' ? (<>

                                            {degreeData.map((data1, index) =>
                                                <>
                                                    <div className="col-md-10 mb-2">
                                                        <label className="pro-label">Program Type</label>
                                                        <div className="myform">
                                                            <select className={data1.pType.pType ? "select  error-border" : "select"} value={data1.pType.value} data-index={index} data-name="pType" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                                                <option value="">Program Type</option>
                                                                {medicalprogrametypes.map((pt, pti) =>
                                                                    <option key={pt.id + '-' + index} value={pt.id}>{pt.title}</option>
                                                                )}
                                                            </select>
                                                            <small className="error-input">{data1.pType.pType}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10 mb-2">
                                                        <label className="pro-label">School Name or Institution Name</label>
                                                        <div className="myform">
                                                            <select className={data1.sName.sName ? "select  error-border" : "select"} value={data1.sName.value} data-index={index} data-name="sName" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                                                <option value="">School Name or Institution Name</option>
                                                                {medInstitue.map((it, iti) =>
                                                                    <option key={it.id + '-' + index} value={it.id}>{it.name}</option>
                                                                )}
                                                            </select>
                                                            <small className="error-input">{data1.sName.sName}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="pro-label">Year Started</label>
                                                        <div className="myform">
                                                            <select className={data1.yStarted.yStarted ? "select  error-border" : "select"} value={data1.yStarted.value} data-index={index} data-name="yStarted" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                                                <option value="">Year Started</option>
                                                                {allyears.map((sy, si) =>
                                                                    <option key={'start-' + index + '-' + si} value={sy}>{sy}</option>
                                                                )}
                                                            </select>
                                                            <small className="error-input">{data1.yStarted.yStarted}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="pro-label">Year Completed</label>
                                                        <div className="myform">
                                                            <select className={data1.yCompleted.yCompleted ? "select  error-border" : "select"} value={data1.yCompleted.value} data-index={index} data-name="yCompleted" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                                                <option value="">Year Completed</option>
                                                                {allyears.map((sy, si) =>
                                                                    <option key={'end-' + index + '-' + si} value={sy}>{sy}</option>
                                                                )}
                                                            </select>
                                                            <small className="error-input">{data1.yCompleted.yCompleted}</small>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>) :
                                            (<>
                                                {trainingData.map((data, index) =>
                                                    <>
                                                        <div className="col-md-10 mb-2">
                                                            <label className="pro-label">Training Type</label>
                                                            <div className="myform">
                                                                <select className={data.pType.pType ? "select  error-border" : "select"} value={data.pType.value} data-index={index} data-name="pType" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                                    <option value="">Training Type</option>
                                                                    {trainingList.map((pt, pti) =>
                                                                        <option key={pt.id + '-' + index} value={pt.id}>{pt.title}</option>
                                                                    )}
                                                                </select>
                                                                <small className="error-input">{data.pType.pType}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10 mb-2">
                                                            <label className="pro-label">School Name or Institution Name</label>
                                                            <input type="text" name="sNumber" className={data.sName.sName ? "input-pro  error-border" : "input-pro"} placeholder="School Name or Institution Name" autoComplete="off"
                                                                data-index={index} data-name="sName"
                                                                value={data.sName.value}
                                                                onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}
                                                            />
                                                            <small className="error-input">{data.sName.sName}</small>
                                                        </div>
                                                        {/* <div className="col-md-10 mb-2">
                                                        <label className="pro-label">School Name or Institution Name</label>
                                                        <div className="myform">  
                                                            <select className={ data.sName.sName ? "select  error-border" : "select"}  value={data.sName.value}  data-index={index} data-name="sName" onChange={ (e) => {modifyMedicalSpeciality(e, 'trainingData')}}>
                                                                <option value="">School Name or Institution Name</option>
                                                                { medInstitue.map(( it , iti ) => 
                                                                    <option key={it.id+'-'+index} value={it.id}>{it.name}</option>
                                                                )}
                                                            </select>
                                                            <small className="error-input">{data.sName.sName}</small>
                                                        </div>  
                                                    </div> */}
                                                        <div className="col-md-10 mb-2">
                                                            <label className="pro-label">Program Name</label>
                                                            <input type="text" name="pNumber" className={data.pName.pName ? "input-pro  error-border" : "input-pro"} placeholder="Program Name" autoComplete="off"
                                                                data-index={index} data-name="pName"
                                                                value={data.pName.value}
                                                                onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}
                                                            />
                                                            <small className="error-input">{data.pName.pName}</small>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="pro-label">Year Started</label>
                                                            <div className="myform">
                                                                <select className={data.yStarted.yStarted ? "select  error-border" : "select"} value={data.yStarted.value} data-index={index} data-name="yStarted" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                                    <option value="">Year Started</option>
                                                                    {allyears.map((sy, si) =>
                                                                        <option key={'start-' + index + '-' + si} value={sy}>{sy}</option>
                                                                    )}
                                                                </select>
                                                                <small className="error-input">{data.yStarted.yStarted}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="pro-label">Year Completed</label>
                                                            <div className="myform">
                                                                <select className={data.yCompleted.yCompleted ? "select  error-border" : "select"} value={data.yCompleted.value} data-index={index} data-name="yCompleted" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                                    <option value="">Year Completed</option>
                                                                    {allyears.map((sy, si) =>
                                                                        <option key={'end-' + index + '-' + si} value={sy}>{sy}</option>
                                                                    )}
                                                                </select>
                                                                <small className="error-input">{data.yCompleted.yCompleted}</small>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>)
                                    }

                                </Row>
                                <Row>


                                </Row>
                                <Row>
                                    <div className="col-12 text-center mt-4 mt-md-5">
                                        <input type="button" className="next-btn-pro" onClick={formSubmit} value="Save" />
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </>) :
                        (<>
                            <div className="dashboard-modal-wrap ">
                                <Row>
                                    {
                                        authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                                    }
                                </Row>
                                <Row>
                                    <div className="col-12 px-0">
                                        <div className="modal-head">Edit {props.modalName === 'editDegree' ? 'Education' : 'Training'}</div>
                                    </div>
                                </Row>
                                <div className="edit-edu-box">
                                    <Row>
                                        {
                                            props.modalName === 'editDegree' ? (<>

                                                <DragDropContext onDragEnd={swapItem}>
                                                    <Droppable droppableId="characters">
                                                        {(provided) => (
                                                            <ul  {...provided.droppableProps} ref={provided.innerRef}>
                                                                {degreeData.map((data1, index) =>
                                                                    <Draggable key={'trainingData-' + index} draggableId={'degreeData-' + index} index={index}>
                                                                        {(provided) => (
                                                                            <li className="row" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                <>
                                                                                    <div className={index > 0 ? "col-12 mt-5" : "col-12"}>
                                                                                        <div className="sub-head mb-3">Edit Education </div>
                                                                                        <div className="edit-edu-head">
                                                                                            <span className="desig-icon">Degree</span>
                                                                                            <div className="edit-buttons-wrap">
                                                                                                {/* <div><img src="/assets/images/edit-icon.png" alt=""Edit/></div> */}
                                                                                                {
                                                                                                    degreeData.length > 1 ? (<>
                                                                                                        <div onClick={() => askDelete(data1.id.value)}><img key={'degDel-' + index} src="/assets/images/del-icon.png" alt="" Delete /></div>
                                                                                                    </>) : (<></>)
                                                                                                }

                                                                                                <div><img className="grab-icon" key={'degDrag-' + index} src="/assets/images/icon-pop.png" alt="" Icon /></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-10 mb-2">
                                                                                        <label className="pro-label">Program Type</label>
                                                                                        <div className="myform">
                                                                                            <select className={data1.pType.pType ? "select  error-border" : "select"} value={data1.pType.value} data-index={index} data-name="pType" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                                                                                <option value="">Program Type</option>
                                                                                                {medicalprogrametypes.map((pt, pti) =>
                                                                                                    <option key={pt.id + '-' + index} value={pt.id}>{pt.title}</option>
                                                                                                )}
                                                                                            </select>
                                                                                            <small className="error-input">{data1.pType.pType}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-10 mb-2">
                                                                                        <label className="pro-label">School Name or Institution Name</label>
                                                                                        <div className="myform">
                                                                                            <select className={data1.sName.sName ? "select  error-border" : "select"} value={data1.sName.value} data-index={index} data-name="sName" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                                                                                <option value="">School Name or Institution Name</option>
                                                                                                {medInstitue.map((it, iti) =>
                                                                                                    <option key={it.id + '-' + index} value={it.id}>{it.name}</option>
                                                                                                )}
                                                                                            </select>
                                                                                            <small className="error-input">{data1.sName.sName}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label className="pro-label">Year Started</label>
                                                                                        <div className="myform">
                                                                                            <select className={data1.yStarted.yStarted ? "select  error-border" : "select"} value={data1.yStarted.value} data-index={index} data-name="yStarted" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                                                                                <option value="">Year Started</option>
                                                                                                {allyears.map((sy, si) =>
                                                                                                    <option key={'start-' + index + '-' + si} value={sy}>{sy}</option>
                                                                                                )}
                                                                                            </select>
                                                                                            <small className="error-input">{data1.yStarted.yStarted}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label className="pro-label">Year Completed</label>
                                                                                        <div className="myform">
                                                                                            <select className={data1.yCompleted.yCompleted ? "select  error-border" : "select"} value={data1.yCompleted.value} data-index={index} data-name="yCompleted" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                                                                                <option value="">Year Completed</option>
                                                                                                {allyears.map((sy, si) =>
                                                                                                    <option key={'end-' + index + '-' + si} value={sy}>{sy}</option>
                                                                                                )}
                                                                                            </select>
                                                                                            <small className="error-input">{data1.yCompleted.yCompleted}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            </li>

                                                                        )}
                                                                    </Draggable>


                                                                )}

                                                            </ul>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </>) :
                                                (<>


                                                    <DragDropContext onDragEnd={swapItem}>
                                                        <Droppable droppableId="characters">
                                                            {(provided) => (
                                                                <ul  {...provided.droppableProps} ref={provided.innerRef}>
                                                                    {trainingData.map((data, index) =>
                                                                        <Draggable key={'trainingData-' + index} draggableId={'trainingData-' + index} index={index}>
                                                                            {(provided) => (
                                                                                <li className="row" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                    <>
                                                                                        <div className={index > 0 ? "col-12 mt-5" : "col-12"}>
                                                                                            <div className="sub-head mb-3">Edit Residency or Fellowship or Internship</div>
                                                                                            <div className="edit-edu-head">
                                                                                                <span className="resi-icon">Training</span>
                                                                                                <div className="edit-buttons-wrap">
                                                                                                    {/* <div><img src="/assets/images/edit-icon.png" alt=""Edit/></div> */}
                                                                                                    {
                                                                                                        trainingData.length > 1 ? (<>
                                                                                                            <div onClick={() => askDelete(data.id.value)}><img key={'trainingDel-' + index} src="/assets/images/del-icon.png" alt="" Delete /></div>
                                                                                                        </>) : (<></>)
                                                                                                    }
                                                                                                    <div><img key={'dragTraining-' + index} src="/assets/images/icon-pop.png" alt="" Icon /></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-10 mb-2">
                                                                                            <label className="pro-label">Training Type</label>
                                                                                            <div className="myform">
                                                                                                <select className={data.pType.pType ? "select  error-border" : "select"} value={data.pType.value} data-index={index} data-name="pType" onChange={(e) => { modifyMedicalSpeciality(e) }}>
                                                                                                    <option value="">Training Type</option>
                                                                                                    {trainingList.map((pt, pti) =>
                                                                                                        <option key={pt.id + '-' + index} value={pt.id}>{pt.title}</option>
                                                                                                    )}
                                                                                                </select>
                                                                                                <small className="error-input">{data.pType.pType}</small>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-10 mb-2">
                                                                                            <label className="pro-label">School Name or Institution Name</label>
                                                                                            <input type="text" name="sNumber" className={data.sName.sName ? "input-pro  error-border" : "input-pro"} placeholder="School Name or Institution Name" autoComplete="off"
                                                                                                data-index={index} data-name="sName"
                                                                                                value={data.sName.value}
                                                                                                onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}
                                                                                            />
                                                                                            <small className="error-input">{data.sName.sName}</small>
                                                                                        </div>

                                                                                        {/* <div className="col-md-10 mb-2">
                                                                                            <label className="pro-label">School Name or Institution Name</label>
                                                                                            <div className="myform">
                                                                                                <select className={data.sName.sName ? "select  error-border" : "select"} value={data.sName.value} data-index={index} data-name="sName" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                                                                    <option value="">School Name or Institution Name</option>
                                                                                                    {medInstitue.map((it, iti) =>
                                                                                                        <option key={it.id + '-' + index} value={it.id}>{it.name}</option>
                                                                                                    )}
                                                                                                </select>
                                                                                                <small className="error-input">{data.sName.sName}</small>
                                                                                            </div>
                                                                                        </div> */}

                                                                                        <div className="col-md-10 mb-2">
                                                                                            <label className="pro-label">Program Name</label>
                                                                                            <input type="text" name="pNumber" className={data.pName.pName ? "input-pro  error-border" : "input-pro"} placeholder="Program Name" autoComplete="off"
                                                                                                data-index={index} data-name="pName"
                                                                                                value={data.pName.value}
                                                                                                onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}
                                                                                            />
                                                                                            <small className="error-input">{data.pName.pName}</small>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <label className="pro-label">Year Started</label>
                                                                                            <div className="myform">
                                                                                                <select className={data.yStarted.yStarted ? "select  error-border" : "select"} value={data.yStarted.value} data-index={index} data-name="yStarted" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                                                                    <option value="">Year Started</option>
                                                                                                    {allyears.map((sy, si) =>
                                                                                                        <option key={'start-' + index + '-' + si} value={sy}>{sy}</option>
                                                                                                    )}
                                                                                                </select>
                                                                                                <small className="error-input">{data.yStarted.yStarted}</small>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <label className="pro-label">Year Completed</label>
                                                                                            <div className="myform">
                                                                                                <select className={data.yCompleted.yCompleted ? "select  error-border" : "select"} value={data.yCompleted.value} data-index={index} data-name="yCompleted" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                                                                    <option value="">Year Completed</option>
                                                                                                    {allyears.map((sy, si) =>
                                                                                                        <option key={'end-' + index + '-' + si} value={sy}>{sy}</option>
                                                                                                    )}
                                                                                                </select>
                                                                                                <small className="error-input">{data.yCompleted.yCompleted}</small>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                </li>

                                                                            )}
                                                                        </Draggable>


                                                                    )}

                                                                </ul>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>


                                                </>)
                                        }

                                    </Row>
                                    <Row>


                                    </Row>
                                    <Row>
                                        <div className="col-12 text-center mt-4 mt-md-5">
                                            <input type="button" className="next-btn-pro" onClick={formSubmit} value="Save" />
                                        </div>
                                    </Row>
                                </div>
                            </div>


                        </>)
                }

            </Container>
            </Suspense>
        </Fragment>
    )

}

export default AddEducation;