import React, { Fragment, useContext, useState, useEffect } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import Custommodal from '../../../global/modal/modal';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import Paging from '../patient/paging';
import Pagination from "../../../components/admin/pagination/pagination";
import EditSpecialityModal from './edit-speciality-modal';
import DeleteSpecialityModal from './delete-speciality-modal';
import SpecialitySearch from './speciality-search';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Loadermodal from "../../../global/loader-modal/loader-modal";

let searchText = ''
const DentalSchoolList = () => {
    const authContext = useContext(AuthContext);
    const [totalCount, settotalCount] = useState(0);
    const [filter, setfilter] = useState({
        offset: 0,
        limit: 10
    });
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [viewdata, setViewdata] = useState();
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const modalList = ['editSpeModal', 'delSpeModal'];
    const openProfileTabModal = (modalName, value) => {
        setViewdata(value);
        settabvisibleComponent(modalName)
        authContext.showModal()
    }

    const getList = async (value) => {
        console.log("get searchText :", searchText);
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (searchText) {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                searchValue: searchText,
                category: 'dental'
            }
        } else {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                category: 'dental'
            }
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/schools/get',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        try {
            let result = await NetworkLayer.postData(submitData);
            console.log(result);
            setData(result ?.body ?.result.rows);
            settotalCount(result ?.body ?.result ?.count);
        } catch (err) {
            console.log("ERROR :", err)
        }

        authContext.unsetLoader();
        settabvisibleComponent("")
    }

    const searchFilter = (value) => {
        console.log(value);
        setfilter({offset:0,limit:10})
        searchText = value
        getList(value);
    }

    useEffect(() => {
        getList();
    }, [filter.offset])

    // PAGINATION CALLBACK
    const changeLimit = (page) => {
        setfilter({ ...filter, ...{ offset: parseInt(page) } });
    };

    useEffect(() => {
        getList();
    }, [])
    return (
        <Fragment>
            <div className="speciality-wrap">
                <Loadermodal />
                {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                    <Custommodal unsetComponent={settabvisibleComponent} componentName={
                        tabvisibleComponent === 'editSpeModal' ? <EditSpecialityModal viewdata={viewdata} getList={getList} type={"Speciality"} subtype={"Dental"} isschool={1} />
                            :
                            tabvisibleComponent === 'delSpeModal' ? <DeleteSpecialityModal viewdata={viewdata} getList={getList} type={"Speciality"} subtype={"Dental"} isschool={1} />
                                :
                                <></>
                    } />
                ) : (
                        <></>
                    )}
                <SpecialitySearch search={searchFilter} type={"Speciality"} subtype={"Dental"} isschool={1} getList={getList} />
                <div className="table-report-scroll mb-4">
                    <table className="table mb-2">
                        <thead>
                            <tr>
                                <th className="data-sort">
                                    <span className="sort-outer">
                                        State
                                    <span className="data-sort-icon"><DataSortIcon /></span>
                                    </span>
                                </th>
                                <th className="data-sort">
                                    <span className="sort-outer">
                                        School
                                    <span className="data-sort-icon"><DataSortIcon /></span>
                                    </span>
                                </th>
                                <th className="data-sort">
                                    <span className="sort-outer">
                                        City
                                    <span className="data-sort-icon"><DataSortIcon /></span>
                                    </span>
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.length == 0 &&
                                <tr>
                                    <td>No Such Schools Found</td>
                                </tr>
                            }
                            {
                                data && data.map((value, index) =>
                                    <tr key={value.id}>
                                        <td>{value ?.states ?.name}</td>
                                        <td>
                                            <div className="comment-school">{value ?.name}</div>
                                        </td>
                                        <td>{value ?.cities ?.name}</td>
                                        <td>
                                            <Link onClick={() => { openProfileTabModal("editSpeModal", value) }} className="table-action view-all">Edit</Link>
                                            <Link onClick={() => { openProfileTabModal("delSpeModal", value) }} className="table-action view-all">Delete</Link>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
                {/* End of first table */}
                {/* <Paging /> */}
                <div className="report-page mt-4">
                    <Pagination
                        total={totalCount}
                        limit={filter.limit}
                        range={4}
                        offset={filter.offset}
                        callBack={changeLimit}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default DentalSchoolList;