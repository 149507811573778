import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import AuthContext from '../../reactLayer/auth/AuthContext';
import { PatientLinks } from '../../linkFile';

import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';

import CovidSelfAssessment1 from './covid-self-assessment-1';
import CovidSelfAssessment2 from './covid-self-assessment-2';
import CovidSelfAssessment3 from './covid-self-assessment-3';
import CovidSelfAssessment4 from './covid-self-assessment-4';
import CovidSelfAssessment5 from './covid-self-assessment-5';
import CovidSelfAssessment6 from './covid-self-assessment-6';
import CovidSelfAssessment7 from './covid-self-assessment-7';
import CovidSelfAssessment8 from './covid-self-assessment-8';
import Error403 from '../../global/errorpages/error-403';
import Error404 from '../../global/errorpages/error-404';






const CovidSelfAssessment = (props) => {
    console.log('covid screen', props.match.params.step);
    const history = useHistory();
    const validLinks = ['step-1','step-2','step-3','step-4','step-5','step-6','step-7','step-8'];
    const authContext = useContext(AuthContext);
    const [ error, seterror ] = useState({
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
      step8:  false,
    })
    // const covidOption = authContext.covidOption;

    const goPrevious = ( e ) => {
      e.preventDefault();
      let previousPage = e.currentTarget.getAttribute('data-prev-page');
      history.push({
                pathname: previousPage,
              });
    }
    
    const onNext = (e) => {
      e && e.preventDefault();
      console.log('covidOption',authContext.covidOption);
      let covidOption = authContext.covidOption ? authContext.covidOption : {};
      if(props.match.params.step === 'step-1' ) {
         if( covidOption['step-1']) {
           if( covidOption['step-1'] == 'no') {
              covidOption['step-8'] = 'first';
              authContext.setCovidOption(covidOption);
              history.push({
                pathname: PatientLinks.covid_self_8,
              });
           } else {
             history.push({
                pathname: PatientLinks.covid_self_2,
              });
           }
         } else {
           seterror({...error, ...{step1: 'Select any option'}})
         }
      } else if(props.match.params.step === 'step-2' ) {
         if( covidOption['step-2']) {
           if( covidOption['step-2'] == 'opt1') {
              history.push({
                pathname: PatientLinks.covid_self_3,
              });
           } else {
              covidOption['step-8'] = 'second';
              authContext.setCovidOption(covidOption);
              history.push({
                pathname: PatientLinks.covid_self_8,
              });
           }
         } else {
           seterror({...error, ...{step2: 'Select any option'}})
         }
      } else if(props.match.params.step === 'step-3' ) {
         if( covidOption['step-3']) {
           if( covidOption['step-3'] == 'opt4') {
              history.push({
                pathname: PatientLinks.covid_self_4,
              });
           } else {
              covidOption['step-8'] = 'third';
              authContext.setCovidOption(covidOption);
              history.push({
                pathname: PatientLinks.covid_self_8,
              });
           }
         } else {
           seterror({...error, ...{step3: 'Select any option'}})
         }
      } else if(props.match.params.step === 'step-4' ) {
         if( covidOption['step-4']) {
            history.push({
              pathname: PatientLinks.covid_self_5,
            });
         } else {
           seterror({...error, ...{step4: 'Select any option'}})
         }
      } else if(props.match.params.step === 'step-5' ) {
         if( covidOption['step-5']) {
            history.push({
              pathname: PatientLinks.covid_self_6,
            });
         } else {
           seterror({...error, ...{step5: 'Select any option'}})
         }
      } else if(props.match.params.step === 'step-6' ) {
         if( covidOption['step-6'].length ) {
            if(covidOption['step-6'].indexOf('opt5') > '-1') {
                if( covidOption['step-4'] && covidOption['step-5'] && covidOption['step-4'] === 'opt3' && covidOption['step-5'] === 'opt2') {
                  covidOption['step-8'] = 'fifth';
                  authContext.setCovidOption(covidOption);
                  history.push({
                    pathname: PatientLinks.covid_self_8,
                  });
                } else {
                  covidOption['step-8'] = 'fourth';
                  authContext.setCovidOption(covidOption);
                  history.push({
                    pathname: PatientLinks.covid_self_8,
                  });
                }
            } else {
               history.push({
                pathname: PatientLinks.covid_self_7,
              });
            }
         } else {
           seterror({...error, ...{step6: 'Select atleast one option'}})
         }
      } else if(props.match.params.step === 'step-7' ) {
         if( covidOption['step-7']) {
            if(covidOption['step-7'] === 'opt6') {
              covidOption['step-8'] = 'fourth';
            } else {
              covidOption['step-8'] = 'sixth';
            }
            
            authContext.setCovidOption(covidOption);
            history.push({
              pathname: PatientLinks.covid_self_8,
            });
         } else {
           seterror({...error, ...{step7: 'Select any option'}})
         }
      } 
    }

    const onSelectStepData = (e) => {
      // e && e.preventDefault();
      let covidOption = authContext.covidOption ? authContext.covidOption : {};
      if( props.match.params.step === 'step-6' ) {
        let value = e.currentTarget.value;
        
        if( e.currentTarget.checked ) {
          if( covidOption[props.match.params.step] && covidOption[props.match.params.step].length ) {
            let arrayIndex = covidOption[props.match.params.step].indexOf(value);
            if(arrayIndex == '-1' ) {
                covidOption[props.match.params.step].push(value);
            }
          } else {
            covidOption[props.match.params.step] = [];
            covidOption[props.match.params.step].push(value);
          }
        } else {
            if( covidOption[props.match.params.step] && covidOption[props.match.params.step].length ) {
                let arrayIndex = covidOption[props.match.params.step].indexOf(value);
                covidOption[props.match.params.step].splice(arrayIndex, 1);
            }
        }
      } else {
        covidOption[props.match.params.step] = e.currentTarget.value;
      }
      authContext.setCovidOption(covidOption);
    }

    const Validatepages = () => {
      if (validLinks.indexOf(props.match.params.step) > '-1') {
        if( props.match.params.step === 'step-1' ) {
            return <CovidSelfAssessment1 {...{goPrevious : goPrevious, onNext: onNext, onSelectStepData: onSelectStepData, error: error}}/>
        } else if( props.match.params.step === 'step-2' ) {
            return <CovidSelfAssessment2 {...{goPrevious : goPrevious, onNext: onNext, onSelectStepData: onSelectStepData, error: error}}/>
        } else if( props.match.params.step === 'step-3' ) {
            return <CovidSelfAssessment3 {...{goPrevious : goPrevious, onNext: onNext, onSelectStepData: onSelectStepData, error: error}}/>
        } else if( props.match.params.step === 'step-4' ) {
            return <CovidSelfAssessment4 {...{goPrevious : goPrevious, onNext: onNext, onSelectStepData: onSelectStepData, error: error}}/>
        } else if( props.match.params.step === 'step-5' ) {
            return <CovidSelfAssessment5 {...{goPrevious : goPrevious, onNext: onNext, onSelectStepData: onSelectStepData, error: error}}/>
        } else if( props.match.params.step === 'step-6' ) {
            return <CovidSelfAssessment6 {...{goPrevious : goPrevious, onNext: onNext, onSelectStepData: onSelectStepData, error: error}}/>
        } else if( props.match.params.step === 'step-7' ) {
            return <CovidSelfAssessment7 {...{goPrevious : goPrevious, onNext: onNext, onSelectStepData: onSelectStepData, error: error}}/>
        } else if( props.match.params.step === 'step-8' ) {
            return <CovidSelfAssessment8 {...{goPrevious : goPrevious, onNext: onNext, onSelectStepData: onSelectStepData, error: error}}/>
        } 
      } else {
        return <Error404/>
      }
    }
    return (
      <Fragment>
        {/* header */}
        <Container fluid className="header px-0">
          <HomeHeader/>
        </Container>
          {
            Validatepages()
          }
        {/* <CovidSelfAssessment1/> */}
        {/* <CovidSelfAssessment2/> */}
        {/* <CovidSelfAssessment3/> */}
        {/* <CovidSelfAssessment4/> */}
        {/* <CovidSelfAssessment5/> */}
        {/* <CovidSelfAssessment6/> */}
        {/* <CovidSelfAssessment7/> */}
        {/* <CovidSelfAssessment8/> */}


        {/* footer */}
        <Footer/>
        
      
        
      </Fragment>
      );
  }
  
  export default CovidSelfAssessment;