import React, { Fragment, useEffect, useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import SponsoredDoc from '../../components/search-results/sponsored-doctor';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';
import SchedulingDate from "../../components/scheduling/scheduling-date";
import SchedulingTitle from "../../components/scheduling/scheduling-title";

const CareTeam = () => {

  const authContext = useContext(AuthContext);
  const [recommendedDoctors, setRecommendedDoctors] = useState([]);
  const [savedDoctors, setSavedDoctors] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const getCareTeamResults = async () => {
    authContext.setLoader();
    let url = 'patients/care-team/get';

    // FORM DATA
    const formData = {
      "latitude": 22.757376,
      "longitude": 75.8644736
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        "access_token": authContext.token
      },
      url: url,
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData);
    console.log(result);
    setRecommendedDoctors(result.body.result.recommendedDoctors);
    setSavedDoctors(result.body.result.savedDoctors);

    authContext.unsetLoader();
  }

  const dateSelect = (value = false, event, type = false) => {
    let cdate = value;
    if (type) {
      if (type == 'prev') {
        cdate = Global.addDays(startDate, -5)
      } else {
        cdate = Global.addDays(startDate, 5)
        // console.log('cdate=====>', cdate);
      }
    }
    setStartDate(cdate);
  }

  useEffect(() => {
    getCareTeamResults();
  }, []);

  return (
    <Fragment>
      {/* header */}
      {/* <Container fluid className="header px-0">
        <HomeHeader/>
      </Container> */}

      <Container>
        <Row>
          <div className="col-12 care-team-wrap">
            <h2>My Care Team</h2>
            <div className="subhead">Recommended Doctors</div>
            <div className="ct-outer-box recommended-doctors-box doctor-search-wrap container">
              <div className="ct-inner-box">
                <div className="care-team-list">
                  <div className="sticky-patient">
                    <Row className="justify-content-end">
                      { recommendedDoctors.length > 0 && (
                        <div className="col-lg-6 col-xl-6">
                          <SchedulingTitle
                            startDate={startDate}
                            setStartDate={setStartDate}
                            dateSelect={dateSelect}
                            key={'schedulingTitle'}
                          />
                          <SchedulingDate startDate={startDate} setStartDate={setStartDate} key={'schedulingDate'}/>
                        </div>
                      )}
                    </Row>
                  </div>
                  <Row className="doctor-search-wrap">
                    {recommendedDoctors.length ?  
                      <SponsoredDoc getProfile={getCareTeamResults} componentName={'careteam'}
                       items={recommendedDoctors} userCateType={"patient"} startDate={startDate} setStartDate={setStartDate} isCareteam={true} getCareTeamResults={getCareTeamResults} /> :
                      <>No recommendations for now</>
                    }
                  </Row>
                </div>
              </div>
            </div>
            <div className="subhead">Saved Doctors</div>
            <div className="ct-outer-box saved-doctors-box doctor-search-wrap container">
              <div className="ct-inner-box">
                <div className="care-team-list">
                  <div className="sticky-patient">
                    <Row className="justify-content-end">
                      { savedDoctors.length > 0 && (
                        <div className="col-lg-6 col-xl-6">
                          <SchedulingTitle
                            startDate={startDate}
                            setStartDate={setStartDate}
                            dateSelect={dateSelect}
                            key={'schedulingTitle'}
                          />
                          <SchedulingDate startDate={startDate} setStartDate={setStartDate} key={'schedulingDate'}/>
                        </div>
                      )}
                    </Row>
                  </div>
                  <Row className="doctor-search-wrap">
                    { savedDoctors.length ? 
                      <SponsoredDoc careTeamCallBack={getCareTeamResults} componentName={'careteam'} items={savedDoctors} userCateType={"patient"} startDate={startDate} setStartDate={setStartDate} isCareteam={true} getCareTeamResults={getCareTeamResults} /> :
                      <>You haven't saved any doctors yet</>
                    }
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>



    </Fragment>
  );
}

export default CareTeam;