import React, { Fragment, useEffect, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import { Container, Row } from 'react-bootstrap';
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg';
import SchedulingDate from '../../scheduling/scheduling-date';
import SchedulingTime from './scheduling-time';
import Global from '../../../reactLayer/global';
import InputValidation from "../../../reactLayer/validation";
import SchedulingTitle from '../../scheduling/scheduling-title';
import * as moment from 'moment';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Msgmodal from "../../../global/modal-response-msg/MsgResponseModal";

const PatientAppointmentModal = (props) => {
    const authContext = useContext(AuthContext);
    console.log("PatientAppointmentModal Props ===>", props);
    const [patientReq, setpatientReq] = useState({
        pname: {
            value: `${props.data ?.users ?.patients ?.firstName} ${props.data ?.users ?.patients ?.lastName}`,
            validation: ['blank', 'name'],
            errorMsg: ['This filed is required', 'Invalid Name'],
            errorKey: 'pname',
            pname: false
        },
        isNewPatient: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This filed is required'],
            errorKey: 'isNewPatient',
            isNewPatient: false
        },
        locationId: {
            value: props ?.data ?.locationId,
            validation: ['blank'],
            errorMsg: ['This filed is required'],
            errorKey: 'locationId',
            locationId: false
        },
        preferAppointmentTime: {
            value: 'morning',
            validation: ['blank'],
            errorMsg: ['This filed is required'],
            errorKey: 'preferAppointmentTime',
            preferAppointmentTime: false
        },
        preferAppointmentType: {
            value: 'In-person',
            validation: ['blank'],
            errorMsg: ['This filed is required'],
            errorKey: 'preferAppointmentType',
            preferAppointmentType: false
        },
        appointmentDayType: {
            value: 'Next 7 Days',
            validation: ['blank'],
            errorMsg: ['This filed is required'],
            errorKey: 'preferAppointmentType',
            preferAppointmentType: false
        },
        visitType: {
            value: 'Routine Checkup',
            validation: ['blank'],
            errorMsg: ['This filed is required'],
            errorKey: 'visitType',
            visitType: false
        },
        appointmentDescription: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This filed is required'],
            errorKey: 'appointmentDescription',
            appointmentDescription: false,
            totalWords: 0
        },
    })
    const [startDate, setStartDate] = useState("");
    const [slots, setslots] = useState([]);
    const [selectedslots, setSelectedslots] = useState();
    const [providerLocations, setproviderLocations] = useState([]);
    const [appDate, setAppDate] = useState("");
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });

    const modifyPracticeData = (e) => {

        let name, value,
            tempData = patientReq;
        name = e.currentTarget.getAttribute('name');
        value = e.currentTarget.value;

        if (name === 'appointmentDescription') {
            if (value.length <= 300) {
                tempData[name]['value'] = value;
                tempData[name]['totalWords'] = 300 - value.length;
                tempData[name][name] = false;
            }
            setpatientReq({ ...tempData });
        } else {
            tempData[name]['value'] = value;
            tempData[name][name] = false;
            setpatientReq({ ...tempData });
        }

    }

    const bookAppointment = (slotInfo, item) => {
        console.log(slotInfo, item);
        setSelectedslots(slotInfo)
        let date = slotInfo.date.split('-');
        let new_date = date[1] + '/' + date[2] + '/' + date[0];
        setAppDate(new_date)

        let tempData = patientReq;
        tempData['preferAppointmentTime']['value'] = slotInfo.startTime;
        tempData['preferAppointmentTime']['preferAppointmentTime'] = false;
        setpatientReq({ ...tempData });
    }

    const callBackSubmit = async () => {
        let temppracticeData, flag = true;
        temppracticeData = patientReq;
        let validationResult = InputValidation(patientReq);

        if (Object.keys(validationResult).length > 0) {

            for (const prop in validationResult) {
                temppracticeData[prop][prop] = `${validationResult[prop]}`;
                if (flag) {
                    console.log('check which element ==>', document.querySelectorAll(`[name="${prop}"]`)[0]);
                    if (prop == 'isNewPatient') {
                        // let element = document.querySelectorAll(`.isNewPatient`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
                    } else {
                        // let element = document.querySelectorAll(`[name="${prop}"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
                    }

                }
                flag = false;


            }
            setpatientReq({ ...temppracticeData });
        }
        console.log(patientReq, validationResult)

        if (flag) {

            const fData = {
                "patientId": props ?.data ?.patientId,
                "childId": 0,
                "isNewPatient": patientReq.isNewPatient.value,
                "locationId": patientReq.locationId.value,
                "preferAppointmentTime": patientReq.preferAppointmentTime.value,
                "preferAppointmentType": patientReq.preferAppointmentType.value,
                "visitType": patientReq.visitType.value,
                "appointmentDescription": patientReq.appointmentDescription.value
            }

            let date = appDate.split('/');
            let new_date = date[2] + '-' + date[0] + '-' + date[1];

            if (appDate) {
                fData.appointmentDate = new_date
            } else {
                fData.appointmentDayType = patientReq.appointmentDayType.value
            }
            // console.log(fData);
            // return false;
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.adminToken
                },
                url: 'admin/appointment/book',
                body: JSON.stringify(fData),

            }
            authContext.setLoader();
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();

            if (result.body.status) {
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                setTimeout(() => {
                    authContext.hideModal()
                    props.getList()
                }, 2000);
            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }


        }
    }

    useEffect(() => {
        setStartDate(new Date());
        setproviderLocations([props.data.providerPracticeLocations])
    }, [])

    return (
        <Fragment>
            {
                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            <Container>
                <div className="search-modal-wrap">
                    <div className="edit-all-filter">
                        <Row>
                            <div className="col-12 px-0">
                                <div className="modal-head">
                                    {props ?.data ?.providerPracticeLocations ?.users ?.providers ?.allowOnline == 1 ? "Book Appointment" : "Request Callback"}
                                </div>
                            </div>
                        </Row>
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12 mb-3 mb-lg-4">
                                    <label className="pro-label">Who is this appointment for?</label>
                                    <div className="myform req-appoint">
                                        <input className="input-pro" type="text" id="" name="" disabled value={`${props.data ?.users ?.patients ?.firstName} ${props.data ?.users ?.patients ?.lastName}`} />
                                    </div>
                                </div>
                                <div className="col-12 mb-3 mb-md-4">
                                    <label className="pro-label">Will you/your child be a new patient?</label>
                                    <div className="gender-allfilter isNewPatient">
                                        <div className="cus-radio">
                                            <input type="radio" id="yes" onChange={modifyPracticeData} name="isNewPatient" value="1" />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="cus-radio">
                                            <input type="radio" id="no" onChange={modifyPracticeData} name="isNewPatient" value="0" />
                                            <label htmlFor="no">No</label>
                                        </div>
                                    </div>
                                    <small className="error-input">{patientReq.isNewPatient.isNewPatient}</small>
                                </div>
                                <div className="col-12 value-box mb-3">
                                    <label className="pro-label">Preferred location?</label>
                                    <div className="myform req-appoint">

                                        <select name="locationId" className="select-service" onChange={modifyPracticeData}
                                            disabled={props.data.locationId ? true : false}
                                        >
                                            {
                                                props.data.locationId ? (<>
                                                    {
                                                        providerLocations.length && providerLocations.map((l, i) =>

                                                            l.id === props.data.locationId ? <option key={i + 1} value={l.id}>Location #{i + 1} {l.practiceName}</option> : ''
                                                        )
                                                    }
                                                </>) : (<>
                                                    {
                                                        providerLocations.length && providerLocations.map((l, i) =>
                                                            <option key={i + 1} value={l.id}>Location #{i + 1} {l.practiceName}</option>
                                                        )
                                                    }
                                                </>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                {
                                    props ?.data ?.providerPracticeLocations ?.users ?.providers ?.allowOnline == 0 &&
                                        <div className="col-12 value-box mb-3">
                                            <label className="pro-label">Preferred appointment time?</label>
                                            <div className="myform req-appoint">
                                                <select className="select-service" name="preferAppointmentTime" onChange={modifyPracticeData} >
                                                    <option value="morning">Morning</option>
                                                    <option value="afternoon">Afternoon</option>
                                                </select>
                                            </div>
                                        </div>
                                }

                                {
                                    props ?.data ?.providerPracticeLocations ?.users ?.providers ?.allowOnline == 1 &&
                                        <div className="col-12 value-box mb-3">
                                            <label className="pro-label">Preferred appointment time?</label>
                                            <div className="admin-appoint-cal">
                                                <SchedulingDate startDate={startDate} setStartDate={setStartDate} />
                                                <SchedulingTime
                                                    key={'locationId'}
                                                    locationId={props.data.locationId}
                                                    item={slots}
                                                    startDate={startDate}
                                                    bookAppointment={bookAppointment}
                                                />
                                            </div>

                                        </div>
                                }



                                <div className="col-12 value-box mb-3">
                                    <label className="pro-label">Preferred appointment type?</label>
                                    <div className="myform req-appoint">
                                        <select className="select-service" name="preferAppointmentType" onChange={modifyPracticeData}>
                                            <option value="In-person">In-person</option>
                                            {
                                                props ?.data ?.providerPracticeLocations ?.users ?.providers ?.hasVirtualVisit == 1 ? <><option value="Virtual Visit">Virtual Visit</option></> : <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                                {
                                    // JSON.stringify(props ?.data ?.providerPracticeLocations ?.users ?.providers ?.allowOnline)
                                }
                                {
                                    props ?.data ?.providerPracticeLocations ?.users ?.providers ?.allowOnline == 0 &&
                                        <div className="col-12 value-box mb-3">
                                            <label className="pro-label">When do you want this appointment?</label>
                                            <div className="myform req-appoint">
                                                <select className="select-service" name="appointmentDayType" onChange={modifyPracticeData}
                                                >

                                                    <option value="Next 7 Days">Next 7 Days</option>
                                                    <option value="Next 14 Days">Next 14 Days</option>
                                                    <option value="Next 30 Days">Next 30 Days</option>

                                                </select>
                                            </div>
                                        </div>
                                }

                                {
                                    props ?.data ?.providerPracticeLocations ?.users ?.providers ?.allowOnline == 1 &&

                                        <div className="col-12 value-box mb-3">
                                            <label className="pro-label">When do you want this appointment?</label>
                                            <div className="myform req-appoint">

                                                <select className="select-service" name="appointmentDayType" onChange={modifyPracticeData}
                                                    disabled
                                                >
                                                    {
                                                        <option value={selectedslots ?.date}>
                                                            {appDate}
                                                        </option>
                                                    }
                                                </select>
                                            </div>
                                            <small className="error-input">{patientReq.preferAppointmentTime.preferAppointmentTime}</small>
                                        </div>
                                }



                                <div className="col-12 value-box mb-3">
                                    <label className="pro-label">Visit type?</label>
                                    <div className="myform req-appoint">
                                        <select className="select-service" name="visitType" onChange={modifyPracticeData}>
                                            <option value="Routine Checkup">Routine Checkup</option>
                                            <option value="Follow-up on Previous Visit">Follow-up on Previous Visit</option>
                                            <option value="Sick or Injured Visit">Sick or Injured Visit</option>
                                            <option value="Consulation">Consulation</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 mb-2 textarea-description">
                                    <label className="pro-label">Short description of reason for visit? <span>(Required)</span></label>
                                    <textarea id="description" name="appointmentDescription" value={patientReq.appointmentDescription.value} onChange={modifyPracticeData} className={patientReq.appointmentDescription.appointmentDescription ? "input-pro  error-border" : "input-pro"} rows="" cols=""></textarea>
                                    <div className="textarea-note">Note: 300 character limit</div>
                                    <small className="error-input">{patientReq.appointmentDescription.appointmentDescription}</small>
                                </div>

                                <div className="col-12 mt-3 mb-4">
                                    <div className="seaprator-title"><span><img src="/assets/images/lock-icon.png" className="scale lock" alt="lock" /> Encrypted Account Data</span></div>
                                </div>

                                <div className="col-12 mb-3 mb-md-4">
                                    <div className="demo-medical-wrap">
                                        <div className="demo-left">
                                            <div className="head">Demographic Information</div>
                                            <div className="demo-medical-inner">
                                                <div className="dmleft">Name:</div>
                                                <div className="dmright">{`${props.data ?.users ?.patients ?.firstName} ${props.data ?.users ?.patients ?.lastName}`}</div>

                                                <div className="dmleft">Sex:</div>
                                                <div className="dmright">{props.data ?.users ?.patients ?.gender}</div>

                                                <div className="dmleft">Preferred Pronoun:</div>
                                                <div className="dmright">{props.data ?.users ?.patients ?.preferredPronoun}</div>

                                                <div className="dmleft">Email Address:</div>
                                                <div className="dmright">{props.data ?.users ?.email}</div>

                                                <div className="dmleft">Phone Number:</div>
                                                <div className="dmright">{props.data ?.users ?.patients ?.phoneNumber}</div>

                                                <div className="dmleft">Birth Date:</div>
                                                <div className="dmright">{moment(props.data ?.users ?.patients ?.dob).format("DD/MM/YYYY")}</div>
                                            </div>
                                        </div>
                                        <div className="medical-right">
                                            <div className="head">Medical Insurance Information</div>
                                            <div className="demo-medical-inner">
                                                <div className="dmleft">Insurance Name:</div>
                                                <div className="dmright">{props.data ?.users ?.patientMedicalInsurances[0] ?.insurances ?.name}</div>

                                                <div className="dmleft">Plan Name:</div>
                                                <div className="dmright">{props.data ?.users ?.patientMedicalInsurances[0] ?.planName}</div>

                                                <div className="dmleft">Group ID#:</div>
                                                <div className="dmright">{props.data ?.users ?.patientMedicalInsurances[0] ?.group}</div>

                                                <div className="dmleft">Member ID#:</div>
                                                <div className="dmright">{props.data ?.users ?.patientMedicalInsurances[0] ?.membershipId}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mb-4 all-fltrbtn">
                                    <input type="button" className="next-btn-pro" value={props ?.data ?.providerPracticeLocations ?.users ?.providers ?.allowOnline == 1 ? "Book Appointment" : "Request Callback"} onClick={callBackSubmit} />
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </Fragment>
    )

}

export default PatientAppointmentModal;