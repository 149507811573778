import React, { Fragment, useState,useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import Global from '../../reactLayer/global';
import AuthContext from '../../reactLayer/auth/AuthContext';
import InputValidation from '../../reactLayer/validation';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';


const EditInsurance = (props) => {

    const authContext = useContext(AuthContext);
    const [insuranceList, setinsuranceList] = useState([]);
    const [selectall, setselectall] = useState( false );
    const [insuranceData, setinsuranceData] = useState(props.selectedIns);
    const [insError, setinsError] = useState(false);
    const [modalProps, setmodalProps] = useState({
        type:'', 
        msg:'',
        btnname: '',
        redirection: ''
    });
    const selectAll = ( e ) => {
        if( e.currentTarget.checked === true ) {
            setselectall(true);
            let selectedIns = [];
            insuranceList.map((insList) => {
                selectedIns.push(insList.name);
            })
            setinsuranceData([...selectedIns]);

        } else {
            setselectall(false);
            setinsuranceData([...[]]);
        }
    }
    const [custIns, setcustIns] = useState({
        custIns: {  
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'custIns',
                    custIns: false
                },
    })

    const addCustomInsurance = (e) => {
       let tempCustIns = custIns;
       let validationResult = InputValidation(custIns);
       if (Object.keys(validationResult).length > 0) {
            for (const prop in validationResult) {
                tempCustIns[prop][prop] = `${validationResult[prop]}`;
            }
            setcustIns({...tempCustIns});
        } else {
            let value = tempCustIns['custIns']['value'];
            tempCustIns['custIns']['value'] = '';
            setcustIns({...tempCustIns});
            let tempinsData, 
              tempInsList = insuranceList;
              tempinsData = insuranceData;
            tempinsData.push(value);
            setinsuranceData([...tempinsData]);
            tempInsList.push({"id":value,"name":value});
            setinsuranceList([...tempInsList]);

        }
    }
    const openTimehandle = (e, varName = 'practiceData' ) => {
        let index,name,value,
            tempData = [];

        name = e.currentTarget.getAttribute('data-name');
        value = e.currentTarget.value;
        tempData = insuranceData;

        let arrayIndex = tempData.indexOf(value);
        if(arrayIndex > '-1' ) {
            tempData.splice(arrayIndex, 1);
        } else {
            tempData.push(value);
        }
        setinsuranceData([...tempData]);

    }

    const getAllInsu = async() => {
       let header =  authContext.token;
       let insResponse = await Global.getInsurance(header);
       setinsuranceList(insResponse);
    }

    const formSubmit = async () => {
    
        let flag = true;
        if(!insuranceData.length) {
            flag=false;
            setinsError('Please select an insurance');
        }

        if( flag ) {
            authContext.setLoader();
            const fData = { "insuranceAccepted": insuranceData }

            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/insurances/update',
                body: JSON.stringify(fData),
                
            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            // console.log('language response', result);
            if( result.body.status ) {
                props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
                setmodalProps({
                    type:'success', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                setTimeout(() => {
                    props.setVisibleComponent && props.setVisibleComponent('');
                },3000)
            } else {
                setmodalProps({
                    type:'error', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }
        
    }
    useEffect(() => {
       getAllInsu();
    },[])
    return (
        <Fragment>
            <Container>
                
                <div className="dashboard-modal-wrap">
                    <Row>
                        {
                            authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                        }
                    </Row>
                    <Row>
                        
                        <div className="col-12 px-0">  
                            <div className="modal-head">Edit Insurances</div>                                
                        </div>
                    </Row> 
                    <div className="add-edu-box">                        
                        <Row>
                            <div className="col-12">
                                <div className="sub-head mb-4">Accepted Insurances</div>
                                <div className="ins-opt-wrap mb-2">                                    
                                    <div className="cus-check-rev cus-check-rev">
                                        {/* <input type="checkbox" id="Select All-0" name="insurance" value="Select All"/>
                                        <label for="Select All-0">Select All</label> */}
                                        <input type="checkbox" id="sAll" name="lang" onChange={(e) => selectAll(e)}  
                                            checked={ selectall ? true : false}
                                        />
                                        <label htmlFor="sAll">Select All</label>
                                    </div>   
                                </div> 
                                <div className="ins-opt-wrap">   
                                    {insuranceList.map(( d, ind ) =>
                                    <div className="cus-check-rev" key={d.name+'insList'+ind}>
                                        <input type="checkbox" id={d.name} name="insurance" 
                                            data-name='insuranceData'
                                            value={d.name}
                                            onChange={(e) => openTimehandle(e, 'insuranceData')} 
                                            checked={insuranceData.indexOf(d.name) > '-1' ? true : false}
                                        />
                                        <label htmlFor={d.name}>{d.name}</label>
                                    </div>
                                    )} 
                                    <small className="error-input">{ insError }</small>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12">
                                <div className="add-cus-ins-box">
                                    <div>
                                        <label htmlFor="cIns" className="pro-label">Add Custom Insurance</label>
                                    </div>
                                    <div>                                       
                                        {/* <input type="text" name="cIns" className="input-pro" placeholder="Custom Insurance Name" autoComplete="off"/> */}
                                        <input  type="text" name="addCustomIns" placeholder="Custom Insurance Name" autoComplete="off" 
                                            className="input-pro"className={ custIns.custIns.custIns ? "input-pro error-border" : "input-pro"}
                                            value={custIns.custIns.value}
                                            data-name="addCustomIns"
                                            onChange={ (e) => {
                                                setcustIns({
                                                    custIns: {  
                                                            value: e.currentTarget.value,
                                                            validation: ['blank'],
                                                            errorMsg: ['This field is required'],
                                                            errorKey: 'custIns',
                                                            custIns: false
                                                        },
                                                })
                                            }}
                                        />
                                        <small className="error-input">{ custIns.custIns.custIns }</small>
                                    </div>
                                    <div>
                                        <input type="button" className="next-btn-pro" onClick={(e)=> {addCustomInsurance(e)}} value="Add"/>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12 text-center mt-3 mt-md-4">
                                <input type="button" className="next-btn-pro" onClick={()=> {formSubmit()}} value="Save"/>
                            </div>
                        </Row>
                     </div>
                </div>              
            </Container>
        </Fragment>
    )
    
}

export default EditInsurance;