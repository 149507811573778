import React, { Fragment, useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { Link } from 'react-router-dom';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import RatingFilter from '../../components/admin/rating-review-admin/rating-filter';
import RatingTable from '../../components/admin/rating-review-admin/rating-table';
import Paging from '../../components/admin/patient/paging';


const RatingReview = () => {
    const authContext = useContext(AuthContext);
    return (
        <Fragment>
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section"}>

                    <Row className="bg-white-report">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Ratings &amp; Comments</div>
                            </div>

                            {/* Bottom container content */}
                            {/* <RatingFilter /> */}
                            <RatingTable />

                        </div>
                    </Row>



                </Container>
            </div>
        </Fragment>
    );
}

export default RatingReview;