import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import Custommodal from '../../../global/modal/modal';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg';
import AddSpecialityModal from './add-speciality-modal';
import InsuranceExport from '../../../components/admin/insurance-admin/insurance-export-modal';
import InsuranceImport from '../../../components/admin/insurance-admin/insurance-import-modal';

export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};

const SpecialitySearch = (props) => {
    // console.log("SpecialitySearch===>", props);
    const { search, type, getList, subtype, isdegree, isschool } = props;
    const authContext = useContext(AuthContext);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const modalList = ['addSpeModal', 'insuranceExport', 'specialityExport', 'insuranceImport', 'specialityImport'];
    const openProfileTabModal = (modalName) => {
        console.log(modalName);
        settabvisibleComponent(modalName)
        authContext.showModal()
    }
    const [filtervalue, setFiltervalue] = useState();

    const onInputChange = (e) => {
        e.preventDefault();
        setFiltervalue(e.target.value);
        let timeOut;
        clearTimeout(timeOut)
        timeOut = setTimeout(()=>{
            search(e.target.value)
        },1000)
    }

    const getRefreshList = () => {
        getList();
        settabvisibleComponent("")
    }

    /*const debounceGet = useRef(
        debounce((nextValue) => search(nextValue), 1000)
    ).current;*/

    useEffect(() => {
        if (filtervalue == "" && search) {
            search("");
        } else {
            if (filtervalue && filtervalue.length > 2) {
                //debounceGet(filtervalue);
            }

        }
        settabvisibleComponent("");
    }, [filtervalue])

    useEffect(() => {
        settabvisibleComponent("")
    }, [])


    return (
        <Fragment>

            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'addSpeModal' ? <AddSpecialityModal type={type} subtype={subtype} isdegree={isdegree} isschool={isschool} getRefreshList={getRefreshList} />
                        :
                        tabvisibleComponent === 'insuranceExport' ? <InsuranceExport type={type} subtype={subtype} />
                            :
                            tabvisibleComponent === 'specialityExport' ? <InsuranceExport type={type} subtype={subtype} isdegree={isdegree} isschool={isschool} />
                                :
                                tabvisibleComponent === 'insuranceImport' ? <InsuranceImport type={type} subtype={subtype} getRefreshList={getRefreshList} />
                                    :
                                    tabvisibleComponent === 'specialityImport' ? <InsuranceImport type={type} subtype={subtype} isdegree={isdegree} isschool={isschool} getRefreshList={getRefreshList} />
                                        :
                                        <></>
                } />
            ) : (
                    <></>
                )}

            <div className="speciality-filter">
                <span className="filter-input">
                    <input type="text" class="input-pro" name="usernae" id="username" placeholder={`Search by ${type.toLowerCase()} name`} autocomplete="off" value={filtervalue} onChange={(e) => onInputChange(e)} />
                    <i className="search-icon"><SearchIcon onClick={() => search(filtervalue)} /></i>
                </span>
                <div className="text-right speciality-links">
                    <Link onClick={() => { openProfileTabModal("addSpeModal") }}>+ Add</Link>

                    {
                        type == "Insurance" && <Link onClick={() => { openProfileTabModal("insuranceImport") }} >Import</Link>
                    }
                    {
                        type == "Speciality" && <Link onClick={() => { openProfileTabModal("specialityImport") }} >Import</Link>
                    }

                    {
                        type == "Insurance" && <Link onClick={() => { openProfileTabModal("insuranceExport") }} >Export</Link>
                    }
                    {
                        type == "Speciality" && <Link onClick={() => { openProfileTabModal("specialityExport") }} >Export</Link>
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default SpecialitySearch;