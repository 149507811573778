const ProviderLinks = {

    // Provider Links
    home: '/',
    login: '/provider/login',
    register: '/provider/register/:type/:id?',
    register_setp1: '/provider/register/account-information',
    register_setp2: '/provider/register/medical-specialty',
    register_setp3: '/provider/register/practice-information',
    register_setp4: '/provider/register/select-plan',
    register_setp5: '/provider/register/payment/',
    search_result: '/provider/search-result',
    profile: '/profile',
    dashboard: '/provider/dashboard',
    edit_address: '/provider/edit-address',
    add_address: '/provider/add-address',
    freeprofile: '/provider/free-profile',
    myaccount: '/provider/my-account/:page',
    accountprofile: '/provider/my-account/profile',
    accountsecurity: '/provider/my-account/security',
    account_profile: '/provider/my-account/:page/:type/:cardId?',
    refer_friend: '/provider/refer-friend',
    reactive_account: '/provider/reactive-account',
    subscription_canceled: '/provider/subscription-canceled',
    payment_receipt: '/provider/payment-receipt/:planId',
    twofactauth: '/provider/two-step-verification',
    forgot_pass: '/forgot-password',
    reset_pass: '/reset-password/:token',
    reset_pass_screen: '/reset-password-link-send',
    gmaps: '/provider/gmaps',
    notification: '/provider/notifications',


};


const PatientLinks = {
    // Patient Links
    home: '/',
    login: '/',
    register: '/patient/register/:type',
    register_setp1: '/patient/register/account-information',
    register_setp2: '/patient/register/demographic-information',
    register_setp3: '/patient/register/insurance-information',
    appointment_success: '/patient/appointment-request-success',
    aboutus: '/aboutus',
    contactus: '/contactus',
    error_404: '/error-404',
    privacy_policy: '/privacy-policy',
    acceptable_policy: '/acceptable-policy',
    additional_terms: '/additional-terms',
    term_of_user: '/term-of-use',
    profile_deleted: '/profile-deleted',
    covid_screen: '/patient/covid-screen',
    covid_self: '/patient/covid-self-assessment/:step',
    covid_self_1: '/patient/covid-self-assessment/step-1',
    covid_self_2: '/patient/covid-self-assessment/step-2',
    covid_self_3: '/patient/covid-self-assessment/step-3',
    covid_self_4: '/patient/covid-self-assessment/step-4',
    covid_self_5: '/patient/covid-self-assessment/step-5',
    covid_self_6: '/patient/covid-self-assessment/step-6',
    covid_self_7: '/patient/covid-self-assessment/step-7',
    covid_self_8: '/patient/covid-self-assessment/step-8',
    covid_self_9: '/patient/covid-self-assessment/step-9',
    how_works: '/how-docscheduler-works',
    dashboard: '/patient/dashboard',
    // searchby: '/patient/searchby',
    searchby: '/patient/searchby/:type',
    // myaccount: '/patient/my-account',
    // account_profile: '/patient/my-account/:type',
    redirection: '/leaving-docscheduler',
    myaccount: '/patient/my-account/:page',
    accountprofile: '/patient/my-account/profile',
    accountsecurity: '/patient/my-account/security',
    account_profile: '/patient/my-account/:page/:type/:cardId?',


    notification: '/patient/notifications',
    health_grade: '/patient/health-grade',
    health_grade_result: '/patient/health-grade-result',
    care_team: '/patient/care-team',
    doctor_search_result: '/patient/doctor-search-result',
    doctor_profile: '/patient/doctor-profile',
    accept_cookies: '/patient/accept-cookies',


};

const AdminLinks = {
    admin_root: '/admin',
    admin_login: '/admin/login',
    admin_dashboard: '/admin/dashboard',
    admin_provider_management: '/admin/provider/management',
    admin_revenue_management: '/admin/revenue/management',
    admin_patient_management: '/admin/patient/management',
    admin_provider_detail: '/admin/provider/detail',
    admin_patient_detail: '/admin/patient/detail',
    admin_patient_add_detail: '/admin/patient/adddetail',
    admin_speciality: '/admin/speciality',
    admin_insurance: '/admin/insurance',
    admin_content_management: '/admin/content-management',
    admin_rating_review: '/admin/rating-review',
    admin_rating_management: '/admin/rating-review/management',
    admin_notification: '/admin/notification',
    admin_appointments: '/admin/manage-appointments',
    admin_subscription: '/admin/manage-subscription',
    admin_manage_sub_admin: '/admin/manage-admin',
    admin_revenue: '/admin/revenue',
    admin_verify: '/admin/verify',
}

export { ProviderLinks, PatientLinks, AdminLinks };