import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';

import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';






const Covidstep1 = () => {

  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <HomeHeader/>
      </Container>
      


      <Container fluid className="covid-screens-wrap">
        <Row>
          <Container>
            <Row>
                <div className="col-12">
                    <h1>Coronavirus (COVID-19) Symptoms/Self Screening</h1>
                </div>                
            </Row>
            <Row>
              <div className="col-12">
                <div className="covid-screens">
                    <div className="covid-yellowbox space-covid-screen1">
                        <div className="left-icon"><img src="/assets/images/orange-covid.svg" alt=""/></div>
                        <div className="right-text">
                            <span>Older adults and people who have severe underlying medical conditions</span>
                            <span>like heart or lung disease or diabetes seem to be at higher risk for developing more serious complications from COVID-19 illness.</span>
                        </div>
                    </div>
                    <div className="col-12 list">
                        <div>These symptoms may appear 2-14 days after exposure (based on the incubation period of MERS-CoV viruses).</div>
                        <ul>
                            <li>Fever</li>
                            <li>Dry Cough</li>
                            <li>Shortness of breath</li>
                            <li>Extreme fatigue</li>
                        </ul>
                        <div className="note"><span>Note:</span> This list is not all inclusive. Please consult your medical provider for any other symptoms that are severe or concerning.</div>
                    </div>
                    <div className="button-section">
                      <div className="next-btn-pro text-center"><a>Self Screening</a></div>
                      <a className="link">COVID-19 Testing Locations</a>
                    
                    </div>
                </div>
                
              </div>
            </Row>
          </Container>
        </Row>
      </Container>



      {/* footer */}
      <Footer/>
      
     
      
    </Fragment>
    );
  }
  
  export default Covidstep1;