import React, { Fragment, memo, useCallback, useEffect, useRef, useState, lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Row, ListGroup } from "react-bootstrap";
import NetworkLayer from "../../reactLayer/Network/Network";

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

const HomeSearch = props => {
  const { filter, setfilter } = props;
  const [searchCate, setSearchCate] = useState(filter.searchValue ? filter.searchValue : "");
  const [searchzipCity, setSearchZipCity] = useState("");
  const [displayCate, setDisplayCate] = useState(false);
  const [isSearchingCate, setIsSearchingCate] = useState(false);
  const [optionsCate, setOptionsCate] = useState([
    {
      specialityData: [],
      conditionData: [],
      treatmentData: [],
      doctorData: [],
    },
  ]);
  const wrapperCateRef = useRef(null);

  const redirectToSearchResult = () => {
    setfilter({ ...filter, callApi: true, ...{ cityOrZip: searchzipCity, searchValue: searchCate } });
  };

  const getAllFindResult = useCallback(async (input) => {
    if (input.length >= 3) {
      setIsSearchingCate(true);
      setDisplayCate(true);
      let dataNode = [
        {
          specialityData: [],
          conditionData: [],
          treatmentData: [],
          doctorData: [],
        },
      ];
      const submitData = {
        headers: {
          "Content-Type": "application/json",
        },
        url: "providers/search/suggestion/get",
        body: JSON.stringify({
          searchValue: input,
        }),
      };
      let result = await NetworkLayer.postData(submitData);
      if (result.body.status) {
        if (result.body.result) {
          setIsSearchingCate(false);
          const {
            specialityData,
            conditionData,
            treatmentData,
            doctorData,
          } = result.body.result;
          let specialityNode = specialityData.map(({ id, speciality }) => ({
            id: id,
            data: speciality,
          }));
          let doctorListNode = doctorData.map(
            ({ id, firstName, lastName }) => ({
              id: id,
              data:
                (firstName ? firstName : "") + (lastName ? " " + lastName : ""),
            })
          );
          dataNode[0].specialityData = specialityNode;
          dataNode[0].conditionData = conditionData;
          dataNode[0].treatmentData = treatmentData;
          dataNode[0].doctorData = doctorListNode;
          if (
            specialityNode.length === 0 &&
            conditionData.length === 0 &&
            treatmentData.length === 0 &&
            doctorData.length === 0
          ) {
            setDisplayCate(false);
          }
        }
      } else {
        setIsSearchingCate(false);
      }
      setOptionsCate(dataNode);
    }
  }, []);

  const debounceGet = useRef(
    debounce((nextValue) => getAllFindResult(nextValue), 1000)
  ).current;

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperCateRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplayCate(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const _renderListItem = (title = "SPECIALTY", items) => {
    return (
      <ListGroup.Item>
        {title}
        <ListGroup className="borderless">
          {items.map((each, index) => (
            <ListGroup.Item
              className="cateFindItem"
              key={title + "_" + index}
              onClick={() => updateCateDex(each.data)}
            >
              {each.data}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </ListGroup.Item>
    );
  };

  const updateCateDex = (each) => {
    setSearchCate(each);
    setDisplayCate(false);
  };

  const handleChange = (event) => {
    const nextValue = event?.target?.value;
    setSearchCate(nextValue);
    debounceGet(nextValue);
  };
  const suspenseLoader = () => <p></p>;
  return (
    <Suspense fallback={suspenseLoader()}>
      <Fragment>
        <Row>
          <div className="col-12">
            <div className="home-top-fields">
              <span ref={wrapperCateRef}>
                <input
                  type="text"
                  name="find"
                  placeholder="Condition, Specialty, Treatment, or Doctor Name"
                  className="input-home"
                  autoComplete="off"
                  value={searchCate}
                  onChange={handleChange}
                />
                <div className="find-home">Find:</div>
                {isSearchingCate && (
                  <div className="cateSearch">Searching ...</div>
                )}
                {displayCate &&
                  (optionsCate[0].specialityData.length > 0 ||
                    optionsCate[0].conditionData.length > 0 ||
                    optionsCate[0].treatmentData.length ||
                    optionsCate[0].doctorData.length > 0) && (
                    <div className="autoContainerCate">
                      <div className="autoContenrCate">
                        <ListGroup>
                          {optionsCate[0].specialityData.length > 0 &&
                            _renderListItem(
                              "SPECIALTY",
                              optionsCate[0].specialityData
                            )}
                          {optionsCate[0].conditionData.length > 0 &&
                            _renderListItem(
                              "CONDITION",
                              optionsCate[0].conditionData
                            )}
                          {optionsCate[0].treatmentData.length > 0 &&
                            _renderListItem(
                              "TREATMENT",
                              optionsCate[0].treatmentData
                            )}
                          {optionsCate[0].doctorData.length > 0 &&
                            _renderListItem("DOCTOR", optionsCate[0].doctorData)}
                        </ListGroup>
                      </div>
                    </div>
                  )}
                <div className="home-bottom-field-txt mt-2">
                  <span className="f-wdth mb-2">
                    <em>
                      <strong>Examples:</strong> Physical exam, Primary Care
                      doctor, back pain, allergies
                    </em>
                  </span>
                  <span className="home-check pt-0 d-md-block d-none f-wdth ">
                    <div className="cus-check">
                      <input type="checkbox" id="hipppa" name="docHippa" />
                      <label htmlFor="hipppa">
                        Select check box to search for children's doctors
                      </label>
                    </div>
                  </span>
                </div>
              </span>
              <span>
                <input
                  type="text"
                  name="find"
                  placeholder="Zip Code or City"
                  className="input-home"
                  autoComplete="off"
                  onChange={(event) => {
                    setSearchZipCity(event?.target?.value);
                  }}
                />
                <div className="find-home">Near:</div>
              </span>
              <span>
                <input
                  type="text"
                  name="find"
                  placeholder="Insurance Name"
                  className="input-home"
                />
              </span>
              <span>
                {/* <input type="button" onClick={()=>{redirectToSearchResult()}} className="search-home-btn" value="Search" /> */}
                <span className="home-check d-md-none f-wdth">
                  <div className="cus-check mb-3">
                    <input type="checkbox" id="hipppa" name="docHippa" />
                    <label htmlFor="hipppa">
                      Select check box to search for children's doctors
                    </label>
                  </div>
                </span>
                <input
                  type="button"
                  onClick={() => {
                    redirectToSearchResult();
                  }}
                  className="search-home-btn"
                  value="Search"
                />
              </span>
            </div>
          </div>
        </Row>
      </Fragment>
    </Suspense>
  );
};

HomeSearch.defaultProps = {
  filter: null,
  setfilter: null,
};

export default memo(HomeSearch);