import React, { Fragment, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AuthContext from '../../reactLayer/auth/AuthContext';

const CalculateResult = (props) => {
    const { toggle } = props;
    const authContext = useContext(AuthContext);

    const [data, setData] = useState(authContext.healthGradeResult);

    return (
        <Fragment>
            {/* {JSON.stringify(data)} */}
            {/* Calculator part */}
            <div className="health-grade-left">
                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Body Mass Index (BMI)
            </div>
                    <div className="health-grade-field-result">
                        <div>
                            <span className={data.bmi <= 18.5 && "result-highlight"}>Underweight (Below 18.5)</span>
                            <span className={data.bmi > 18.5 && data.bmi <= 24.9 && "result-highlight"}>Normal (18.5 - 24.9)</span>
                            <span className={data.bmi > 25 && data.bmi <= 29 && "result-highlight"}>Overweight (25.0 - 29.9)</span>
                            <span className={data.bmi > 30 && data.bmi <= 39.9 && "result-highlight"}>Obese (30.0 - 39.9)</span>
                            <span className={data.bmi >= 40 && "result-highlight"}>Morbidly Obese (40.0+)</span>
                        </div>
                        <div>
                            <div className={(data.bmi > 18.5 && data.bmi <= 24.9) ? "green-right" : "result-red-mark"}></div>
                            <div className="hg-result-text">
                                Your BMI is
                    <span className="info-hg">
                                    <div className="info-icon"></div>
                                    <div className="info-callout">
                                        <div className="callout-inner">
                                            <p>
                                                <strong>BMI Risk Overview</strong><br />
                                                Our bodies are not meant to carry more or less than a normal amount of weight and body fat. If you maintain a healthy weight, you can reduce your risk of weight-related diseases and health conditions, such as stroke, some cancers, Diabetes (Type 2), Hypertension, etc.
                        </p>
                                            <p>
                                                <strong>How to Reduce BMI Risk</strong><br />
                                                Maintaining a healthy weight isn’t a diet. It’s a lifestyle. Here are some ideas to keep you moving in the right direction:
                            <ul>
                                                    <li>Balance your calorie intake with your physical activity.</li>
                                                    <li>Eat more lean meats, fruits, vegetables, and whole grains and limit red meat and sugary food/drink intake.</li>
                                                    <li>Exercise more frequently.</li>
                                                    <li>Avoid tobacco smoke and limit alcohol consumption.</li>
                                                    <li>Set small and manageable goals for weight loss.</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </span>
                                <br />
                                {data.bmi}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Resting Heart Rate
            </div>
                    <div className="health-grade-field-result">
                        <div>
                            <span className={data.data.restingheartrate > 0 && data.data.restingheartrate <= 64 && "result-highlight"}>Excellent (54 - 64)</span>
                            <span className={data.data.restingheartrate > 65 && data.data.restingheartrate <= 73 && "result-highlight"}>Above Average (65 - 73)</span>
                            <span className={data.data.restingheartrate > 74 && data.data.restingheartrate <= 77 && "result-highlight"}>Average (74 - 77)</span>
                            <span className={data.data.restingheartrate > 78 && data.data.restingheartrate <= 84 && "result-highlight"}>Below Average (78 - 84)</span>
                            <span className={data.data.restingheartrate >= 85 && "result-highlight"}>Poor (85+)</span>
                        </div>
                        <div>
                            <div className={data.data.restingheartrate <= 73 ? "green-right" : "result-red-mark"}></div>
                            <div className="hg-result-text">
                                Your BPM is
                    <span className="info-hg">
                                    <div className="info-icon"></div>
                                    <div className="info-callout">
                                        <div className="callout-inner">
                                            <strong>Resting Heart Rate -</strong> Resting Heart Rate, or pulse, measures the number of times your heart beats per minute. An abnormal resting heart rate may identify an increased level of health related risk--heart attack, mental stress, in-activeness, etc.
                            </div>
                                    </div>
                                </span>
                                <br />
                                {data.data.restingheartrate}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Hours of Sleep Per Day
            </div>
                    <div className="health-grade-field-result">
                        <div>
                            <span className={data.data.sleep >= 8 && "result-highlight"}>Adequate (8 or more)</span>
                            <span className={data.data.sleep >= 6 && data.data.sleep <= 7 && "result-highlight"}>Below Adequate (6 - 7)</span>
                            <span className={data.data.sleep >= 3 && data.data.sleep <= 5 && "result-highlight"}>Poor (3 - 5)</span>
                            <span className={data.data.sleep <= 2 && "result-highlight"}>Below Average (2 or Less)</span>
                        </div>
                        <div>
                            <div className={data.data.sleep >= 6 ? "green-right" : "result-red-mark"}></div>
                            <div className="hg-result-text">
                                You Sleep {data.data.sleep}
                                <span className="info-hg">
                                    <div className="info-icon"></div>
                                    <div className="info-callout">
                                        <div className="callout-inner">
                                            <p>
                                                <strong>Hours of Sleep Per Day Risk Overview </strong><br />
                                                Lack of sleep can cause fatigue, short temper and lack of focus. Chronicle lack of sleep can affect your overall health and make you prone to serious medical conditions, such as obesity, heart disease, high blood pressure and diabetes.
                            </p>
                                            <p>
                                                <strong>How to Reduce Hours of Sleep Per Day Risk</strong>
                                                <ul>
                                                    <li>Avoid caffeine, alcohol, nicotine, and other chemicals that interfere with sleep.</li>
                                                    <li>Create a better sleep environment--quiet, dark, and cool environment can help promote sleep.</li>
                                                    <li>Establish a relaxing pre-sleep routine</li>
                                                    <li>Go to sleep when you’re truly tired</li>
                                                    <li>Limit electronics/screen time 1-2 hours before bed</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </span>
                                <br />
                                Hrs Per Day
                    </div>
                        </div>
                    </div>
                </div>

                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Cigarette/Tobacco Use
            </div>
                    <div className="health-grade-field-result">
                        <div>
                            <span className={data.data.tobacco == 'No' && "result-highlight"}>No</span>
                            <span className={data.data.tobacco == 'Yes' && "result-highlight"}>Yes</span>
                        </div>
                        <div>

                            <div className={data.data.tobacco == 'No' ? "green-right" : "result-red-mark"}></div>
                            <div className="hg-result-text">
                                You Do {data.data.tobacco == 'No' && "Not"}
                                <span className="info-hg">
                                    <div className="info-icon"></div>
                                    <div className="info-callout">
                                        <div className="callout-inner">
                                            <p>
                                                <strong>Smoking/Tobacco Use Health Risk Overview</strong><br />
                                                Smokers are more likely than nonsmokers to develop heart disease, stroke, and lung cancer. Smoking causes diminished overall health and increased health care use and cost.<br />
                                                Estimates show smoking increases the risk:
                                    <ul>
                                                    <li>For coronary heart disease by 2 to 4 times</li>
                                                    <li>For stroke by 2 to 4 times</li>
                                                    <li>Of men developing lung cancer by 25 times</li>
                                                    <li>Of women developing lung cancer by 25 times</li>
                                                </ul>
                                            </p>
                                            <p>
                                                <strong>How to Reduce Smoking/Tobacco Use Health Risk</strong>
                                                <br />
                                                <ul>
                                                    <li>Seek professional help and friends and family support</li>
                                                    <li>Identify your triggers (e.g., Driving, finishing a meal, etc.) and how to beat them</li>
                                                    <li>Learn to cope with withdrawal symptoms</li>
                                                    <li>Avoid smoking areas, smokers, and enablers</li>
                                                    <li>Celebrate victories during your journey to quit smoking</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </span>
                                <br />
                                Use Tobacco
                    </div>
                        </div>
                    </div>
                </div>


                {/* <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Blood Pressure
            </div>
                    <div className="health-grade-field-advance">
                        <div>
                            <span className="result-italic">Upper # / Systolic</span>
                            <span className="result-highlight">Normal (Less than 120)</span>
                            <span>Elevated (120 - 129)</span>
                            <span>Hypertension Stage 1 (130 - 139)</span>
                            <span>Hypertension Stage 2 (140 - 179)</span>
                            <span>Hypertension Stage 2 (180+)</span>
                            <span className="result-italic mt-4">Lower # / Diastolic</span>
                            <span className="result-highlight">Normal (Less than 80)</span>
                            <span>Elevated (Less than 80)</span>
                            <span>Hypertension Stage 1 (80 - 89)</span>
                            <span>Hypertension Stage 2 (90 - 119)</span>
                            <span>Hypertension Crises (120+)</span>
                        </div>
                        <div>
                            <div className="green-right"></div>
                            <div className="hg-result-text">
                                Your BP is
                    <span className="info-hg">
                                    <div className="info-icon"></div>
                                    <div className="info-callout">
                                        <div className="callout-inner">
                                            <p>
                                                <strong>Blood Pressure (BP) Health Risk Overview</strong><br />
                                                High blood pressure is a common disease in which blood flows through blood vessels, or arteries, at higher than normal pressures. High blood pressure, sometimes called hypertension, is when this force against the artery walls is high enough that it may eventually cause health problems, such as heart disease.
                                </p>
                                            <p>
                                                <strong>How to Reduce Blood Pressure (BP) Health Risk</strong>
                                                <ul>
                                                    <li>AQuit/don’t smoke, becoming a non-smoker may be the most important step you can take for your health. Quitting smoking will help to lower your blood pressure</li>
                                                    <li>Exercise on most days of the week for at least 30 minutes per day and increase your physical activity</li>
                                                    <li>Reduce consumption of certain foods and fats—red meat, dairy products, salt, cookies, crackers, cakes, and other processed foods</li>
                                                    <li>Increase your consumption of fiber rich foods, fruits, and vegetables</li>
                                                    <li>Maintain a healthy weight (e.g., normal BMI), carrying even a few extra pounds contributes to high blood pressure</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </span>
                                <br />
                                120/70
                    </div>
                        </div>
                    </div>
                </div>

                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Cholesterol Levels
            </div>
                    <div className="health-grade-field-advance">
                        <div>
                            <span>Total Cholesterol (TC)</span>
                            <span>Desirable (Less than 200)</span>
                            <span>Borderline (200 - 239)</span>
                            <span className="result-highlight">Undesirable (240+)</span>
                            <span className="result-italic mt-4">High-Density Lipoprotein (HDL)</span>
                            <span className="result-highlight">Desirable (60+)</span>
                            <span>Borderline (50 - 59)</span>
                            <span>Undesirable (Less Than 50)</span>
                            <span className="result-italic mt-4">Fasting Glucose</span>
                            <span className="result-highlight">Desirable (Less than 100)</span>
                            <span>Borderline (100 - 125)</span>
                            <span>Undesirable (126+)</span>
                        </div>
                        <div>
                            <div className="result-red-mark"></div>
                            <div className="hg-result-text">
                                Your TC/
                    <span className="info-hg">
                                    <div className="info-icon"></div>
                                    <div className="info-callout">
                                        <div className="callout-inner">
                                            <p>
                                                <strong>Cholesterol Health Risk Overview</strong><br />
                                                Too much cholesterol--a fat like substance-- in your blood, it builds up in the walls of your arteries and increases your risk of developing heart disease and heart attacks.
                            </p>
                                            <p>
                                                <strong>How to Reduce Cholesterol Health Risk</strong>
                                                <ul>
                                                    <li>Quit/don't smoke, smoking can increase your HDL cholesterol by upto 10%</li>
                                                    <li>Exercise on most days of the week and increase your physical activity</li>
                                                    <li>Reduce consumption of certain foods and fats—red meat, dairy products, cookies, crackers, cakes, and other processed foods</li>
                                                    <li>Increase your consumption of fiber rich foods, fruits, and vegetables</li>
                                                    <li>Maintain a healthy weight (e.g., normal BMI), carrying even a few extra pounds contributes to high cholesterol</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </span>
                                <br />
                                HDL/GLU is
                    <br />
                                244/63/85
                    </div>
                        </div>
                    </div>
                </div> */}

                <div className="hg-calc-button text-center text-md-left">
                    <input type="button" className="next-btn-pro" value="Calculate Again" onClick={() => toggle(false)} />
                </div>
            </div>

        </Fragment>
    );
}

export default CalculateResult;