import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg';
import { CSVLink } from "react-csv";
import NetworkLayer from '../../../reactLayer/Network/Network';
import * as moment from 'moment';
import Calendar from 'react-calendar';

const RatingExport = (props) => {
    const authContext = useContext(AuthContext);
    const [data, setData] = useState("");
    const csvLinkEl = useRef();
    const [header, setHeader] = useState([{ label: "", key: "name" }]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [formstartDate, setFormStartDate] = useState("");
    const [formendDate, setFormEndDate] = useState("");
    const [providerType, setProviderType] = useState("");
    const [showCal, setshowCal] = useState(false);
    const [plans, setPlans] = useState([]);
    const calendarRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setshowCal(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [calendarRef])

    const getExportList = async () => {
        console.log("getExportList");
        let url = "";
        let formData = {
            startFrom: formstartDate,
            endTo: formendDate,
            type: providerType
        }

        url = "admin/rating/export";
        // Add Insurance
        setHeader([
            { label: "Provider", key: "providername" },
            { label: "Patient", key: "patientname" },
            { label: "Rating", key: "rating" },
            { label: "Source", key: "source" },
            { label: "Message", key: "message" },
            { label: "Reviewed", key: "status" },
            { label: "Submission Date", key: "date" },
        ]);

        
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: url,
            body: JSON.stringify(formData),
        }

        authContext.setLoader();
        try {
            let result = await NetworkLayer.postData(submitData);
            console.log(result);
            let datares = result.body ?.result.map(item => ({
                providername: `${item.providerInfo ?.providerName ?.firstName} ${item.providerInfo ?.providerName ?.lastName}`,
                patientname: `${item.patientsInfo ?.patientName ?.firstName} ${item.patientsInfo ?.patientName ?.lastName}`,
                rating: item.rating,
                source: item.source,
                message: item.message,
                status: item.status == 1 ? 'Yes' : 'No',
                date: moment(item.createdAt).format('D MMM, Y')
            }))
            console.log(datares);
            setData(datares);
        } catch (err) {

        }
        authContext.unsetLoader();
    }

    const downloadReport = async () => {
        let result = await getExportList();
        csvLinkEl.current.link.click();
        authContext.hideModal()
    }

    const onChangeDate = (value, event) => {
        setStartDate(moment(value[0]).format('M/D/Y'));
        setFormStartDate(moment(value[0]).format('Y-M-D'))
        setEndDate(moment(value[1]).format('M/D/Y'));
        setFormEndDate(moment(value[1]).format('Y-M-D'))
        setshowCal(!showCal)
    }

    const selectProviderType = (e) => {
        console.log(e.target.value);
        setProviderType(e.target.value);
    }

    useEffect(() => {
        setPlans(props ?.plans)
        console.log("plans=============>", props ?.plans);
    }, [])

    return (
        <Fragment>
            {
                showCal && (
                    <div ref={calendarRef} class="ratings-calendar" style={{ 'zIndex': '10', position: 'relative', right: '20%', bottom: '-2em' }}>
                        <Calendar
                            onChange={onChangeDate}
                            maxDate={new Date()}
                            calendarType={'US'}
                            selectRange={true}
                            returnValue={"range"}
                        />
                    </div>
                )
            }
            <Container>
                <div className="search-modal-wrap">
                    <div className="edit-all-filter">
                        <Row>
                            <div className="col-12 px-0">
                                <div className="modal-head">Export Ratings &amp; Comments</div>
                            </div>
                        </Row>
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12">
                                    <div className="rating-export-wrap provider-form-edit">
                                        <div className="rating-export-inner select-option">
                                            <label className="pro-label">Provider Type</label>

                                            <select className="select" onChange={selectProviderType}>
                                                <option value="">All</option>
                                                {
                                                    plans.map((value, index) => (
                                                        <option value={value.id} > {value.name} </option>
                                                    ))
                                                }
                                            </select>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                        </div>
                                        <div className="rating-export-inner select-option">
                                            <div className="export-period">
                                                <div className="exp-period-left" style={{width:'100%'}} onClick={() => { setshowCal(!showCal) }}>
                                                    <input type="text" class="input-pro" name="date" id="date" placeholder="Select Date range" value={startDate && endDate ? startDate+' to '+endDate : ''} />
                                                    <div className="search-icon"><div className="calender-icon"></div></div>
                                                </div>
                                            </div>

                                        </div>
                                        <Row>
                                            <div className="col-12 mb-4 all-fltrbtn">
                                                <input type="button" className="rev-btn-pro" value="Cancel" onClick={() => authContext.hideModal()} />
                                                <input type="button" className="next-btn-pro" value="Save" onClick={downloadReport} />
                                            </div>
                                        </Row>
                                        <CSVLink
                                            headers={header}
                                            filename={`Ratings-and-Reviews-list.csv`}
                                            data={data}
                                            ref={csvLinkEl}
                                        />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </Fragment>
    )

}

export default RatingExport;