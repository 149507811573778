import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';
import Global from '../../reactLayer/global';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import { ProviderLinks } from '../../linkFile';
import EditCard from '../account-info/edit-card-detail';

    const PaymentPlans = (props) => {
    console.log('payment plan -->', props);
    const authContext = useContext(AuthContext);
    const TodayDate = new Date();
    const CalculatedDate = Global.addDays('', 60);
    const Tab2 = useRef(null);
    const currentDate = Global.getYearMonthDate(TodayDate);
    const calculatedDate = Global.getYearMonthDate(CalculatedDate);
    const [upgradeDetails, setupgradeDetails] = useState({
        planId: props.planId,
        planTypeId: ''
    });
    const [plan, setPlan] = useState(props.plan ? props.plan : {})
    const [key, setKey] = useState();
    console.log('plan details on payment page', plan);
    const changeTab = (e) => {
        e.preventDefault()
        props.setselectedplan && props.setselectedplan(e.currentTarget.dataset.rbeventkey);
        setKey(e.currentTarget.dataset.rbeventkey);
        let planType = e.currentTarget.dataset.rbeventkey;
        setupgradeDetails({
            planId: props.planId,
            planTypeId: planType
        })
    } 

    const getPlanById = async() => {
        if( props.planId ) {
            authContext.setLoader();
            let url = 'providers/plan/details/get/'+props.planId;
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: url
            }
            let result = await NetworkLayer.getRequest(submitData);
            authContext.unsetLoader();
            if( result.status === true ) {
                
                setPlan({...result.result.planDetails});
                
                if( authContext.user.providerCurrentPlan && authContext.user.providerCurrentPlan.planId && props.planId === authContext.user.providerCurrentPlan.planId ) {
                    console.log('step-5 result-1==>', result);
                    setKey(authContext.user.providerCurrentPlan.planTypeId);
                    setupgradeDetails({
                        planId: props.planId,
                        planTypeId: authContext.user.providerCurrentPlan.planTypeId
                    })
                } else {
                    console.log('step-5 result-2==>', result);
                    setKey(result.result.planDetails.subPlans.Annually.id);
                    setupgradeDetails({
                        planId: props.planId,
                        planTypeId: result.result.planDetails.subPlans.Annually.id
                    })
                }
            }
        } else {
            Tab2.current.click();
        }
    }
    
    useEffect(()=> {
        getPlanById();
        
    },[])

    


  return (
    <Fragment>
        {
            plan && plan.subPlans ? (<>
                <Container className="provider-form-inner tabComponent">
                    <Row >
                        <div className="seaprator-title"><span>Confirm Billing Cycle</span></div>
                    </Row>
                    <Row className="description">
                        {plan.subPlans.Annually.discount_percentage ? (<>
                            Select Annual billing cycle to <strong>Save {plan.subPlans.Annually.discount_percentage}%</strong> and expense with a <strong>single receipt. </strong>
                        </>) : (<></>) }
                    </Row>
                    { plan && Object.keys(plan).length ? (<>
                        <nav className="nav nav-tabs" role="tablist">
                            <a onClick={(e)=>changeTab(e)} id="controlled-tab-example-tab-home" href="#" role="tab" data-rbeventkey={plan.subPlans.Monthly.id} aria-controls="controlled-tab-example-tabpane-home" aria-selected="true" tabIndex={key == plan.subPlans.Monthly.id ? '' : '-1'} className={key == plan.subPlans.Monthly.id ? 'nav-item nav-link active' : 'nav-item nav-link' }>
                                <div className="tabTitle">
                                    <h3>{plan.planName}</h3>
                                    <p>${plan.subPlans.Monthly.discount_price ? (plan.subPlans.Monthly.price - plan.subPlans.Monthly.discount_price).toFixed(2) : plan.subPlans.Monthly.price}<span className="block-mobile">/Month</span></p>
                                </div>
                                <div className="topmsg">
                                    
                                </div>
                            </a>
                            <a ref={Tab2} onClick={(e)=>changeTab(e)} id="controlled-tab-example-tab-profile" href="#" role="tab" data-rbeventkey={plan.subPlans.Annually.id} aria-controls="controlled-tab-example-tabpane-profile" tabIndex={key == plan.subPlans.Annually.id ? '' : '-1'} aria-selected="false" className={key == plan.subPlans.Annually.id ? 'nav-item nav-link active' : 'nav-item nav-link' }>
                                <div className="tabTitle">
                                    <h3>{plan.planName}</h3>
                                    <p>${ plan.subPlans.Annually.discount_price ? (plan.subPlans.Annually.price - plan.subPlans.Annually.discount_price).toFixed(2) : plan.subPlans.Annually.price }<span className="block-mobile">/Annual</span></p>
                                </div>
                                <div className="topmsg">
                                    { plan.subPlans.Annually.discount_percentage ? 'Save '+ plan.subPlans.Annually.discount_percentage+'%' : '' }
                                </div>
                            </a>
                            <a onClick={(e)=>changeTab(e)} id="controlled-tab-example-tab-contact" href="#" role="tab" data-rbeventkey={plan.subPlans.Lifetime.id} aria-controls="controlled-tab-example-tabpane-contact" tabIndex={key == plan.subPlans.Lifetime.id ? '' : '-1'} aria-selected="false" className={key == plan.subPlans.Lifetime.id ? 'nav-item nav-link active' : 'nav-item nav-link' }>
                                <div className="tabTitle">
                                    <h3>{plan.planName}</h3>
                                    <p>${plan.subPlans.Lifetime.discount_price ? (plan.subPlans.Lifetime.price - plan.subPlans.Lifetime.discount_price).toFixed(2) : plan.subPlans.Lifetime.price}<span className="block-mobile">/Lifetime</span></p>
                                </div>
                                <div className="topmsg">
                                    One Time Payment
                                </div>
                            </a>
                        </nav>

                        <div className="tab-content">
                            <div id="controlled-tab-example-tabpane-home" aria-labelledby="controlled-tab-example-tab-home" role="tabpanel" aria-hidden={key == plan.subPlans.Monthly.id ? "true" : "false" } className={key == plan.subPlans.Monthly.id ? "fade tab-pane active show" : "fade tab-pane" }>
                                <Container className="">
                                    <Row className="particular">
                                        <Col md="6" className="col-8">
                                            Monthly Charge (${plan.subPlans.Monthly.price})
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            ${plan.subPlans.Monthly.price}
                                        </Col>
                                    </Row>
                                    { plan.subPlans.Monthly.discount_percentage ? (<>
                                    <Row className="particular">
                                        <Col md="6" className="col-8">
                                            Monthly Discount (Save {plan.subPlans.Monthly.discount_percentage}%)
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            <span className="red-color">(${plan.subPlans.Monthly.discount_price})</span>
                                        </Col>
                                    </Row>
                                    
                                    
                                    </>): (<></>)}
                                    
                                </Container>
                                <Container className="">
                                    <Row className="totalTrail">
                                        <Col md="6" className="col-8">
                                            Total After Free Trial
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            ${
                                                plan.subPlans.Monthly.discount_price ? (plan.subPlans.Monthly.price - plan.subPlans.Monthly.discount_price).toFixed(2) : plan.subPlans.Monthly.price
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="">
                                    
                                    <Row className="total">
                                        <Col md="6" className="col-8">
                                            Today’s Total
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            $0.00
                                        </Col>
                                    </Row>
                                </Container>
                                <Row className="des">
                                    <Col md="12">
                                        Your free trial begins on {currentDate.month}/{currentDate.date}/{currentDate.year} and will end on {calculatedDate.month}/{calculatedDate.date}/{calculatedDate.year}. You can cancel anytime before {calculatedDate.month}/{calculatedDate.date}/{calculatedDate.year} to avoid being charged and we'll send an email 7 days before the trial ends.
                                    </Col>
                                
                                </Row>
                            </div>
                            <div id="controlled-tab-example-tabpane-home" aria-labelledby="controlled-tab-example-tab-home" role="tabpanel" aria-hidden={key == plan.subPlans.Annually.id ? "true" : "false" } className={key == plan.subPlans.Annually.id ? "fade tab-pane active show" : "fade tab-pane" }>
                                <Container className="">
                                    <Row className="particular">
                                        <Col md="6" className="col-8">
                                            Annual Charge (${plan.subPlans.Annually.price ? (plan.subPlans.Annually.price/12).toFixed(2) : "--"}x12)
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            ${plan.subPlans.Annually.price ? plan.subPlans.Annually.price : "--"}
                                        </Col>
                                    </Row>
                                    {
                                        plan.subPlans.Annually.discount_percentage ? (<>
                                        <Row className="particular">
                                            <Col md="6" className="col-8">
                                                Annual Discount (Save {plan.subPlans.Annually.discount_percentage}%)
                                            </Col>
                                            <Col md="6" className="text-right col-4">
                                                <span className="red-color">(${plan.subPlans.Annually.discount_price})</span>
                                            </Col>
                                        </Row>
                                        
                                        </>) :(<></>)
                                    }
                                    
                                </Container>
                                <Container className="">
                                    <Row className="totalTrail">
                                        <Col md="6" className="col-8">
                                            Total After Free Trial
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            ${ plan.subPlans.Annually.discount_price ? (plan.subPlans.Annually.price - plan.subPlans.Annually.discount_price).toFixed(2) : plan.subPlans.Annually.price }
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="">
                                    
                                    <Row className="total">
                                        <Col md="6" className="col-8">
                                            Today’s Total
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            $0.00
                                        </Col>
                                    </Row>
                                </Container>
                                <Row className="des">
                                    <Col md="12">
                                        Your free trial begins on {currentDate.month}/{currentDate.date}/{currentDate.year} and will end on {calculatedDate.month}/{calculatedDate.date}/{calculatedDate.year}. You can cancel anytime before {calculatedDate.month}/{calculatedDate.date}/{calculatedDate.year} to avoid being charged and we'll send an email 7 days before the trial ends.
                                    </Col>
                                </Row>
                            </div>
                            <div id="controlled-tab-example-tabpane-home" aria-labelledby="controlled-tab-example-tab-home" role="tabpanel" aria-hidden={key == plan.subPlans.Lifetime.id ? "true" : "false" } className={key == plan.subPlans.Lifetime.id ? "fade tab-pane active show" : "fade tab-pane" }>
                                <Container className="">
                                    <Row className="particular">
                                        <Col md="6" className="col-8">
                                            Lifetime Charge (${plan.subPlans.Lifetime.price})
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            ${plan.subPlans.Lifetime.price}
                                        </Col>
                                    </Row>
                                    {
                                        plan.subPlans.Lifetime.discount_percentage ? (<>
                                        <Row className="particular">
                                            <Col md="6" className="col-8">
                                                Lifetime Discount (Save {plan.subPlans.Lifetime.discount_percentage}%)
                                            </Col>
                                            <Col md="6" className="text-right col-4">
                                                <span className="red-color">(${plan.subPlans.Lifetime.discount_price})</span>
                                            </Col>
                                        </Row>
                                        
                                        </>) : (<></>)
                                    }
                                    
                                </Container>
                                <Container className="">
                                    <Row className="totalTrail">
                                        <Col md="6" className="col-8">
                                            Total After Free Trial
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            ${ plan.subPlans.Lifetime.discount_price ? (plan.subPlans.Lifetime.price - plan.subPlans.Lifetime.discount_price).toFixed(2) : plan.subPlans.Lifetime.price }
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="">
                                    
                                    <Row className="total">
                                        <Col md="6" className="col-8">
                                            Today’s Total
                                        </Col>
                                        <Col md="6" className="text-right col-4">
                                            $0.00
                                        </Col>
                                    </Row>
                                </Container>
                                <Row className="des">
                                    <Col md="12">
                                        Your free trial begins on {currentDate.month}/{currentDate.date}/{currentDate.year} and will end on {calculatedDate.month}/{calculatedDate.date}/{calculatedDate.year}. You can cancel anytime before  {calculatedDate.month}/{calculatedDate.date}/{calculatedDate.year} to avoid being charged and we'll send an email 7 days before the trial ends.
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </> ): (<>
                    
                    
                    </>)}
                    
                </Container>
            
            </>) : (<></>)
        }
        
        { props.elementName === 'upgrade-subscription' ? (<EditCard  {...props } upgradeDetails={upgradeDetails} />) : (<></>)}
        
    </Fragment>
    );
  }
  
  export default PaymentPlans;