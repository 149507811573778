import React, { Fragment, useEffect, useState, useContext } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import Custommodal from '../../../global/modal/modal';
import 'bootstrap/dist/css/bootstrap.css';
import { AdminLinks } from '../../../linkFile';
import { Link } from 'react-router-dom';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import PatientManagementInfoMadal from './patient-manage-info-madal';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Loadermodal from "../../../global/loader-modal/loader-modal";
import Pagination from "../../../components/admin/pagination/pagination";
import ManagementFilter from '../../../components/admin/patient/filter';
import * as moment from 'moment';

let searchText = ''
const ManagementTable = () => {

    const authContext = useContext(AuthContext);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const modalList = ['manageInfo'];
    const openProfileTabModal = (modalName, value) => {
        getDetails(value ?.id).then(() => {
            settabvisibleComponent(modalName)
            authContext.showModal()
        })
    }

    const [totalCount, settotalCount] = useState(0);
    const [filter, setfilter] = useState({
        offset: 0,
        limit: 10
    });
    const [data, setData] = useState([]);
    const [viewdetails, setViewdetails] = useState([]);
    const [search, setSearch] = useState("");
    const [sort, setsort] = useState('ASC');
    const [sortName, setSortName] = useState('Name');

    const getList = async (value) => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (value || searchText) {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                query: searchText,
                sortOrder: sort,
                sortBy: sortName
            }
        } else {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                sortOrder: sort,
                sortBy: sortName
            }
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getAllPatientDetails',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        // console.log("result==>", result.body.result);
        setData(result ?.body ?.result ?.rows);
        settotalCount(result ?.body ?.result ?.count);
        authContext.unsetLoader();
    }

    const getDetails = async (id) => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        formData = {
            id: id
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getPatient',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);


        setViewdetails(result ?.body ?.result);
        authContext.unsetLoader();
    }

    const toggleStatus = (values) => {
        let status = values.status == 1 ? 0 : 1;
        let id = values.id;
        updateStatus(id, status);
    }

    const updateStatus = async (id, status) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/updatePatientStatus',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        authContext.unsetLoader();
        getList();
    }

    // PAGINATION CALLBACK
    const changeLimit = (page) => {
        console.log('page')
        setfilter({ ...filter, ...{ offset: parseInt(page) } });
    };

    const searchFilter = (value) => {
        setfilter({offset:0,limit:10})
        searchText = value
        getList(value);
    }

    const changeSorting = (e) => {
        let name = e.currentTarget.getAttribute('name');
        setSortName(name);
        setsort(sort == "ASC" ? "DESC" : "ASC")
    };

    useEffect(() => {
        getList();
    }, [filter.offset, sort])

    return (
        <Fragment>
            <Loadermodal />
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'manageInfo' ? <PatientManagementInfoMadal data={viewdetails} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <ManagementFilter search={searchFilter} />
            <div className="table-report-scroll mt-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="data-sort" style={{ 'min-width': '150px' }}>
                                <span className="sort-outer">
                                    Patient Name
                                <span className="data-sort-icon" name="Name" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>
                                <span className="sort-outer">
                                    Age
                                <span className="data-sort-icon" name="Age" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>
                                <span className="sort-outer">
                                    Sex
                                <span className="data-sort-icon" name="Gender" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>
                                <span className="sort-outer">
                                    Joined Date
                                <span className="data-sort-icon" name="Joined_Date" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Email</th>
                            <th style={{ 'min-width': '200px' }}>Medical Ins. Name</th>
                            <th>Dental Ins. Name</th>
                            <th>Location</th>
                            <th style={{ 'min-width': '250px' }}>
                                <span className="sort-outer">
                                    Last Appt. Request Date
                                <span className="data-sort-icon" name="LastApReq" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>
                                <span className="sort-outer">
                                    Active
                                <span className="data-sort-icon" name="Status" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((value, index) =>
                                <tr key={index}>
                                    <td><Link>{`${value ?.patients ?.firstName} ${value ?.patients ?.lastName}`}</Link></td>
                                    <td>{value ?.patients ?.age}</td>
                                    <td>{value ?.patients ?.gender}</td>
                                    <td>{moment(value ?.patients ?.joining_date).format('MM/DD/YYYY')}</td>
                                    <td>{value ?.email}</td>
                                    <td>{value ?.patientMedicalInsurances[0] ?.insurances ?.name}</td>
                                    <td>{value ?.patientDentalInsurances[0] ?.insurances ?.name}</td>
                                    <td>{`${value ?.patients ?.cities ?.name}, ${value ?.patients ?.cities ?.states ?.name}`}</td>
                                    <td>{value ?.patients ?.lastAppointmentDate ? moment(value ?.patients ?.lastAppointmentDate).format('MM/DD/YYYY - HH:mm') : ''}</td>
                                    <td>
                                        <label class="active-toggle">
                                            <input type="checkbox" checked={value ?.status == 1 ? "checked" : ""} onChange={() => { toggleStatus(value) }} />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <Link onClick={() => { openProfileTabModal("manageInfo", value) }} className="table-action view-all">View</Link>
                                        <Link to={`${AdminLinks.admin_patient_detail}/${value.id}`} className="table-action view-all">Edit</Link>
                                    </td>
                                </tr>
                            )
                        }

                    </tbody>
                </table>
            </div>
            <div className="report-page mt-4">
                <Pagination
                    total={totalCount}
                    limit={filter.limit}
                    range={4}
                    offset={filter.offset}
                    callBack={changeLimit}
                />
            </div>
        </Fragment>
    );
}

export default ManagementTable;