import React, { Fragment, useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useHistory } from "react-router-dom";
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';
import InputValidation from '../../reactLayer/validation';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import { ProviderLinks } from '../../linkFile';




const PaymentDetails = (props) => {
    const authContext = useContext(AuthContext);
    const [cardList, setcardList] = useState({})
    const history = useHistory();
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    console.log('Global card formator', Global.cardFormatorReset('1234 5612 3635 2636'));
    const [cardDetails, setcardDetails] = useState({

        name: {
            value: cardList.cardHolderName ? cardList.cardHolderName : '',
            validation: ['blank', 'name'],
            errorMsg: ['This field is required', 'Invalid name'],
            errorKey: 'name',
        },
        card: {
            value: cardList.cardNumber ? Global.cardFormator(cardList.cardNumber) : '',
            validation: ['blank', 'card'],
            errorMsg: ['This field is required', 'Invalid number'],
            errorKey: 'card',
        },
        expiry: {
            value: cardList.expiration ? cardList.expiration : '',
            validation: ['blank', 'expiry'],
            errorMsg: ['This field is required', 'Invalid expiry'],
            errorKey: 'expiry',
        },
        cvv: {
            value: cardList.securityCode ? cardList.securityCode : '',
            validation: ['blank', 'cvv'],
            errorMsg: ['This field is required', 'Invalid cvv'],
            errorKey: 'cvv',
        },
        zip: {
            value: cardList.billingZip ? cardList.billingZip : '',
            validation: ['blank', 'number'],
            errorMsg: ['This field is required', 'Invalid zip'],
            errorKey: 'zip',
        }
    });
    const formReset = () => {
        history.push({
            pathname: `${ProviderLinks.accountprofile}/card-list`,
        });
    }
    const formSave = async () => {
        let tempcardDetails = cardDetails;
        let flag = true;

        let validationResult = InputValidation(cardDetails);
        if (Object.keys(validationResult).length > 0) {
            flag = false;
            for (const prop in validationResult) {
                tempcardDetails[prop][prop] = `${validationResult[prop]}`;
            }
            setcardDetails({ ...tempcardDetails });
        }
        if (flag) {
            authContext.setLoader();
            const fData = {
                "cardType": "",
                "cardHolderName": cardDetails.name.value,
                "cardNumber": Global.cardFormatorReset(cardDetails.card.value),
                "expiration": cardDetails.expiry.value,
                "securityCode": cardDetails.cvv.value,
                "billingZip": cardDetails.zip.value,
            }
            let url = 'providers/credit-card/add';
            if (cardList.id && cardList.id !== 0) {
                fData.id = cardList.id;
                url = 'providers/credit-card/update';
            }
            console.log('fData', fData);
            console.log(fData, JSON.stringify(fData));
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: url,
                body: JSON.stringify(fData),

            }
            let result = await NetworkLayer.postData(submitData);

            console.log('result', result);
            if (result.body.status) {
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                    closeredirection: ProviderLinks.accountprofile + '/card-list',
                });
                authContext.unsetLoader();
                authContext.showResponseModal();

            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.unsetLoader();
                authContext.showResponseModal();
            }

        }

    }
    const formSubmit = async () => {
        let tempcardDetails = cardDetails;
        let flag = true;
        let validationResult = InputValidation(cardDetails);
        if (Object.keys(validationResult).length > 0) {
            flag = false;
            for (const prop in validationResult) {
                tempcardDetails[prop][prop] = `${validationResult[prop]}`;
            }
            setcardDetails({ ...tempcardDetails });
        }
        // console.log('medical current value ', practiceData);
        let planId = props.plan.id;
        let planType = props.selectedplan == '1' ? props.plan.subPlans.Monthly.id : props.selectedplan == '2' ? props.plan.subPlans.Annually.id : props.plan.subPlans.Lifetime.id;
        if (flag) {

            authContext.setLoader();
            const fData = {
                "planId": planId,
                "planTypeId": planType,
                "cardType": "",
                "cardHolderName": cardDetails.name.value,
                "cardNumber": Global.cardFormatorReset(cardDetails.card.value),
                "expiration": cardDetails.expiry.value,
                "securityCode": cardDetails.cvv.value,
                "billingZip": cardDetails.zip.value,
                "stripeToken": "tok_visa"
            }

            console.log('fData', fData);
            console.log(fData, JSON.stringify(fData));
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/signup/plan/save',
                body: JSON.stringify(fData),

            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            console.log('result123', result);
            if (result.body.status) {
                let user = result.body.result;
                authContext.setUser(user);
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                    //redirection: ProviderLinks.twofactauth,
                    //closeredirection: ProviderLinks.twofactauth
                });
                authContext.showResponseModal();
                setTimeout(()=>{
                    history.push({
                        pathname: '/provider/payment-receipt/'+user.providerCurrentPlan.purchase_plan_id,
                    });
                },1000)
            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }

    }
    const modifyCardData = (e) => {

        let index, name, value,
            tempData = {};
        name = e.currentTarget.getAttribute('data-name');
        value = e.currentTarget.value;
        //  return false;
        if (name == "expiry") {
            let textTemp = value;
            if (textTemp[0] !== '1' && textTemp[0] !== '0') {
                textTemp = '';
            }
            if (textTemp.length === 2) {
                if (
                    parseInt(textTemp.substring(0, 2)) > 12 ||
                    parseInt(textTemp.substring(0, 2)) == 0
                ) {
                    textTemp = textTemp[0];
                } else if (cardDetails.expiry.value.length === 1) {
                    textTemp += '/';
                } else {
                    textTemp = textTemp[0];
                }
            }
            value = textTemp;
            // if (value.length == 2 && value != "/") {
            //     value = value + '/'
            // }
        }
        tempData = cardDetails;
        if (name == 'card') {
            tempData[name]['value'] = Global.cardFormator(value);
        } else {
            tempData[name]['value'] = value;
        }
        tempData[name][name] = '';

        setcardDetails({ ...tempData });
    }
    const getCards = async (id) => {

        authContext.setLoader();
        let url = `providers/credit-card/get/${id}`;
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: url
        }
        let result = await NetworkLayer.getRequest(submitData);
        console.log('result-cards', result.result);
        authContext.unsetLoader();
        if (result.status === true) {
            setcardList({ ...result.result });
            setcardDetails({
                name: {
                    value: result.result.cardHolderName ? result.result.cardHolderName : '',
                    validation: ['blank', 'name'],
                    errorMsg: ['This field is required', 'Invalid name'],
                    errorKey: 'name',
                },
                card: {
                    value: result.result.cardNumber ? Global.cardFormator(result.result.cardNumber) : '',
                    validation: ['blank', 'card'],
                    errorMsg: ['This field is required', 'Invalid number'],
                    errorKey: 'card',
                },
                expiry: {
                    value: result.result.expiration ? result.result.expiration : '',
                    validation: ['blank', 'expiry'],
                    errorMsg: ['This field is required', 'Invalid expiry'],
                    errorKey: 'expiry',
                },
                cvv: {
                    value: result.result.securityCode ? result.result.securityCode : '',
                    validation: ['blank', 'cvv'],
                    errorMsg: ['This field is required', 'Invalid cvv'],
                    errorKey: 'cvv',
                },
                zip: {
                    value: result.result.billingZip ? result.result.billingZip : '',
                    validation: ['blank', 'number'],
                    errorMsg: ['This field is required', 'Invalid zip'],
                    errorKey: 'zip',
                }
            })
        }
        console.log('cardList', result.result);
    }

    useEffect(() => {
        if (props.cardId) {
            getCards(props.cardId)
        }
        return () => {
            authContext.hideResponseModal();
        }
    }, [])

    return (
        <Fragment>
            <Container className="provider-form-inner cardFormContainer">
                <Row>
                    {
                        authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                    }
                </Row>
                <Row >
                    <div className="seaprator-title"><span>Enter Payment Details</span></div>
                </Row>
                <Row className="cardForm">

                    <div className="col-md-12 mb-2 mb-md-4">
                        <div className="radio-payment">
                            <div className="cus-radio">
                                <input type="radio" id="visa" name="payment" defaultChecked />
                                <label htmlFor="visa">
                                    {/* <img src="/assets/images/visa-img.png" alt="Info" className="scale pay-icon" /> */}
                                    <div className="payment-card-icon"></div>
                                </label>
                            </div>
                            {/* <div className="cus-radio">
                            <input type="radio" id="payPal" name="payment" />
                            <label htmlFor="payPal"><img src="/assets/images/paypal-img.png" alt="Info" className="scale pay-pal-icon" /></label>
                        </div> */}
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <label htmlFor="cardname" className="pro-label">Name on Card</label>
                        <input type="text" name="cardname" placeholder="Name" autoComplete="off"
                            className={cardDetails.name.name ? "input-pro  error-border" : "input-pro"}
                            value={cardDetails.name.value}
                            data-name="name"
                            onChange={(e) => { modifyCardData(e) }}
                        />
                        <small className="error-input">{cardDetails.name.name}</small>

                    </div>
                    <div className="col-md-12 mb-2">
                        <label htmlFor="cardnumber" className="pro-label">Card Number</label>
                        <input type="text" name="cardnumber" placeholder="#### #### #### #####" autoComplete="off"
                            className={cardDetails.card.card ? "input-pro  error-border" : "input-pro"}
                            value={cardDetails.card.value}
                            data-name="card"
                            onChange={(e) => { modifyCardData(e) }}
                        />
                        <small className="error-input">{cardDetails.card.card}</small>

                    </div>
                    <div className="col-md-4 mb-3 mb-md-4">
                        <label htmlFor="expiration" className="pro-label">Expiration</label>
                        <input type="text" name="expiration" placeholder="MM/YYYY" autoComplete="off"
                            className={cardDetails.expiry.expiry ? "input-pro  error-border" : "input-pro"}
                            value={cardDetails.expiry.value}
                            data-name="expiry"
                            onChange={(e) => { modifyCardData(e) }}
                            maxLength={7}
                        />
                        <small className="error-input">{cardDetails.expiry.expiry}</small>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-4">
                        <label htmlFor="securitycard" className="pro-label">Card Security Code</label>
                        <input type="password" name="securitycard" className="input-pro" placeholder="###" autoComplete="new-password"
                            className={cardDetails.cvv.cvv ? "input-pro  error-border" : "input-pro"}
                            value={cardDetails.cvv.value}
                            data-name="cvv"
                            onChange={(e) => { modifyCardData(e) }}
                        />
                        <small className="error-input">{cardDetails.cvv.cvv}</small>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-4">
                        <label htmlFor="billingzip" className="pro-label">Billing Zip</label>
                        <input type="text" name="billingzip" className="input-pro" placeholder="" autoComplete="off"
                            className={cardDetails.zip.zip ? "input-pro  error-border" : "input-pro"}
                            value={cardDetails.zip.value}
                            data-name="zip"
                            onChange={(e) => { modifyCardData(e) }}
                        />
                        <small className="error-input">{cardDetails.zip.zip}</small>
                    </div>
                    {
                        authContext.user.isProfileCompleted ? (<>
                            <div className="card-buttons text-center">
                                <input type="button" className="next-btn-pro" onClick={(e) => { formSave() }} value="Save" />
                                <input type="button" className="rev-btn-pro" onClick={(e) => { formReset() }} value="Cancel" />
                            </div>

                        </>) : (<>
                            <div className="col-md-12 mb-4 mb-md-5 text-center payment-submit">
                                <input onClick={(e) => { formSubmit() }} type="button" className="next-btn-pro" value="Submit" />
                            </div>
                        </>)
                    }




                </Row>
            </Container>
        </Fragment>
    );
}

export default PaymentDetails;