import React, { Fragment, useContext, useState, useEffect, lazy, Suspense } from 'react';
import { useHistory } from "react-router-dom";
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { ProviderLinks } from '../../linkFile';
import Custommodal from '../../global/modal/modal';
import EditInsurance from '../dashboard-modals/edit-insurance';
import EditFocusArea from '../dashboard-modals/edit-focus-area';
import Gmaps from '../googlemap/googlemap';
import ManageAddress from '../profile-tabs/manage-address';

const ProfileTabs = (props) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const baseAddress = 'https://www.google.co.in/maps/dir/';
    const browserAddress = props.addressInfo && props.addressInfo.formattedAddress ? props.addressInfo.formattedAddress : false;
    const practiceInfo = props.practiceInfo ? props.practiceInfo : {};
    const selectedIns = props.practiceInfo && props.practiceInfo.insuranceAccepted ? props.practiceInfo.insuranceAccepted : []
    const medicalInfo = props.medicalSpeciality ? props.medicalSpeciality : {}
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const modalList = ['editLocation', 'editInsurance', 'editFocusArea', 'addFocusArea', 'addLocation'];
    // console.log('props of profile tab', props);
    const [gmapData, setgmapData] = useState([]);
    const [visibleComponent, setVisibleComponent] = useState('');
    const editable = props.editable ? props.editable : false
    const openModal = (modalName) => {
        // authContext.hideModal()
        settabvisibleComponent(modalName)
        authContext.showModal()
    }

    const openProfileTabModal = (modalName) => {
        // authContext.hideModal()
        settabvisibleComponent(modalName)
        authContext.showModal()
    }

    const setProfile = () => {
        debugger;
        props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
    }
    const [key, setKey] = useState('1');
    const changeTab = (e) => {
        e.preventDefault()
        setKey(e.currentTarget.dataset.rbeventkey);
    }
    const modifyLocation = (path) => {
        if (path === 'add') {
            history.push({ pathname: ProviderLinks.add_address })
        } else {
            history.push({ pathname: ProviderLinks.edit_address })
        }

    }
    const setGmapData = () => {
        let locations = [];
        // console.log('test for lat lng', practiceInfo);
        practiceInfo.providerPracticeLocations && practiceInfo.providerPracticeLocations.map((l, li) => {

            if (l.latitude && l.longitude) {
                // console.log('test for lat lng----inside');
                locations.push({ lat: parseFloat(l.latitude), lng: parseFloat(l.longitude) })
            }
        })
        // console.log('test for lat lng----outside',locations );
        setgmapData([...locations]);
    }
    useEffect(() => {
        setGmapData();
    }, [props])
    const suspenseLoader = () => <p></p>;
    return (
        <Suspense fallback={suspenseLoader()}>
            <Fragment>
                {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                    <Custommodal unsetComponent={settabvisibleComponent} componentName={
                        tabvisibleComponent === 'editLocation' ? <ManageAddress setVisibleComponent={settabvisibleComponent} tabvisibleComponent={tabvisibleComponent} getProfile={setProfile} />
                            :
                            tabvisibleComponent === 'editInsurance' ? <EditInsurance setVisibleComponent={settabvisibleComponent} selectedIns={selectedIns} getProfile={setProfile} />
                                :
                                tabvisibleComponent === 'editFocusArea' ? <EditFocusArea setVisibleComponent={settabvisibleComponent} tabvisibleComponent={tabvisibleComponent} medicalInfo={medicalInfo} getProfile={setProfile} />
                                    :
                                    tabvisibleComponent === 'addFocusArea' ? <EditFocusArea setVisibleComponent={settabvisibleComponent} tabvisibleComponent={tabvisibleComponent} getProfile={setProfile} medicalInfo={medicalInfo} />
                                        :
                                        tabvisibleComponent === 'addLocation' ? <ManageAddress setVisibleComponent={settabvisibleComponent} tabvisibleComponent={tabvisibleComponent} getProfile={setProfile}/>
                                            :
                                            <></>
                    } />
                ) : (
                        <></>
                    )}

                <Container>
                    <Row>
                        <div className="col-12 mb-4">
                            <div className="profile-tabs">
                                <nav className="nav nav-tabs" role="tablist">
                                    <a onClick={(e) => changeTab(e)} id="controlled-tab-example-tab-home" href="#" role="tab" data-rbeventkey="1" aria-controls="controlled-tab-example-tabpane-home" aria-selected="true" tabIndex={key == '1' ? '' : '-1'} className={key == '1' ? 'nav-item nav-link active' : 'nav-item nav-link'}>
                                        <div className="tabTitle">
                                            <h3>Locations</h3>
                                        </div>
                                    </a>
                                    <a onClick={(e) => changeTab(e)} id="controlled-tab-example-tab-profile" href="#" role="tab" data-rbeventkey="2" aria-controls="controlled-tab-example-tabpane-profile" tabIndex={key == '2' ? '' : '-1'} aria-selected="false" className={key == '2' ? 'nav-item nav-link active' : 'nav-item nav-link'}>
                                        <div className="tabTitle">
                                            <h3>Insurances <span>Accepted</span></h3>
                                        </div>
                                    </a>
                                    <a onClick={(e) => changeTab(e)} id="controlled-tab-example-tab-contact" href="#" role="tab" data-rbeventkey="3" aria-controls="controlled-tab-example-tabpane-contact" tabIndex={key == '3' ? '' : '-1'} aria-selected="false" className={key == '3' ? 'nav-item nav-link active' : 'nav-item nav-link'}>
                                        <div className="tabTitle">
                                            <h3>Focus <span>Areas</span></h3>
                                        </div>
                                    </a>
                                </nav>

                                <div className="tab-content">
                                    <div id="controlled-tab-example-tabpane-home" aria-labelledby="controlled-tab-example-tab-home" role="tabpanel" aria-hidden={key == '1' ? "true" : "false"} className={key == '1' ? "fade tab-pane active show" : "fade tab-pane"}>
                                        { editable && <div style={{"textAlign":"right", position: "absolute", top: "10px",right: "0"}}>
                                            <div className="edit-icon" onClick={() => { openModal("editLocation") }} />
                                            <div className="plus-icon" onClick={() => { openModal("addLocation") }} />
                                            
                                        </div>}
                                        
                                        <Row className="no-gutters justify-content-between">
                                            <div className="col-lg-6 col-xl-5">
                                                <div className="location-map-box">
                                                    {/* <img src="/assets/images/provider-prof-map-img.jpg" alt="map" /> */}
                                                    {
                                                        gmapData.length ? <Gmaps gmapData={gmapData} /> : <></>
                                                    }
                                                    {/* <Gmaps gmapData={gmapData}/> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-xl-6">
                                                <ul className="location-add-profile">
                                                    {
                                                        practiceInfo.providerPracticeLocations && practiceInfo.providerPracticeLocations.map((l, li) =>
                                                            <li key={'location-' + li} className="map-icon capitalize">
                                                                <div className="location-head">
                                                                    {li === 0 ? 'Primary Location' : `Location #${li + 1}`}
                                                                </div>
                                                                {l.practiceName}
                                                                <br/>
                                                                {l.practiceAddress}, {l.cities.name}, {l.zipCode}
                                                                <br />
                                                                <a href={`tel:${l.phoneNumber}`}>
                                                                    {Global.phoneFormator(l.phoneNumber)}
                                                                </a>
                                                                <div className="location-head">Hours</div>
                                                                {
                                                                    l.practiceHours.map((pinfo, pinfoIndex) =>
                                                                        <div key={'pinfo-' + li + '-' + pinfoIndex} className="loca-hours">
                                                                            <span>{pinfo.day}</span><span>{pinfo.start} - {pinfo.end}</span>
                                                                        </div>
                                                                    )
                                                                }

                                                                <div className="location-link">
                                                                    {
                                                                        browserAddress ? (<><a target="_blank" href={`${baseAddress}${browserAddress}/${l.practiceAddress} ${l.cities.name} ${l.states.name} ${l.zipCode}`} >Direction</a></>) : (<></>)
                                                                    }
                                                                    {
                                                                        l.website ? (<><a target="_blank" href={l.website.indexOf('http') > -1 ? l.website : `https://${l.website}`}>Website</a></>) : (<></>)
                                                                    }

                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </Row>
                                    </div>
                                    <div id="controlled-tab-example-tabpane-home" aria-labelledby="controlled-tab-example-tab-home" role="tabpanel" aria-hidden={key == '2' ? "true" : "false"} className={key == '2' ? "fade tab-pane active show" : "fade tab-pane"}>
                                        { editable && <div style={{ top: "10px"}} onClick={() => { openProfileTabModal("editInsurance") }} className="edit-icon-position-top"></div>}
                                        <Row className="no-gutters">
                                            <div className="col-12">
                                                <ul className="profile-ins-list">
                                                    {
                                                        selectedIns && selectedIns.map((ins, ins_index) =>
                                                            <li key={'ins-' + ins_index}>{ins}</li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </Row>
                                    </div>
                                    <div id="controlled-tab-example-tabpane-home" aria-labelledby="controlled-tab-example-tab-home" role="tabpanel" aria-hidden={key == '3' ? "true" : "false"} className={key == '3' ? "fade tab-pane active show" : "fade tab-pane"}>
                                        <div style={{"textAlign":"right", position: "absolute", top: "10px",right: "0"}}>
                                            {editable && <div className="edit-icon" onClick={() => { openProfileTabModal("editFocusArea") }} />}
                                            {editable && medicalInfo.providerMedicalFocusAreas && medicalInfo.providerMedicalFocusAreas.length < 10 ? (<>
                                                <div className="plus-icon" onClick={() => { openProfileTabModal("addFocusArea") }} />
                                                    <div style={{top: "10px"}} onClick={() => { openProfileTabModal("addFocusArea") }} className="plus-icon-position-top"></div>
                                                </>) : (<></>)}
                                        </div>            
                                        <Row className="no-gutters">
                                            <div className="col-12">
                                                <ul className="focus-area-list">
                                                    {
                                                        medicalInfo.providerMedicalFocusAreas && medicalInfo.providerMedicalFocusAreas.map((fa, ins_index) =>
                                                            <li key={'ins-' + ins_index}>{fa.focusName}</li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Fragment>
        </Suspense>
    )

}

export default ProfileTabs;