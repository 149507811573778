import React, { Fragment, useContext, useState } from 'react';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import { PatientLinks } from "../../linkFile";
import HomeHeader from '../../global/header/header-main';
import Footer from '../../global/footer/footer';






const ProfileDeleted = (props) => {
  
  return (
    <Fragment>
      <HomeHeader/>
        <Container fluid className="free-profile">
            <Container>
              <Row>
                
                  <div className="col-12 no-padding">
                      <h2>Profile Deleted</h2>
                  </div>

                  <div className="col-12 contactus">
                    <Row>
                        <div className="suc-msg">
                            
                        <h3 className="">
                            Your account has been deleted.
                        </h3>
                        <h3>
                            Thank you for using DocScheduler.
                        </h3>
                        <ul>
                            <li><Link to={PatientLinks.home}> Homepage</Link></li>
                            <li>
                            <Link to={PatientLinks.register_setp1}>
                            {" "}
                            Create Account
                            </Link>
                            </li>
                            
                        </ul>  
                        </div>
                        
                    </Row>
                  </div>
              </Row>
            </Container>
        </Container>
      <Footer/>
    </Fragment>
    );
  }
  
  export default ProfileDeleted;