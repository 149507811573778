import React, { Fragment, useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import 'bootstrap/dist/css/bootstrap.css';


const EditorText = (props) => {
    const { data, onChange, index } = props;
    console.log('on editor page props', props);
    let _contentState = ContentState.createFromText(data);
    const raw = convertToRaw(_contentState)

    const contentDataState = ContentState.createFromBlockArray(convertFromHTML(data));
    const editorDataState = EditorState.createWithContent(contentDataState);
    const [editorState, setEditorState] = useState(editorDataState);


    const onEditorStateChange = (editorStateData) => {
        setEditorState(editorStateData);
        let data = draftToHtml(convertToRaw(editorStateData.getCurrentContent()));
        // props.onDataChange(data);
        onChange(data, index, 'description')
    };
    // useEffect(() => {
    //     onChange(contentState, index, 'description')
    // }, [contentState])

    return (
        <Fragment>
            <div className="paragraph-wrap mb-3">
                <Editor
                    // defaultContentState={contentState}
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                />
            </div>
        </Fragment>
    )

}

export default EditorText;