import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg';
import { ReactComponent as SchoolIcon } from '../../../admin/assets/images/education-grey.svg';
import { ReactComponent as TrainingIcon } from '../../../admin/assets/images/stethoscope-grey.svg';
import { ReactComponent as LanguageIcon } from '../../../admin/assets/images/translate.svg';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import Global from '../../../reactLayer/global';
import { v4 as uuidv4 } from 'uuid';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';
import InputValidation from '../../../reactLayer/validation';
import Select from 'react-select';

const MedicalSpecialty = (props) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [languageList, setLanguageList] = useState([]);
    const [selectedFarea,setSelectedFarea] = useState([]);
    const patientTreatedList = ['Adults', 'Children (Pediatrics)'];
    const areaerrorMsg = ['This Field is required'];
    const patientTreatederrorMsg = ['This Field is required'];
    const languageerrorMsg = ['This Field is required'];
    const [allFarea, setallFarea] = useState([{label:'a',value:'a'}]);
    const startyear = (new Date()).getFullYear() - 80;
    const allyears = Array.from(new Array(81), (val, index) => index + startyear).reverse();

    const [currentFocusArea,setCurrentFocusArea] = useState('')
    let colors = ['orange', 'red', 'blue', 'purple'];


    const [errors, setErrors] = useState({
        area: false,
        patientTreated: false,
        language: false,
    });
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    const [focusFiled, setfocusFiled] = useState({
        area: {
            value: '',
            validation: ['blank'],
            errorMsg: areaerrorMsg,
            errorKey: 'earea',
        },
    })
    const [medicalCredentials, setmedicalCredentials] = useState([]);
    const [medicalSpeciality, setmedicalSpeciality] = useState([]);
    const [medicalfocusarea, setmedicalfocusarea] = useState([]);
    const [medicalprogrametypes, setmedicalprogrametypes] = useState([]);
    const [medInstitue, setmedInstitue] = useState([]);
    const [trainingList, settrainingList] = useState([]);



    const [otherData, setotherData] = useState({
        area: {
            value: [],
            validation: ['favalue'],
            errorMsg: areaerrorMsg,
            errorKey: 'area'
        },
        patientTreated: {
            value: [],
            validation: ['arrayempty'],
            errorMsg: patientTreatederrorMsg,
            errorKey: 'patientTreated',
        },
        language: {
            value: ['english'],
            validation: ['arrayempty'],
            errorMsg: languageerrorMsg,
            errorKey: 'language',
        }
    })
    const [medicalData, setmedicalData] = useState([{
        medicalCredential: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            category: '',
            errorKey: 'medicalCredential',
        },
        medicalSpecialty: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'medicalSpecialty',
        }
    }]);

    const [degreeData, setdegreeData] = useState([{
        pType: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'pType',
        },
        sName: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'sName',
        },
        yStarted: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'yStarted',
        },
        yCompleted: {
            value: '',
            validation: ['blank', { compareDate: { startDate: '' } }],
            errorMsg: ['This field is required', 'Completed year should not be less than started year'],
            errorKey: 'yCompleted',
        },
    }]);

    const [trainingData, settrainingData] = useState([{
        pType: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'pType',
        },
        pName: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'pName',
        },
        sName: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'sName',
        },
        yStarted: {
            value: '',
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'yStarted',
        },
        yCompleted: {
            value: '',
            validation: ['blank', { compareDate: { startDate: '' } }],
            errorMsg: ['This field is required', 'Completed year should not be less than started year'],
            errorKey: 'yCompleted',
        }
    }]);


    const addMedicalFields = (e) => {
        e.preventDefault();
        const tempData = medicalData;
        tempData.push({
            medicalCredential: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'medicalCredential',
            },
            medicalSpecialty: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'medicalSpecialty',
            }
        });

        setmedicalData([...tempData]);
    }

    const addLangData = (e) => {
        let data, val, arrayIndex,
            name = e.currentTarget.getAttribute('data-name');
        val = e.currentTarget.value;
        data = otherData[name];
        arrayIndex = data.value.indexOf(val);
        if (arrayIndex > '-1') {
            data.value.splice(arrayIndex, 1);
        } else {
            data.value.push(val);
        }
        

        setotherData({ ...otherData, ...{ [name]: data } })

    }
    const modifyMedicalSpeciality = (e, varName = 'medicalData') => {
        let index, name, value,
            tempData = [];
        index = e.currentTarget.getAttribute('data-index');
        name = e.currentTarget.getAttribute('data-name');
        value = e.currentTarget.value;
        //  console.log('varName', varName, index, value, name);
        //  return false;
        console.log(name, value);
        if (varName === 'medicalData') {
            tempData = medicalData;
            tempData[index][name]['value'] = value;
            if (name == 'medicalCredential') {
                let catArray = medicalCredentials.filter((mc) => {
                    if (mc.id == value) {
                        return true;
                    }
                });
                if (catArray.length) {
                    tempData[index][name]['category'] = catArray[0]['category'];
                } else {
                    tempData[index][name]['category'] = '';
                }

            }


            tempData[index][name][name] = '';
            setmedicalData([...tempData]);
        } else if (varName === 'degreeData') {
            tempData = degreeData;
            tempData[index][name]['value'] = value;
            tempData[index][name][name] = '';
            // console.log('varName', tempData);
            // return false;
            if (name == 'yCompleted' || name == 'yStarted') {
                tempData[index]['yCompleted']['validation'] = ['blank', { compareDate: { startDate: tempData[index]['yStarted']['value'] } }];
            }
            setdegreeData([...tempData]);
            // console.log('degree data', degreeData);
        } else if (varName === 'trainingData') {
            tempData = trainingData;
            tempData[index][name]['value'] = value;
            tempData[index][name][name] = '';
            if (name == 'yCompleted' || name == 'yStarted') {
                tempData[index]['yCompleted']['validation'] = ['blank', { compareDate: { startDate: tempData[index]['yStarted']['value'] } }];
            }
            settrainingData([...tempData]);
        }
    }



    const DeleteElement = (elementName, index) => {

        let tempData = [];
        if (elementName === 'medicalData') {
            tempData = medicalData;
            tempData[index] && tempData.splice(index, 1);
            setmedicalData([...tempData]);
        } else if (elementName === 'degreeData') {
            tempData = degreeData;
            tempData[index] && tempData.splice(index, 1);
            setdegreeData([...tempData]);
        } else if (elementName === 'trainingData') {
            tempData = trainingData;
            tempData[index] && tempData.splice(index, 1);
            settrainingData([...tempData]);
        }

    }
    const DeleteSection = (props) => {
        return (
            <>
                <div onClick={() => DeleteElement(props.name, props.index)} className="delete-icon" alt="Delete" style={{ cursor: 'pointer' }} ></div>
            </>
        )
    }

    const addDegreeFields = (e) => {
        e.preventDefault();
        const tempData = degreeData;
        tempData.push({
            pType: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'pType',
            },
            sName: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'sName',
            },
            yStarted: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'yStarted',
            },
            yCompleted: {
                value: '',
                validation: ['blank', { compareDate: { startDate: '' } }],
                errorMsg: ['This field is required', 'Completed year should not be less than started year'],
                errorKey: 'yCompleted',
            },

        });
        setdegreeData([...tempData]);
    }

    const addTrainingFields = (e) => {
        e.preventDefault();
        const tempData = trainingData;
        tempData.push({
            pType: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'pType',
            },
            pName: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'pName',
            },
            sName: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'sName',
            },
            yStarted: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This field is required'],
                errorKey: 'yStarted',
            },
            yCompleted: {
                value: '',
                validation: ['blank', { compareDate: { startDate: '' } }],
                errorMsg: ['This field is required', 'Completed year should not be less than started year'],
                errorKey: 'yCompleted',
            }
        });

        settrainingData([...tempData]);
    }

    const getFocusedArea = async () => {
        const fData = {};
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'suggested-speciality/get'

        }
        let result = await NetworkLayer.getRequest(submitData);
        console.log('focus edit area result', result);
        authContext.unsetLoader();
        if (result.status) {
            let focusAreaData = []
            result.result.focusArea.map((d)=>{
                focusAreaData.push({value:d,label:d})
                return null
            })
            setallFarea(focusAreaData);
        } else {
            setallFarea([]);
        }
    }

    const getApiData = async () => {

        let medicalCred = await Global.getMedicalCredentials();
        // console.log('medical cred', medicalCred);
        setmedicalCredentials(medicalCred);

        let medicalSpec = await Global.getMedicalSpeciality();
        // console.log('medical speciality',medicalSpec );
        setmedicalSpeciality(medicalSpec);

        let medicalFocus = await Global.getMedicalFocusArea();
        setmedicalfocusarea(medicalFocus);

        let progTp = await Global.getProgrametypes();
        setmedicalprogrametypes(progTp);

        let instList = await Global.getInstitues();
        setmedInstitue(instList);

        let trainingList = await Global.getTraining();
        settrainingList(trainingList);
        getFocusedArea();
    }

    useEffect(() => {
        async function getLanData() {
            let data = await Global.getLangList();
            if (data.length > 0) {
                setLanguageList(data);
            }
        }
        getLanData();
        getApiData();
    }, [])

    useEffect(() => {
        setInitialData();
    }, [medicalCredentials, props.providerDetails])

    const setInitialData = () => {
        console.log(' props on medical speciality-->', props);
        let focus = [];
        
        props ?.providerDetails ?.medicalSpeciality ?.providerMedicalFocusAreas ?.map((d, i) => {
            focus.push({label:d.focusName,value:d.focusName});
        })
        
        setotherData({
            ...{
                area: {
                    value: focus,
                    validation: ['arrayempty'],
                    errorMsg: areaerrorMsg,
                    errorKey: 'earea'
                },
                patientTreated: {
                    value: props.providerDetails.medicalSpeciality.patientTreated ? props.providerDetails.medicalSpeciality.patientTreated.split(',') : [],
                    validation: ['arrayempty'],
                    errorMsg: patientTreatederrorMsg,
                    errorKey: 'epatientTreated',
                },
                language: {
                    value: props.providerDetails.medicalSpeciality.languagesSpoken ? props.providerDetails.medicalSpeciality.languagesSpoken : [],
                    validation: ['arrayempty'],
                    errorMsg: languageerrorMsg,
                    errorKey: 'elanguage',
                }
            }
        });

        var i;
        const tempData = [];
        let tempData1 = [];
        for (i = 0; i < props.providerDetails.medicalSpeciality.providerMedicalSpecialities ?.length; i++) {

            tempData.push({
                medicalCredential: {
                    value: props.providerDetails.medicalSpeciality.providerMedicalSpecialities[i].degrees ? props.providerDetails.medicalSpeciality.providerMedicalSpecialities[i].degrees.id : '',
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'medicalCredential',
                },
                medicalSpecialty: {
                    value: props.providerDetails.medicalSpeciality.providerMedicalSpecialities[i].specialties ? props.providerDetails.medicalSpeciality.providerMedicalSpecialities[i].specialties.id : '',
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'medicalSpecialty',
                }
            });

            let name = "medicalCredential";
            let value = props.providerDetails.medicalSpeciality.providerMedicalSpecialities[i].degrees ? props.providerDetails.medicalSpeciality.providerMedicalSpecialities[i].degrees.id : "";

            tempData1 = tempData;
            tempData1[i][name]['value'] = value;
            if (name == 'medicalCredential') {
                let catArray = medicalCredentials.filter((mc) => {
                    if (mc.id == value) {
                        return true;
                    }
                });
                if (catArray.length) {
                    tempData1[i][name]['category'] = catArray[0]['category'];
                } else {
                    tempData1[i][name]['category'] = '';
                }

            }

            tempData1[i][name][name] = '';
            setmedicalData([...tempData1]);

        }

        const tempDataTraining = [];
        for (i = 0; i < props.providerDetails.medicalSpeciality.providerTrainingDetails ?.length; i++) {
            // TRAINING DATA 
            tempDataTraining.push({
                pType: {
                    value: props.providerDetails.medicalSpeciality.providerTrainingDetails[i].trainingTypes.id,
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'pType',
                },
                pName: {
                    value: props.providerDetails.medicalSpeciality ?.providerTrainingDetails[i] ?.programName,
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'pName',
                },
                sName: {
                    value: props.providerDetails.medicalSpeciality ?.providerTrainingDetails[i] ?.schools ?.id,
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'sName',
                },
                yStarted: {
                    value: props.providerDetails.medicalSpeciality.providerTrainingDetails[i].yearStarted,
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'yStarted',
                },
                yCompleted: {
                    value: props.providerDetails.medicalSpeciality.providerTrainingDetails[i].yearCompleted,
                    validation: ['blank', { compareDate: { startDate: '' } }],
                    errorMsg: ['This field is required', 'Completed year should not be less than started year'],
                    errorKey: 'yCompleted',
                }
            });

            settrainingData([...tempDataTraining]);
        }


        const tempDataDegree = [];
        for (i = 0; i < props.providerDetails.medicalSpeciality.providerDegreeDetails ?.length; i++) {
            // TRAINING DATA 
            tempDataDegree.push({
                pType: {
                    value: props.providerDetails.medicalSpeciality.providerDegreeDetails[i].programTypes.id,
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'pType',
                },
                sName: {
                    value: props.providerDetails.medicalSpeciality ?.providerDegreeDetails[i] ?.schools ?.id,
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'sName',
                },
                yStarted: {
                    value: props.providerDetails.medicalSpeciality.providerDegreeDetails[i].yearStarted,
                    validation: ['blank'],
                    errorMsg: ['This field is required'],
                    errorKey: 'yStarted',
                },
                yCompleted: {
                    value: props.providerDetails.medicalSpeciality.providerDegreeDetails[i].yearCompleted,
                    validation: ['blank', { compareDate: { startDate: '' } }],
                    errorMsg: ['This field is required', 'Completed year should not be less than started year'],
                    errorKey: 'yCompleted',
                }
            });

            setdegreeData([...tempDataDegree]);
        }


        console.log(props.providerDetails.medicalSpeciality);
        // setmedicalData();
        // setData(medicalSpecialtyInfo);
    }

    const goBack = () => {
        history.push({
            pathname: '../../provider/management',
        });
    }

    const savePersonalInfo = async (event) => {
        event.preventDefault();
        let flag = true;
        let setFocus = false;



        // FORM DATA 
        let medicalSpeciality = [],
            degreeDetails = [],
            trainingDetails = [];
        let tempMedicalData=medicalData;
        medicalData.forEach((currentValue, index) => {
            let validationResult = InputValidation(currentValue);
            if (Object.keys(validationResult).length > 0) {
                flag = false;
                for (const prop in validationResult) {
                    tempMedicalData[index][prop][prop] = `${validationResult[prop]}`;
                    if (!flag && !setFocus) {
                        setFocus = true;
                        let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
                    }
                }
                setmedicalData([...tempMedicalData]);
            }else{
                let ms = { medicalCredential: currentValue.medicalCredential.value, medicalSpeciality: currentValue.medicalSpecialty.value, "sortOrder": index + 1 };
                medicalSpeciality.push(ms);
            }
        });

        let tempdegreeData = degreeData;
        degreeData.forEach((currentValue, index) => {
            let degData = {
                "educationType": currentValue.pType.value,
                "schoolName": currentValue.sName.value,
                "yearStarted": currentValue.yStarted.value,
                "yearCompleted": currentValue.yCompleted.value,
                "sortOrder": index + 1
            };
            degreeDetails.push(degData);
            let validationResult = InputValidation(currentValue);

            if (Object.keys(validationResult).length > 0) {
                flag = false;
                for (const prop in validationResult) {
                    tempdegreeData[index][prop][prop] = `${validationResult[prop]}`;
                    if (!flag && !setFocus) {
                        setFocus = true;
                        let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
                    }
                }
                setdegreeData([...tempdegreeData]);
            } else {
                if (currentValue.yStarted.value > currentValue.yCompleted.value) {
                    temptrainingData[index]['yCompleted']['yCompleted'] = 'Completed year should not be less then started year';
                    setdegreeData([...tempdegreeData]);
                }
            }

        });

        let temptrainingData = trainingData;
        temptrainingData.forEach((currentValue, index) => {
            let tData = {
                "educationType": currentValue.pType.value,
                "schoolName": currentValue.sName.value,
                "programName": currentValue.pName.value,
                "yearStarted": currentValue.yStarted.value,
                "yearCompleted": currentValue.yCompleted.value,
                "sortOrder": index + 1
            };
            trainingDetails.push(tData);
            let validationResult = InputValidation(currentValue);
            if (Object.keys(validationResult).length > 0) {
                flag = false;
                for (const prop in validationResult) {
                    temptrainingData[index][prop][prop] = `${validationResult[prop]}`;
                    if (!flag && !setFocus) {
                        setFocus = true;
                        let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
                    }
                }
                settrainingData([...temptrainingData]);
            } else {
                if (currentValue.yStarted.value > currentValue.yCompleted.value) {
                    temptrainingData[index]['yCompleted']['yCompleted'] = 'Completed year should not be less then started year';
                    settrainingData([...temptrainingData]);
                }
            }
        });

        let focusArea = [], tempAreaFocus = otherData.area.value ? otherData.area.value : [];

        tempAreaFocus.map((d, i) => {
            focusArea.push({ "focusName": d.value.trim(), "sortOrder": i + 1 });
        });
        if (!flag) {
            return false;
        }
        authContext.setLoader();

        let datasubmit = {
            "userId": props.providerDetails ?.accountInfo ?.id,
            "patientTreated": otherData.patientTreated.value.map((val) => `${val}`).join(','),
            "medicalSpeciality": medicalSpeciality,
            "areaOfFocus": focusArea,
            "degreeDetails": degreeDetails,
            "trainingDetails": trainingDetails,
            "languageSpoken": otherData.language.value
        };

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/medical-speciality/update',
            body: JSON.stringify(datasubmit),
        }
        console.log(JSON.stringify(datasubmit));
        // SEND REQUEST 
        if (flag) {
            let result = await NetworkLayer.postData(submitData);

            if (result.body.code == 200) {
                props.callBack && props.callBack();
                successMsg("success", "Record Updated Successfully.");
            } else {
                successMsg("error", result.body.message);
            }
        }

        authContext.unsetLoader();
    }

    const successMsg = (type, message) => {
        setmodalProps({
            type: type,
            msg: message
        });
        authContext.showResponseModal();
    }

    const setFocusArea = (e,type='none') => {
        //setFocusAreaError('')
        if(otherData.area.value.length === 5){
            //setFocusAreaError('Max 5 tags are allowed')
            return;
        }
        if(otherData.area.value.includes(e.target.value)){
            //setFocusAreaError('Tag already exist')
            return;
        }
        if(e.keyCode === 13 || type === 'add'){
            const favalueData = {label:e.target.value,value:e.target.value}
            otherData.area.value.push(favalueData)
            
            let temparea = {
                area: {
                    value: otherData.area.value,
                    validation: ['arrayempty'],
                    errorMsg: areaerrorMsg,
                    errorKey: 'earea',
                }
            };
            
            let tempOtherData = { ...otherData, ...temparea };
            setErrors({ ...errors, ...{ area: false } });
            setCurrentFocusArea('')
            setotherData(tempOtherData);
        }
    }

    const removeTag = (key) => {
        let otherDataTemp = JSON.parse(JSON.stringify(otherData))
        otherDataTemp.area.value.splice(key,1)
        setotherData(otherDataTemp);
    }

    return (
        <Fragment>
            <Row className="table-sort-filter">
                {
                    authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                }
                <div className="col-12 col-lg-9">
                    <form className="provider-form-edit row mb-3">
                        <div className="col-12 mb-1">
                            <label className="pro-label">Patients Treated</label>
                            {patientTreatedList.map((d, ind) =>
                                <label className="custom-checkbox" for={d.toLowerCase() + '-' + ind} key={d.toLowerCase() + '-main'}>
                                    <input type="checkbox" id={d.toLowerCase() + '-' + ind} name="patientTreated"
                                        data-name='patientTreated'
                                        onClick={() => { setErrors({ ...errors, ...{ patientTreated: false } }) }}
                                        value={d.toLowerCase()}
                                        onChange={(e) => addLangData(e)}
                                        checked={otherData.patientTreated.value.indexOf(d.toLowerCase()) > '-1' ? true : false}
                                    />
                                    <span className="checkmark" style={{ 'borderRadius': '0px', 'boxShadow': 'none', 'animation': 'none' }}></span>
                                    {d}
                                </label>
                            )}
                        </div>
                        <div className="col-12 mb-1">
                            {medicalData &&
                                medicalData.map((value, index) => (
                                    <Row className="medical-specility" key={'medical-wrapper-' + index}>

                                        <div className="col-md-5 mb-1 select-option">
                                            <label className="pro-label">Medical Credential</label>
                                            <select className={value.medicalCredential.medicalCredential ? "select  error-border" : "select"} value={value.medicalCredential.value} data-index={index} data-name="medicalCredential" onChange={(e) => { modifyMedicalSpeciality(e) }}>
                                                <option value="">Choose</option>
                                                {medicalCredentials.map((mc, mci) =>
                                                    <option key={mc.id + '-' + index} value={mc.id}>{mc.name}</option>
                                                )}
                                            </select>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                            <small className="error-input">{value.medicalCredential.medicalCredential ? value.medicalCredential.medicalCredential : ""}</small>
                                        </div>
                                        <div className="col-md-6 mb-1 select-option">
                                            <label className="pro-label">Your Medical Speciality</label>
                                            <select className={value.medicalSpecialty.medicalSpecialty ? "select  error-border" : "select"} value={value.medicalSpecialty.value} data-index={index} data-name="medicalSpecialty" onChange={(e) => { modifyMedicalSpeciality(e) }}>
                                                <option value="">Choose</option>
                                                {medicalSpeciality.map((ms, msi) =>
                                                    <>
                                                        {ms.category != '' ? <>
                                                            {value.medicalCredential.category == ms.category ?
                                                                (
                                                                    <option key={ms.id + '-' + index} value={ms.id}>{ms.name}</option>
                                                                ) :
                                                                (
                                                                    ''
                                                                )
                                                            }
                                                        </> : <>
                                                                <option key={ms.id + '-' + index} value={ms.id}>{ms.name}</option>
                                                            </>}

                                                    </>
                                                )}
                                            </select>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                            <small className="error-input">{value.medicalSpecialty.medicalSpecialty ? value.medicalSpecialty.medicalSpecialty : ""}</small>
                                        </div>
                                        {
                                            index > 0 ? <div className="col-md-1 mb-1 select-option"><DeleteSection name='medicalData' index={index} /></div> : <></>
                                        }
                                    </Row>
                                ))
                            }

                            <Row className="add-another">
                                <div className="col-12 text-right">
                                    <Link className="font-13" onClick={(e) => { addMedicalFields(e) }}>+ Add Another Medical Specialty</Link>
                                </div>
                            </Row>


                            <Row className="medical-focus-areas">
                                <div className="col-12 mb-5">
                                    <label className="pro-label">Add Medical Focus Areas/Specialties</label>
                                    {/*<Select 
                                        isMulti
                                        value={otherData.area.value}
                                        options={allFarea}
                                        closeMenuOnSelect={false}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={value => changeFocusArea(value)}
                                    />*/}
                                    <input type="text" list="pCare" onKeyUp={(e)=>setFocusArea(e)} onChange={(e)=>setCurrentFocusArea(e.target.value)} value={currentFocusArea} name="pCare" className="input-pro" placeholder="Enter tags" autoComplete="off"/>
                                    <div className="faTags">
                                    {
                                        otherData.area.value.map((fa,key)=>{
                                            return(
                                                <div className="faTagsContent" key={key}>
                                                    {fa.label}
                                                    <div onClick={()=>removeTag(key)} className="removeFaCode">X</div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    <small className="error-input">{errors.area}</small>
                                </div>
                            </Row>


                            {/* <Row className="add-another mb-3">
                                <div className="col-12 text-right">
                                    <Link className="font-13" onClick={(e) => { addMedicalFocus(e) }}>+ Add Medical Focus Area</Link>
                                </div>
                            </Row> */}
                        </div>

                        {degreeData.map((data1, index) =>
                            <div className="col-12 mb-4">
                                <div className="blue-area">
                                    <div className="col-11 blue-head mb-3 mt-3">
                                        <i className="blue-icon pr-3"><TrainingIcon /></i>SCHOOL
                                </div>
                                    <div className="col-1 blue-head mb-3 mt-3">
                                        {
                                            index > 0 ? <DeleteSection name='degreeData' index={index} /> : <></>
                                        }
                                    </div>
                                    <div class="col-12 select-option mb-3">
                                        <label className="pro-label">Program Type</label>
                                        <select className={data1.pType.pType ? "select  error-border" : "select"} value={data1.pType.value} data-index={index} data-name="pType" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                            <option value="">Program Type</option>
                                            {medicalprogrametypes.map((pt, pti) =>
                                                <option key={pt.id + '-' + index} value={pt.id}>{pt.title}</option>
                                            )}
                                        </select>
                                        <small className="error-input">{data1.pType.pType}</small>
                                        <i className="select-option-icon">
                                            <SelectOptionIcon />
                                        </i>
                                    </div>
                                    <div class="col-12 select-option mb-3">
                                        <label className="pro-label">School Name or instutution Name</label>
                                        <select className={data1.sName.sName ? "select  error-border" : "select"} value={data1.sName.value} data-index={index} data-name="sName" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                            <option value="">School Name or Institution Name</option>
                                            {medInstitue.map((it, iti) =>
                                                <option key={it.id + '-' + index} value={it.id}>{it.name}</option>
                                            )}
                                        </select>
                                        <small className="error-input">{data1.sName.sName}</small>
                                        <i className="select-option-icon">
                                            <SelectOptionIcon />
                                        </i>
                                    </div>
                                    <div class="col-md-4 select-option mb-3">
                                        <label className="pro-label">Year Started</label>
                                        <select className={data1.yStarted.yStarted ? "select  error-border" : "select"} value={data1.yStarted.value} data-index={index} data-name="yStarted" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                            <option value="">Year Started</option>
                                            {allyears.map((sy, si) =>
                                                <option key={'start-' + index + '-' + si} value={sy}>{sy}</option>
                                            )}
                                        </select>
                                        <small className="error-input">{data1.yStarted.yStarted}</small>
                                        <i className="select-option-icon">
                                            <SelectOptionIcon />
                                        </i>
                                    </div>
                                    <div class="col-md-4 select-option mb-3">
                                        <label className="pro-label">Year Completed</label>
                                        <select className={data1.yCompleted.yCompleted ? "select  error-border" : "select"} value={data1.yCompleted.value} data-index={index} data-name="yCompleted" onChange={(e) => { modifyMedicalSpeciality(e, 'degreeData') }}>
                                            <option value="">Year Completed</option>
                                            {allyears.map((sy, si) =>
                                                <option key={'end-' + index + '-' + si} value={sy}>{sy}</option>
                                            )}
                                        </select>
                                        <small className="error-input">{data1.yCompleted.yCompleted}</small>
                                        <i className="select-option-icon">
                                            <SelectOptionIcon />
                                        </i>
                                    </div>
                                </div>
                            </div>
                        )}



                        <div className="add-anither col-12 text-right">
                            <Link className="font-13" onClick={(e) => { addDegreeFields(e) }}>+ Add another Education</Link>
                        </div>

                        {trainingData &&
                            trainingData.map((data, index) => (
                                <div className="col-12 mb-1">
                                    <div className="blue-area">
                                        <div className="col-11 blue-head mb-3 mt-3">
                                            <i className="school-icon pr-3"><SchoolIcon /></i>TRAINING
                                    </div>
                                        <div className="col-1 blue-head mb-3 mt-3">
                                            {
                                                index > 0 ? <DeleteSection name='trainingData' index={index} /> : <></>
                                            }
                                        </div>

                                        <div class="col-12 select-option mb-3">
                                            <label className="pro-label">Education Type</label>
                                            <select className={data.pType.pType ? "select  error-border" : "select"} value={data.pType.value} data-index={index} data-name="pType" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                <option value="">Training Type</option>
                                                {trainingList.map((pt, pti) =>
                                                    <option key={pt.id + '-' + index} value={pt.id}>{pt.title}</option>
                                                )}
                                            </select>
                                            <small className="error-input">{data.pType.pType}</small>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                        </div>
                                        <div class="col-12 select-option mb-3">
                                            <label className="pro-label">School Name or instutution Name</label>
                                            <select className={data.sName.sName ? "select  error-border" : "select"} value={data.sName.value} data-index={index} data-name="sName" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                <option value="">School Name or Institution Name</option>
                                                {medInstitue.map((it, iti) =>
                                                    <option key={it.id + '-' + index} value={it.id}>{it.name}</option>
                                                )}
                                            </select>
                                            <small className="error-input">{data.sName.sName}</small>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label className="pro-label">Program Name</label>
                                            <input type="text" name="pNumber" className={data.pName.pName ? "input-pro  error-border" : "input-pro"} placeholder="Program Name" autoComplete="off"
                                                data-index={index} data-name="pName"
                                                value={data.pName.value}
                                                onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}
                                            />
                                            <small className="error-input">{data.pName.pName}</small>
                                        </div>
                                        <div class="col-md-4 select-option mb-3">
                                            <label className="pro-label">Year Started</label>
                                            <select className={data.yStarted.yStarted ? "select  error-border" : "select"} value={data.yStarted.value} data-index={index} data-name="yStarted" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                <option value="">Year Started</option>
                                                {allyears.map((sy, si) =>
                                                    <option key={'start-' + index + '-' + si} value={sy}>{sy}</option>
                                                )}
                                            </select>
                                            <small className="error-input">{data.yStarted.yStarted}</small>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                        </div>
                                        <div class="col-md-4 select-option mb-3">
                                            <label className="pro-label">Year Completed</label>
                                            <select className={data.yCompleted.yCompleted ? "select  error-border" : "select"} value={data.yCompleted.value} data-index={index} data-name="yCompleted" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                                <option value="">Year Completed</option>
                                                {allyears.map((sy, si) =>
                                                    <option key={'end-' + index + '-' + si} value={sy}>{sy}</option>
                                                )}
                                            </select>
                                            <small className="error-input">{data.yCompleted.yCompleted}</small>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                        <div className="add-anither col-12 text-right">
                            <Link className="font-13" onClick={(e) => { addTrainingFields(e) }}>+ Add Another residency, fellowship, or intership</Link>
                        </div>
                        <div className="pro-label col-12 mt-3">
                            <i className="pr-3"><LanguageIcon /></i>Languages Spoken
                        </div>
                        <div className="col-12 mt-3">
                            {
                                languageList.map((d, ind) => (
                                    // JSON.stringify(value)
                                    <label className="custom-checkbox" for={d.toLowerCase() + '-' + ind} key={uuidv4()}>
                                        <input type="checkbox" id={d.toLowerCase() + '-' + ind} name="language"
                                            data-name='language'
                                            key={d.toLowerCase() + '-' + ind}
                                            value={d.toLowerCase()}
                                            onChange={(e) => addLangData(e)}
                                            onClick={() => { setErrors({ ...errors, ...{ language: false } }) }}
                                            checked={otherData.language.value.indexOf(d.toLowerCase()) > '-1' ? true : false}
                                        />
                                        <span className="checkmark" style={{ 'borderRadius': '0px', 'boxShadow': 'none', 'animation': 'none' }}></span>
                                        {d}
                                    </label>
                                ))
                            }
                        </div>
                        <div className="col-12 bottom-btns mt-3">
                            <div className="next-prev-btn-pro">
                                <input type="button" className="prev-btn-pro" value="Cancel" onClick={goBack} />
                                <input type="button" className="next-btn-pro" value="Save" onClick={(e) => savePersonalInfo(e)} />
                            </div>
                        </div>
                    </form>
                </div>
            </Row>
        </Fragment>
    );
}

export default MedicalSpecialty;