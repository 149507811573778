import React, {
  Fragment,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import AuthContext from "../../reactLayer/auth/AuthContext";
import { ProviderLinks, PatientLinks } from "../../linkFile";
import useLocationMod from "../../hooks/useLocationMod";
import NetworkLayer from '../../reactLayer/Network/Network';

const Header = (props) => {
  const authContext = useContext(AuthContext);
  const locationMod = useLocationMod();
  const history = useHistory();
  const headerSearch = useRef(null);
  const [menu, setmenu] = useState(false);
  const [showStrip, setshowStrip] = useState(true);
  const [logostatus, setlogostatus] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalNotifications , setTotalNotifications] = useState(0)
  let intervalObject;
  const menuToggle = () => {
    if (menu) {
      setmenu(false);
      document.querySelector("html").classList.remove("overflowy-hide");
      document.body.classList.remove("overflowy-hide");
    } else {
      setmenu(true);
      document.querySelector("html").classList.add("overflowy-hide");
      document.body.classList.add("overflowy-hide");
    }
  };

  const RedirectToHome = () => {
    history.push({
      pathname: PatientLinks.login,
    });
  };

  const RedirectToSearch = () => {
    if (history.location?.pathname !== ProviderLinks.search_result) {
      authContext.setHeaderSearchText({
        text: searchText,
        actionSubmit: false,
      });
      history.push({
        pathname: ProviderLinks.search_result,
        state: {
          findHeaderTextFind: searchText
            ? searchText
            : "",
        },
      });
      locationMod.location.state = {
        findHeaderTextFind: searchText
          ? searchText
          : "",
      };
    } else {
      authContext.setHeaderSearchText({
        text: searchText,
        actionSubmit: true,
      });
    }
  };

  const checkKey = (e) => {
    if (e.key === "Enter") {
      RedirectToSearch();
    }
  };

  const DoLogout = (e) => {
    e.preventDefault();
    authContext.logout();
    RedirectToHome();
  };
  const setEmailVerified = () => {
    let user = authContext.user;
    user.email_verified = false;
    authContext.setUser(user);
  }
  const getNotificationCount = async () => {
    let userData = authContext.user
    if(userData.creditCardError !== true){
    const submitData = {
      headers: {
          'Content-Type': 'application/json',
          'access_token': authContext.token
      },
      url: 'patients/notifications/newnotifications',
  }

    let response = await NetworkLayer.getRequest(submitData);
    if(response.status === true){
      if(response.result.newNotificationData){
        if(response.result.newNotificationData.creditCardError === true){
          userData.creditCardError = true
          authContext.setUser(userData)
          window.location.reload()
        }
        setTotalNotifications(response.result.newNotificationData.total)
      }
      
    }
  }
  }

  useEffect(() => {
    if(authContext.user){
      if(authContext.user.creditCardError === true){
        let locationValue = window.location.href.split('/')
        if(locationValue.includes('manage-subscription') || locationValue.includes('upgrade-subscription')){
          
        }else{
          history.push({
            pathname: ProviderLinks.accountprofile+'/manage-subscription',
          })
        }
      }
    }
    

    if(authContext.user){
      getNotificationCount()
      intervalObject = setInterval(()=>{
        getNotificationCount()
      },10000)
      console.log('authContext', authContext.user);
    }

    setSearchText(
      authContext?.headerSearchText?.text
        ? authContext?.headerSearchText?.text
        : ""
    );

    
    let logostatus = props.location ? props.location.pathname.split('/') : [];
    if( logostatus.length ) {
      setlogostatus(true);
    } else {
      setlogostatus(false);
    }
    return () => {
      clearInterval(intervalObject)
    }
    
  }, []);

  return (
    <Fragment>
      <header>
        {/* Header with logo */}
        {/* <Container>
            <Row>
                <div className="col-12">
                    <div className="header-inner">
                        <div className="header-left"><img src="/assets/images/doc-scheduler-logo.png" alt="Logo" /></div>
                    </div>
                </div>
            </Row>
        </Container> */}
        {/* Header provider search */}
        {
            authContext.user && authContext.user.email_verified ?(<>
            <div className="covid-strip">
                <img src="/assets/images/info-icon.png" className="info-covid-icon" alt="Info" /><a onClick={(e) => e.preventDefault()} >Your email has been verified</a>
                <div onClick={()=> {setEmailVerified(false)}} className="covid-close-icon"><img src="/assets/images/cross.svg" className="scale" /></div>
            </div>
            </>):(<></>)
        }

    {
          authContext.user && authContext.user.creditCardError === true ?(<>
            <div className="covid-strip error">
                <img src="/assets/images/info-icon.png" className="info-covid-icon" alt="Info" />
                <a onClick={(e) => e.preventDefault()} >
                </a>
                There was a problem processing your credit card. Please update your credit card info to keep your paid DocScheduler Account Active
            </div>
            </>):(<></>)
        }

        <Container>
          <Row>
            <div className="col-12">
              <div className="header-after-login">
                <div className="header-logo">
                  {/* <Link to={PatientLinks.login}><img src="/assets/images/doc-scheduler-logo.png" alt="Logo" /></Link> */}
                  <Link to={PatientLinks.login}>
                    {/* <img src="/assets/images/doc-scheduler-logo.png" alt="Logo" /> */}
                    <div className="logo-main">
                      <img
                        src="/assets/images/logo-icon.svg"
                        className="logo-icon"
                        alt="DocScheduler"
                      />
                      <span>DocScheduler</span>
                    </div>
                  </Link>
                </div>
                {authContext.user && authContext?.user?.isProfileCompleted ? (
                  <>
                    {authContext.user.accountInfo.roleId === 3 && authContext.user.creditCardError !== true ? (
                      <>
                        <div className="header-search hide-for-mobile">
                          <input
                            ref={headerSearch}
                            onKeyUp={(e) => checkKey(e)}
                            type="text"
                            name="mInsName"
                            className="input-search"
                            placeholder="Search Provider"
                            autoComplete="off"
                            value={searchText}
                            onChange={(e) => setSearchText(e?.target?.value)}
                          />
                          <input
                            type="submit"
                            name=""
                            onClick={RedirectToSearch}
                            value=""
                            className="search-btn"
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* <div className={ menu ? "mobile-menu bmenu-active" : "mobile-menu"}
                      onClick={menuToggle}
                    >
                      <div className="burger-menu">
                        <span></span>
                      </div>
                    </div> */}
                    <div
                      className={
                        menu ? "header-right-log open-menu" : "header-right-log"
                      }
                    >
                      <div className="account-icons-wrap">
                        <div className="dashboard-sec">
                          {authContext.user && authContext.user.accountInfo.roleId === 3 ? (
                            <>
                            {
                              authContext.user.creditCardError === true ?
                              ""
                              :
                              <Link to={ProviderLinks.dashboard}>
                                <div className="dash-board-icon"></div>
                                <span className="menu-name">My Health Dashboard</span>
                              </Link>
                            }
                            </>
                          ) : (
                            <>
                              <Link to={PatientLinks.dashboard}>
                                <div className="dash-board-icon"></div>
                                <span className="menu-name">My Health Dashboard</span>
                              </Link>
                            </>
                          )}
                        </div>
                        <div className="notification-sec">
                          <Link to={authContext.user && authContext.user.accountInfo.roleId === 3 ? ProviderLinks.notification : PatientLinks.notification }>
                                <div className="notify-icon">
                                {
                                  totalNotifications !== 0 ?
                                  <div className="notification-circle">{totalNotifications}</div>
                                  :
                                  ""
                                }
                                </div>
                            <span className="menu-name">Notifications</span>
                          </Link>
                        </div>
                        <div className="account-sec">
                          <span className="menu-arrow-icon">
                            <div className="myacc-icon"></div>
                            <span className="menu-name">My Account</span>
                          </span>
                          <div className="home-menu">
                            <div className="home-menu-inner">
                              {authContext.user && authContext.user.accountInfo.roleId === 3  ? (
                                <>
                                  {
                                    !authContext.user.accountInfo.subRoleId || authContext.user.accountInfo.subRoleId !== 5 ? (<>
                                      <Link to={ProviderLinks.accountprofile}>
                                        <span>Account Info</span>
                                      </Link>
                                      <Link to={ProviderLinks.accountsecurity}>
                                        <span>Security</span>
                                      </Link>
                                    </>) : (<></>)
                                  }
                                  
                                </>
                              ) : (
                                <>
                                  <Link to={PatientLinks.accountprofile}>
                                    <span>Account Info</span>
                                  </Link>
                                  <Link to={PatientLinks.accountsecurity}>
                                    <span>Security</span>
                                  </Link>
                                </>
                              )}

                              <a href="/#" onClick={(e) => DoLogout(e)}>
                                <span>Sign-out</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {
                      // RedirectToHome()
                    }
                  </>
                )}
              </div>
              {authContext.user && authContext.user.accountInfo.roleId === 3 ? (
                <>
                  <div className="header-search hide-for-tablet hide-for-desktop">
                    <input
                      ref={headerSearch}
                      onKeyUp={(e) => checkKey(e)}
                      type="text"
                      name="mInsName"
                      className="input-search"
                      placeholder="Search Provider"
                      autoComplete="off"
                      value={searchText}
                      onChange={(e) => setSearchText(e?.target?.value)}
                    />
                    <input
                      type="submit"
                      name=""
                      onClick={RedirectToSearch}
                      value=""
                      className="search-btn"
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      </header>
    </Fragment>
  );
};

export default Header;
