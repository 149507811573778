import React, { Fragment, useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { ProviderLinks, PatientLinks, AdminLinks } from '../../linkFile';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../admin/assets/images/back_icon.svg';
import PatientTabs from '../../components/admin/patient/patient-tabs';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import MedicalSpeciality from '../../components/admin/speciality-admin/medical-speciality';
import DentalSpecilities from '../../components/admin/speciality-admin/dental-specialities';
import MedicalSchoolList from '../../components/admin/speciality-admin/medical-school-list';
import DentalSchoolList from '../../components/admin/speciality-admin/dental-school-list';

const SpecialityDetail = () => {
    const authContext = useContext(AuthContext);
    const [visibleComponent, setvisibleComponent] = useState('medicalSpe');
    const tabName = [{ id: 'medicalSpe', name: 'Medical Specialties' }, { id: 'medicalSchool', name: 'Medical Schools List' }, { id: 'dentalSpec', name: 'Dental Specialties' }, { id: 'dentalSchool', name: 'Dental Schools List' }]


    return (
        <Fragment>
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section patient-management-outer"}>
                    <Row className="bg-white-report patient-details-outer">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Specialties</div>
                            </div>
                            <div className="col-12 mt-3">
                                <Link to={AdminLinks.admin_dashboard} className="back-link">
                                    <span className="back-icon"><BackIcon /></span>
                                    Back
                            </Link>
                            </div>
                            <div className="speciality-tabs-outer">
                                <PatientTabs visibleComponent={visibleComponent} setvisibleComponent={setvisibleComponent} tabName={tabName} />
                            </div>
                            {
                                visibleComponent === 'medicalSpe' ? <><MedicalSpeciality type={"Speciality"} subtype={"Medical"} /> </>
                                    :
                                    visibleComponent === 'medicalSchool' ? <MedicalSchoolList type={"Speciality"} subtype={"Medical"} />
                                        :
                                        visibleComponent === 'dentalSpec' ? <DentalSpecilities type={"Speciality"} subtype={"Dental"} />
                                            :
                                            visibleComponent === 'dentalSchool' ? <DentalSchoolList type={"Speciality"} subtype={"Dental"} />
                                                : <></>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default SpecialityDetail;