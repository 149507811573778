import React, { Fragment, useContext, useState, useEffect, lazy, Suspense } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import AuthContext from '../../reactLayer/auth/AuthContext';
import NetworkLayer from '../../reactLayer/Network/Network';
import { ProviderLinks,PatientLinks } from '../../linkFile';
import 'bootstrap/dist/css/bootstrap.css';

import Custommodal from '../../global/modal/modal';
import EditIntro from '../dashboard-modals/edit-intro';
import AddEducation from '../dashboard-modals/add-education';
import EditLang from '../dashboard-modals/edit-language';
import Stars from '../../global/ratingreview/stars';

const ProviderLeftProfile = (props) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const [isSavedDoctor, setIsSavedDoctor] = useState(props.isSavedDoctor);
    const [visibleComponent, setVisibleComponent] = useState('');
    const [patientRules, setpatientRules] = useState({
        isAcceptingNewPatient: props.practiceInfo && props.practiceInfo.isAcceptingNewPatient ? props.practiceInfo.isAcceptingNewPatient : 0,
        hasVirtualVisit: props.practiceInfo && props.practiceInfo.hasVirtualVisit ? props.practiceInfo.hasVirtualVisit : 0,
        allowedPatient: props.practiceInfo && props.practiceInfo.allowedPatient ? props.practiceInfo.allowedPatient : 0,
        allowOnline: props.practiceInfo && props.practiceInfo.allowOnline ? props.practiceInfo.allowOnline : 0
    });
    // console.log('patientRules========>',patientRules);
    // const [modalProps, setmodalProps] = useState({
    //     type:'', 
    //     msg:'',
    // });
    const editable = props.editable ? props.editable : false
    const accountInfo = props.accountInfo ? props.accountInfo : {};
    const practiceInfo = props.practiceInfo ? props.practiceInfo : {};
    const medicalInfo = props.medicalSpeciality ? props.medicalSpeciality : {};

    console.log('Provider Left Profile-editable check', editable);
    const modalList = ['editProfile', 'addEdu', 'editEdu', 'editDegree', 'editPractice', 'editLang'];
    const redirectToSeachResult = (e) => {
        e && e.preventDefault();
        history.push({ pathname: ProviderLinks.search_result });
        authContext.setAdvDoctorSearchModal(true);
    }
    const setProfile = () => {
        props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
    }
    const openModal = (modalName) => {
        // authContext.hideModal()
        setVisibleComponent(modalName)
        authContext.showModal()
    }
    const submitForm = (formData = {}) => {
        // console.log('fromData on submit', formData);
    }
    const openLoginModal = (e) => {
        e && e.preventDefault();
        authContext.showLoginModal({
            modalName: 'login',
            redirectUrl: PatientLinks.doctor_search_result
        });
    }
    const saveUnsaveFavDoc = async (value, type) => {

        if (authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.isProfileCompleted && authContext.user.accountInfo.roleId === 4) {

        } else {
            openLoginModal();
            return false;
        }

        console.log("saveUnsaveFavDoc", value);

        authContext.setLoader();

        let url = '';
        let isSavedDoctor = 1;
        if (type == 'save') {
            url = 'patients/favourite/doctor/add';
            isSavedDoctor = 1;
        } else {
            url = 'patients/favourite/doctor/remove';
            isSavedDoctor = 0;
        }
        // FORM DATA
        const formData = {
            "toUser": value ?.practiceInfo ?.providerPracticeLocations[0] ?.userId
        }
        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                "access_token": authContext.token
            },
            url: url,
            body: JSON.stringify(formData),
        }
        // SEND REQUEST
        let result = await NetworkLayer.postData(submitData);

        console.log('Response', result);
        if (result.body.code == 200) {
            setIsSavedDoctor(isSavedDoctor);
        }

        authContext.unsetLoader();
    }

    const changePatientRule = async (e) => {

        if (editable) {
            let name, value,
                tempData = {};
            tempData = patientRules;
            name = e.currentTarget.getAttribute('data-name');
            // console.log('name===>',name, 'checked==>',e.currentTarget.checked);
            value = e.currentTarget.value;
            if (e.currentTarget.checked) {
                tempData[name] = 1;
            } else {
                tempData[name] = 0;
            }

            authContext.setLoader();
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/other-profile-details/update',
                body: JSON.stringify(tempData),

            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if (result.body.status) {
                setpatientRules({ ...tempData });
                // setmodalProps({
                //     type:'success', 
                //     msg: result.body.message,
                // });
                // authContext.showResponseModal();
            } else {
                // setmodalProps({
                //     type:'error', 
                //     msg: result.body.message,
                // });
                // authContext.showResponseModal();
            }
        }

    }

    useEffect(() => {
        console.log('props on left profile page', props);
        setpatientRules({
            ...{
                isAcceptingNewPatient: props.practiceInfo && props.practiceInfo.isAcceptingNewPatient ? props.practiceInfo.isAcceptingNewPatient : 0,
                hasVirtualVisit: props.practiceInfo && props.practiceInfo.hasVirtualVisit ? props.practiceInfo.hasVirtualVisit : 0,
                allowedPatient: props.practiceInfo && props.practiceInfo.allowedPatient ? props.practiceInfo.allowedPatient : 0,
                allowOnline: props.practiceInfo && props.practiceInfo.allowOnline ? props.practiceInfo.allowOnline : 0
            }
        })
    }, [props.practiceInfo])
    return (
        <Fragment>
            <Suspense fallback={<div>Loading... </div>}>
                {modalList.indexOf(visibleComponent) > '-1' ? (
                    <Custommodal unsetComponent={setVisibleComponent} componentName={
                        // visibleComponent === 'editProfile' ? <EditLocation />
                        visibleComponent === 'editProfile' ? <EditIntro accountInfo={accountInfo} getProfile={setProfile} medicalInfo={medicalInfo} setVisibleComponent={setVisibleComponent} />
                            :
                            visibleComponent === 'addEdu' ? <AddEducation getProfile={setProfile} modalName={visibleComponent} setVisibleComponent={setVisibleComponent} />
                                :
                                visibleComponent === 'editDegree' ? <AddEducation getProfile={setProfile} medicalData={medicalInfo.providerDegreeDetails} practiceData={medicalInfo.providerTrainingDetails} modalName={visibleComponent} setVisibleComponent={setVisibleComponent} />
                                    :
                                    visibleComponent === 'editPractice' ? <AddEducation getProfile={setProfile} medicalData={medicalInfo.providerDegreeDetails} practiceData={medicalInfo.providerTrainingDetails} modalName={visibleComponent} setVisibleComponent={setVisibleComponent} />
                                        :
                                        // visibleComponent === 'editPractice' ? <EditEducation getProfile={setProfile} medicalData={medicalInfo.providerDegreeDetails} practiceData={medicalInfo.providerTrainingDetails} modalName={visibleComponent} />
                                        // :
                                        visibleComponent === 'editLang' ? <EditLang language={medicalInfo.languagesSpoken} getProfile={setProfile} setVisibleComponent={setVisibleComponent} />
                                            :
                                            <></>
                    } />
                ) : (
                        <></>
                    )}
                <div className={accountInfo.providerTypeValue && accountInfo.providerTypeValue === 'top' ? 'pro-profile-box-style provider-detail-left-top top-doc-border mb-4 mb-lg-0' : accountInfo.providerTypeValue && accountInfo.providerTypeValue === 'sponsored' ? 'pro-profile-box-style provider-detail-left-top sponsor-border mb-4 mb-lg-0' : 'pro-profile-box-style provider-detail-left-top mb-4 mb-lg-0'}>

                {
                    !authContext.user ?.accountInfo &&
                        <div className="doc-save profile" style={{cursor: 'pointer'}}  onClick={() => saveUnsaveFavDoc(props, 'save')}>
                            <img src="/assets/images/unsave-icon.svg" alt="" className="scale icon-docsave" />
                            Save Doctor
                        </div>
                }

                {
                    authContext.user ?.accountInfo && authContext.user ?.accountInfo ?.roleId != 3 && isSavedDoctor != 1 &&
                        <div className="doc-save profile" style={{cursor: 'pointer'}}  onClick={() => saveUnsaveFavDoc(props, 'save')}>
                            <img src="/assets/images/unsave-icon.svg" alt="" className="scale icon-docsave" />
                            Save Doctor
                        </div>
                }

                {
                    authContext.user ?.accountInfo && authContext.user ?.accountInfo ?.roleId != 3 && isSavedDoctor == 1 &&
                        <div className="doc-unsave profile"  style={{cursor: 'pointer'}}  onClick={() => saveUnsaveFavDoc(props, 'unsave')}>
                            <img src="/assets/images/save-icon.png" alt="" className="scale icon-docsave" />
                            Unsave Doctor
                        </div>
                }
                <span
                    className={accountInfo.providerTypeValue && accountInfo.providerTypeValue === 'top' ? 'top-doc-icon capitalize' : accountInfo.providerTypeValue && accountInfo.providerTypeValue === 'sponsered' ? 'sponsor-doc-icon capitalize' : 'capitalize'}

                >{accountInfo.providerTypeValue && (accountInfo.providerTypeValue === 'top' || accountInfo.providerTypeValue === 'sponsered') ? accountInfo.providerTypeValue + ' Doctor' : ''}</span>
                <div className="provider-detail-box">
                    {editable && <div style={{top: 0, transform: "translateY(-100%)"}} onClick={() => { openModal("editProfile") }}  className="edit-icon-position-top"></div>}
                    <div className="provider-doc-img">
                        {
                            accountInfo.profileImage ? (<>
                                <img src={accountInfo.profileImage} alt="Doctor" />
                            </>) : (<>
                                <img src="/assets/images/top-doc-img.png" alt="Doctor" />
                            </>)
                        }

                    </div>
                    <h5 style={{ textTransform: 'capitalize' }}>
                        {accountInfo.firstName ? accountInfo.firstName + ' ' : ''}
                        {accountInfo.middleName ? accountInfo.middleName + ' ' : ''}
                        {accountInfo.lastName ? accountInfo.lastName : ''}
                        {medicalInfo.providerMedicalCredientialsValue ? ', ' + medicalInfo.providerMedicalCredientialsValue : ''}
                        <span>Verified</span>
                    </h5>
                    <div className="provider-designation">{medicalInfo.providerMedicalSpecialitiesValue ? medicalInfo.providerMedicalSpecialitiesValue : ''}</div>
                    <div className="endorsements"><span>{props.endorsementCount ? props.endorsementCount : 0} Doctor endorsements</span></div>
                    
                    <div className="rating-result">
                        <span className="rating">
                            {/* <span className={props.ratingReviewsAvg ? `rating${props.ratingReviewsAvg}` : 'rating0'}></span> */}
                            <Stars 
                                {...props} 
                                rating={props.ratingReviewsAvg && props.ratingReviewsAvg ? parseFloat(props.ratingReviewsAvg).toFixed(1) : 0}
                                getProfile={setProfile}
                                componentName={ props.accountInfo ? 'profile' : ''}
                            />
                        </span>
                        <span>({props.ratingReviewsAvg ? props.ratingReviewsAvg : 0}) {props.ratingReviewsCount ? props.ratingReviewsCount : 0} Reviews</span>
                    </div>
                    {
                        !props.secondarySection ? (<>
                            <div className="endorse-link">
                                <a href="/#" onClick={(e) => redirectToSeachResult(e)}><span>Ask for a Doctor Endorsement</span></a>
                            </div>
                        </>) : (<>
                            <div className="endorse-link">
                            </div>
                        </>)
                    }

                </div>
                {
                    props.secondarySection ? (<>
                        {
                            patientRules.isAcceptingNewPatient || patientRules.hasVirtualVisit ? (<>
                                <div className="profile-eligible-icons">
                                    {patientRules.isAcceptingNewPatient ? (<>
                                        <span className="tick-icon">Accepting new patients</span>
                                    </>) : (<></>)}
                                    {patientRules.hasVirtualVisit ? (<>
                                        <span className="video-icon">Virtual visits available</span>
                                    </>) : (<></>)}
                                </div>
                            </>) : (<><div className="mb-4"></div></>)
                        }
                    </>) : (<>
                        <div className="doc-profile-switcher-wrap">
                            <div className="switcher-inner">
                                <span>Allow Patients to Send You Callback Requests</span>
                                <span>
                                    <label className="switch"><input type="checkbox" data-name="allowedPatient" onChange={(e) => changePatientRule(e)} checked={patientRules.allowedPatient ? true : false} id="switch-btn1" /><div className="slider round"></div></label>
                                </span>
                            </div>
                            <div className="switcher-inner">
                                <span>Are You Accepting New Patients?</span>
                                <span>
                                    <label className="switch"><input type="checkbox" data-name="isAcceptingNewPatient" onChange={(e) => changePatientRule(e)} checked={patientRules.isAcceptingNewPatient ? true : false} id="switch-btn2" /><div className="slider round"></div></label>
                                </span>
                            </div>
                            <div className="switcher-inner">
                                <span>Virtual Visit Capability?</span>
                                <span>
                                    <label className="switch"><input type="checkbox" data-name="hasVirtualVisit"
                                        onChange={(e) => changePatientRule(e)}
                                        checked={patientRules.hasVirtualVisit ? true : false}
                                        id="switch-btn3" />
                                        <div className="slider round"></div>
                                    </label>
                                </span>
                            </div>
                            <div className="switcher-inner">
                                <span>Allow Online Patient Scheduling?</span>
                                <span>
                                    <label className="switch"><input type="checkbox" data-name="allowOnline"
                                        onChange={(e) => changePatientRule(e)}
                                        checked={patientRules.allowOnline ? true : false}
                                        id="switch-btn3" />
                                        <div className="slider round"></div>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </>)

                }


                <div className="edu-lang-wrap">
                    <div className="head-edu">
                        Medical Education
                    <span className="edit-edu" onClick={() => { openModal("addEdu") }}>
                            <div className="plus-icon"></div>
                    </span>
                    </div>
                    <ul>
                        {
                            medicalInfo.providerDegreeDetails && medicalInfo.providerDegreeDetails.map((deg, degIndex) =>
                                <>
                                    <li className="desig-icon">
                                        <span>Degree {editable && <div onClick={() => { openModal("editDegree") }} className="edit-icon-position"></div>}</span>
                                        {deg ?.schools ?.name}, {deg.programTypes.title}<br />
                                        {deg.yearStarted} - {deg.yearCompleted}
                                    </li>
                                </>
                            )
                        }

                        {
                            medicalInfo.providerTrainingDetails && medicalInfo.providerTrainingDetails.map((training, trainingIndex) =>
                                <>
                                    <li className="resi-icon" key={trainingIndex}>
                                        <span>{training.trainingTypes ? training.trainingTypes.title : "--"} {editable && <div onClick={() => { openModal("editPractice") }} className="edit-icon-position"></div>}</span>
                                        {training.otherSchoolName ? training.otherSchoolName : ""}, {training.programName ? training.programName : ""}<br />
                                        {training.yearStarted ? training.yearStarted : ""} - {training.yearCompleted ? training.yearCompleted : ""}
                                    </li>
                                </>
                            )
                        }

                    </ul>
                    <div className="head-edu">Languages {editable && <div onClick={() => { openModal("editLang") }} className="edit-icon-position"></div>}</div>
                    <ul>
                        <li className="lang-icon" style={{ textTransform: 'capitalize' }}>
                            {
                                medicalInfo.languagesSpoken ? medicalInfo.languagesSpoken.join(', ') : ''
                            }
                        </li>
                    </ul>
                </div>
            </div>
            </Suspense>
        </Fragment>
    )

}

export default ProviderLeftProfile;