import React, { Fragment, useEffect, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as SelectIcon } from '../../../admin/assets/images/list-triangle.svg';
import { ReactComponent as FilterIcon } from '../../../admin/assets/images/filter.svg';
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import ManagementFilter from '../patient/filter';
import Paging from '../patient/paging';
import Custommodal from '../../../global/modal/modal';
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import Pagination from "../../../components/admin/pagination/pagination";
import EndorsementInfoModal from './endorsement-info-modal';
import * as moment from 'moment';

const ProEndorsement = (props) => {

    const authContext = useContext(AuthContext);

    const [data, setData] = useState([]);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [viewdetails, setViewdetails] = useState([]);
    const [sort, setsort] = useState('ASC');
    const [sortName, setSortName] = useState('Name');

    // FOR POPUP MODAL
    const modalList = ['manageInfo'];

    const getList = async (value) => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (value) {
            formData = {
                userId: props.providerDetails ?.accountInfo ?.id,
                searchValue: value,
                sortOrder: sort,
                sortBy: sortName
            }
        } else {
            formData = {
                userId: props.providerDetails ?.accountInfo ?.id,
                sortOrder: sort,
                sortBy: sortName
            }
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/endorsement/get',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        // console.log("result==>", result.body.result);
        setData(result ?.body ?.result);
        authContext.unsetLoader();
    }

    const getDetails = async (value) => {
        authContext.setLoader();

        // STRUCTURE LOGIN REQUEST DATA
        // const submitData = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'access_token': authContext.adminToken
        //     },
        //     url: 'admin/providers/endorsement/view/' + id,
        // }

        // // SEND REQUEST 
        // let result = await NetworkLayer.getRequest(submitData);
        // setViewdetails(result ?.result);
        setViewdetails(value);
        authContext.unsetLoader();
    }

    const deleteProviderEndorsement = async (id) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/endorsement/destroy',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        if (window.confirm('Are you sure you want to delete this provider endorsement?')) {
            let result = await NetworkLayer.postData(submitData);
            if (result) {
                getList();
            }

        }

        authContext.unsetLoader();
    }

    const openProfileTabModal = (modalName, value) => {
        getDetails(value).then(() => {
            authContext.showModal()
            settabvisibleComponent(modalName)
        })
    }

    const searchFilter = (value) => {
        console.log(value);
        getList(value);
    }

    const changeSorting = (e) => {
        let name = e.currentTarget.getAttribute('name');
        setSortName(name);
        setsort(sort == "ASC" ? "DESC" : "ASC")
    };

    useEffect(() => {
        getList();
    }, [sort])

    return (
        <Fragment>
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'manageInfo' ? <EndorsementInfoModal data={viewdetails} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <ManagementFilter search={searchFilter} />
            <div className="table-report-scroll mt-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="data-sort">
                                <span className="sort-outer">
                                    Provider
                                    <span className="data-sort-icon" name="Name" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>
                                <span className="sort-outer">
                                    Date Endorsed
                                    <span className="data-sort-icon" name="Name" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th className="data-sort">
                                <span className="sort-outer">
                                    Specialty
                                    <span className="data-sort-icon" name="Name" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th className="data-sort">
                                <span className="sort-outer">
                                    Endorsement<br />Received/Given
                                    <span className="data-sort-icon" name="Name" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !data && <tr>
                                <td>No Results Found</td>
                            </tr>
                        }
                        {
                            data && data.map((value, index) =>
                                <tr>
                                    <td>{value.providerName}</td>
                                    <td>{value.dateEndoresed}</td>
                                    <td> {value.Speciality[0]}</td>
                                    <td>{value.type}</td>
                                    <td>
                                        <Link className="table-action view-all" onClick={() => { openProfileTabModal("manageInfo", value) }}>View</Link>
                                        <Link className="table-action view-all" onClick={() => { deleteProviderEndorsement(value.id) }}>Delete</Link>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            {/* <Paging /> */}

        </Fragment>
    );
}

export default ProEndorsement;