import React, { Fragment, useContext } from 'react';
import AuthContext from '../../reactLayer/auth/AuthContext';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import ManagementTable from '../../components/admin/patient/management-table';

const AdminPatientManagement = () => {
    const authContext = useContext(AuthContext);
    return (
        <Fragment>
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section patient-management-outer"}>

                    <Row className="bg-white-report">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Patient Management</div>
                            </div>
                            {/* <ManagementFilter /> */}
                            <ManagementTable />
                            {/* <Paging/> */}
                        </div>
                    </Row>

                    {/* <PatientDetail/> */}

                </Container>
            </div>
        </Fragment>
    );
}

export default AdminPatientManagement;