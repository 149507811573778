import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';


const ProviderStep = (props) => {


  const step = props.step ? props.step : '1';
  const list = ['Account Information', 'Medical Specialty', 'Practice Information', 'Select a Plan', 'Payment']
  return (
    <Fragment>
    <Container key={uuidv4()}>
        <Row key={uuidv4()}>
            <div className="col">
                <h1 className="text-center mt-md-5 mt-3">Provider On-Boarding <span>(5 Minute Task)</span></h1>
            </div>
        </Row>
    </Container>
    <Container key={uuidv4()}>
        <Row key={uuidv4()}>
            <div className="col pl-0 pr-0">
                <div className="stepper-wrap">        
                    <ul className="steps">
                        {
                            list.map((d,i) =>

                                <li 
                                    key={uuidv4()} 
                                    className={step > i+1 ? "step is-complete is-active" : step == i+1 ? "step is-active" : "step" }  

                                    data-step={i+1}>
                                    {d}
                                </li>    
                            
                            )
                        }
                        {/* <li className="step is-complete is-active" data-step="">Account Information</li>
                        <li className="step is-complete is-active" data-step="2">Medical Specialty</li>
                        <li className="step is-active" data-step="3">Practice Information</li>
                        <li className="step" data-step="4">Select a Plan</li>
                        <li className="step" data-step="5">Payment</li> */}
                    </ul>        
                </div>
            </div>
        </Row>
    </Container>
    </Fragment>
    );
  }
  
  export default ProviderStep;