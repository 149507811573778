import React, { Fragment, useState, useContext, useEffect, lazy, Suspense } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';
import InputValidation from '../../reactLayer/validation';

import { ProviderLinks,PatientLinks } from '../../linkFile';
import Error404 from '../../global/errorpages/error-404';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';

import ProviderStep4 from '../provider-steps/provider-step4';
import PaymentPlans from '../payment-plan/payment-plan';
import PaymentDetails from '../payment-plan/payment-details';
import EditCard from './edit-card-detail';

const EditName = (props) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const monthList = Global.getCurrentYear();
    const yearList = Global.getYearList();
    const yearListChild = Global.getYearListChild();
    const [insuranceList, setinsuranceList] = useState([]);
    const [deleteflag, setdeleteflag] = useState(false);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [filterCities, setfilterCities] = useState([]);
    const [zipCodes,setZipCodes] = useState([])

    const cardId = props.cardId ? props.cardId : '0';
    const accountInfo = authContext.user && authContext.user.accountInfo ? authContext.user.accountInfo : {};
    const insuranceInfo = authContext.user && authContext.user.patientInsurances ? authContext.user.patientInsurances : {};
    const elementName = props.elementName ? props.elementName : 'name'
    const updateElement = props.elementName ? props.elementName.split('-')[1] : 'name';
    const [editable, seteditable] = useState(false);
    
    const getInsurance = async () => {
        let stateResponse = await Global.getStates();
        setStates(stateResponse);
        if( accountInfo.cityId ) {
            let cityResponse = await Global.getCities(accountInfo.stateId);
            setfilterCities(cityResponse);
            if( accountInfo && accountInfo.city_name && cityResponse.citylist && Object.keys(cityResponse.citylist).length > 0) {
                let zip = [];
                for (const key in cityResponse.citylist) { 
                    if( key.toLowerCase() == accountInfo.city_name.toLowerCase()) {
                        zip = cityResponse.citylist[key];
                    }
                }
                setZipCodes([...zip]);
            }
        }
        
        let insResponse = await Global.getInsurance();
        setinsuranceList(insResponse);

    }
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
    });

    const checkPhoneType = (phoneType) => {
        if( accountInfo.roleId == 3 && phoneType == 'home') {
            return 'Office';
        } else {
            return phoneType;
        }
        
    }
    const deleteProfilePatient = async () => {

        authContext.setLoader();
        let url;
        if (accountInfo.roleId === 3) {
            url = 'providers/account-information/update';
        } else {
            url = 'patients/profile-information/update';
        }
        let formData = { formType: 'isDeleted', isDeleted: 1 }

        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: url,
            body: JSON.stringify(formData),

        }
        let result = await NetworkLayer.postData(submitData);
        authContext.unsetLoader();
        if (result.body.status) {

            setmodalProps({
                type: 'success',
                msg: result.body.message,
            });
            authContext.showResponseModal();

            setTimeout(() => {
                authContext.logout();
                history.push({
                    pathname: `${PatientLinks.profile_deleted}`,
                });
            }, 3000);

        } else {
            setmodalProps({
                type: 'error',
                msg: result.body.message,
            });
            authContext.showResponseModal();
        }
    }
    const responseDeletePatient = async () => {
        setdeleteflag(true);
        deleteProfilePatient();
    }
    const confirmDeletePatient = async () => {
        setmodalProps({
            type: 'question',
            msg: 'This action cannot be undone. Are you sure you would like to delete your DocScheduler account?',
            btnname: 'Yes',
            redirection: '',
            action: responseDeletePatient,
            btncancel: 'No',
            autoclose: 'false'

        });
        authContext.showResponseModal();
    }
    const getInsuranceName = (id) => {
        let insName = [];
        insName = insuranceList.filter((ins, ind) => {
            if (ins.id === id) {
                return true;
            }
        })
        if (insName.length) {
            return insName[0].name;
        } else {
            return '';
        }
    }
    const convertDob = (date, onlyFormat = false) => {
        // console.log('date change section', date);
        let dob = { year: '', month: '', day: '' };
        if (date && date != '' && new Date(date) instanceof Date) {

            let fdate = new Date(date);
            dob.year = fdate.getFullYear()
            dob.month = fdate.getMonth() + 1
            dob.day = fdate.getDate()

            if (onlyFormat) {
                return `${dob.month}/${dob.day}/${dob.year}`
            } else {
                return dob;
            }
        }
        return dob;

    }
    const [updateData, setUpdateData] = useState({
        name: {
            firstName: {
                value: accountInfo.firstName,
                validation: ['blank', 'name'],
                errorMsg: ['This filed is required', 'Invalid First Name'],
                errorKey: 'firstName',
                firstName: false
            },
            middleName: {
                value: accountInfo.middleName,
                validation: ['name'],
                errorMsg: ['Invalid MIddle Name'],
                errorKey: 'middleName',
                middleName: false
            },
            lastName: {
                value: accountInfo.lastName,
                validation: ['blank', 'name'],
                errorMsg: ['This filed is required', 'Invalid Last Name'],
                errorKey: 'lastName',
                lastName: false
            }
        },
        email: {
            recoveryEmail: {
                value: accountInfo.firstName,
                validation: ['blank', 'email'],
                errorMsg: ['This filed is required', 'Invalid Email'],
                errorKey: 'recoveryEmail',
                recoveryEmail: false
            },
        },
        birthday: {
            day: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'day',
                day: false
            },
            month: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'month',
                month: false
            },
            year: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'year',
                year: false
            }
        },
        address: {
            add: {
                value: accountInfo.address ? accountInfo.address : '',
                validation: [],
                errorMsg: ['This filed is required'],
                errorKey: 'add',
                add: false
            },
            state: {
                value: accountInfo.stateId ? accountInfo.stateId : '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'state',
                state: false
            },
            city: {
                value: accountInfo.cityId ? accountInfo.cityId : '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'city',
                city: false
            },
            zipcode: {
                value: accountInfo.zipcode ? accountInfo.zipcode : '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'zipcode',
                zipcode: false
            }
        },
        phone: {
            phoneType: {
                value: accountInfo.phoneType,
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'phoneType',
                phoneType: false
            },
            phoneNumber: {
                value: accountInfo.phoneNumber ? accountInfo.phoneNumber : '',
                validation: ['indianmobile'],
                errorMsg: ['Invalid Cell Number'],
                errorKey: 'phoneNumber',
                phoneNumber: false
            },
            homeNumber: {
                value: accountInfo.homeNumber ? accountInfo.homeNumber : '',
                validation: ['indianmobile'],
                errorMsg: [`${accountInfo.roleId == 3 ? 'Invalid Office Number' : 'Invalid Home Number'}`],
                errorKey: 'homeNumber',
                homeNumber: false

            }
        },
        gender: {
            sex: {
                value: accountInfo.gender,
                validation: ['blank'],
                errorMsg: ['Please select gender'],
                errorKey: 'sex',
                sex: false
            }
        },
        pronoun: {
            gender: {
                value: accountInfo.gender,
                validation: ['blank'],
                errorMsg: ['Please select gender'],
                errorKey: 'gender',
                gender: false
            },
            preferredPronoun: {
                value: accountInfo.preferredPronoun,
                validation: ['blank'],
                errorMsg: ['Please select preferred pronoun'],
                errorKey: 'preferredPronoun',
                preferredPronoun: false
            }
        },
        verification: {
            isVerifyAuth: {
                value: accountInfo.isVerifyAuth,
                errorKey: 'isVerifyAuth',
                isVerifyAuth: false
            }
        },
        npi: {
            nationalProviderIdentifierNumber: {
                value: accountInfo.nationalProviderIdentifierNumber,
                validation: ['blank', 'number'],
                errorMsg: ['This filed is required', 'Invalid National Provider Identifier'],
                errorKey: 'nationalProviderIdentifierNumber',
                nationalProviderIdentifierNumber: false
            }
        },
        phoneno: {
            recoveryPhone: {
                value: accountInfo.recoveryPhone,
                validation: ['blank', 'indianmobile'],
                errorMsg: ['This filed is required', 'Invalid Recovery Number'],
                errorKey: 'recoveryPhone',
                recoveryPhone: false
            }
        },
        license: {
            medicalLicenseNumber: {
                value: accountInfo.medicalLicenseNumber,
                validation: ['blank', 'alphanumeric'],
                errorMsg: ['This filed is required', 'Invalid Medical License Number'],
                errorKey: 'medicalLicenseNumber',
                medicalLicenseNumber: false
            }
        },
        password: {
            oldPassword: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'oldPassword',
                oldPassword: false
            },
            newPassword: {
                value: '',
                validation: ['blank', 'password'],
                errorMsg: ['This filed is required', 'Invalid password'],
                errorKey: 'newPassword',
                newPassword: false
            },
            confirmPassword: {
                value: '',
                validation: ['blank', 'password', { confirmPassword: { password: '' } }],
                errorMsg: ['This filed is required', 'Invalid password', 'Confirm password not matched '],
                errorKey: 'confirmPassword',
                confirmPassword: false
            }
        },
        medical: {
            insuranceId: {
                value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].insuranceId : '',
                validation: [],
                errorMsg: ['Please select insurance name'],
                errorKey: 'insuranceId',
                insuranceId: false
            },
            membershipId: {
                value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].membershipId : '',
                validation: ['alphanumeric'],
                errorMsg: ['Invalid membership id'],
                errorKey: 'membershipId',
                membershipId: false
            },
            planName: {
                value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].planName : '',
                validation: ['name'],
                errorMsg: ['Invalid plan name'],
                errorKey: 'planName',
                planName: false
            },
            group: {
                value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].group : '',
                validation: [],
                errorMsg: ['This filed is required'],
                errorKey: 'group',
                group: false
            }
        },
        dental: {
            insuranceId: {
                value: insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].insuranceId : '',
                validation: [],
                errorMsg: ['Please select insurance name'],
                errorKey: 'insuranceId',
                insuranceId: false
            },
            membershipId: {
                value: insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].membershipId : '',
                validation: ['alphanumeric'],
                errorMsg: ['Invalid membership id'],
                errorKey: 'membershipId',
                membershipId: false
            },
            planName: {
                value: insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].planName : '',
                validation: ['name'],
                errorMsg: ['This filed is required', 'Invalid plan name'],
                errorKey: 'planName',
                planName: false
            },
            group: {
                value: insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].group : '',
                validation: [],
                errorMsg: ['This filed is required'],
                errorKey: 'group',
                group: false
            }
        },
        vision: {
            insuranceId: {
                value: insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].insuranceId : '',
                validation: [],
                errorMsg: ['Please select insurance name'],
                errorKey: 'insuranceId',
                insuranceId: false
            },
            membershipId: {
                value: insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].membershipId : '',
                validation: ['alphanumeric'],
                errorMsg: ['Invalid membership id'],
                errorKey: 'membershipId',
                membershipId: false
            },
            planName: {
                value: insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].planName : '',
                validation: ['name'],
                errorMsg: ['Invalid plan name'],
                errorKey: 'planName',
                planName: false
            },
            group: {
                value: insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].group : '',
                validation: [],
                errorMsg: ['This filed is required'],
                errorKey: 'group',
                group: false
            }
        },
        child: {
            firstName: {
                value: insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].firstName : '',
                validation: ['blank', 'name'],
                errorMsg: ['This filed is required', 'Invalid First Name'],
                errorKey: 'firstName',
                firstName: false
            },
            middleName: {
                value: insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].middleName : '',
                validation: ['name'],
                errorMsg: ['Invalid MIddle Name'],
                errorKey: 'middleName',
                middleName: false
            },
            lastName: {
                value: insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].lastName : '',
                validation: ['blank', 'name'],
                errorMsg: ['This filed is required', 'Invalid Last Name'],
                errorKey: 'lastName',
                lastName: false
            },
            day: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'day',
                day: false
            },
            month: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'month',
                month: false
            },
            year: {
                value: '',
                validation: ['blank'],
                errorMsg: ['This filed is required'],
                errorKey: 'year',
                year: false
            },
            insuranceId: {
                value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].insuranceId : '',
                validation: [],
                errorMsg: ['Please select insurance name'],
                errorKey: 'insuranceId',
                insuranceId: false
            },
            membershipId: {
                value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].membershipId : '',
                validation: ['alphanumeric'],
                errorMsg: ['Invalid membership id'],
                errorKey: 'membershipId',
                membershipId: false
            },
            planName: {
                value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].planName : '',
                validation: ['name'],
                errorMsg: ['Invalid plan name'],
                errorKey: 'planName',
                planName: false
            },
            group: {
                value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].group : '',
                validation: [],
                errorMsg: ['This filed is required'],
                errorKey: 'group',
                group: false
            }
        },


    })

    const resetValuesOnCancel = () => {
        setUpdateData({
            name: {
                firstName: {
                    value: accountInfo.firstName,
                    validation: ['blank', 'name'],
                    errorMsg: ['This filed is required', 'Invalid First Name'],
                    errorKey: 'firstName',
                    firstName: false
                },
                middleName: {
                    value: accountInfo.middleName,
                    validation: ['name'],
                    errorMsg: ['Invalid MIddle Name'],
                    errorKey: 'middleName',
                    middleName: false
                },
                lastName: {
                    value: accountInfo.lastName,
                    validation: ['blank', 'name'],
                    errorMsg: ['This filed is required', 'Invalid Last Name'],
                    errorKey: 'lastName',
                    lastName: false
                }
            },
            email: {
                recoveryEmail: {
                    value: accountInfo.firstName,
                    validation: ['blank', 'email'],
                    errorMsg: ['This filed is required', 'Invalid Email'],
                    errorKey: 'recoveryEmail',
                    recoveryEmail: false
                },
            },
            birthday: {
                day: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'day',
                    day: false
                },
                month: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'month',
                    month: false
                },
                year: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'year',
                    year: false
                }
            },
            address: {
                add: {
                    value: accountInfo.address ? accountInfo.address : '',
                    validation: [],
                    errorMsg: ['This filed is required'],
                    errorKey: 'add',
                    add: false
                },
                state: {
                    value: accountInfo.stateId ? accountInfo.stateId : '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'state',
                    state: false
                },
                city: {
                    value: accountInfo.cityId ? accountInfo.cityId : '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'city',
                    city: false
                },
                zipcode: {
                    value: accountInfo.zipcode ? accountInfo.zipcode : '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'zipcode',
                    zipcode: false
                }
            },
            phone: {
                phoneType: {
                    value: accountInfo.phoneType,
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'phoneType',
                    phoneType: false
                },
                phoneNumber: {
                    value: accountInfo.phoneNumber,
                    validation: ['indianmobile'],
                    errorMsg: ['Invalid Cell Number'],
                    errorKey: 'phoneNumber',
                    phoneNumber: false
                },
                homeNumber: {
                    value: accountInfo.homeNumber ? accountInfo.homeNumber : '',
                    validation: ['indianmobile'],
                    errorMsg: [`${accountInfo.roleId == 3 ? 'Invalid Office Number' : 'Invalid Home Number'}`],
                    errorKey: 'homeNumber',
                    homeNumber: false
    
                }
            },
            gender: {
                sex: {
                    value: accountInfo.gender,
                    validation: ['blank'],
                    errorMsg: ['Please select gender'],
                    errorKey: 'sex',
                    sex: false
                }
            },
            pronoun: {
                gender: {
                    value: accountInfo.gender,
                    validation: ['blank'],
                    errorMsg: ['Please select gender'],
                    errorKey: 'gender',
                    gender: false
                },
                preferredPronoun: {
                    value: accountInfo.preferredPronoun,
                    validation: ['blank'],
                    errorMsg: ['Please select preferred pronoun'],
                    errorKey: 'preferredPronoun',
                    preferredPronoun: false
                }
            },
            verification: {
                isVerifyAuth: {
                    value: accountInfo.isVerifyAuth,
                    errorKey: 'isVerifyAuth',
                    isVerifyAuth: false
                }
            },
            npi: {
                nationalProviderIdentifierNumber: {
                    value: accountInfo.nationalProviderIdentifierNumber,
                    validation: ['blank', 'number'],
                    errorMsg: ['This filed is required', 'Invalid National Provider Identifier'],
                    errorKey: 'nationalProviderIdentifierNumber',
                    nationalProviderIdentifierNumber: false
                }
            },
            phoneno: {
                recoveryPhone: {
                    value: accountInfo.recoveryPhone,
                    validation: ['blank', 'indianmobile'],
                    errorMsg: ['This filed is required', 'Invalid Recovery Number'],
                    errorKey: 'recoveryPhone',
                    recoveryPhone: false
                }
            },
            license: {
                medicalLicenseNumber: {
                    value: accountInfo.medicalLicenseNumber,
                    validation: ['blank', 'alphanumeric'],
                    errorMsg: ['This filed is required', 'Invalid Medical License Number'],
                    errorKey: 'medicalLicenseNumber',
                    medicalLicenseNumber: false
                }
            },
            password: {
                oldPassword: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'oldPassword',
                    oldPassword: false
                },
                newPassword: {
                    value: '',
                    validation: ['blank', 'password'],
                    errorMsg: ['This filed is required', 'Invalid password'],
                    errorKey: 'newPassword',
                    newPassword: false
                },
                confirmPassword: {
                    value: '',
                    validation: ['blank', 'password', { confirmPassword: { password: '' } }],
                    errorMsg: ['This filed is required', 'Invalid password', 'Confirm password not matched '],
                    errorKey: 'confirmPassword',
                    confirmPassword: false
                }
            },
            medical: {
                insuranceId: {
                    value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].insuranceId : '',
                    validation: [],
                    errorMsg: ['Please select insurance name'],
                    errorKey: 'insuranceId',
                    insuranceId: false
                },
                membershipId: {
                    value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].membershipId : '',
                    validation: ['alphanumeric'],
                    errorMsg: ['Invalid membership id'],
                    errorKey: 'membershipId',
                    membershipId: false
                },
                planName: {
                    value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].planName : '',
                    validation: ['name'],
                    errorMsg: ['Invalid plan name'],
                    errorKey: 'planName',
                    planName: false
                },
                group: {
                    value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].group : '',
                    validation: [],
                    errorMsg: ['This filed is required'],
                    errorKey: 'group',
                    group: false
                }
            },
            dental: {
                insuranceId: {
                    value: insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].insuranceId : '',
                    validation: [],
                    errorMsg: ['Please select insurance name'],
                    errorKey: 'insuranceId',
                    insuranceId: false
                },
                membershipId: {
                    value: insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].membershipId : '',
                    validation: ['alphanumeric'],
                    errorMsg: ['Invalid membership id'],
                    errorKey: 'membershipId',
                    membershipId: false
                },
                planName: {
                    value: insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].planName : '',
                    validation: ['name'],
                    errorMsg: ['This filed is required', 'Invalid plan name'],
                    errorKey: 'planName',
                    planName: false
                },
                group: {
                    value: insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].group : '',
                    validation: [],
                    errorMsg: ['This filed is required'],
                    errorKey: 'group',
                    group: false
                }
            },
            vision: {
                insuranceId: {
                    value: insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].insuranceId : '',
                    validation: [],
                    errorMsg: ['Please select insurance name'],
                    errorKey: 'insuranceId',
                    insuranceId: false
                },
                membershipId: {
                    value: insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].membershipId : '',
                    validation: ['alphanumeric'],
                    errorMsg: ['Invalid membership id'],
                    errorKey: 'membershipId',
                    membershipId: false
                },
                planName: {
                    value: insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].planName : '',
                    validation: ['name'],
                    errorMsg: ['Invalid plan name'],
                    errorKey: 'planName',
                    planName: false
                },
                group: {
                    value: insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].group : '',
                    validation: [],
                    errorMsg: ['This filed is required'],
                    errorKey: 'group',
                    group: false
                }
            },
            child: {
                firstName: {
                    value: insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].firstName : '',
                    validation: ['blank', 'name'],
                    errorMsg: ['This filed is required', 'Invalid First Name'],
                    errorKey: 'firstName',
                    firstName: false
                },
                middleName: {
                    value: insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].middleName : '',
                    validation: ['name'],
                    errorMsg: ['Invalid MIddle Name'],
                    errorKey: 'middleName',
                    middleName: false
                },
                lastName: {
                    value: insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].lastName : '',
                    validation: ['blank', 'name'],
                    errorMsg: ['This filed is required', 'Invalid Last Name'],
                    errorKey: 'lastName',
                    lastName: false
                },
                day: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'day',
                    day: false
                },
                month: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'month',
                    month: false
                },
                year: {
                    value: '',
                    validation: ['blank'],
                    errorMsg: ['This filed is required'],
                    errorKey: 'year',
                    year: false
                },
                insuranceId: {
                    value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].insuranceId : '',
                    validation: [],
                    errorMsg: ['Please select insurance name'],
                    errorKey: 'insuranceId',
                    insuranceId: false
                },
                membershipId: {
                    value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].membershipId : '',
                    validation: ['alphanumeric'],
                    errorMsg: ['Invalid membership id'],
                    errorKey: 'membershipId',
                    membershipId: false
                },
                planName: {
                    value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].planName : '',
                    validation: ['name'],
                    errorMsg: ['Invalid plan name'],
                    errorKey: 'planName',
                    planName: false
                },
                group: {
                    value: insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].group : '',
                    validation: [],
                    errorMsg: ['This filed is required'],
                    errorKey: 'group',
                    group: false
                }
            }
        });
    }
    const changeFields = (e) => {
        e.preventDefault();
        seteditable(editable ? false : true);
        //resetValuesOnCancel();
    }
    const SubmitButton = () => {
        return (
            <div className="col-lg-4 col-md-4 text-md-right pt-lg-1">
                <div className="edit-btn-profile">
                    <a onClick={(e) => { changeFields(e) }} href="\#" className="prev-btn">Cancel</a>
                    <input type="button" onClick={() => submitForm()} className="next-btn-pro" value="Save" />
                </div>
            </div>
        )
    }
   

    const modifyData = async (e) => {
        let index, name, value,
            tempData = {};
        name = e.currentTarget.getAttribute('name');
        value = e.currentTarget.value;
        //  console.log('name-->', name, 'value-->', value);

        tempData = updateData[updateElement];
        if (name == 'phoneNumber' || name == 'homeNumber' || name === 'recoveryPhone') {
            console.log( 'Global.phoneFormator(value)', typeof(Global.phoneFormator(value)));
            tempData[name]['value'] = typeof(Global.phoneFormator(value)) == 'undefined' ? '' : Global.phoneFormator(value)
        } else if (name == 'state' ) {
            tempData[name]['value'] = value;
            tempData['city']['value'] = '';
            tempData['zipcode']['value'] = '';
            authContext.setLoader();
            let cityResponse = await Global.getCities(value);
            setfilterCities(cityResponse);
            authContext.unsetLoader();
        } else if (name == 'city' ) {
            tempData[name]['value'] = value;
            tempData['zipcode']['value'] = '';
            setZipCodes(JSON.parse(e.currentTarget.selectedOptions[0].getAttribute('data-zipcode')))
        } else if (name == 'newPassword') {
            tempData[name]['value'] = value;
            tempData['confirmPassword']['validation'] = ['blank', 'password', { confirmPassword: { password: value } }];
        } else if (name == 'isVerifyAuth') {
            if (e.currentTarget.checked) {
                tempData[name]['value'] = 1;
            } else {
                tempData[name]['value'] = 0;
            }
        } else if (name == 'phoneType') {
            tempData['phoneNumber']['phoneNumber'] = false;
            tempData['homeNumber']['homeNumber'] = false;
            tempData[name]['value'] = value;
        } else if (name == 'nationalProviderIdentifierNumber') {
            if( !isNaN(value)) {
                tempData[name]['value'] = value;
            } else {
                tempData[name]['value'] = value.slice(0, -1);
            }
        } else {
            tempData[name]['value'] = value;
        }
        tempData[name][name] = false;
        // console.log('tempData-->', tempData);
        // return false;
        setUpdateData({ ...updateData, ...tempData });
        // console.log('setUpdateData==>',updateData);
        // setpracticeData([...tempData]);
        // formSubmit();
    }
    const submitForm = async () => {
         console.log('fasfasfasf232323', updateData[updateElement]);
         
        let validationResult = InputValidation(updateData[updateElement]);
        //   console.log('validationResult',validationResult);
        let flag = true;
        let tempData = updateData[updateElement];
        if (Object.keys(validationResult).length > 0) {
            flag = false;
            for (const prop in validationResult) {
                tempData[prop][prop] = `${validationResult[prop]}`;
            }
            setUpdateData({ ...updateData, ...tempData });
        }

        if (flag) {
            let url,
                formData,
                updatedData = updateData[updateElement];

            if (accountInfo.roleId === 4) {
                formData = {}
                url = 'patients/profile-information/update';

                if (updateElement == 'verification') {
                    formData = { formType: updateElement };
                }

            } else {
                formData = { formType: updateElement };
                url = 'providers/account-information/update';
            }
            
            if (updateElement === 'birthday') {
                let currentDate = new Date().getTime();
                let userDob = new Date(updatedData.year.value + '-' + updatedData.month.value.toString().padStart(2, "0") + '-' + updatedData.day.value.toString().padStart(2, "0")).getTime();
                if (userDob > currentDate) {
                    updatedData['year']['year'] = 'Invalid Date of Birth';
                    setUpdateData({ ...updateData, ...updatedData });
                    return false;
                } else {
                    formData['dob'] = updatedData.month.value.toString().padStart(2, "0") + '-' + updatedData.day.value.toString().padStart(2, "0") + '-' + updatedData.year.value;
                }
            } else if (updateElement === 'gender') {
                formData.gender = updatedData['sex']['value'];
            } else if (updateElement === 'phone') {
                // console.log('updateElement===>',updatedData);

                if (updatedData.phoneType.value === 'cell') {
                    if (updatedData.phoneNumber.value.trim() === '') {
                        updatedData.phoneNumber.phoneNumber = 'This filed is required';
                        setUpdateData({ ...updateData, ...updatedData });
                        return false;
                    } else {
                        for (const prop in updateData[updateElement]) {
                            formData[prop] = updatedData[prop]['value'];
                        }
                    }
                } else if (updatedData.phoneType.value === 'home') {
                    if (updatedData.homeNumber.value.trim() === '') {
                        updatedData.homeNumber.homeNumber = 'This filed is required';
                        setUpdateData({ ...updateData, ...updatedData });
                        return false;
                    } else {
                        for (const prop in updateData[updateElement]) {
                            formData[prop] = updatedData[prop]['value'];
                        }
                    }
                }
                // return false;
            } else if (updateElement === 'address') {
                for (const prop in updateData[updateElement]) {
                    formData[prop] = updatedData[prop]['value'];
                }
                formData['address'] = formData['add'];
                delete formData['add'];
                // return false;
            } else {
                for (const prop in updateData[updateElement]) {
                    formData[prop] = updatedData[prop]['value'];
                }
            }

            // console.log(formData,JSON.stringify(formData));
            // let url = 'providers/account-information/update';
            if (updateElement === 'password') {
                url = 'change-password';
                formData.formType && delete formData.formType;
            } else if (updateElement == 'medical' || updateElement == 'dental' || updateElement == 'vision' || updateElement == 'child') {
                url = 'patients/insurance-information';
                formData.formType && delete formData.formType;
                let tempFormData = formData;
                formData = {};
                if (updateElement === 'child') {
                    let currentDate = new Date().getTime();
                    let userDob = new Date(updatedData.year.value + '-' + updatedData.month.value.toString().padStart(2, "0") + '-' + updatedData.day.value.toString().padStart(2, "0")).getTime();
                    if (userDob > currentDate) {
                        updatedData['year']['year'] = 'Invalid Date of Birth';
                        setUpdateData({ ...updateData, ...updatedData });
                        return false;
                    } else {
                        tempFormData['dob'] = updatedData.month.value.toString().padStart(2, "0") + '-' + updatedData.day.value.toString().padStart(2, "0") + '-' + updatedData.year.value;
                        tempFormData.year && delete tempFormData.year;
                        tempFormData.month && delete tempFormData.month;
                        tempFormData.day && delete tempFormData.day;
                    }
                }
                formData.patient_insurances = { [updateElement]: [tempFormData] };
            }
            // console.log('formData===>', formData);
            // return false;
            authContext.setLoader();
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: url,
                body: JSON.stringify(formData),

            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if (result.body.status) {
                if (updateElement !== 'password') {
                    let user = result.body.result;
                    authContext.setUser(user);
                }
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                seteditable(false);
            } else {
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
            // console.log('result ', result);
        }

    }
    useEffect(() => {
        let dob = { year: '', month: '', date: '' };
        if (accountInfo.dob && accountInfo.dob != '') {
            let date = new Date(accountInfo.dob);
            if (date instanceof Date) {
                dob.year = date.getFullYear()
                dob.month = date.getMonth() + 1
                dob.date = date.getDate()
                let birthday = {
                    day: {
                        value: dob.date,
                        validation: ['blank'],
                        errorMsg: ['This filed is required'],
                        errorKey: 'day',
                        day: false
                    },
                    month: {
                        value: dob.month,
                        validation: ['blank'],
                        errorMsg: ['This filed is required'],
                        errorKey: 'month',
                        month: false
                    },
                    year: {
                        value: dob.year,
                        validation: ['blank'],
                        errorMsg: ['This filed is required'],
                        errorKey: 'year',
                        year: false
                    }
                }
                console.log('birthday',birthday)
                setUpdateData({ ...updateData, ...{ birthday } });
            }

        }
        getInsurance();
        return () => {
            authContext.hideResponseModal();
        };
    }, [])
    const suspenseLoader = () => <p></p>;
    return (
        <Suspense fallback={suspenseLoader()}>
            <Container>
                <Container className="my-account-wrap">
                    <Row>
                        {
                            authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                        }
                        {
                            elementName == 'profile-name' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner profile-name-innerbox">
                                            {/* Name fields */}
                                            <h3>Name</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4">
                                                    <div className="col-lg-8 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">First Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="firstName"
                                                                    defaultValue={accountInfo.firstName} autoComplete="off"
                                                                    className={updateData.name.firstName.firstName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.name.firstName.firstName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Middle Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="middleName" defaultValue={accountInfo.middleName} autoComplete="off"
                                                                    className={updateData.name.middleName.middleName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.name.middleName.middleName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Last Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="lastName"
                                                                    defaultValue={accountInfo.lastName} autoComplete="off"
                                                                    className={updateData.name.lastName.lastName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.name.lastName.lastName}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">

                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">First Name:</div>
                                                                <div className="edit-field">{accountInfo.firstName}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Middle Name:</div>
                                                                <div className="edit-field">{accountInfo.middleName}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Last Name:</div>
                                                                <div className="edit-field">{accountInfo.lastName}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'profile-birthday' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner birthday-pro-innerbox">
                                            {/* Birthday fields */}
                                            <h3>Birthday</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-birth-fields">
                                                            <div>
                                                                <div className="myform">
                                                                    <select
                                                                        name="month"
                                                                        className={updateData.birthday.month.month ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                        defaultValue={new Date(accountInfo.dob) instanceof Date ? parseInt(new Date(accountInfo.dob).getMonth()) + 1 : ''}
                                                                    >
                                                                        <option>Month</option>
                                                                        {monthList.map((month, index_index) =>
                                                                            <option key={month.id + '-month'} value={month.id}>{month.name}</option>
                                                                        )}

                                                                    </select>
                                                                    <small className="error-input">{updateData.birthday.month.month}</small>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="myform">
                                                                    <select
                                                                        name="day"
                                                                        className={updateData.birthday.day.day ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                        defaultValue={new Date(accountInfo.dob) instanceof Date ? new Date(accountInfo.dob).getDate() : ''}
                                                                    >
                                                                        <option>Day</option>
                                                                        {[...new Array(31)].map((day, day_index) =>
                                                                            <option key={1 + day_index + '-days'} value={day_index + 1}>{day_index + 1}</option>
                                                                        )}
                                                                    </select>
                                                                    <small className="error-input">{updateData.birthday.day.day}</small>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="myform">
                                                                    <select
                                                                        name="year"
                                                                        className={updateData.birthday.year.year ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                        defaultValue={new Date(accountInfo.dob) instanceof Date ? new Date(accountInfo.dob).getFullYear() : ''}
                                                                    >
                                                                        <option>Year</option>
                                                                        {yearList.map((year) =>
                                                                            <option key={year + '-year'} value={year}>{year}</option>
                                                                        )}
                                                                    </select>
                                                                    <small className="error-input">{updateData.birthday.year.year}</small>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7 pb-3">

                                                            <div className="account-edit-box">
                                                                <div className="edit-field"> {new Date(accountInfo.dob) instanceof Date ? (<> {(parseInt(new Date(accountInfo.dob).getMonth()) + 1).toString().padStart(2, "0")}/{new Date(accountInfo.dob).getDate().toString().padStart(2, "0")}/{new Date(accountInfo.dob).getFullYear()} </>) : (<>DD/MM/YYYY</>)}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'profile-address' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner birthday-pro-innerbox address-box" style={{"minHeight": "440px"}}>
                                            {/* Birthday fields */}
                                            <h3>Address</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4">
                                                    <div className="col-lg-7 col-md-7">
                                                    <div className="account-edit-box">
                                                            <div className="edit-caption">Address (Optional):</div>
                                                            <div className="edit-field">
                                                                <textarea type="text" name="add"
                                                                    defaultValue={accountInfo.address}
                                                                    placeholder="" autoComplete="off"
                                                                    className={updateData.address.add.add ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                >
                                                                </textarea>
                                                                <small className="error-input">{updateData.address.add.add}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">State:</div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select
                                                                        name="state"
                                                                        className={updateData.address.state.state ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                        defaultValue={accountInfo.stateId}
                                                                    >
                                                                        <option value="">state</option>
                                                                        {states.map((st, index_index) =>
                                                                            <option key={st.id + '-state'} value={st.id}>{st.name}</option>
                                                                        )}

                                                                    </select>
                                                                    <small className="error-input">{updateData.address.state.state}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">City:</div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select
                                                                        name="city"
                                                                        className={updateData.address.city.city ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                        defaultValue={accountInfo.cityId}
                                                                    >
                                                                        <option value="">city</option>
                                                                        {  filterCities.citynames && filterCities.citynames.length && filterCities.citylist ?
                                                                                Object.keys(filterCities.citylist).map(key => 
                                                                                    <option key={filterCities.citylist[key][0].id+'-city'} data-zipcode={JSON.stringify(filterCities.citylist[key])} value={filterCities.citylist[key][0].id}>{key}</option>
                                                                                )
                                                                            :
                                                                            ""
                                                                        }

                                                                    </select>
                                                                    <small className="error-input">{updateData.address.city.city}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Zipcode:</div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select
                                                                        name="zipcode"
                                                                        className={updateData.address.zipcode.zipcode ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                        defaultValue={accountInfo.zipcode}
                                                                    >
                                                                        <option value="">zipcode</option>
                                                                        {
                                                                            zipCodes.map((zip,key)=>{
                                                                                return(
                                                                                <option key={key} value={zip.zip_code} data-city={zip.id}>{zip.zip_code}</option>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                    <small className="error-input">{updateData.address.zipcode.zipcode}</small>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                            <div className="col-md-7">

                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Address:</div>
                                                                <div className="edit-field">{accountInfo.address}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">State:</div>
                                                                <div className="edit-field">{accountInfo.state_name}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">City:</div>
                                                                <div className="edit-field">{accountInfo.city_name}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Zipcode:</div>
                                                                <div className="edit-field">{accountInfo.zipcode}</div>
                                                            </div>

                                                        </div>
                                                    </Row>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'profile-gender' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box pb-3 pb-md-4">
                                            {/* Gender fields */}
                                            <h3>Sex</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="gender-edit-box">
                                                            <div className="cus-radio">
                                                                <input type="radio" id="female" name="sex" value="female"
                                                                    defaultChecked={accountInfo.gender == 'female' ? true : false}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <label htmlFor="female">Female</label>
                                                            </div>
                                                            <div className="cus-radio">
                                                                <input type="radio" id="male" name="sex" value="male"
                                                                    defaultChecked={accountInfo.gender == 'male' ? true : false}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <label htmlFor="male">Male</label>
                                                            </div>
                                                            <small className="error-input">{updateData.gender.sex.sex}</small>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">
                                                            <div className="account-edit-box pb-4">
                                                                <div className="edit-field" style={{ 'textTransform': 'capitalize' }}>{accountInfo.gender}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'profile-phone' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner phoneno-innerbox">
                                            {/* phone fields */}
                                            <h3>Phone Number</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">{accountInfo.roleId == 3 ? 'Office' : 'Home'} :</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="homeNumber"

                                                                    value={updateData.phone.homeNumber.value}
                                                                    placeholder="" autoComplete="off"
                                                                    className={updateData.phone.homeNumber.homeNumber ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.phone.homeNumber.homeNumber}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Cell :</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="phoneNumber"

                                                                    value={updateData.phone.phoneNumber.value}
                                                                    placeholder="" autoComplete="off"
                                                                    className={updateData.phone.phoneNumber.phoneNumber ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.phone.phoneNumber.phoneNumber}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Preferred Number:</div>
                                                            <div className="edit-field capitalize">
                                                                <div>
                                                                    <div className="myform">
                                                                        <select
                                                                            name="phoneType"
                                                                            className={updateData.phone.phoneType.phoneType ? "select  error-border" : "select"}
                                                                            onChange={(e) => modifyData(e)}
                                                                            value={updateData.phone.phoneType.value}
                                                                        >
                                                                            <option value="cell">Cell</option>
                                                                            <option value="home">{accountInfo.roleId == 3 ? 'Office' : 'Home'}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">

                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">{accountInfo.roleId == 3 ? 'Office' : 'Home'} :</div>
                                                                <div className="edit-field">{accountInfo.homeNumber ? accountInfo.homeNumber : '(###) ###-####'}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Cell :</div>
                                                                <div className="edit-field">{accountInfo.phoneNumber ? accountInfo.phoneNumber : '(###) ###-####'}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Preferred Number:</div>
                                                                <div className="edit-field capitalize">{checkPhoneType(accountInfo.phoneType)}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'preferred-pronoun' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner sex-pronoun-innerbox">
                                            {/* phone fields */}
                                            <h3>Sex and Preferred Pronoun</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Sex: </div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select
                                                                        name="gender"
                                                                        defaultValue={accountInfo.gender}
                                                                        autoComplete="off"
                                                                        className={updateData.pronoun.gender.gender ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                    >
                                                                        <option value="male">Male</option>
                                                                        <option value="female">Female</option>

                                                                    </select>
                                                                    <small className="error-input">{updateData.pronoun.gender.gender}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Preferred Pronoun: </div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select
                                                                        name="preferredPronoun"
                                                                        defaultValue={accountInfo.preferredPronoun}
                                                                        autoComplete="off"
                                                                        className={updateData.pronoun.preferredPronoun.preferredPronoun ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                    >
                                                                        <option value="he/him/his">He/Him/His</option>
                                                                        <option value="she/her/hers">She/Her/Hers</option>
                                                                        <option value="they/them/theirs">They/Them/Theirs</option>

                                                                    </select>
                                                                    <small className="error-input">{updateData.pronoun.preferredPronoun.preferredPronoun}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">

                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Sex: </div>
                                                                <div className="edit-field" style={{ textTransform: 'capitalize' }}>{accountInfo.gender ? accountInfo.gender : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Preferred Pronoun: </div>
                                                                <div className="edit-field" style={{ textTransform: 'capitalize' }}>{accountInfo.preferredPronoun ? accountInfo.preferredPronoun : ''}</div>
                                                            </div>

                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'profile-email' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box">
                                            {/* Email fields */}
                                            <h3>Email Address</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4">
                                                    <div className="col-lg-5 col-md-4 text-md-right pt-lg-1">
                                                        <div className="edit-btn-profile">

                                                        </div>
                                                    </div>
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div className="col-md-7">
                                                            <div className="account-edit-box pb-5">
                                                                <div className="edit-field">{accountInfo.email}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'manage-subscription' ? (
                                <>
                                    {/* Plan tabs */}
                                    <div className="col-12 profile-plans">
                                        <div className="provider-step4-wrap">
                                            <ProviderStep4 />
                                        </div>
                                    </div>
                                    {/* Card detail and add card */}

                                </>
                            ) : elementName == 'upgrade-subscription' ? (
                                <>

                                    {/* Card detail and add card */}
                                    <div className="col-12">
                                        <div className="profile-billing">
                                            {/* <h3>Confirm Billing Cycle</h3>
                                            <div className="smalltxt">Select Annual billing cycle to Save 25% and expense with single receipt.</div> */}
                                            <PaymentPlans {...props} />
                                            {/* <EditCard {...props}/>                                                            */}
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'card-list' ? (
                                <>
                                    {/* Manage cards */}
                                    <div className="col-12">
                                        <div className="profile-billing manage-cards">
                                            <h2>Manage Your Credit Card</h2>
                                            <div className="smalltxt">Add new or modify saved credit card(s).</div>
                                            <EditCard />
                                        </div>
                                    </div>

                                </>
                            ) : elementName == 'manage-card' ? (
                                <>
                                    {/* Card details */}
                                    <div className="col-12">
                                        <div className="profile-billing manage-cards">
                                            <h2>Manage Your Credit Card</h2>
                                            <div className="smalltxt">Add new or modify saved credit card(s).</div>
                                            <PaymentDetails {...props} />
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'change-password' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box">
                                            {/* change password */}
                                            <h3>Change Password</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Current Password:</div>
                                                            <div className="edit-field">
                                                                <input type="password" name="oldPassword"
                                                                    placeholder="" autoComplete="off"
                                                                    className={updateData.password.oldPassword.oldPassword ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.password.oldPassword.oldPassword}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">New Password:</div>
                                                            <div className="edit-field">
                                                                <input type="password" name="newPassword"
                                                                    placeholder="" autoComplete="off"
                                                                    className={updateData.password.newPassword.newPassword ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.password.newPassword.newPassword}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Confirm New Password:</div>
                                                            <div className="edit-field">
                                                                <input type="password" name="confirmPassword"
                                                                    placeholder autoComplete="off"
                                                                    className={updateData.password.confirmPassword.confirmPassword ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.password.confirmPassword.confirmPassword}</small>
                                                            </div>
                                                        </div>
                                                        <div className="pass-rules">
                                                            <div className="head-rules">Password Rules:</div>
                                                            <ul>
                                                                <li>Has at least 8 characters</li>
                                                                <li>Must contain letters, at least 1 number, and at least 1 special character</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                    {/* <div className="col-12">
                                            
                                            </div> */}
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">

                                                            <div className="account-edit-box mb-4 mb-md-5">
                                                                <div className="edit-field">**********************</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'step-verification' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner two-step-innerbox">
                                            {/* change password */}
                                            <h3>2-Step Verification
                                        <span className="info-icon-step">
                                                    <img src="/assets/images/info-icon-grey.png" alt="Info" />
                                                    <div className="info-text">When 2-step verification is turned on, each time you sign in to your DocScheduler Account, you'll need your password and a verification code that will be texted to your phone or emailed to you.</div>
                                                </span>
                                            </h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-field switch-btn">
                                                                <label className="switch">
                                                                    <input defaultChecked={accountInfo.isVerifyAuth ? true : false} type="checkbox" id="switch-btn3" name="isVerifyAuth" value="1" onChange={(e) => modifyData(e)} /><div className="slider round"></div></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative pb-4 pb-md-5">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">
                                                            <div className="account-edit-box">
                                                                <div className="edit-field">{accountInfo.isVerifyAuth ? 'On' : 'Off'}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'recovery-phoneno' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner cellphone-innerbox">
                                            {/* change password */}
                                            <h3>Cell Phone #</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-field">
                                                                <input type="text" name="recoveryPhone"
                                                                    value={updateData.phoneno.recoveryPhone.value}
                                                                    placeholder="" autoComplete="off"
                                                                    className={updateData.phoneno.recoveryPhone.recoveryPhone ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.phoneno.recoveryPhone.recoveryPhone}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative pb-4 pb-md-5">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">
                                                            <div className="account-edit-box">
                                                                <div className="edit-field">{accountInfo.recoveryPhone ? accountInfo.recoveryPhone : '(###) ###-####'}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'recovery-email' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner recovery-mail-innerbox">
                                            {/* change password */}
                                            <h3>Recovery Email</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-edit-box">

                                                            <div className="edit-field edit-field-email">
                                                                <input type="text" name="recoveryEmail"
                                                                    placeholder=''
                                                                    defaultValue={accountInfo.recoveryEmail}
                                                                    autoComplete="off"
                                                                    className={updateData.email.recoveryEmail.recoveryEmail ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.email.recoveryEmail.recoveryEmail}</small>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative pb-4 pb-md-5">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">
                                                            <div className="account-edit-box">
                                                                <div className="edit-field">{accountInfo.recoveryEmail ? accountInfo.recoveryEmail : '  Emailname@domainname.com'}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'medical-npi' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box">
                                            {/* change password */}
                                            <h3>National Provider Identifier #</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-edit-box">

                                                            <div className="edit-field">
                                                                <input type="text" name="nationalProviderIdentifierNumber"
                                                                    // defaultValue={accountInfo.nationalProviderIdentifierNumber}
                                                                    value={updateData.npi.nationalProviderIdentifierNumber.value}
                                                                    autoComplete="off"
                                                                    // onKeyPress={(e)=>{return false}}
                                                                    className={updateData.npi.nationalProviderIdentifierNumber.nationalProviderIdentifierNumber ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.npi.nationalProviderIdentifierNumber.nationalProviderIdentifierNumber}</small>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative pb-4 pb-md-5">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">
                                                            <div className="account-edit-box">
                                                                <div className="edit-field">{accountInfo.nationalProviderIdentifierNumber}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'medical-license' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box">
                                            {/* change password */}
                                            <h3>Medical License #</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="account-edit-box">

                                                            <div className="edit-field">
                                                                <input type="text" name="medicalLicenseNumber"
                                                                    name='medicalLicenseNumber'
                                                                    placeholder=''
                                                                    defaultValue={accountInfo.medicalLicenseNumber}
                                                                    autoComplete="off"
                                                                    className={updateData.license.medicalLicenseNumber.medicalLicenseNumber ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.license.medicalLicenseNumber.medicalLicenseNumber}</small>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative pb-4 pb-md-5">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">
                                                            <div className="account-edit-box">
                                                                <div className="edit-field">{accountInfo.medicalLicenseNumber ? accountInfo.medicalLicenseNumber : '  Number and letters allowed'}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'delete-profile' ? (
                                <>
                                    <div className="col-12 mb-1 mb-md-3 ipad-pro">
                                        <div className="my-acc-info-box">
                                            {/* change password */}
                                            <h3 className="delete-prof-head">Delete Your Profile</h3>
                                            <Row className="no-gutters position-relative pb-4 pb-md-5">
                                                <div className="col-12">
                                                    <div className="delete-profile-txt">
                                                        Thank you for using DocScheduler. We respect your right to privacy and control over your personal data. You can use this page to delete your profile subject to the terms of our Privacy Policy. This action is not reversible. If you delete your profile, you will be unable to book new appointments, view your appointment history, or track your health. Do you want to proceed?
                                            </div>
                                                    <div className="delete-profile-btn">
                                                        <input type="button" className="rev-btn-pro" onClick={() => confirmDeletePatient()} value="Delete Profile" />
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'insurance-medical' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner primary-ins-innerbox">
                                            {/* Name fields */}
                                            <h3>Primary Medical Insurance Information  </h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-8 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Insurance Name:</div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select type="text" name="insuranceId"
                                                                        defaultValue={insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].insuranceId : ''} autoComplete="off"
                                                                        className={updateData.medical.insuranceId.insuranceId ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                    >

                                                                        <option value=''></option>
                                                                        {insuranceList.map((mc, mci) =>
                                                                            <>
                                                                                {
                                                                                    mc.category == "medical" ? (<>
                                                                                        <option key={'medical-' + mc.id} value={mc.id}>{mc.name}</option>
                                                                                    </>) : (<></>)
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                    <small className="error-input">{updateData.medical.insuranceId.insuranceId}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Plan Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="planName"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].planName : ''} autoComplete="off"
                                                                    className={updateData.medical.planName.planName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.medical.planName.planName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Group #:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="group"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].group : ''} autoComplete="off"
                                                                    className={updateData.medical.group.group ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.medical.group.group}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption"> Membership ID#:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="membershipId"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].membershipId : ''} autoComplete="off"
                                                                    className={updateData.medical.membershipId.membershipId ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.medical.membershipId.membershipId}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">

                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Insurance Name:</div>
                                                                <div className="edit-field">{insuranceInfo.medical && insuranceInfo.medical.length ? getInsuranceName(insuranceInfo.medical[0].insuranceId) : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Plan Name:</div>
                                                                <div className="edit-field">{insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].planName : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Group #:</div>
                                                                <div className="edit-field">{insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].group : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption"> Membership ID#:</div>
                                                                <div className="edit-field">{insuranceInfo.medical && insuranceInfo.medical.length ? insuranceInfo.medical[0].membershipId : ''}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'insurance-dental' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner dental-ins-innerbox">
                                            {/* Name fields */}
                                            <h3>Dental Insurance Information  </h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-8 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Insurance Name:</div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select type="text" name="insuranceId"
                                                                        defaultValue={insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].insuranceId : ''} autoComplete="off"
                                                                        className={updateData.dental.insuranceId.insuranceId ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                    >
                                                                        <option value=''></option>
                                                                        {insuranceList.map((mc, mci) =>
                                                                            <>
                                                                                {
                                                                                    mc.category == "dental" ? (<>
                                                                                        <option key={'dental-' + mc.id} value={mc.id}>{mc.name}</option>
                                                                                    </>) : (<></>)
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                    <small className="error-input">{updateData.dental.insuranceId.insuranceId}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Plan Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="planName"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].planName : ''} autoComplete="off"
                                                                    className={updateData.dental.planName.planName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.dental.planName.planName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Group #:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="group"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].group : ''} autoComplete="off"
                                                                    className={updateData.dental.group.group ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.dental.group.group}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption"> Membership ID#:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="membershipId"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].membershipId : ''} autoComplete="off"
                                                                    className={updateData.dental.membershipId.membershipId ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.dental.membershipId.membershipId}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">

                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Insurance Name:</div>
                                                                <div className="edit-field">
                                                                    {

                                                                        insuranceInfo.dental && insuranceInfo.dental.length ? getInsuranceName(insuranceInfo.dental[0].insuranceId) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Plan Name:</div>
                                                                <div className="edit-field">{insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].planName : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Group #:</div>
                                                                <div className="edit-field">{insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].group : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption"> Membership ID#:</div>
                                                                <div className="edit-field">{insuranceInfo.dental && insuranceInfo.dental.length ? insuranceInfo.dental[0].membershipId : ''}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}

                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'insurance-vision' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner vision-ins-innerbox">
                                            {/* Name fields */}
                                            <h3>Vision Insurance Information  </h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-8 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Insurance Name:</div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select type="text" name="insuranceId"
                                                                        defaultValue={insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].insuranceId : ''} autoComplete="off"
                                                                        className={updateData.vision.insuranceId.insuranceId ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                    >
                                                                        <option value=''></option>
                                                                        {insuranceList.map((mc, mci) =>
                                                                            <>
                                                                                {
                                                                                    <option key={'vision-' + mc.id} value={mc.id}>{mc.name}</option>
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                    <small className="error-input">{updateData.vision.insuranceId.insuranceId}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Plan Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="planName"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].planName : ''} autoComplete="off"
                                                                    className={updateData.vision.planName.planName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.vision.planName.planName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Group #:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="group"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].group : ''} autoComplete="off"
                                                                    className={updateData.vision.group.group ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.vision.group.group}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption"> Membership ID#:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="membershipId"
                                                                    placeholder=" "
                                                                    defaultValue={insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].membershipId : ''} autoComplete="off"
                                                                    className={updateData.vision.membershipId.membershipId ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.vision.membershipId.membershipId}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">

                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Insurance Name:</div>
                                                                <div className="edit-field">{insuranceInfo.vision && insuranceInfo.vision.length ? getInsuranceName(insuranceInfo.vision[0].insuranceId) : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Plan Name:</div>
                                                                <div className="edit-field">{insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].planName : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Group #:</div>
                                                                <div className="edit-field">{insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].group : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption"> Membership ID#:</div>
                                                                <div className="edit-field">{insuranceInfo.vision && insuranceInfo.vision.length ? insuranceInfo.vision[0].membershipId : ''}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : elementName == 'insurance-child' ? (
                                <>
                                    <div className="col-12 ipad-pro">
                                        <div className="my-acc-info-box myacc-infobox-inner childs-ins-innerbox">
                                            {/* Name fields */}
                                            <h3>Child's Medical Insurance Information</h3>
                                            {editable ? (
                                                <Row className="no-gutters justify-content-between pb-4 pb-md-5">
                                                    <div className="col-lg-8 col-md-7">
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">First Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="firstName"
                                                                    placeholder=""
                                                                    defaultValue={insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].firstName : ''} autoComplete="off"
                                                                    className={updateData.child.firstName.firstName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.child.firstName.firstName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Middle Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="middleName"
                                                                    placeholder=""
                                                                    defaultValue={insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].middleName : ''} autoComplete="off"
                                                                    className={updateData.child.middleName.middleName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.child.middleName.middleName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Last Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="lastName"
                                                                    placeholder=""
                                                                    defaultValue={insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].lastName : ''} autoComplete="off"
                                                                    className={updateData.child.lastName.lastName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.child.lastName.lastName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Child Birthday:</div>
                                                            <div className="account-birth-fields edit-field">
                                                                <div>
                                                                    <div className="myform">
                                                                        <select name="month"
                                                                            defaultValue={insuranceInfo.child && insuranceInfo.child.length ? convertDob(insuranceInfo.child[0].dob).month : ''} autoComplete="off"
                                                                            className={updateData.child.month.month ? "select  error-border" : "select"}
                                                                            onChange={(e) => modifyData(e)}
                                                                        >
                                                                            <option value=''>Month</option>
                                                                            {monthList.map((month, index_index) =>
                                                                                <option key={month.id + '-month'} value={month.id}>{month.name}</option>
                                                                            )}
                                                                        </select>
                                                                        <small className="error-input">{updateData.child.month.month}</small>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="myform">
                                                                        <select name="day"
                                                                            defaultValue={insuranceInfo.child && insuranceInfo.child.length ? convertDob(insuranceInfo.child[0].dob).day : ''} autoComplete="off"
                                                                            className={updateData.child.day.day ? "select  error-border" : "select"}
                                                                            onChange={(e) => modifyData(e)}
                                                                        >
                                                                            <option value="">Day</option>
                                                                            {[...new Array(31)].map((day, day_index) =>
                                                                                <option key={1 + day_index + '-days'} value={day_index + 1}>{day_index + 1}</option>
                                                                            )}
                                                                        </select>
                                                                        <small className="error-input">{updateData.child.day.day}</small>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="myform">
                                                                        <select name="year"
                                                                            defaultValue={insuranceInfo.child && insuranceInfo.child.length ? convertDob(insuranceInfo.child[0].dob).year : ''} autoComplete="off"
                                                                            className={updateData.child.year.year ? "select  error-border" : "select"}
                                                                            onChange={(e) => modifyData(e)}
                                                                        >
                                                                            <option value=''>Year</option>
                                                                            {yearListChild.map((year) =>
                                                                                <option key={year + '-year'} value={year}>{year}</option>
                                                                            )}
                                                                        </select>
                                                                        <small className="error-input">{updateData.child.year.year}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Insurance Name:</div>
                                                            <div className="edit-field">
                                                                <div className="myform">
                                                                    <select type="text" name="insuranceId"
                                                                        defaultValue={insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].insuranceId : ''} autoComplete="off"
                                                                        className={updateData.child.insuranceId.insuranceId ? "select  error-border" : "select"}
                                                                        onChange={(e) => modifyData(e)}
                                                                    >
                                                                        <option value=''></option>
                                                                        {insuranceList.map((mc, mci) =>
                                                                            <>
                                                                                {
                                                                                    <option key={'child-' + mc.id} value={mc.id}>{mc.name}</option>
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                    <small className="error-input">{updateData.child.insuranceId.insuranceId}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Plan Name:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="planName"
                                                                    placeholder=""
                                                                    defaultValue={insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].planName : ''} autoComplete="off"
                                                                    className={updateData.child.planName.planName ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.child.planName.planName}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption">Group #:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="group"
                                                                    placeholder=""
                                                                    defaultValue={insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].group : ''} autoComplete="off"
                                                                    className={updateData.child.group.group ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.child.group.group}</small>
                                                            </div>
                                                        </div>
                                                        <div className="account-edit-box">
                                                            <div className="edit-caption"> Membership ID#:</div>
                                                            <div className="edit-field">
                                                                <input type="text" name="membershipId"
                                                                    placeholder=""
                                                                    defaultValue={insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].membershipId : ''} autoComplete="off"
                                                                    className={updateData.child.membershipId.membershipId ? "input-pro  error-border" : "input-pro"}
                                                                    onChange={(e) => modifyData(e)}
                                                                />
                                                                <small className="error-input">{updateData.child.membershipId.membershipId}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        SubmitButton()
                                                    }
                                                </Row>
                                            ) : (
                                                    <Row className="no-gutters position-relative">
                                                        <div onClick={(e) => { changeFields(e) }} className="edit-icon-position-top"></div>
                                                        <div className="col-md-7">
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">First Name:</div>
                                                                <div className="edit-field">{insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].firstName : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Middle Name:</div>
                                                                <div className="edit-field">{insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].middleName : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Last Name:</div>
                                                                <div className="edit-field">{insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].lastName : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Child Birthday:</div>
                                                                <div className="edit-field">{insuranceInfo.child && insuranceInfo.child.length ? (convertDob(insuranceInfo.child[0].dob, true)) : (<></>)}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Insurance Name:</div>
                                                                <div className="edit-field">{insuranceInfo.child && insuranceInfo.child.length ? getInsuranceName(insuranceInfo.child[0].insuranceId) : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Plan Name:</div>
                                                                <div className="edit-field">{insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].planName : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption">Group #:</div>
                                                                <div className="edit-field">{insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].group : ''}</div>
                                                            </div>
                                                            <div className="account-edit-box">
                                                                <div className="edit-caption"> Membership ID#:</div>
                                                                <div className="edit-field">{insuranceInfo.child && insuranceInfo.child.length ? insuranceInfo.child[0].membershipId : ''}</div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )}


                                        </div>
                                    </div>
                                </>
                            ) : (<Error404 />)
                        }
                    </Row>
                </Container>
            </Container>
        </Suspense>

    );
}

export default EditName;