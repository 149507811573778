import React, { Fragment, memo, lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container } from "react-bootstrap";
import HeaderMain from '../../global/header/header-main';
import Footer from "../../global/footer/footer";
import SearchResult from '../search-results/search-results';

const DoctorSearchResult = (props) => {

  return (
    <Fragment>
      <Container fluid className="header px-0">
        <HeaderMain {...props}/>
      </Container>
      <SearchResult {...props} userCateType={'patient'} />
      <Footer />
    </Fragment>
  );
};

export default memo(DoctorSearchResult);