import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import PolicyOne from '../../components/privacy-terms/policy1';
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';

const PrivacyPolicy = (props) => {

  return (
    <Fragment>
        <HeaderMain {...props}/>
        <PolicyOne/>
        <Footer/>

    </Fragment>
    );
  }
  
  export default PrivacyPolicy;