import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
  memo,
} from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container, InputGroup, Row } from "react-bootstrap";
import NetworkLayer from "../../reactLayer/Network/Network";
import AuthContext from "../../reactLayer/auth/AuthContext";
import { PatientLinks, ProviderLinks } from "../../linkFile";
import { useHistory } from "react-router-dom";
import Global from "../../reactLayer/global";
import useLocationMod from "../../hooks/useLocationMod";

const SearchBy = (props) => {
  const { pageHeading } = props;
  const history = useHistory();
  const locationMod = useLocationMod();
  const [allCityArea, setallCityArea] = useState([]);
  const [mainAllCityArea, setmainAllCityArea] = useState([]);
  const [seletedCityArea, setSeletedCityArea] = useState([]);
  const [specialtyList, setSpecialtyList] = useState([]);
  const [selectedSpecialtyList, setSelectedSpecialtyList] = useState('');
  const authContext = useContext(AuthContext);

  const getAllCity = useCallback(async () => {
    authContext.setLoader();
    const submitData = {
      headers: {
        "Content-Type": "application/json",
      },
      url: "cities/get",
    };
    let result = await NetworkLayer.getRequest(submitData);
    if (result.status) {
      if (result.result.length > 0) {
        setmainAllCityArea(result.result);
        let cityListNode = result.result.map(({ id, name }) => ({
          id: id,
          cityName: name,
        }));
        if (cityListNode.length > 0) {
          const n = 50;
          const resultSet = new Array(Math.ceil(cityListNode.length / n))
            .fill()
            .map((_) => cityListNode.splice(0, n));
          setallCityArea(resultSet);
        }
      }
    } else {
      setallCityArea([]);
    }
    authContext.unsetLoader();
  }, []);

  const midwest = ['Chicago','Detroit','Minneapolis','St. Louis','Cincinnati','Kansas City','Columbus','Indianapolis','Cleveland','Milwaukee'];
  const west = ['Los Angeles','San Francisco','Riverside','Seattle','San Diego','Denver','San Josh','Salt Lake City','Tucson','Fresno'];
  const northeast = ['New York','Washington DC','Philadelphia','Boston','Baltimore','Pittsburgh','Providence','Hartford','Buffalo','Rochester'];
  const south = ['Dallas','Houston','Miami','Atlanta','Tampa','Charlotte','Orlando','Austin','Nashville','Virginia Beach'];

  const onSumbitSearch = useCallback(
    (e) => {
      e.preventDefault();
      const path =
        authContext.user &&
        authContext?.user?.isProfileCompleted &&
        authContext.user.accountInfo.roleId === 3
          ? ProviderLinks.search_result
          : PatientLinks.doctor_search_result;
      const selectedCityNane = e?.target?.outerText;
      history.push({
        pathname: path,
        state: {
          speciality:
            selectedSpecialtyList.length > 0 ? selectedSpecialtyList : [],
          zipCity: selectedCityNane ? selectedCityNane : "",
        },
      });
      locationMod.location.state = {
        speciality:
          selectedSpecialtyList.length > 0 ? selectedSpecialtyList : [],
        zipCity: selectedCityNane ? selectedCityNane : "",
      };
    },
    [
      history,
      selectedSpecialtyList,
      seletedCityArea,
      mainAllCityArea,
      locationMod,
    ]
  );

  const addOptionChangeData = (event) => {
    let value = event?.target?.value;
    setSelectedSpecialtyList(value);
  };

  const handleCityListOption = useCallback(
    (id) => {
      let data = [];
      let arrayIndex = allCityArea.findIndex((node) => node.id === id);
      if (arrayIndex > -1) {
        data.splice(arrayIndex, 1);
      } else {
        data.push(id);
      }
      setSeletedCityArea(data);
    },
    [allCityArea]
  );

  const getMedicalSpeciality = useCallback(async () => {
    let result = await Global.getMedicalSpeciality();
    let formatResult = result.map(({ id, name }) => {
      return {
        id,
        name,
      };
    });
    if (formatResult.length > 0) {
      setSpecialtyList(formatResult);
    }
  }, []);

  useEffect(() => {
    // getAllCity();
    getMedicalSpeciality();
  }, [getMedicalSpeciality]);

  return (
    <Fragment>
      <Container className="free-profile searchby-container">
        <Container>
          <Row>
            <div className="col-12">
              <h2>{pageHeading?.title}</h2>
            </div>
            <div className="col-12">
            <div className="searchBy provider-steps-wrap">
              <Row>
                <div className="col-12">
                  <div className="sub-head-pro-step2 head-title mb-2">
                    Doctor Specialty
                  </div>
                  <div className="home-top-fields searchFilter">
                    <div className="myform col-md-8 col-lg-5 no-padding">
                      <select
                        className="select"
                        data-index="0"
                        data-name="medicalSpecialty"
                        onChange={addOptionChangeData}
                      >
                        <option value="">Choose</option>
                        {specialtyList.map((d, ind) => (
                          <option value={d.id} key={ind}>
                            {d.name}
                          </option>
                        ))}
                      </select>
                      <small className="error-input"></small>
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="mb-3 mb-md-4 mt-3 mt-md-0">
                  <div className="search-city col-md-6 col-lg-3">
                    <div className="sub-head-pro-step2 head-title mb-3">
                      Midwest
                    </div>
                    <ul className="search-by-city noMarginCity">
                      {
                        midwest.map((name) => 
                          <li onClick={onSumbitSearch} key={name}>{name}</li>
                        )
                      }
                      
                      {/* <li>Detroit</li>
                      <li>Minneapolis</li>
                      <li>St. Louis</li>
                      <li>Cincinnati</li>
                      <li>Kansas City</li>
                      <li>Columbus</li>
                      <li>Indianapolis</li>
                      <li>Cleveland</li>
                      <li>Milwaukee</li> */}
                    </ul>
                  </div>
                  <div className="search-city col-md-6 col-lg-3">
                    <div className="sub-head-pro-step2 head-title mb-3">
                      West
                    </div>
                    <ul className="search-by-city noMarginCity">
                      {
                        west.map((name) => 
                          <li onClick={onSumbitSearch} key={name}>{name}</li>
                        )
                      }
                      {/* <li>Los Angeles</li>
                      <li>San Francisco</li>
                      <li>Riverside</li>
                      <li>Seattle</li>
                      <li>San Diego</li>
                      <li>Denver</li>
                      <li>San Josh</li>
                      <li>Salt Lake City</li>
                      <li>Tucson</li>
                      <li>Fresno</li> */}
                    </ul>
                  </div>
                  <div className="search-city col-md-6 col-lg-3">
                    <div className="sub-head-pro-step2 head-title mb-3">
                      Northeast
                    </div>
                    <ul className="search-by-city noMarginCity">
                      {
                        northeast.map((name) => 
                          <li onClick={onSumbitSearch} key={name}>{name}</li>
                        )
                      }
                      {/* <li>New York/Jersey City</li>
                      <li>Washington DC/Arlington</li>
                      <li>Philadelphia</li>
                      <li>Boston</li>
                      <li>Baltimore</li>
                      <li>Pittsburgh</li>
                      <li>Providence</li>
                      <li>Hartford</li>
                      <li>Buffalo</li>
                      <li>Rochester</li> */}
                    </ul>
                  </div>
                  <div className="search-city col-md-6 col-lg-3 last">
                    <div className="sub-head-pro-step2 head-title mb-3">
                      South
                    </div>
                    <ul className="search-by-city noMarginCity">
                      {
                        south.map((name) => 
                          <li onClick={onSumbitSearch} key={name}>{name}</li>
                        )
                      }
                      {/* <li>Dallas/Fort Worth</li>
                      <li>Houston</li>
                      <li>Miami</li>
                      <li>Atlanta</li>
                      <li>Tampa</li>
                      <li>Charlotte</li>
                      <li>Orlando</li>
                      <li>Austin</li>
                      <li>Nashville</li>
                      <li>Virginia Beach</li> */}
                    </ul>
                  </div>
              </Row>
              {/* <Row className="mb-3 mb-md-4 provider-steps-wrap">
                {allCityArea.map((city, ind) => (
                  <div
                    className={
                      allCityArea.length - 1 === ind
                        ? "search-city last col-md-6 col-lg-3"
                        : "search-city col-md-6 col-lg-3"
                    }
                    key={uuidv4()}
                  >
                    <div className="sub-head-pro-step2 head-title mb-3">
                      {city.zonename}
                    </div>
                    <ul className="search-by-city noMarginCity">
                      {city.map((d) => (
                        <li key={uuidv4()} onClick={onSumbitSearch}>
                          {d?.cityName}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Row> */}
            </div>
            </div>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
};
SearchBy.defaultProps = {
  pageHeading: null,
};

export default memo(SearchBy);
