import React, { Fragment } from 'react';
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Tabs  } from 'react-bootstrap';
// Component Improt From Here 
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';
import Twostepauth from '../../components/provider-login/twostep';

const Twofactauth = (props) => {
 

  return (
    <Fragment>
     
        <HeaderMain/>
        <Twostepauth ltype={'provider'}/>
        <Footer/>
     
      
    </Fragment>
    );
  }
  
export default Twofactauth
