import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import InputValidation from '../../reactLayer/validation';
import AuthContext from '../../reactLayer/auth/AuthContext';
import NetworkLayer from '../../reactLayer/Network/Network';


const DoctorReviewModal = (props) => {
    // debugger; 
    const authContext = useContext(AuthContext);
    const [modalProps, setmodalProps] = useState({
        type:'', 
        msg:'',
        btnname: '',
        redirection: ''
    });  
    const [ userRating, setuserRating ] = useState({
        rating: {  
                    value: '',
                    tempvalue: '',
                    validation: ['blank'],
                    errorMsg: ['Please rate'],
                    errorKey: 'rating',
                    rating: false
                },
        review: {  
                    value: '',
                    validation: [''],
                    errorMsg: ['Please rate'],
                    errorKey: 'review',
                    remaining: 300,
                    review: false
                },
    })
    const setrating = (r) => {
         let urating = userRating; 
         urating['rating']['value'] = r;
         urating['rating']['tempvalue'] = 0;
         urating['rating']['rating'] = false;
         setuserRating({...urating});
    }
    const settemprating = (r) => {
         let urating = userRating; 
         urating['rating']['tempvalue'] = r;
         urating['rating']['rating'] = false;
         setuserRating({...urating});
    }
    const modifyReview = (e) => {
        let value = e.currentTarget.value;
        let name = e.currentTarget.getAttribute('name');
        let urating = userRating; 
        console.log('urating fafsff', urating[name], value);
        if( name === 'review') {
            if(value.length <= 300 ) {
                urating[name]['value'] = value;
                urating[name][name] = false;
                urating[name]['remaining'] = 300 - parseInt(value.length);
                setuserRating({...urating});
            }
        }
    }
    const giveReview = async(e) => {
        let flag = true,
          currentValue = userRating;
        let validationResult = InputValidation(currentValue);
        
        if (Object.keys(validationResult).length > 0) {
            flag = false
            for (const prop in validationResult) {
                currentValue[prop][prop] = `${validationResult[prop]}`;
            }
            setuserRating({...currentValue});
        } 
        if( flag ) {
            authContext.setLoader();
            const fData = {
                "message": userRating.review.value,
                "rating": userRating.rating.value,
                "toUser": props.accountInfo.id,
                
            }
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'rating/add',
                body: JSON.stringify(fData),
                
            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if( result.body.status ) {
                let user = result.body.result;
                setmodalProps({
                    type:'success', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                setTimeout(() => {
                    authContext.hideModal();
                    props.getProfile && props.getProfile();
                }, 3000);
            } else {
                setmodalProps({
                    type:'error', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }
    }
    return (
        <Fragment>
            <Container>
                <div className="edit-all-filter">
                    <div className="allfilter-innerbox">
                        <Row>
                            {
                                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                            }
                            <div className="col-12 mb-3 mb-md-4">
                                <div className="doctor-review-modal-wrap">
                                    <div className="docreview-inner">
                                        <div className="docreview-toptext">
                                            Please rate your experience with
                                            <span className="redtext">
                                            {props.accountInfo && props.accountInfo.firstName ? props.accountInfo.firstName + ' ' : ''} 
                                            {props.accountInfo && props.accountInfo.middleName ? props.accountInfo.middleName + ' ' : ''} 
                                            {props.accountInfo && props.accountInfo.lastName ? props.accountInfo.lastName : ''}, 
                                            {props.accountInfo && props.medicalSpeciality && props.medicalSpeciality.providerMedicalSpecialitiesValue ? props.medicalSpeciality.providerMedicalSpecialitiesValue + ' ' : ''} </span>
                                        </div>
                                        <div className="doctor-reviewimg">

                                            <img src={props.accountInfo && props.accountInfo.profileImage} alt="Doctor" />
                                        </div>
                                        <div className="docreview-botext">
                                            My Review for <span className="redtext">
                                            {props.accountInfo && props.accountInfo.firstName ? props.accountInfo.firstName + ' ' : ''} 
                                            {props.accountInfo && props.accountInfo.middleName ? props.accountInfo.middleName + ' ' : ''} 
                                            {props.accountInfo && props.accountInfo.lastName ? props.accountInfo.lastName : ''}, 
                                            {props.accountInfo && props.medicalSpeciality && props.medicalSpeciality.providerMedicalSpecialitiesValue ? props.medicalSpeciality.providerMedicalSpecialitiesValue + ' ' : ''} </span>
                                        </div>
                                        <div className="rating-doctor-review">
                                            <div className="rating">
                                                <div className="stars-outer"> 
                                                    {
                                                        [1,2,3,4,5].map((d,i) =>
                                                            <span className={userRating.rating.value >= d ? 'star full' :  userRating.rating.tempvalue >= d ? 'star full' : 'star'} onMouseLeave={() => settemprating(0)} onMouseEnter={() => settemprating(d)}
    onClick={()=>setrating(d)}></span>
                                                        )
                                                    }
                                                </div>
                                                <small className="error-input">{ userRating.rating.rating }</small>
                                            </div>
                                           
                                        </div>
                                        <div className="ratingreview-textarea">
                                            <textarea id="address" name="review" 
                                                className={userRating.review.review ? 'input-pro error-border' : 'input-pro'}
                                                onChange={(e) => modifyReview(e)}
                                                value={userRating.review.value}
                                            />
                                            <div className="limit"><strong>Note:</strong> {userRating.review.remaining} character limit</div>
                                            <small className="error-input">{ userRating.review.review }</small>

                                        </div>
                                        <div className="post-btn">
                                            <input type="button" onClick={giveReview} className="next-btn-pro" value="Post Review" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row> 
                    </div>
                </div>             
            </Container>
        </Fragment>
    )
    
}

export default DoctorReviewModal;