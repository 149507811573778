import React, { Fragment, useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import InputValidation from '../../reactLayer/validation';
import { useHistory } from "react-router-dom";
import { ProviderLinks, PatientLinks, AdminLinks } from '../../linkFile';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Loadermodal from "../../global/loader-modal/loader-modal";

const AdminLogin = () => {

  const authContext = useContext(AuthContext);
  const history = useHistory();

  const userNameErrorMsg = ['Username is required', 'Invalid Username'];
  const passwordErrorMsg = ['Password is required', 'Invalid Password'];

  const [errors, setErrors] = useState({
    adminUser: false,
    adminPassword: false,
  });
  
  useEffect(() => {
    if(authContext ?.admin ?.email){
      history.push({ pathname: AdminLinks.admin_dashboard });
    }
  },[])

  const [loginData, setLoginData] = useState({
    username: {
      value: '',
      validation: ['blank', 'email'],
      errorMsg: userNameErrorMsg,
      errorKey: 'adminUser',
    },
    password: {
      value: '',
      validation: ['blank', 'password'],
      errorMsg: passwordErrorMsg,
      errorKey: 'adminPassword',
    }

  });

  const formSubmit = async (e) => {
    e.preventDefault();
    // VALIDATION
    const validationResult = InputValidation(loginData);
    console.log('validationResult-->', validationResult)
    if (Object.keys(validationResult).length > 0) {
      setErrors({ ...errors, ...validationResult });
    } else {

      authContext.setLoader();

      // FORM DATA 
      const formData = {
        "email": loginData.username.value,
        "password": loginData.password.value,
      }

      // STRUCTURE LOGIN REQUEST DATA
      const submitData = {
        headers: {
          'Content-Type': 'application/json'
        },
        url: 'admin/login',
        body: JSON.stringify(formData),
      }

      // SEND REQUEST 
      let result = await NetworkLayer.postData(submitData);
      authContext.unsetLoader();
      console.log('Response', result);

      if (result.body.status) {
        // SET ADMIN DATA IN STORAGE AND REDIRECT TO DASHBOARD AFTER SUCCESS RESPONSE
        let admin = result.body.result;
        authContext.setAdminToken(admin.token);
        const adminData = admin.data
        authContext.setAdminUser(adminData);
        history.push({ pathname: AdminLinks.admin_dashboard });
        console.log('submit successfully');
      } else {
        // HANDLE SERVER ERRORS
        setErrors({ ...errors, ...{ adminPassword: result.body.message } });
      }
    }
  }

  return (
    <Fragment>
      <Loadermodal />
      <Container fluid className="admin-login">
        <Row>
          <Container>
            <Row className="admin-login-row">
              <div className="col-12 admin-login-wrapper">
                <div className="col-12 text-center admin-logo">
                  <img src="/assets/images/doc-scheduler-logo.png" alt="Logo" />
                </div>
                <h1 className="col-12 login-head">Hello, Welcome Back</h1>
                <div className="admin-login-form">
                  <form onSubmit={formSubmit}>
                    <div className="col-md-12 mb-2">
                      <label className="pro-label">Username</label>
                      <input type="text" name="username" className={errors.adminUser ? "input-pro  error-border" : "input-pro"} placeholder="Username" autoComplete="off"
                        onClick={() => setErrors({ ...errors, ...{ adminUser: false } })}
                        onFocus={() => setErrors({ ...errors, ...{ adminUser: false } })}
                        onChange={(e) => setLoginData({
                          ...loginData,
                          ...{
                            username: {
                              value: e.currentTarget.value.trim(),
                              validation: ['blank', 'email'],
                              errorMsg: userNameErrorMsg,
                              errorKey: 'adminUser',
                            }
                          }
                        })
                        }
                        value={loginData.username.value}
                      />
                      <small className="error-input">{errors.adminUser}</small>
                    </div>
                    <div className="col-md-12 mb-2">
                      <label className="pro-label">Password</label>
                      <input type="password" className={errors.adminPassword ? "input-pro error-border" : "input-pro"} name="password" placeholder="********" autoComplete="off"
                        onClick={() => setErrors({ ...errors, ...{ adminPassword: false } })}
                        onFocus={() => setErrors({ ...errors, ...{ adminPassword: false } })}
                        onChange={(e) => setLoginData({
                          ...loginData,
                          ...{
                            password: {
                              value: e.currentTarget.value.trim(),
                              validation: ['blank'],
                              errorMsg: passwordErrorMsg,
                              errorKey: 'adminPassword',
                            }
                          }
                        })
                        }
                        value={loginData.password.value}
                      />
                      <small className="error-input">{errors.adminPassword}</small>
                    </div>
                    <div className="col-md-12 text-center submit-btn">
                      <input type="submit"  className="next-btn-pro" value="Sign In" />
                    </div>
                  </form>
                </div>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AdminLogin;