import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Modal, Button } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import {ReactComponent as ReactLogo} from '../../Icons/loader.svg';


const Loadermodal = (props) => {

  const authContext = useContext(AuthContext);
  const loaderClose = () => {
    if( props.unsetComponent && typeof(props.unsetComponent) == 'function') {
      props.unsetComponent('');
    }
    authContext.unsetLoader()
  };
  const loaderShow = () => authContext.setLoader();

  const ComponentName = props.componentName ? props.componentName : <></>;  

  return (
    <Fragment>
        <Modal dialogClassName="loader-modal" show={authContext.loading}>
            <div className="loader-overlay">
              {/* <ReactLogo /> */}
              <img src='/assets/images/DocS-loader.gif' />
            </div>
        </Modal>
    </Fragment>
    );
  }
  
  export default Loadermodal;