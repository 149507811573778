import React, { Fragment } from "react";
import Header from "../../global/header/header";
import Footer from "../../global/footer/footer";
import "bootstrap/dist/css/bootstrap.css";
import { Container } from "react-bootstrap";
import SearchResults from "../../components/search-results/search-results";

const SearchResult = (props) => {
  return (
    <Fragment>
      <Container fluid className="header px-0">
        <Header />
      </Container>
      <SearchResults userCateType={"provider"} setopenLogin={props.setopenLogin ? props.setopenLogin : ''} />
      <Footer />
    </Fragment>
  );
};

export default SearchResult;
