import React, { memo, Suspense, useCallback, useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Spinner } from "react-bootstrap";
import { PatientLinks, ProviderLinks } from "../../linkFile";
import { useHistory } from "react-router-dom";
import useLocationMod from "../../hooks/useLocationMod";
import AuthContext from "../../reactLayer/auth/AuthContext";

const MedicalFocusarea = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const locationMod = useLocationMod();
  const [isLoading, setIsLoading] = useState(false);
  const [focusareaList, setFocusareaList] = useState([]);

  const redirectToSearchResult = useCallback(
    (event, each) => {
      event.preventDefault();
      const path = authContext.user && authContext?.user?.isProfileCompleted && authContext.user.accountInfo.roleId === 3 ? ProviderLinks.search_result : PatientLinks.doctor_search_result;
      history.push({
        pathname: path,
        state: {
          find: "",
          zipCity: "",
          insurance_Name: [],
          focusAreas: each,
        },
      });
      locationMod.location.state = {
        find: "",
        zipCity: "",
        insurance_Name: [],
        focusAreas: each,
      };
    },
    [history, locationMod]
  );

  useEffect(() => {
    // async function getAllInsuranceResult() {
    //   setIsLoading(true);
    //   let data = [];
    //   const submitData = {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     url: "medical-focusarea/get",
    //   };
    //   let result = await NetworkLayer.getRequest(submitData);
    //   if (result.result.length > 0) {
    //     data = [...result.result];
    //   }
    //   setFocusareaList(data);
    //   setIsLoading(false);
    // }
    // getAllInsuranceResult();
      const data = [{"image":"/assets/images/category/Search_for_Primary_Care_Doctors_near_me.webp","id":1,"title":"Primary Care","altText":"Search for Primary Care Doctors near me","category":null},{"image":"/assets/images/category/Search_for_OB_GYN_Doctors_near_me.webp","id":2,"title":"Women's Health","altText":"Search for OB/GYN Doctors near me","category":null},{"image":"/assets/images/category/Search_for_Dentist_near_me.webp","id":3,"title":"Oral Health","altText":"Search for Dentist near me","category":null},{"image":"/assets/images/category/Search_for_Eye_Doctors_near_me.webp","id":4,"title":"Eye Health","altText":"Search for Eye Doctors near me","category":null},{"image":"/assets/images/category/Search_for_Pediatricians _near_me.webp","id":5,"title":"Pediatrics","altText":"Search for Pediatricians near me","category":null},{"image":"/assets/images/category/Search_for_Dermatologist_near_me.webp","id":6,"title":"Dermatology","altText":"Search for Dermatologist near me","category":null},{"image":"/assets/images/category/Search_for_Mental_Health_doctor_near_me.webp","id":7,"title":"Mental Health","altText":"Search for Mental Health doctor near me","category":null},{"image":"/assets/images/category/Search_for_Cosmetic_Surgery _doctor_near_me.webp","id":8,"title":"Aesthetic Medicine","altText":"Search for Cosmetic Surgery doctor near me","category":null}];
      setFocusareaList(data);
  }, []);

  return (
    <div className="col-12">
      <div className="specialty-doc-list-wrap">
        <h1>Choose a specialty doctor or dentist near me</h1>
        {isLoading ? (
          <div className="loadingContainer">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : focusareaList.length > 0 ? (
          <div className="spec-list-cat">
            <Suspense>
              {focusareaList.map((each, index) => (
                <a href="/#" key={index} onClick={e => redirectToSearchResult(e, each?.title)}>
                  <div className="img-container">
                    <img
                      src={each?.image}
                      alt={each?.altText}
                      className="cat-img"
                    />
                    <div className="overlay-cat"></div>
                    <div className="cat-overlay-text">
                      <div className="text">{each?.title}</div>
                    </div>
                    <div className="cat-border-box-bottom"></div>
                  </div>
                </a>
              ))}
            </Suspense>
          </div>
        ) : (
          <div className="loadingContainer">
            <span>No data found.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(MedicalFocusarea);
