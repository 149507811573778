import React, { Fragment, useState } from 'react';
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'react-bootstrap';
import DashboardHead from '../../components/dashboard-head-tab/dashboard-head';
import AppointmentHistory from './appointment';
import PatientHealth from '../../components/patient-health-grade/patient-hgrade';
import CareTeam from './care-team';
import Error404 from '../../global/errorpages/error-404';
import { useHistory } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router";
import {ProviderLinks, PatientLinks, AdminLinks} from '../../linkFile';



const Dashboard = () => {
  const [visibleComponent, setvisibleComponent] = useState('appointmentHistory');
  const tabName = [{ id: 'appointmentHistory', name: 'Appointment History' }, { id: 'careTeam', name: 'Care Team' }, { id: 'healthGrade', name: 'Health Grade' }, { id: 'doctorSearch', name: 'Doctor Search' }]
  const history = useHistory();
  const redirectToHome = () => {
    history.push({
        pathname: ProviderLinks.home,
    });
  }
  return (
    <Fragment>
      <HeaderMain />
      <DashboardHead visibleComponent={visibleComponent} setvisibleComponent={setvisibleComponent} tabName={tabName} title={'My Health Dashboard'} />

      {
          visibleComponent === 'appointmentHistory' ? <AppointmentHistory/>
          : 
          visibleComponent === 'careTeam' ? <CareTeam/> 
          : 
          visibleComponent === 'healthGrade' ? <PatientHealth/> 
          :
          visibleComponent === 'doctorSearch' ? <>{redirectToHome()}</> : <></>
      }

      {/* {
        visibleComponent === 'appointmentHistory' ? <Error404 />
          :
          visibleComponent === 'careTeam' ? <CareTeam />
            :
            visibleComponent === 'healthGrade' ? <Error404 />
              :
              visibleComponent === 'doctorSearch' ? <><Redirect to="/" /></> : <></>
      } */}

      <Footer />
    </Fragment>
  );
}

export default Dashboard;