import React, { Fragment, useState, useContext, useEffect } from 'react';
import HeaderMain from '../../global/header/header-main';
import Footer from '../../global/footer/footer';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from "../../reactLayer/Network/Network";
import AuthContext from "../../reactLayer/auth/AuthContext";
import ProviderHeading from '../../components/provider-heading/provider-heading';
import ProviderLeftProfile from '../../components/pro-doc-profile-left/pro-doc-profile-left';
import ProviderRightProfile from '../../components/pro-doc-profile-right/pro-doc-profile-right';
import ProfileTabs from '../../components/profile-tabs/profile-tabs';
import Ratingandreview from '../../components/rating-review/rating-review';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import Custommodal from '../../global/modal/modal';
import Claimform_modal from './Claimform_modal';


const Profile = (props) => {
    const authContext = useContext(AuthContext);
    const [claim, setClaim] = useState(false);
    const [profile, setProfile] = useState({});
    const [modalProps, setmodalProps] = useState({
        type:'', 
        msg:'',
        btnname: '',
        redirection: '',
        claimProfile:false
    });

    const changeClaimForm = () => {
        setClaim(true);
        authContext.showModal();
    }
    const closeClaimForm = () => {
        setClaim(false);
        authContext.hideModal();
    }

    const getProfile = async () => {

        let userId = props.match && props.match.params && props.match.params.userID ? props.match.params.userID : null;
        if( userId ) {
            authContext.setLoader();
            let headers = {'Content-Type': 'application/json'};
            if( authContext.token ) {
                headers['access_token'] = authContext.token
            }
            let fData = {};
            if( authContext.browserLatLng && authContext.browserLatLng.latitude && authContext.browserLatLng.longitude ) {
                fData['latitude'] = authContext.browserLatLng.latitude;
                fData['longitude'] = authContext.browserLatLng.longitude;
            }
            const submitData = {
                headers: headers,
                url: 'providers/get/'+userId,
                body: JSON.stringify(fData),
            }
            let result = await NetworkLayer.postData(submitData);
            // console.log('result in get profile by id', result);
            if(result.body.status) {
                let temp_result = result.body.result;
                if( result.body.addressInfo ) {
                    temp_result['addressInfo'] = result.body.addressInfo;
                }
                setProfile(temp_result);
            }
            authContext.unsetLoader();
        }
        
    } 

    const forgetPassword = async () => {
        
        authContext.setLoader();
            let data = {
                email: profile.accountInfo.email,  
            }
            
            let url = 'forgot-password';
            
            const submitData = {
                url: url,
                body: JSON.stringify(data),
            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            console.log('result .body', result);
            if( result.body.status ) {                
                setmodalProps({
                    type:'success', 
                    msg: result.body.message,
                    claimProfile:true
                });
                authContext.showResponseModal();
            } else {
                setmodalProps({
                    type:'error', 
                    msg: result.body.message,
                    claimProfile:true
                });
                authContext.showResponseModal();
            }
            setTimeout(()=>{ setmodalProps({claimProfile:false}) },4000)
    }

    useEffect(() => {
        console.log('props in get profile page', props);
        getProfile();
    },[props]) 
    return (
        <Fragment>
        {/* header */}
        <Row>
            {
                authContext.responseModal && modalProps.claimProfile ? <Msgmodal {...modalProps} /> : <></>
            }
        </Row>
        <HeaderMain/>
        {/* provider heading */}
        <ProviderHeading {...props} forgetPassword={forgetPassword} claimProfile = {profile} setClaim={changeClaimForm}/>   
        <Container>
            {/* Profile section start */}
            <Row className="mb-4">
                <div className="col-12">    
                    <div className="provider-profile-inner">
                    {
                        claim === true ?
                            <Custommodal 
                                unsetComponent={closeClaimForm}
                                componentName={ <Claimform_modal userId={props.match.params.userID} closeClaimForm={closeClaimForm} /> }
                            />
                        :
                        <></>
                    }
                    
                        <ProviderLeftProfile {...profile} getProfile={getProfile} editable={false} secondarySection={true}/>
                        <ProviderRightProfile {...profile} getProfile={getProfile} />
                    </div>
                </div>
            </Row>
            {/* tabs start here */}
            <ProfileTabs {...profile} editable={false} getProfile={getProfile}/>
            {/* Review section start */}
            <Ratingandreview sourceList={profile.allSource ? profile.allSource : []} profile={profile} getProfile={getProfile} ratingReviews = {profile.ratingReviews ? profile.ratingReviews : []}/>
        </Container>

        <Footer/>
        </Fragment>
        );
    }
  
  export default Profile;