import React, { Fragment, useContext, useState, useEffect } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import AppointmentFilter from './appointment-filter';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Pagination from "../pagination/pagination";
import Loadermodal from "../../../global/loader-modal/loader-modal";
import Custommodal from '../../../global/modal/modal';
import ProviderManagementInfoMadal from '../provider/provider-manage-info-modal';
import PatientManagementInfoMadal from '../patient/patient-manage-info-madal';

let _dateFilter = ""
let searchText = ''
const CallBackTable = () => {

    const authContext = useContext(AuthContext);
    const [totalCount, settotalCount] = useState(0);
    const [filter, setfilter] = useState({
        offset: 0,
        limit: 10
    });
    const [data, setData] = useState([]);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [viewdetails, setViewdetails] = useState([]);
    const [search, setSearch] = useState("");
    const [sort, setsort] = useState('ASC');
    const [sortName, setSortName] = useState('id');

    // FOR POPUP MODAL
    const modalList = ['ratingComment'];
    const openProfileTabModal = (modalName, value) => {
        getDetails(value ?.id).then(() => {
            settabvisibleComponent(modalName)
            authContext.showModal()
        })
    }

    const getList = async (value) => {
        console.log('searchText',searchText);
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (value || searchText) {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                searchValue: searchText,
                sortOrder: sort,
                sortBy: sortName
            }
        } else {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                sortOrder: sort,
                sortBy: sortName
            }
        }

        if(_dateFilter){
            const dates = _dateFilter.split(' - ')
            formData.startdate = dates[0]
            formData.enddate = dates[1]
            console.log('_dateFilter',_dateFilter)
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/appointment/callbackList',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        try {
            let result = await NetworkLayer.postData(submitData);
            console.log('callback result-->',result);
            setData(result ?.body ?.result ?.rows);
            settotalCount(result ?.body ?.result ?.count);
        } catch (err) {
            console.log("ERROR :", err)
        }

        authContext.unsetLoader();

    }

    const getDetails = async (id) => {
        authContext.setLoader();

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/rating/get/' + id,
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        setViewdetails(result ?.result);
        authContext.unsetLoader();
    }

    useEffect(() => {
        getList();
        return () => {
            _dateFilter = '';
        };
    }, [filter.offset, sort])

    // PAGINATION CALLBACK
    const changeLimit = (page) => {
        setfilter({ ...filter, ...{ offset: parseInt(page) } });
    };


    const changeSorting = (e) => {
        let name = e.currentTarget.getAttribute('name');
        setSortName(name);
        setsort(sort == "ASC" ? "DESC" : "ASC")
    };

    const searchFilter = (value,dateFilter = "") => {
        setfilter({offset:0,limit:10})
        _dateFilter = dateFilter
        searchText = value
        getList(value);
    }


    const showProviderProfile = (id,type) => {
        if(type === 'manageInfoProvider'){
            getProviderDetails(id).then(() => {
                settabvisibleComponent(type)
                authContext.showModal()
            })
        }else{
            getPatientDetails(id).then(() => {
                settabvisibleComponent(type)
                authContext.showModal()
            })
        }
    }

    const getPatientDetails = async (id) => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        formData = {
            id: id
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/getPatient',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);


        setViewdetails(result ?.body ?.result);
        authContext.unsetLoader();
    }

    const getProviderDetails = async (id) => {
        authContext.setLoader();

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/view/' + id,
        }



        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        setViewdetails(result ?.result);
        authContext.unsetLoader();
    }

    const getSpeciality = (speciality) => {
        let spl = [];
        if( speciality ) {
            speciality.map((d) => {
                  if( d.specialties && d.specialties.name ) {
                      spl.push(d.specialties.name);
                  }  
            })
        } 
        return spl.join(', ');
    }

    const changeSchedue = async(e, id, status,index) => {
        e && e.preventDefault();
        authContext.setLoader();
        let fData = { id: id, bookingStatus: status === 1 ? 2 : 1}
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'appointment/book/status/update',
            body: JSON.stringify(fData),
        }
        let result = await NetworkLayer.postData(submitData);
        console.log('appointment result ===>', result);
        if( result.body.status ) {
            let dataTemp = JSON.parse(JSON.stringify(data))
            dataTemp[index].bookingStatus = status === 1 ? 2 : 1
            setData(dataTemp)
            authContext.unsetLoader();
        } else {
            authContext.unsetLoader();
        }
    }


    return (
        <Fragment>
            <Loadermodal />
            <Custommodal 
                    unsetComponent={settabvisibleComponent} 
                    componentName={tabvisibleComponent === 'manageInfoProvider' ? 
                                    <ProviderManagementInfoMadal data={viewdetails} /> : 
                                    <PatientManagementInfoMadal data={viewdetails} /> }
            />
            <AppointmentFilter search={searchFilter} callBack={true} />
            <div className="table-report-scroll mt-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="data-sort">
                                <span className="sort-outer">
                                    Patient
                                </span>
                            </th>
                            <th style={{ 'min-width': '150px' }}>Patient Contact#</th>
                            <th>Provider</th>
                            <th>Speciality</th>
                            <th>Request Type</th>
                            <th style={{ 'min-width': '200px' }}>
                                <span className="sort-outer">
                                    Callback Request Date
                                    <span className="data-sort-icon" name="createdAt" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            data.length == 0 &&
                            <tr>
                                <td>No Records Found</td>
                            </tr>
                        }
                        {
                            data && data.map((value, index) =>
                                <tr key={index}>
                                    <td><a onClick={(e)=>{ e.preventDefault(); showProviderProfile(value.patientId,'manageInfoPatient') }} href="/#">{`${value.usersFrom ?.patients ? value.usersFrom ?.patients ?.firstName + " " + value.usersFrom ?.patients ?.lastName : ""}`}</a></td>
                                    <td>{value.usersFrom ?.patients ?.phoneNumber}</td>
                                    <td><a onClick={(e)=>{ e.preventDefault(); showProviderProfile(value.usersTo.providers.id,'manageInfoProvider') }} href="/#">{`${value.usersTo ? value.usersTo ?.providers ?.firstName + " " + value.usersTo ?.providers ?.lastName : ""}`}</a></td>
                                    <td>
                                        {
                                            value.usersTo && value.usersTo.providerMedicalSpecialities && getSpeciality(value.usersTo.providerMedicalSpecialities)
                                            
                                        }
                                    
                                    </td>
                                    <td>Call Back Request</td>
                                    <td>{moment(`${new Date(value.createdAt)}`).format('MM-DD-YYYY')}</td>
                                    <td>{moment(`${new Date(value.createdAt)}`).format('HH:MM')}</td>
                                </tr>
                            )}

                    </tbody>
                </table>
            </div>
            <div className="report-page mt-4">
                <Pagination
                    total={totalCount}
                    limit={filter.limit}
                    range={4}
                    offset={filter.offset}
                    callBack={changeLimit}
                />
            </div>
        </Fragment>
    );
}

export default CallBackTable;