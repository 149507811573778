import React, {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  lazy,
  Suspense
} from "react";

import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { PatientLinks, ProviderLinks } from "../../linkFile";
import NetworkLayer from "../../reactLayer/Network/Network";


// import MedicalFocusarea from "./MedicalFocusarea";
import useLocationMod from "../../hooks/useLocationMod";
import AuthContext from "../../reactLayer/auth/AuthContext";
import MedicalFocusarea from './MedicalFocusarea';

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

const HomePage = () => {
  const locationMod = useLocationMod();
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [searchCate, setSearchCate] = useState("");
  const [displayCate, setDisplayCate] = useState(false);
  const [isSearchingCate, setIsSearchingCate] = useState(false);
  const [optionsCate, setOptionsCate] = useState([
    {
      specialityData: [],
      conditionData: [],
      treatmentData: [],
      doctorData: [],
    },
  ]);
  const wrapperCateRef = useRef(null);
  const [displayInsuranceName, setDisplaInsuranceName] = useState(false);
  const [optionsInsuranceName, setOptionsInsuranceName] = useState([]);
  const [searchInsuranceName, setSearchInsuranceName] = useState("");
  const [searchzipCity, setSearchZipCity] = useState("");
  const [isChildren, setisChildren] = useState(0);

  const wrapperInsuranceRef = useRef(null);

  const selectChildren = (e) => {
    if(e.currentTarget.checked) {
       setisChildren(1)
    } else {
      setisChildren(0)
    }
  } 
  const redirectToSearchResult = () => {
    console.log('ascascas');
    const path =
      authContext.user &&
      authContext?.user?.isProfileCompleted &&
      authContext.user.accountInfo.roleId === 3
        ? ProviderLinks.search_result
        : PatientLinks.doctor_search_result;
    history.push({
      pathname: path,
      state: {
        find: searchCate,
        zipCity: searchzipCity,
        insurance_Name: searchInsuranceName,
        isChildren: isChildren
      },
    });
    locationMod.location.state = {
      find: searchCate,
      zipCity: searchzipCity,
      insurance_Name: searchInsuranceName,
      isChildren: isChildren
    };
  };

  const getAllFindResult = useCallback(async (input) => {
    if (input.length >= 3) {
      setIsSearchingCate(true);
      setDisplayCate(true);
      let dataNode = [
        {
          specialityData: [],
          conditionData: [],
          treatmentData: [],
          doctorData: [],
        },
      ];
      const submitData = {
        headers: {
          "Content-Type": "application/json",
        },
        url: "providers/search/suggestion/get",
        body: JSON.stringify({
          searchValue: input,
        }),
      };
      let result = await NetworkLayer.postData(submitData);
      if (result.body.status) {
        if (result.body.result) {
          setIsSearchingCate(false);
          const {
            specialityData,
            conditionData,
            treatmentData,
            doctorData,
          } = result.body.result;
          let specialityNode = specialityData.map(({ id, speciality }) => ({
            id: id,
            data: speciality,
          }));
          let doctorListNode = doctorData.map(
            ({ id, firstName, lastName }) => ({
              id: id,
              data:
                (firstName ? firstName : "") + (lastName ? " " + lastName : ""),
            })
          );
          dataNode[0].specialityData = specialityNode;
          dataNode[0].conditionData = conditionData;
          dataNode[0].treatmentData = treatmentData;
          dataNode[0].doctorData = doctorListNode;
          if (
            specialityNode.length === 0 &&
            conditionData.length === 0 &&
            treatmentData.length === 0 &&
            doctorData.length === 0
          ) {
            setDisplayCate(false);
          }
        }
      } else {
        setIsSearchingCate(false);
      }
      setOptionsCate(dataNode);
    }
  }, []);

  const debounceGet = useRef(
    debounce((nextValue) => getAllFindResult(nextValue), 1000)
  ).current;

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperCateRef;
    const { current: wrapInsurance } = wrapperInsuranceRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplayCate(false);
    }
    if (wrapInsurance && !wrapInsurance.contains(event.target)) {
      setDisplaInsuranceName(false);
    }
  };

  const updateCateDex = (each) => {
    setSearchCate(each);
    setDisplayCate(false);
  };

  const updateInsuranceDex = (each) => {
    setSearchInsuranceName(each);
    setDisplaInsuranceName(false);
  };

  const _renderListItem = (title = "SPECIALTY", items) => {
    return (
      <ListGroup.Item>
        {title}
        <ListGroup className="borderless">
          {items.map((each, index) => (
            <ListGroup.Item
              className="cateFindItem"
              key={title + "_" + index}
              onClick={() => updateCateDex(each.data)}
            >
              {each.data}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </ListGroup.Item>
    );
  };

  const _renderInsuranceListItem = (item, index) => {
    return (
      <ListGroup.Item
        className="option insuranceItem"
        onClick={() => updateInsuranceDex(item.name)}
        key={index}
      >
        {item.name}
      </ListGroup.Item>
    );
  };

  const handleChange = (event) => {
    const nextValue = event?.target?.value;
    setSearchCate(nextValue);
    debounceGet(nextValue);
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    async function getAllInsuranceResult() {
      let data = [];
      const submitData = {
        headers: {
          "Content-Type": "application/json",
        },
        url: "home/insurance/get",
      };
      let result = await NetworkLayer.getRequest(submitData);
      if (
        result.result?.data.length > 0 ||
        result.result?.customInsuranceData > 0
      ) {
        data = [].concat(
          [...result.result?.data],
          [...result.result?.customInsuranceData]
        );
      }
      setOptionsInsuranceName(data);
    }
    getAllInsuranceResult();
    console.log('testing -->123', 'vipin123567890')
  }, []);
  const suspenseLoader = () => <p></p>;
  return (
    <Suspense fallback={suspenseLoader()}>
      <Fragment>
        <div className="home-top-container">
          <Container>
            <Row>
              <div className="col-12">
                <div className="home-logo">
                  <div className="homelogo-new">
                    <img
                      src="/assets/images/logo-icon.svg"
                      className="logo-icon"
                      alt="DocScheduler"
                    />
                    <strong>DocScheduler</strong>
                  </div>
                  <span>Search and schedule local doctors and dentists</span>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-12">
                <div className="home-top-fields">
                  <span ref={wrapperCateRef}>
                    <input
                      type="text"
                      name="find"
                      placeholder="Condition, Specialty, Treatment, or Doctor Name"
                      className="input-home"
                      autoComplete="off"
                      value={searchCate}
                      onChange={handleChange}
                    />
                    <div className="find-home">Find:</div>
                    {isSearchingCate && (
                      <div className="cateSearch">Searching ...</div>
                    )}
                    {displayCate &&
                      (optionsCate[0].specialityData.length > 0 ||
                        optionsCate[0].conditionData.length > 0 ||
                        optionsCate[0].treatmentData.length ||
                        optionsCate[0].doctorData.length > 0) && (
                        <div className="autoContainerCate">
                          <div className="autoContenrCate">
                            <ListGroup>
                              {optionsCate[0].specialityData.length > 0 &&
                                _renderListItem(
                                  "SPECIALTY",
                                  optionsCate[0].specialityData
                                )}
                              {optionsCate[0].conditionData.length > 0 &&
                                _renderListItem(
                                  "CONDITION",
                                  optionsCate[0].conditionData
                                )}
                              {optionsCate[0].treatmentData.length > 0 &&
                                _renderListItem(
                                  "TREATMENT",
                                  optionsCate[0].treatmentData
                                )}
                              {optionsCate[0].doctorData.length > 0 &&
                                _renderListItem(
                                  "DOCTOR",
                                  optionsCate[0].doctorData
                                )}
                            </ListGroup>
                          </div>
                        </div>
                      )}
                      <div className="home-bottom-field-txt mt-2">
                        <span className="f-wdth mb-2">
                          <em>
                            <strong>Examples:</strong> Physical exam, Primary Care
                            doctor, back pain, allergies
                          </em>
                        </span>
                        <span className="home-check pt-0 d-md-block d-none f-wdth ">
                          <div className="cus-check">
                            <input type="checkbox" id="hipppa" name="docHippa" checked={isChildren ? true : false} onChange={(e)=>selectChildren(e)} />
                            <label htmlFor="hipppa">
                              Select check box to search for children's doctors
                            </label>
                          </div>
                        </span>
                      </div>
                  </span>
                  <span>
                    <input
                      type="text"
                      name="find"
                      placeholder="Zip Code or City"
                      className="input-home"
                      autoComplete="off"
                      onChange={(event) => {
                        setSearchZipCity(event?.target?.value);
                      }}
                    />
                    <div className="find-home">Near:</div>
                  </span>
                  <span ref={wrapperInsuranceRef}>
                    <input
                      type="text"
                      name="insurance_name"
                      onClick={() => {
                        setDisplaInsuranceName(!displayInsuranceName);
                      }}
                      placeholder="Insurance Name"
                      autoComplete="off"
                      className="input-home"
                      value={searchInsuranceName}
                      onChange={(event) => {
                        setSearchInsuranceName(event?.target?.value);
                      }}
                    />
                    <ListGroup>
                      {displayInsuranceName && optionsInsuranceName.length > 0 && (
                        <div className="autoContainerInsuranceName">
                          <div className="autoContentInsuranceName">
                            {optionsInsuranceName
                              .filter(
                                ({ name }) =>
                                  name
                                    .toLowerCase()
                                    .indexOf(searchInsuranceName.toLowerCase()) >
                                  -1
                              )
                              .map((value, i) =>
                                _renderInsuranceListItem(value, i)
                              )}
                          </div>
                        </div>
                      )}
                    </ListGroup>
                  </span>
                  <span>
                    <span className="home-check d-md-none f-wdth">
                      <div className="cus-check mb-3">
                        <input type="checkbox" id="hipppa" name="docHippa" checked={isChildren ? true : false} onChange={(e)=>selectChildren(e)} />
                        <label htmlFor="hipppa">
                          Select check box to search for children's doctors
                        </label>
                      </div>
                    </span>
                    <input
                      type="button"
                      onClick={() => {
                        redirectToSearchResult();
                      }}
                      className="search-home-btn"
                      value="Search"
                    />
                  </span>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <MedicalFocusarea />
          </Row>
        </Container>
      </Fragment>
    </Suspense>
  );
};

export default memo(HomePage);
