import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Global from '../../reactLayer/global';
import InputValidation from '../../reactLayer/validation';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import { ProviderLinks } from '../../linkFile';

const Claimform_modal = (props) => {
    const baseUrl = process.env.REACT_APP_BASEURL;
    const authContext = useContext(AuthContext);

    const history = useHistory();
    const emailErrorMsg = ['Email is required', 'Invalid Email'];
    const npinumberErrorMsg = ['NPI is required', 'Invalid Number'];
    const [userData, setUserData] = useState({
        email: {
            value: '',
            validation: ['blank', 'email'],
            errorMsg: emailErrorMsg,
            errorKey: 'email',
            email: false
        },
        npinumber: {
            value: '',
            validation: ['blank', 'number'],
            errorMsg: npinumberErrorMsg,
            errorKey: 'npinumber',
            npinumber: false
        }
    });
    const [type, setType] = useState('success');
    const [claimProfileModalShow,setClaimProfileModalShow] = useState(false)
    const [claimProfileData,setClaimProfileData] = useState({})
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    
    const formSubmit = () => {

        let tempuserData = userData;
        console.log('tempuserData', tempuserData);
        const validationResult = InputValidation(userData);
        // console.log( 'validationResult-->',validationResult )
        if (Object.keys(validationResult).length > 0) {
            for (const prop in validationResult) {
                console.log('prop', tempuserData[prop][prop]);
                tempuserData[prop][prop] = `${validationResult[prop]}`;
            }
            setUserData({ ...tempuserData });
            setTimeout(() => {
                let errorFilds = document.querySelectorAll('.error-border');
                // console.log('errorFilds',errorFilds);
                if (errorFilds.length) {
                    errorFilds[0].scrollIntoView({ block: "start", behavior: "smooth" });
                }
            }, 100);
        } else {
            formDataSubmit();
            //    console.log('submit successfully');
        }
    }

    const formDataSubmit = async () => {
        const data = {
            "email": userData.email.value.trim(),
            "nationalProviderIdentifierNumber": userData.npinumber.value.trim(),
            "id": props.userId
        }
        const submitData = {
            url: 'forgot-password-claimprofile',
            body: JSON.stringify(data),
        }

        authContext.setLoader();
        let result = await NetworkLayer.postData(submitData);
        authContext.unsetLoader();
        
        if (result.body.status) {
            setmodalProps({
                type: 'success',
                msg: result.body.message,
                btnname: 'Sign In',
                redirection: ProviderLinks.login
            });
            authContext.showResponseModal();
            setTimeout(() => {
                props.closeClaimForm();
            },3000)
        } else {
            setmodalProps({
                type: 'error',
                msg: result.body.message,
            });
            authContext.showResponseModal();
            setTimeout(() => {
                props.closeClaimForm();
            },3000)
        }
    }

    useEffect(() => {
        if (authContext.user) {
            let user = authContext.user.accountInfo;
            setUserData({
                email: {
                    value: user.email,
                    validation: ['blank', 'email'],
                    errorMsg: emailErrorMsg,
                    errorKey: 'email',
                    email: false
                },
                npinumber: {
                    value: user.nationalProviderIdentifierNumber,
                    validation: ['blank', 'number'],
                    errorMsg: npinumberErrorMsg,
                    errorKey: 'npinumber',
                    npinumber: false
                }
                
            });
        }
    }, [modalProps]);


    return (

        <Fragment>
            {/* Form start step 1 */}
            <Container className="provider-form-inner">
                {
                    authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                }
                <form>
                    <Row>
                        <div className="col-md-12 mb-3 mb-3 mt-3" style={{textAlign: 'center'}}>
                            <h2>Claim Your Profile</h2>
                        </div>
                        <div className="col-md-12 mb-3 mb-3 mt-3">
                            <label htmlFor="wMail" className="pro-label">Email</label>
                            <input type="text" name="wMail" className={userData.email.email ? "input-pro  error-border" : "input-pro"} placeholder="Email required for account verification" autoComplete="off"
                                onChange={(e) => setUserData({
                                    ...userData,
                                    ...{
                                        email: {
                                            value: e.currentTarget.value,
                                            validation: ['blank', 'email'],
                                            errorMsg: emailErrorMsg,
                                            errorKey: 'email',
                                            email: false
                                        }
                                    }
                                })
                                }
                                value={userData.email.value}
                            />
                            <small className="error-input">{userData.email.email}</small>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label htmlFor="npiNumber" className="pro-label">National Provider Identifier (NPI) Number</label>
                            <input type="text" name="npiNumber" className={userData.npinumber.npinumber ? "input-pro  error-border" : "input-pro"} placeholder=" " autoComplete="off"
                                onChange={(e) => setUserData({
                                    ...userData,
                                    ...{
                                        npinumber: {
                                            value: !isNaN(e.currentTarget.value) ? e.currentTarget.value : e.currentTarget.value.slice(0, -1) ,
                                            validation: ['blank', 'number'],
                                            errorMsg: npinumberErrorMsg,
                                            errorKey: 'npinumber',
                                            npinumber: false
                                        }
                                    }
                                })
                                }
                                value={userData.npinumber.value}
                            />
                            <small className="error-input">{userData.npinumber.npinumber}</small>
                            {/* <span className="input-bottom-link info-hg right">
                                <a href="/#" onClick={(e) => e.preventDefault()}>How will my NPI # be used?</a>
                                <div className="info-callout right">
                                    Providing your NPI number allows DocScheduler to verify that you are a HIPAA–covered entity/person.
                                </div>
                            </span> */}
                        </div>
                        <div className="col-md-12 mb-5 text-center">
                            <input type="button" className="next-btn-pro" onClick={() => { formSubmit() }} value="Submit" />
                        </div>
                    </Row>
                </form>
            </Container>
        </Fragment>
    );
}

export default Claimform_modal;