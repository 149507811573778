import React, { Fragment, useState, useEffect, useContext } from 'react';
import CircleAnimationGrade from './circle-animation';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import HealthCalculate from './calculate';
import CalculateResult from './calculate-result';
import AuthContext from '../../reactLayer/auth/AuthContext';
import NetworkLayer from '../../reactLayer/Network/Network';
import * as moment from 'moment';
const PatientHealthResult = () => {

  const [show, toggleShow] = useState(false);
  const [data, setData] = useState([]);
  const authContext = useContext(AuthContext);

  async function getHealthGrades() {
    authContext.setLoader();
    let submitData = {
      headers: {
        "access_token": authContext.token,
        "Content-Type": "application/json"
      },
      url: "patients/health-grades/get",
    };
    let result = await NetworkLayer.getRequest(submitData);
    setData(result.result);
    authContext.unsetLoader();
  }

  async function deleteHealthGrades(value) {
    authContext.setLoader();

    const formData = {
      "id": value.id
    }

    let submitData = {
      headers: {
        "access_token": authContext.token,
        "Content-Type": "application/json"
      },
      url: "patients/health-grades/destroy",
      body: JSON.stringify(formData),
    };

    let delelteResult = await NetworkLayer.postData(submitData);
    getHealthGrades();
    authContext.unsetLoader();
  }

  useEffect(() => {
    getHealthGrades();
  }, [])

  useEffect(() => {
    getHealthGrades();
  }, [show])

  return (
    <Fragment>
      <Container fluid className="policy-terms-wrap">
        <Row>
          <Container>
            <Row>
              <div className="col-12">
                <div className="health-grade-wrap">
                  <h2>Health Grade</h2>
                  <div className="health-grade-inner">
                    <h2>Health Grade Calculator</h2>

                    <Row>
                      <div className="col-lg-6 px-0 hgrade-border">
                        {/* Calculator part */}
                        {!show && <HealthCalculate toggle={toggleShow} />}

                        {/* Result part */}
                        {show && <CalculateResult toggle={toggleShow} />}
                      </div>
                      <div className="col-lg-6 px-0 text-center">
                        <div className="health-grade-right">
                          <div className="calc-heading">Health Grade</div>
                          <div className="share-wrap">Share your health grade!</div>
                          <div className="social-icon-hg">
                            <a href="\#"><img src="/assets/images/fb-share.png" alt="Facebook" className="scale" /></a>
                            <a href="\#"><img src="/assets/images/twitter-icon.png" alt="Twitter" className="scale" /></a>
                          </div>
                          <div className="health-grade-circle">
                            <CircleAnimationGrade score={authContext.healthGradeResult ?.totalScore ? authContext.healthGradeResult ?.totalScore * 25 : 100} grade={authContext.healthGradeResult ?.grade ? authContext.healthGradeResult ?.grade : "?"} />
                            <img src="/assets/images/health-circle.png" alt="Twitter" className="scale" />
                          </div>
                        </div>
                      </div>
                    </Row>


                    {/* Health Grade History */}
                    <Row>
                      <div className="col-12 px-0">
                        <div className="hg-history-wrap">
                          <div className="hg-history-inner">
                            <h2>Health Grade History</h2>
                            <div className="health-grade-table">
                              <div className="table-body-hg table-heading-hg">
                                <div>Date</div>
                                <div>Health Grade</div>
                                <div>BMI</div>
                                <div>BPM</div>
                                <div>Sleep Per Night</div>
                                <div>Tobacco Use</div>
                                {/* <div>Blood Pressure</div>
                                <div>Cholesterol Levels</div> */}
                                <div>Action</div>
                              </div>
                              {
                                data.length == 0 ?
                                  <div className="table-body-hg">
                                    No Records Found
                                </div>
                                  :
                                  (
                                    data.map((value, index) =>
                                      <div className="table-body-hg">
                                        <div>{moment(value.createdAt).format('M/D/Y')}</div>
                                        <div><p className={`grade-${value.healthGrade.toLowerCase().replace(/[+-]/g, '')}`}>{value.healthGrade}</p></div>
                                        <div>{value.BMI}</div>
                                        <div>{value.BPM}</div>
                                        <div>{value.sleep_per_night} hours</div>
                                        <div>{value.tobacco_use == 1 ? "Yes" : "No"}</div>
                                        {/* <div>SBP {value.blood_pressure} , DBP {value.blood_pressure}</div>
                                        <div>TC {value.cholesterol_levels}, HDL63, GLU {value.cholesterol_levels}</div> */}
                                        <div><button onClick={() => deleteHealthGrades(value)}>Delete</button></div>
                                      </div>
                                    )
                                  )

                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
  );
}

export default PatientHealthResult;