import React, { memo } from "react";
import SchedulingTime from "../scheduling/scheduling-time";
import Gmaps from "../googlemap/googlemap";

const SecondaryAddress = (props) => {
  const { item, address, startDate, ref, bookAppointment, browserAddress } = props;
  console.log('on secondary location props', props);
  const renderSchedulingTime = (locationId, date) => {
    return (
      <div className="scheduling-box">
        <SchedulingTime key={locationId} locationId={locationId} item={item} startDate={date} bookAppointment={bookAppointment} />
      </div>
    );
  };

  return (
    <div className="row profile-mid-border-address align-items-center">
      <div className="col-lg-7">
        <div className="result-address-wrap">
          <div className="address-left">
            <span className="tick-icon">Accepting new patients</span>
            <span className="video-icon">Virtual visits available</span>
            <span className="map-icon">
              {address.practiceName ? address.practiceName + "\n" : ""}
              {address.practiceAddress ? address.practiceAddress + "\n" : ""}
            </span>
          </div>
          <div className="address-right-map">
            {/* {item.providerPracticePrimaryLocation &&  item.providerPracticePrimaryLocation.distance ? '≈ '+parseFloat(item.providerPracticePrimaryLocation.distance).toFixed(2)+ ' mi' : ''} */}
            {address?.latitude && address?.longitude && (
            <div className="address-map-box">
              {
                                          
                props.browserAddress ? (<>
                  <a target="_blank" href={`${props.baseAddress}${props.browserAddress}/${address.practiceAddress} ${address.cities.name} ${address.states.name} ${address.zipCode}`} >Direction</a>

                </>) : (<></>)
              }
              <Gmaps
                gmapData={[{
                  lat: parseFloat(address?.latitude),
                  lng: parseFloat(address?.longitude),
                }]}
                zoomLabel={9}
                width={200}
                height={200}
                ref={ref}
              />
            </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-5 profile-mid-border-address1 border-md-0">
        {address?.id && startDate ? renderSchedulingTime(address?.id, startDate) : <></>}
      </div>
    </div>
  );
};

SecondaryAddress.defaultProps = {
  userCateType: "",
  startDate: "",
  address: null,
  ref: null,
  item:null
};

export default memo(SecondaryAddress);
