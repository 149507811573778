import React, { Fragment, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { ProviderLinks, PatinetLinks } from '../../linkFile';
import AuthContext from '../../reactLayer/auth/AuthContext';
  

import HeaderWithLogin from './header';
import HeaderWithoutLogin from './home-page-header';
  


const Header = (props) => {
  const authContext = useContext(AuthContext);
  // console.log('authcontext user in header', authContext.user);
  // console.log('header props', props);
  return (
    <Fragment>
        <Container className="header px-0">
            {
              authContext.user === null || ! authContext.user.isProfileCompleted ? <HeaderWithoutLogin {...props}/> : <HeaderWithLogin {...props}/>
            }
            
        </Container>

    </Fragment>
    );
  }
  
  export default Header;