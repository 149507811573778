import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';


const Youtubevideo = (props) => {


    return (
        <Container className="youtube-video-component">
            <Row>
                <div className="offset-lg-2 col-lg-8 text-center">
                    <h3> View Video Demo </h3>
                </div>
                <div className="offset-1 col-md-10 video-container"> 
                    <iframe src="https://www.youtube.com/embed/videoseries?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                </div>
                
                { props.redirectToCreatAccount ? (<>
                <div className="col-sm-8 col-md-6 mb-5 submission next-btn-pro" onClick={(e) => props.redirectToCreatAccount(e)}>
                    <a href='/#' className="" onClick={(e) => props.redirectToCreatAccount(e)}>
                        Try 60 Days Free
                    </a>
                </div>
            </>) : (<></>)}
            </Row>
        </Container>
    )
    
}

export default Youtubevideo;