import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useCallback,
  memo,
} from "react";
import Custommodal from "../../global/modal/modal";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import { ProviderLinks } from "../../linkFile";
import { Container, Row } from "react-bootstrap";
import SchedulingPatient from "../scheduling/scheduling-patient";
import SchedulingDate from "../scheduling/scheduling-date";
import SchedulingTime from "../scheduling/scheduling-time";
import RequestAppointmentModal from "../doctor-search-result/req-appontment-modal";
// import ProviderLogin from '../provider-login/provider-login';
import NetworkLayer from "../../reactLayer/Network/Network";
import AuthContext from "../../reactLayer/auth/AuthContext";
import Global from "../../reactLayer/global";
import InputValidation from "../../reactLayer/validation";
import Msgmodal from "../../global/modal-response-msg/MsgResponseModal";
import SecondaryAddress from "./secondary-address";
import Gmaps from "../googlemap/googlemap";
import Stars from '../../global/ratingreview/stars';
import { PatientLinks } from '../../linkFile';

const SponsoredDoc = (props) => {
  const { userCateType, startDate, setStartDate, callBack, items, browserAddress, isCareteam, getCareTeamResults } = props;
  const history = useHistory();
   //console.log('userCateType======>',props );
  let mapPrimayRefs = React.useRef([]);
  let mapRefs = React.useRef([]);
  const authContext = useContext(AuthContext);
  const baseAddress = 'https://www.google.co.in/maps/dir/';
  const [tabvisibleComponent, settabvisibleComponent] = useState("");
  const [seeAllLocation, setSeeAllLocation] = useState(false);
  const modalList = ["reqAppointment"];
  const [bookSlot, setbookSlot] = useState(false)
  const [modalProps, setmodalProps] = useState({
    type: "",
    msg: "",
    btnname: "",
    redirection: "",
  });

  const [selectedItem, setselectedItem] = useState(null);
  const openLoginModal = (e) => {
    e && e.preventDefault();
    authContext.showLoginModal({
      modalName: 'login',
      redirectUrl: PatientLinks.doctor_search_result
    });
  }
  const openRequestModal = (modalName, slot = false, item = null) => {
    // authContext.hideModal()
    setselectedItem(item);
    setbookSlot(slot);
    settabvisibleComponent(modalName);
    authContext.showModal();
  };
  const bookAppointment = (slot, item = null) => {
    
    if (authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.isProfileCompleted && authContext.user.accountInfo.roleId === 4) {
      openRequestModal('reqAppointment', slot, item);
    } else {
      openLoginModal();
    }
  }
  const handleLocation = (e) => {
    e.preventDefault();
    setSeeAllLocation(!seeAllLocation);
  };

  const redirectLeavingPage = (e) => {
    e && e.preventDefault();
    let nextPage = e.currentTarget.href;
    history.push({ pathname: PatientLinks.redirection, state: { redirectTo: nextPage } })

  }
  const doaskenDorseDoctor = async (drId, type = "ask") => {
    authContext.setLoader();
    let data = { toUser: drId };
    let url = "endorsement/ask";
    if (type === "add") {
      url = "endorsement/add";
    }
    const submitData = {
      headers: {
        "Content-Type": "application/json",
        access_token: authContext.token,
      },
      url: url,
      body: JSON.stringify(data),
    };
    let result = await NetworkLayer.postData(submitData);
    // authContext.unsetLoader();
    if (result.body.status) {
      setmodalProps({
        type: "success",
        msg: result.body.message,
      });
      authContext.showResponseModal();
      props.getSearchResult && props.getSearchResult();
    } else {
      setmodalProps({
        type: "error",
        msg: result.body.message,
      });
      authContext.showResponseModal();
      props.getSearchResult && props.getSearchResult();
    }
    authContext.setHeaderSearchText({
      text: authContext ?.headerSearchText ?.text,
      actionSubmit: true,
    });
  };
  const requstCallback = async (e, userId) => {
    e.preventDefault();
    authContext.setLoader();
    let data = { userId: userId };
    let url = "patients/request-callback/submit";

    const submitData = {
      headers: {
        "Content-Type": "application/json",
        access_token: authContext.token,
      },
      url: url,
      body: JSON.stringify(data),
    };
    let result = await NetworkLayer.postData(submitData);
    console.log('result on ', result);
    // authContext.unsetLoader();
    authContext.unsetLoader();
    if (result.body.status) {
      setmodalProps({
        type: "success",
        msg: result.body.message,
      });
      authContext.showResponseModal();
      props.getSearchResult && props.getSearchResult();
    } else {
      setmodalProps({
        type: "error",
        msg: result.body.message,
      });
      authContext.showResponseModal();
      props.getSearchResult && props.getSearchResult();
    }
  }
  const askenDorseDoctor = (e, drId) => {
    e && e.preventDefault();
    doaskenDorseDoctor(drId, "ask");
  };

  const enDorseDoctor = (e, drId) => {
    e && e.preventDefault();
    doaskenDorseDoctor(drId, "add");
  };

  const saveUnsaveFavDoc = async (value, type) => {
    console.log(value);

    authContext.setLoader();

    let url = '';
    let isSavedDoctor = 1;
    if (type == 'save') {
      url = 'patients/favourite/doctor/add';
      isSavedDoctor = 1;
    } else {
      url = 'patients/favourite/doctor/remove';
      isSavedDoctor = 0;
    }
    // FORM DATA
    const formData = {
      "toUser": value ?.providerPracticeLocations[0] ?.userId
    }
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        "access_token": authContext.token
      },
      url: url,
      body: JSON.stringify(formData),
    }
    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData);

    console.log('Response', result);
    if (result.body.code == 200) {
      // getCareTeamResults();
      if (isCareteam) {
        getCareTeamResults();
      }
    }

    authContext.unsetLoader();
  }

  const renderSchedulingTime = (locationId, date, item) => {
    console.log('event fire on change date =====date', item);
    return (
      <div key={locationId + '-WrapperMain'} className="scheduling-box">
        {/* {isCareteam && <SchedulingDate key={'sd'+locationId} startDate={startDate} setStartDate={setStartDate} />} */}
        <SchedulingTime key={locationId} locationId={locationId} item={item} startDate={date} bookAppointment={bookAppointment} isCareteam={isCareteam} />
      </div>
      // <></>
    );
  };

  const renderComponent = () => {
    // console.log('checkf safjlsfkjlkj fjwelkjrlqwr',props);
    return (
      <Fragment>
        <Row>
          {authContext.responseModal && !tabvisibleComponent ? <Msgmodal {...modalProps} /> : <></>}
        </Row>
        {modalList.indexOf(tabvisibleComponent) > "-1" ? (
          <Custommodal
            unsetComponent={settabvisibleComponent}
            componentName={
              tabvisibleComponent === "reqAppointment" && selectedItem ? (
                <RequestAppointmentModal {...selectedItem} bookSlot={bookSlot} browserAddress={props.browserAddress} />
              ) : (
                  <></>
                )
            }
          />
        ) : (
            <></>
          )}
        {

          props.items && props.items.map((item, itemIndex) =>
            <>


              <div className="col-12 sponsor-doctor-list">
                <div
                  style={{ textTransform: "capitalize" }}
                  className={
                    item.userType === "sponsored"
                      ? "search-result-box sponsor-border"
                      : item.userType === "top"
                        ? "search-result-box top-doc-border"
                        : "search-result-box"
                  }
                >
                  {item.userType === "sponsored" || item.userType === "top" ? (
                    <>
                      <span
                        className={`${
                          item.userType === "sponsored" ? "sponsor" : "top"
                          }-doc-icon`}
                      >
                        {item.userType} Doctor
                        </span>
                    </>
                  ) : (
                      <></>
                    )}

                  {/* doctor profile top */}
                  <div className="row justify-content-between">
                    <div className="col-lg-6 order-1 order-lg-1 profile-mid-border">
                      <div className="doctor-description-wrap">
                        {item.isSavedDoctor == 0 ?
                          <div className="doc-save" onClick={() => saveUnsaveFavDoc(item, 'save')}>
                            <img
                              src="/assets/images/unsave-icon.svg"
                              alt=""
                              className="scale icon-docsave"
                            />
                            Save Doctor
                         </div>
                          :
                          <div className="doc-unsave" onClick={() => saveUnsaveFavDoc(item, 'unsave')}>
                            <img
                              src="/assets/images/save-icon.png"
                              alt=""
                              className="scale icon-docsave"
                            />
                            Unsave Doctor
                        </div>
                        }
                        <div className="doctor-img">
                          <Link to={ProviderLinks.profile + "/" + item.id}>
                            <img
                              src={item.profileImage}
                              alt="Doctor"
                              className="sponsored-docimg"
                            />
                          </Link>
                          {/* <img src="/assets/images/top-doc-img.png" alt="Doctor" className="top-docimg" />
                                                  <img src="/assets/images/dummy-doc-img.png" alt="Doctor" className="other-docimg" /> */}
                        </div>
                        <div className="doctor-description-right">
                          <h5>
                            <Link to={ProviderLinks.profile + "/" + item.id}>
                              {" "}
                              <span>
                                {item.providers && item.providers.firstName
                                  ? item.providers.firstName + " "
                                  : ""}
                                {item.providers && item.providers.middleName
                                  ? item.providers.middleName + " "
                                  : ""}
                                {item.providers && item.providers.lastName
                                  ? item.providers.lastName
                                  : ""}
                                {item.providerMedicalCredientialsValue
                                  ? ", " + item.providerMedicalCredientialsValue
                                  : ""}
                              </span>
                            </Link>
                          </h5>
                          <span className="doc-designation">
                            {item.providerMedicalSpecialitiesValue
                              ? item.providerMedicalSpecialitiesValue
                              : ""}

                          </span>
                          <div className="endorsements">
                            <span>
                              {item.providers && item.totalEndorsementCount
                                ? item.totalEndorsementCount
                                : 0}{" "}
                              Doctor endorsements
                              </span>
                          </div>
                          <div className="rating-result">
                            <span className="rating">
                              {/* <span
                                className={
                                  item && item.totalReviewAvg
                                    ? "rating" + parseFloat(item.totalReviewAvg).toFixed(1).replace(".", '')
                                    : "rating" + 0
                                }
                              ></span> */}
                              <Stars 
                                  rating={item.providers && item.totalReviewAvg ? parseFloat(item.totalReviewAvg).toFixed(1) : 0}
                                  {...item} 
                                  getProfile={props.getProfile}
                                  componentName={props.isCareteam ? 'sponsored_doctor' : 'searchResult'}
                              />
                            </span>
                            <span style={{marginLeft:'8px'}}>
                              (
                                {item.providers && item.totalReviewAvg
                                ? parseFloat(item.totalReviewAvg).toFixed(1)
                                : 0}
                              ){" "}
                              {item && item.totalReviewCount
                                ? item.totalReviewCount
                                : 0}{" "}
                              Reviews
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 order-3 order-lg-2 profile-mid-border px-0">
                      <div className="patient-features">
                        <em>
                          Patient features unavailable.
                            <br />
                          Please sign in as a patient if you would like to book an
                          appointment.
                          </em>
                      </div>
                      {userCateType === "patient" &&
                        item ?.providers &&
                            item ?.providerPlanDetails ?.planId &&
                              (item ?.providerPlanDetails ?.planId === 1 ) && (
                                <div className="scheduling-features">
                                  <em>
                                    The real-time scheduling feature is not available for this
                                    doctor. Please call the number or request an appointment
                                    below to book with this doctor.
                              </em>
                                </div>
                              )}
                      {userCateType === "patient" &&
                        item ?.providers &&
                          item ?.providers ?.allowOnline === 0 &&
                            item ?.providerPlanDetails ?.planId &&
                              (item ?.providerPlanDetails ?.planId === 3 ||
                                item ?.providerPlanDetails ?.planId === 4) && (
                                <div className="scheduling-features">
                                  <em>
                                    The real-time scheduling feature is not available for this
                                    doctor. Please call the number or request an appointment
                                    below to book with this doctor.
                              </em>
                                </div>
                              )}
                      {userCateType === "patient" &&
                        item ?.providers &&
                          item ?.providers ?.allowOnline === 1 &&
                            item ?.providerPlanDetails ?.planId &&
                              item ?.providerPlanDetails ?.planId === 2 && (
                                <div className="scheduling-features">
                                  <em>
                                    The real-time scheduling feature is not available for this
                                    doctor. Please call the number or request an appointment
                                    below to book with this doctor.
                              </em>
                                </div>
                              )}
                      {/* <p>allowOnline: {item?.providers?.allowOnline}</p>
                          <p>planId: {item?.providerPlanDetails?.planId}</p>
                          <p>allowedPatient: {item?.providers?.allowedPatient}</p> */}
                      
                      {userCateType === "patient" &&
                        item ?.providers &&
                          item ?.providers ?.allowOnline === 1 &&
                            item ?.providerPlanDetails ?.planId &&
                              (item ?.providerPlanDetails ?.planId === 3 ||
                                item ?.providerPlanDetails ?.planId === 4 ) &&
                              item ?.providerPracticeLocations.length > 0 &&
                              renderSchedulingTime(
                                item.providerPracticeLocations[0] ?.id,
                                startDate,
                                item
                              )
                          }
                      {userCateType === "patient" &&
                        item ?.providers &&
                          item ?.providers ?.allowOnline === 0 &&
                            item ?.providerPlanDetails ?.planId &&
                              (item ?.providerPlanDetails ?.planId === 3 ||
                                item ?.providerPlanDetails ?.planId === 4) && (
                                <div className="request-appoint">
                                  <input
                                    type="button"
                                    onClick={() => authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.isProfileCompleted && authContext.user.accountInfo.roleId === 4 ?
                                      openRequestModal("reqAppointment", false, item) : openLoginModal()
                                    }
                                    className="next-btn-pro"
                                    value="Request Appointment"
                                  />
                                </div>
                              )
                          }
                    </div>
                    <div className="col-lg-6 order-2 order-lg-3 profile-mid-border-add">
                      <div className="result-address-wrap">
                        <div className="address-left">
                          <span
                            className={
                              item.providers && item.providers.isAcceptingNewPatient
                                ? "tick-icon"
                                : "red-cross-icon"
                            }
                          >
                            Accepting new patients
                            </span>
                          <span
                            className={
                              item.providers && item.providers.hasVirtualVisit
                                ? "video-icon"
                                : "grey-cross-icon"
                            }
                          >
                            Virtual visits available
                            </span>
                          {item.providerPracticeLocations.length > 0 &&
                            item.providerPracticeLocations.map(
                              (pa, pai) =>
                                pai === 0 && (
                                  <span className="map-icon" key={"address-" + pai}>
                                    {pa.practiceName ? pa.practiceName + "\n" : ""}
                                    {pa.practiceAddress
                                      ? pa.practiceAddress + "\n"
                                      : ""}
                                  </span>
                                )
                            )}
                          {item.providerPracticeLocations &&
                            item.providerPracticeLocations.length > 1 ? (
                              <>
                                <div className="see-location-link">
                                  <a href="/#" onClick={handleLocation}>
                                    {seeAllLocation ? "Close" : "See"} All Locations
                                  </a>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                        </div>
                        {item.providerPracticeLocations.length > 0 &&
                          item.providerPracticeLocations.map(
                            (pa, pai) =>
                              pai === 0 && (
                                <div className="address-right-map" key={pai}>
                                  {item.providerPracticePrimaryLocation && item.providerPracticePrimaryLocation.distance ? '≈ ' + parseFloat(item.providerPracticePrimaryLocation.distance).toFixed(2) + ' mi' : ''}
                                  {pa ?.latitude && pa ?.longitude && (
                                    <div className="address-map-box">
                                      {

                                        props.browserAddress && pa.cities && pa.cities.name && pa.states && pa.states.name && pa.zipCode ? (<>
                                          <a target="_blank" onClick={redirectLeavingPage} href={`${baseAddress}${props.browserAddress}/${pa.practiceAddress} ${pa.cities.name} ${pa.states.name} ${pa.zipCode}`} >Direction</a>

                                        </>) : (<>
                                        
                                        </>)
                                      }
                                      <Gmaps
                                        gmapData={[{
                                          lat: parseFloat(pa ?.latitude),
                                          lng: parseFloat(pa ?.longitude),
                                        }]}
                                        zoomLabel={15}
                                        ref={mapPrimayRefs.current[pai]}
                                        width={200}
                                        height={200}
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                          )}
                      </div>
                    </div>
                    <div className="col-lg-6 order-4 order-lg-4">
                      <div className="endorse-link">
                        {item.isEndorsed ? (
                          <>
                            <a
                              style={{
                                cursor: "default",
                                "background-color": "#e4eef3",
                              }}
                              href="/#"
                              onClick={(e) => e.preventDefault()}
                            >
                              <span>Endorsed</span>
                            </a>
                          </>
                        ) : (
                            <>
                              <a href="/#" onClick={(e) => enDorseDoctor(e, item.id)}>
                                <span>Endorse Doctor</span>
                              </a>
                            </>
                          )}

                        <a href="/#" onClick={(e) => askenDorseDoctor(e, item.id)}>
                          Ask Doctor for Endorsement
                          </a>
                      </div>
                      <div className="endorse-link endorse-link-patient">
                        {item ?.providerPracticeLocations[0] ?.phoneNumber && (
                          <a
                            href={`tel:${item ?.providerPracticeLocations[0] ?.phoneNumber}`}
                          >
                            {item.providerPracticeLocations[0] && item.providerPracticeLocations[0].phoneNumber ?
                              Global.phoneFormator(item.providerPracticeLocations[0].phoneNumber) : ''
                            }
                          </a>
                        )}
                        {userCateType === "patient" &&
                          item ?.providers &&
                            item ?.providers ?.allowedPatient === 1 &&
                              item ?.providerPlanDetails ?.planId &&
                                (item ?.providerPlanDetails ?.planId === 2 ||
                                  item ?.providerPlanDetails ?.planId === 3 ||
                                    item ?.providerPlanDetails ?.planId === 4 ) && (
                                  <a href="/#" className={!item.isCallbackRequested ? '' : 'cursor-default'} onClick={(e) => authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.isProfileCompleted && authContext.user.accountInfo.roleId === 4 ?
                                    !item.isCallbackRequested ? requstCallback(e, item.id) : e.preventDefault() : openLoginModal(e)
                                  }
                                  >{!item.isCallbackRequested ? 'Request Callback' : 'Request Sent'}</a>
                                )}

                      </div>
                    </div>
                  </div>
                  {item.providerPracticeLocations.length > 1 &&
                    seeAllLocation &&
                    item.providerPracticeLocations.map(
                      (pa, pai) =>
                        pai !== 0 && (
                          <SecondaryAddress
                            bookAppointment={bookAppointment}
                            item={item}
                            address={pa}
                            ref={mapRefs.current[pai]}
                            key={pa.id}
                            startDate={startDate}
                            browserAddress={browserAddress}
                            baseAddress={baseAddress}
                          />
                        )
                    )}
                </div>
              </div>
            </>
          )
        }
      </Fragment>
    )
  }
  useEffect(() => {
    renderComponent();
  }, [props])
 
  return (
    renderComponent()
  );
};

SponsoredDoc.defaultProps = {
  userCateType: "",
  startDate: "",
  items: null,
  browserAddress: null,

};

export default memo(SponsoredDoc);
