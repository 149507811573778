import React, { Fragment, useState, useContext } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';

const DeleteSpecialityModal = (props) => {

    console.log("DeleteSpecialityModal", props);
    const { viewdata, getList, type, subtype, isdegree, isschool } = props
    const authContext = useContext(AuthContext);
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });

    const deleteRecord = async (id) => {


        let url = "";
        let formData = {}

        if (type == 'Speciality') {
            //  Speciality
            url = "admin/specialities/destroy";
            if (isdegree == 1) {
                //  Medical OR Dental Degree 
                formData = {
                    formType: 'degree',
                    id: id
                }
            }
            if (isdegree == 0) {
                //  Medical OR Dental Speciality 
                formData = {
                    formType: 'speciality',
                    id: id
                }
            }

            if (isschool == 1) {
                url = "admin/schools/destroy";
                // Medical OR Dental School 
                formData = {
                    id: id
                }
            }
        }

        if (type == 'Insurance') {
            url = "admin/insurance/destroy";
            // Insurance
            formData = {
                id: id
            }
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: url,
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        authContext.setLoader();
        try {
            let result = await NetworkLayer.postData(submitData);
            if (result?.body?.status) {
                setmodalProps({
                    type: 'success',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }else{
                setmodalProps({
                    type: 'error',
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
            setTimeout(() => {
                authContext.unsetLoader();
                authContext.hideModal()
                getList();
            }, 2000);
            
        } catch (err) {

        }
    }
    return (
        <Fragment>
            {
                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            <Container>
                <div className="search-modal-wrap speciality-modal-wrap">
                    <div className="edit-all-filter">
                        <Row>
                            <div className="col-12 px-0">
                                <div className="modal-head">Delete {type}</div>
                            </div>
                        </Row>
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12 mb-4">
                                    <label className="pro-label mb-4">Are you sure you want to delete this {type} ?</label>
                                    <input type="text" className="input-pro" defaultValue={viewdata.name} disabled />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12 mb-4 all-fltrbtn">
                                    <input type="button" className="del-btn-pro" value="Delete" onClick={() => { deleteRecord(viewdata.id) }} />
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>

        </Fragment>
    )

}

export default DeleteSpecialityModal;