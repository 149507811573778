import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../linkFile';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import { ReactComponent as BackIcon } from '../../admin/assets/images/back_icon.svg';
import RatingReviewManage from '../../components/admin/rating-review-admin/rating-review-manage';


const RatingManagement = (props) => {
    let ratingID = props.match.params.ratingID;
    const authContext = useContext(AuthContext);
    return (
        <Fragment>
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section"}>
                    <Row className="bg-white-report">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Ratings &amp; Reviews Management</div>
                            </div>
                            <div className="col-12 mt-3">
                                <Link to={AdminLinks.admin_rating_review} className="back-link">
                                    <span className="back-icon"><BackIcon /></span>
                                    Back
                            </Link>
                            </div>

                            {/* Bottom container content */}
                            <RatingReviewManage ratingID={ratingID} />
                        </div>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default RatingManagement;