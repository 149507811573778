import React, { Fragment, useState, useEffect, useContext, lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../reactLayer/Network/Network';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal';
import arrayMove from 'array-move';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const EditFocusArea = (props) => {
    
    const authContext = useContext(AuthContext);
    const [focusedArea, setfocusedArea] = useState(props.medicalInfo && props.medicalInfo.providerMedicalFocusAreas ? props.medicalInfo.providerMedicalFocusAreas : []);
    const [ferror, setferror] = useState(false);
    const [allFarea, setallFarea] = useState([]);
    const [focusAreas,setFocusAreas] = useState([])
    const [focusAreaError,setFocusAreaError] = useState('')
    const [selectedFarea, setselectedFarea] = useState('');
    const [currentFocusArea,setCurrentFocusArea] = useState('')
    const [otherData, setotherData] = useState ({
      area: {
          value: [],
          validation: ['favalue'],
          errorMsg: ['This Field is required'],
          errorKey: 'area'
      },
    })
    const [modalProps, setmodalProps] = useState({
        type:'', 
        msg:'',
        btnname: '',
        redirection: ''
    });
    let id = null;
    const swapItem = (result) => {
        
        let tempData = [];
        if( result.destination && result.source.index !== result.destination.index ) {
            tempData = focusedArea;
            tempData = arrayMove(tempData, result.source.index, result.destination.index);
            setfocusedArea(tempData);
        }
    }
    const deleteData = async() => {
        if( id ) {
            authContext.setLoader();

            const fData = {
                "id": id,
            }
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/focus-area/delete',
                body: JSON.stringify(fData),
                
            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if( result.body.status ) {
                props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
                setmodalProps({
                    type:'success', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                props.setVisibleComponent && props.setVisibleComponent('');
            } else {
                setmodalProps({
                    type:'error', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }
    }
    const askDelete = ( deleteId ) => {
        id = deleteId;
        setmodalProps({
            type:'success', 
            msg: 'Are you sure to delete focus area',
            btnname: 'Yes',
            action: deleteData,
            btncancel: 'No',
            autoclose: 'false'
        });
        authContext.showResponseModal();
    }

    
    const hideData = (e) => {
        console.log(e.currentTarget.value);
        let value = e.currentTarget.value;
        if(value && allFarea.indexOf(value) > '-1') {
            setselectedFarea(value);
        } else {
            setselectedFarea('');
            e.currentTarget.value = '';
        }
    }

    
    const getFocusedArea = async() => {
        const fData = {};
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.token
            },
            url: 'suggested-speciality/get'
            
        }
        let result = await NetworkLayer.getRequest(submitData);
        console.log('focus edit area result', result);
        authContext.unsetLoader();
        if( result.status ) {
            setallFarea(result.result.focusArea);
        } else {
            setallFarea([]);
        }
    }
    const changeFocusArea = (favalue) => {
    //   setErrors({...errors, ...{earea: false}});
        console.log('favalue===>', favalue);
        let temparea,fa,
          totalFA = 10;
        temparea = otherData;
        if( focusedArea ) {
            fa  = focusedArea.length + favalue.length;
        } else {
            fa  = favalue.length;
        }
        if( fa <= 10 ) {
            temparea.area.value = favalue;
        } else {
            temparea['area']['area'] = 'Max 10 can be select';
        }
        let tempOtherData = {...otherData, ...temparea};
        setotherData(tempOtherData);
    }
    const reorderFocusArea = async() => {
        authContext.setLoader();
        let flag = true;
        let af = [];
        if( focusedArea && focusedArea.length ) {
            focusedArea.map((fa , fi) => {
                af.push({focusName: fa.focusName })
            })
        }
        // console.log('check reorder of fa', af);
        // return false;
        if( flag ) {
            const areaOfFocus = {areaOfFocus:af};
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/focus-area/reorder',
                body: JSON.stringify(areaOfFocus),
            }

            let result = await NetworkLayer.postData(submitData);
            console.log('check reorder of fa', result);
            authContext.unsetLoader();
            if( result.body.status ) {
                props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
                setmodalProps({
                    type:'success', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                setTimeout(() => {
                    props.setVisibleComponent && props.setVisibleComponent('');
                }, 3000)
            } else {
                setmodalProps({
                    type:'error', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }
    }
    const addFocusArea = async() => {
        let flag = true;
        if(props.tabvisibleComponent === 'editFocusArea') {

        } else {
            if(focusAreas.length === 0){
                flag = false
                setFocusAreaError('Please add at least one tag')
                return;
            }
        }
        
        if( flag ) {
            authContext.setLoader();
            const areaOfFocus = {areaOfFocus:focusAreas};
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': authContext.token
                },
                url: 'providers/focus-area/update',
                body: JSON.stringify(areaOfFocus),
            }

            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if( result.body.status ) {
                props.getProfile && typeof props.getProfile === 'function' && props.getProfile();
                setmodalProps({
                    type:'success', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
                setTimeout(() => {
                    props.setVisibleComponent && props.setVisibleComponent('');
                }, 3000)
                // props.setVisibleComponent && props.setVisibleComponent('');
            } else {
                setmodalProps({
                    type:'error', 
                    msg: result.body.message,
                });
                authContext.showResponseModal();
            }
        }
    }


    const setFocusArea = (e,type='none') => {
        setFocusAreaError('')
        if(focusAreas.length === 5){
            setFocusAreaError('Max 5 tags are allowed')
            return;
        }
        if(focusAreas.includes(e.target.value)){
            setFocusAreaError('Tag already exist')
            return;
        }
        if(e.keyCode === 13 || type === 'add'){
                setFocusAreas([...focusAreas,e.target.value])
                setCurrentFocusArea('')
        }
    }

    const removeTag = (key) => {
        setFocusAreaError('')
        let focusAreaTemp = JSON.parse(JSON.stringify(focusAreas))
        focusAreaTemp.splice(key,1)
        console.log('focusAreaTemp',focusAreaTemp)
        if(focusAreaTemp.length === 0){
            setFocusAreaError('Please add at least one focus area')
        }
        setFocusAreas(focusAreaTemp)
    }

    useEffect(()=> {
        getFocusedArea();
    },[])
    const suspenseLoader = () => <p></p>;

    return (
        <Suspense fallback={suspenseLoader()}>
            <Fragment>
                <Container>
                    <div className="dashboard-modal-wrap">
                        <Row>
                            {
                                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
                            }
                    
                        </Row>
                        {
                            props.tabvisibleComponent === 'editFocusArea' ? (<>
                                
                                <Row>
                                    <div className="col-12 px-0">  
                                        <div className="modal-head">Reorder or Delete Focus Area</div>                                
                                    </div>
                                </Row>
                                <div className="edit-edu-box"> 
                                    <Row>
                                        <div className="col-12">
                                            <div className="focus-modal-wrap">
                                                <DragDropContext onDragEnd={swapItem}>
                                                    <Droppable droppableId="characters">
                                                        {(provided) => (
                                                            <ul className="focus-area-list" {...provided.droppableProps} ref={provided.innerRef}>
                                                                { focusedArea && focusedArea.map((fa, index) =>
                                                                    <Draggable key={'focusarea-'+index} draggableId={'focusarea-'+index} index={index}>
                                                                        {(provided) => (
                                                                            <li className="row"  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                {fa.focusName}
                                                                                <div className="edit-buttons-wrap">
                                                                                    <div onClick={() => askDelete(fa.id)}><img key={'focusarea-del'+index} src="/assets/images/del-icon.png" alt=""Delete/></div>
                                                                                    <div><img key={'focusarea-swap'+index} src="/assets/images/icon-pop.png" alt=""Icon/></div>
                                                                                </div>
                                                                            </li>
                                                                        )}
                                                                    </Draggable>
                                                                )}
                                                            </ul>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext> 
                                            </div>
                                        </div>
                                    </Row> 
                                    
                                    <Row>
                                        <div className="col-12 text-center mt-1 mt-md-4">
                                            <input type="button" className="next-btn-pro" onClick={reorderFocusArea} value="Save"/>
                                        </div>
                                    </Row>
                                </div>
                            </>) : (<>
                                
                                <div className="edit-edu-box"> 

                                    <Row>
                                        <div className="col-12 px-0">  
                                            <div className="modal-head">Add Focus Areas</div>                                
                                        </div>
                                    </Row>
                                    <Row>
                                    <div className="col-md-12 mb-2">
                                        <div className="focus-modal-wrap">
                                            <label className="pro-label">Add Tags</label>
                                            <input type="text" list="pCare" onKeyUp={(e)=>setFocusArea(e)} onChange={(e)=>setCurrentFocusArea(e.target.value)} value={currentFocusArea} name="pCare" className="input-pro" placeholder="Enter tags" autoComplete="off"/>
                                            <div className="faTags">
                                            {
                                                focusAreas.map((fa,key)=>{
                                                    return(
                                                        <div className="faTagsContent" key={key}>
                                                            {fa}
                                                            <div onClick={()=>removeTag(key)} className="removeFaCode">X</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            </div>
                                            <small className="error-input">{focusAreaError}</small>
                                            <div className="add-subtext">You can add up to 5 focus areas</div>
                                        </div>
                                    </div>
                                        {/*
                                        <div className="col-md-12 mb-2">
                                            <div className="focus-modal-wrap">
                                                <Multiselect
                                                    placeholder={"Example: Primary Care"}
                                                    className={ otherData.area.area ? "input-pro  error-border" : "input-pro"}
                                                    data={allFarea}
                                                    value={otherData.area.value}
                                                    // disabled={["red", "purple"]}
                                                    onChange={value => changeFocusArea(value)}
                                                />
                                                <small className="error-input">{otherData.area.area}</small>
                                                <div className="add-subtext">You can add up to 10 Specialities</div>
                                            </div>
                                        </div> */}                    
                                    
                                        <div className="col-12 mb-2">
                                            <div className="focus-modal-wrap">
                                                <div className="suggest-focus-head">Suggested focus areas based on your profile:</div>
                                                <ul className="focus-area-list focus-area-list-add">
                                                    <li onClick={()=>setFocusArea({target:{value:'Cardiac rehabilitation'}},'add')} style={{cursor:'pointer'}}>Cardiac rehabilitation</li>
                                                    <li onClick={()=>setFocusArea({target:{value:'Cardiovascular and thoracic surgery'}},'add')} style={{cursor:'pointer'}}>Cardiovascular and thoracic surgery</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-12 text-center mt-1 mt-md-4">
                                            <input type="button" onClick={addFocusArea} className="next-btn-pro" value="Save"/>
                                        </div>
                                    </Row>
                                </div>
                            </>)
                        }
                    
                    </div>              
                </Container>
            </Fragment>
        </Suspense>
    )
    
}

export default EditFocusArea;