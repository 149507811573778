import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';


const Cookies = () => {


    return (
        <Fragment>
            <Container fluid className="cookies-wrap">
                <Container>
                    <Row>
                        <div className="col-12">
                            <div className="cookies-body">
                                <p>By continuing to use this website, you are agreeing to DocScheduler's  <span>Privacy Policy and Terms of Use.</span></p>
                                <p><span>Important notice regarding use of cookies:</span> We have updated our Privacy Policy to reflect our use of cookies to collect and process data. By continuing to use this website, you agree to our use of cookies and similar technologies as described in our <span>Privacy Policy</span>.  More about our use of cookies >> </p>
                            </div>
                            <div className="cookies-btn">
                                <input type="button" className="next-btn-pro" value="Accept and hide this message"/>
                            </div>
                        </div>
                    </Row>
                </Container>              
            </Container>
        </Fragment>
    )
    
}

export default Cookies;