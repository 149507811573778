import React, { Component } from 'react'

class Storage extends Component {

    //Storage type could be session/local/cookies 
    setLocalStorage = ( key = '' , value = {}, encoded = true, storageType = 'session', storageTime = '') => {
        if( encoded == true ) {
            // encode value here
        } 
        try {
            return this.setStorageTypeBase(key, JSON.stringify(value), storageType, storageTime);
        } catch ( e ) {
            console.log( 'Fail to call storage function ');
        }
        
    }

    getLocalStorage = ( key = '', encoded = true, storageType = 'session') => {
       
        try {
            let response = this.getStorageTypeBase(key,storageType);
            // console.log('get from local data ', response);
            if( encoded === true ) {
                // encode value here
            } 
            response = JSON.parse(response);
            return response;
            
        } catch ( e ) {
            console.log( 'Fail to call storage function ');
       }

    };

    setStorageTypeBase = (key, value, type ) => {
        switch (type) {
            case 'session':
                try {
                    let response = sessionStorage.setItem(key, value);
                    console.log( 'storageResponse', response);
                    return response;
                } catch ( e ) {
                    console.log( 'Session Storeage fail', e);
                    return false;
                }
            case 'cookies':
                try {
                    let response = sessionStorage.setItem(key, value);
                    console.log( 'storageResponse', response);
                    return response;
                } catch ( e ) {
                    console.log( 'Session Storeage fail', e);
                    return false;
                }
            case 'local':
                try {
                    let response = localStorage.setItem(key, value);
                    return response;
                } catch ( e ) {
                    console.log( 'localStorage fail', e);
                    return false;
                }
        
            default:
                console.log( 'Storage Method not defined');
                return false;
        }
    };
    getStorageTypeBase = (key, type ) => {
        switch (type) {
            case 'session':
                try {
                    let response = sessionStorage.getItem(key);
                    return response;
                } catch ( e ) {
                    console.log( 'Get Session Storeage fail', e);
                    return false;
                };
            case 'cookies':
                try {
                    let response = sessionStorage.getItem(key);
                    return response;
                } catch ( e ) {
                    console.log( 'Get Session Storeage fail', e);
                    return false;
                }
            case 'local':
                try {
                    let response = localStorage.getItem(key);
                    return response;
                } catch( e ) {
                    console.log( 'Get localStorage fail', e);
                    return false;
                }
        
            default:
                console.log( 'Storage Method not defined');
                return false;
        }
    }
    removeLocalStorage = (key, type = 'session' ) => {
        switch (type) {
            case 'session':
                try {
                    let response =  sessionStorage.removeItem(key);
                    console.log( 'storageResponse', response);
                    return response;
                } catch ( e ) {
                    console.log( 'Get Session Storeage fail', e);
                    return false;
                }
            case 'cookies':
                try {
                    let response = localStorage.removeItem(key);
                    console.log( 'Local Storage', response);
                    return response;
                } catch ( e ) {
                    console.log( 'Get localStorage fail', e);
                    return false;
                }
            case 'local':
                try {
                    let response = localStorage.removeItem(key);
                    console.log( 'Local Storage', response);
                    return response;
                } catch ( e ) {
                    console.log( 'Get localStorage fail', e);
                    return false;
                }
        
            default:
                console.log( 'Storage Method not defined');
                return false;
        }
    };
    render() {
        return ''
    }
}
const StorageAdaptation = new Storage();
export default StorageAdaptation;
