import React, { Fragment, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';

const ProviderManagementInfoModal = (props) => {
    const authContext = useContext(AuthContext);
    const [file,setFile] = useState('')
    const [fileName,setFileName] = useState('')
    const [fileError,setFileError] = useState('')
    const [errorFileUrl,setErrorFileUrl] = useState('')
    const importFile = async () => {
        if(file === ''){
            setFileError('Please upload file to import')
        }else{
            authContext.setLoader();
            let formData = new FormData()
            formData.append('providerList',file.target.files[0])
            const submitData = {
                headers: {
                    'access_token': authContext.adminToken
                },
                url: 'providers/import',
                body: formData,
            }
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            if(result.body.status === true){
                if(result.body.result.status === true){
                    props.unsetComponent();
                    props.responseHandler(result.body)
                }else{
                    props.responseHandler(result.body.result)
                    setErrorFileUrl(result.body.result.url)
                }
            }
        }
    }


    return (
        <Fragment>
            <Container>
                <div className="search-modal-wrap provider-info-modal-outer">
                    <div className="edit-all-filter">
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12 px-lg-0">
                                    <div className="provider-info-modal-wrap provider-form-edit">
                                        {/* doctor profile */}
                                            <div className="heading-appointment-req">Import Providers</div>
                                            <form enctype="multipart/form-data" method="post">

                                                <div className="row">
                                                    <div className="col-md-9 mb-3">
                                                        <label className="pro-label">Upload CSV</label>
                                                        <div style={{position:'relative'}}>
                                                            <input type="file" onChange={(e)=>{ setFileName(e.target.value); setFile(e); setFileError(''); }} value={fileName} name="importFile" accept=".csv" className="input-pro" />
                                                            {file ? <div onClick={()=>{ setFile('');setFileName(''); }} style={{position:'absolute',right:'10px',top:'10px',border:'1px solid #297db4',padding:'5px',borderRadius:'5px',color:'#297db4',cursor:'pointer'}}>Remove</div> : ""}
                                                        </div>
                                                        <small className="error-input">{fileError}</small>
                                                    </div>
                                                    {
                                                        errorFileUrl ? 
                                                        <div className="col-md-9 mb-3">
                                                            <a style={{color:'red'}} href={errorFileUrl}>Download Error File</a>
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    <div className="col-md-9 mb-3">
                                                        <a href="https://api.docscheduler.com/providers.csv">Download Sample CSV</a>
                                                    </div>
                                                    <div class="col-12 bottom-btns mt-3">
                                                        <div class="next-prev-btn-pro">
                                                            <input type="button" class="prev-btn-pro" onClick={()=>props.unsetComponent(false)} value="Cancel" />
                                                            <input type="button" class="next-btn-pro" onClick={()=>importFile()} value="Import" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        
                                    </div>
                                </div>
                            </Row>

                        </div>
                    </div>
                </div>
            </Container>
        </Fragment>
    )

}

export default ProviderManagementInfoModal;