import React, { Fragment, useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AuthContext from '../../reactLayer/auth/AuthContext';
import NetworkLayer from '../../reactLayer/Network/Network';

const HealthCalculate = (props) => {
    const authContext = useContext(AuthContext);
    const { toggle } = props;
    const [show, toggleShow] = useState(false);
    const numberRegex = /^[0-9]+$/;
    const [data, setData] = useState({
        bmi: {
            weight: '',
            heightfeet: '',
            heightinch: ''
        },
        restingheartrate: '',
        sleep: '',
        tobacco: 'No'
    });

    const [bmiscale, setBmiScale] = useState(0.5);
    const [restingHeartRatescale, setRestingHeartRateScale] = useState(0.8);
    const [sleepscale, setSleepScale] = useState(0.8);
    const [tobaccoscale, setTobaccoScale] = useState(1.4);
    const [totalScore, setTotalScore] = useState(0);
    const [grade, setGrade] = useState("?");
    const [bmi, setBmi] = useState(0);
    const [error, seterror] = useState({
        ebmi: false,
        eheartbeat: false,
        esleep: false
    })

    const onChangeValues = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == "weight") {
            if((!numberRegex.test(value) || value <=0 ) && value ) {
                return false;
            }
            setData({
                ...data, bmi: {
                    weight: value,
                    heightfeet: data.bmi.heightfeet,
                    heightinch: data.bmi.heightinch
                }
            })
            seterror({...error, ...{ebmi: false}})
        }
        if (name == "heightfeet") {
            if((!numberRegex.test(value) || value <=0 ) && value ) {
                return false;
            }
            setData({
                ...data, bmi: {
                    weight: data.bmi.weight,
                    heightfeet: value,
                    heightinch: data.bmi.heightinch
                }
            })
            seterror({...error, ...{ebmi: false}})

        }
        if (name == "heightinch") {
            if((!numberRegex.test(value) || value <=0 ) && value ) {
                return false;
            }
            setData({
                ...data, bmi: {
                    weight: data.bmi.weight,
                    heightfeet: data.bmi.heightfeet,
                    heightinch: value
                }
            })
            seterror({...error, ...{ebmi: false}})

        }

        if (name == "restingheartrate") {
            if((!numberRegex.test(value) || value <=0 ) && value ) {
                return false;
            }
            setData({
                ...data, restingheartrate: value
            })
            seterror({...error, ...{eheartbeat: false}})

        }
        if (name == "sleep") {
            if((!numberRegex.test(value) || value <=0 ) && value ) {
                return false;
            }
            setData({
                ...data, sleep: value
            })
            seterror({...error, ...{esleep: false}})

        }
        if (name == "tobacco") {
            setData({
                ...data, tobacco: value
            })
        }

    }

    const calculateBMI = () => {
        // Convert LBS TO KG
        let weightvalue = data.bmi.weight / 2.205
        // Convert ft to meter
        let inchtofeet = data.bmi.heightinch / 12;

        let totalHeightInFoot = parseFloat(parseFloat(data.bmi.heightfeet) + parseFloat(inchtofeet)).toFixed(2);
        let heightvalue = totalHeightInFoot / 3.281;

        // Calculate BMI ( weight / height^2 )
        let totalHeightValue = parseFloat(heightvalue) * parseFloat(heightvalue);
        let bmivalue = parseFloat(weightvalue / totalHeightValue).toFixed(2)

        setBmi(bmivalue)

    }

    const calculateScore = () => {
        // BMI SCORE
        if (bmi > 0 && bmi <= 18.49) {
            setBmiScale(0.5);
        } else if (bmi > 18.5 && bmi <= 24.9) {
            setBmiScale(1);
        } else if (bmi > 25 && bmi <= 29.9) {
            setBmiScale(0.5);
        } else if (bmi > 30 && bmi <= 39.9) {
            setBmiScale(0.25);
        } else if (bmi > 40 && bmi <= 100) {
            setBmiScale(-0.75);
        }

        // Resting Heart Rate SCORE
        if (data.restingheartrate > 42 && data.restingheartrate <= 64) {
            setRestingHeartRateScale(0.8);
        } else if (data.restingheartrate > 65 && data.restingheartrate <= 73) {
            setRestingHeartRateScale(0.6);
        } else if (data.restingheartrate > 74 && data.restingheartrate <= 77) {
            setRestingHeartRateScale(0.4);
        } else if (data.restingheartrate > 78 && data.restingheartrate <= 84) {
            setRestingHeartRateScale(0.2);
        } else if (data.restingheartrate > 85 && data.restingheartrate <= 100) {
            setRestingHeartRateScale(-0.4);
        }

        // Sleep SCORE
        if (data.sleep > 8 && data.sleep <= 10) {
            setSleepScale(0.8);
        } else if (data.sleep > 6 && data.sleep <= 7) {
            setSleepScale(0.4);
        } else if (data.sleep > 3 && data.sleep <= 5) {
            setSleepScale(0);
        } else if (data.sleep > 1 && data.sleep <= 2) {
            setSleepScale(-0.8);
        }

        // Tobacco SCORE
        if (data.tobacco == "Yes" || data.tobacco == "yes") {
            setTobaccoScale(-1.4);
        } else if (data.tobacco == "No" || data.tobacco == "no") {
            setTobaccoScale(1.4);
        }

    }

    const getGrades = () => {
        if (totalScore == 4 || totalScore == 3.9) {
            setGrade("A+");
        } else if (totalScore == 3.8 || totalScore == 3.7 || totalScore == 3.6) {
            setGrade("A");
        } else if (totalScore == 3.5 || totalScore == 3.4) {
            setGrade("A-");
        } else if (totalScore == 3.1 || totalScore == 3.2 || totalScore == 3.3) {
            setGrade("B+");
        } else if (totalScore == 2.8 || totalScore == 2.9 || totalScore == 3) {
            setGrade("B");
        } else if (totalScore == 2.4 || totalScore == 2.5 || totalScore == 2.6 || totalScore == 2.7) {
            setGrade("B-");
        } else if (totalScore == 2.1 || totalScore == 2.2 || totalScore == 2.3) {
            setGrade("C+");
        } else if (totalScore == 1.8 || totalScore == 1.9 || totalScore == 2) {
            setGrade("C");
        } else if (totalScore == 1.4 || totalScore == 1.5 || totalScore == 1.6 || totalScore == 1.7) {
            setGrade("C-");
        } else if (totalScore == 1.3) {
            setGrade("D+");
        } else if (totalScore == 1 || totalScore == 1.1 || totalScore == 1.2) {
            setGrade("D");
        } else {
            setGrade("F");
        }
    }

    useEffect(() => {
        if (data) {
            calculateBMI();
        }
        if (bmi) {
            calculateScore();
        }
    }, [data])

    useEffect(() => {
        authContext.sethealthGradeResult();
    }, [])

    useEffect(() => {
        let total = parseFloat(bmiscale + restingHeartRatescale + sleepscale + tobaccoscale).toFixed(1)
        setTotalScore(total);
        getGrades(total);
    }, [bmiscale, restingHeartRatescale, sleepscale, tobaccoscale])

    const saveHealthGrade = async () => {
        authContext.setLoader();

        // FORM DATA
        let tempError = error;
        const formData = {
            healthGrade: grade,
            BMI: bmi,
            BPM: data.restingheartrate,
            sleep_per_night: data.sleep,
            tobacco_use: data.tobacco == "Yes" ? 1 : 0,
            blood_pressure: 110,
            cholesterol_levels: 180
        }
        
        let flag = true;
        if( !bmi || bmi == 'NaN') {
            
            tempError.ebmi = 'Please fill body mass index';
            flag && document.querySelectorAll(`[name="weight"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
            flag = false;
        }
        if( !data.restingheartrate ) {
            tempError.eheartbeat = 'Please fill resting heart rate';
            flag && document.querySelectorAll(`[name="restingheartrate"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
            flag = false;
        }
        if( !data.sleep ) {
            tempError.esleep = 'Please fill hours of sleep';
            flag && document.querySelectorAll(`[name="sleep"]`)[0].scrollIntoView({ block: "start", behavior: "smooth" });
            flag = false;
        }
        // STRUCTURE LOGIN REQUEST DATA
        if( flag ) {
            const submitData = {
                headers: {
                    'Content-Type': 'application/json',
                    "access_token": authContext.token
                },
                url: 'patients/health-grades/add',
                body: JSON.stringify(formData),
            }
            // SEND REQUEST
            let result = await NetworkLayer.postData(submitData);
            authContext.unsetLoader();
            return result.body.status;
        } else {
            seterror({...tempError});
            authContext.unsetLoader();
            return false;
        }
        
    }

    const showResults = () => {
        let submitData = {
            data: data,
            bmiscale: bmiscale,
            restingHeartRatescale: restingHeartRatescale,
            sleepscale: sleepscale,
            tobaccoscale: tobaccoscale,
            totalScore: totalScore,
            grade: grade,
            bmi: bmi
        }

        saveHealthGrade().then((res) => {
            if( res ) {
                authContext.sethealthGradeResult(submitData);
                toggle(true);   
            }
            
        });

    }

    return (
        <Fragment>
            {/* Calculator part */}
            <div className="health-grade-left">
                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Body Mass Index (BMI)
                <span className="info-hg">
                            <div className="info-icon"></div>
                            <div className="info-callout"><strong>Body Mass Index (BMI) -</strong> BMI is a measure of your weight relative to your height. It gives an approximation of total body fat. Abnormal total body fat increases your risk of diseases that are related to being under or overweight.</div>
                        </span>
                    </div>
                    <div className="health-grade-field">
                        <div>
                            <span>Weight</span>
                            <input type="text" name="weight" className="input-pro" value={data.bmi.weight} autoComplete="off" onChange={(e) => onChangeValues(e)} />
                            <small>lb</small>
                        </div>
                        <div>
                            <span>Height</span>
                            <input type="text" name="heightfeet" className="input-pro" value={data.bmi.heightfeet} autoComplete="off" onChange={(e) => onChangeValues(e)} />
                            <small>ft</small>
                        </div>
                        <div>
                            <input type="text" name="heightinch" className="input-pro" value={data.bmi.heightinch} autoComplete="off" onChange={(e) => onChangeValues(e)} />
                            <small>in</small>
                        </div>
                    </div>
                    <small class="error-input">{error.ebmi}</small>
                </div>

                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Resting Heart Rate
                <span className="info-hg">
                            <div className="info-icon"></div>
                            <div className="info-callout"><strong>Resting Heart Rate -</strong> Resting Heart Rate, or pulse, measures the number of times your heart beats per minute. An abnormal resting heart rate may identify an increased level of health related risk--heart attack, mental stress, in-activeness, etc.</div>
                        </span>
                    </div>
                    <div className="health-grade-field">
                        <div>
                            <span>Heart beats per minute</span>
                            <input type="text" name="restingheartrate" className="input-pro" value={data.restingheartrate} onChange={(e) => onChangeValues(e)} autoComplete="off" />
                        </div>
                    </div>
                    <small class="error-input">{error.eheartbeat}</small>
                </div>

                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Hours of Sleep Per Day
                <span className="info-hg">
                            <div className="info-icon"></div>
                            <div className="info-callout"><strong>Hours of Sleep Per Day -</strong> Hours of sleep per day measures how many hours you sleep per night.
Too little sleep can cause mood swings, memory lapses, weight gain, increase blood pressure, and increase the risk of heart disease.</div>
                        </span>
                    </div>
                    <div className="health-grade-field">
                        <div>
                            <span>Average hours per night</span>
                            <input type="text" name="sleep" value={data.sleep} className="input-pro" onChange={(e) => onChangeValues(e)} autoComplete="off" />
                        </div>
                    </div>
                    <small class="error-input">{error.esleep}</small>
                </div>

                <div className="health-grade-field-outer">
                    <div className="calc-heading">
                        Cigarette/Tobacco Use
                <span className="info-hg">
                            <div className="info-icon"></div>
                            <div className="info-callout"><strong>Cigarette/Tobacco Use -</strong> Cigarette/Tobacco use measures your use of cigarette and tobacco use. Cigarettes and tobacco can increase your risk of heart disease, stroke, blindness, lung disease, etc., and can reduce blood flow and lung capacity.</div>
                        </span>
                    </div>
                    <div className="health-grade-field">
                        <div class="myform">
                            <span>Yes/No</span>
                            {/* <input type="text" name="tobacco" value={data.tobacco} className="input-pro" defaultValue="No" onChange={(e) => onChangeValues(e)} autoComplete="off" /> */}
                            <select name="tobacco" value={data.tobacco} className="input-pro select" onChange={(e) => onChangeValues(e)} >
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                            
                        </div>
                    </div>
                </div>

                {show && <div>
                    <div className="health-grade-field-outer">
                        <div className="calc-heading">
                            Blood Pressure
                <span className="info-hg">
                                <div className="info-icon"></div>
                                <div className="info-callout"><strong>Blood Pressure -</strong> Blood pressure measures components of blood pressure. The upper number, called Systolic measures the pressure in your blood vessels when your heart beats. The lower number, called diastolic blood pressure, measures the pressure in your blood vessels when your heart rests between beats. High blood pressure increases your risk of stroke, heart attack, and kidney disease.</div>
                            </span>
                        </div>
                        <div className="health-grade-field">
                            <div>
                                <span>Upper #</span>
                                <input type="text" name="Weight" className="input-pro" defaultValue="244" autoComplete="off" />
                                <small>SBP</small>
                            </div>
                            <div>
                                <span>Lower #</span>
                                <input type="text" name="Height" className="input-pro" defaultValue="63" autoComplete="off" />
                                <small>DBP</small>
                            </div>
                        </div>
                    </div>

                    <div className="health-grade-field-outer">
                        <div className="calc-heading">
                            Cholesterol Levels
                <span className="info-hg">
                                <div className="info-icon"></div>
                                <div className="info-callout"><strong>Cholesterol Levels -</strong> A cholesterol test measures three types of "fats in the blood." Total cholesterol (TC), a measure of the total amount of cholesterol in the blood, and high density lipoprotein (HDL) cholesterol (referred to as the "good cholesterol") as well as glucose (GLU), a measure of blood sugar. High cholesterol can limit blood flow, increasing risk of heart attack or stroke.</div>
                            </span>
                        </div>
                        <div className="health-grade-field">
                            <div>
                                <span>TC</span>
                                <input type="text" name="Weight" className="input-pro" defaultValue="244" autoComplete="off" />
                            </div>
                            <div>
                                <span>HDL</span>
                                <input type="text" name="Height" className="input-pro" defaultValue="63" autoComplete="off" />
                            </div>
                            <div>
                                <span>GLU</span>
                                <input type="text" name="inch" className="input-pro" defaultValue="85" autoComplete="off" />
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="hg-calc-button">
                    <input type="button" className="next-btn-pro" value="Calculate" onClick={() => showResults()} />
                    {/* <span className="switch-button-hg" onClick={() => toggleShow(!show)}>{show ? 'Basic Health Grade Calculator' : 'Advanced Health Grade Calculator'}</span> */}
                </div>
            </div>

        </Fragment>
    );
}

export default HealthCalculate;