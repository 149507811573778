import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Custommodal from '../../../global/modal/modal';
import ManagementFilter from './filter';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import PatientAppointmentModal from './patient-appointment-modal';

const SavedDoctor = (props) => {
    // console.log('SavedDoctor===>',props);
    const authContext = useContext(AuthContext);
    const [data, setData] = useState([]);
    const modalList = ['appointModal'];
    const [filter, setfilter] = useState({
        offset: 0,
        limit: 10
    });

    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [appointmentDetails, setAppointmentDetails] = useState();
    const [sort, setsort] = useState('DESC');
    const [sortfield, setSortName] = useState('id');

    const openProfileTabModal = (modalName, value) => {
        setAppointmentDetails(value);
        settabvisibleComponent(modalName)
        authContext.showModal()
    }

    const getList = async (value) => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (value) {
            formData = {
                id: props.data ?.patientInfo ?.accountInfo ?.id,
                sortOrder: sort,
                sortBy: sortfield,
                offset: filter.offset,
                limit: filter.limit,
                searchValue: value
            }
        } else {
            formData = {
                id: props.data ?.patientInfo ?.accountInfo ?.id,
                sortOrder: sort,
                sortBy: sortfield,
                offset: filter.offset,
                limit: filter.limit
            }
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/patientFavouriteDoctors',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        // console.log("result==>", result.body.result);
        setData(result ?.body ?.result ?.rows);
        authContext.unsetLoader();
    }

    const searchFilter = (value) => {
        console.log(value);
        getList(value);
    }


    const deleteProvider = async (patientId, doctorId) => {

        // FORM DATA 
        const formData = {
            userId: patientId,
            toUser: doctorId
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/patientFavouriteDoctors/delete',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        if (window.confirm('Are you sure you want to delete this provider?')) {
            authContext.setLoader();
            try {
                let result = await NetworkLayer.postData(submitData);
                if (result.body.status) {
                    getList();
                }
            } catch (err) {

            }
            authContext.unsetLoader();
        }


    }

    const changeSorting = (e) => {
        let name = e.currentTarget.getAttribute('name');
        setSortName(name);
        setsort(sort == "ASC" ? "DESC" : "ASC")
    };

    useEffect(() => {
        console.log("SavedDoctor===>")
        getList();
    }, [sort])

    return (
        <Fragment>
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'appointModal' && appointmentDetails ? <PatientAppointmentModal data={appointmentDetails} getList={getList} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}

            <ManagementFilter search={searchFilter} />
            {/* table start here */}
            <div className="table-report-scroll mt-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="data-sort">
                                <span className="sort-outer">
                                    Provider Name
                                <span className="data-sort-icon" name="Name" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Specialty</th>
                            <th>Contact No.</th>
                            <th>Location</th>
                            <th className="data-sort" style={{ minWidth: '220px' }}>
                                <span className="sort-outer">
                                    Last Appt. Request Date
                                <span className="data-sort-icon" name="appointmentDate" onClick={changeSorting}><DataSortIcon /></span>
                                </span>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !data &&
                            <tr>
                                <td>No Such Saved Doctors Found</td>
                            </tr>
                        }
                        {
                            data.length == 0 &&
                            <tr>
                                <td>No Such Saved Doctors Found</td>
                            </tr>
                        }
                        {
                            data && data.map((value, index) =>
                                <tr key={index}>
                                    <td><Link>{`${value.providerPracticeLocations ?.users ?.providers ?.firstName} ${value.providerPracticeLocations ?.users ?.providers ?.lastName}`}</Link></td>
                                    <td>{value.toUserDetails ?.providerMedicalSpecialities[0] ?.specialties ?.name}</td>
                                    <td>{value.providerPracticeLocations ?.users ?.providers ?.phoneNumber}</td>
                                    <td>{`${value ?.providerPracticeLocations ?.cities ?.name}, ${value ?.providerPracticeLocations ?.states ?.code}`}</td>
                                    <td>{value ?.providerPracticeLocations ?.lastAppointmentDate}</td>
                                    <td>
                                        <div className="bookbtn-wrap">
                                            {
                                                (value ?.providerPracticeLocations ?.users ?.userCurrentPlans ?.planPurchaseDetails ?.planId == 3
                                                    ||
                                                    value ?.providerPracticeLocations ?.users ?.userCurrentPlans ?.planPurchaseDetails ?.planId == 4)
                                                    &&
                                                    value ?.providerPracticeLocations ?.users ?.providers ?.allowOnline === 1 &&
                                                        <Link className="bookbtn" onClick={() => { openProfileTabModal("appointModal", value) }}>Book Appointment</Link>
                                            }
                                            {
                                                (value ?.providerPracticeLocations ?.users ?.userCurrentPlans ?.planPurchaseDetails ?.planId == 3
                                                    ||
                                                    value ?.providerPracticeLocations ?.users ?.userCurrentPlans ?.planPurchaseDetails ?.planId == 4)
                                                    &&
                                                    value ?.providerPracticeLocations ?.users ?.providers ?.allowOnline === 0 &&
                                                        <Link className="bookbtn" onClick={() => { openProfileTabModal("appointModal", value) }}>Request Callback</Link>
                                            }
                                            <Link className="delicon"><img src="/assets/images/delete-icon.svg" alt="Delete" onClick={() => { deleteProvider(value.fromUser, value.toUser) }} /></Link>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

        </Fragment>
    );
}

export default SavedDoctor;