import { Component } from 'react';
import StorageAdaptation from '../storage/storage';
import { ProviderLinks, PatientLinks } from '../../linkFile';


class Network extends Component {
  constructor(props) {
    super(props);
    this.ajaxUrl = 'https://api.docscheduler.com/api/v1/';
    this.requestList = [];
    this.getRequestConfig = {
      url: '',
      data: '',
      type: 'GET',

    };
    this.postRequestConfig = {
      url: '',
      method: 'POST',
      cache: 'no-cache',
      body: {},
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },

    };
    // console.log('on network page props', this);
  }

  postData = async (postReqData, context = null) => {
    let config = { ...this.postRequestConfig, ...postReqData };
    // console.log( 'configPostRequest', this.contextType );
    let postUrl;

    // console.log( 'requestList', this.requestList );

    // Modify/concatinate the URL
    if (config.fullUrl && config.fullUrl !== '') {
      postUrl = config.fullUrl;
      config.fullUrl && delete config.fullUrl;
    } else {
      postUrl = this.ajaxUrl + config.url;
    }
    // Modify/mange formdate in multipart/form-data
    const formData = config.data;

    // let data = {};
    // if (Object.keys(formData).length > 0) {
    //   data = new FormData();
    //   for (var key of Object.keys(formData)) {
    //     data.append(key, formData[key]);
    //   }
    // }



    config.url && delete config.url;
    // console.log('postCO', config);
    let response = await fetch(postUrl, config);
    let body,
      headers = {};
    for (let [key, value] of response.headers) {
      headers[key] = value;
    }
    body = await response.json();

    if (body.code == 413) {
      let userData = StorageAdaptation.getLocalStorage("user", false, "local");
      if (userData && userData.accountInfo && userData.accountInfo.roleId && userData.accountInfo.roleId === 4) {
        StorageAdaptation.setLocalStorage("tokenExpire", "patient", "false", "session");
        StorageAdaptation.removeLocalStorage("user", "local");
        StorageAdaptation.removeLocalStorage("token", "local");
        window.location.href = ProviderLinks.home;

      } else if (userData && userData.accountInfo && userData.accountInfo.roleId && userData.accountInfo.roleId === 3) {
        StorageAdaptation.setLocalStorage("tokenExpire", "provider", "false", "session");
        StorageAdaptation.removeLocalStorage("user", "local");
        StorageAdaptation.removeLocalStorage("token", "local");
        window.location.href = ProviderLinks.login;
      } else {
        StorageAdaptation.setLocalStorage("tokenExpire", "patient", "false", "session");
        StorageAdaptation.removeLocalStorage("user", "local");
        StorageAdaptation.removeLocalStorage("token", "local");
        window.location.href = ProviderLinks.home;
      }
    }
    return { headers: headers, body: body };


  };

  getRequest = async (getReqData) => {

    let config = { ...this.getRequestConfig, ...getReqData };
    // console.log( 'configGetRequest', config );

    const { url } = config;

    let getUrl;

    if (config.fullUrl && config.fullUrl !== '') {
      getUrl = config.fullUrl;
    } else {
      getUrl = this.ajaxUrl + url;
    }

    let response = await fetch(getUrl, config);
    response = await response.json();
    if (response.code == 413 && url != 'admin/verify/') {
      let userData = StorageAdaptation.getLocalStorage("user", false, "local");
      if (userData && userData.accountInfo && userData.accountInfo.roleId && userData.accountInfo.roleId === 4) {
        StorageAdaptation.setLocalStorage("tokenExpire", "patient", "false", "session");
        StorageAdaptation.removeLocalStorage("user", "local");
        StorageAdaptation.removeLocalStorage("token", "local");
        window.location.href = ProviderLinks.home;
      } else if (userData && userData.accountInfo && userData.accountInfo.roleId && userData.accountInfo.roleId === 3) {
        StorageAdaptation.setLocalStorage("tokenExpire", "provider", "false", "session");
        StorageAdaptation.removeLocalStorage("user", "local");
        StorageAdaptation.removeLocalStorage("token", "local");
        window.location.href = ProviderLinks.login;
      } else {
        StorageAdaptation.setLocalStorage("tokenExpire", "patient", "false", "session");
        StorageAdaptation.removeLocalStorage("user", "local");
        StorageAdaptation.removeLocalStorage("token", "local");
        window.location.href = ProviderLinks.home;
      }
    }
    return response;
  };

  render() {
    return '';
  }
}

const NetworkLayer = new Network();
export default NetworkLayer;
