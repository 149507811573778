import React, { Fragment, useState, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg';
import { CSVLink } from "react-csv";
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';



const InsuranceExport = (props) => {
    console.log(props);
    const { type, subtype, isdegree, isschool } = props
    const authContext = useContext(AuthContext);
    const [data, setData] = useState("");
    const [selectsubtype, setSelectsubtype] = useState(props.subtype);
    const csvLinkEl = useRef();
    const [header, setHeader] = useState([{ label: "", key: "name" }]);

    let text = "Insurance";

    if (type == 'Speciality') {

        text = "Speciality";

        if (isdegree == 1) {

            text = "Degree";
        }

        if (isschool == 1) {
            text = "School";
        }
    }

    const getExportList = async () => {

        let url = "";
        let formData = {}

        if (type == 'Speciality') {
            // Add Speciality

            if (isdegree == 1) {
                url = "admin/degree/export";
                // Add Medical OR Dental Degree 
                formData = {
                    category: selectsubtype,
                }
                setHeader([{ label: "id", key: "id" }, { label: selectsubtype + " Degree List", key: "name" }]);
            }
            if (isdegree == 0) {
                url = "admin/specialities/export";
                // Add Medical OR Dental Speciality 
                formData = {
                    category: selectsubtype,
                }
                setHeader([{ label: "id", key: "id" }, { label: selectsubtype + " Specialities List", key: "name" }]);
            }

            if (isschool == 1) {
                url = "admin/schools/export";
                // Add Medical OR Dental Speciality 
                formData = {
                    category: selectsubtype,
                }
                setHeader([{ label: "id", key: "id" }, { label: selectsubtype + " School List", key: "name" }]);
            }
        }

        if (type == 'Insurance') {
            url = "admin/insurance/export";
            // Add Insurance
            formData = { category: selectsubtype }
            setHeader([{ label: "id", key: "id" }, { label: selectsubtype + " Insurance List", key: "name" }]);
        }

        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: url,
            body: JSON.stringify(formData),
        }
        // console.log(submitData);
        // return false;

        authContext.setLoader();
        try {
            console.log('exporting')
            let result = await NetworkLayer.postData(submitData);
            setData(result.body.result);
            authContext.hideModal()
        } catch (err) {

        }
        authContext.unsetLoader();
    }
    const downloadReport = async () => {
        let result = await getExportList();
        console.log(csvLinkEl.current.link);
        csvLinkEl.current.link.click();
    }

    const closeModal = () => {
        authContext.hideModal()
    }
    return (
        <Fragment>
            <Container>
                <div className="search-modal-wrap">
                    <div className="edit-all-filter">
                        <Row>
                            <div className="col-12 px-0">
                                <div className="modal-head">Export {type}</div>
                            </div>
                        </Row>
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12">
                                    <div className="rating-export-wrap provider-form-edit">
                                        <div className="rating-export-inner select-option">
                                            <label className="pro-label">{text} Type</label>
                                            <select className="select" onChange={(e) => { setSelectsubtype(e.target.value) }}>
                                                <option value="Medical">Medical</option>
                                                <option value="Dental" selected={subtype == 'Dental'}>Dental</option>
                                            </select>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                        </div>
                                        <Row>
                                            <div className="col-12 mb-4 all-fltrbtn">
                                                <input type="button" className="rev-btn-pro" value="Cancel" onClick={closeModal} />
                                                <input type="button" className="next-btn-pro" value="Submit" onClick={downloadReport} />
                                                <CSVLink
                                                    headers={header}
                                                    filename={`${selectsubtype}-${type}-list.csv`}
                                                    data={data}
                                                    ref={csvLinkEl}
                                                />
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </Fragment>
    )

}

export default InsuranceExport;