import React, { Fragment, useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import Global from '../../reactLayer/global';
import AuthContext from '../../reactLayer/auth/AuthContext';
import Custommodal from '../../global/modal/modal';

import DoctorReviewModal from '../../components/doctor-review-modal/doctor-review-modal';


const Ratingandreview = (props) => {

    const authContext = useContext(AuthContext);
    const reviews = props.ratingReviews ? props.ratingReviews : [];
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [allReviews, setallReviews] = useState(reviews);
    const [source, setsource] = useState('');
    const [rating, setrating] = useState('');
    const modalList = ['reviewModal']; 
    

    const openreviewModal = (e) => {
        e.preventDefault(e);
        openProfileTabModal('reviewModal');
    }
    const openProfileTabModal = (modalName)=>{
        settabvisibleComponent(modalName)
        authContext.showModal()
    }
    const getProfile = () => {
        authContext.hideModal()
        settabvisibleComponent('');
        props.getProfile && props.getProfile();

    }
    const dateFormat = (date) => {
        let result = Global.getYearMonthDate(new Date(date), true) ;
        if( result) {
            return `${result.month}/${result.date}/${result.year}`;
        } else {
            return '';
        }
    }
    const changeSourceRating = (e, type) => {
        let value = e.currentTarget.value;
        if( type === 'source') {
            setsource(value);
        } else {
            setrating(value);
        }
        changeReviewData();
    }
    const changeReviewData = () => {
        let review = [];
        console.log('reviews--->', reviews);
        review =  reviews.filter((r) => {
            if( source !== '' && rating !== '') {
                if(r.source.toLowerCase() ==  source.toLowerCase() && r.rating == rating) {
                    return true;
                } else {
                    return false;
                }
            } else if( source !== '' ) {
                if(r.source.toLowerCase() ==  source.toLowerCase()) {
                    return true;
                } else {
                    return false;
                }
            } else if( rating !== '' ) {
                if(r.rating == rating) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        })
        setallReviews(review);
    }
    

    useEffect(() => {
       changeReviewData();
    }, [source, rating, reviews,props])

    return (
        <Fragment>
            <Container>
                <Row>
                    { props.profile && props.profile.accountInfo && props.profile.accountInfo.id && modalList.indexOf(tabvisibleComponent) > '-1' ? (
                        <Custommodal unsetComponent={settabvisibleComponent} componentName={
                            tabvisibleComponent === 'reviewModal' ? <DoctorReviewModal {...props.profile} getProfile={props.getProfile} /> 
                            :
                            <></>
                        } />
                    ) : (
                        <></>
                    )}
                    
                    <div className="col-12">
                        <div className="profile-review-box">
                            <Row>
                                <div className="col-lg-5 pl-0">
                                    <h5>Reviews</h5>
                                </div>
                                {
                                    authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId === 3 ? (<>
                                        <div className="review-filter-selection col-lg-7 px-0 myform">
                                            <div className="source">
                                                <label className="pro-label">Source</label>
                                                <select onChange={(e)=>changeSourceRating(e, 'source')}>
                                                    <option value="">All</option>
                                                    {
                                                        props.sourceList && props.sourceList.map((m) => 
                                                            <option key={m} value={m}>{`${m[0].toUpperCase()}${m.slice(1)}`}</option>
                                                        )
                                                    }
                                                    
                                                </select>
                                            </div>
                                            <div className="rating-filter">
                                                <label className="pro-label">Ratings</label>
                                                <select onChange={(e)=>changeSourceRating(e, 'rating')}>
                                                    <option value="">All</option>
                                                    <option value="5.0">5</option>
                                                    <option value="4.0">4</option>
                                                    <option value="3.0">3</option>
                                                    <option value="2.0">2</option>
                                                    <option value="1.0">1</option>
                                                </select>
                                            </div>
                                        </div>
                                    </>) : (<>
                                    </>)
                                }
                            </Row>
                            {
                                authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.roleId === 4 ? (<>
                                    <div className="write-review review-enable"><a href="/#" onClick={(e) => openreviewModal(e)}><span>Write a Review</span></a></div>    
                                </>) : (<></>)
                            }
                            {
                                allReviews && allReviews.length ? (<>
                                    <div className="review-text-container">
                                        <ul>
                                            {
                                                allReviews.map((review , index) => 
                                                    <li>
                                                        <div>
                                                            {review.patientsInfo && review.patientsInfo.patientName && review.patientsInfo.patientName.firstName ? review.patientsInfo.patientName.firstName + ' ' : ''} 
                                                            {review.patientsInfo && review.patientsInfo.patientName && review.patientsInfo.patientName.middleName ? review.patientsInfo.patientName.middleName + ' ' : ''} 
                                                            {review.patientsInfo && review.patientsInfo.patientName && review.patientsInfo.patientName.lastName ? review.patientsInfo.patientName.lastName : ''} 
                                                            
                                                        </div>
                                                        <div className="rating-result">
                                                            <span className="rating view"><span className={'rating'+review.rating.replace('.', '')}></span></span>
                                                            {review.createdAt ? dateFormat(review.createdAt) : ''}
                                                        </div>
                                                        <div className="rating-source capitalize">Source: {review.source ? review.source : 'N/A' }</div>
                                                        <div>{review.message ? review.message : '' }</div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </>) : (<>
                                    No Reviews Available
                                </>)
                            }
                        </div>
                    </div>
                </Row>
            </Container>
        </Fragment>
    )
    
}

export default Ratingandreview;