import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';

import HomeHeader from '../../global/header/header';
import Footer from '../../global/footer/footer';
import Accordion from '../../global/accordion/accordion';
import Freeprofile from '../../components/free-profile-component/free-profile-component';
import Youtubevideo from '../../components/youtube-video/youtube-video';
import { ProviderLinks, PatientLinks } from '../../linkFile';
import AuthContext from '../../reactLayer/auth/AuthContext';





const FreeProfile = (props) => {

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const user = authContext.user ? authContext.user : {};
  const redirectToCreatAccount = (e) => {
    e && e.preventDefault();
    history.push( {
        pathname: ProviderLinks.register_setp1,
    });
  }

  const RedirectToDashboard = () => {
    if( user.accountInfo && user.accountInfo.isProfileCompleted && user.accountInfo.roleId && user.accountInfo.roleId == '3') {
      history.push( {
          pathname: ProviderLinks.dashboard,
      });
    }
  }

  const commentSection = useRef();
  const howWorks = useRef();
  const gotoCommentSection = (section)=>
  window.scrollTo({
    top:section,
    behavior: "smooth"
  });

  useEffect(()=>{
    if( props.location && props.location.query && props.location.query === 'howWorks') {
      gotoCommentSection(howWorks.current.offsetTop);
    } else if(props.location && props.location.query && props.location.query === 'commentSection') {
      gotoCommentSection(commentSection.current.offsetTop);
    }
    
  },[]);

  return (
    <Fragment>
      {/* header */}
      {
        RedirectToDashboard()
      }
      <Container fluid className="header px-0">
        <HomeHeader/>
      </Container>
      <Container fluid className="free-profile">
        <Row>
          <Container>
            <Row>
                <div className="col-12">
                    <h2>Try 60 Days Free</h2>
                </div>
                <div className="col-12">
                    <Freeprofile  redirectToCreatAccount = {redirectToCreatAccount}/>
                </div>
                
            </Row>
          </Container>
          <div className="video-howorks" ref={howWorks} >
            <Youtubevideo redirectToCreatAccount = {redirectToCreatAccount}/>
          </div>
          <Container>
            <Row ref={commentSection}>
              <div className="col-12">
                  <Accordion />
              </div>
              <div className="col-sm-8 col-md-6 mb-5 submission next-btn-pro">
                <a href="/#" className="" onClick={(e) => redirectToCreatAccount(e)} >
                    Try 60 Days Free
                </a>
              </div>
              <div className="col-12 text-center">
                  <div className="col-12text-center questions">
                    Have additional questions?
                  </div>
                  <a className="col-sm-8 col-md-6 text-center question-contactus">
                      <h2><Link to={PatientLinks.contactus}><img src="/assets/images/blue-mail-icon.png" className="mail-icon-blue"/>Contact Us</Link></h2>
                  </a>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
      <Footer/>
      
    </Fragment>
    );
  }
  
  export default FreeProfile;