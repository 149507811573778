import React, { Fragment, useContext, useState, useEffect } from 'react';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import Custommodal from '../../../global/modal/modal';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg';
import Pagination from "../../../components/admin/pagination/pagination";
import EditSpecialityModal from './edit-speciality-modal';
import DeleteSpecialityModal from './delete-speciality-modal';
import SpecialitySearch from './speciality-search';
import NetworkLayer from '../../../reactLayer/Network/Network';
import Loadermodal from "../../../global/loader-modal/loader-modal";

let searchText = ''
const DentalSpecilities = (props) => {
    const { type, subtype } = props;
    const authContext = useContext(AuthContext);
    const [totalCount, settotalCount] = useState(0);
    const [sortdegree, setSortdegree] = useState('ASC');
    const [sortspeciality, setSortspeciality] = useState('ASC');
    const [filter, setfilter] = useState({
        offset: 0,
        limit: 10
    });
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [viewdata, setViewdata] = useState();
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const modalList = ['editSpeModal', 'delSpeModal'];
    const openProfileTabModal = (modalName, value, isdegree) => {
        value.isdegree = isdegree;
        setViewdata(value);
        settabvisibleComponent(modalName)
        authContext.showModal()
    }

    const getList = async (value) => {
        console.log("get searchText :", searchText);
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if (searchText) {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                searchValue: searchText,
                category: 'dental',
                sortDegree: sortdegree,
                sortSpecialties: sortspeciality
            }
        } else {
            formData = {
                offset: filter.offset,
                limit: filter.limit,
                category: 'dental',
                sortDegree: sortdegree,
                sortSpecialties: sortspeciality
            }
        }


        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/specialities/get',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        try {
            let result = await NetworkLayer.postData(submitData);
            console.log(result);
            setData(result ?.body ?.result);
            settotalCount(result ?.body ?.result ?.specialties ?.count);
        } catch (err) {
            console.log("ERROR :", err)
        }

        authContext.unsetLoader();
        settabvisibleComponent("")
    }

    const searchFilter = (value) => {
        console.log(value);
        setfilter({offset:0,limit:10})
        searchText = value
        getList(value);
    }

    useEffect(() => {
        getList();
    }, [filter.offset, sortdegree, sortspeciality])

    // PAGINATION CALLBACK
    const changeLimit = (page) => {
        setfilter({ ...filter, ...{ offset: parseInt(page) } });
    };

    const changeDegreeSorting = () => {
        console.log(type);
        setSortdegree(sortdegree == "ASC" ? "DESC" : "ASC")
    };

    const changeSpecialitySorting = () => {
        console.log(type);
        setSortspeciality(sortspeciality == "ASC" ? "DESC" : "ASC")
    };

    useEffect(() => {
        getList();
    }, [])

    return (
        <Fragment>
            <Loadermodal />
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'editSpeModal' ? <EditSpecialityModal viewdata={viewdata} getList={getList} type={type} subtype={subtype} isdegree={viewdata.isdegree} />
                        :
                        tabvisibleComponent === 'delSpeModal' ? <DeleteSpecialityModal viewdata={viewdata} getList={getList} type={type} subtype={subtype} isdegree={viewdata.isdegree} />
                            :
                            <></>
                } />
            ) : (
                    <></>
                )}
            <div className="speciality-wrap">
                <div className="speciality-filter-top">
                    <SpecialitySearch search={searchFilter} type={type} subtype={subtype} isdegree={1} getList={getList} />
                </div>
                <div className="table-report-scroll mb-4">
                    <table className="table mb-2">
                        <thead>
                            <tr>
                                <th className="data-sort">
                                    <span className="sort-outer">
                                        Dental Degree Selections
                                    <span className="data-sort-icon" onClick={changeDegreeSorting}><DataSortIcon /></span>
                                    </span>
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.degree ?.rows.length == 0 &&
                                    <tr>
                                        <td>No Such Degree Found</td>
                                    </tr>
                            }
                            {
                                data ?.degree && data.degree ?.rows.map((value, index) =>
                                    <tr key={value.id}>
                                        <td>
                                            <div className="comment-medical">{value.name}</div>
                                        </td>
                                        <td>
                                            <Link onClick={() => { openProfileTabModal("editSpeModal", value, 1) }} className="table-action view-all">Edit</Link>
                                            <Link onClick={() => { openProfileTabModal("delSpeModal", value, 1) }} className="table-action view-all">Delete</Link>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
                {/* End of first table */}
                <div className="speciality-only-link">
                    <SpecialitySearch search={searchFilter} type={type} subtype={subtype} isdegree={0} getList={getList} />
                </div>
                {/* Start 2nd table */}
                <div className="table-report-scroll mb-4">
                    <table className="table mb-2">
                        <thead>
                            <tr>
                                <th className="data-sort">
                                    <span className="sort-outer">
                                        Dental Medical Specialties
                                    <span className="data-sort-icon" onClick={changeSpecialitySorting}><DataSortIcon /></span>
                                    </span>
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.specialties ?.rows.length == 0 &&
                                    <tr>
                                        <td>No Such Specialties Found</td>
                                    </tr>
                            }
                            {
                                data ?.specialties && data.specialties ?.rows.map((value, index) =>
                                    <tr key={value.id}>
                                        <td>
                                            <div className="comment-medical">{value.name}</div>
                                        </td>
                                        <td>
                                            <Link onClick={() => { openProfileTabModal("editSpeModal", value, 0) }} className="table-action view-all">Edit</Link>
                                            <Link onClick={() => { openProfileTabModal("delSpeModal", value, 0) }} className="table-action view-all">Delete</Link>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                {/* <Paging /> */}
                <div className="report-page mt-4">
                    <Pagination
                        total={totalCount}
                        limit={filter.limit}
                        range={4}
                        offset={filter.offset}
                        callBack={changeLimit}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default DentalSpecilities;