import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { Link } from 'react-router-dom';
import { ProviderLinks, PatientLinks, AdminLinks } from '../../linkFile';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import { ReactComponent as BackIcon } from '../../admin/assets/images/back_icon.svg';
import PracticeInformation from '../../components/admin/provider/practice-information';
import PersonalInformation from '../../components/admin/provider/personal-information';
import MedicalSpecialty from '../../components/admin/provider/medical-specialty';
import ManagementAppointment from '../../components/admin/provider/management-appointment';
import PatientTabs from '../../components/admin/patient/patient-tabs';
import DigitalReputation from '../../components/admin/provider/digital-reputation';
import ProEndorsement from '../../components/admin/provider/provider-endorsement';
import NetworkLayer from '../../reactLayer/Network/Network';
import Loadermodal from "../../global/loader-modal/loader-modal";

const ProviderDetail = (props) => {

    const authContext = useContext(AuthContext);
    const [visibleComponent, setvisibleComponent] = useState('personalInfo');
    const [providerDetail, setProviderDetails] = useState(null);
    const tabName = [{ id: 'personalInfo', name: 'Personal Information' }, { id: 'medSpe', name: 'Medical Specialty' }, { id: 'practiceInfo', name: 'Practice Information' }, { id: 'appointHistory', name: 'Appointment History' }, { id: 'digitalRepo', name: 'Digital Reputation' }, { id: 'endorse', name: 'Endorsement' }]
    let providerId = props.match.params.userID;

    const getDetails = async () => {
        authContext.setLoader();
        let id = props.match.params.userID;
        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/view/' + id,
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        setProviderDetails(result ?.result)
        // authContext.setProviderDetails(result ?.result);
        authContext.unsetLoader();
    }

    useEffect(() => {
        getDetails();
    }, [])

    return (
        <Fragment>
            <Loadermodal />
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section patient-management-outer"}>
                    <Row className="bg-white-report patient-details-outer">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Edit Provider Detail</div>
                            </div>
                            <div className="col-12 mt-3">
                                <Link to={AdminLinks.admin_provider_management} className="back-link">
                                    <span className="back-icon"><BackIcon /></span>
                                    Back
                            </Link>
                            </div>
                            <PatientTabs visibleComponent={visibleComponent} setvisibleComponent={setvisibleComponent} tabName={tabName} />

                            {
                                visibleComponent === 'personalInfo' ? <><PersonalInformation data={providerDetail} providerDetails={providerDetail} callBack={getDetails} /> </>
                                    :
                                    visibleComponent === 'medSpe' ? <MedicalSpecialty data={providerDetail} providerDetails={providerDetail} callBack={getDetails} />
                                        :
                                        visibleComponent === 'practiceInfo' ? <PracticeInformation data={providerDetail} providerDetails={providerDetail}  callBack={getDetails} />
                                            :
                                            visibleComponent === 'appointHistory' ? <ManagementAppointment data={providerDetail} providerDetails={providerDetail} callBack={getDetails} />
                                                :
                                                visibleComponent === 'digitalRepo' ? <DigitalReputation data={providerDetail} providerDetails={providerDetail} callBack={getDetails} />
                                                    :
                                                    visibleComponent === 'endorse' ? <ProEndorsement data={providerDetail} providerDetails={providerDetail} callBack={getDetails} />
                                                        : <></>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default ProviderDetail;