import React, { Fragment, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
// import { uuid } from 'uuidv4';

// Link File
import { ProviderLinks, PatientLinks, AdminLinks } from './linkFile';

// Auth File
import AuthState from './reactLayer/auth/AuthState';
// Global Files 
import Footer from './global/footer/footer';
import HeaderMain from './global/header/header-main';

// Provider Files


import Home from './provider/home/home';
import ProviderRegistration from './provider/provider-register/provider-register';
import ProviderLogin from './provider/provider-login/provider-login';
import ProviderTwoFactAuth from './provider/provider-login/twosteppage';
import ProviderProfile from './provider/profile/profile';
import Schedulingpage from './provider/dashboard/scheduling_page';
import SearchResult from './provider/search-result/search-result';
import ProviderDashboard from './provider/dashboard/dashboard';
import ManageAddress from './components/profile-tabs/manage-address';
import FreeProfile from './provider/free-profile/free-profile';
import MyAccount from './provider/my-account/my-account';
import AccountProfile from './provider/my-account/account-profile';
import ReferFriendPage from './provider/refer-friend/refer-friend';
import ScrollToTop from './global/scrollToTop/scrollToTop';
import ReactiveAccount from './provider/my-account/reactive-account';
import SubscriptionCancel from './provider/my-account/subscription-canceled';
import PaymentReceipt from './provider/payment-confirmed/payment-receipt';

import Gmaps from './components/googlemap/googlemap';

// Patient Files
import PatientHome from './patient/home/home';
import TestComponent from './provider/test/test';
import PatientRegistration from './patient/patient-register/patient-register';
import PatientDashboard from './patient/dashboard/dashboard';
import Aboutus from './patient/aboutus/aboutus';
import Contactus from './patient/contactus/contactus';
import Error404 from './global/errorpages/error-404';
import Error403 from './global/errorpages/error-403';
import RedirectionPage from './global/errorpages/redirectionPage';
import ProfileDeleted from './global/errorpages/profile-deleted';
import Covidscreen from './patient/covid-screens/covid-screen-1';
import SelfAssessment from './patient/covid-screens/covid-self-assessment';
import PrivacyPolicy from './patient/privacy-policy/privacy-policy';
import AcceptablePolicy from './patient/privacy-policy/acceptable-policy';
import Additionalterms from './patient/privacy-policy/additional-terms';
import TermsOfUse from './patient/privacy-policy/term-of-use';
import PatientAccount from './patient/my-account/my-account';
import AccountPatientProfile from './provider/my-account/account-profile';
import SearchBy from './patient/searchBy/searchBy';
import PatientHealth from './components/patient-health-grade/patient-hgrade';
import PatientHealthResult from './components/patient-health-grade/health-grade-result';
import CareTeam from './patient/dashboard/care-team';
import Notification from './patient/notification/notification';
import HowWorks from './patient/how-it-works/how-it-works';
import DoctorSearchResult from './components/doctor-search-result/doctor-search-result';
import AppointmentRequestSubmit from './patient/contactus/appointmentRequestSubmit';
import { ProviderPages, PatientPages, AdminPages } from './PrivatePages';


// import Doctor

import './App.scss';
import DoctorProfile from './patient/doctor-profile/doctor-profile';
import ForgotPassword from './components/provider-login/forgot-password';
import ResetPassword from './components/provider-login/reset-password';
// import ResetPasswordScreen from './components/provider-login/reset-password-screen';
import Cookies from './patient/cookies/accept-cookies';

//import Admin
import AdminLogin from './admin/admin-login/admin-login';
import AdminDashboard from './admin/dashboard/dashboard';
import AdminPatientManagement from './admin/patient-admin/patient-management';
import PatientDetail from './admin/patient-admin/patient-detail';
import PatientAddDetail from './admin/patient-admin/patient-adddetail';
import ProviderManagement from './admin/provider/provider-management';
import RevenueManagement from './admin/revenue/revenue-management';
import ProviderDetail from './admin/provider/provider-detail';
import SpecialityDetail from './admin/speciality/speciality-detail';
import InsuranceDetail from './admin/insurance/insurance-detail';
import WebsiteContent from './admin/website-content/website-content';
import RatingReview from './admin/rating-review/rating-review';
import RatingManagement from './admin/rating-review/rating-management';
import NotificationDetail from './admin/notification/notification-detail';
import AdminAppointments from './admin/appointments/appointments';
import SubscriptionManagementAdmin from './admin/subscription/subscription';
import ManageSubAdmin from './admin/manage-admin/manage-admin';
import RevenueAdmin from './admin/revenue/revenue-admin';
import VerifyAdmin from './admin/verify/verify-admin';

const App = (props) => {

  // const id = uuid();
  return (
    <AuthState>

      <Router key={uuidv4()}>
        <Fragment key={uuidv4()}>
          <ScrollToTop />
          <Switch key={uuidv4()}>
            {/* Common Routing */}
            <Route exact path='/' component={Home} key={uuidv4()} />

            {/* Provider Used Routing  */}
            <Route exact path={ProviderLinks.register} component={ProviderRegistration} key={uuidv4()} />
            <Route exact path={ProviderLinks.login} component={ProviderLogin} key={uuidv4()} />
            <Route exact path={ProviderLinks.twofactauth} component={ProviderTwoFactAuth} key={uuidv4()} />
            <Route exact path='/test' component={TestComponent} key={uuidv4()} />
            <Route exact path={ProviderLinks.dashboard} render={(props) => <ProviderPages> <ProviderDashboard {...props} /> </ProviderPages>} key={uuidv4()} />
            <Route exact path={ProviderLinks.myaccount} render={(props) => <ProviderPages> <MyAccount {...props} /> </ProviderPages>} key={uuidv4()} />
            <Route exact path={ProviderLinks.account_profile} render={(props) => <ProviderPages> <AccountProfile {...props} /> </ProviderPages>} key={uuidv4()} />
            <Route exact path={ProviderLinks.forgot_pass} component={ForgotPassword} key={uuidv4()} />
            <Route exact path={ProviderLinks.reset_pass} component={ResetPassword} key={uuidv4()} />
            {/* <Route exact path={ProviderLinks.reset_pass_screen} component={ResetPasswordScreen} key={uuidv4()} /> */}
            <Route exact path={ProviderLinks.subscription_canceled} render={(props) => <ProviderPages> <SubscriptionCancel {...props} /> </ProviderPages>} key={uuidv4()} />
            <Route exact path={ProviderLinks.freeprofile} component={FreeProfile} key={uuidv4()} />
            <Route exact path={ProviderLinks.edit_address} render={(props) => <ProviderPages> <ManageAddress {...props} /> </ProviderPages>} key={uuidv4()} />
            <Route exact path={ProviderLinks.add_address} render={(props) => <ProviderPages> <ManageAddress {...props} /> </ProviderPages>} key={uuidv4()} />
            <Route exact path={ProviderLinks.gmaps} component={Gmaps} key={uuidv4()} />

            {/* Patient Used Routing  */}
            <Route exact path={PatientLinks.dashboard} render={(props) => <PatientPages> <PatientDashboard {...props} /> </PatientPages>} key={uuidv4()} />
            <Route exact path={PatientLinks.register} component={PatientRegistration} key={uuidv4()} />
            <Route exact path={PatientLinks.redirection} component={RedirectionPage} key={uuidv4()} />
            <Route exact path={PatientLinks.login} component={ProviderLogin} key={uuidv4()} />
            <Route exact path={PatientLinks.myaccount} render={(props) => <PatientPages> <PatientAccount {...props} /> </PatientPages>} key={uuidv4()} />
            <Route exact path={PatientLinks.account_profile} render={(props) => <PatientPages> <AccountPatientProfile {...props} /> </PatientPages>} key={uuidv4()} />
            <Route exact path={PatientLinks.appointment_success} render={(props) => <PatientPages> <AppointmentRequestSubmit {...props} /> </PatientPages>} key={uuidv4()} />

            {/* <Route  component={Error404}  key={uuidv4()}/> */}

            {/* Provider unused Routing  */}
            <Route exact path={ProviderLinks.search_result} component={SearchResult} key={uuidv4()} />
            <Route exact path={ProviderLinks.profile + '/:userID'} component={ProviderProfile} key={uuidv4()} />
            <Route exact path={ProviderLinks.refer_friend} component={ReferFriendPage} key={uuidv4()} />
            <Route exact path={ProviderLinks.reactive_account} component={ReactiveAccount} key={uuidv4()} />
            <Route exact path={ProviderLinks.payment_receipt} component={PaymentReceipt} key={uuidv4()} />





            {/* Patient Unused Routing  */}

            <Route exact path={PatientLinks.privacy_policy} component={PrivacyPolicy} key={uuidv4()} />
            <Route exact path={PatientLinks.aboutus} component={Aboutus} key={uuidv4()} />
            <Route exact path={PatientLinks.profile_deleted} component={ProfileDeleted} key={uuidv4()} />
            <Route exact path={PatientLinks.contactus} component={Contactus} key={uuidv4()} />
            <Route exact path={PatientLinks.acceptable_policy} component={AcceptablePolicy} key={uuidv4()} />
            <Route exact path={PatientLinks.additional_terms} component={Additionalterms} key={uuidv4()} />
            <Route exact path={PatientLinks.term_of_user} component={TermsOfUse} key={uuidv4()} />
            <Route exact path={PatientLinks.covid_screen} component={Covidscreen} key={uuidv4()} />
            <Route exact path={PatientLinks.searchby} component={SearchBy} key={uuidv4()} />
            <Route exact path={PatientLinks.how_works} component={HowWorks} key={uuidv4()} />

            <Route exact path={PatientLinks.health_grade} component={PatientHealth} key={uuidv4()} />
            {/* <Route exact path={PatientLinks.health_grade_result} component={PatientHealthResult}  key={uuidv4()}/> */}
            <Route exact path={PatientLinks.care_team} component={CareTeam} key={uuidv4()} />
            <Route exact path={PatientLinks.notification} component={Notification} key={uuidv4()} />
            <Route exact path={PatientLinks.notification} render={(props) => <PatientPages> <Notification {...props} /> </PatientPages>} key={uuidv4()} />
            <Route exact path={ProviderLinks.notification} render={(props) => <ProviderPages> <Notification {...props} /> </ProviderPages>} key={uuidv4()} />
            <Route exact path={PatientLinks.doctor_search_result} component={DoctorSearchResult} key={uuidv4()} />
            <Route exact path={PatientLinks.doctor_profile} component={DoctorProfile} key={uuidv4()} />
            <Route exact path={PatientLinks.accept_cookies} component={Cookies} key={uuidv4()} />
            <Route exact path={PatientLinks.covid_self} component={SelfAssessment} key={uuidv4()} />

            {/* Admin Routing  */}
            <Route exact path={AdminLinks.admin_root} component={AdminLogin} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_login} component={AdminLogin} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_dashboard} render={(props) => <AdminPages> <AdminDashboard {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_provider_management} render={(props) => <AdminPages> <ProviderManagement {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_provider_management + '/:type'} render={(props) => <AdminPages> <ProviderManagement {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_revenue_management} render={(props) => <AdminPages> <RevenueManagement {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_patient_management} render={(props) => <AdminPages> <AdminPatientManagement {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_provider_detail + '/:userID'} render={(props) => <AdminPages> <ProviderDetail {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_patient_detail + '/:userID'} render={(props) => <AdminPages> <PatientDetail {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_patient_add_detail} render={(props) => <AdminPages> <PatientAddDetail {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_speciality} render={(props) => <AdminPages> <SpecialityDetail {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_insurance} render={(props) => <AdminPages> <InsuranceDetail {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_content_management} render={(props) => <AdminPages> <WebsiteContent {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_rating_review} render={(props) => <AdminPages> <RatingReview {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_rating_management + '/:ratingID'} render={(props) => <AdminPages> <RatingManagement {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_notification} render={(props) => <AdminPages> <NotificationDetail {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_appointments} render={(props) => <AdminPages> <AdminAppointments {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_subscription} render={(props) => <AdminPages> <SubscriptionManagementAdmin {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_manage_sub_admin} render={(props) => <AdminPages> <ManageSubAdmin {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_revenue} render={(props) => <AdminPages> <RevenueAdmin {...props} /> </AdminPages>} key={uuidv4()} />
            <Route exact path={AdminLinks.admin_verify + '/:token'} render={(props) => <VerifyAdmin {...props} />} key={uuidv4()} />




            <Route component={Error404} key={uuidv4()} />





            {/* <Route exact path='/patient' component={PatientHome}  key={uuidv4()}/>
              <Route exact path='/patient-register' component={PatientRegistration}  key={uuidv4()}/> */}
          </Switch>
        </Fragment>
      </Router>
    </AuthState>
  );
}

export default App;