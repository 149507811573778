import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import { ReactComponent as SelectIcon } from '../assets/images/list-triangle.svg';
// import RevenueChart from '../../components/admin/chart/revenue-chart';
// import DoughnutChart from '../../components/admin/chart/doughnut-chart';
import TopView from '../dashboard/top-view';
import ProviderManagementInfoMadal from '../../components/admin/provider/provider-manage-info-modal';
import NetworkLayer from '../../reactLayer/Network/Network';
import Custommodal from '../../global/modal/modal';
import { AdminLinks } from '../../linkFile';
import Loadermodal from "../../global/loader-modal/loader-modal";
import * as moment from 'moment';
import { Line,Doughnut } from 'react-chartjs-2';

const RevenueAdmin = () => {
    const [data, setData] = useState();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [viewdetails, setViewdetails] = useState([]);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const [growthAnalytics,setGrowthAnalytics] = useState({})
    const [donutData,setDonutData] = useState({})
    const [percentages,setPercentages] = useState({basic:'0%',premium:'0%',premiumPlus:'0%'})

    const donutOptions = {
        cutout:100,
        data: {
            labels: 'cccccc'            
        }
    }
    
    const modalList = ['manageInfo'];
    const getList = async (value,type) => {
        authContext.setLoader();
        // FORM DATA 
        const formData = {
            "growthAnalyticsOptionValue": value ? value : 365
        }
        if( type.indexOf('donut') > '-1') {
            formData["revenueCategoryOptionValue"] = value ? value : 365
        } 
        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/revenue/get',
            body: JSON.stringify(formData),
        }

        const graphData = {
            labels: [],
            datasets: [
              {
                label: 'Basic',
                data: [],
                fill: false,
                backgroundColor: 'green',
                borderColor: 'green',
                yAxisID: 'y1',
              },
              {
                label: 'Premium',
                data: [],
                fill: false,
                backgroundColor: 'blue',
                borderColor: 'blue',
                yAxisID: 'y1',
              },
              {
                label: 'Premium Plus',
                data: [],
                backgroundColor: '#de5646',
                borderColor: '#de5646',
                yAxisID: 'y1',
              },
            ],
          };

          const DoughnutData = {
            labels: ['Basic', 'Premium', 'Premium +'],
            datasets: [
              {
                label: '# of Votes',
                data: [],
                backgroundColor: [
                  '#48c0fb',
                  '#297db4',
                  '#39a7f0',
                ]
              },
            ],
          };

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        console.log('result',result);

        let revenueGrowthAnalytics = result.body.result.revenueGrowthAnalytics
        let revenueCategory = result.body.result.revenueCategory
        let labels = []
        let basicDataValues = []
        let premiumDataValues = []
        let premiumPlusDataValues = []
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        if(revenueGrowthAnalytics.basic){
        revenueGrowthAnalytics.basic.map((basicData)=>{
            basicDataValues.push(basicData.count)
            if(basicData.month){
                labels.push(month[parseInt(basicData.month)-1])
            }
            if(basicData.date){
                const today = new Date(basicData.date);
                const month = today.toLocaleString('default', { month: 'short' })
                const date = today.getDate()
                labels.push(month+','+date)
            }
            return null
        })
        }
        
        graphData.labels = labels;
        if(revenueGrowthAnalytics.premium){
        revenueGrowthAnalytics.premium.map((data)=>{
            premiumDataValues.push(data.count)
            return null
        })
        }
        if(revenueGrowthAnalytics.premiumPlus){
        revenueGrowthAnalytics.premiumPlus.map((data)=>{
            premiumPlusDataValues.push(data.count)
            return null
        })
        }
        
        graphData.datasets[0].data = basicDataValues
        graphData.datasets[1].data = premiumDataValues
        graphData.datasets[2].data = premiumPlusDataValues
       
        if(type.includes('graph')){
            console.log('graphData',graphData)
            setGrowthAnalytics(graphData)
        }

        if(type.includes('donut')){
            setPercentages({basic:revenueCategory.basic.percentageValue.toFixed(2)+'%',premium:revenueCategory.premium.percentageValue.toFixed(2)+'%',premiumPlus:revenueCategory.premiumPlus.percentageValue.toFixed(2)+'%'})
            DoughnutData.datasets[0].data = [revenueCategory.basic.totalCount,revenueCategory.premium.totalCount,revenueCategory.premiumPlus.totalCount]    
            setDonutData(DoughnutData)
        }

        setData(result ?.body ?.result);

        authContext.unsetLoader();
    }

    const openProfileTabModal = (modalName, value) => {
        getDetails(value ?.id).then(() => {
            settabvisibleComponent(modalName)
            authContext.showModal()
        })
    }

    const getDetails = async (id) => {
        authContext.setLoader();

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/view/' + id,
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        setViewdetails(result ?.result);
        authContext.unsetLoader();
    }

    const deleteProvider = async (id) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/delete',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        if (window.confirm('Are you sure you want to delete this provider?')) {
            let result = await NetworkLayer.postData(submitData);
            if (result.body.status) {
                getList('',['graph','donut']);
            }
        }

        authContext.unsetLoader();
    }

    const options = {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: true,
        plugins: {
          title: {
            display: false,
            text: 'Chart.js Line Chart - Multi Axis'
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    //console.log('context',context)
                    if(context.dataset.label === 'Revenue'){
                        return context.dataset.label+' : $'+context.formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }else{
                        return context.dataset.label+' : '+context.formattedValue;
                    }
                }
            }
        }
        },
        scales: {
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: {
                color:"#de5646",
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
          }
        }
      };

    const changetime = (e,type) => {
        let time = e.target.value;
        console.log(time)
        getList(time,type);
    }

    const redirectToViewAll = (e) => {
        e && e.preventDefault();
        history.push({
            pathname: `${AdminLinks.admin_revenue_management}`,
            state: { adminPath : true}
        });
    }

    const updateStatus = async (id, status) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id,
            status: status
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/status/update',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        authContext.unsetLoader();
        getList('',['graph','donut']);
    }

    const toggleStatus = (values) => {
        let status = values.status == 1 ? 0 : 1;
        let id = values.id;
        updateStatus(id, status);
    }

    useEffect(() => {
        getList('',['graph','donut']);
    }, [])
    return (
        <Fragment>
            <Loadermodal />
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'manageInfo' ? <ProviderManagementInfoMadal data={viewdetails} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section"}>
                    {/* Top blocks */}
                    <div className="revenue-section">
                        <TopView data={data} />
                    </div>
                    {/* Top blocks */}
                    <Row className="top-graph">
                        <div className="growth-analy">
                            <div className="heading-wrap">
                                <div className="report-heading">Revenue Analytics</div>
                                {/*<div className="color-sign">
                                    <span className="patient-sign-ups">Premium +</span>
                                    <span className="provider-sign-ups">Premium</span>
                                    <span className="revenue">Basic</span>
                                </div>*/}
                                <div className="report-drop-date">
                                    <select className="select-time" id="select-analytic-date"  onChange={(e)=>changetime(e,['graph'])}>
                                        <option value="365">Last 1 Year</option>
                                        <option value="30">Last 30 Days</option>
                                        <option value="7">Last Week</option>
                                        <option value="1">Yesterday</option>
                                        <option value="0">Today</option>
                                    </select>
                                    <span for="select-analytic-date" className="select-icon">
                                        <SelectIcon />
                                    </span>
                                </div>
                            </div>
                            {
                                <Line data={growthAnalytics} options={options} />
                            }
                        </div>
                        <div className="appoiny-cat">
                            <div className="heading-wrap">
                                <div className="report-heading">Revenue Categories</div>
                                <div className="report-drop-date">
                                    <select className="select-time" id="select-pppoint-date" onChange={(e)=>changetime(e,['donut'])}>
                                        <option value="365">Last 1 Year</option>
                                        <option value="30">Last 30 Days</option>
                                        <option value="7">Last Week</option>
                                        <option value="1">Yesterday</option>
                                        <option value="0">Today</option>
                                    </select>
                                    <span for="select-pppoint-date" className="select-icon">
                                        <SelectIcon />
                                    </span>
                                </div>
                            </div>
                            <div className="percentGraph">
                                <span>Basic : {percentages.basic}</span>
                                <span>Premium : {percentages.premium}</span>
                            </div>
                            <div className="percentGraph">
                            <span>Premium + : {percentages.premiumPlus}</span>
                            </div>
                            {/*<DoughnutChart type="revenue" appointmentCategory={data ?.revenueCategory} />*/}
                            <Doughnut data={donutData} options={donutOptions} />
                            <div className="app-color-sign">
                                <span className="child-sign">Child</span>
                                <span className="dental-sign">Dental</span>
                                <span className="adults-sign">Adults</span>
                            </div>
                        </div>
                    </Row>
                    <Row className="bg-white-report">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Provider Revenues</div>
                                <div className="table-view-all"><a href="/#" className="view-all" onClick={(e) => redirectToViewAll(e)}>View All</a></div>
                            </div>
                            <div className="table-report-scroll">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Provider Name</th>
                                            <th>Email</th>
                                            <th>Account Type</th>
                                            <th>Credit Card<br />Expiration</th>
                                            <th>First Trans Data</th>
                                            <th>Last Trans Data</th>
                                            <th>Total Revenue</th>
                                            <th>Active</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data ?.providersRevenue ?.result ?
                                                data.providersRevenue.result.map((value) =>
                                                    <tr key={`irp-${value.id}`}>
                                                        <td>{`${value.data ?.providers ?.firstName} ${value.data ?.providers ?.lastName}`}</td>
                                                        <td>{value.data ?.email}</td>
                                                        <td>{value.planName}</td>
                                                        <td>{value.data ?.userCurrentPlans ?.planPurchaseDetails ?.cardExpiry}</td>
                                                        <td>{moment(value ?.firstTransDate).format('D/MM/Y')}</td>
                                                        <td>{moment(value ?.lastTransDate).format('D/MM/Y')}</td>
                                                        <td>${value.totalRevenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td>
                                                            <label class="active-toggle">
                                                                <input type="checkbox" checked={value ?.data ?.status == 1 ? 'checked' : ''} onChange={() => { toggleStatus(value.data) }} />
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <Link className="table-action view-all" onClick={() => { openProfileTabModal("manageInfo", value.data) }}>View</Link>
                                                            <Link className="table-action view-all" onClick={() => { deleteProvider(value.data.id) }}>Delete</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            :
                                            <tr>
                                                <td colSpan="8">Records not found...</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="report-page">
                                {
                                    data ?.providersRevenue ?.result ?
                                        <span>Showing 1 to {data.providersRevenue.result.length} of {data ?.providersRevenue ?.resultCount} entries</span>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default RevenueAdmin;