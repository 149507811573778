import React, { Fragment, useState, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg';
import { CSVLink } from "react-csv";
import NetworkLayer from '../../../reactLayer/Network/Network';
import AuthContext from '../../../reactLayer/auth/AuthContext';
import FileUpload from "./file-upload";
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal';

const InsuranceImport = (props) => {
    console.log(props);
    const { getRefreshList, type, subtype, isdegree, isschool } = props
    const authContext = useContext(AuthContext);

    const [selectsubtype, setSelectsubtype] = useState(props.subtype);
    const [importFile, setImportFile] = useState();
    const [modalProps, setmodalProps] = useState({
        type: '',
        msg: '',
        btnname: '',
        redirection: ''
    });
    let text = "Insurance";

    if (type == 'Speciality') {

        text = "Speciality";

        if (isdegree == 1) {

            text = "Degree";
        }

        if (isschool == 1) {
            text = "School";
        }
    }

    const handleChange = (event) => {
        let file = "";
        if (event ?.target) {
            file = event.target.files[0];
        } else {
            file = event;
        }
        console.log("handleChange : ", file);


        setImportFile(file)
        // getBase64(file).then(
        //     data => setImportFile(data)
        // );

    }


    const importReport = async () => {

        let url = "";
        const formDataNew = new FormData();

        if (!importFile) {
            successMsg("error", "Please select file");
            return false;
        }
        if (type == 'Speciality') {
            // Add Speciality

            if (isdegree == 1) {
                url = "admin/degree/import";
                // Add Medical OR Dental Degree 
                formDataNew.append('category', selectsubtype)
                formDataNew.append('import_file', importFile)

            }
            if (isdegree == 0) {
                url = "admin/specialities/import";
                // Add Medical OR Dental Speciality 
                formDataNew.append('category', selectsubtype)
                formDataNew.append('import_file', importFile)

            }

            if (isschool == 1) {
                url = "admin/schools/import";
                // Add Medical OR Dental Speciality 
                formDataNew.append('category', selectsubtype)
                formDataNew.append('import_file', importFile)

            }
        }

        if (type == 'Insurance') {
            url = "admin/insurance/import";
            // Add Insurance
            formDataNew.append('category', selectsubtype)
            formDataNew.append('import_file', importFile)
        }

        const submitData = {
            headers: {
                'access_token': authContext.adminToken,
            },
            url: url,
            body: formDataNew,
        }


        authContext.setLoader();
        try {
            let result = await NetworkLayer.postData(submitData);
            if (result.body.status) {
                successMsg("success", "File Imported Successfully.");
               setTimeout(() => {
                 closeModal();
                 getRefreshList();
                }, 2000);
            } else {
                successMsg("error", result.body.message);
            }


        } catch (err) {
            successMsg("success", "Something went wrong.");
        }
        authContext.unsetLoader();

    }

    const closeModal = () => {
        authContext.hideModal()
    }

    const successMsg = (type, message) => {
        setmodalProps({
            type: type,
            msg: message
        });
        authContext.showResponseModal();
    }

    return (
        <Fragment>
            {
                authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>
            }
            <Container>
                <div className="search-modal-wrap">
                    <div className="edit-all-filter">
                        <Row>
                            <div className="col-12 px-0">
                                <div className="modal-head">Import {type}</div>
                            </div>
                        </Row>
                        <div className="allfilter-innerbox">
                            <Row>
                                <div className="col-12">
                                    <div className="rating-export-wrap provider-form-edit">
                                        <div className="rating-export-inner select-option">
                                            <label className="pro-label">{text} Type</label>
                                            <select className="select" onChange={(e) => { setSelectsubtype(e.target.value) }}>
                                                <option value="Medical">Medical</option>
                                                <option value="Dental">Dental</option>
                                            </select>
                                            <i className="select-option-icon">
                                                <SelectOptionIcon />
                                            </i>
                                        </div>
                                        <div className="rating-export-inner select-option">
                                            <label className="pro-label">Import File</label>
                                            <FileUpload handleChange={handleChange} />
                                        </div>

                                        <Row>
                                            <div className="col-12 mb-4 all-fltrbtn">
                                                <input type="button" className="rev-btn-pro" value="Cancel" onClick={closeModal} />
                                                <input type="button" className="next-btn-pro" value="Submit" onClick={importReport} />
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>

        </Fragment>
    )

}

export default InsuranceImport;