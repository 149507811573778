import React, { Fragment, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container,Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { PatientLinks } from '../../linkFile';





const CovidSelfAssessment3 = (props) => {
  const authContext = useContext(AuthContext);
  const step3 = authContext['covidOption'] && authContext['covidOption']['step-3'] ? authContext['covidOption']['step-3'] : false

  return (
    <Fragment>
      <Container fluid className="covid-screens-wrap">
        <Row>
          <Container>
            <Row>
                <div className="col-12">
                    <h1>Self Assessment</h1>
                    <div className="progress-outer">
                        <div className="progress-inner" style={{width: "39%"}}></div>
                    </div>
                </div>                
            </Row>
            <Row>
              <div className="col-12">
                <div className="covid-self-outerbox">
                    <div className="covid-question">
                      <span>3.</span>
                      Do you have any of the following symptoms?
                    </div>
                    <div className="covid-question-options">
                      <div className="cus-radio">
                          <input type="radio" id="opt1" checked={step3 === 'opt1' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt1"/>
                          <label htmlFor="opt1">Moderate to severe difficulty breathing (unable to speak full sentences)</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt2" checked={step3 === 'opt2' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt2"/>
                          <label htmlFor="opt2">Coughing up blood (more than about 1 teaspoon)</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt3" checked={step3 === 'opt3' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt3"/>
                          <label htmlFor="opt3">Signs of low blood pressure (feeling cold, pale, light-headed, too weak to stand)</label>
                      </div>
                      <div className="cus-radio">
                          <input type="radio" id="opt4" checked={step3 === 'opt4' ? true : false} onChange={(e)=>{ props.onSelectStepData(e) }} name="options" value="opt4"/>
                          <label htmlFor="opt4">None of the above</label>
                      </div>
                      <small className="error-input">{ props.error.step3 }</small>
                    </div>
                    <div className="covid-buttons">
                      <span className="covid-bkbtn" data-prev-page={PatientLinks.covid_self_2} onClick={(e)=>{ props.goPrevious(e) }}>Back</span>
                      <span className="nextbtn">
                        <input type="button" className="next-btn-pro" onClick={(e)=>{ props.onNext(e) }} value="Next"/>
                      </span>
                    </div>
                </div>                
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
    );
  }
  
  export default CovidSelfAssessment3;