import React, { Fragment, useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row } from 'react-bootstrap';
import AuthContext from '../../reactLayer/auth/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import AdminHeader from '../../components/admin/admin-header';
import AdminNav from '../../components/admin/admin-nav';
import { ReactComponent as SelectIcon } from '../assets/images/list-triangle.svg';
import TopView from './top-view';
import NetworkLayer from '../../reactLayer/Network/Network';
import Loadermodal from "../../global/loader-modal/loader-modal";
import ProviderManagementInfoMadal from '../../components/admin/provider/provider-manage-info-modal';
import Custommodal from '../../global/modal/modal';
import { AdminLinks } from '../../linkFile';
import { Line,Doughnut } from 'react-chartjs-2';
import moment from 'moment'

const AdminDashboard = () => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const donutOptions = {
        cutout:100,
        data: {
            labels: 'cccccc'            
        }
    }
    const [data, setData] = useState();
    const [viewdetails, setViewdetails] = useState([]);
    const [tabvisibleComponent, settabvisibleComponent] = useState('');
    const modalList = ['manageInfo'];
    const [growthAnalytics,setGrowthAnalytics] = useState({})
    const [donutData,setDonutData] = useState({})
    const [percentages,setPercentages] = useState({child:'0%',adult:'0%',dental:'0%'})
    
    
    const getList = async (value,type) => {
        authContext.setLoader();
        // FORM DATA 
        let formData = {};
        if( type.indexOf('donut') > '-1') {
            formData["appointmentCategoryOptionValue"] = value ? value : 365
        } 
        formData["growthAnalyticsOptionValue"] = value ? value : 365

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/dashboard/get',
            body: JSON.stringify(formData),
        }

        const graphData = {
            labels: [],
            datasets: [
              {
                label: 'Provider Sign Ups',
                data: [],
                fill: false,
                backgroundColor: 'green',
                borderColor: 'green',
                yAxisID: 'y',
              },
              {
                label: 'Patient Sign Ups',
                data: [],
                fill: false,
                backgroundColor: 'blue',
                borderColor: 'blue',
                yAxisID: 'y',
              },
              {
                label: 'Revenue',
                data: [],
                type:'bar',
                backgroundColor: '#de5646',
                borderColor: '#de5646',
                yAxisID: 'y1',
              },
            ],
          };

          const DoughnutData = {
            labels: ['Child', 'Adult', 'Dental'],
            datasets: [
              {
                label: '# of Votes',
                data: [],
                backgroundColor: [
                  '#48c0fb',
                  '#297db4',
                  '#39a7f0',
                ]
              },
            ],
          };

        // SEND REQUEST 
       // debugger;
        let result = await NetworkLayer.postData(submitData);
        
        let growthAnalytics = result.body.result.growthAnalytics
        let appointmentCategory = result.body.result.appointmentCategory
        let labels = []
        let patientValues = []
        
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
       
        if (Object.keys(growthAnalytics).length > 0) {
            growthAnalytics.patients.reverse().map((patientData)=>{
                patientValues.push(patientData.count)
                if(patientData.month){
                    labels.push(month[parseInt(patientData.month)-1])
                }
                if(patientData.date){
                    const today = new Date(patientData.date);
                    const month = today.toLocaleString('default', { month: 'short' })
                    const date = today.getDate()
                    labels.push(month+','+date)
                }
                return null
            })
            graphData.labels = labels;
            graphData.datasets[1].data = patientValues
            
            let providersValues = []
            growthAnalytics.providers.reverse().map((providerData)=>{
                providersValues.push(providerData.count)
            })
    
            graphData.datasets[0].data = providersValues
    
            let revenueValues = []
            growthAnalytics.revenue.reverse().map((revenueData) => {
                revenueValues.push(revenueData.count)
            })
    
            graphData.datasets[2].data = revenueValues
            if( type.indexOf('graph') > '-1') {
                console.log('graphData',graphData)
                setGrowthAnalytics(graphData)
            }
            if( type.indexOf('donut') > '-1') {
                setPercentages({child:appointmentCategory.child.percentageValue+'%',adult:appointmentCategory.adult.percentageValue+'%',dental:appointmentCategory.dental.percentageValue+'%'})
                DoughnutData.datasets[0].data = [appointmentCategory.child.totalCount,appointmentCategory.adult.totalCount,appointmentCategory.dental.totalCount]
                console.log('DoughnutData',)
                setDonutData(DoughnutData)
            }
            setData(result ?.body ?.result);
        }
       

        authContext.unsetLoader();
    }

    const toggleStatus = (values) => {
        let status = values.status == 1 ? 0 : 1;
        let id = values.id;
        updateStatus(id, status);
    }

    const changetime = (e,type) => {
        let time = e.target.value;
        console.log(time)
        getList(time,type);
    }

    const updateStatus = async (id, status) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id,
            status: status
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/status/update',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        let result = await NetworkLayer.postData(submitData);
        authContext.unsetLoader();
        getList('',['graph']);
    }

    const openProfileTabModal = (modalName, value) => {
        getDetails(value ?.id).then(() => {
            settabvisibleComponent(modalName)
            authContext.showModal()
        })
    }

    const getDetails = async (id) => {
        authContext.setLoader();

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/view/' + id,
        }

        // SEND REQUEST 
        let result = await NetworkLayer.getRequest(submitData);
        setViewdetails(result ?.result);
        authContext.unsetLoader();
    }

    const deleteProvider = async (id) => {
        authContext.setLoader();

        // FORM DATA 
        const formData = {
            id: id
        }

        // STRUCTURE LOGIN REQUEST DATA
        const submitData = {
            headers: {
                'Content-Type': 'application/json',
                'access_token': authContext.adminToken
            },
            url: 'admin/providers/delete',
            body: JSON.stringify(formData),
        }

        // SEND REQUEST 
        if (window.confirm('Are you sure you want to delete this provider?')) {
            let result = await NetworkLayer.postData(submitData);
            if (result.body.status) {
                getList('',['graph']);
            }
        }

        authContext.unsetLoader();
    }

    const options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: true,
    plugins: {
        title: {
        display: false,
        text: 'Chart.js Line Chart - Multi Axis'
        },
        tooltip: {
        callbacks: {
            label: function(context) {
                //console.log('context',context)
                if(context.dataset.label === 'Revenue'){
                    return context.dataset.label+' : $'+context.formattedValue;
                }else{
                    return context.dataset.label+' : '+context.formattedValue;
                }
            }
        }
    }
    },
    scales: {
        
        y: {
        type: 'linear',
        display: true,
        position: 'left',
        // grid line settings
        grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        },
        y1: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
            color:"#de5646",
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
                return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        }
        }
    }
    };
    const redirectToViewAll = (e, path) => {
        e && e.preventDefault();
        history.push({
            pathname: `${AdminLinks.admin_provider_management}/${path}`,
            state: { adminPath : true}
        });
    }
    useEffect(() => {
        getList('',['graph','donut']);
    }, [])
    return (
        <Fragment>
            <Loadermodal />
            {modalList.indexOf(tabvisibleComponent) > '-1' ? (
                <Custommodal unsetComponent={settabvisibleComponent} componentName={
                    tabvisibleComponent === 'manageInfo' ? <ProviderManagementInfoMadal data={viewdetails} />
                        :
                        <></>
                } />
            ) : (
                    <></>
                )}
            <AdminHeader />
            <div className="outerWrapper">
                <AdminNav />
                <Container fluid className={authContext.adminMenuActive + " admin-dashboard right-admin-section"}>
                    {/* Top blocks */}
                    <div className="dash-section">
                        <TopView data={data} />
                    </div>
                    {/* Top blocks */}
                    <Row key={"top-graph"} className="top-graph">
                        <div key={'growth-analy'} className="growth-analy">
                            <div className="heading-wrap">

                                <div className="report-heading">Growth Analytics</div>
                                {/*<div className="color-sign">
                                    <span className="patient-sign-ups">Patient Sign Ups</span>
                                    <span className="provider-sign-ups">Provider Sign Ups</span>
                                    <span className="revenue">Revenue</span>
                                </div>*/}
                                <div className="report-drop-date">
                                    <select className="select-time" id="select-analytic-date" onChange={(e)=>changetime(e,['graph'])}>
                                        <option value="365">Last 1 Year</option>
                                        <option value="30">Last 30 Days</option>
                                        <option value="7">Last Week</option>
                                        <option value="1">Yesterday</option>
                                        <option value="0">Today</option>
                                    </select>
                                    <span for="select-analytic-date" className="select-icon">
                                        <SelectIcon />
                                    </span>
                                </div>
                                {
                                    /*data ?.growthAnalytics &&
                                        <ColumnLineAreaChart growthAnalytics={data ?.growthAnalytics} />
                                    */
                                    <Line data={growthAnalytics} options={options} />
                                }
                            </div>

                        </div>
                        <div key={'appoiny-cat'} className="appoiny-cat">
                            <div className="heading-wrap">
                                <div className="report-heading">Appointment Categories</div>
                                <div className="report-drop-date">
                                    <select className="select-time" id="select-pppoint-date" onChange={(e)=>changetime(e,['donut'])}>
                                        <option value="365">Last 1 Year</option>
                                        <option value="30">Last 30 Days</option>
                                        <option value="7">Last Week</option>
                                        <option value="1">Yesterday</option>
                                        <option value="0">Today</option>
                                    </select>
                                    <span for="select-pppoint-date" className="select-icon">
                                        <SelectIcon />
                                    </span>
                                </div>
                            </div>
                            <div className="percentGraph">
                                <span>Child : {percentages.child}</span>
                                <span>Adult : {percentages.adult}</span>
                                <span>Dental : {percentages.dental}</span>
                            </div>
                            {
                                /*data ?.growthAnalytics &&
                                    <DoughnutChart type="appointment" appointmentCategory={data ?.appointmentCategory} />
                                */
                                <Doughnut data={donutData} options={donutOptions} />
                            }


                            <div className="app-color-sign">
                                <span className="child-sign">Child</span>
                                <span className="dental-sign">Dental</span>
                                <span className="adults-sign">Adults</span>
                            </div>
                        </div>
                    </Row>
                    <Row key={"bg-white-report-1"} className="bg-white-report">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Incomplete Registered Providers</div>
                                <div className="table-view-all"><a className="view-all" href="/#" onClick={(e) => redirectToViewAll(e,'incomplete-registered-providers') }>View All</a></div>
                            </div>
                            <div className="table-report-scroll">
                                <table className="table" key={'incomplete-provider'}>
                                    <thead>
                                        <tr>
                                            <th>Provider Name</th>
                                            <th>Joined Date</th>
                                            <th>Email</th>
                                            <th>Location</th>
                                            <th>Account Type</th>
                                            <th>Active</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data ?.incompleteRegisteredProviders &&
                                                data.incompleteRegisteredProviders.result.map((value) =>
                                                    <tr key={`irp-${value.id}`}>
                                                        <td>{`${value.accountInfo.firstName} ${value.accountInfo.lastName}`}</td>
                                                        <td>{`${moment(value.accountInfo.joining_date).format('DD MMM YYYY')}`}</td>
                                                        <td>{value.accountInfo.email}</td>
                                                        <td>{value.accountInfo.city_name ? value.accountInfo.state_name+","+value.accountInfo.city_name : '--'}</td>
                                                        <td>{value.accountInfo.account_type.planName ? value.accountInfo.account_type.planName.toUpperCase() : value.accountInfo.account_type.toUpperCase() }</td>
                                                        <td>
                                                            <label className="active-toggle">
                                                                <input type="checkbox" checked={value.accountInfo.status} onChange={() => { toggleStatus(value.accountInfo) }} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <Link className="table-action view-all" onClick={() => { openProfileTabModal("manageInfo", value.accountInfo) }}>View</Link>
                                                            <Link className="table-action view-all" to={`${AdminLinks.admin_provider_detail}/${value.accountInfo.id}`}>Edit</Link>
                                                            <Link className="table-action view-all" onClick={() => { deleteProvider(value.accountInfo.id) }}>Delete</Link>
                                                        </td>
                                                    </tr>
                                                )

                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="report-page">Showing 1 to {data ?.incompleteRegisteredProviders ?.result.length} of {data ?.incompleteRegisteredProviders ?.resultCount} entries</div>
                        </div>
                    </Row>
                    <Row key={"bg-white-report-2"} className="bg-white-report">
                        <div className="table-report">
                            <div className="heading-wrap">
                                <div className="table-heading">Latest Providers<span className="normal-text">(Previous 30 days)</span></div>
                                <div className="table-view-all"><a className="view-all" href="/#" onClick={(e) => redirectToViewAll(e, 'latest-providers') }>View All</a></div>
                            </div>
                            <div className="table-report-scroll">
                                <table className="table" key={'complete-provider'}>
                                    <thead>
                                        <tr>
                                            <th>Provider Name</th>
                                            <th>Joined Date</th>
                                            <th>Email</th>
                                            <th>Location</th>
                                            <th>Account Type</th>
                                            <th>Active</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data ?.latestProviders &&
                                                data.latestProviders.result.map((value) =>
                                                    <tr key={`lp-${value.id}`}>
                                                        <td>{`${value.accountInfo.firstName} ${value.accountInfo.lastName}`}</td>
                                                        <td>{`${moment(value.accountInfo.joining_date).format('DD MMM YYYY')}`}</td>
                                                        <td>{value.accountInfo.email}</td>
                                                        <td>{value.accountInfo.city_name ? value.accountInfo.state_name+','+value.accountInfo.city_name : '--'}</td>
                                                        <td>{value.accountInfo.account_type.planName ? value.accountInfo.account_type.planName.toUpperCase() : value.accountInfo.account_type.toUpperCase() }</td>
                                                        <td>
                                                            <label className="active-toggle">
                                                                <input type="checkbox" checked={value.accountInfo.status} onChange={() => { toggleStatus(value.accountInfo) }} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <Link className="table-action view-all" onClick={() => { openProfileTabModal("manageInfo", value.accountInfo) }}>View</Link>
                                                            <Link className="table-action view-all" to={`${AdminLinks.admin_provider_detail}/${value.accountInfo.id}`}>Edit</Link>
                                                            <Link className="table-action view-all" onClick={() => { deleteProvider(value.accountInfo.id) }}>Delete</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                    </tbody>
                                </table>
                            </div>
                            <div className="report-page">Showing 1 to {data ?.latestProviders ?.result.length} of {data ?.latestProviders ?.resultCount} entries</div>
                        </div>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default AdminDashboard;