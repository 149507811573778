import React, { Fragment, useContext } from 'react';
import { useHistory } from "react-router-dom";
import AuthContext from '../../reactLayer/auth/AuthContext';

import HeaderMain from '../../global/header/header';
import Footer from '../../global/footer/footer';
import ProviderStep from '../../components/provider-steps/provider-steps';
import ProviderStep1 from '../../components/provider-steps/provider-step1';
import ProviderStep2 from '../../components/provider-steps/provider-step2';
import ProviderStep3 from '../../components/provider-steps/provider-step3';
import ProviderStep4 from '../../components/provider-steps/provider-step4';
import ProviderStep5 from '../../components/provider-steps/provider-step5';
import Error403 from '../../global/errorpages/error-403';
import Error404 from '../../global/errorpages/error-404';
import { ProviderLinks } from '../../linkFile';
import ProviderLogin from '../../components/provider-login/provider-login';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Tabs  } from 'react-bootstrap';


const ProviderRegister = (props) => {

  const validLinks = ['account-information', 'medical-specialty', 'practice-information', 'select-plan', 'payment']
  const authContext = useContext(AuthContext);
  const user = authContext.user;
  const history = useHistory();

  const goPrevious = ( e ) => {
    e.preventDefault();
    let previousPage = e.currentTarget.getAttribute('data-prev-page');
    history.push({
                pathname: previousPage,
            });
  }

  const Validatepages = () => {
    if (validLinks.indexOf(props.match.params.type) > '-1') {
      if( props.match.params.type === 'account-information' ) {
        if( authContext.user == null || authContext.user.step ) {
          return <Container fluid className="provider-steps-wrap"><ProviderStep1/></Container>;
        } else {
          if( user &&  user.accountInfo.isProfileCompleted && user.accountInfo.roleId && user.accountInfo.roleId === 3 ) {
            history.push( {
                pathname: ProviderLinks.dashboard,
            });
            return;
          }  else if( user && user.accountInfo && user.accountInfo.roleId && user.accountInfo.roleId !== 3 ) {
            authContext.logout();
            history.push( {
              pathname: ProviderLinks.register_setp1,
            });
          } else {

            if( user.step ) {
               history.push( {
                  pathname: ProviderLinks['register_setp'+user.step],
              });
            } else {
              return <Container fluid className="provider-steps-wrap"><ProviderStep1/></Container>;
            }
           
            // return <Container fluid className="provider-steps-wrap"><ProviderStep1/></Container>;
          }
        }
      } else {
        if( user && user.accountInfo.isProfileCompleted && !user.accountInfo && user.accountInfo.roleId && user.accountInfo.roleId === 3 ) {
            history.push( {
                pathname: ProviderLinks.dashboard,
            });
            return;
          } else if( user && user.accountInfo &&  user.accountInfo.roleId && user.accountInfo.roleId != 3 || !user) {
              authContext.logout();  
              history.push( {
                  pathname: ProviderLinks.register_setp1,
                });
          } else {
            if( props.match.params.type === 'medical-specialty' ) { 
              return <Container fluid className="provider-steps-wrap"><ProviderStep2 {...{goPrevious : goPrevious}} /></Container>
            } else if( props.match.params.type === 'practice-information' ) { 
              return <Container fluid className="provider-steps-wrap"><ProviderStep3 {...{goPrevious : goPrevious}}/></Container> 
            } else if( props.match.params.type === 'select-plan' ) { 
              return <Container fluid className="provider-step4-wrap"><ProviderStep4 {...{goPrevious : goPrevious}}/></Container>
            } else {
              return <Container fluid className="provider-steps-wrap"><ProviderStep5 {...{goPrevious : goPrevious, ...props}}/></Container>
            }
          }
         
      }
    } else {
      return <Error404/>
    }
  }

  return (
    <Fragment>
      <Container className="header px-0">
        <HeaderMain/>
      </Container>
       {
         Validatepages()
       }
       <Footer/>
    </Fragment>
    );
  }
  
export default ProviderRegister
